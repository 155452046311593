import React, {useEffect, useState, FC} from 'react'
import {ProgressComponent} from './ProgressComponent'
import CountUp from 'react-countup'

type Props = {
  textColorLabel?:string
  labelColor: string
  backgroundColor: string
  visualParts: [
    {
      percentage: string
      color: string
    }
  ]
}

/**
 * This Component create a Dinamic Progress component with number
 *
 * @param labelColor - color for label section (variable sass)
 * @param textColorLabel - string with color for progress count label (variable sass)
 * @param backgroundColor - background base Color for the progress section. (variable sass)
 * @param visualParts - array with percentage and color (variable sass) for progress active section
 */

const ProgressWithNum: FC<Props> = ({
  textColorLabel,
  labelColor,
  backgroundColor,
  // expected format for visual parts
  visualParts = [
    {
      percentage: '0%',
      color: 'white',
    },
  ],
}) => {
  const [widths, setWidths] = useState(visualParts.map((item) => '0%'))

  useEffect(() => {
    const timerId = setTimeout(() => {
      setWidths(visualParts.map((item) => item.percentage))
    }, 0)

    return () => clearTimeout(timerId)
  }, [visualParts])

  const labelStyles: React.CSSProperties = {
    position: 'absolute',
    padding: '0.1em 0.3em 0.1em 0.3em',
    width: 'fit-content',
    flexShrink: 0,
    border: 'none',
    borderRadius: '0.5em',
    left: widths[0],
    bottom: 'calc(100% + 5px)',
    transform: 'translateX(-50%)',
  }

  const labelStyleExtra: React.CSSProperties = {
    content: '""',
    position: 'absolute',
    top: '100%',
    left: '50%',
    marginLeft: '-5px',
    width: 0,
    height: 0,
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTopStyle: 'solid',
    borderTopWidth: '5px',
  }
  //Remove % symbol from string
  let numberPercentage = visualParts[0].percentage.slice(0, -1)
  
  return (
    <div  style={{position: 'relative'}}>
      <div className='d-flex justify-content-start align-items-end mb-2 relative'>
        <div
          style={{
            left: widths[0],
            ...labelStyles,
            transition: 'left 2s',
            background: labelColor
          }}
        >
          <CountUp
            className={`ff-prompt fw-bolder fs-9 mt-0 text-${textColorLabel}`}
            start={0}
            end={Number(numberPercentage)}
            duration={2}
            suffix={'%'}
          />

          <div  style={{
            ...labelStyleExtra,
            color: labelColor
          }} />
        </div>
      </div>
      <ProgressComponent backgroundColor={backgroundColor} visualParts={visualParts} />
    </div>
  )
}

export {ProgressWithNum}
