import React from 'react'
import {AsideMenuItem} from '../../AsideMenuItem'
import {useIntl} from 'react-intl'

const HomeAside = () => {
  const intl = useIntl()
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/gws-icons/warket.svg'
        title={intl.formatMessage({id: 'MENU.HOME'})}
      />
    </>
  )
}

export {HomeAside}
