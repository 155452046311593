import styled from '@emotion/styled'
import {DOTS, usePagination} from '../../../../../constants/usePagination'
import { useIntl } from 'react-intl'

type PaginationItemProps = {
  active: boolean
}

type PaginationControlProps = {
  disabled: boolean
}

const PaginationWrapper = styled('div')({
  display: 'flex',
  margin: '10px 0px',
  justifyContent: 'flex-end',
  "@media (max-width: 450px)": {
    marginTop: "20px"
  }
})

const PaginationContainer = styled('div')({
  display: 'inline-flex',
  background: 'transparent',
  "@media (max-width: 450px)": {
    alignItems: "center"
  }
})

const PaginationItem = styled('div')<PaginationItemProps>(({active}) => ({
  padding: '0 12px',
  height: '30px',
  textAlign: 'center',
  display: 'flex',
  boxSizing: 'border-box',
  alignItems: 'center',
  fontSize: '13px',
  minWidth: '30px',
  background: '#fff',
  borderRadius: '5px',
  color: active ? '#0D7CBD' : '#212121',
  border: `1px solid ${active ? '#0D7CBD' : '#212121'}`,
  margin: '0px 3px',
  cursor: 'pointer',
  transition: 'all ease .3s',
  "@media (max-width: 450px)": {
    height: '20px',
    fontSize: '8px',
    minWidth: '20px',
    padding: '0 0px',
    justifyContent: "center"
  }
}))

const PaginationDots = styled('div')(() => ({
  padding: '0 12px',
  height: '30px',
  textAlign: 'center',
  display: 'flex',
  boxSizing: 'border-box',
  alignItems: 'center',
  fontSize: '13px',
  minWidth: '30px',
  background: '#fff',
  borderRadius: '5px',
  color: '#0D7CBD',
  margin: '0px 3px',
}))

const PaginationControl = styled('li')<PaginationControlProps>(({disabled}) => ({
  padding: '0 12px',
  height: '30px',
  textAlign: 'center',
  display: 'flex',
  boxSizing: 'border-box',
  alignItems: 'center',
  fontSize: '13px',
  minWidth: '30px',
  background: '#fff',
  borderRadius: '5px',
  color:"#212121",
  cursor: 'pointer',
  transition: 'all ease .3s',
  ':hover': {
    color: disabled ? "": "#0D7CBD",
    cursor: disabled ? "initial": "pointer"
  },
}))

export const Pagination = (props: any) => {
  const intl = useIntl()
  const {onPageChange, totalCount, siblingCount = 1, currentPage, pageSize} = props

  const paginationRange: any = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  const onNext = () => {
    onPageChange(currentPage + 1)
  }

  const onPrevious = () => {
    if (currentPage !== 1) {
      onPageChange(currentPage - 1)
    }
  }

  let lastPage = paginationRange[paginationRange.length - 1]
  return (
    <PaginationWrapper>
      <PaginationContainer>
        <PaginationControl disabled={currentPage === 1} onClick={onPrevious}>
          {intl.formatMessage({ id: 'PAGINATION.BACK' })}
        </PaginationControl>
        {paginationRange.map((pageNumber: any) => {
          if (pageNumber === DOTS) {
            return <PaginationDots>...</PaginationDots>
          }
          return (
            <PaginationItem
              active={pageNumber === currentPage}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </PaginationItem>
          )
        })}
        <PaginationControl disabled={currentPage === lastPage} onClick={onNext}>
          {intl.formatMessage({ id: 'PAGINATION.NEXT' })}
        </PaginationControl>
      </PaginationContainer>
    </PaginationWrapper>
  )
}
