/* eslint-disable jsx-a11y/anchor-is-valid */
import {Route, Routes} from 'react-router-dom'
import { ContainerImg} from './PaymentPage'
import {PaymentSuccess} from './components/PaymentSuccess'
/**
 * Auth Layout for the login form and the other elements outside the form
 * @component
 */

const PaymentSuccessLayout = () => {
  return (
    <ContainerImg style={{height: '100vh', display: 'grid', placeItems: 'center'}}>
      <PaymentSuccess />
    </ContainerImg>
  )
}
const PaymentSuccessPage = () => (
  <Routes>
    <Route element={<PaymentSuccessLayout />}>
      <Route index element={<PaymentSuccess />} />
    </Route>
  </Routes>
)

export {PaymentSuccessPage}
