import {createRoot} from 'react-dom/client'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
// Apps
import {CrunchI18nProvider} from './_gws/i18n/Crunchi18n'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_gws/assets/css/style.rtl.css'
 **/
//Default Flatpick styles
import 'flatpickr/dist/flatpickr.css'
import 'nouislider/dist/nouislider.css'
import './_gws/assets/sass/style.scss'
import './_gws/assets/sass/plugins.scss'
import './_gws/assets/sass/style.react.scss'
import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, setupAxios} from './app/modules/auth'

setupAxios(axios)
Chart.register(...registerables)

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <CrunchI18nProvider>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </CrunchI18nProvider>
    </QueryClientProvider>
  )
}
