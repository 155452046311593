import {KTSVG} from '../../../helpers/components/KTSVG'
import {toAbsoluteUrl} from '../../../helpers/AssetHelpers'
import { AgPercentageCardText } from '../../../layout/components/ag/components/AgPercentageCardText' 

type Props = {
  text: string
  backgroundColor?: string
  color?:string
  customCss?: string
  icon?: string
  svgColor?: string
  customStyleIcon?: string
}
/**
 * This Component create a Label for the fields in user profile.
 *
 * @param text - string with the text
 * @param backgroundColor - (optional)background Color for the component. (variable sass)
 * @param color - (optional)color for text-component (variable sass) - gray-550 by default
 * @param customCss - (optional) string with aditional css for the label (variable sass)
 * @param icon - (optional)string(icon referent) for the component
 * @param svgColor - (optional)string with svg icon (variable sass) - gray-550 by default
 * @param customStyleIcon - (optional) string with aditional css for the icon (variable sass)
 */

const ProfileLabelWithIcon: React.FC<Props> = ({text, color='gray-550', customCss, backgroundColor, icon, svgColor='gray-550', customStyleIcon}) => {

  return (
    <div
      className={`d-flex justify-content-start align-items-center gap-1 bg-${backgroundColor} ${customCss}`}
    >
    {
        icon &&
        <KTSVG
            path={toAbsoluteUrl(icon)}
            className={`svg-icon-9 pb-1 svg-icon-${svgColor} ${customStyleIcon}`}
        />
    }
      
      <AgPercentageCardText customClass={`text-${color}`} text={text} />
    </div>
  )
}

export {ProfileLabelWithIcon}