import {useAuth, UserModel} from '../../../../app/modules/auth'
import {useEffect, useState} from 'react'
import {HeaderUserTopMenu} from '../../../partials/layout/header-menus/HeaderUserTopMenu'
// import {useLang} from '../../../i18n/Crunchi18n'

/* eslint-disable jsx-a11y/anchor-is-valid */
const UserHeaderToolbar = () => {
  const {currentUser, currentClient} = useAuth()
  const [client, setClient] = useState<UserModel | undefined>()
  // const lang = useLang()

  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    setClient(currentClient !== undefined ? currentClient : currentUser)
  }, [currentUser, currentClient])

  return (
    <>
      <a
        href='#'
        className='text-gray-900 fs-7 fw-regular'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-start'
        data-kt-menu-overflow='false'
      >
        <div className='d-flex align-items-sm-center justify-content-center'>
          <div className='symbol symbol-35px symbol-md-50px '>
            {
              //@ts-ignore
              client?.picUrl !== undefined && client?.picUrl !== null && client.picUrl !== '' ? (
                <img alt={client?.username} src={client.picUrl} />
              ) : (
                <span className='symbol-label bg-light-primary text-dark text-uppercase fw-bold'>
                  {client?.lastname?.substring(0, 1)}
                  {client?.name?.substring(0, 1)}
                </span>
              )
            }
            {
              /**
                        <img
              alt={lang}
              src={
                lang === 'es'
                  ? toAbsoluteUrl('/media/flags/mexico.svg')
                  : toAbsoluteUrl('/media/flags/united-states.svg')
              }
              className='symbol-badge badge badge-circle bg-success  top-0 start-0'
              style={{
                width: '1.2em',
                height: '1.2em',
                border: '1px solid white',
                marginTop: '0.25em',
                marginLeft: '0.25em',
              }}
            ></img>
               */
            }
          </div>
          {/*<div className='aside-user-info flex-row-fluid flex-wrap ms-5 d-none d-md-flex'>*/}
          {/*  <div className='d-flex'>*/}
          {/*    <div className='flex-grow-1 me-2'>*/}
          {/*      {client?.name + ' ' + client?.lastname}*/}
          {/*      <span className='text-gray-600 fw-regular d-block fs-8 mb-1'>*/}
          {/*        {client?.saasPlan?.toUpperCase()}*/}
          {/*      </span>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </a>
      <HeaderUserTopMenu />
    </>
  )
}

export {UserHeaderToolbar}
