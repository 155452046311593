import styled from '@emotion/styled'

export const Container = styled('div')({})

export const ProductsWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
  gap: '1rem',
})

export const BaseCard = styled('div')({
  padding: '20px',
  borderRadius: '10px',
  boxShadow: '-1px 14px 19px -4px #919EAB33',
  display: 'grid',
  gridTemplateColumns: '1.5fr 1fr',
  gap: '1rem',
})

export const ImgWrapper = styled('div')({
  minWidth: '110px',
  minHeight: '110px',
  maxWidth: '110px',
  maxHeight: '110px',
  overflow: 'auto',
  '::-webkit-scrollbar': {
    display: 'none',
  },
})

export const Slider = styled('div')({})

export const SliderControls = styled('div')({
  display: 'grid',
  placeItems: 'center',
  margin: '10px 0px',
})

type PropsBtn = {
  active: boolean
}
export const SliderBtn = styled('div')<PropsBtn>(({active}) => ({
  width: '6px',
  height: '6px',
  borderRadius: '50%',
  background: !active ? '#D9D9D9' : '#212121',
  margin: '0px 5px',
  cursor: 'pointer',
}))

export const BadgeRank = styled('div')({
  height: '20px',
  width: '20px',
  borderRadius: '50%',
  background: '#0D7CBD',
  display: 'grid',
  color: '#fff',
  placeItems: 'center',
})

export const ContainerInfo = styled('div')({
  marginTop: '10px',
  button: {
    border: 'none',
    background: 'none',
    fontSize: '.9rem',
    fontWeight: 'bold',
  },
  h4: {
    fontSize: '14px',
  },
  p: {
    fontSize: '10px',
    color: '#9E9E9E',
  },
})

export const ExportCSV = styled('button')({
  padding: '5px',
  borderRadius: '8px',
  background: "#fff",
  marginLeft: "15px"
})
