import React, {FC} from 'react'
import TableClient from './TableClient'
import {useIntl} from 'react-intl'
import {CustomTotal} from '../../../../../_gws/partials/tables/CustomTotal'
import {AvatarUser} from '../../../../../_gws/partials/widgets/misc/AvatarUser'
import {MarketplaceConexionTable} from '../../../../../_gws/partials/widgets/misc/MarketplaceConexionTable'
import {headFormatter, optionsTable} from '../../../../../_gws/partials/tables/TableHelper'
import {UserActionsCell} from '../../../../modules/apps/user-management/users-list/table/UserActionCell'

type Props = {
  list: any
}
/**
 * Used to wrap the final Table component
 * @param list - Client List
 * @component
 */
const TableClientWrapper: FC<Props> = ({list}) => {
  const intl = useIntl()
  const formatterActions = (cellContent: any, row: any) => <UserActionsCell row={row} />

  /**
   * Bootstrap Table data Array
   */
  const columns = [
    {
      dataField: 'picture',
      text: '',
      searchable: false,
      style: {width: '5%'},
      headerFormatter: headFormatter,
      formatter: (cellContent: any, row: any) => {
        return (
          <AvatarUser
            userID={row.id}
            picUrl={row.picture}
            name={row.name}
            username={row.username}
            lastname={row.lastname}
          />
        )
      },
    },
    {
      dataField: 'id',
      text: intl.formatMessage({id: 'USER_MNGT.TABLE.HEAD.ID'}),
      sort: true,
      searchable: false,
      style: {width: '5%'},
      headerFormatter: headFormatter,
    },
    {
      dataField: 'email',
      text: intl.formatMessage({id: 'USER_MNGT.TABLE.HEAD.EMAIL'}),
      sort: true,
      style: {width: '20%'},
      headerFormatter: headFormatter,
    },
    {
      dataField: 'name',
      text: intl.formatMessage({id: 'USER_MNGT.TABLE.HEAD.NAME'}),
      sort: true,
      style: {width: '20%'},
      headerFormatter: headFormatter,
    },
    {
      dataField: 'lastname',
      text: intl.formatMessage({id: 'USER_MNGT.TABLE.HEAD.LASTNAME'}),
      sort: true,
      style: {width: '20%'},
      headerFormatter: headFormatter,
    },
    {
      dataField: 'marketplaces',
      text: intl.formatMessage({id: 'USER_MNGT.TABLE.HEAD.CONEXIONS'}),
      headerFormatter: headFormatter,
      style: {width: '15%'},
      formatter: (cellContent: any, row: any) => {
        return <MarketplaceConexionTable marketplaces={cellContent} />
      },
    },
    {
      dataField: 'Action user',
      isDummyField: true,
      headerFormatter: headFormatter,
      style: {width: '15%'},
      text: intl.formatMessage({id: 'USER_MNGT.ACTION'}),
      formatter: formatterActions,
    },
  ]

  const customTotal = (from: number, to: number, size: number) => (
    <CustomTotal from={from} to={to} size={size} />
  )

  /**
   * Clients lis processor
   * Mapping all the list to made some fixes
   */
  const dataTable = list.map((client: any) => {
    return {
      id: client.id,
      name: client.name,
      lastname: client.lastname,
      username: client.username,
      email: client.email,
      picture: client.contactProfiles[0] !== undefined ? client.contactProfiles[0].picture : '',
      marketplaces: client.marketplaces,
    }
  })

  return (
    <>
      <TableClient
        columns={columns}
        listClient={dataTable}
        optionsTable={optionsTable(customTotal, intl.locale, 3)}
      />
    </>
  )
}

export {TableClientWrapper}
