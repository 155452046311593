import {FC} from 'react'
import {useIntl} from 'react-intl'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import {ToolkitContextType} from 'react-bootstrap-table2-toolkit'
//@ts-ignore
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css'
import {CustomTotal} from '../../../../_gws/partials/tables/CustomTotal'
import {CustomSearchForm} from '../../../../_gws/partials/tables/CustomSearchForm'
import {headFormatter} from '../../../../_gws/partials/tables/TableHelper'
import {ProductWithImage} from '../../../../_gws/partials/misc'
import styled from '@emotion/styled'
import Form from 'react-bootstrap/Form'
import { addCommasInNumber } from '../../../../_gws/helpers/misc'
type Props = {
  dataTable?: any
}

type PaginationItemProps = {
  active: boolean
}

const PaginationItem = styled('div')<PaginationItemProps>(({active}) => ({
  padding: '0 12px',
  height: '30px',
  textAlign: 'center',
  display: 'flex',
  boxSizing: 'border-box',
  alignItems: 'center',
  fontSize: '13px',
  minWidth: '30px',
  color: active ? '#0D7CBD' : '#212121',
  border: `1px solid ${active ? '#0D7CBD' : '#212121'}`,
  borderRadius: '5px',
  margin: '0px 3px',
  cursor: 'pointer',
  transition: 'all ease .3s',
}))

const TableOrders: FC<Props> = ({dataTable}) => {
  const intl = useIntl()
  function colFormatter(cell: any, row: any) {
    return <span> ${addCommasInNumber(cell)} </span>
  }

  const dataColumns = [
    {
      dataField: 'orderNumber',
      text: intl.formatMessage({id: 'REPORT.STATE_RESULT.TABLE_HEAD.ORDER'}),
      sort: true,
      headerFormatter: headFormatter,
    },
    {
      dataField: 'id',
      text: intl.formatMessage({id: 'REPORT.STATE_RESULT.TABLE_HEAD.PRODUCT'}),
      sort: true,
      searchable: false,
      headerFormatter: headFormatter,
      formatter: (cellContent: any, row: any) => {
        return (
          <a href={'/product-tracker/meli/' + row.id} target='_blank' rel='noreferrer'>
            {row.name && row.image ? (
              <ProductWithImage name={row.name} asinId={row.id} imgUrl={row.image} />
            ) : (
              row.id
            )}
          </a>
        )
      },
    },
    {
      dataField: 'quantity',
      text: intl.formatMessage({id: 'REPORT.STATE_RESULT.TABLE_HEAD.QUANTITY'}),
      sort: true,
      searchable: false,
      headerFormatter: headFormatter,
    },
    {
      dataField: 'date',
      text: intl.formatMessage({id: 'REPORT.STATE_RESULT.TABLE_HEAD.DATE'}),
      sort: true,
      searchable: false,
      headerFormatter: headFormatter,
    },
    {
      dataField: 'itemCharges',
      text: intl.formatMessage({id: 'REPORT.STATE_RESULT.TABLE_HEAD.CHARGES'}),
      sort: true,
      searchable: false,
      formatter: colFormatter,
      headerFormatter: headFormatter,
    },
    {
      dataField: 'itemShipment',
      text: intl.formatMessage({id: 'REPORT.STATE_RESULT.TABLE_HEAD.SHIPMENT'}),
      sort: true,
      searchable: false,
      formatter: colFormatter,
      headerFormatter: headFormatter,
    },
    {
      dataField: 'itemDiscount',
      text: intl.formatMessage({id: 'REPORT.STATE_RESULT.TABLE_HEAD.DISC'}),
      sort: true,
      searchable: false,
      formatter: colFormatter,
      headerFormatter: headFormatter,
    },
    {
      dataField: 'itemTaxes',
      text: intl.formatMessage({id: 'REPORT.STATE_RESULT.TABLE_HEAD.TAX'}),
      sort: true,
      searchable: false,
      formatter: colFormatter,
      headerFormatter: headFormatter,
    },
    {
      dataField: 'itemTotal',
      text: intl.formatMessage({id: 'REPORT.STATE_RESULT.TABLE_HEAD.TOTAL'}),
      sort: true,
      searchable: false,
      formatter: colFormatter,
      headerFormatter: headFormatter,
    },
  ]

  const customTotal = (from: number, to: number, size: number) => (
    <CustomTotal from={from} to={to} size={size} />
  )

  const sizePerPageRenderer = ({options, currSizePerPage, onSizePerPageChange}: any) => (
    <Form.Select
      size='sm'
      value={currSizePerPage}
      onChange={(e) => onSizePerPageChange(e.target.value)}
      style={{width: '70px'}}
    >
      {options.map((option: any) => (
        <option key={option.page} value={option.page}>
          {option.text}
        </option>
      ))}
    </Form.Select>
  )

  const pageButtonRenderer = ({page, active, onPageChange}: any) => {
    const handleClick = (e: any) => {
      e.preventDefault()
      onPageChange(page)
    }
    return (
      <PaginationItem active={active} onClick={handleClick}>
        {page}
      </PaginationItem>
    )
  }

  const options = {
    pageButtonRenderer,
    sizePerPageRenderer,
    paginationTotalRenderer: customTotal,
    showTotal: false,
    disablePageTitle: true,
  }

  return (
    <ToolkitProvider
      columns={dataColumns}
      data={dataTable}
      keyField={'orderNumber'}
      search={{
        searchFormatted: true,
      }}
    >
      {(props: ToolkitContextType) => (
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <h4>{intl.formatMessage({id: 'REPORT.SALES.ORDERS'})}</h4>
              <p>{intl.formatMessage({id: 'REPORT.STATE_RESULT.TABLE_DESCRIPTION'})}</p>
            </div>
            <CustomSearchForm
              dataToExport={dataTable}
              placeholder={intl.formatMessage({id: 'REPORT.INVENTORY.SEARCH_TERM'})}
              {...props.searchProps}
            />
          </div>
          <div className='separator separator-line my-5'></div>
          <BootstrapTable
            {...props.baseProps}
            bootstrap4={true}
            striped={false}
            hover={true}
            pagination={paginationFactory(options)}
            classes={'table table-row-line table-row-gray-300 gy-5 gs-5'}
            wrapperClasses={'table-responsive position-relative'}
            bodyClasses={'text-gray-600 fw-semibold'}
          />
        </div>
      )}
    </ToolkitProvider>
  )
}

export {TableOrders}
