/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useLayout} from '../core'
import styled from '@emotion/styled'

const Container = styled('div')({
  background: "#FFFFFF"
})


const Footer: FC = () => {
  const {classes} = useLayout()
  return (
    <Container className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className='text-dark order-2 order-md-1 m-auto'>
          <span className='text-gray-900 fw-regular me-2'>
            Copyright {new Date().getFullYear()}
          </span>
          <a href='#' className='text-primary-dark text-hover-light-primary'>
            &copy; Growker
          </a>
        </div>
      </div>
    </Container>
  )
}

export {Footer}
