import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {MerchantHeader} from './components/MerchantHeader'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import {Splash} from '../../../../../_gws/helpers/misc'
import {getUsers, getUsersAssociated} from './core/_requests'
import {User, UserAssociated} from './core/_models'
import {UsersTableAssigns} from './components/UsersTableAssigns'

const UsersAssociatedListWrapper: FC = () => {
  const intl = useIntl()
  const Alert = withReactContent(Swal)

  /**
   * URL Params
   */
  const params = useParams()
  /**
   * Used to set the list of all users
   */
  const [listUsers, setListUsers] = useState<User>()
  /**
   * Used to set the list of Associated Users to current user id
   */
  const [activeUsers, setActiveUsers] = useState<UserAssociated>()

  /**
   * It fetches all the users from the backend and sets the list of users in the
   * state.
   */
  const fetchAllUsers = async () => {
    try {
      Splash(true)
      const {data} = await getUsers()
      if (data) {
        setListUsers(data.detail.users)
        Splash(false)
      }
    } catch (error: any) {
      Alert.fire({
        title: intl.formatMessage({id: 'ERROR.BACKEND.FETCH'}),
        text: intl.formatMessage({id: 'USER_MNGT.ALERT.UNABLE_USER'}),
        icon: 'error',
        iconColor: '#00c6c7',
        buttonsStyling: false,
        confirmButtonText: intl.formatMessage({id: 'ACCOUNT.PROFILE.DETAILS.OK_GOT_IT'}),
        customClass: {
          confirmButton: 'btn btn-primary',
          popup: 'bg-light',
        },
      }).then(() => {
        Splash(false)
      })
    }
  }

  /**
   * Api request for users associated to current user ID
   * @param userID
   */
  const fetchUsersAssociated = async (userID: number) => {
    try {
      Splash(true)
      const {data} = await getUsersAssociated(userID)
      if (data) {
        setActiveUsers(data.detail)
        Splash(false)
      }
    } catch (error: any) {
      Alert.fire({
        title: intl.formatMessage({id: 'ERROR.BACKEND.FETCH'}),
        text: intl.formatMessage({id: 'USER_MNGT.ALERT.UNABLE_USER'}),
        icon: 'error',
        iconColor: '#00c6c7',
        buttonsStyling: false,
        confirmButtonText: intl.formatMessage({id: 'ACCOUNT.PROFILE.DETAILS.OK_GOT_IT'}),
        customClass: {
          confirmButton: 'btn btn-primary',
          popup: 'bg-light',
        },
      }).then(() => {
        Splash(false)
      })
    }
  }

  useEffect(() => {
    fetchAllUsers()
    fetchUsersAssociated(Number(params.userId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <MerchantHeader userID={Number(params.userId)} activeUsers={activeUsers} />
      <div className='card'>
        <div className='card-header cursor-pointer border-0'>
          <h3 className='card-title m-0 align-items-start flex-column'>
            <span className='card-label fw-bolder m-0'>
              {intl.formatMessage({id: 'USER_MNGT.TOP.TITLE'})}
            </span>
          </h3>
        </div>
        <div className='card-body pt-0'>
          <UsersTableAssigns
            users={listUsers}
            activeUsers={activeUsers}
            userId={Number(params.userId)}
          />
        </div>
      </div>
    </>
  )
}

export {UsersAssociatedListWrapper}
