import styled from '@emotion/styled'

export const Container = styled('div')({
  border: '1px solid red',
})

// ---------------- Indicators ---------------------//
type IndicatorProp = {
  bg?: string
}

export const IndicatorsWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
  gap: '1rem',
  padding: '10px',
  borderRadius: '10px',
  boxShadow: '-1px 14px 19px -4px #919EAB33',
})

export const IndicatorCard = styled('div')<IndicatorProp>(({bg}) => ({
  background: bg,
  borderRadius: '8px',
  fontSize: '1.5rem',
  fontWeight: 'bold',
  display: 'grid',
  placeItems: 'center',
  height: '100px',
  textAlign: 'center',
}))
// ---------------- Indicators ---------------------//


// ---------------- Campaing Table ---------------------//
export const TableCard = styled('div')({
  padding: "20px",
  borderRadius: "10px",
  boxShadow: '-1px 14px 19px -4px #919EAB33',
  marginTop: "2rem",
  p: {
    color: "#9E9E9E"
  },
})
// ---------------- Campaing Table ---------------------//