import Lottie from 'lottie-react'
import LoadingAnimation from '../../../../_gws/assets/lottie/splash.json'
import {FC} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  height: any
}
/**
 *
 * @param height
 * @constructor
 */
const ModalLoading: FC<Props> = ({height}) => {
  const intl = useIntl()
  return (
    <div
      className='text-gray-500 fs-5 text-center d-flex justify-content-center'
      style={{height: height === undefined ? 70 : height + 'vh'}}
    >
      <div className='align-self-center' style={{marginTop: '-50px'}}>
        <Lottie animationData={LoadingAnimation} style={{height: '120px', width: '120px'}} />
        <div style={{fontSize: '14px', color: '#3d3d3d'}}>
          {intl.formatMessage({id: 'GENERAL.LOADING'})}
        </div>
        <div style={{fontSize: '10px', color: '#00c6c7'}}>
          {intl.formatMessage({id: 'AUTH.GENERAL.PLEASE_WAIT'})}
        </div>
      </div>
    </div>
  )
}

export {ModalLoading}
