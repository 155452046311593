import React, {useState} from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import {KTSVG, toAbsoluteUrl} from '../../../../_gws/helpers'
import styled from '@emotion/styled'
import {IconSort} from './PlayListIcons'
import {FieldSort, FieldSortType} from '../types'
import { useIntl } from 'react-intl'

const styleMenu = {
  padding: '20px',
  width: '370px',
  background: '#fff',
  boxShadow: '1px 2px 4px 2px #0000001A',
}

const CustomToggle = React.forwardRef(({children, onClick}: any, ref: any) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    }}
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
  >
    <KTSVG
      path={toAbsoluteUrl('media/icons/gws-icons/sort_by_alpha.svg')}
      className={`svg-icon-1x svg-icon-dark`}
    />
    {children}
  </div>
))

export const FilterAction = styled('span')({
  marginLeft: '8px',
  cursor: 'pointer',
})

const InitialState: FieldSort[] = [
  {
    field: 'type',
    label: 'Origen',
    sort: 'asc',
  },
  {
    field: 'marketplaceId',
    label: 'Marketplace',
    sort: 'asc',
  },
  {
    field: 'priority',
    label: 'Prioridad',
    sort: 'asc',
  },
  {
    field: 'status',
    label: 'Estatus',
    sort: 'asc',
  },
  {
    field: 'deadline',
    label: 'Fecha límite',
    sort: 'asc',
  },
  {
    field: 'createdAt',
    label: 'Fecha de creación',
    sort: 'asc',
  },
]

export const PlaylistSort = ({sortItems}: {sortItems: any}) => {
  const intl = useIntl()
  const [sorts, setSorts] = useState<FieldSort[]>(InitialState)

  const handlerSort = (field: FieldSortType, sort: 'asc' | 'desc') => {
    const update: FieldSort[] = sorts.map((item: FieldSort) => {
      if (item.field === field) {
        return {
          ...item,
          sort: item.sort === 'asc' ? 'desc' : 'asc',
        }
      }
      return item
    })
    setSorts(update)
    sortItems({
      field: field,
      sort: sort === 'asc' ? 'desc' : 'asc',
    })
  }

  return (
    <Dropdown
      style={{
        margin: '0px 20px',
      }}
    >
      <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
        {intl.formatMessage({id: 'PLAYLIST.SORT'})}
      </Dropdown.Toggle>

      <Dropdown.Menu
        style={{
          ...styleMenu,
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
            gap: '1rem',
          }}
        >
          {sorts.map((item) => (
            <div key={item.field}>
              <span>{item.label}</span>
              <FilterAction onClick={() => handlerSort(item.field, item.sort)}>
                <IconSort sort={item.sort} />
              </FilterAction>
            </div>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}
