import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'

type PropsSelectOption = {
  text?: any
}

const SelectOption: React.FC<PropsSelectOption> = ({text}) => {
  return (
    <>
      <div className='p-5 pt-3'>
        <span className='btn btn-icon pe-none btn-light pulse me-4'>
          <KTSVG
            path={toAbsoluteUrl('/media/icons/unicos/095-up-arrow.svg')}
            className={`svg-icon svg-icon-8 upanddown`}
          />
          <span className='pulse-ring border-5'></span>
        </span>
        <span className='d-inline-block text-gray-600 '>{text}</span>
      </div>
    </>
  )
}

export {SelectOption}
