import React, {useState} from 'react'
import {Months, Task} from '../../../types'
import {differenceInDays, getMonth} from 'date-fns'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {IconChecked, IconLimite, IconUnStatus} from '../../PlayListIcons'
import {updatePlayListTask} from '../../../core/_requests'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const AsingDeadLine = () => {
  return <div>N/A</div>
}

const CustomInput = React.forwardRef(
  ({value, onClick, status, diffDays, deadline}: any, ref: any) => {
    const statusDeadline: any = {
      3: <IconChecked />,
      2: diffDays < 3 ? <IconLimite /> : <IconUnStatus />,
      1: diffDays < 3 ? <IconLimite /> : <IconUnStatus />,
    }
    const day = new Date(deadline)
      .toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .split('/')[1]

    return (
      <div
        ref={ref}
        onClick={onClick}
        style={{
          cursor: 'pointer',
        }}
      >
        {statusDeadline[status]}
        {`${day} ${Months[getMonth(deadline) + 1]}`}
      </div>
    )
  }
)

const ShowDeadLine = ({
  deadline,
  status,
  item,
  updateTasksAction,
  indexTable,
}: {
  deadline: Date
  status: number
  item: Task
  updateTasksAction: any
  indexTable: number
}) => {
  const [startDate, setStartDate] = useState<Date>(new Date(deadline))
  const today = new Date()
  const diffDays = differenceInDays(deadline, today)

  const handlerUpdateDeadline = async (date: Date) => {
    setStartDate(date)
    const response = await updatePlayListTask({
      id: item.id,
      deadline: new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    })
    if (response.status === 200) {
      updateTasksAction(
        [
          {
            ...item,
            deadline: new Date(date).toLocaleDateString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }),
          },
        ],
        true,
        indexTable
      )
    }
  }

  return (
    <DatePicker
      customInput={<CustomInput status={status} diffDays={diffDays} deadline={deadline} />}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {'<'}
          </button>
          <select
            value={months[getMonth(date)]}
            onChange={({target: {value}}) => changeMonth(months.indexOf(value))}
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {'>'}
          </button>
        </div>
      )}
      selected={startDate}
      onChange={(date: any) => handlerUpdateDeadline(date)}
    />
  )
}

export const RenderDeadline = ({
  item,
  updateTasksAction,
  indexTable,
}: {
  item: Task
  updateTasksAction: any
  indexTable: number
}) => {
  const DeadLine: unknown = item.deadline
  return (
    <>
      {item.deadline && (
        <ShowDeadLine
          updateTasksAction={updateTasksAction}
          item={item}
          deadline={DeadLine as Date}
          status={item.status}
          indexTable={indexTable}
        />
      )}
      {!item.deadline && <AsingDeadLine />}
    </>
  )
}
