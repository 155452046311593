import axios, {AxiosResponse} from 'axios'
import {getTokenHeader} from '../../../modules/auth'

const API_URL_CRUNCH = `${process.env.REACT_APP_BACKEND_MAIN_API}/api`

const USER_URL = `${API_URL_CRUNCH}/users`

/**
 * It gets the dashboard data, it returns a promise that resolves to an object
 */
const getDashboardData = (): Promise<any> => {
  const axiosClean = axios.create()
  return axiosClean.get(
    `${API_URL_CRUNCH}/growmmary`,
    //@ts-ignore
    getTokenHeader()
  )
}
export {getDashboardData}

export const getLastElementsPlaylist = (): Promise<any> => {
  const axiosClean = axios.create()
  return axiosClean.get(
    `${API_URL_CRUNCH}/playlist/last-elements/`,
    //@ts-ignore
    getTokenHeader()
  )
}

/**
 * It gets the client list for a merchant, it returns a promise that resolves to an array of clients
 */
const getlistClients = (): Promise<any> => {
  return axios.get(`${USER_URL}/merchants/clients`).then((d: AxiosResponse<any>) => d.data)
}
export {getlistClients}

const getClientToken = (id: number): Promise<any> => {
  return axios
    .post(`${USER_URL}/merchants/clients/token`, {filter: `${id}`})
    .then((d: AxiosResponse<any>) => d.data)
}
export {getClientToken}
