/* eslint-disable jsx-a11y/anchor-is-valid */

// this component is the glossary main, and contain the all card items and Pagination.

import {useState, useEffect} from 'react'
import {GlossaryCard} from './components/GlossaryCard'
import {ModalLoading} from '../inventoryReport/components/ModalLoading'
import {GlossaryFilters} from './components/GlossaryFilters'
import {AgTitleH2} from '../../../_gws/layout/components/ag/components/AgTitleH2'
import styled from '@emotion/styled'
import {mkProp} from '../../modules/auth'

type Props = {
  dataGlossary?: any
  isLoading: boolean
  mkplace: mkProp
}

const Container = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
  gap: '1rem',
})

/**
 * This Component create a Glossary Component and has GlossaryFilter, GlossaryCard and Pagination
 *
 * @param dataGlossary - array of objects with data. Each object should have icon, title, marketplace, description, reports.
 * @param isLoading - booleano, when is true show de ModalLoading component.
 */

const Glossary: React.FC<Props> = ({dataGlossary = [], isLoading, mkplace}) => {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<number[]>([])
  const [searchInput, setSearchInput] = useState('')
  const [filteredData, setFilteredData] = useState<any[]>([])
  const [hasResults, setHasResults] = useState(true)

  /**
   * This function has the logic to compare an array with the item. Market and the array of selected Checkboxes
   */
  function arrayEquals(a: number[], b: number[]) {
    if (a.length !== b.length) {
      return false
    }

    const sortedA = a.slice().sort()
    const sortedB = b.slice().sort()

    for (let i = 0; i < sortedA.length; i++) {
      if (sortedA[i] !== sortedB[i]) {
        return false
      }
    }

    return true
  }

  /**
   * This function has the logic for the normalization of the search word
   */

  function normalizeString(str: string): string {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
  }

  /**
   * this useEffect has 2 logic, firts if searchItem change and then filter with marketplace changes.
   */
  useEffect(() => {
    let filteredItems = dataGlossary
    if (searchInput) {
      const normalizedSearchInput = normalizeString(searchInput)
      filteredItems = filteredItems.filter(
        (item: any) =>
          normalizeString(item.title).includes(normalizedSearchInput) ||
          normalizeString(item.definition).includes(normalizedSearchInput)
      )
    }
    if (selectedCheckboxes.length > 0) {
      filteredItems = filteredItems.filter((item: any) =>
        selectedCheckboxes.every((selected) => arrayEquals(item.marketplace, selectedCheckboxes))
      )
    }
    setFilteredData(filteredItems)
    setHasResults(filteredItems.length > 0)
  }, [dataGlossary, selectedCheckboxes, searchInput])

  /**
   * this function has the Loading logic.
   */
  if (isLoading) {
    return <ModalLoading height={50} />
  }

  /**
   * this function has the Pagination logic
   */

  const visibleItems = Array.isArray(filteredData) ? filteredData : dataGlossary

  return (
    <div>
      <GlossaryFilters
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        selectedCheckboxes={selectedCheckboxes}
        setSelectedCheckboxes={setSelectedCheckboxes}
        mkplace={mkplace}
      />
      {!hasResults && <AgTitleH2 text={'Sin Coincidencias'} />}
      <Container>
        {visibleItems.map((e: any) => (
          <GlossaryCard
            key={e.id}
            icon={e.icon}
            title={e.title}
            marketplace={e.marketplace}
            description={e.definition}
            reports={e.reports}
          />
        ))}
      </Container>
    </div>
  )
}

export {Glossary}
