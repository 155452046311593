import {FC} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {MarketplaceButton} from './MarkeplaceButton'

type Props = {
  mkp: any
  handleClick?: any
  tooltip?: string
  comingSoon?: boolean
}
const MarketPlacePill: FC<Props> = ({mkp, handleClick, tooltip, comingSoon}) => {
  return (
    <>
      {tooltip ? (
        <OverlayTrigger
          placement='top'
          overlay={<Tooltip id={`tooltip-top`}>{tooltip && `${mkp.name} - ${tooltip}`}</Tooltip>}
          defaultShow={false}
        >
          <div data-balloon-pos='top' aria-label={tooltip && tooltip}>
            <MarketplaceButton mkp={mkp} handleClick={handleClick} comingSoon={comingSoon} />
          </div>
        </OverlayTrigger>
      ) : (
        <MarketplaceButton mkp={mkp} handleClick={handleClick} comingSoon={comingSoon} />
      )}
    </>
  )
}

export {MarketPlacePill}
