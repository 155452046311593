/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {AgTextGeneralBigBold} from '../../../../_gws/layout/components/ag'
import {urlGlossary} from '../../../../_gws/helpers/miscConst'
import Accordion from 'react-bootstrap/Accordion'
import styled from '@emotion/styled'
import './accordion.css'
import {KTSVG, toAbsoluteUrl} from '../../../../_gws/helpers'
/**
 * This Component create a GlossaryCard Component, and is the individual card rendered for each metric
 *
 * @param title - title of metric
 * @param icon - icon of metric.
 * @param marketplace - array of related marketplace
 * @param reports - array of related reports
 * @param description - description of metric
 */

type Props = {
  title: string
  icon: string
  marketplace: any
  reports: any
  description: string
}

const AccordionCard = styled(Accordion)({})

const AccordionItem = styled(Accordion.Item)({
  border: 'none',
})

const AccordionBody = styled(Accordion.Body)({
  border: '1px solid #919EAB33',
})

const GlossaryCard: FC<Props> = ({title, icon, marketplace, reports, description}) => {
  const intl = useIntl()

  return (
    <AccordionCard flush>
      <AccordionItem eventKey='0'>
        <Accordion.Header>
          <KTSVG path={toAbsoluteUrl(icon)} className='svg-icon-2x svg-icon-dark me-3' />
          <h4 style={{color: '#212121'}}>{title}</h4>
        </Accordion.Header>
        <AccordionBody>
          <div>{description}</div>

          <div style={{margin: '20px 0px'}}>
            <div style={{marginBottom: '20px'}}>
              <AgTextGeneralBigBold text={'Reportes relacionados'} />
            </div>
            {reports.map((report: string) => (
              <a
                key={report}
                href={urlGlossary[report]}
                className='card-link text-primary-dark text-decoration-underline'
                style={{marginRight: '20px'}}
              >
                {intl.formatMessage({id: `MENU.REPORTS.${report}`})}
              </a>
            ))}
          </div>
        </AccordionBody>
      </AccordionItem>
    </AccordionCard>
  )
}

export {GlossaryCard}
