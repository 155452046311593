import { IconAmazon, IconMeli } from "../../PlayListIcons"

export const RenderMarketplace = ({marketplace}: {marketplace: number}) => {
  const flex = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
  const typeMarketplace: any = {
    1: <IconAmazon />,
    2: <IconMeli />,
  }
  return <div style={{...flex}}>{typeMarketplace[marketplace]}</div>
}
