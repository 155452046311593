import {useIntl} from 'react-intl'
import {useListView} from '../core/ListViewProvider'
import {KTSVG} from '../../../../../../_gws/helpers'

const UserEditModalHeader = () => {
  const {setItemIdForUpdate, itemIdForUpdate} = useListView()
  const intl = useIntl()
  return (
    <div className='modal-header border-0'>
      <h2 className='fw-bolder'>
        {itemIdForUpdate
          ? intl.formatMessage({id: 'USER_MNGT.MODAL.HEAD.EDIT_USER'})
          : intl.formatMessage({id: 'USER_MNGT.MODAL.HEAD.NEW_USER'})}
      </h2>
      <button
        type='button'
        data-bs-dismiss='modal'
        className='btn btn-icon btn-sm btn-active-icon-primary'
        onClick={() => setItemIdForUpdate(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/unicos/008-exit.svg' className='svg-icon-1' />
      </button>
    </div>
  )
}

export {UserEditModalHeader}
