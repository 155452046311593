import React, {useEffect, useState} from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'
import {Button} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {IconPriority, IconStock, IconStore} from '../../playlist/components/PlayListIcons'
import {Line, WrapperColumn} from './styled'
import {ParamsType} from '../core/getBrandhealthIndicators'
import {useNavigate, useSearchParams} from 'react-router-dom'

const CustomToggle = React.forwardRef(({children, onClick}: any, ref: any) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      padding: '10px',
    }}
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
  >
    <svg width='15' height='15' viewBox='0 0 15 15' fill='none'>
      <mask id='mask0_33_106' maskUnits='userSpaceOnUse' x='0' y='0' width='15' height='15'>
        <rect width='15' height='15' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_33_106)'>
        <path
          d='M6.59856 10.625V10H8.38942V10.625H6.59856ZM4.2548 7.8125V7.1875H10.7332V7.8125H4.2548ZM2.5 5V4.375H12.5V5H2.5Z'
          fill='#212121'
        />
      </g>
    </svg>
    {children}
  </div>
))

const RenderCheckboxs = ({handlerFilters, field, value, label, checked}: InputParams) => {
  return (
    <div style={{...flex, marginLeft: '10px'}}>
      <input
        onChange={() => handlerFilters(field, value)}
        style={{marginRight: '5px'}}
        type='checkbox'
        checked={checked}
      />
      <span>{label}</span>
    </div>
  )
}

const flex = {
  display: 'flex',
  alignItems: 'center',
}

const styleMenu = {
  padding: '20px',
  width: '300px',
  background: '#fff',
  boxShadow: '1px 2px 4px 2px #0000001A',
}

type Field = 'item_type_id' | 'health_status' | 'marketplace_id' | 'stockless'

export type ReferenceType = {
  value: number
  label: string
  flag: boolean
}

export type StateFilters<t> = {
  field: Field
  value: t
}

type Input<t> = StateFilters<t> & {
  label: string
  checked: boolean
}

type InputsType = {
  mkp: Input<number>[]
  priority: Input<number>[]
  stockless: Input<boolean>[]
}

type InputParams = {
  handlerFilters: any
  field: Field
  value: number | string | boolean | null
  label: string
  checked: boolean
}

const generateBrandHealthPath = ({
  initialMkp,
  category,
  priority,
  type,
  mkpFilter,
  stockless
}: {
  initialMkp: string
  category: string
  priority: string | null
  type: string | null
  mkpFilter: string | null
  stockless: string | null
}) => {
  const BASE_MKP = `/brand-health/${initialMkp}`
  const BASE_CATEGORY = `/category_${category}/?`
  const PRIORITY = priority ? `&priority=${priority}` : ``
  const MKP_FILTER = mkpFilter ? `&marketplaceFilter=${mkpFilter}` : ``
  const OUTPUT = type ? `&output=${type}` : ``
  const STOCKLESS = stockless !== null ? `&stockless=${stockless}` : ``
  const URL = `${BASE_MKP}${BASE_CATEGORY}${MKP_FILTER}${OUTPUT}${PRIORITY}${STOCKLESS}`
  return URL
}

export const Filters = ({
  references,
  paramsObj,
}: {
  references: ReferenceType[]
  paramsObj: ParamsType
}) => {
  const intl = useIntl()
  const [searchParams] = useSearchParams()
  const navigation = useNavigate()
  const Inputs: InputsType = {
    mkp: [
      {
        field: 'marketplace_id',
        value: 1,
        label: 'Amazon',
        checked: searchParams.get('marketplaceFilter') === '1',
      },
      {
        field: 'marketplace_id',
        value: 2,
        label: 'Mercado Libre',
        checked: searchParams.get('marketplaceFilter') === '2',
      },
    ],
    priority: [
      {
        field: 'health_status',
        value: 1,
        label: 'Critico',
        checked: searchParams.get('priority') === '1',
      },
      {
        field: 'health_status',
        value: 2,
        label: 'Delicado',
        checked: searchParams.get('priority') === '2',
      },
    ],
    stockless: [
      {
        field: 'stockless',
        value: true,
        label: 'Sin Stock',
        checked: searchParams.get('stockless') === 'true',
      },
      {
        field: 'stockless',
        value: false,
        label: 'Con Stock',
        checked: searchParams.get('stockless') === 'false',
      },
    ],
  }

  const [inputs, setInputs] = useState(Inputs)

  useEffect(() => {
    setInputs(Inputs)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsObj])

  const handlerFilters = (field: Field, value: number | boolean) => {
    const tableFiltersPathData = {
      initialMkp: paramsObj.initialMkp,
      category: paramsObj.category,
      priority: searchParams.get('priority'),
      type: searchParams.get('output'),
      mkpFilter: searchParams.get('marketplaceFilter'),
      stockless: searchParams.get('stockless'),
    }
    const valueString = value.toString()
    if (field === 'health_status') {
      tableFiltersPathData.priority =
        tableFiltersPathData.priority === valueString ? null : valueString
    }
    if (field === 'marketplace_id') {
      tableFiltersPathData.mkpFilter =
        tableFiltersPathData.mkpFilter === valueString ? null : valueString
    }
    if (field === 'item_type_id') {
      tableFiltersPathData.type = valueString
    }
    if (field === 'stockless') {
      tableFiltersPathData.stockless =
        tableFiltersPathData.stockless === valueString ? null : valueString
    }
    const pathname = generateBrandHealthPath(tableFiltersPathData)
    navigation(pathname)
  }

  const handlerResetFilters = () => {
    const pathname = generateBrandHealthPath({
      initialMkp: paramsObj.initialMkp,
      category: paramsObj.category,
      priority: null,
      type: null,
      mkpFilter: null,
      stockless: null,
    })
    navigation(pathname)
  }

  const MkpColumn = {
    icons: <IconStore />,
    title: 'Marketplace',
    filters: inputs.mkp,
  }
  const PriorityColumn = {
    icons: <IconPriority />,
    title: 'Prioridad',
    filters: inputs.priority,
  }
  const StockColumn = {
    icons: <IconStock />,
    title: 'Stock',
    filters: inputs.stockless,
  }

  const columns = [PriorityColumn, MkpColumn, StockColumn]

  const options = [...references]
  options.push({
    flag: false,
    label: 'Todos',
    value: 0,
  })
  return (
    <>
      {columns.length > 0 ? (
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle}>
            {intl.formatMessage({id: 'PLAYLIST.FILTER'})}
          </Dropdown.Toggle>

          <Dropdown.Menu style={{...styleMenu}}>
            <div>
              <h4>{intl.formatMessage({id: 'PLAYLIST.FILTER'})}</h4>
              <Form.Select
                onChange={(e: any) => handlerFilters('item_type_id', e.target.value)}
                size='sm'
                value={searchParams.get('output') || 1}
              >
                {options.map((item: ReferenceType) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Form.Select>
            </div>

            <WrapperColumn>
              {columns.map((item, i) => (
                <div key={i}>
                  <div style={{...flex}}>
                    {item.icons}
                    <h6 style={{marginTop: '5px', marginLeft: '5px'}}>{item.title}</h6>
                  </div>
                  {item.filters.map((item, i) => (
                    <RenderCheckboxs
                      key={i}
                      value={item.value}
                      label={item.label}
                      field={item.field}
                      checked={item.checked}
                      handlerFilters={handlerFilters}
                    />
                  ))}
                </div>
              ))}
            </WrapperColumn>
            <Line />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                style={{border: '1px solid #D6D6D6', marginRight: '10px'}}
                variant='outlined'
                size='sm'
                onClick={handlerResetFilters}
              >
                {intl.formatMessage({id: 'BRAND_HEALTH.FILTERS.CLEAR'})}
              </Button>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      ) : null}
    </>
  )
}
