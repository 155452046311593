import React, {FC, useEffect} from 'react'
import {LandedCostEditModalHeader} from './LandedCostEditModalHeader'
import {LandedCostEditModalFormWrapper} from './LandedCostEditModalFormWrapper'

type Props = {
  height?: number
}

/**
 * Modal to edit a playlist
 * @param height
 * @constructor
 */
const LandedCostEditModal: FC<Props> = ({height}) => {
  /**
   * Handle the class of the modal to show/hide
   */
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_landed_cost'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <LandedCostEditModalHeader />
            <div className='modal-body scroll-y mx-5 pb-0 position-relative'>
              <LandedCostEditModalFormWrapper height={height} />
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export {LandedCostEditModal}
