import React, {FC} from 'react'
import {StatisticsWidget7} from '../../../../../_gws/partials'
import {FrankieTableWrapper} from '../FrankieTable/FrankieTableWrapper'
import {useIntl} from 'react-intl'

type Props = {
  sales: any
  mkp: string | undefined
}
const FrankieSalesWrapper: FC<Props> = ({sales, mkp}) => {
  const intl = useIntl()
  const listConditionsOrders: any = Array.from(
    new Set(
      sales?.orderList?.map((dat: any) => {
        return dat.state
      })
    )
  )
  const listConditionsCancelledOrders: any = Array.from(
    new Set(
      sales?.cancelledOrderList?.map((dat: any) => {
        return dat.state
      })
    )
  )

  return (
    <>
      <div className='row'>
        <div className='col-md-6 mt-3'>
          <div className='row mt-5'>
            <div className='col-12'>
              <h5 className='card-title align-items-start flex-column'>
                <span className='card-label fw-semibold fs-3 mb-1 ps-5'>{`${intl.formatMessage({
                  id: 'REPORT.FRANKIE.SALES.ORDERS.30',
                })}`}</span>
              </h5>
            </div>
          </div>
          <div className='row'>
            <div className='col-6 mt-3 mb-1'>
              <StatisticsWidget7
                className={'card-xl-stretch'}
                iconColor={'dark'}
                titleColor={'dark'}
                descriptionColor={'dark'}
                title={sales.orderAmount}
                description={`${intl.formatMessage({id: 'REPORT.SALES.ORDERS'})}`}
                height={'150px'}
                countUp={true}
              />
            </div>
            <div className='col-6 mt-3'>
              <StatisticsWidget7
                className={'card-xl-stretch'}
                iconColor={'dark'}
                titleColor={'dark'}
                descriptionColor={'dark'}
                title={sales.totalProfit}
                description={`${intl.formatMessage({id: 'REPORT.SALES.TOTAL_SALES'})}`}
                height={'150px'}
                countUp={true}
                prefix={'$'}
              />
            </div>
          </div>
          <div className='row mt-5'>
            <div className='col'>
              <FrankieTableWrapper
                listProduct={sales.orderList}
                mkp={mkp}
                title={`${intl.formatMessage({id: 'REPORT.FRANKIE.SALES.ORDERS.LIST'})}`}
                listConditions={listConditionsOrders}
                sales={true}
              />
            </div>
          </div>
        </div>
        <div className='col-md-6 mt-3'>
          <div className='row mt-5'>
            <div className='col-12'>
              <h5 className='card-title align-items-start flex-column'>
                <span className='card-label fw-semibold fs-3 mb-1 ps-5'>{`${intl.formatMessage({
                  id: 'REPORT.FRANKIE.SALES.ORDERS.CANCELLED',
                })}`}</span>
              </h5>
            </div>
          </div>
          <div className='row'>
            <div className='col-6 mt-3 mb-1'>
              <StatisticsWidget7
                className={'card-xl-stretch'}
                iconColor={'dark'}
                titleColor={'dark'}
                descriptionColor={'dark'}
                title={sales.cancelledOrderAmount}
                description={`${intl.formatMessage({id: 'REPORT.SALES.ORDERS'})}`}
                height={'150px'}
                countUp={true}
              />
            </div>
            <div className='col-6 mt-3'>
              <StatisticsWidget7
                className={'card-xl-stretch'}
                iconColor={'dark'}
                titleColor={'dark'}
                descriptionColor={'dark'}
                title={sales.cancelledPercent}
                description={`${intl.formatMessage({id: 'REPORT.FRANKIE.SALES.ORDERS.CANCELLED'})}`}
                height={'150px'}
                metrix={'%'}
                countUp={true}
              />
            </div>
          </div>
          <div className='row mt-5'>
            <div className='col'>
              <FrankieTableWrapper
                listProduct={sales.cancelledOrderList}
                mkp={mkp}
                title={`${intl.formatMessage({id: 'REPORT.FRANKIE.SALES.ORDERS.CANCELLED.LIST'})}`}
                listConditions={listConditionsCancelledOrders}
                sales={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {FrankieSalesWrapper}
