export const IconStock = () => {
  return (
    <svg width='60' height='60' viewBox='0 0 45 45' fill='none'>
      <g clipPath='url(#clip0_269_77)'>
        <path
          d='M8.26127 27.2306L14.64 20.8519L19.9425 26.1562L25.8619 20.2369L22.5 16.875H31.875V26.25L28.5131 22.8881L19.9425 31.4587L14.64 26.1562L9.99939 30.795C12.1058 33.9654 15.3387 36.2164 19.043 37.0919C22.7472 37.9674 26.6458 37.4019 29.9487 35.5101C33.2515 33.6182 35.7117 30.5414 36.8305 26.9033C37.9494 23.2651 37.6433 19.3376 35.9742 15.9168C34.3052 12.4959 31.3981 9.83743 27.842 8.48015C24.2859 7.12286 20.3467 7.16826 16.8229 8.60713C13.299 10.046 10.4539 12.7707 8.86414 16.2292C7.27438 19.6876 7.05887 23.6211 8.26127 27.2325V27.2306ZM5.37752 30.1519L5.35877 30.1331L5.36627 30.1256C4.29738 27.7257 3.74665 25.1272 3.75002 22.5C3.75002 12.1444 12.1444 3.75 22.5 3.75C32.8556 3.75 41.25 12.1444 41.25 22.5C41.25 32.8556 32.8556 41.25 22.5 41.25C14.8688 41.25 8.30627 36.6937 5.37752 30.1519V30.1519Z'
          fill='#2D3436'
        />
      </g>
      <defs>
        <clipPath id='clip0_269_77'>
          <rect width='60' height='60' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const IconCampaigns = () => {
  return (
    <svg width='60' height='60' viewBox='0 0 45 45' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_269_83)'>
        <path
          d='M20.4375 3.9375L38.9981 6.59063L41.6493 25.1531L24.4143 42.3881C24.0627 42.7396 23.5859 42.9371 23.0887 42.9371C22.5915 42.9371 22.1147 42.7396 21.7631 42.3881L3.20059 23.8256C2.84908 23.474 2.65161 22.9972 2.65161 22.5C2.65161 22.0028 2.84908 21.526 3.20059 21.1744L20.4375 3.9375ZM21.7631 7.91625L7.17746 22.5L23.0887 38.4094L37.6725 23.8256L35.685 9.90375L21.7631 7.91625V7.91625ZM25.7381 19.8488C25.0347 19.1451 24.6396 18.1908 24.6398 17.1959C24.6399 16.7033 24.737 16.2155 24.9256 15.7603C25.1142 15.3052 25.3906 14.8917 25.739 14.5434C26.0874 14.1951 26.501 13.9189 26.9562 13.7304C27.4114 13.542 27.8992 13.4451 28.3919 13.4451C29.3868 13.4453 30.3409 13.8407 31.0443 14.5444C31.7477 15.248 32.1428 16.2023 32.1426 17.1972C32.1425 18.1922 31.747 19.1463 31.0434 19.8497C30.3397 20.5531 29.3855 20.9482 28.3905 20.948C27.3956 20.9478 26.4415 20.5524 25.7381 19.8488V19.8488Z'
          fill='#2D3436'
        />
      </g>
      <defs>
        <clipPath id='clip0_269_83'>
          <rect width='60' height='60' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const IconLandedCost = () => {
  return (
    <svg width='60' height='60' viewBox='0 0 45 45' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_269_89)'>
        <path
          d='M7.5 3.75H37.5C37.9973 3.75 38.4742 3.94754 38.8258 4.29917C39.1775 4.65081 39.375 5.12772 39.375 5.625V39.375C39.375 39.8723 39.1775 40.3492 38.8258 40.7008C38.4742 41.0525 37.9973 41.25 37.5 41.25H7.5C7.00272 41.25 6.52581 41.0525 6.17417 40.7008C5.82254 40.3492 5.625 39.8723 5.625 39.375V5.625C5.625 5.12772 5.82254 4.65081 6.17417 4.29917C6.52581 3.94754 7.00272 3.75 7.5 3.75V3.75ZM9.375 7.5V37.5H35.625V7.5H9.375ZM13.125 11.25H31.875V18.75H13.125V11.25ZM13.125 22.5H16.875V26.25H13.125V22.5ZM13.125 30H16.875V33.75H13.125V30ZM20.625 22.5H24.375V26.25H20.625V22.5ZM20.625 30H24.375V33.75H20.625V30ZM28.125 22.5H31.875V33.75H28.125V22.5Z'
          fill='#2D3436'
        />
      </g>
      <defs>
        <clipPath id='clip0_269_89'>
          <rect width='60' height='60' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const IconMkpFee = () => {
  return (
    <svg width='60' height='60' viewBox='0 0 45 45' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_269_95)'>
        <path
          d='M36.3281 28.3181C37.5447 25.4274 37.8247 22.2281 37.1289 19.17C36.433 16.1119 34.7962 13.3488 32.4486 11.2692C30.1009 9.18956 27.1606 7.89797 24.0409 7.57603C20.9212 7.25408 17.779 7.91797 15.0562 9.47435L13.1962 6.21747C16.0418 4.59113 19.2641 3.73941 22.5416 3.74731C25.8191 3.7552 29.0373 4.62244 31.875 6.26247C40.2937 11.1225 43.5187 21.5287 39.5943 30.2062L42.1106 31.6575L34.3012 35.8087L33.9918 26.97L36.3281 28.3181V28.3181ZM8.67183 16.6818C7.45522 19.5725 7.17517 22.7718 7.87105 25.8299C8.56692 28.888 10.2037 31.6511 12.5513 33.7308C14.899 35.8104 17.8393 37.102 20.959 37.4239C24.0787 37.7459 27.2209 37.082 29.9437 35.5256L31.8037 38.7825C28.9581 40.4088 25.7358 41.2605 22.4583 41.2526C19.1808 41.2447 15.9626 40.3775 13.125 38.7375C4.7062 33.8775 1.4812 23.4712 5.40558 14.7937L2.88745 13.3443L10.6968 9.1931L11.0062 18.0318L8.66995 16.6837L8.67183 16.6818ZM15.9375 26.25H26.25C26.4986 26.25 26.737 26.1512 26.9129 25.9754C27.0887 25.7996 27.1875 25.5611 27.1875 25.3125C27.1875 25.0638 27.0887 24.8254 26.9129 24.6496C26.737 24.4737 26.4986 24.375 26.25 24.375H18.75C17.5067 24.375 16.3145 23.8811 15.4354 23.002C14.5563 22.123 14.0625 20.9307 14.0625 19.6875C14.0625 18.4443 14.5563 17.252 15.4354 16.3729C16.3145 15.4938 17.5067 15 18.75 15H20.625V13.125H24.375V15H29.0625V18.75H18.75C18.5013 18.75 18.2629 18.8487 18.087 19.0246C17.9112 19.2004 17.8125 19.4388 17.8125 19.6875C17.8125 19.9361 17.9112 20.1746 18.087 20.3504C18.2629 20.5262 18.5013 20.625 18.75 20.625H26.25C27.4932 20.625 28.6854 21.1188 29.5645 21.9979C30.4436 22.877 30.9375 24.0693 30.9375 25.3125C30.9375 26.5557 30.4436 27.748 29.5645 28.627C28.6854 29.5061 27.4932 30 26.25 30H24.375V31.875H20.625V30H15.9375V26.25Z'
          fill='#2D3436'
        />
      </g>
      <defs>
        <clipPath id='clip0_269_95'>
          <rect width='60' height='60' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const IconUtility = () => {
  return (
    <svg width='60' height='60' viewBox='0 0 45 45' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_269_101)'>
        <path
          d='M32.8125 39.375C31.072 39.375 29.4028 38.6836 28.1721 37.4529C26.9414 36.2222 26.25 34.553 26.25 32.8125C26.25 31.072 26.9414 29.4028 28.1721 28.1721C29.4028 26.9414 31.072 26.25 32.8125 26.25C34.553 26.25 36.2222 26.9414 37.4529 28.1721C38.6836 29.4028 39.375 31.072 39.375 32.8125C39.375 34.553 38.6836 36.2222 37.4529 37.4529C36.2222 38.6836 34.553 39.375 32.8125 39.375V39.375ZM32.8125 35.625C33.5584 35.625 34.2738 35.3287 34.8012 34.8012C35.3287 34.2738 35.625 33.5584 35.625 32.8125C35.625 32.0666 35.3287 31.3512 34.8012 30.8238C34.2738 30.2963 33.5584 30 32.8125 30C32.0666 30 31.3512 30.2963 30.8238 30.8238C30.2963 31.3512 30 32.0666 30 32.8125C30 33.5584 30.2963 34.2738 30.8238 34.8012C31.3512 35.3287 32.0666 35.625 32.8125 35.625ZM12.1875 18.75C11.3257 18.75 10.4723 18.5803 9.67614 18.2505C8.87994 17.9207 8.1565 17.4373 7.54711 16.8279C6.93773 16.2185 6.45434 15.4951 6.12454 14.6989C5.79474 13.9027 5.625 13.0493 5.625 12.1875C5.625 11.3257 5.79474 10.4723 6.12454 9.67614C6.45434 8.87994 6.93773 8.1565 7.54711 7.54711C8.1565 6.93773 8.87994 6.45434 9.67614 6.12454C10.4723 5.79474 11.3257 5.625 12.1875 5.625C13.928 5.625 15.5972 6.3164 16.8279 7.54711C18.0586 8.77782 18.75 10.447 18.75 12.1875C18.75 13.928 18.0586 15.5972 16.8279 16.8279C15.5972 18.0586 13.928 18.75 12.1875 18.75V18.75ZM12.1875 15C12.9334 15 13.6488 14.7037 14.1762 14.1762C14.7037 13.6488 15 12.9334 15 12.1875C15 11.4416 14.7037 10.7262 14.1762 10.1988C13.6488 9.67132 12.9334 9.375 12.1875 9.375C11.4416 9.375 10.7262 9.67132 10.1988 10.1988C9.67132 10.7262 9.375 11.4416 9.375 12.1875C9.375 12.9334 9.67132 13.6488 10.1988 14.1762C10.7262 14.7037 11.4416 15 12.1875 15V15ZM35.7581 6.59063L38.4094 9.24187L9.24375 38.4094L6.5925 35.7581L35.7562 6.59063H35.7581Z'
          fill='#2D3436'
        />
      </g>
      <defs>
        <clipPath id='clip0_269_101'>
          <rect width='60' height='60' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const IconType = () => {
  return (
    <svg width='60' height='60' viewBox='0 0 45 45' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_269_108)'>
        <path
          d='M18.75 18.9581V1.875L39.375 13.125V39.375H5.625V13.125L18.75 18.9581ZM22.5 8.19187V24.7294L9.375 18.8963V35.625H35.625V15.3506L22.5 8.19375V8.19187Z'
          fill='#2D3436'
        />
      </g>
      <defs>
        <clipPath id='clip0_269_108'>
          <rect width='60' height='60' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
