/* eslint-disable jsx-a11y/anchor-is-valid,jsx-a11y/aria-role */
import {useCallback, useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {getReCaptchaScore, getUserByToken, signUp, signUpMemberstack} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {useIntl} from 'react-intl'
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import {KTSVG, toAbsoluteUrl} from '../../../../_gws/helpers'
import {Spinner} from 'react-bootstrap'
import styled from '@emotion/styled'
import InputGroup from 'react-bootstrap/InputGroup'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import { MemberstackFunctions } from '../../../../solidInterfaces/membestack'

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/
/**
 * Login form | Use Formik for form management and Yup for form validation.
 * The form has two fields, one for the user's email and one for their password, uses other dependencies such as clsx for conditional classes and react-intl for internationalization.
 * @component
 */

const GooleIcon = () => {
  return (
    <svg style={{marginLeft: '10px'}} viewBox='0 0 48 48' width='25px' height='25px'>
      <path
        fill='#FFC107'
        d='M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z'
      />
      <path
        fill='#FF3D00'
        d='M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z'
      />
      <path
        fill='#4CAF50'
        d='M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z'
      />
      <path
        fill='#1976D2'
        d='M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z'
      />
    </svg>
  )
}

const Input = styled('input')({
  border: '1px solid #DBDFE9',
  background: 'transparent',
  borderRadius: '8px',
  color: '#DBDFE9',
})

const BtnSignUp = styled('button')({
  width: '100%',
  height: '50px',
  borderRadius: '8px',
  cursor: 'pointer',
  color: '#fff',
  background: '#212121',
  fontSize: '1.2rem',
  fontWeight: 'bold',
  border: 'none',
})


const WrapperInfo = styled('div')({
  h4: {
    fontSize: '2.2rem',
    marginTop: '15px',
    width: '80%',
    fontWeight: 'normal',
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
})

const WrapperLogin = styled('div')({
  '@media (max-width: 768px)': {
    marginTop: '40px',
  },
})

const WrapperPolicy = styled('div')({
  '@media (max-width: 768px)': {
    marginTop: '40px',
  },
})

const Container = styled('div')({
  height: '80%',
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'space-between',
  padding: '0px 70px',
  '@media (max-width: 768px)': {
    justifyContent: 'initial',
  },
})

export function SignUp() {
  const navigate = useNavigate()
  const {search} = useLocation()
  const intl = useIntl()

  /**
   * Schema for validation inputs (Using Yup)
   */
  const signUpSchema = Yup.object().shape({
    email: Yup.string()
      //.email(intl.formatMessage({id: 'AUTH.VALIDATION.WRONG_EMAIL_FORMAT'}))
      .matches(
        /^([^\s@]+@[^\s@]+\.[^\s@]+(\.demo)?)|(^[^\s@]+\.demo)$/,
        intl.formatMessage({id: 'AUTH.VALIDATION.WRONG_USER_FORMAT'})
      )
      .required(intl.formatMessage({id: 'AUTH.VALIDATION.USER_REQUIRED'})),
    password: Yup.string().required(intl.formatMessage({id: 'AUTH.VALIDATION.PASSWORD_REQUIRED'})),
  })

  /**
   * Form initial values for Formik
   */
  const initialValues = {
    email: '',
    password: '',
  }

  /**
   * ReCaptcha Score Limit
   * */
  const limitRCScore = 50

  /**
   * Show/Hide loading in button
   */
  const [loading, setLoading] = useState(false)
  const [loadingAuth, setLoadingAuth] = useState(true)
  /**
   * To set active User
   */
  const {saveAuth, setCurrentUser} = useAuth()

  /**
   * ReCaptcha States and Stuffs
   */
  const {executeRecaptcha} = useGoogleReCaptcha()
  const [token, setToken] = useState('')
  const [score, setScore] = useState(100)

  /**
   * Show/Hide password states
   */
  const [showPass, setShowPass] = useState(true)
  const [eyeIcon, setEyeIcon] = useState('017-invisible')

  /**
   * ReCapcha V3 handler
   * Also get the score consuming backend API
   */
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return
    }

    /**
     * Get ReCaptcha Token
     */
    const token = await executeRecaptcha('Login')
    setToken(token)

    /**
     * Get ReCaptcha Score
     */
    const resp = await getReCaptchaScore(token)

    if (resp.data.status === 200) {
      if (resp.data.success === true) {
        setScore(resp.data.score * 100)
      }
    }
  }, [executeRecaptcha])

  /**
   * Show/Hide Password
   */
  const handleShowPassword = () => {
    setShowPass(!showPass)
    setEyeIcon(showPass === true ? '041-eye' : '017-invisible')
  }

  useEffect(() => {
    handleReCaptchaVerify()
  }, [handleReCaptchaVerify])

  useEffect(() => {
    const token = new URLSearchParams(search).get('token')
    const verified = new URLSearchParams(search).get('verified')
    if (token && verified) {
      saveAuth({
        token: token,
        verified: verified,
      })
      getUserByToken().then((data: any) => {
        setCurrentUser(data)
        navigate(0)
      })
    } else {
      setLoadingAuth(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  /**
   * Formik Implementation sign up Form
   * @param initialValues - Array for default values
   * @param validationSchema - Yup array for validations
   */
  const formik = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      if (score > limitRCScore) {
        try {
          setLoading(true)
          const {data: auth} = await signUp(values.email, values.password)
          saveAuth(auth)
          const {data: user} = await getUserByToken()
          setCurrentUser(user)
        } catch (error) {
          saveAuth(undefined)
          setStatus(intl.formatMessage({id: 'AUTH.VALIDATION.INVALID_LOGIN'}))
          setSubmitting(false)
          setLoading(false)
        }
      }
    },
  })

  const handlerGoogleAuth = async () => {
    try {
      let registerResult: any = await MemberstackFunctions.registerWithProvider('google')
      const {data: auth} = await signUpMemberstack(registerResult.data.tokens.accessToken)
      saveAuth(auth)
      const {data: user} = await getUserByToken()
      setCurrentUser(user)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Container>
      <WrapperInfo>
        <a href='https://growker.ai' target='_blank' rel='noreferrer'>
          <img
            style={{width: '100px'}}
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/gwsbeta_1.svg')}
          />
        </a>
        <h4>{intl.formatMessage({id: 'AUTH.LOGIN.TEXT'})}</h4>
      </WrapperInfo>

      <WrapperLogin>
        <h4 style={{fontWeight: 'bold', marginBottom: '20px'}}>Registrate</h4>
        {!loadingAuth && (
          <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
            {formik.status ? <div className='mb-5 fw-normal text-danger'>{formik.status}</div> : ''}

            <InputGroup>
              <Input
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-lg',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
                type='email'
                name='email'
                autoComplete='off'
                id='email'
                placeholder={intl.formatMessage({id: 'AUTH.INPUT.EMAIL.PLACEHOLDER'})}
              />
            </InputGroup>
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}

            <InputGroup style={{marginTop: '25px'}}>
              <Input
                id='password'
                type={!showPass ? 'text' : 'password'}
                autoComplete='off'
                placeholder={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD.PLACEHOLDER'})}
                {...formik.getFieldProps('password')}
                className={clsx(
                  ' form-control form-control-lg',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              <InputGroup.Text>
                <span onClick={handleShowPassword}>
                  <KTSVG
                    path={toAbsoluteUrl(`media/icons/unicos/${eyeIcon}.svg`)}
                    className='svg-icon svg-icon svg-icon-1 cursor-pointer'
                  />
                </span>
              </InputGroup.Text>
            </InputGroup>
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}

            <div className='fv-row'>
              {token === '' && (
                <div className='text-black'>
                  {intl.formatMessage({id: 'AUTH.GENERAL.GETTING.RECAPTCHA.SCORE'})}
                </div>
              )}
              {token !== '' && score <= limitRCScore && (
                <div className='text-danger'>
                  {intl.formatMessage({id: 'AUTH.GENERAL.LOW.RECAPTCHA.SCORE'})}
                </div>
              )}
            </div>

            <div>
              <div style={{marginTop: '25px'}}>
                <BtnSignUp
                  type='submit'
                  id='kt_sign_in_submit'
                  className={`${token === '' ? 'd-none' : ''} ${
                    score <= limitRCScore ? 'd-none' : ''
                  }`}
                  role='submit'
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {!loading && intl.formatMessage({id: 'AUTH.GENERAL.SIGNUP.BUTTON'})}
                  {loading && (
                    <Spinner size='sm' animation='border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                  )}
                </BtnSignUp>
              </div>
              <div style={{marginTop: '10px'}}>
                <div
                  style={{
                    cursor: 'pointer',
                    border: '2px solid #212121',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '10px',
                    borderRadius: '8px',
                  }}
                  onClick={handlerGoogleAuth}
                >
                  {intl.formatMessage({id: 'AUTH.GENERAL.SIGNUP.GOOGLE'})}
                  <GooleIcon />
                </div>
              </div>
              <div style={{display: 'flex', justifyContent: "end", marginTop: '1.5rem'}}>
                <Link to="/login" style={{ color: "rgb(70, 164, 179)"}} replace={true}>
                  {intl.formatMessage({ id: "AUTH.GENERAL.LOGIN.REDIRECT" })}
                </Link>
              </div>
            </div>
          </form>
        )}
        {loadingAuth && (
          <div>
            <Spinner size='sm' animation='border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </Spinner>
          </div>
        )}
      </WrapperLogin>
      <WrapperPolicy>
        <span>{intl.formatMessage({id: 'AUTH.POLICIES.TITLE'})} </span>
        <a
          href='https://www.growker.ai/terminos-y-condiciones'
          target='_blank'
          rel='noopener noreferrer'
        >
          {intl.formatMessage({id: 'AUTH.POLICIES.USER'})}{' '}
        </a>
        <a
          href='https://www.growker.ai/declaracion-de-privacidad'
          target='_blank'
          rel='noopener noreferrer'
        >
          {intl.formatMessage({id: 'AUTH.POLICIES.PRIVACY'})}{' '}
        </a>
      </WrapperPolicy>
    </Container>
  )
}
