import {FC} from 'react'
import React from 'react'
import {BasicLabelWithIcon} from './Labels'
import {BasicLabel} from './Labels'
import {LabelWithIndicator} from './Labels'
import {LabelWithGrowIndicator} from './Labels'
import {ProgressWithNumText} from './Progress'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import {ProgressWithNum} from './Progress'
import {CardLabelandProgress, CardTitleIcon, SliderCardGeneral} from './Sliders'
import {NewCalendar} from './Calendar/NewCalendar'
import {CardBarChart} from '../cards'
import {CardLineChart} from '../cards'

/**
 * This component has the gool to show the new components
 */

const TestSheet: FC = () => {
  const changeDate = (date: any) => {
    console.log('Date from the calendar: ', date)
  }

  const cardsData = [
    {
      children: (
        <>
          <CardLabelandProgress
            title={'Analisis de inventario'}
            to={'ejemplo'}
            basicLabel={{text: 'buena', backgroundColor: 'light-warning', color: 'warning-dark'}}
            progressWithNum={{
              labelColor: 'warning',
              backgroundColor: 'light-dark',
              visualPartsPercentage: '70%',
              visualPartsColor: 'warning',
            }}
          />{' '}
        </>
      ),
    },
    {
      children: (
        <>
          <CardLabelandProgress
            title={'Analisis de marketing'}
            to={''}
            basicLabel={{text: 'buena', backgroundColor: 'light-orange', color: 'orange-dark'}}
            progressWithNum={{
              labelColor: 'orange',
              backgroundColor: 'light-dark',
              visualPartsPercentage: '20%',
              visualPartsColor: 'orange',
            }}
          />{' '}
        </>
      ),
    },
  ]

  const cardData2 = [
    {
      children: (
        <>
          <CardTitleIcon
            title={'Acciones a mejorar'}
            textNumber={'435'}
            textState={'Urgentes'}
            icon='media/icons/gws-icons/triangulo.svg'
            svgColor='warning'
            backgroundColorSvg='light-warning'
          />
        </>
      ),
    },
    {
      children: (
        <>
          <CardTitleIcon
            title={'Acciones a mejorar'}
            textNumber={'236'}
            textState={'Urgentes'}
            icon='media/icons/gws-icons/triangulo.svg'
            svgColor='warning'
            backgroundColorSvg='light-warning'
          />
        </>
      ),
    },
  ]
  return (
    <>
      <div className='d-flex flex-row'>
        <div className='d-flex flex-column'>
          <h3>Etiquetas</h3>
          <div className='m-3'>
            <p>etiqueta con icono</p>
            <BasicLabelWithIcon
              svgColor='danger'
              text={'atencion inmediata'}
              backgroundColor={'light-danger'}
              icon={'media/icons/gws-icons/etiqueta-atencion-inmediata.svg'}
              color={'danger-dark'}
            />
            <div className='my-2'>
              <BasicLabelWithIcon
                text={'pronta atencion'}
                backgroundColor={'light-orange'}
                icon={'media/icons/gws-icons/etiqueta-pronta-atencion.svg'}
                color={'orange-dark'}
              />
            </div>
            <div className='my-2'>
              <BasicLabelWithIcon
                text={'atencion urgente'}
                backgroundColor={'light-warning'}
                icon={'media/icons/gws-icons/etiqueta-pronta-atencion.svg'}
                color={'warning-dark'}
              />
            </div>
            <div className='my-2'>
              <BasicLabelWithIcon
                text={'rendimiento optimo'}
                backgroundColor={'light-primary'}
                icon={'media/icons/gws-icons/etiqueta-optimo-rendimiento.svg'}
                color={'primary-dark'}
              />
            </div>
          </div>

          <div className='m-3'>
            <p>etiqueta sin icono</p>
            <div className='d-flex flex-row'>
              <div>
                <BasicLabel
                  text={'delicada'}
                  backgroundColor={'light-orange'}
                  color={'orange-dark'}
                />
              </div>
              <div className='mx-2'>
                <BasicLabel
                  text={'urgente'}
                  backgroundColor={'light-orange'}
                  color={'orange-dark'}
                />
              </div>
            </div>
            <div className='d-flex flex-row mt-2'>
              <div>
                <BasicLabel
                  text={'critica'}
                  backgroundColor={'light-danger'}
                  color={'danger-dark'}
                />
              </div>
              <div className='mx-2'>
                <BasicLabel
                  text={'importante'}
                  backgroundColor={'light-danger'}
                  color={'danger-dark'}
                />
              </div>
            </div>
            <div className='d-flex flex-row mt-2'>
              <BasicLabel text={'buena'} backgroundColor={'light-warning'} color={'warning-dark'} />
            </div>
            <div className='d-flex flex-row mt-2'>
              <BasicLabel
                text={'optima'}
                backgroundColor={'light-primary'}
                color={'primary-dark'}
              />
            </div>
          </div>

          <div className='m-3'>
            <p>etiqueta con indicador</p>
            <div className='d-flex flex-row'>
              <LabelWithIndicator
                svgColor={'success-dark'}
                text={'+12%'}
                backgroundColor={'light-success'}
                color={'success-dark'}
                icon={'media/icons/gws-icons/Arrow10.svg'}
              />
              <div className='my-15'>
                <LabelWithIndicator
                  svgColor={'danger-dark'}
                  text={'-12%'}
                  backgroundColor={'light-danger'}
                  color={'danger-dark'}
                  icon={'media/icons/gws-icons/Arrow10-decrec.svg'}
                />
              </div>
            </div>
          </div>

          <div className='m-3'>
            <p>etiqueta con indicador de crecimiento o decrecimiento</p>
            <div className='d-flex flex-row'>
              <LabelWithGrowIndicator
                svgColor={'danger-dark'}
                text={'-12'}
                backgroundColor={'light-danger'}
                color={'black'}
              />
              <div className='mx-15'>
                <LabelWithGrowIndicator
                  svgColor={'success-dark'}
                  text={'+13'}
                  backgroundColor={'light-success'}
                  color={'black'}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <h3>Progress</h3>
          <div className='my-8'>
            <ProgressWithNumText
              among='122'
              text='Urgentes'
              labelColor='orange'
              backgroundColor={'light-dark'}
              visualParts={[
                {
                  percentage: '35%',
                  color: 'orange',
                },
              ]}
            />
          </div>
          <div className='my-20'>
            <ProgressWithNum
              labelColor='warning'
              backgroundColor={'light-dark'}
              visualParts={[
                {
                  percentage: '70%',
                  color: 'warning',
                },
              ]}
            />
          </div>
        </div>
        <div className='mx-20'>
          <div>Slider</div>
          <div style={{width: '20em'}}>
            <SliderCardGeneral cardsData={cardsData} />
          </div>

          <div style={{width: '10em', margin: '20px 0'}}>
            <SliderCardGeneral cardsData={cardData2} />
          </div>
          <div style={{width: '10em', margin: '20px 0'}}>
            <SliderCardGeneral arrow={true} autoPlay={false} cardsData={cardData2} />
          </div>
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col-6'>
          <CardBarChart
            title={'Titulo de prueba'}
            subtitle={'Probando subtitulo'}
            chartYTitle={'Estado de órdenes'}
            arrayLabel={['Enviado', 'Recibido', 'Despachado', 'Enviando', 'Preparando el pedido']}
            arrayValues={[98, 74, 53, 12, 5]}
          />
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col-4'>
          <CardLineChart
            key={1}
            title={'Impresiones y clics'}
            dataLabels={['Enero', 'Febrero', 'Marzo', 'Abril']}
            dataColors={[
              {
                borderColor: 'rgba(80,187,204,1)',
                gradientEnd: 'rgba(198,241,250,0.40)',
                gradientStart: 'rgba(253,254,254,0.50)',
              },
              {
                borderColor: 'rgba(10,138,121,1)',
                gradientEnd: 'rgba(10, 138, 121, 0.20)',
                gradientStart: 'rgba(10, 138, 121, 0.07)',
              },
            ]}
            dataValues={[
              {label: 'Clicks', values: ['37', '195', '81', '120']},
              {
                axis: '1',
                label: 'Impresiones',
                values: ['1154', '250', '165', '303'],
              },
            ]}
            hasLegend={true}
          />
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col-2'>
          <CardLineChart
            key={2}
            title={'Impresiones y clics'}
            dataLabels={['Enero', 'Febrero', 'Marzo', 'Abril']}
            dataColors={[
              {
                borderColor: 'rgba(80,187,204,1)',
                gradientEnd: 'rgba(198,241,250,0.40)',
                gradientStart: 'rgba(253,254,254,0.50)',
              },
            ]}
            dataValues={[{label: '', values: ['37', '195', '81', '120']}]}
            hasLegend={false}
            cleanChart={true}
          />
        </div>
      </div>

      <div className='mx-20'>
        <div>Slider</div>
        <div style={{width: '20em'}}>
          <SliderCardGeneral cardsData={cardsData} />
        </div>

        <div style={{width: '10em', margin: '20px 0'}}>
          <SliderCardGeneral cardsData={cardData2} />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <div>Calendario</div>
          <NewCalendar changeDate={changeDate} />
        </div>
      </div>
    </>
  )
}

export default TestSheet
