import {PlaylistActions, ReferenceType, Task} from '../types'
import {CreateTask} from './CreateTask'
import {Filters} from './PlaylistFilters'
import {PlaylistSort} from './PlaylistSort'
import {ContainerFilters, PlaylistFilterWrapper, PlaylistFilterWrapperRes} from './styled'

const PlaylistFiltersResponsive = ({
  references,
  actions,
}: {
  references: ReferenceType[]
  actions: PlaylistActions
}) => {
  return (
    <ContainerFilters>
      <CreateTask updateTasks={actions.updateTasks} references={references} />
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '1fr',
        }}
      >
        <Filters references={references} applyFilters={actions.applyFilters} />
        <PlaylistSort sortItems={actions.sortItems} />
      </div>
    </ContainerFilters>
  )
}

const PlaylistFilters = ({
  references,
  actions,
}: {
  references: ReferenceType[]
  actions: PlaylistActions
}) => {
  return (
    <ContainerFilters>
      <CreateTask updateTasks={actions.updateTasks} references={references} />
      <Filters applyFilters={actions.applyFilters} references={references} />
      <PlaylistSort sortItems={actions.sortItems} />
    </ContainerFilters>
  )
}

export const PlaylisActions = ({
  references,
  selected,
  actions,
}: {
  references: ReferenceType[]
  selected: Task[]
  actions: PlaylistActions
}) => {
  return (
    <>
      <PlaylistFilterWrapper>
        <PlaylistFilters actions={actions} references={references} />
      </PlaylistFilterWrapper>
      <PlaylistFilterWrapperRes>
        <PlaylistFiltersResponsive actions={actions} references={references} />
      </PlaylistFilterWrapperRes>
    </>
  )
}
