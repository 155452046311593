import React from 'react'

export function headFormatter(
  column: any,
  colIndex: any,
  {sortElement, filterElement}: {sortElement: any; filterElement: any}
) {
  return (
    <div className='text-start fw-normal fs-9 text-capitalize gs-0 text-nowrap'>
      {column.text}
      {sortElement}
      {filterElement}
    </div>
  )
}

export function headFormatterDashboard(
  column: any,
  colIndex: any,
  { sortElement, filterElement }: { sortElement: any; filterElement: any }
) {
  return (
    <div className='text-start fw-bolder fs-7 text-capitalize gs-0 text-nowrap'>
      {column.text}
      {sortElement}
      {filterElement}
    </div>
  )
}


export function headFormatterFK(
  column: any,
  colIndex: any,
  {sortElement, filterElement}: {sortElement: any; filterElement: any}
) {
  return (
    <div className='text-start fw-normal fs-7 text-capitalize gs-0 text-nowrap'>
      {column.text}
      {sortElement}
      {filterElement}
    </div>
  )
}

export function headFormatterClear(
  column: any,
  colIndex: any,
  {sortElement, filterElement}: {sortElement: any; filterElement: any}
) {
  return <></>
}

export function optionsTable(
  customTotal: any,
  lang: string,
  size?: number,
  sIndex?: number,
  currentPage?: number,
  pageSize?: number,
  totalRecords?: number,
  customSizePerPage?: any,
  customPageList?: any,
  customTexts?: any
) {
  const texts =
    customTexts === undefined
      ? {
          firstPageText: '',
          prePageText: '',
          nextPageText: '',
          lastPageText: '',
          nextPageTitle: '',
          prePageTitle: '',
          firstPageTitle: '',
          lastPageTitle: '',
        }
      : customTexts

  const standard = {
    paginationSize: size === undefined ? 1 : size,
    pageStartIndex: sIndex === undefined ? 1 : sIndex,
    hideSizePerPage: true,
    sizePerPage: pageSize === undefined ? 7 : pageSize,
    showTotal: true,
    disablePageTitle: true,
    hidePageListOnlyOnePage: true,
    paginationTotalRenderer: customTotal,
    hover: true,
  }

  const remote = {
    page: currentPage,
    sizePerPage: pageSize,
    totalSize: totalRecords,
    paginationSize: size === undefined ? 3 : size,
    hideSizePerPage: true,
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageRenderer: customSizePerPage,
    pageListRenderer: customPageList,
  }

  const options = currentPage === undefined ? {...standard, ...texts} : {...remote, ...texts}

  return options
}
