import { useAuth } from '../../../../app/modules/auth'
import { AdminAside } from './AsideSass/AdminAside'
//import { PlayAside } from './AsideSass/PlayAside'
import { ProAside } from './AsideSass/ProAside'
import { MentorAside } from './AsideSass/MentorAside'
import { PartnerAside } from './AsideSass/PartnerAside'

const AsideMenu = () => {
  const {currentUser} = useAuth()
  let isAdmin = currentUser?.roleId === 2
  return (
    <>
      {isAdmin ? (
        <AdminAside />
      ) : (
        <>
          {currentUser?.saasPlanId === 1 && <MentorAside />}
          {currentUser?.saasPlanId === 2 && <ProAside />}
          {currentUser?.saasPlanId === 3 && <MentorAside />}
          {currentUser?.saasPlanId === 4 && <PartnerAside />}
          {currentUser?.saasPlanId === 7 && <PartnerAside />}
        </>
      )}
    </>
  )
}

export {AsideMenu}