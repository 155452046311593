/* eslint-disable jsx-a11y/anchor-is-valid */
import {Outlet, Route, Routes} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_gws/helpers'
import {LoginWrapper} from './LoginWrapper'
import styled from '@emotion/styled'
import { useIntl } from 'react-intl'
import { SignUpWrapper } from './signUpWrapper'

const Container = styled('div')({
  height: '100vh',
  background: '#ffffff',
})

const ContainerImg = styled('div')({
  height: '100vh',
  background: `url(/media/backgrounds/bg-gradient.png) no-repeat center`,
  backgroundSize: "cover",
  display: "grid",
  placeItems: "center",
  "@media (max-width: 768px)": {
    display: "none"
  }
})

const ContentImg = styled('div')({
  color: "#fff",
  display: "flex",
  flexFlow: "column",
  alignItems: "center",
  img: {
    width: "70%"
  },
  h4: {
    color: "#fff",
    fontSize: "2rem"
  },
  p: {
    width: "50%",
    textAlign: "center",
    marginTop: "10px"
  },
})

const Wrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: '.7fr 1fr',
  height: '100%',
  gap: '1rem',
  "@media (max-width: 768px)": {
    gridTemplateColumns: '1fr',
  }
})

export const AuthLayout = () => {
  // const year = new Date().getFullYear()
  const intl = useIntl()
  return (
    <Container>
      <Wrapper>
        <div
          style={{
            display: 'grid',
            placeItems: 'center',
          }}
        >
          <Outlet />
        </div>
        <ContainerImg>
          <ContentImg>
            <img
              alt='Atro'
              src={toAbsoluteUrl('/media/backgrounds/Artboard-login.png')}
            />
            <h4>{intl.formatMessage({id: 'LOGIN.PAGE.TITLE'})}</h4>
            <p>
              {intl.formatMessage({id: 'LOGIN.PAGE.TEXT'})}
            </p>
            <img style={{ width: "200px", marginTop: "10px" }} alt='Logo' src={toAbsoluteUrl('/media/backgrounds/early-access.png')} />
          </ContentImg>
        </ContainerImg>
      </Wrapper>
    </Container>
  )
}


/**
 * Router for Auth Page
 * @component
 */
const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<LoginWrapper />} />
      <Route path='signup' element={<SignUpWrapper />} />
      <Route index element={<LoginWrapper />} />
    </Route>
  </Routes>
)

export {AuthPage}
