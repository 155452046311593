import {MARKETPLACES} from '../../../constants/marketplaces'
import {mkProp} from '../../../modules/auth'
import {getOrders, getSales} from './_requests'
import {
  DateRange,
  ModelOrderStatusDetail,
  ModelReportMonthlyDetail,
  MonthlyDetail,
  ReportOrderDetail,
  SalesReport,
  StatusDetail,
} from './types'

const getDate = () => {
  const actualMoth = new Date()
  const lastMonth = new Date()
  lastMonth.setDate(lastMonth.getDate() - 30)
  const actual = actualMoth.toISOString().split('T')[0].slice(0, 7)
  const last = lastMonth.toISOString().split('T')[0].slice(0, 7)
  return {
    last,
    actual,
  }
}

const structureMonthlyDetail = (
  monthlyDetail: MonthlyDetail,
  marketplace: mkProp
): ModelReportMonthlyDetail => {
  const total: number[] = []
  const profit: number[] = []
  const categories: string[] = []
  const months: Record<string, string> = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sep',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dic',
  }
  for (const key in monthlyDetail) {
    const month: string = key.slice(5)
    categories.push(months[month])
    total.push(monthlyDetail[key].total)
    profit.push(Number(monthlyDetail[key].profit.toFixed(2)))
  }

  return {
    orders: {
      series: [
        {
          name: 'ORDERS',
          data: total,
          color: marketplace === MARKETPLACES.AMAZON.name ? '#F5C57C' : '#7B94B3',
        },
      ],
      categories: categories,
    },
    profit: {
      series: [
        {
          name: 'PROFIT',
          data: profit,
          color: marketplace === MARKETPLACES.AMAZON.name ? '#F5C57C' : '#7B94B3',
        },
      ],
      categories: categories,
    },
  }
}

const structureOrderStatusDetail = (orderStatus: StatusDetail): ModelOrderStatusDetail => {
  const data: ReportOrderDetail[] = []
  for (const key in orderStatus) {
    data.push({
      x: key,
      y: orderStatus[key],
    })
  }
  return data
}

export const getSalesData = async ({
  date,
  marketplace,
  periodType,
}: {
  date: DateRange
  marketplace: mkProp
  periodType: number
}) => {
  if (!marketplace || marketplace === 'growker') return
  const {last, actual} = getDate()
  const [sales, orders] = await Promise.all([
    getSales(date.start, date.end, marketplace, periodType),
    getOrders(date.start, date.end, marketplace, periodType),
  ])
  const detail = sales.data.detail
  const ordersDetail = orders.data.detail

  const DefaultorderMonthlyDetail: MonthlyDetail = {
    [last]: {
      total: 0,
      profit: 0,
    },
    [actual]: {
      total: 0,
      profit: 0,
    },
  }

  const monthlyDetail: MonthlyDetail =
    Object.keys(detail.orderMonthlyDetail).length > 0
      ? detail.orderMonthlyDetail
      : DefaultorderMonthlyDetail

  const data: SalesReport = {
    sales: {
      ...detail,
      orderMonthlyDetail: structureMonthlyDetail(monthlyDetail, marketplace),
      orderStatus: structureOrderStatusDetail(detail.orderStatus),
    },
    orders: {
      items: ordersDetail.orderItems,
      indicators: {
        orderQty: ordersDetail.orderQty,
        itemsQty: ordersDetail.itemsQty,
        profitAmount: ordersDetail.profitAmount,
        discountAmount: ordersDetail.discountAmount,
        shipmentAmount: ordersDetail.shipmentAmount,
      },
    },
  }

  return data
}
