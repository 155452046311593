import {useMemo, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_gws/helpers'
import {getShortMonth} from '../../../../../_gws/helpers/misc'
import {useAuth} from '../../../../modules/auth'
import {BannerBody, BannerCard, BannerImg, Revenue, StyledButton, Year} from './styled'
import {useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
import CountUp from 'react-countup'

const getDataMonths = (lastMonths: number) => {
  const today = new Date()
  const dataMonths = []
  for (let i = 0; i < lastMonths; i++) {
    const month = today.getMonth() - i
    if (month < 0) {
      dataMonths.push(getShortMonth(month + 12))
      continue
    }
    dataMonths.push(getShortMonth(month))
  }
  return dataMonths.reverse()
}

const calculateTotalSales = (monthly: any) => {
  const actualMonth = new Date().getMonth() + 1
  let total: number = 0
  let sales: number[] = []
  if (Object.keys(monthly).length > 1) {
    getDataMonths(actualMonth).forEach((month) => {
      total += monthly[month]?.sold
      sales.push(Number(monthly[month]?.sold.toFixed(2)))
    })
    return {
      total,
      sales,
    }
  } else {
    const Month = getDataMonths(actualMonth)
    return {
      total: monthly[Month[Month.length - 1]].sold,
      sales: [monthly[Month[Month.length - 1]].sold],
    }
  }
}

const generateResults = (data: any) => {
  let totalSales: number = 0
  let percentage: number = 0
  const {total, sales} = data
  totalSales = total
  if (data.length > 1) {
    percentage =
      ((sales[sales.length - 1] - sales[sales.length - 2]) / sales[sales.length - 2]) * 100
    return {
      totalSales: totalSales,
      percentage: Number(percentage.toFixed(2)),
    }
  } else {
    return {
      totalSales: totalSales,
      percentage: 0,
    }
  }
}

const generateCombinedResults = (amazonSales: any, meliSales: any) => {
  let percentage: number = 0
  if (amazonSales.sales.length > 1 || meliSales.sales.length > 1) {
    const lastMonth =
      amazonSales.sales[amazonSales.sales.length - 2] + meliSales.sales[meliSales.sales.length - 2]
    const actualMonth =
      amazonSales.sales[amazonSales.sales.length - 1] + meliSales.sales[meliSales.sales.length - 1]
    percentage = ((actualMonth - lastMonth) / lastMonth) * 100
    return {
      totalSales: amazonSales.total + meliSales.total,
      percentage: Number(percentage.toFixed(2)),
    }
  } else {
    return {
      totalSales: amazonSales.total + meliSales.total,
      percentage: 0,
    }
  }
}

const generateMessage = (data: BannerData, intl: any) => {
  if (data.percentage === 0) return ''
  const percentageStatus: 'less' | 'more' = data.percentage < 0 ? 'less' : 'more'
  const message: Record<'less' | 'more', string> = {
    less: `${intl.formatMessage({id: 'BANNER.HEADER.MESSAGE.LESS.PREFIX'})} ${
      data.percentage
    }% ${intl.formatMessage({id: 'BANNER.HEADER.MESSAGE.LESS.SUFIX'})}`,
    more: `${intl.formatMessage({id: 'BANNER.HEADER.MESSAGE.MORE.PREFIX'})} ${
      data.percentage
    }% ${intl.formatMessage({id: 'BANNER.HEADER.MESSAGE.MORE.SUFIX'})}`,
  }
  return message[percentageStatus]
}
type SalesCondition = 'onlyAmazon' | 'OnlyMeli' | 'growker'

type Sales = {
  total: number
  sales: number[]
}
type State = {
  lastMonth: {
    amazonSales: Sales | null
    maliSales: Sales | null
  }
}
type BannerData = {
  totalSales: number
  percentage: number
}

export const Banner = ({dataReport}: {dataReport: any}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {currentUser, hasAmazon, hasMeli} = useAuth()
  const isSalesMeliValid =
    Object.keys(dataReport?.sales?.meli.monthly).length > 0
      ? dataReport?.sales?.meli?.monthly
      : null
  const isSalesAmazonValid =
    Object.keys(dataReport?.sales?.amazon.monthly).length > 0
      ? dataReport?.sales?.amazon?.monthly
      : null

  const calculateCondition =
    isSalesAmazonValid && !isSalesMeliValid
      ? 'onlyAmazon'
      : !isSalesAmazonValid && isSalesMeliValid
      ? 'OnlyMeli'
      : 'growker'

  const [data] = useState<State>({
    lastMonth: {
      amazonSales: hasAmazon && isSalesAmazonValid ? calculateTotalSales(isSalesAmazonValid) : null,
      maliSales: hasMeli && isSalesMeliValid ? calculateTotalSales(isSalesMeliValid) : null,
    },
  })

  const Condition: Record<SalesCondition, () => any> = {
    onlyAmazon: () => generateResults(data.lastMonth.amazonSales),
    OnlyMeli: () => generateResults(data.lastMonth.maliSales),
    growker: () => generateCombinedResults(data.lastMonth.amazonSales, data.lastMonth.maliSales),
  }

  const dataBanner: BannerData = useMemo(() => {
    return Condition[calculateCondition]()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataReport])

  return (
    <BannerCard>
      <BannerBody>
        <div>
          <h4>
            {intl.formatMessage({id: 'BANNER.HEADER.PREFIX'})} {currentUser?.name}!{' '}
            {intl.formatMessage({id: 'BANNER.HEADER.SUFIX'})}
          </h4>
          <p>{generateMessage(dataBanner, intl)}</p>
          <p>
            <Revenue>
              $
              <CountUp
                end={Number(dataBanner.totalSales)}
                duration={2}
                separator={','}
                decimals={2}
                className='fw-bolder h2 mb-0'
                style={{color: '#212121'}}
              />
            </Revenue>
            <Year>
              {intl.formatMessage({id: 'BANNER.HEADER.YEAR'})} {new Date().getFullYear()}
            </Year>
          </p>
          <StyledButton onClick={() => navigate('/brand-health')} size='sm'>
            {intl.formatMessage({id: 'BANNER.ACTION'})}
          </StyledButton>
        </div>
      </BannerBody>
      <BannerImg>
        <img alt='Atro' src={toAbsoluteUrl('/media/backgrounds/jetpack-delivery.png')} />
      </BannerImg>
    </BannerCard>
  )
}
