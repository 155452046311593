/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {KTSVG} from '../../../../helpers'
import {User} from './core/_models'
import {ListClientPanel} from './ListClientPanel'
import {removeClientAuth, useAuth} from '../../../../../app/modules/auth'
import {useIntl} from 'react-intl'
import {AvatarUser} from '../../../../partials/widgets/misc/AvatarUser'

type Props = {
  client: any
  list: User
  isMerchant?: boolean
}

const SearchClient: FC<Props> = ({client, list, isMerchant}) => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [searched, setSearched] = useState(false)
  const [searchVal, setSearchVal] = useState('')
  const [listFiltered, setListFiltered] = useState<any>([])

  const handleChange = (e: any) => {
    e.preventDefault()
    setSearched(true)
    setSearchVal(e.target.value)
    if (list !== undefined) {
      // @ts-ignore
      setListFiltered(list.filter((cl) => (cl?.username).includes(e.target.value)))
    }

    if (e.target.value === '') {
      setSearched(false)
    }
  }

  const handleClick = () => {
    removeClientAuth()
    // @ts-ignore
    /**
     * Fires only when the current user is merchant
     * and the user is returning to the client list
     */
    window.location.href = '/apps/user-management/clients'
  }

  return (
    <>
      <div
        id='#kt_header_client_switcher'
        className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px p-5'
        data-kt-menu='true'
      >
        <div className='d-flex flex-stack fw-regular mb-4 px-3'>
          <span className='text-900 fs-6 me-2'>{intl.formatMessage({id: 'HUMENU.PROFILE'})}</span>
        </div>
        <div
          className='d-flex align-items-center mb-5 p-2 rounded-1 bg-hover-light'
          onClick={(e) => {
            handleClick()
          }}
        >
          <AvatarUser //@ts-ignores
            userID={currentUser?.id}
            picUrl={currentUser?.picUrl}
            name={currentUser?.name}
            username={currentUser?.username}
            lastname={currentUser?.lastname}
            isMerchant={currentUser?.id === client.id}
          />
          <div className='d-flex flex-column'>
            <span className='fs-6 text-gray-900 fw-semibold'>
              {currentUser?.name} {currentUser?.lastname}
            </span>
            <span className='fs-7 text-gray-600 fw-lighter'>{currentUser?.email}</span>
          </div>
        </div>
        <div className='separator separator-dashed mt-1 mb-3'></div>
        <form className='w-100 position-relative mb-5 mt-3 px-3' autoComplete='off'>
          <KTSVG
            path='/media/icons/unicos/032-search.svg'
            className='svg-icon-2 svg-icon-lg-2 svg-icon-gray-900 position-absolute top-50 translate-middle-y ms-0 px-3'
          />

          <input
            type='text'
            className='search-input form-control ps-13 fs-7 h-40px bg-light border-0 fw-light text-gray-800'
            name='search'
            value={searchVal}
            onChange={(e) => handleChange(e)}
            placeholder={intl.formatMessage({id: 'SEARCH.CLIENTS.FIND'})}
          />
        </form>
        {!searched ? (
          <>
            <ListClientPanel
              title={intl.formatMessage({id: 'SEARCH.CLIENTS.LIST'})}
              list={list}
              setSearchVal={setSearchVal}
              setSearched={setSearched}
              actual={client.id}
            />
          </>
        ) : (
          <ListClientPanel
            title={intl.formatMessage({id: 'SEARCH.RESULTS'})}
            list={listFiltered}
            setSearchVal={setSearchVal}
            setSearched={setSearched}
            actual={client.id}
          />
        )}
      </div>
    </>
  )
}

export {SearchClient}
