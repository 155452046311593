import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG, toAbsoluteUrl} from '../../../../_gws/helpers'
// @ts-ignore
import GaugeChart from 'react-gauge-chart'

type PropsProductDetails = {
  dataReport?: any
}

const ProductGauge: React.FC<PropsProductDetails> = ({dataReport}) => {
  const intl = useIntl()

  const GaugeChartStyle = {
    height: '100%',
  }

  return (
    <div style={GaugeChartStyle}>
      <span className='mb-1 fw-semibold fs-5 w-100 d-block'>
        {intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.GAUGE.TITLE'})}&nbsp;
        <a href={'/#'} data-bs-toggle='modal' data-bs-target='#info_pt3_report_modal'>
          <KTSVG
            path={toAbsoluteUrl('/media/icons/duotune/general/gen046.svg')}
            className='svg-icon-1x svg-icon-dark'
          />
        </a>
      </span>
      <span className='text-gray-800 fw-regular text-dark mb-10'>
        {intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.GAUGE.TEXT'})}
      </span>
      <GaugeChart
        textColor='#a1a5b7'
        nrOfLevels={10}
        arcsLength={[0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]}
        // colors={['#FFF7F5', '#FF947A', '#FF3100']}
        colors={['#d24962', '#FFDA00', '#1bbc9c']}
        percent={dataReport?.orderCount < 11 ? dataReport?.orderCount / 10 : 1}
        arcPadding={0.03}
        cornerRadius={3}
        arcWidth={0.2}
        needleColor='#a1a5b7'
        needleBaseColor='#a1a5b7'
        formatTextValue={(value: any) => dataReport?.orderCount}
      />
      <div className='text-center'>
        <span className='card-label fw-bold fs-3 mb-1'>
          {dataReport?.orderCount <= 5 && intl.formatMessage({id: 'BRAND_HEALTH.TABLE.GAUGE.LOW'})}
          {dataReport?.orderCount > 5 &&
            dataReport?.orderCount <= 10 &&
            intl.formatMessage({id: 'BRAND_HEALTH.TABLE.GAUGE.MEDIUM'})}
          {dataReport?.orderCount >= 11 &&
            intl.formatMessage({id: 'BRAND_HEALTH.TABLE.GAUGE.HIGH'})}
        </span>
      </div>
    </div>
  )
}
export {ProductGauge}
