import {IconBase} from './Base'

export const IconBrandHealth = ({
  color = '#273A51',
  size = 24,
}: {
  color?: string
  size?: number
}) => {
  const setColor = color
  return (
    <IconBase size={size} color={setColor} viewBox='0 0 10 10'>
      <svg
        width='10'
        height='10'
        viewBox='0 0 10 10'
        fill='none'
      >
        <path
          d='M1 10C0.725 10 0.489583 9.8776 0.29375 9.63281C0.0979167 9.38802 0 9.09375 0 8.75V5.625H2.6875L3.55 7.78125C3.59167 7.89583 3.65417 7.98177 3.7375 8.03906C3.82083 8.09635 3.90833 8.125 4 8.125C4.09167 8.125 4.17917 8.09635 4.2625 8.03906C4.34583 7.98177 4.40833 7.89583 4.45 7.78125L6 3.90625L6.55 5.28125C6.59167 5.39583 6.65417 5.48177 6.7375 5.53906C6.82083 5.59635 6.90833 5.625 7 5.625H10V8.75C10 9.09375 9.90208 9.38802 9.70625 9.63281C9.51042 9.8776 9.275 10 9 10H1ZM0 4.375V1.25C0 0.90625 0.0979167 0.611979 0.29375 0.367188C0.489583 0.122396 0.725 0 1 0H9C9.275 0 9.51042 0.122396 9.70625 0.367188C9.90208 0.611979 10 0.90625 10 1.25V4.375H7.3125L6.45 2.21875C6.40833 2.10417 6.34583 2.02344 6.2625 1.97656C6.17917 1.92969 6.09167 1.90625 6 1.90625C5.90833 1.90625 5.82083 1.92969 5.7375 1.97656C5.65417 2.02344 5.59167 2.10417 5.55 2.21875L4 6.09375L3.45 4.71875C3.40833 4.60417 3.34583 4.51823 3.2625 4.46094C3.17917 4.40365 3.09167 4.375 3 4.375H0Z'
          fill={color}
        />
      </svg>
    </IconBase>
  )
}
