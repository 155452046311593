import {FC} from 'react'
import {Container, ContainerChart, ContainerDetails, ContainerIndicators} from './components/styled'
import {ProductCard} from './components/ProductCard'
import {ProductDetailIa} from './components/ProductDetail'
import {ProductIndicators} from './components/ProdictIndicators'
import {ChartIncome} from './components/IncomeChart'
import {BrandHealthIndicators} from './components/BrandHealthIndicators'
import {Marketplace, Meta} from './core/type'
import {PRODUCT_TYPE} from '../../constants/marketplaces'
import {BannerHelp} from '../../modules/apps/BannerHelp/BannerHelp'

type Props = {
  metaProduct: any
  mkplace: Marketplace
}

export type DetailBody = {
  ai: string
  value: string
}

export type DetaillBullets = {
  ai: string
  value: string[]
}

const structureData = (metaProduct: Meta) => {
  const productCardData = {
    asin: metaProduct.asin as string,
    name: metaProduct.name.value as string,
    price: metaProduct.price as number,
    top: metaProduct.top as number,
    description: metaProduct.description.value as string,
    brand: metaProduct.brand as string,
    category: metaProduct.category as string,
    image: metaProduct.image as string,
    marketplace: metaProduct.marketplace as number,
    type: metaProduct.fba ? PRODUCT_TYPE.FBA : PRODUCT_TYPE.FBM,
  }
  const productIndicatorsData = {
    stock: metaProduct.stock as number,
    campaigns: metaProduct.marketing.custom.last_30_days?.campaigns ?? (0 as number),
    landed_cost: metaProduct.landed_cost as number,
    marketplace_fee: metaProduct.sales.custom.last_30_days.marketplace_fee,
    utility: metaProduct.sales.custom.last_30_days.sold,
    orders: metaProduct.sales.custom.last_30_days?.orders ?? (0 as number),
  }
  const productDetailSData = {
    name: metaProduct.name as DetailBody,
    description: metaProduct.description as DetailBody,
    bullets: metaProduct.bullets
      ? (metaProduct.bullets as DetaillBullets)
      : {
          ai: '',
          value: [],
        },
  }
  const productIncomeData = {
    income: metaProduct.sales.monthly,
  }
  const productMetricsData = {
    metrics: [
      ...(metaProduct.metrics.category_1?.indicators ?? []),
      ...(metaProduct.metrics.category_2?.indicators ?? []),
      ...(metaProduct.metrics.category_3?.indicators ?? []),
      ...(metaProduct.metrics.category_4?.indicators ?? []),
    ],
  }
  return {
    productCardData,
    productIndicatorsData,
    productDetailSData,
    productIncomeData,
    productMetricsData,
  }
}

const ProductHealth: FC<Props> = ({metaProduct, mkplace}) => {
  const {
    productCardData,
    productIndicatorsData,
    productDetailSData,
    productIncomeData,
    productMetricsData,
  } = structureData(metaProduct)

  return (
    <Container>
      <BannerHelp
        linkToDoc='https://docs.growker.ai/kb/producthelath/'
        title='HELP.TITLE.PRODUCT_HEALTH'
        text='HELP.TEXT.PRODUCT_HEALTH'
      />
      <ContainerDetails>
        <div>
          <ProductCard product={productCardData} marketplace={mkplace} />
        </div>
        <div>
          <ProductDetailIa
            asin={metaProduct.asin}
            marketplace={metaProduct.marketplace}
            data={productDetailSData}
          />
        </div>
      </ContainerDetails>
      <ContainerIndicators>
        <ProductIndicators indicator={productIndicatorsData} mkp={mkplace} />
      </ContainerIndicators>
      <ContainerChart
        style={{
          gridTemplateColumns: productMetricsData.metrics.length > 0 ? '2fr 1fr' : '1fr',
        }}
      >
        <div>
          <ChartIncome data={productIncomeData.income} />
        </div>
        {productMetricsData.metrics.length > 0 && (
          <div>
            <BrandHealthIndicators metrics={productMetricsData.metrics} />
          </div>
        )}
      </ContainerChart>
    </Container>
  )
}

export {ProductHealth}
