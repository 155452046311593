import {KTSVG} from '../../../helpers'
import React from 'react'

type PropsElementWithIcon = {
  icon?: any
  title: string
  description: string
}
const ElementWithIcon: React.FC<PropsElementWithIcon> = ({icon, title, description}) => {
  return (
    <div className='d-flex align-items-center mb-7'>
      {icon !== '' && (
        <div className='symbol symbol-50px me-5'>
          <span className='symbol-label'>
            <KTSVG path={icon} className='svg-icon-2x svg-icon-dark' />
          </span>
        </div>
      )}
      <div className='d-flex flex-column'>
        <span className='text-dark text-hover-primary fs-6 fw-bold'>{title}</span>
        <span className='text-muted fw-regular'>{description}</span>
      </div>
    </div>
  )
}

export {ElementWithIcon}
