import React, {FC, useEffect, useRef, useState} from 'react'

import {Chart} from 'react-chartjs-2'
import {Chart as ChartJS} from 'chart.js'

import type {ChartArea, ChartData} from 'chart.js'
import {abbreviateNumber, tooltipDefault} from '../../../../helpers/misc'

type Props = {
  dataLabels: string[] | number[]
  dataColors: ObjectColors[]
  dataValues: ObjectValues[]
  titleYAxis?: string
  titleY1Axis?: string
  titleXAxis?: string
  currentElement?: number
  updateCurrentElement?: (index: number) => void
  cleanChart?: boolean
  canSeeTooltip?: boolean
  ref: React.Ref<any>
}

export interface ObjectValues {
  axis?: string
  label: string
  values: string[]
}

export interface ObjectColors {
  borderColor: string
  gradientEnd: string
  gradientStart: string
}

/**
 * This component is used to display the line chart with gradient
 * @param dataLabels - array of labels
 * @param dataValues - array of values
 * @param dataColors - array of colors in hexadecimal format
 * @param titleXAxis - title of the x-axis if is not defined, the x-axis will not be displayed
 * @param titleYAxis - title of the y-axis if is not defined, the y-axis will not be displayed
 * @param titleY1Axis - title of the y1-axis if is not defined, the y1-axis will not be displayed
 * @param cleanChart  - boolean to display or not the grid and axis
 * @param canSeeTooltip - boolean to display or not the tooltip
 * @constructor
 */
const WrapperLineChart: FC<Props> = ({
  dataLabels,
  dataValues,
  dataColors,
  titleXAxis,
  titleYAxis,
  titleY1Axis,
  cleanChart,
  canSeeTooltip,
}) => {
  const chartRef = useRef<ChartJS>(null)
  const [chartData, setChartData] = useState<ChartData<'bar'>>({
    datasets: [],
  })
  /**
   * Check if there is a second axis
   */
  const hasY1Axis = dataValues.some((item) => item.axis === '1')

  /**hasY1Axis
   * Define the dataset of the chart dynamically
   */

  let dataset = dataValues.map((item, index) => {
    /**
     * Set the axis of the data, if it is not defined, the data will be on the left axis
     */
    let axisID = `y${item.axis === undefined ? '' : item.axis}`

    return {
      type: 'line' as const,
      label: item?.label,
      data: item?.values,
      borderColor: dataColors[index].borderColor,
      pointBackgroundColor: dataColors[index].borderColor,
      borderWidth: 1,
      pointRadius: 1,
      pointHoverRadius: 3,
      yAxisID: axisID,
      fill: true,
      tension: 0.35,
      datalabels: {
        display: false,
      },
    }
  })

  let optionsChart = {
    //aspectRatio: 1,
    //maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: canSeeTooltip || !cleanChart ? tooltipDefault() : {enabled: false},
    },
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    scales: {
      y: {
        title: {
          display: !cleanChart && !!titleYAxis,
          text: titleYAxis,
          color: '#a1a5b7',
          font: {
            weight: 'bold',
          },
        },
        grid: {
          display: !cleanChart,
          drawBorder: false,
          drawTicks: false,
          borderDash: [5, 5],
          lineWidth: 1,
          color: function () {
            return 'rgba(161,162,182,0.17)'
          },
        },
        ticks: {
          display: !cleanChart,
          color: '#a1a2b6',
          callback: function (value: any, index: any, ticks: any) {
            return abbreviateNumber(value, 0)
          },
        },
      },
      x: {
        offset: false,
        title: {
          display: !cleanChart && !!titleXAxis,
          text: titleXAxis,
          color: '#a1a5b7',
          font: {
            weight: 'bold',
          },
        },
        grid: {
          display: false,
          drawBorder: false,
          drawTicks: false,
          borderDash: [5, 5],
          lineWidth: 1,
          color: function () {
            return 'rgba(161,162,182,0.17)'
          },
        },
        ticks: {
          display: !cleanChart,
          color: '#a1a2b6',
          rotation: 90,
        },
      },
    },
  }

  if (hasY1Axis) {
    let y1Axis = {
      y1: {
        position: 'right' as const,
        title: {
          display: !cleanChart && !!titleY1Axis,
          text: !cleanChart && titleY1Axis,
          color: '#a1a5b7',
          font: {
            weight: 'bold',
          },
        },
        grid: {
          display: false,
          drawBorder: false,
          drawTicks: false,
          borderDash: [5, 5],
          lineWidth: 1,
          color: function () {
            return 'rgba(161,162,182,0.17)'
          },
        },
        ticks: {
          color: '#a1a2b6',
          callback: function (value: any, index: any, ticks: any) {
            return abbreviateNumber(value, 0)
          },
        },
      },
    }
    let {scales} = optionsChart

    optionsChart.scales = {...scales, ...y1Axis}
  }

  const dataChart: any = {
    labels: dataLabels,
    datasets: dataset,
  }

  /**
   * Create gradient function
   * @param ctx
   * @param area
   * @param indexDataset
   */
  function createGradient(ctx: CanvasRenderingContext2D, area: ChartArea, indexDataset: number) {
    const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top)
    gradient.addColorStop(0, dataColors[indexDataset].gradientStart)
    gradient.addColorStop(1, dataColors[indexDataset].gradientEnd)
    return gradient
  }

  useEffect(() => {
    const chart = chartRef.current
    if (!chart) {
      return
    }

    const chartData = {
      ...dataChart,
      datasets: dataChart.datasets.map((dataset: any, i: number) => ({
        ...dataset,
        backgroundColor: createGradient(chart.ctx, chart.chartArea, i),
      })),
    }
    setChartData(chartData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataColors, dataValues])
  return (
    <>
      <Chart ref={chartRef} data={chartData} options={optionsChart} type='bar' />
    </>
  )
}

export {WrapperLineChart}
