import {IconBase} from './Base'

export const IconInventory = ({color = '#273A51', size = 24}: {color?: string; size?: number}) => {
  const setColor = color
  return (
    <IconBase size={size} color={setColor} viewBox='0 0 10 10'>
      <svg
        width='10'
        height='10'
        viewBox='0 0 10 10'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M1.66667 4.72222V1.66667H4.72222V4.72222H1.66667ZM1.66667 8.33333V5.27778H4.72222V8.33333H1.66667ZM5.27778 4.72222V1.66667H8.33333V4.72222H5.27778ZM5.27778 8.33333V5.27778H8.33333V8.33333H5.27778ZM1.11111 10C0.805556 10 0.543982 9.8912 0.326389 9.67361C0.108796 9.45602 0 9.19444 0 8.88889V1.11111C0 0.805556 0.108796 0.543982 0.326389 0.326389C0.543982 0.108796 0.805556 0 1.11111 0H8.88889C9.19444 0 9.45602 0.108796 9.67361 0.326389C9.8912 0.543982 10 0.805556 10 1.11111V8.88889C10 9.19444 9.8912 9.45602 9.67361 9.67361C9.45602 9.8912 9.19444 10 8.88889 10H1.11111ZM1.11111 8.88889H8.88889V1.11111H1.11111V8.88889Z'
          fill={color}
        />
      </svg>
    </IconBase>
  )
}
