import React from 'react'
import {AgCategory} from './components/AgCategory'
import {AgSubCategory} from './components/AgSubCategory'

type Props = {
  customClass?: string
  choice: any
  text: string
}
/**
 * Aside component | render components depending on the value of the choice property.
 * @param customClass - to add extra classes
 * @param choice - determines which component to render
 * @param text - the text content
 * @component
 */
const AgAside: React.FC<Props> = ({customClass, choice, text}) => {
  /**
   * A statement that condition to render one of the other components, and if is anything else, it will return an empty fragment
   */
  if (choice.toLowerCase() === 'category') {
    return <AgCategory text={text} customClass={customClass} />
  } else if (choice.toLowerCase() === 'subcategory') {
    return <AgSubCategory text={text} customClass={customClass} />
  } else {
    return <></>
  }
}

export {AgAside}
