import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_gws/layout/core'
import {Glossary} from './Glossary'
import {getGlossaryData} from './core/_requests'
import {AlertFetchError, Splash} from '../../../_gws/helpers/misc'
import {mkProp} from '../../modules/auth'

/**
 * This component has the fetch logic and sends the dataglossary status
 */

const GlossaryWrapper: FC = () => {
  const [mkplace] = useState('growker')
  const intl = useIntl()
  const [glossaryData, setGlossaryData] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  /**
   * This function has the fetch logic and data management
   */

  const fetchData = async () => {
    Splash(true)
    try {
      const response = await getGlossaryData()
      Splash(false)
      if (response.status === 200) {
        setGlossaryData(response.data)
        setIsLoading(false)
      }
    } catch (error: any) {
      Splash(false)
      AlertFetchError(intl.locale, error).fire()
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/* header page */}
      <PageTitle
        breadcrumbs={[
          {
            title: intl.formatMessage({id: 'MENU.HOME'}),
            path: '/dashboard',
            isSeparator: false,
            isActive: false,
          },
          {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.GLOSSARY'})}
      </PageTitle>
      {!isLoading && (
        <Glossary mkplace={mkplace as mkProp} dataGlossary={glossaryData} isLoading={isLoading} />
      )}
    </>
  )
}

export default GlossaryWrapper
