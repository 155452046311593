import {addCommasInNumber, getFixedDecimals} from '../../../../_gws/helpers/misc'
import {mkProp} from '../../../modules/auth'
import {getPaidMarketingData} from './_requests'
import {Campaign, MarketingData, MarketingReport} from './types'

/*This function give us the number of days passed one month from today 
    Eg: if today is 15/8, from 15/7 to 15/8 are 31 days, but if today is 15/7, from 15/6 to 15/7 are 30 days.
    We need this when working with Amazon, because we will be working with info from the last month, while with Meli, we will get info from the last 30 days
   */
const daysMonth = (): number => {
  const today = new Date()
  const monthAgo = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate())

  const milisecondsDifference = monthAgo.getTime() - today.getTime()
  const milisecondsDay = 1000 * 60 * 60 * 24
  const daysDifference = Math.ceil(milisecondsDifference / milisecondsDay)
  return daysDifference
}
/** This function calculate the daily cost in advertising dividing the total cost for the number of days of the period */
const getDailyBudget = (cost: number, mkp: string): string => {
  const days = mkp === 'amazon' ? daysMonth() : 30
  return cost ? (cost / days).toString() : '0'
}

const formatCampaingnItems = (data: Campaign[]) => {
  const format = data.map((item: Campaign, i: number) => ({
    ...item,
    uniqueId: (item.uniqueId = i + new Date().getTime().toString()),
    cpc: `$${getFixedDecimals(item.cpc)}`,
    ctr: `${getFixedDecimals(item.ctr)}%`,
    acos: `${getFixedDecimals(item.acos)}%`,
    roas: `${getFixedDecimals(item.roas)}%`,
    cost: `$${addCommasInNumber(item.cost)}`,
  }))
  return format
}

export const getMarketingData = async ({marketplace, periodType}: {marketplace: mkProp, periodType: number}) => {
  if (!marketplace || marketplace === 'growker') return
  const [marketing] = await Promise.all([getPaidMarketingData(marketplace, periodType)])
  const data = marketing.data.detail as MarketingData
  const report: MarketingReport = {
    campaigns: formatCampaingnItems(data.campaigns),
    indicators: {
      campaign: data.campaign ? data.campaign.toString() : '0',
      convertions: data.convertions
        ? `${addCommasInNumber(Number(data.convertions.toFixed(2)))}`
        : '0',
      clicks: data.clicks ? `${addCommasInNumber(Number(data.clicks))}` : '0',
      cpc: data.cpc ? `$${addCommasInNumber(Number(data.cpc.toFixed(2)))}` : '0',
      tacos: data.tacos ? `${addCommasInNumber(Number(data.tacos.toFixed(2)))}%` : '0',
      acos: data.acos ? `${addCommasInNumber(Number(data.acos.toFixed(2)))}%` : '0',
      cost: data.cost ? `$${addCommasInNumber(Number(data.cost.toFixed(2)))}` : '0',
      impressions: data.impressions
        ? `${addCommasInNumber(Number(data.impressions.toFixed(2)))}`
        : '0',
      budget: data.cost
        ? `$${addCommasInNumber(Number(getDailyBudget(data.cost, marketplace)))}`
        : '0',
    },
  }
  return report
}
