import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageTitle} from '../../../../_gws/layout/core'
import {UsersListWrapper} from './users-list/UsersList'
import {useIntl} from 'react-intl'
import {UsersAssociatedListWrapper} from './associate/UsersAssociatedList'
import {DashboardMerchantContainer} from '../../../pages/dashboard/DashboardMerchantContainer'

const UsersPage = () => {
  const intl = useIntl()

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='users'
          element={
            <>
              <PageTitle breadcrumbs={[]}>
                {intl.formatMessage({id: 'MENU.USER_MANAGEMENT'})}
              </PageTitle>
              <UsersListWrapper />
            </>
          }
        />
        <Route
          path='clients'
          element={
            <>
              <PageTitle breadcrumbs={[]}>
                {intl.formatMessage({id: 'MENU.USER_MANAGEMENT'})}
              </PageTitle>
              <DashboardMerchantContainer />
            </>
          }
        />
        <Route
          path='associate/:userId'
          element={
            <>
              <PageTitle breadcrumbs={[]}>
                {intl.formatMessage({id: 'MENU.ASSOCIATIONS'})}
              </PageTitle>
              <UsersAssociatedListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/user-management/users' />} />
    </Routes>
  )
}

export default UsersPage
