import styled from '@emotion/styled'

type CellProps = {
  block?: boolean
}

export const Container = styled('div')({
  background: '#fff',
  borderRadius: '10px',
})

export const Table = styled('table')({
  overflow: 'auto',
  display: 'block',
  whiteSpace: 'nowrap',
  borderCollapse: 'collapse',
  background: '#f1f1f1',
  td: {
    textAlign: 'left',
    padding: '8px',
    "@media (max-width: 650px)": {
      padding: '8.6px',
    },
    background: '#fff',
  },
})

export const HeaderCell = styled('th')({
  textAlign: 'left',
  padding: '8px',
  background: '#F3F3F3',
})

export const FilterAction = styled('span')({
  marginLeft: '8px',
  cursor: 'pointer',
})

export const Cell = styled('td')<CellProps>(({block}) => ({
  background: block ? '#F3F3F3' : 'transparent',
  filter: block ? 'blur(5px)' : 'blur(0px)',
  userSelect: block ? 'none' : 'initial',
}))

export const ContentCell = styled('tr')({
  borderBottom: '1px solid #dddddd',
  textAlign: 'left',
  padding: '8px',
  ':last-child': {
    borderBottom: '1px solid transparent',
  },
})

export const WrapperTable = styled('div')({
  display: 'grid',
  gridTemplateColumns: '410px 1fr',
  "@media (max-width: 650px)": {
    gridTemplateColumns: '200px 1fr',
  }
})

export const ActionsTop = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export const TableTitle = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  padding: '20px',
  p: {
    marginLeft: '10px',
    color: '#9E9E9E',
  },
  '@media (max-width: 650px)': {
    flexFlow: "column",
    p: {
        marginLeft: '0px',
      },
  },
})

export const Search = styled('div')({
  display: 'flex',
  alignItems: 'center',
  input: {
    marginLeft: '10px',
    borderRadius: '8px',
    background: '#F6F6F6',
    border: 'none',
    padding: '8px',
    color: '#BBBBBB',
    ':focus': {
      outline: 'none',
    },
  },
})

export const WrapperActions = styled('div')({
  display: "grid",
  gridTemplateColumns: "200px 1fr 100px",
  gap: "1rem",
  padding: "0px 10px",
  '@media (max-width: 650px)': {
    display: "grid",
    gridTemplateColumns: "initial",
    gridTemplateRows: "1fr 1fr !important",
    gap: "1rem",
    padding: "10px"
  },
})

export const ExportCSV = styled('button')({
  padding: '5px',
  borderRadius: '8px',
})


export const WrapperPagination = styled('div')({})