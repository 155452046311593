import styled from '@emotion/styled'
import {KTSVG, toAbsoluteUrl} from '../../../../../_gws/helpers'
import {useState} from 'react'
import {ContentInventory} from '../ContentInventory/ContentInventory'
import {ContentMerketing} from '../ContentMerketing/ContentMerketing'
import {ContentSales} from '../ContentSales/ContentSales'
import { DiagnosticReport } from '../../core/types'

type TabProps = {
  active: boolean
}

const Tabs = styled('div')({
  display: 'flex',
  marginTop: '20px',
})

const Tab = styled('div')<TabProps>(({active}) => ({
  height: '80px',
  width: '130px',
  background: !active ? '#F7F7F7' : '#fff',
  borderRadius: '8px',
  marginRight: "15px",
  display: "grid",
  placeItems: "center",
  boxShadow: active ? '-1px 14px 19px -4px #919EAB33': 'none',
  cursor: 'pointer',
  transition: 'all ease .3s',
  border: active ? "1px solid #21212133": "none",
  h4: {
    color: !active ? '#9E9E9E' : '#000',
  },
}))

export const DiagnostikTabPrincipalContent = ({ report }: { report: DiagnosticReport }) => {
  const [activeTab, setActiveTab] = useState({
    label: 'inventory',
  })
  const handlerChangeTab = (value: string) => {
    setActiveTab((pre) => ({
      ...pre,
      label: value,
    }))
  }
  const tabs = [
    {
      label: 'inventory',
      text: 'Inventario',
      icon: 'media/icons/gws-icons/ordenes.svg',
    },
    {
      label: 'marketing',
      text: 'Marketing',
      icon: 'media/icons/gws-icons/aside-marketing.svg',
    },
    {
      label: 'sales',
      text: 'Ventas',
      icon: 'media/icons/gws-icons/ingreso-mensual.svg',
    },
  ]

  const content: any = {
    inventory: <ContentInventory report={report.inventory} />,
    marketing: <ContentMerketing  report={report.marketing} />,
    sales: <ContentSales  report={report.sales} />,
  }

  return (
    <div>
      <Tabs>
        {tabs.map((item) => (
          <Tab
            onClick={() => handlerChangeTab(item.label)}
            key={item.label}
            active={item.label === activeTab.label}
          >
            <div style={{
              display: "grid",
              placeItems: "center"
            }}>
              <KTSVG
                path={toAbsoluteUrl(item.icon)}
                className={`svg-icon-2x svg-icon-${
                  item.label === activeTab.label ? 'dark' : 'grey'
                }`}
              />
              <h4 style={{ marginTop: "10px" }}>{item.text}</h4>
            </div>
          </Tab>
        ))}
      </Tabs>
      <div style={{
        padding: '20px',
        height: '100%',
        boxShadow: '-1px 14px 19px -4px #919EAB33',
        borderRadius: "8px",
        marginTop: "30px",
      }}>
        <div>{content[activeTab.label]}</div>
      </div>
    </div>
  )
}
