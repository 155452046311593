import {IconBase} from './Base'

export const IconSales = ({color = '#273A51', size = 24}: {color?: string; size?: number}) => {
  const setColor = color
  return (
    <IconBase size={size} color={setColor} viewBox='0 0 10 10'>
      <svg
        width='10'
        height='10'
        viewBox='0 0 10 10'
        fill='none'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2 0C0.895431 0 0 0.89543 0 2V8C0 9.10457 0.89543 10 2 10H8C9.10457 10 10 9.10457 10 8V2C10 0.895431 9.10457 0 8 0H2ZM4.0476 6.14286H5.47617V5.57143H4.0476C3.91268 5.57143 3.79959 5.51667 3.70832 5.40714C3.61705 5.29762 3.57141 5.1619 3.57141 5V3.28571C3.57141 3.12381 3.61705 2.9881 3.70832 2.87857C3.79959 2.76905 3.91268 2.71429 4.0476 2.71429H4.52379C4.61109 2.60952 4.68054 2.4881 4.73213 2.35C4.78371 2.2119 4.873 2.14286 4.99998 2.14286C5.1349 2.14286 5.248 2.19762 5.33927 2.30714C5.43054 2.41667 5.47617 2.55238 5.47617 2.71429H5.95236C6.08728 2.71429 6.20038 2.76905 6.29165 2.87857C6.38292 2.9881 6.42855 3.12381 6.42855 3.28571C6.42855 3.44762 6.38292 3.58333 6.29165 3.69286C6.20038 3.80238 6.08728 3.85714 5.95236 3.85714H4.52379V4.42857H5.95236C6.08728 4.42857 6.20038 4.48333 6.29165 4.59286C6.38292 4.70238 6.42855 4.8381 6.42855 5V6.71429C6.42855 6.87619 6.38292 7.01191 6.29165 7.12143C6.20038 7.23095 6.08728 7.28571 5.95236 7.28571H5.47617C5.38887 7.39048 5.31943 7.5119 5.26784 7.65C5.21625 7.78809 5.12697 7.85714 4.99998 7.85714C4.86506 7.85714 4.75197 7.80238 4.6607 7.69286C4.56943 7.58333 4.52379 7.44762 4.52379 7.28571H4.0476C3.91268 7.28571 3.79959 7.23095 3.70832 7.12143C3.61705 7.01191 3.57141 6.87619 3.57141 6.71429C3.57141 6.55238 3.61705 6.41667 3.70832 6.30714C3.79959 6.19762 3.91268 6.14286 4.0476 6.14286Z'
          fill={color}
        />
      </svg>
    </IconBase>
  )
}
