import React, {useEffect, useState} from 'react'
import {ClientSelect} from './ClientSelect'
import {getlistClients} from './core/_request'

const ClientWrapper = () => {
  const [listClients, setListClients] = useState([])

  const fetchList = async () => {
    const response = await getlistClients()
    if (response.status === 200) {
      setListClients(response.detail)
    }
  }

  useEffect(() => {
    fetchList()
  }, [])

  return (
    <>
      <ClientSelect list={listClients} />
    </>
  )
}

export {ClientWrapper}
