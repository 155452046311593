import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import React from 'react'
import {useIntl} from 'react-intl'
import {ToolkitContextType} from 'react-bootstrap-table2-toolkit'
//@ts-ignore
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css'
import {addCommasInNumber, getFixedDecimals} from '../../../../_gws/helpers/misc'
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css'
import {CustomTotal} from '../../../../_gws/partials/tables/CustomTotal'
import {CustomSearchForm} from '../../../../_gws/partials/tables/CustomSearchForm'
import styled from '@emotion/styled'
import Form from 'react-bootstrap/Form'
import {
  headFormatter,
  headFormatterFK,
  optionsTable,
} from '../../../../_gws/partials/tables/TableHelper'
import { Campaign } from '../core/types'

type PropsCampaignTable = {
  dataReport?: any
  mkp?: string
  frankie?: boolean
}

type PaginationItemProps = {
  active: boolean
}

const PaginationItem = styled('div')<PaginationItemProps>(({active}) => ({
  padding: '0 12px',
  height: '30px',
  textAlign: 'center',
  display: 'flex',
  boxSizing: 'border-box',
  alignItems: 'center',
  fontSize: '13px',
  minWidth: '30px',
  color: active ? '#0D7CBD' : '#212121',
  border: `1px solid ${active ? '#0D7CBD' : '#212121'}`,
  borderRadius: '5px',
  margin: '0px 3px',
  cursor: 'pointer',
  transition: 'all ease .3s',
}))

export const CampaignsTable: React.FC<PropsCampaignTable> = ({dataReport, mkp, frankie}) => {
  const intl = useIntl()
  const copy = JSON.stringify(dataReport)
  const formatData = JSON.parse(copy).map((e: any, i: any) => ({
    ...e,
    uniqueId: (e.uniqueId = i + new Date().getTime().toString()),
    cpc: (e.cpc = '$' + getFixedDecimals(e.cpc)),
    ctr: (e.ctr = getFixedDecimals(e.ctr) + '%'),
    acos: (e.acos = getFixedDecimals(e.acos) + '%'),
    roas: (e.roas = getFixedDecimals(e.roas) + '%'),
    cost: (e.cost = '$' + getFixedDecimals(e.cost)),
    frankie: frankie && (e.sold = '$' + getFixedDecimals(e.sold)),
  }))

  const head = frankie ? headFormatterFK : headFormatter
  let columns = [
    {
      dataField: 'id',
      text: intl.formatMessage({id: 'REPORT.PAID_MARKETING.ID'}),
      sort: true,
      searchable: false,
      hidden: true,
      headerFormatter: head,
      style: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
    {
      dataField: 'name',
      text: intl.formatMessage({id: 'REPORT.PAID_MARKETING.CAMPAIGN_NAME'}),
      sort: true,
      searchable: true,
      headerFormatter: head,
      style: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
    {
      dataField: 'impressions',
      text: intl.formatMessage({id: 'REPORT.PAID_MARKETING.IMPRESSIONS'}),
      sort: true,
      searchable: false,
      headerFormatter: head,
    },
    {
      dataField: 'clicks',
      text: intl.formatMessage({id: 'REPORT.PAID_MARKETING.CLICKS'}),
      sort: true,
      headerFormatter: head,
      style: {
        width: '8%',
      },
    },
    {
      dataField: 'ctr',
      text: intl.formatMessage({id: 'REPORT.PAID_MARKETING.CTR'}),
      sort: true,
      searchable: false,
      headerFormatter: head,
      style: {
        width: '8%',
      },
    },
    {
      dataField: 'cpc',
      text: intl.formatMessage({id: 'REPORT.PAID_MARKETING.CPC'}),
      sort: true,
      searchable: false,
      headerFormatter: head,
      style: {
        width: '8%',
      },
    },
    {
      dataField: 'cost',
      text: intl.formatMessage({id: 'REPORT.PAID_MARKETING.COST'}),
      sort: true,
      searchable: false,
      headerFormatter: head,
      style: {
        width: '8%',
      },
    },
    {
      dataField: 'roas',
      text: intl.formatMessage({id: 'REPORT.PAID_MARKETING.ROAS'}),
      sort: true,
      searchable: false,
      headerFormatter: head,
      style: {
        width: '8%',
      },
    },
    {
      dataField: 'acos',
      text: intl.formatMessage({id: 'REPORT.PAID_MARKETING.ACOS'}),
      sort: true,
      searchable: false,
      headerFormatter: head,
      style: {
        width: '8%',
      },
    },
  ]
  frankie &&
    (columns = [
      ...columns,
      {
        dataField: 'sold',
        text: intl.formatMessage({id: 'REPORT.FRANKIE.TABLE.SOLD'}),
        sort: true,
        searchable: false,
        headerFormatter: head,
        style: {
          width: '8%',
        },
      },
    ])

  const customTotal1 = (from: number, to: number, size: number) => (
    <CustomTotal from={from} to={to} size={size} />
  )

  const customTotal2 = (from: number, to: number, size: number) => (
    <div className='mt-3 mb-4 justify-content-start align-items-center'>
      <span className='text-gray-600 fw-normal gy-5'>
        {from} {'→'} {to} {intl.formatMessage({id: 'REPORT.INVENTORY.FROM'})} {size}{' '}
      </span>
    </div>
  )

  const customTexts = {
    firstPageText: '',
    prePageText: '',
    nextPageText: '',
    lastPageText: '',
    nextPageTitle: '',
    prePageTitle: '',
    firstPageTitle: '',
    lastPageTitle: '',
  }

  const paginationCustom = frankie
    ? optionsTable(
        customTotal2,
        intl.locale,
        3,
        undefined,
        undefined,
        6,
        undefined,
        undefined,
        undefined,
        customTexts
      )
    : optionsTable(customTotal1, intl.locale, 3)
  return (
    <ToolkitProvider
      columns={columns}
      data={dataReport === undefined ? [] : formatData}
      keyField={'uniqueId'}
      search
    >
      {(props: ToolkitContextType) => (
        <div>
          {/* <CustomSearchForm
            placeholder={intl.formatMessage({id: 'REPORT.PAID_MARKETING.SEARCH_TERM'})}
            {...props.searchProps}
          /> 
          {!frankie && <div className='separator separator-dashed my-5'></div>}*/}
          <BootstrapTable
            {...props.baseProps}
            bootstrap4={true}
            striped={false}
            hover={true}
            pagination={paginationFactory(paginationCustom)}
            classes={`${
              !frankie
                ? 'table table-row-gray-300 gy-5 gs-5 table-row-dashed'
                : 'table table-row-gray-900 gy-3 gs-3'
            }`}
            wrapperClasses={`${
              !frankie
                ? 'table-responsive position-relative'
                : 'table-responsive position-relative mb-5'
            }`}
            bodyClasses={`${!frankie ? 'text-gray-600 fw-semibold' : 'text-gray-600 fw-normal'}`}
          />
        </div>
      )}
    </ToolkitProvider>
  )
}

export const TableCampaigns = ({ data }: { data: Campaign[] }) => {
  const intl = useIntl()
  function colFormatter(cell: any, row: any) {
    return <span> {addCommasInNumber(cell)} </span>
  }
  const columns = [
    {
      dataField: 'id',
      text: intl.formatMessage({id: 'REPORT.PAID_MARKETING.ID'}),
      sort: true,
      searchable: false,
      hidden: false,
      headerFormatter: headFormatter,
      style: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
    {
      dataField: 'name',
      text: intl.formatMessage({id: 'REPORT.PAID_MARKETING.CAMPAIGN_NAME'}),
      sort: true,
      searchable: true,
      headerFormatter: headFormatter,
      style: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
    {
      dataField: 'impressions',
      text: intl.formatMessage({id: 'REPORT.PAID_MARKETING.IMPRESSIONS'}),
      sort: true,
      searchable: false,
      formatter: colFormatter,
      headerFormatter: headFormatter,
    },
    {
      dataField: 'clicks',
      text: intl.formatMessage({id: 'REPORT.PAID_MARKETING.CLICKS'}),
      sort: true,
      headerFormatter: headFormatter,
      formatter: colFormatter,
      style: {
        width: '8%',
      },
    },
    {
      dataField: 'ctr',
      text: intl.formatMessage({id: 'REPORT.PAID_MARKETING.CTR'}),
      sort: true,
      searchable: false,
      headerFormatter: headFormatter,
      style: {
        width: '8%',
      },
    },
    {
      dataField: 'cpc',
      text: intl.formatMessage({id: 'REPORT.PAID_MARKETING.CPC'}),
      sort: true,
      searchable: false,
      headerFormatter: headFormatter,
      style: {
        width: '8%',
      },
    },
    {
      dataField: 'cost',
      text: intl.formatMessage({id: 'REPORT.PAID_MARKETING.COST'}),
      sort: true,
      searchable: false,
      headerFormatter: headFormatter,
      style: {
        width: '8%',
      },
    },
    {
      dataField: 'roas',
      text: intl.formatMessage({id: 'REPORT.PAID_MARKETING.ROAS'}),
      sort: true,
      searchable: false,
      headerFormatter: headFormatter,
      style: {
        width: '8%',
      },
    },
    {
      dataField: 'acos',
      text: intl.formatMessage({id: 'REPORT.PAID_MARKETING.ACOS'}),
      sort: true,
      searchable: false,
      headerFormatter: headFormatter,
      style: {
        width: '8%',
      },
    },
  ]

  const customTotal = (from: number, to: number, size: number) => (
    <CustomTotal from={from} to={to} size={size} />
  )

  const sizePerPageRenderer = ({options, currSizePerPage, onSizePerPageChange}: any) => (
    <Form.Select
      size='sm'
      value={currSizePerPage}
      onChange={(e) => onSizePerPageChange(e.target.value)}
      style={{width: '70px'}}
    >
      {options.map((option: any) => (
        <option key={option.page} value={option.page}>
          {option.text}
        </option>
      ))}
    </Form.Select>
  )

  const pageButtonRenderer = ({page, active, onPageChange}: any) => {
    const handleClick = (e: any) => {
      e.preventDefault()
      onPageChange(page)
    }
    return (
      <PaginationItem active={active} onClick={handleClick}>
        {page}
      </PaginationItem>
    )
  }

  const options = {
    pageButtonRenderer,
    sizePerPageRenderer,
    paginationTotalRenderer: customTotal,
    showTotal: false,
    disablePageTitle: true,
  }

  return (
    <ToolkitProvider
      columns={columns}
      data={data}
      keyField={'name'}
      search={{
        searchFormatted: true,
      }}
    >
      {(props: ToolkitContextType) => (
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <h4>{intl.formatMessage({id: 'REPORT.PAID_MARKETING.TABLE_TITLE'})}</h4>
            </div>
            <CustomSearchForm
              dataToExport={data}
              placeholder={intl.formatMessage({id: 'REPORT.PAID_MARKETING.SEARCH_TERM'})}
              {...props.searchProps}
            />
          </div>
          <div className='separator separator-line my-5'></div>
          <BootstrapTable
            {...props.baseProps}
            bootstrap4={true}
            striped={false}
            hover={true}
            pagination={paginationFactory(options)}
            classes={'table table-row-line table-row-gray-300 gy-5 gs-5'}
            wrapperClasses={'table-responsive position-relative'}
            bodyClasses={'text-gray-600 fw-semibold'}
          />
        </div>
      )}
    </ToolkitProvider>
  )
}




