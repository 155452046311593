import React, {FC} from 'react'
// @ts-ignore
import GaugeChart from 'react-gauge-chart'
import {useIntl} from 'react-intl'

type Props = {
  percent: number
  text?: string
  title: string
  description: string
  low: boolean
}
const BhGauge: FC<Props> = ({percent, title, description, low}) => {
  const intl = useIntl()
  return (
    <div className='card' style={{height: '100%'}}>
      <div className='card-header border-0'>
        <h3 className='card-title fw-semibold text-dark'>{title}</h3>
      </div>
      <div className='card-body'>
        <GaugeChart
          textColor='#a1a5b7'
          nrOfLevels={10}
          arcsLength={[0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]}
          colors={['#d24962', '#FFDA00', '#1bbc9c']}
          percent={percent / 100}
          arcPadding={0.03}
          cornerRadius={3}
          arcWidth={0.2}
          needleColor='#a1a5b7'
          needleBaseColor='#a1a5b7'
          formatTextValue={(value: any) => `${percent.toFixed(2)} %`}
        />
        <div className='text-center'>
          <span className='card-label fw-bold fs-3 mb-1'>
            {low
              ? intl.formatMessage({id: 'BRAND_HEALTH.TABLE.GAUGE.LOW'})
              : intl.formatMessage({id: 'BRAND_HEALTH.TABLE.GAUGE.GOOD'})}
            &nbsp;
          </span>
        </div>
      </div>
    </div>
  )
}

export {BhGauge}
