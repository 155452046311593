import {FC, MouseEventHandler} from 'react'
import { AgTextButton } from '../../layout/components/ag/components/AgTextButton'

type Props = {
  text: string
  color?: string
  bgColor: string
  customClass?: string
  clickFunction: MouseEventHandler<HTMLButtonElement>
}

/**
 * Component to show a button
 * @param text - string to add the button label
 * @param color - (optional)string to add the font color -var sass-
 * @param bgColor - string to add the background color -var sass-
 * @param customClass - (optional)string to pass aditional style -var sass-
 * @param clickFunction - function to run when the button is clicked.
 */

const BasicButton: FC<Props> = ({text, color = 'dark', bgColor, customClass, clickFunction}) => {
  const buttonStyle = {
    minWidth: '6.187em',
    borderRadius: '0.25em',
  }

  return (
    <button
      className={`border-0 d-flex py-1 px-5 justify-content-center align-items-center text-${color} fourStep bg-${bgColor} ${customClass} rounded-1`}
      style={buttonStyle}
      onClick={clickFunction}
    >
      <AgTextButton text={text} />
    </button>
  )
}

export {BasicButton}
