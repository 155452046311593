/* eslint-disable jsx-a11y/anchor-is-valid */
import {useLayout} from '../../core'
import {DefaultTitle} from './page-title/DefaultTitle'
import {UserHeaderToolbar} from '../aside/UserHeaderToolbar'
import React from 'react'

type Props = {
  playlistItems?: number
}

const HeaderToolbar: React.FC<Props> = ({playlistItems}) => {
  const {classes} = useLayout()

  // let notificationButtonList = [
  //   {
  //     id: 1,
  //     svgIcon: '/media/icons/unicos/075-bookmark.svg',
  //     to: '/playlist',
  //     tooltip: 'Playlist',
  //     notificationNumber: abbreviateNumber(playlistItems, 0),
  //   },
  //   {
  //     id: 2,
  //     svgIcon: '/media/icons/unicos/004-chat.svg',
  //     to: '/error/wip',
  //     tooltip: 'Tickets',
  //   },
  //   {
  //     id: 3,
  //     svgIcon: '/media/icons/unicos/001-notification.svg',
  //     to: '/error/wip',
  //     tooltip: 'Mensajes',
  //   },
  // ]
  // if (currentUser && currentUser.roleId && currentUser.roleId > 1) {
  //   notificationButtonList = []
  // }

  return (
    <div
      style={{
        padding: '0px 12px',
        borderLeft: "1px solid #0000000D",
        background: "#fff",
        marginLeft: "-1px"
      }}
      className='toolbar d-flex align-items-stretch'
    >
      {/* begin::Toolbar container */}
      <div className={`${classes.headerContainer.join(' ')} py-6 py-lg-0  px-lg-8 d-flex`}>
        <DefaultTitle />
        {/* ToDo: Barra de búsqueda*/}
        {/* <div className=' align-items-stretch d-none d-md-flex'>
          <div className='d-flex align-items-center position-relative'>
            <KTSVG
              path='/media/icons/unicos/032-search.svg'
              className='svg-icon svg-icon-3 position-absolute ms-4 text-gray-900'
            />
            <input
              className='form-control form-control-solid form-control-lg ps-14 bg-light fs-8 fw-light input-search-topbar'
              type='text'
              placeholder='Búsqueda'
            />
          </div>
        </div> */}
        <div className='d-flex align-items-stretch'>
          {/*<div className='d-flex align-items-center mx-3'>*/}
          {/*  {notificationButtonList.map((notificationButton: any) => (*/}
          {/*    <NotificationButton*/}
          {/*      key={notificationButton.id}*/}
          {/*      svgIcon={notificationButton.svgIcon}*/}
          {/*      to={notificationButton.to}*/}
          {/*      tooltip={notificationButton.tooltip}*/}
          {/*      notificationNumber={notificationButton.notificationNumber}*/}
          {/*    />*/}
          {/*  ))}*/}
          {/*</div>*/}
          <div className='d-flex align-items-center'>
            <div className='d-flex'>
              <UserHeaderToolbar />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {HeaderToolbar}
