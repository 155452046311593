/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {KTSVG} from '../../../helpers'
import {ElementWithIcon} from '../../content/list/ElementWithIcon'
import {useIntl} from 'react-intl'

const InventoryReportModal: FC = () => {
  const intl = useIntl()
  return (
    <>
      <div className='modal fade' id='info_in1_report_modal' aria-hidden='true'>
        <div className='modal-dialog mw-550px modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='text-center mb-13'>
              <h1 className='mb-3'>
                {intl.formatMessage({id: 'REPORT.INVENTORY.MODAL.KPI_TITLE'})}
              </h1>
            </div>

            <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
              <ElementWithIcon
                icon={'/media/icons/duotune/ecommerce/ecm001.svg'}
                title={intl.formatMessage({id: 'REPORT.INVENTORY.TOTAL.AVAILABLE'})}
                description={intl.formatMessage({
                  id: 'REPORT.INVENTORY.TOTAL.AVAILABLE_DESCRIPTION',
                })}
              />
              <ElementWithIcon
                icon={'/media/icons/duotune/ecommerce/ecm009.svg'}
                title={intl.formatMessage({id: 'REPORT.INVENTORY.TOTAL.UNIQUE'})}
                description={intl.formatMessage({id: 'REPORT.INVENTORY.TOTAL.UNIQUE_DESCRIPTION'})}
              />

              <div className='d-flex align-items-start border border-gray-300 border-dashed rounded p-5'>
                <div className='symbol symbol-30px me-5'>
                  <span className='symbol-label'>
                    <KTSVG
                      path={'/media/icons/duotune/communication/com009.svg'}
                      className='svg-icon-1x svg-icon-dark'
                    />
                  </span>
                </div>
                <div className='flex-grow-1 me-2'>
                  <span className='fw-bold text-gray-800 text-hover-primary fs-6'>
                    {intl.formatMessage({id: 'REPORT.MODALS.EXAMPLE_TITLE'})}
                  </span>
                  <div className='text-muted fw-regular d-block'>
                    5 {intl.formatMessage({id: 'REPORT.MODALS.EXAMPLE_CONTENT_INVENTORY_TEXT1'})}
                    <br />
                    10 {intl.formatMessage({id: 'REPORT.MODALS.EXAMPLE_CONTENT_INVENTORY_TEXT2'})}
                    <br />4{' '}
                    {intl.formatMessage({id: 'REPORT.MODALS.EXAMPLE_CONTENT_INVENTORY_TEXT3'})}
                    <br />
                    <br />
                    <span className='fw-bold text-gray-800 text-hover-primary fs-6'>
                      {intl.formatMessage({id: 'REPORT.INVENTORY.TOTAL.AVAILABLE'})}:
                    </span>
                    &nbsp;19
                    <br />
                    <span className='fw-bold text-gray-800 text-hover-primary fs-6'>
                      {intl.formatMessage({id: 'REPORT.INVENTORY.TOTAL.UNIQUE'})}:
                    </span>
                    &nbsp;3
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' id='info_in2_report_modal' aria-hidden='true'>
        <div className='modal-dialog mw-550px modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='text-center mb-13'>
              <h1 className='mb-3'>
                {intl.formatMessage({id: 'REPORT.INVENTORY.MODAL.TABLE_TITLE'})}
              </h1>
            </div>

            <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
              <ElementWithIcon
                icon={''}
                title={`${intl.formatMessage({id: 'REPORT.INVENTORY.TABLE.HEAD.ID'})}`}
                description={intl.formatMessage({
                  id: 'REPORT.INVENTORY.TABLE.HEAD.ID_DESC',
                })}
              />
              <ElementWithIcon
                icon={''}
                title={`${intl.formatMessage({id: 'REPORT.INVENTORY.TABLE.HEAD.ASIN'})}`}
                description={intl.formatMessage({
                  id: 'REPORT.STATE_RESULT.TABLE_HEAD.ASIN_DESC',
                })}
              />
              <ElementWithIcon
                icon={''}
                title={intl.formatMessage({id: 'REPORT.INVENTORY.TABLE.HEAD.QUANTITY'})}
                description={intl.formatMessage({id: 'REPORT.INVENTORY.TABLE.HEAD.QUANTITY_DESC'})}
              />
              <ElementWithIcon
                icon={''}
                title={intl.formatMessage({id: 'REPORT.INVENTORY.TABLE.HEAD.NAME'})}
                description={intl.formatMessage({id: 'REPORT.INVENTORY.TABLE.HEAD.NAME_DESC'})}
              />
              <ElementWithIcon
                icon={''}
                title={intl.formatMessage({id: 'REPORT.INVENTORY.TABLE.HEAD.STATE'})}
                description={intl.formatMessage({id: 'REPORT.INVENTORY.TABLE.HEAD.STATE_DESC'})}
              />
              <ElementWithIcon
                icon={''}
                title={intl.formatMessage({id: 'REPORT.INVENTORY.TABLE.HEAD.SKU1'})}
                description={intl.formatMessage({id: 'REPORT.INVENTORY.TABLE.HEAD.SKU1_DESC'})}
              />
              <ElementWithIcon
                icon={''}
                title={intl.formatMessage({id: 'REPORT.INVENTORY.TABLE.HEAD.SKU2'})}
                description={intl.formatMessage({id: 'REPORT.INVENTORY.TABLE.HEAD.SKU2_DESC'})}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {InventoryReportModal}
