import {ReactElement, useState} from 'react'

import {Btn} from './styled'
import {IconAmazon, IconMeli} from '../../playlist/components/PlayListIcons'
import {useIntl} from 'react-intl'
import {makeURL} from '../../../../_gws/helpers/misc'
import Modal from 'react-bootstrap/Modal'
import MDEditor from '@uiw/react-md-editor'
import parse from 'html-react-parser'
import {CloseButton} from 'react-bootstrap'
import {brandHealthStateList} from './BrandhealthTableDetails'
import {useParams} from 'react-router-dom'
import {BRAND_HEALTH_CATEGORY_TYPES} from '../../../constants/marketplaces'

const priorityKeys: Record<number, string> = {
  1: 'CRITIQUE',
  2: 'DELICATE',
  3: 'GOOD',
  0: 'OPTIMAL',
}

const MKP: Record<number, string> = {
  1: 'amazon',
  2: 'meli',
}

const flex = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export const IconCritical = () => {
  return (
    <svg width='45' height='45' viewBox='0 0 45 45' fill='none'>
      <mask id='mask0_15_2988' maskUnits='userSpaceOnUse' x='0' y='0' width='45' height='45'>
        <rect width='45' height='45' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_15_2988)'>
        <path
          d='M22.5 31.875C23.0312 31.875 23.4766 31.6953 23.8359 31.3359C24.1953 30.9766 24.375 30.5312 24.375 30C24.375 29.4688 24.1953 29.0234 23.8359 28.6641C23.4766 28.3047 23.0312 28.125 22.5 28.125C21.9688 28.125 21.5234 28.3047 21.1641 28.6641C20.8047 29.0234 20.625 29.4688 20.625 30C20.625 30.5312 20.8047 30.9766 21.1641 31.3359C21.5234 31.6953 21.9688 31.875 22.5 31.875ZM20.625 24.375H24.375V13.125H20.625V24.375ZM22.5 41.25C19.9062 41.25 17.4688 40.7578 15.1875 39.7734C12.9062 38.7891 10.9219 37.4531 9.23438 35.7656C7.54687 34.0781 6.21094 32.0938 5.22656 29.8125C4.24219 27.5313 3.75 25.0938 3.75 22.5C3.75 19.9062 4.24219 17.4688 5.22656 15.1875C6.21094 12.9062 7.54687 10.9219 9.23438 9.23438C10.9219 7.54687 12.9062 6.21094 15.1875 5.22656C17.4688 4.24219 19.9062 3.75 22.5 3.75C25.0938 3.75 27.5313 4.24219 29.8125 5.22656C32.0938 6.21094 34.0781 7.54687 35.7656 9.23438C37.4531 10.9219 38.7891 12.9062 39.7734 15.1875C40.7578 17.4688 41.25 19.9062 41.25 22.5C41.25 25.0938 40.7578 27.5313 39.7734 29.8125C38.7891 32.0938 37.4531 34.0781 35.7656 35.7656C34.0781 37.4531 32.0938 38.7891 29.8125 39.7734C27.5313 40.7578 25.0938 41.25 22.5 41.25ZM22.5 37.5C26.6875 37.5 30.2344 36.0469 33.1406 33.1406C36.0469 30.2344 37.5 26.6875 37.5 22.5C37.5 18.3125 36.0469 14.7656 33.1406 11.8594C30.2344 8.95313 26.6875 7.5 22.5 7.5C18.3125 7.5 14.7656 8.95313 11.8594 11.8594C8.95313 14.7656 7.5 18.3125 7.5 22.5C7.5 26.6875 8.95313 30.2344 11.8594 33.1406C14.7656 36.0469 18.3125 37.5 22.5 37.5Z'
          fill='#212121'
        />
      </g>
    </svg>
  )
}

export const IconDelicate = () => {
  return (
    <svg width='45' height='45' viewBox='0 0 45 45' fill='none'>
      <mask id='mask0_15_2994' maskUnits='userSpaceOnUse' x='0' y='0' width='45' height='45'>
        <rect width='45' height='45' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_15_2994)'>
        <path
          d='M22.5 41.2969C22 41.2969 21.5234 41.2031 21.0703 41.0156C20.6172 40.8281 20.2031 40.5625 19.8281 40.2188L4.78125 25.1719C4.4375 24.7969 4.17188 24.3828 3.98438 23.9297C3.79688 23.4766 3.70312 23 3.70312 22.5C3.70312 22 3.79688 21.5156 3.98438 21.0469C4.17188 20.5781 4.4375 20.1719 4.78125 19.8281L19.8281 4.78125C20.2031 4.40625 20.6172 4.13281 21.0703 3.96094C21.5234 3.78906 22 3.70312 22.5 3.70312C23 3.70312 23.4844 3.78906 23.9531 3.96094C24.4219 4.13281 24.8281 4.40625 25.1719 4.78125L40.2188 19.8281C40.5938 20.1719 40.8672 20.5781 41.0391 21.0469C41.2109 21.5156 41.2969 22 41.2969 22.5C41.2969 23 41.2109 23.4766 41.0391 23.9297C40.8672 24.3828 40.5938 24.7969 40.2188 25.1719L25.1719 40.2188C24.8281 40.5625 24.4219 40.8281 23.9531 41.0156C23.4844 41.2031 23 41.2969 22.5 41.2969ZM22.5 37.5469L37.5469 22.5L22.5 7.45313L7.45313 22.5L22.5 37.5469ZM20.625 24.375H24.375V13.125H20.625V24.375ZM22.5 30C23.0313 30 23.4766 29.8203 23.8359 29.4609C24.1953 29.1016 24.375 28.6563 24.375 28.125C24.375 27.5938 24.1953 27.1484 23.8359 26.7891C23.4766 26.4297 23.0313 26.25 22.5 26.25C21.9688 26.25 21.5234 26.4297 21.1641 26.7891C20.8047 27.1484 20.625 27.5938 20.625 28.125C20.625 28.6563 20.8047 29.1016 21.1641 29.4609C21.5234 29.8203 21.9688 30 22.5 30Z'
          fill='#212121'
        />
      </g>
    </svg>
  )
}

export const ShowOutputDescription = ({
  showModal,
  handlerClouse,
  message,
  externalLink,
}: {
  showModal: boolean
  handlerClouse: any
  message: string
  externalLink: string | undefined
}) => {
  return (
    <Modal size='lg' centered show={showModal}>
      <Modal.Header className={'justify-content-between px-10 py-5'}>
        <h4>Producto: {externalLink && parse(externalLink)} </h4>
        <CloseButton onClick={handlerClouse} />
      </Modal.Header>
      <Modal.Body className='px-10 py-5'>
        <div data-color-mode='light'>
          <MDEditor.Markdown source={message} />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export const RenderOutputDescription = ({
  description,
  marketplaceId,
  item,
  priority,
  type,
}: {
  description: string
  marketplaceId: number
  item: string
  priority: number
  type: number
}) => {
  const params = useParams()
  const [isOpen, setIsOpen] = useState(false)
  const intl = useIntl()
  const isNotCategory4 = params.ind !== BRAND_HEALTH_CATEGORY_TYPES.CATEGORY_4
  let externalLink = isNotCategory4 ? makeURL(description, marketplaceId, item) : ''
  const formatPrefix = intl.formatMessage({
    id: `PLAYLIST.TABLE.DESCRIPTION.TYPE_${type}.PRIO_${priority}.MKP_${marketplaceId}.PREFIX.${priorityKeys[priority]}`,
  })
  const formatSuffix = intl.formatMessage({
    id: `PLAYLIST.TABLE.DESCRIPTION.TYPE_${type}.PRIO_${priority}.MKP_${marketplaceId}.SUFFIX.${priorityKeys[priority]}`,
  })
  const prefix =
    formatPrefix === 'N/A' ? `N/A -> type ${type} - priority ${priority}` : formatPrefix
  const suffix = formatSuffix === 'N/A' ? '' : formatSuffix

  const suffixFormated = {
    suffix: suffix.length > 200 ? suffix.slice(0, 200) + '...' : `${prefix} ${suffix}`,
    isLarge: suffix.length > 200 ? true : false,
  }
  const LINK = `
    <p>
    👉 Haz clic aquí
        <a href='/product-health/${MKP[marketplaceId]}/${item}' target='_blank' className='mx-2' rel='noreferrer'>
            ${item}
        </a>
      para refinar y mejorar aún más el rendimiento de tu producto
    </p>
    `
  const TEMPLATE = `${prefix}${suffix}${isNotCategory4 ? LINK : ''}`

  return (
    <div>
      <Btn onClick={() => setIsOpen(true)}>{suffixFormated.suffix}</Btn>
      <ShowOutputDescription
        showModal={isOpen}
        handlerClouse={() => setIsOpen((pre) => !pre)}
        message={TEMPLATE}
        externalLink={externalLink}
      />
    </div>
  )
}

export const RenderMarketplace = ({marketplace}: {marketplace: number}) => {
  const flex = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
  const typeMarketplace: Record<number, ReactElement> = {
    1: <IconAmazon />,
    2: <IconMeli />,
  }
  return <div style={{...flex}}>{typeMarketplace[marketplace]}</div>
}

export const RenderPriority = ({priority}: {priority: number}) => {
  const intl = useIntl()
  const flex = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: brandHealthStateList[priority].color,
  }
  return (
    <div style={{...flex}}>
      {intl.formatMessage({
        id: `${brandHealthStateList[priority].label}`,
      })}
    </div>
  )
}

export const RenderSelect = ({
  selectItem,
  id,
  checked,
}: {
  selectItem: any
  id: string
  checked: boolean
}) => {
  return (
    <div style={{...flex}}>
      <input type='checkbox' checked={checked} onChange={() => selectItem(id)} />
    </div>
  )
}

export const RenderSelectAll = ({selectAll}: {selectAll: any}) => {
  return (
    <div style={{...flex}}>
      <input type='checkbox' onChange={selectAll} />
    </div>
  )
}
