/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import CountUp from 'react-countup'
import {abbreviateNumberSplited} from '../../../helpers/misc'

type Props = {
  className: string
  color: string
  title: string
  titleColor?: string
  description: string
  descriptionColor?: string
  height?: string
  tooltip?: string
  countUp?: boolean
  decimal?: boolean
  prefix?: string
  metrix?: string
  minimal?: boolean
  svgIcon?: string
  iconColor?: string
  hasAbbbreviate?: boolean
}

const ResultsCard: React.FC<Props> = ({
  className,
  color,
  title,
  titleColor,
  description,
  descriptionColor,
  height,
  tooltip,
  countUp,
  decimal,
  prefix,
  metrix,
  minimal,
  svgIcon,
  iconColor,
  hasAbbbreviate

}) => {
  const countUpState = countUp !== true ? false : true
  const number = Number(title)
  const numberTrans = abbreviateNumberSplited(number, 1)
  let hideBigger = false

  let numberAb = number
  let metrixAb = ''
  if (hasAbbbreviate &&  numberTrans !== null) {
    numberAb = numberTrans[0]
    metrixAb = numberTrans[1]
  }

  if (minimal !== undefined) {
    hideBigger = minimal
  }

  const fontCss = {
    fontSize: '32px',
    linHeight: '23px',
  }

  return (
    <div
      className={`card bg-${color} hoverable ${className}`}
      style={{
        backgroundImage: 'url(/media/svg/shapes/background-dark-opacity.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '18px 48px',
        backgroundSize: '30px',
        height: `${height}`,
      }}
    >
      <div className='card-body card-body pb-0 pt-4 pe-5 ps-6'>
        <div className='row h-100'>
          <div className='col-12 position-relative'>
            {svgIcon && <KTSVG path={svgIcon} className={`svg-icon-${iconColor} svg-icon-2x`} />}
            <div className={`d-block fs-3 fw-semibold text-${descriptionColor}`}>
              {description}&nbsp;
              {tooltip && (
                <OverlayTrigger
                  placement='top'
                  overlay={<Tooltip id={`tooltip-top`}>{tooltip}</Tooltip>}
                  defaultShow={false}
                >
                  <div
                    className='symbol symbol-50px me-5'
                    data-balloon-pos='up'
                    aria-label={tooltip}
                  >
                    <span>
                      <KTSVG
                        path={toAbsoluteUrl('/media/icons/duotune/general/gen046.svg')}
                        className={`svg-icon-1x svg-icon-dark`}
                      />
                    </span>
                  </div>
                </OverlayTrigger>
              )}
            </div>
            <div className={`text-${titleColor} fw-bold  position-absolute bottom-0 end-0`}>
              <div className='text-end m-auto d-block pe-2' style={fontCss}>
                {prefix}
                {countUpState && decimal && (
                  <>
                    <CountUp
                      className={hideBigger ? 'd-none' : 'd-none d-md-inline-block'}
                      end={number}
                      separator={','}
                      decimals={2}
                    />
                    <CountUp
                      className={hideBigger ? 'd-inline-block' : 'd-inline-block d-md-none'}
                      end={Number(numberAb)}
                      separator={','}
                      decimals={2}
                    />
                  </>
                )}
                {countUpState && !decimal && (
                  <>
                    <CountUp
                      className={hideBigger ? 'd-none' : 'd-none d-md-inline-block'}
                      end={number}
                      separator={','}
                    />
                    <CountUp
                      className={hideBigger ? 'd-inline-block' : 'd-inline-block d-md-none'}
                      end={Number(numberAb)}
                      separator={','}
                    />
                  </>
                )}
                {!countUpState && title}
                {
                  <span className={hideBigger ? 'd-inline-block' : 'd-inline-block d-md-none'}>
                    {metrixAb}
                  </span>
                }
                {metrix}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ResultsCard}
