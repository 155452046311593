type TypeTask = {
  OUTPUT: 'OUTPUT'
  CUSTOM: 'CUSTOM'
}

export const TYPETASK: TypeTask = {
  OUTPUT: 'OUTPUT',
  CUSTOM: 'CUSTOM',
}

export type Status = {
  NotStarted: 1
  InProgress: 2
  Finalized: 3
}

export const TYPESTATUS: Status = {
  NotStarted: 1,
  InProgress: 2,
  Finalized: 3,
}
