/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {User} from '../core/_models'

import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css'
import {TableContainer} from './TableContainer'
import {getRolesList, getSaasPlans} from '../core/_requests'

type listUser = {
  listUser?: Array<User>
}
/**
 * Wrapper for the Users Table
 * @param listUser - List of users
 * @component
 */
const TableWrapper: FC<listUser> = ({listUser}) => {
  /* Creating an object with the roles values of the array listUser. */
  const listRoles = Array.from(
    new Set(
      listUser?.map((user) => {
        return user.roleId
      })
    )
  )

  const [saasPlans, setSaasPlans] = useState([])
  const [roles, setRoles] = useState([])

  useEffect(() => {
    /**
     * Requesting Catalogs
     */
    const loadCatalogs = async () => {
      try {
        /**
         * Getting & Assing Plans
         */
        const responseSaasPlans = await getSaasPlans()
        if (responseSaasPlans.data.status === 200) {
          setSaasPlans(responseSaasPlans.data.detail)
        }

        /**
         * Getting & Assing Roles
         */
        const responseRoles = await getRolesList()
        if (responseRoles.data.status === 200) {
          setRoles(responseRoles.data.detail)
        }
      } catch (error) {
        console.log(error)
      }
    }

    loadCatalogs()
  }, [])

  return (
    <>
      <TableContainer
        listUser={listUser}
        listRoles={listRoles}
        saasPlans={saasPlans}
        roles={roles}
      />
    </>
  )
}

export {TableWrapper}
