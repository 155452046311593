import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_gws/layout/core'
import {useEffect, useState} from 'react'
import {getBrandHealthData} from './core/_requests'
import {BrandHealth} from './BrandHealth'
import {BrandHealthReportModal} from '../../../_gws/partials/modals/reports/BrandHealthReportModal'
import {HeaderReport} from '../../modules/apps/header/HeaderReport'
import 'shepherd.js/dist/css/shepherd.css'
import {AlertFetchError, Splash} from '../../../_gws/helpers/misc'
import {WaitingScreen} from '../../../_gws/partials/content/waiting/WaitingScreen'
import useSWR from 'swr'
import {useAuth} from '../../modules/auth'
import {BannerHelp} from '../../modules/apps/BannerHelp/BannerHelp'
import { useNavigate } from 'react-router-dom'

const BrandHealthWrapper = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [showReports, setShowReports] = useState(false)
  const [mkpData, setMkpData] = useState('')
  const {currentMkp, setCurrentMkp} = useAuth()
  const hasBeenVerified = localStorage.getItem('verified') === 'true'

  
  const {data, error, isLoading} = useSWR(
    () => (currentMkp ? [currentMkp, 0] : null),
    () => getBrandHealthData(currentMkp, 0),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    }
  )

  useEffect(() => {
    if (!data && error) {
      setShowReports(false)
      AlertFetchError(intl.locale, error).fire()
    }
    if (data && !error) {
      setMkpData(data.data)
      setShowReports(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error])

  useEffect(() => {
    Splash(isLoading)
  }, [isLoading])

  useEffect(() => {
    if (!hasBeenVerified) return navigate('/account/overview')
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasBeenVerified])
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: intl.formatMessage({id: 'MENU.HOME'}),
            path: '/dashboard',
            isSeparator: false,
            isActive: false,
          },
          {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.BRAND_HEALTH'})}
      </PageTitle>
      {hasBeenVerified ? (
        <>
          <BannerHelp linkToDoc='https://docs.growker.ai/kb/brandhealth/' title='HELP.TITLE.BRAND_HEALTH' text='HELP.TEXT.BRAND_HEALTH' />
          <BrandHealthReportModal />
          <HeaderReport
            hasMarketPlace={true}
            hasDate={false}
            hasFilter={false}
            growkerMetric={true}
            setMkplace={setCurrentMkp}
            activeMkp={currentMkp}
          />
          {showReports && <BrandHealth objectCategory={mkpData} mkp={currentMkp as string} />}
        </>
      ) : (
        <WaitingScreen />
      )}
    </>
  )
}

export {BrandHealthWrapper}
