import {AgLabelSmallText} from '../../../layout/components/ag/components/AgLabelSmallText'

type Props = {
  text: string
  backgroundColor?: string
  color?: string
  customClass?: string
}

/**
 * This Component create a Basic Label
 *
 * @param text - string with the text
 * @param backgroundColor - background Color for the component. (variable sass)
 * @param color - color for text-component (variable sass)
 */

const BasicLabel: React.FC<Props> = ({text, color, backgroundColor, customClass}) => {
  const labelStyles = {
    height: '1.13em',
  }
  return (
    <div
      className={`d-flex border-0 text-uppercase px-1 bg-${backgroundColor} ${customClass}`}
      style={labelStyles}
    >
      <AgLabelSmallText customClass={`text-${color}`} text={text} />
    </div>
  )
}

export {BasicLabel}
