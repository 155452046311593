import styled from '@emotion/styled'
import {toAbsoluteUrl} from '../../../_gws/helpers'
import MDEditor from '@uiw/react-md-editor'
import './style.css'
import { useIntl } from 'react-intl'

const Container = styled('div')({})

const Card = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '1rem',
  borderRadius: '8px',
  padding: '20px',
  margin: "20px 0px",
  '@media (max-width: 800px)': {
    gridTemplateColumns: '1fr',
  },
})

const Content = styled('div')({})

const ContentImg = styled('div')({
  display: 'grid',
  placeItems: 'center',
  '@media (max-width: 800px)': {
    placeItems: 'initial',
  },
  img: {
    width: '80%',
    display: 'flex',
    '@media (max-width: 800px)': {
      width: '100%',
    },
  },
})

const SectionBeeper = ({
  bg = '#E2EBD3',
  revert = false,
  content,
  imgPath,
}: {
  bg?: string
  revert?: boolean
  content: string
  imgPath: string
}) => {
  return (
    <Card style={{background: bg}}>
      {revert && (
        <ContentImg>
          <img alt='beeper' src={toAbsoluteUrl(`/media/backgrounds/${imgPath}`)} />
        </ContentImg>
      )}
      <Content>
        <div data-color-mode='light'>
          <MDEditor.Markdown source={content} />
        </div>
      </Content>
      {!revert && (
        <ContentImg>
          <img alt='beeper' src={toAbsoluteUrl(`/media/backgrounds/${imgPath}`)} />
        </ContentImg>
      )}
    </Card>
  )
}

export const Beeper = () => {
  const intl = useIntl()
  return (
    <Container>
      <SectionBeeper bg='#E2EBD3' imgPath='beeper-step-1.png' content={intl.formatMessage({id: 'BEEPER.STEP.1'})} />
      <SectionBeeper bg='#F9F6E1' revert={true} imgPath='beeper-step-2.png' content={intl.formatMessage({id: 'BEEPER.STEP.2'})} />
      <SectionBeeper bg='#FCF3E9' imgPath='beeper-step-3.png' content={intl.formatMessage({id: 'BEEPER.STEP.3'})} />
      <SectionBeeper bg='#F2FCFD' revert={true} imgPath='beeper-step-4.png' content={intl.formatMessage({id: 'BEEPER.STEP.4'})} />
    </Container>
  )
}
