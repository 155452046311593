import axios from 'axios'

/**
 * API ENV Parameter
 */
const API_URL_CRUNCH = process.env.REACT_APP_BACKEND_MAIN_API

/**
 * URL build for api request
 */
export const GET_USERS_FROM_MERCHANTS_URL = `${API_URL_CRUNCH}/api/users/merchants/clients/`

/**
 * It returns a promise that will resolve to the response of the GET request to the URL GET_USERS_FROM_MERCHANTS_URL +
 * idMerchant
 * @param {number} idMerchant - The id of the merchant you want to get the users from.
 * @returns An array of users
 */
export function getUsersAssociated(idMerchant: number) {
  return axios.get(GET_USERS_FROM_MERCHANTS_URL + idMerchant)
}

/**
 * URL builder
 */
export const GET_USER_DETAIL_URL = `${API_URL_CRUNCH}/api/users/`

/**
 * It returns the merchant information
 * @param {number} idMerchant - The merchant ID
 */
export function getMerchantDetails(idMerchant: number) {
  return axios.get(GET_USER_DETAIL_URL + idMerchant)
}

/**
 * URL builder
 */
export const GET_USERS = `${API_URL_CRUNCH}/api/users/list`
/**
 * Get all the users for list as users available to associate
 * @returns An object with a property called data, which is an array of objects.
 */
export function getUsers() {
  return axios.get(GET_USERS + '/1')
}

/**
 * URL Builder
 */
export const ASSOCIATE_USER = `${API_URL_CRUNCH}/api/users/merchants/association`
/**
 * It associates a user with a client
 * @param {number} idUser - The id of the user you want to associate with the client
 * @param {number} idClient - The client's ID
 * @returns The response from the server.
 */
export function associateUser(idUser: number, idClient: number) {
  return axios.post(ASSOCIATE_USER, {
    clientId: idClient,
    merchantId: idUser,
  })
}
