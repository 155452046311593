const flex = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}
export const RenderSelectAll = ({ selectAction, indexTable }: { selectAction: any, indexTable: number }) => {
  const handlerSelectAll = (checked: boolean) => selectAction(checked, indexTable)
  return (
    <div style={{...flex}}>
      <input onClick={(e:any) => handlerSelectAll(e.target.checked)} type='checkbox' />
    </div>
  )
}
