import styled from '@emotion/styled'

type AlertProps = {
  error: boolean
}

export const Container = styled('div')({
  marginTop: '10px',
})

export const WrapperColumn = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '1rem',
  margin: '15px 0px',
})

export const Alert = styled('div')<AlertProps>(({error}) => ({
  background: "#fff",
  borderRadius: '8px',
  padding: '7px',
  color: error ? '#F54646' : '#12A150',
  border: `1px solid ${error ? '#F54646' : '#12A150'}`
}))

export const WrapperFlex = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '10px 0px',
})

export const Group = styled('div')({
  margin: '15px 0px',
})
