import {OriginType, PriorityType, ReferenceType, StatusType} from '../../types'
import Offcanvas from 'react-bootstrap/Offcanvas'
import {PlaylistTab} from '../PlaylistTab'
import {useState} from 'react'
import {Alert, Container} from './styled'
import {RenderForm} from './Form'
import {Button, Spinner} from 'react-bootstrap'
import {createPlayListTask} from '../../core/_requests'
import {useIntl} from 'react-intl'
import styled from '@emotion/styled'
import {IconChecked, IconLimite} from '../PlayListIcons'
import { validateFields } from './validations'

export type StateTask = {
  title: string
  description: string
  priority: PriorityType
  deadline: string
  status: StatusType
  type: OriginType
  notes?: string
  referenceId?: number
  marketplaceId: number
  id?: number,
  item: string | undefined
}

const BtnStyled = styled(Button)({
  background: '#0094EB !important',
  color: '#fff !important',
  marginLeft: '5px !important',
})

const InitialState: StateTask = {
  title: '',
  description: '',
  priority: 0,
  deadline: '',
  status: 1,
  item: '',
  type: 'CUSTOM',
  notes: '',
  referenceId: 0,
  marketplaceId: 1,
}

const dispatchClearMessage = (setIsLoading: any) => {
  setTimeout(() => {
    setIsLoading((pre: any) => ({
      ...pre,
      msg: '',
    }))
  }, 2000)
}


const FormCreate = ({
  references,
  updateTasksAction,
}: {
  references: ReferenceType[]
  updateTasksAction: any
}) => {
  const intl = useIntl()
  const [task, setTask] = useState<StateTask>(InitialState)
  const [dateRange, setDateRange] = useState([new Date(), new Date()])
  const [isLoading, setIsLoading] = useState({
    loading: false,
    msg: '',
    error: false,
  })
  const [validations, setValidations] = useState({
    error: false,
    message: [],
  })
  const [startDate, endDate] = dateRange
  const handlerTask = (key: string, e: any) => {
    setTask((pre) => ({
      ...pre,
      [key]: e.target.value,
    }))
  }

  const handlerCreate = async () => {
    const newTask: StateTask[] = [
      {
        ...task,
        deadline: new Date(endDate).toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }),
      },
    ]
    const validation: any = validateFields(newTask[0], intl)
    if (validation.error)
      return setValidations({
        ...validation,
      })
    setIsLoading((pre) => ({
      ...pre,
      loading: true,
    }))
    setValidations({
      error: false,
      message: [],
    })
    if (!newTask[0].notes) {
      delete newTask[0].notes
    }
    if(newTask[0].referenceId === 0) {
      delete newTask[0].referenceId
    }
    if (!newTask[0].item) {
      delete newTask[0].item
    }
    const response = await createPlayListTask(newTask)
    newTask[0].id = response.data.detail[0].detail.id
    if (response.status !== 200) {
      setIsLoading((pre) => ({
        ...pre,
        loading: false,
        error: true,
        msg: intl.formatMessage({id: 'PLAYLIST.ERROR.MESSAGE'}),
      }))
      dispatchClearMessage(setIsLoading)
      return
    }
    setIsLoading((pre) => ({
      ...pre,
      loading: false,
      msg: response.data.description,
    }))
    updateTasksAction(newTask, false)
    dispatchClearMessage(setIsLoading)
  }

  return (
    <Container>
      {validations.error && (
        <Alert error={true}>
          <span>
            <IconLimite />
          </span>
          {validations.message.length === 1 ? 'El' : 'Los campos'}
          {validations.message.map((key: any) => {
            return ` ${key.field}, `
          })}
          {validations.message.length === 1 ? 'es requerido' : 'son requeridos'}
        </Alert>
      )}
      <RenderForm
        task={task}
        setTask={setTask}
        handlerTask={handlerTask}
        disabled={false}
        references={references}
        endDate={endDate}
        startDate={startDate}
        setDateRange={setDateRange}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {isLoading.msg && (
          <Alert error={false}>
            {!isLoading.error ? (
              <span>
                <IconChecked />
              </span>
            ) : (
              <span>
                <IconLimite />
              </span>
            )}
            {isLoading.msg}
          </Alert>
        )}
        <BtnStyled disabled={isLoading.loading} onClick={handlerCreate} size='sm'>
          {isLoading.loading ? (
            <Spinner size='sm' animation='border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </Spinner>
          ) : (
            <> {intl.formatMessage({id: 'PLAYLIST.HEADER.CREATE.TASK'})}</>
          )}
        </BtnStyled>
      </div>
    </Container>
  )
}

export const PlaylistCreateTask = ({
  show,
  handleClose,
  references,
  updateTasks,
}: {
  show: boolean
  handleClose: any
  references: ReferenceType[]
  updateTasks: any
}) => {
  const intl = useIntl()
  const content: any = {
    tasks: <FormCreate references={references} updateTasksAction={updateTasks} />,
  }
  return (
    <Offcanvas show={show} onHide={handleClose} placement='end'>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          {intl.formatMessage({id: 'PLAYLIST.HEADER.NEW.TASK.TITLE'})}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <PlaylistTab isCreate={true} content={content} />
      </Offcanvas.Body>
    </Offcanvas>
  )
}
