/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {KTSVG} from '../../../helpers'
import {ElementWithIcon} from '../../content/list/ElementWithIcon'
import {useIntl} from 'react-intl'

const ProductTrackerReportModal: FC = () => {
  const intl = useIntl()
  return (
    <>
      <div className='modal fade' id='info_pt1_report_modal' aria-hidden='true'>
        <div className='modal-dialog mw-550px modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='text-center mb-13'>
              <h1 className='mb-3'>
                {intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.MODAL.SEARCH_MODAL'})}
              </h1>
            </div>

            <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
              <ElementWithIcon
                icon={''}
                title='ASIN'
                description={intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.MODAL.ASIN'})}
              />
              <ElementWithIcon
                icon={''}
                title='ID'
                description={intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.MODAL.ID'})}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' id='info_pt2_report_modal' aria-hidden='true'>
        <div className='modal-dialog mw-550px modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='text-center mb-13'>
              <h1 className='mb-3'>
                {intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.MODAL.FEATURED_KPI'})}
              </h1>
            </div>

            <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
              <ElementWithIcon
                icon={'/media/icons/duotune/ecommerce/ecm001.svg'}
                title={intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.ORDER_COUNT'})}
                description={intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.ORDER_COUNT_DESC'})}
              />
              <ElementWithIcon
                icon={'/media/icons/duotune/finance/fin010.svg'}
                title={intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.ORDER_TOTAL'})}
                description={intl.formatMessage({id: 'REPORT.HISTORY.KPI_DOWN_DESCRIPTION'})}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' id='info_pt3_report_modal' aria-hidden='true'>
        <div className='modal-dialog mw-550px modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='text-center mb-13'>
              <h1 className='mb-3'>
                {intl.formatMessage({id: 'REPORT.HISTORY.MODAL.GRAPH_TITLE'})}
              </h1>
            </div>

            <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
              <ElementWithIcon
                icon={'/media/icons/duotune/graphs/gra012.svg'}
                title={intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.PRODUC_HEALTH.TITLE'})}
                description={intl.formatMessage({
                  id: 'REPORT.PRODUCT_TRACKER.PRODUC_HEALTH.TEXT',
                })}
              />
              <ElementWithIcon
                icon={'/media/icons/duotune/graphs/gra012.svg'}
                title={intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.UNITS_SOLD_YEAR'})}
                description={intl.formatMessage({
                  id: 'REPORT.PRODUCT_TRACKER.UNITS_SOLD_YEAR_DESCRIPTION_DESC',
                })}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {ProductTrackerReportModal}
