import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {submitLandedCost} from '../core/_requests'
import {useIntl} from 'react-intl'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

import {useListView} from '../core/ListViewProvider'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {LandedCost} from '../core/_models'
import {formatDateTime} from '../../../../_gws/helpers/misc'
import {useAuth} from '../../../modules/auth'

type Props = {
  isLoading: boolean
  item: any
  newItem: boolean
  itemIdForUpdate?: any
}

/**
 * Form to create or edit a playlist
 * @param item
 * @param isLoading
 * @param newItem
 * @constructor
 */
const LandedCostEditModalForm: FC<Props> = ({item, isLoading, newItem, itemIdForUpdate}) => {
  const {currentMkp} = useAuth()
  const {setItemUpdated} = useListView()
  const mk = currentMkp === 'meli' ? 'MERCADOLIBRE' : 'AMAZON'
  const mkId = currentMkp === 'meli' ? 2 : 1

  /**
   * State to know if the form is for edit or create
   */
  const isEditForm = item.id ? true : false
  /**
   * State to know if the modal is open or not
   */
  const {setItemIdForUpdate} = useListView()
  /**
   * To refresh the data after the update
   */
  const {refetch} = useQueryResponse()

  const Alert = withReactContent(Swal)
  const intl = useIntl()

  /**
   * Initial values for the form
   */
  const [LCForEdit] = useState<LandedCost>({
    ...item,
    id: item.id,
    asin: item.asin !== undefined ? item.asin : itemIdForUpdate,
    cost: item.cost,
    createdAt: formatDateTime(item.createdAt),
    updatedAt: formatDateTime(item.updatedAt),
    marketplace: item.marketplace,
    marketplaceId: item.marketplaceId !== undefined ? item.marketplaceId : mkId,
  })

  /**
   *  Yup validation schema
   */
  let editLCSchema = Yup.object({
    cost: Yup.string().required(
      intl.formatMessage({id: 'LANDED_COST.VALIDATION.LANDED_COST.REQUIRED'})
    ),
  })

  /**
   * To Handle te cancel button
   * @param withRefresh
   */
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  /**
   * Formik function to handle the submition with Yup validation
   */
  
  const formik = useFormik({
    initialValues: LCForEdit,
    validationSchema: editLCSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      /**
       * Try to create or update the playlist
       */
      try {
        submitLandedCost(values)
        Alert.fire({
          title: newItem
            ? intl.formatMessage({id: 'LANDED_COST.ADDITEM.SUCCESS.TITLE'})
            : intl.formatMessage({id: 'LANDED_COST.UPDATE.SUCCESS.TITLE'}),
          html: newItem
            ? intl.formatMessage({id: 'LANDED_COST.ADDITEM.SUCCESS.TEXT'})
            : intl.formatMessage({id: 'LANDED_COST.UPDATE.SUCCESS.TEXT'}),
          icon: 'success',
          iconColor: '#00c6c7',
          buttonsStyling: false,
          confirmButtonText: intl.formatMessage({id: 'LANDED_COST.MODAL.BUTTON.ACCEPT'}),
          reverseButtons: true,
          customClass: {
            confirmButton: 'btn btn-primary',
            popup: 'bg-light',
          },
        }).then(() => {
          setSubmitting(true)
          cancel(true)
          //window.location.reload()

          setItemUpdated({asin: values.asin, cost: values.cost})
        })
      } catch (ex: any) {
        let errTitle = newItem
          ? intl.formatMessage({id: 'LANDED_COST.ADDITEM.FAIL.TITLE'})
          : intl.formatMessage({id: 'LANDED_COST.UPDATE.FAIL.TITLE'})
        let errText = ''
        if (ex.response.data.status === 400) {
          errText = intl.formatMessage({id: 'LANDED_COST.ADDITEM.FAIL.400.TEXT'})
        } else if (ex.response.data.status === 404) {
          errText = intl.formatMessage({id: 'LANDED_COST.ADDITEM.FAIL.404.TEXT'})
        } else {
          errText = intl.formatMessage({id: 'LANDED_COST.ADDITEM.FAIL.GEN.TEXT'})
        }
        Alert.fire({
          title: errTitle,
          html: errText,
          icon: 'info',
          iconColor: '#00c6c7',
          buttonsStyling: false,
          confirmButtonText: intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'}),
          reverseButtons: true,
          customClass: {
            confirmButton: 'btn btn-primary',
            popup: 'bg-light',
          },
        })
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {newItem && (
            <>
              <div className='fv-row mb-7 ms-1'>
                <label className=' fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'LANDED_COST.LABEL.PRODUCT'})}
                </label>
                <label className='form-control bg-transparent fw-normal'>{itemIdForUpdate}</label>
              </div>

              <div className='fv-row mb-7 ms-1'>
                <label className=' fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'LANDED_COST.LABEL.MARKETPLACE'})}
                </label>
                <label className='form-control bg-transparent fw-normal'>{mk}</label>
              </div>
              <input type='hidden' {...formik.getFieldProps('asin')} name='asin' />
              <input
                type='hidden'
                {...formik.getFieldProps('marketplaceId')}
                name='marketplaceId'
              />
            </>
          )}
          {!newItem && (
            <>
              <div className='fv-row mb-7 ms-1'>
                <label className=' fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'LANDED_COST.LABEL.PRODUCT'})}
                </label>
                <label className='form-control bg-transparent fw-normal'>
                  {formik.values.asin}
                </label>
              </div>

              <div className='fv-row mb-7 ms-1'>
                <label className=' fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'LANDED_COST.LABEL.MARKETPLACE'})}
                </label>
                <label className='form-control bg-transparent fw-normal'>
                  {formik.values.marketplace?.description}
                </label>
              </div>

              <div className='fv-row mb-7 ms-1'>
                <label className=' fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'LANDED_COST.LABEL.CREATED'})}
                </label>
                <label className='form-control bg-transparent fw-normal'>
                  {formik.values.createdAt}
                </label>
              </div>

              <div className='fv-row mb-7 ms-1'>
                <label className=' fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'LANDED_COST.LABEL.UPDATED'})}
                </label>
                <label className='form-control bg-transparent fw-normal'>
                  {formik.values.updatedAt}
                </label>
              </div>
              <input type='hidden' {...formik.getFieldProps('asin')} name='asin' />
              <input
                type='hidden'
                {...formik.getFieldProps('marketplaceId')}
                name='marketplaceId'
              />
            </>
          )}

          <div className='fv-row mb-7 ms-1'>
            <label className=' fw-bold fs-6 mb-2'>
              {intl.formatMessage({ id: 'LANDED_COST.LABEL.LANDED_COST' })} (<strong>MXN</strong>$)
            </label>

            <input
              type='number'
              {...formik.getFieldProps('cost')}
              name='cost'
              className={clsx(
                'form-control bg-transparent fw-normal',
                {'is-invalid': formik.touched.cost && formik.errors.cost},
                {
                  'is-valid': formik.touched.cost && !formik.errors.cost,
                }
              )}
              autoComplete='off'
            />

            {formik.touched.cost && formik.errors.cost && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.cost}</span>
                </div>
              </div>
            )}
          </div>

          <div className='modal-footer d-flex flex-center'>
            <button
              type='button'
              className='btn me-3 bg-gray-300 text-gray-550 bg-hover-opacity-75'
              onClick={() => setItemIdForUpdate(undefined)}
              data-bs-dismiss='modal'
            >
              {intl.formatMessage({id: 'LANDED_COST.ACTIONS.CANCEL'})}
            </button>
            <button type='submit' className='btn text-light bg-info bg-hover-opacity-75'>
              {isEditForm
                ? intl.formatMessage({id: 'LANDED_COST.ACTIONS.UPDATE'})
                : intl.formatMessage({id: 'LANDED_COST.ACTIONS.ADD'})}
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

export {LandedCostEditModalForm}
