import axios from 'axios'
import {getTokenHeader} from '../../../modules/auth'
import {MARKETPLACES, Marketplaces} from '../../../constants/marketplaces'

const API_URL_CRUNCH = process.env.REACT_APP_BACKEND_MAIN_API
/**
 * API endpoint for Product Health Report
 */
export const REQUEST_PRODUCT_HEALTH_URL = `${API_URL_CRUNCH}/api/product-health/`

export const getProductHealth = async (mkp: any, productId: any) => {
  const axiosClean = axios.create()
  return axiosClean.get(
    `${REQUEST_PRODUCT_HEALTH_URL}${mkp}?product=${productId}`,
    //@ts-ignore
    getTokenHeader()
  )
}

export const getGrowneratorText = async (
  descr: string,
  text: any,
  mkp: string,
  productId: string
) => {
  const marketplaceNormalization = {amazon: 'Amazon', meli: 'Mercado Libre'}
  const axiosClean = axios.create()
  // @ts-ignore
  let data: any = {marketplace: marketplaceNormalization[mkp]}
  data[descr] = text.toString()
  data['id'] = productId

  return axiosClean.post(
    `${API_URL_CRUNCH}/api/grownerator/${descr}`,
    data,
    //@ts-ignore
    getTokenHeader()
  )
}
export const getGrowneratorByTone = async ({
  id,
  marketplace,
  content,
  tone,
  type,
}: {
  id?: string
  marketplace: 'meli' | 'amazon' | string
  content: string
  tone: 'formal' | 'lifestyle' | 'persuasive'
  type: string
}) => {
  const axiosClean = axios.create()
  const marketPlaceObj = Object.values(MARKETPLACES).find(
    (mkp: Marketplaces) => mkp.name === marketplace
  )
  const body = {
    [type]: type === 'bullets' ? content.toString() : content,
    id,
    marketplaceId: marketPlaceObj?.id ?? MARKETPLACES.MERCADOLIBRE.id,
  }
  return axiosClean.post(
    `${API_URL_CRUNCH}/api/grownerator/${type}/${tone}`,
    body,
    //@ts-ignore
    getTokenHeader()
  )
}

export const getByTone = async ({
  body,
  tone,
  type,
}: {
  body: any
  tone: 'formal' | 'lifestyle' | 'persuasive'
  type: string
}) => {
  const axiosClean = axios.create()
  return axiosClean.post(
    `${API_URL_CRUNCH}/api/grownerator/${type}/${tone}`,
    body,
    //@ts-ignore
    getTokenHeader()
  )
}
