import React from 'react'
import { FontSizes } from '../../../../../app/constants/sizes'

type Props = {
  customClass?: string
  text: string
}
/**
 * Standard prototype of Text "AgLabelSmallText" | renders a div element with a specific font size and font weight, and can have a custom class and text provided as properties.
 * @param customClass - to add extra classes
 * @param text - the text content
 * @constructor
 */
const AgLabelSmallText: React.FC<Props> = ({customClass, text}) => {
  /**
   * static css for the component
   */
  const css = {
    fontSize: FontSizes.MINI.SIZE_5,
    fontWeight: 800,
  }

  return (
    <p className={`ff-prompt ${customClass} text-center mb-0`} style={css}>
      {text}
    </p>
  )
}

export {AgLabelSmallText}