import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {UserAssociated} from '../core/_models'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../../../_gws/helpers'

type UsersListSymbolsProps = {
  activeUsers: UserAssociated
}

/**
 * Component to show the faces or avatars of associated users to merchant
 * @param activeUsers - List of associated users to current Merchant
 */
const UsersListSymbols: FC<UsersListSymbolsProps> = ({activeUsers}) => {
  const intl = useIntl()

  return (
    <>
      <div className='fs-5 fw-bolder text-gray-700 mb-1'>
        {intl.formatMessage({id: 'USER_MNGT.ASSOCIATE.USERS_ASSOCIATED'})}
      </div>
      <div className='d-flex align-items-center mb-0 text-nowrap'>
        {activeUsers?.length >= 1 ? (
          <div className='symbol-group symbol-hover mb-4 d-block'>
            {activeUsers?.slice(0, 5).map((user) => (
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    {user.name} {user.lastname}
                  </Tooltip>
                }
                defaultShow={false}
                key={user.id}
              >
                <div className='symbol symbol-35px symbol-circle'>
                  {Number(user.contactProfiles?.length) >= 1 &&
                    user.contactProfiles?.map((profiles) => (
                      <img
                        alt={user.username}
                        src={
                          profiles.picture !== null &&
                          profiles.picture !== undefined &&
                          profiles.picture !== ''
                            ? profiles.picture
                            : toAbsoluteUrl('/media/svg/avatars/blank.svg')
                        }
                        key={profiles.id}
                      />
                    ))}

                  {Number(user.contactProfiles?.length) === 0 && (
                    <span className='symbol-label bg-primary text-inverse-warning text-uppercase fw-bold'>
                      {user.name?.substring(0, 1)}
                      {user.lastname?.substring(0, 1)}
                    </span>
                  )}
                </div>
              </OverlayTrigger>
            ))}
            {Number(activeUsers?.length) > 5 && (
              <span
                className='symbol symbol-35px symbol-circle'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_view_users'
              >
                <span className='symbol-label bg-dark text-gray-300 fs-8 fw-bold'>
                  +{Number(activeUsers?.length) - 5}
                </span>
              </span>
            )}
          </div>
        ) : (
          <div className='symbol-group symbol-hover mb-4'>
            <span className='symbol symbol-35px symbol-circle'>
              <span className='symbol-label bg-dark text-gray-300 fs-8 fw-bold'>0</span>
            </span>
          </div>
        )}
      </div>
    </>
  )
}

export {UsersListSymbols}
