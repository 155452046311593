export const validateFields = (task: any, intl: any) => {
  const message = []
  const {description, title} = task
  const requiredFields: any = {
    description,
    title,
  }
  for (const key in requiredFields) {
    if (!requiredFields[key]) {
      message.push({
        field: intl.formatMessage({id: `PLAYLIST.FORM.REQUIRED.${key.toUpperCase()}`}),
      })
    }
  }
  return {
    error: message.length > 0,
    message: message,
  }
}
