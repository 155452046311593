import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'
import {useAuth} from '../../../../../../app/modules/auth'

const SettingsAside = () => {
  const intl = useIntl()
  const {currentUser, currentClient} = useAuth()

  let isAdmin = currentUser?.roleId === 2
  let isMerchant = currentUser?.roleId === 3
  let canSeeUserAdmin = currentUser?.canSeeUserAdmin === true

  if (canSeeUserAdmin && currentClient === undefined) {
    canSeeUserAdmin = true
  } else {
    canSeeUserAdmin = false
  }

  return (
    <>
      {currentClient === undefined ? (
        <AsideMenuItemWithSub
          listTo={['goals', 'overview', 'marketplaces', 'clients', 'users']}
          icon='/media/icons/gws-icons/aside-settings.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD.SETTINGS'})}
        >
          {!isAdmin && !isMerchant && (
            <>
              {/*Estretegias*/}
              <AsideMenuItem
                to='/goals'
                title={intl.formatMessage({id: 'STRATEGY.SETUP.GOALS'})}
                hasBullet={true}
              />
              {/*SetupWizzard*/}
              {/*Marketplaces*/}
              <AsideMenuItem
                to='/marketplaces'
                title={intl.formatMessage({id: 'MENU.MARKETPLACES'})}
                hasBullet={true}
              />
              {/*QuickAccess*/}
            </>
          )}
          {isMerchant && canSeeUserAdmin && (
            <AsideMenuItem
              to='/apps/user-management/clients'
              title={intl.formatMessage({id: 'MENU.CLIENT_MANAGEMENT'})}
              hasBullet={true}
            />
          )}
          {isAdmin && (
            <AsideMenuItem
              to='/apps/user-management/users'
              title={intl.formatMessage({id: 'MENU.USER_MANAGEMENT'})}
              hasBullet={true}
            />
          )}
          {isAdmin && (
            <AsideMenuItem
              to='/outputs/edit'
              title={intl.formatMessage({id: 'EDIT.DESCRIPTION.OUTPUT'})}
              hasBullet={true}
            />
          )}
          {isAdmin && (
            <AsideMenuItem
              to='/documentation/edit'
              title={"Gestion de glossario"}
              hasBullet={true}
            />
          )}
          {/*Perfil*/}
          <AsideMenuItem
            to='/account/overview'
            title={intl.formatMessage({id: 'STEPPER.STEP1.TITLE'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      ) : (
        <AsideMenuItemWithSub
          listTo={['goals', 'overview', 'marketplaces', 'clients', 'users']}
          icon='/media/icons/gws-icons/aside-settings.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD.SETTINGS'})}
        >
          {/*Estretegias*/}
          <AsideMenuItem
            to='/goals'
            title={intl.formatMessage({id: 'STRATEGY.SETUP.GOALS'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      )}
    </>
  )
}

export {SettingsAside}
