import React, {FC} from 'react'
import {useIntl} from 'react-intl'

/**
 * Props declarations
 * @param props - table component options
 * @param pageInfo - request info of pagination
 * @param count - url actual page size value
 */
type Props = {
  props: any
  pageInfo: any
  count: number
}

/**
 * Bottom leyend Bootstrap Table configuration
 * @param props
 */
const CustomTotalRemote: FC<Props> = ({props, pageInfo, count}) => {
  const intl = useIntl()
  const from = pageInfo.current_page * count - (count - 1)
  const to = pageInfo.page_records === count ? from + (count - 1) : pageInfo.total_records
  const size = pageInfo.total_records

  return (
    <div className='mt-3 mb-4 justify-content-start align-items-center'>
      <span className='text-gray-600 fw-semibold gy-5'>
        {intl.formatMessage({id: 'REPORT.INVENTORY.SHOW'})} {from}{' '}
        {intl.formatMessage({id: 'REPORT.INVENTORY.TO'})} {to}{' '}
        {intl.formatMessage({id: 'REPORT.INVENTORY.FROM'})} {size}{' '}
        {intl.formatMessage({id: 'REPORT.INVENTORY.RESULTS'})}
      </span>
    </div>
  )
}

export {CustomTotalRemote}
