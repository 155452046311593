import React from 'react'
import {TextProps} from '../AgText'
import { FontSizes } from '../../../../../app/constants/sizes'


/**
 * Standard prototype of Text "AgSmall" | renders a div element with a specific font size and font weight, and can have a custom class and text provided as properties.
 * @param customClass - to add extra classes
 * @param text - the text content
 * @constructor
 */
const AgTextButton: React.FC<TextProps> = ({customClass, text, children}) => {
  /**
   * static css for the component
   */
  const css = {
    fontSize: FontSizes.SMALL.SIZE_3, //'0.894em',
    fontWeight: 300,
  }

  return (
    <div className={`ff-prompt ${customClass}`} style={css}>
      {text}
      {children}
    </div>
  )
}

export {AgTextButton}
