import axios from 'axios'
import {getTokenHeader} from '../../../modules/auth'

const API_URL_CRUNCH = process.env.REACT_APP_BACKEND_MAIN_API

export const REQUEST_FRANKIE_URL = `${API_URL_CRUNCH}/api/brand-health/provisional`

export function getFrankieData(mkpId: number) {
  const axiosClean = axios.create()
  return axiosClean.post(
    REQUEST_FRANKIE_URL,
    {marketplaceId: mkpId},
    // @ts-ignore
    getTokenHeader()
  )
}
