import {useState} from 'react'
import {Bestseller} from '../core/types'
import {
  BadgeRank,
  BaseCard,
  ContainerInfo,
  ImgWrapper,
  Slider,
  SliderBtn,
  SliderControls,
} from './styled'
import {makeLink} from '../../playlist/components/Table/PlaylistTable'
import {CloseButton, Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {priceformated} from '../../../../_gws/helpers/misc'
import {AmazonIconFullColor} from '../../playlist/components/PlayListIcons'

const getTruncated = (name: string) => {
  const words = name.trim().split(/\s+/)
  return words.join(' ').substring(0, 55)
}

const ShowTitle = ({
  showModal,
  handlerClouse,
  message,
  asin,
}: {
  showModal: boolean
  handlerClouse: any
  message: string
  asin: string
}) => {
  const intl = useIntl()
  return (
    <Modal centered show={showModal}>
      <Modal.Header className={'justify-content-between px-10 py-5'}>
        <h4>
          {intl.formatMessage({id: 'MENU.REPORTS.INVENTORY.TABLE.HEADER.PRODUCT'})}:{' '}
          {makeLink(asin, 1)}{' '}
        </h4>
        <CloseButton onClick={handlerClouse} />
      </Modal.Header>
      <Modal.Body className='px-10 py-5'>{message}</Modal.Body>
    </Modal>
  )
}

const ShowGraphImage = ({
  showModal,
  handlerClouse,
  graphImage,
  asin,
  title,
  rank,
}: {
  showModal: boolean
  handlerClouse: any
  graphImage: string
  asin: string
  title: string
  rank: number
}) => {
  return (
    <Modal size='lg' centered show={showModal}>
      <Modal.Header>
        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
          <BadgeRank style={{marginRight: '10px', padding: '0px 5px'}}>{rank}</BadgeRank>
          <div style={{width: '80%'}}>
            <h4>{title}</h4>
            <p style={{fontWeight: 'bold', marginTop: '10px'}}>{makeLink(asin, 1)}</p>
          </div>
          <AmazonIconFullColor />
          <CloseButton onClick={handlerClouse} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <img style={{width: '100%'}} src={graphImage} alt={title} />
        </div>
      </Modal.Body>
    </Modal>
  )
}

const SliderCard = ({images}: {images: string[]}) => {
  const [state, setState] = useState({
    images: images,
    active: 0,
  })
  const handlerChangeImage = (active: number) => {
    setState((pre) => ({
      ...pre,
      active: active,
    }))
  }
  return (
    <Slider>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ImgWrapper>
          <img className='img-fluid' alt={images[state.active]} src={images[state.active]} />
        </ImgWrapper>
      </div>

      <SliderControls>
        <div style={{display: 'flex'}}>
          {images.map((_item, i) => (
            <SliderBtn onClick={() => handlerChangeImage(i)} active={i === state.active} key={i} />
          ))}
        </div>
      </SliderControls>
    </Slider>
  )
}

export const ProductCard = ({data}: {data: Bestseller}) => {
  const intl = useIntl()
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenGraph, setIsOpenGraph] = useState(false)
  const imageUrl = `data:image/jpg;base64, ${data.graphImage}`
  return (
    <BaseCard>
      <div>
        <BadgeRank>{data.rank}</BadgeRank>
        <ContainerInfo>
          <p>{data.productGroup}</p>
          <h4>
            {data.title.length < 55 ? data.title : `${getTruncated(data.title)}...`}
            {data.title.length > 55 && (
              <button onClick={() => setIsOpen((pre) => !pre)}>
                {intl.formatMessage({id: 'PRODUCT_HEALTH.CARD_ACTION_SHOW'})}
              </button>
            )}
          </h4>
          <p style={{fontWeight: 'bold'}}>{makeLink(data.asin, 1)}</p>
          <p style={{margin: '2px 0px'}}>
            {intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.BRAND'})}: {data.brand}
          </p>
          <p style={{margin: '2px 0px'}}>
            {intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.COLOR'})}: {data.color}
          </p>
          <h4 style={{fontSize: '1.5rem', marginTop: '10px'}}>${priceformated(data.price)}</h4>
        </ContainerInfo>
      </div>
      <div
        style={{
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <SliderCard images={data.images} />
        <div
          onClick={() => setIsOpenGraph((pre) => !pre)}
          style={{display: 'grid', placeItems: 'center', marginTop: '20px', cursor: 'pointer'}}
        >
          <img width={100} src={imageUrl} alt={data.title} />
        </div>
      </div>
      <ShowTitle
        showModal={isOpen}
        handlerClouse={() => setIsOpen((pre) => !pre)}
        message={data.title}
        asin={data.asin}
      />
      <ShowGraphImage
        showModal={isOpenGraph}
        handlerClouse={() => setIsOpenGraph((pre) => !pre)}
        title={data.title}
        asin={data.asin}
        graphImage={imageUrl}
        rank={data.rank}
      />
    </BaseCard>
  )
}
