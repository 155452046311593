import {Filters, Task} from '../types'

export const sortPriority = (sortOrder: string, data: any) =>
  data.toSorted((a: any, b: any) =>
    sortOrder === 'asc' ? a.priority - b.priority : b.priority - a.priority
  )

export const sortTypeId = (sortOrder: string, data: any) =>
  data.toSorted((a: any, b: any) =>
    sortOrder === 'asc' ? a.typeId - b.typeId : b.typeId - a.typeId
  )

export const sortMarketplace = (sortOrder: string, data: any) => {
  const result = data.toSorted((a: any, b: any) =>
    sortOrder === 'asc' ? a.marketplaceId - b.marketplaceId : b.marketplaceId - a.marketplaceId
  )
  return result
}

export const sortDescription = (sortOrder: string, data: any) => {
  const result = data.toSorted((a: any, b: any) =>
    sortOrder === 'asc'
      ? a.description.localeCompare(b.description)
      : b.description.localeCompare(a.description)
  )
  return result
}

export const filter = (checks: Filters[], arr: Task[]) =>
  arr.filter((item: Task) => checks.some((check: Filters) => item[check.field] === check.value))

export const filterStrict = (checks: Filters[], arr: Task[]) => {
  let aux: Task[] = arr
  checks.forEach((filter: Filters) => {
    aux = aux.filter((task: Task) => task[filter.field] === filter.value)
  })
  return aux
}
