/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {AsideMenu} from './AsideMenu'
import styled from '@emotion/styled'

const Container = styled('div')({
  borderRight: '1px solid #0000000D',
})

const AsideDefault: FC = () => {
  // const {classes} = useLayout()

  return (
    <Container
      id='kt_aside'
      className='aside'
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      <AsideMenu />
    </Container>
  )
}

export {AsideDefault}
