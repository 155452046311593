import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_gws/layout/core'
import {useEffect, useState} from 'react'
import {ReportMarketing} from './PaidMarketingReport'
import {Splash} from '../../../_gws/helpers/misc'
import {HeaderReport} from '../../modules/apps/header/HeaderReport'
import {WaitingScreen} from '../../../_gws/partials/content/waiting/WaitingScreen'
import useSWR from 'swr'
import {useAuth} from '../../modules/auth'
import {getMarketingData} from './core/getReportMarketing'
import {BannerHelp} from '../../modules/apps/BannerHelp/BannerHelp'
import {FilterReport} from './components/FilterReport'

const swrOptions = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  shouldRetryOnError: false,
}

const PaidMarketingWrapper = () => {
  const intl = useIntl()
  const {currentMkp, setCurrentMkp} = useAuth()
  const hasBeenVerified = localStorage.getItem('verified') === 'true'
  const [period, setPeriod] = useState(1)
  const {data, isLoading} = useSWR(
    () => (currentMkp ? ['/paid-marketing/', currentMkp, period] : null),
    () =>
      getMarketingData({
        marketplace: currentMkp,
        periodType: period,
      }),
    {
      ...swrOptions,
    }
  )

  useEffect(() => {
    Splash(isLoading)
  }, [isLoading])

  const handlerSetPeriod = (period: number) => setPeriod(period)
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: intl.formatMessage({id: 'MENU.HOME'}),
            path: '/dashboard',
            isSeparator: false,
            isActive: false,
          },
          {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.REPORTS.PAID_MARKETING'})}
      </PageTitle>
      {hasBeenVerified ? (
        <>
          <BannerHelp linkToDoc='https://docs.growker.ai/kb/marketing/' title='HELP.TITLE.MARKETING' text='HELP.TEXT.MARKETING' />
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <HeaderReport
              hasMarketPlace={true}
              hasDate={false}
              hasFilter={false}
              setMkplace={setCurrentMkp}
            />
            {data && <FilterReport handlerPeriodFilter={handlerSetPeriod} />}
          </div>
          {data && <ReportMarketing report={data} />}
        </>
      ) : (
        <WaitingScreen />
      )}
    </>
  )
}
export {PaidMarketingWrapper}
