import memberstackDOM from '@memberstack/dom';

const memberstack = memberstackDOM.init({
  publicKey: process.env.REACT_APP_MEMBERSTACK_PUBLIC_KEY ?? '',
  domain: process.env.REACT_APP_MEMBERSTACK_DOMAIN ?? 'https://memberstack-client.crunchsoft.site'
});

export const MemberstackFunctions = {
  login: async (email: string, password: string): Promise<any> => {
    return memberstack.loginMemberEmailPassword({email, password})
  },

  logout: async (): Promise<any> => {
    return memberstack.logout()
  },

  signUp: async (email: string, password: string): Promise<any> => {
    return memberstack.signupMemberEmailPassword({email, password})
  },

  changePassword: async (password: string, token: string): Promise<any> => {
    return memberstack.setPassword({password})
  },

  sendPasswordResetEmail: async (email: string): Promise<any> => {
    return memberstack.sendMemberResetPasswordEmail({email})
  },

  loginWithProvider: async (provider: string): Promise<any> => {
    return memberstack.loginWithProvider({provider})
  },

  registerWithProvider: async (provider: string): Promise<any> => {
    return memberstack.signupWithProvider({provider})
  },

  purchaseMembership: async (
    priceId: string,
    successUrl: string | undefined,
    cancelUrl: string | undefined
  ): Promise<any> => {
    return memberstack.purchasePlansWithCheckout({priceId, successUrl, cancelUrl})
  },
}
