import {FC, useEffect} from 'react'
import {MarketplacesListHeader} from './MarketplacesListHeader'
import {DatePickerWrapper} from './DatePickerWrapper'
import {useIntl} from 'react-intl'
import {mkProp, useAuth} from '../../auth'
import {useLocation} from 'react-router'

type Props = {
  hasMarketPlace: boolean
  hasDate: boolean
  hasFilter: boolean
  setMkplace: any
  setMonth?: any
  changeDate?: any
  isMonth?: boolean | undefined
  helperBubble?: boolean
  activeMkp?: mkProp
  onlyShowMarketplace?: boolean
  growkerMetric?: boolean
}

const HeaderReport: FC<Props> = ({
  hasMarketPlace,
  hasFilter,
  hasDate,
  setMkplace,
  setMonth,
  isMonth,
  helperBubble = true,
  activeMkp,
  changeDate,
  onlyShowMarketplace = false,
  growkerMetric = false,
}) => {
  const intl = useIntl()
  const {setCurrentMkp, currentMkp, hasMeli, hasAmazon} = useAuth()
  // const [showImportant, setShowImportant] = useState(false)
  let {pathname} = useLocation()

  useEffect(() => {
    if (activeMkp !== undefined) {
      setCurrentMkp(activeMkp)
    } else {
      if (hasAmazon && hasMeli) {
        setCurrentMkp('growker')
      } else if (hasAmazon) {
        setCurrentMkp('amazon')
      } else {
        setCurrentMkp('meli')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMkp])

  useEffect(() => {
    if (currentMkp !== undefined && growkerMetric) {
      //setShowImportant(true)
    }

    if (currentMkp === 'growker' && growkerMetric === false) {
      // setShowImportant(false)
    } else {
      //setShowImportant(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMkp])

  return (
    <>
      <div
        id={'kt_layout_toolbar'}
        className={'d-flex flex-row align-items-center justify-content-between py-3'}
        style={{boxShadow: 'none'}}
      >
        {hasMarketPlace && (
          <MarketplacesListHeader
            setMkplace={setMkplace}
            onlyShowMarketplace={onlyShowMarketplace}
            pathname={pathname}
          />
        )}
        {hasDate && (
          <div className={'d-flex flex-row align-items-center'}>
            {isMonth ? (
              <span className={'fs-8 me-3'}> {intl.formatMessage({id: 'GENERAL.MONTH'})}:</span>
            ) : (
              <span className={'fs-8 me-3'}>
                {' '}
                {intl.formatMessage({id: 'GENERAL.MONTH_FILTER'})}:
              </span>
            )}
            <DatePickerWrapper month={isMonth} changeDate={changeDate} setMonth={setMonth} />
          </div>
        )}
        {hasFilter && (
          <div className={'d-flex flex-row align-items-center'}>
            <span className={'fs-8 me-1'}> {intl.formatMessage({id: 'GENERAL.FILTER'})}:</span>
          </div>
        )}
      </div>
    </>
  )
}

export {HeaderReport}
