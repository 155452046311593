import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_gws/layout/core'
import Calendar from './Calendar'

const CalendarBuilder: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: intl.formatMessage({id: 'MENU.HOME'}),
            path: '/dashboard',
            isSeparator: false,
            isActive: false,
          },
          {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.CALENDAR'})}
      </PageTitle>
      <Calendar
        minWidth={'320px'}
        height={'630px'}
        url={'https://calendly.com/eduardo-351/30min'}
      />
    </>
  )
}

export default CalendarBuilder
