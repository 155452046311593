import axios, {AxiosResponse} from 'axios'
const API_URL_CRUNCH = `${process.env.REACT_APP_BACKEND_MAIN_API}/api`

const USER_URL = `${API_URL_CRUNCH}/users`

const getlistClients = (): Promise<any> => {
  return axios.get(`${USER_URL}/merchants/clients`).then((d: AxiosResponse<any>) => d.data)
}
export {getlistClients}

const getClientToken = (id: number): Promise<any> => {
  return axios
    .post(`${USER_URL}/merchants/clients/token`, {filter: `${id}`})
    .then((d: AxiosResponse<any>) => d.data)
}
export {getClientToken}
