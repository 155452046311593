import styled from '@emotion/styled'
import {Button} from 'react-bootstrap'

export const Container = styled('div')({})

// ---------------- Product card ---------------------//
export const CardProductContainer = styled('div')({
  padding: '20px',
  borderRadius: '10px',
  boxShadow: '-1px 14px 19px -4px #919EAB33',
  minHeight: "490px"
})

export const CardProductHeader = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 140px',
  gap: '1rem',
  padding: '2px',
})

export const CardProductImage = styled('div')({
  img: {
    width: '130px',
  },
})

export const CardProductBody = styled('div')({
  fontWeight: 'bold',
  p: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#979797',
    border: "1px solid transparent",
    borderTop: '1px solid #F5F5F5',
    padding: '5px',
    paddingTop: "10px",
    width: '85%',
    span: {
      color: '#000',
      width: '200px',
    },
  },
})

export const CardProductTitle = styled('div')({
  h6: {
    fontSize: '.9rem',
    fontWeight: 'bold',
  },
  p: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
})

export const CardProductDescription = styled('div')({
  margin: '10px 0px',
  button: {
    border: 'none',
    background: '#fff',
    fontWeight: 'bold',
  },
})
// ---------------- Product card ---------------------//

// ---------------- Product Description ---------------------//
type PillProps = {
  active: boolean,
  bg?: string
}

export const ContainerDetails = styled('div')({
  display: 'grid',
  gridTemplateColumns: '400px 1fr',
  gap: '1rem',
  '@media (max-width: 768px)': {
    gridTemplateColumns: '1fr',
  },
})

export const WrapperCard = styled('div')({
  boxShadow: '-1px 14px 19px -4px #919EAB33',
  borderRadius: '10px',
  height: "490px",
  padding: "20px",
  display: "flex",
  flexFlow: "column",
  justifyContent: "space-between"
})

export const DetailContainer = styled('div')({})

export const DetailSelectorWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '@media (max-width: 1170px)': {
    flexFlow: 'column',
    alignItems: 'flex-start',
  },
})

export const DetailWrapper = styled('div')({
  color: '#848484',
  fontSize: '.9rem',
})

export const WrapperPills = styled('div')({
  display: 'flex',
})

export const PillDetail = styled('div')<PillProps>(({active, bg }) => ({
  borderRadius: '8px',
  width: '100px',
  height: '40px',
  cursor: 'pointer',
  transition: 'all ease .3s',
  boxShadow: active ? '1px 14px 19px -4px #919EAB33' : '1px 5px 5px -5px #919EAB',
  border: `1px solid ${active ? '#DFDFDF' : 'transparent'}`,
  marginRight: '10px',
  background: bg ? active ? bg : '#fff': "transparent",
  p: {
    fontSize: '1.1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    fontWeight: active ? 'bold' : 'inherit',
    color: active ? '#212121' : '#ACACAC',
  },
  ':hover': {
    boxShadow: '1px 14px 19px -4px #919EAB33',
  },
  '@media (max-width: 1320px)': {
    width: '80px',
    p: {
      fontSize: '1rem',
    },
  },
}))

export const ButtonIA = styled(Button)({
  background: '#0D7CBD !important',
  color: '#fff !important',
  width: '150px',
  '@media (max-width: 1170px)': {
    marginTop: '10px',
  },
})

export const ButtonGenerateNew = styled('button')({
  marginLeft: '5px',
  color: '#9E9E9E',
  background: '#F5F5F5',
  border: "none",
  height: "100%",
  borderRadius: "5px",
  marginRight: "10px"
})

export const ButtonCopy = styled('button')({
  marginLeft: '5px',
  color: '#fff',
  background: '#0D7CBD',
  border: "none",
  height: "100%",
  borderRadius: "5px"
})


export const Divider = styled('div')({
  width: '100%',
  height: '2px',
  borderRadius: '20px',
  background: '#E2E2E2',
})

export const DividerIA = styled('div')({
  height: '100%',
  width: '2px',
  borderRadius: '20px',
  background: '#E2E2E2',
  '@media (max-width: 1024px)': {
    display: "none"
  },
})


export const ContainerIA = styled('div')({})

export const WrapperResults = styled('div')({
  display: 'grid',
})

export const WrapperActions = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: "#fff",
  '@media (max-width: 1265px)': {
    flexFlow: "column",
    alignItems: 'flex-start',
  },
})

export const WrapperActionsButtons = styled('div')({
  height: "100%",
  '@media (max-width: 1265px)': {
    width: "100%",
    height: "40px",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "8px"
  },
})

export const Content = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 10px 1fr',
  gap: '1rem',
  '@media (max-width: 1024px)': {
    gridTemplateColumns: '1fr',
  },
})

export const WrapperContent = styled('div')({
  height: "300px",
  overflow: "auto",
  '@media (max-width: 1265px)': {
    height: "280px",
  },
  '@media (max-width: 1024px)': {
    height: "100px",
  },
})

export const ContainerSpinner = styled('div')({
  display: 'grid',
  placeItems: 'center',
  height: "300px",
  overflow: "auto",
  '@media (max-width: 1265px)': {
    height: "280px",
  },
  '@media (max-width: 1024px)': {
    height: "100px",
  },
})

// ---------------- Indicators ---------------------//
type IndicatorProp = {
  bg?: string
}
export const ContainerIndicators = styled('div')({
  margin: '20px 0px',
})

export const IndicatorsWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(170px, 1fr))',
  gap: '1rem',
})

export const IndicatorCard = styled('div')<IndicatorProp>(({bg}) => ({
  background: bg,
  borderRadius: '8px',
  fontSize: '1.5rem',
  fontWeight: 'bold',
  display: 'grid',
  placeItems: 'center',
  height: '180px',
  textAlign: 'center',
}))
// ---------------- Indicators ---------------------//

// ------------------- Chart ------------------------//
export const ContainerChart = styled('div')({
  display: "grid",
  gridTemplateColumns: "2fr 1fr",
  gap: "1rem",
  '@media (max-width: 768px)': {
    gridTemplateColumns: '1fr',
  },
})