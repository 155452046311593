import styled from '@emotion/styled'
import {ButtonPay, PaymentContainer, PaymentHeader} from './styled'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {useState} from 'react'
import {Spinner} from 'react-bootstrap'

export const CheckoutCircle = ({color = '#212121'}) => {
  return (
    <svg style={{marginBottom: '20px'}} width='72' height='72' viewBox='0 0 16 16' fill='none'>
      <path
        d='M6.9055 10.4231L11.319 6.03044L10.8334 5.54485L6.9055 9.4519L5.13467 7.7019L4.64908 8.18748L6.9055 10.4231ZM8.00283 15.1666C7.02126 15.1666 6.0948 14.9803 5.22346 14.6075C4.35211 14.2347 3.58916 13.7214 2.9346 13.0675C2.28006 12.4135 1.76622 11.6513 1.39308 10.7809C1.01994 9.91036 0.833374 8.98433 0.833374 8.00277C0.833374 7.00731 1.01976 6.07738 1.39252 5.21298C1.7653 4.34858 2.27864 3.5891 2.93256 2.93454C3.58648 2.28 4.34868 1.76616 5.21917 1.39302C6.08967 1.01988 7.01569 0.833313 7.99725 0.833313C8.99271 0.833313 9.92264 1.01969 10.787 1.39246C11.6514 1.76524 12.4109 2.27858 13.0655 2.9325C13.72 3.58642 14.2339 4.34515 14.607 5.20869C14.9801 6.07224 15.1667 7.00174 15.1667 7.99719C15.1667 8.97876 14.9803 9.90522 14.6076 10.7766C14.2348 11.6479 13.7214 12.4109 13.0675 13.0654C12.4136 13.72 11.6549 14.2338 10.7913 14.6069C9.92778 14.9801 8.99828 15.1666 8.00283 15.1666ZM8.00004 14.5C9.8056 14.5 11.3403 13.868 12.6042 12.6041C13.8681 11.3403 14.5 9.80554 14.5 7.99998C14.5 6.19442 13.8681 4.6597 12.6042 3.39581C11.3403 2.13192 9.8056 1.49998 8.00004 1.49998C6.19449 1.49998 4.65976 2.13192 3.39587 3.39581C2.13199 4.6597 1.50004 6.19442 1.50004 7.99998C1.50004 9.80554 2.13199 11.3403 3.39587 12.6041C4.65976 13.868 6.19449 14.5 8.00004 14.5Z'
        fill={color}
      />
    </svg>
  )
}

export const CancelButton = styled('button')({
  width: '100%',
  height: '50px',
  borderRadius: '8px',
  cursor: 'pointer',
  color: '#fff',
  background: '#D3D3D3',
  fontSize: '1.2rem',
  fontWeight: 'bold',
  border: 'none',
})

export const PaymentSuccess = () => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const intl = useIntl()

  const handlerStart = async () => {
    setLoading(true)
    localStorage.removeItem('temporal')
    navigate('/account/overview')
  }
  return (
    <PaymentContainer
      style={{
        color: '#fff',
        padding: "0px",
        width: "50%"
      }}
    >
      <PaymentHeader
        style={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CheckoutCircle color='#fff' />
        <h1 style={{color: '#fff', fontWeight: 'bold', width: "100%", textAlign: "center"}}>
          {intl.formatMessage({id: 'PAYMENT.HEADER.SUCCESS.TITLE'})}
        </h1>
        <p>{intl.formatMessage({id: 'PAYMENT.HEADER.SUCCESS.SUBTITLE'})}</p>
      </PaymentHeader>
      <div
        style={{
          margin: '20px 0px',
        }}
      >
        <iframe
          style={{borderRadius: '10px'}}
          width='100%'
          height='315'
          src='https://imagekit.io/player/embed/oewuzfboc/GrowkerCONECTA_HD.mp4?thumbnail=https%3A%2F%2Fik.imagekit.io%2Foewuzfboc%2FGrowkerCONECTA_HD.mp4%2Fik-thumbnail.jpg&updatedAt=1714139086920'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerPolicy='strict-origin-when-cross-origin'
          allowFullScreen
        ></iframe>
      </div>

      <div
        style={{
          textAlign: 'center',
        }}
      >
        <p>{intl.formatMessage({id: 'PAYMENT.SUCCESS.PARAGRAPH.1'})}</p>
        <p>{intl.formatMessage({id: 'PAYMENT.SUCCESS.PARAGRAPH.2'})}</p>
      </div>
      <ButtonPay
        onClick={handlerStart}
        style={{
          margin: '20px 0px',
          background: '#fff',
          color: '#212121',
        }}
      >
        {loading ? (
          <Spinner size='sm' animation='border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
        ) : (
          <> {intl.formatMessage({id: 'PAYMENT.ACTION.SUCCESS'})}</>
        )}
      </ButtonPay>
    </PaymentContainer>
  )
}
