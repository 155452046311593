export const RenderSelect = ({
  id,
  index,
  action,
  checked,
}: {
  id: number
  index: number
  action: any
  checked: boolean
}) => {
  const flex = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
  return (
    <div style={{...flex}}>
      <input type='checkbox' checked={checked} onChange={() => action({id, index})} />
    </div>
  )
}
