import React, {useEffect} from 'react'

const calendlyWidgetScript = 'calendlyWidgetScript'
const calendarDivId = 'calendarForm'
type Props = {
  minWidth: string
  height: string
  url: string
}
const Calendar: React.FC<Props> = ({minWidth, height, url}) => {
  useEffect(() => {
    const head = document.querySelector('head')
    const script = document.createElement('script')
    script.setAttribute('id', calendlyWidgetScript)
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
    head !== null && head.appendChild(script)

    return () => {
      if (document.getElementById(calendlyWidgetScript) && head) {
        const script = document.getElementById(calendlyWidgetScript)
        script?.remove()
        const calendar = document.getElementById(calendarDivId)
        calendar?.remove()
      }
    }
  }, [])
  return (
    <div id={calendarDivId}>
      <div className='calendly-inline-widget' data-url={url} style={{minWidth, height}} />
    </div>
  )
}

export default Calendar
