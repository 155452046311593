import styled from '@emotion/styled'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../_gws/helpers'
import {useState} from 'react'

export const BannerCard = styled('div')({
  borderRadius: '10px',
  display: 'grid',
  background: '#E5F9FC',
  gridTemplateColumns: '2fr 1fr',
  margin: '20px 0px',
  '@media (max-width: 1024px)': {
    gridTemplateColumns: '1fr',
  },
})

export const BannerBody = styled('div')({
  display: 'grid',
  placeItems: 'flex-start',
  padding: '20px',
  h4: {
    color: '#212121',
    fontSize: '1.6rem',
  },
  p: {
    color: '#9E9E9E',
    marginTop: '5px',
  },
  '@media (max-width: 500px)': {
    p: {
      fontSize: "10px"
    }
  },
})

export const BannerImg = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  img: {
    width: '100px',
    height: 'auto',
  },
  '@media (max-width: 1024px)': {
    display: 'none',
  },
})

export const Img = styled('div')({
  borderRadius: '50%',
  width: '150px',
  height: '150px',
  img: {
    width: '150px',
    height: '150px',
    borderRadius: '50%',
  },
})
export const HelpLink = styled('div')({
  margin: '0px',
  display: 'flex',
  alignItems: 'center',
  a: {
    border: '1px solid #64EAFF',
    padding: '3px 10px',
    borderRadius: '20px',
    marginBottom: '7px',
    marginLeft: '15px',
    fontWeight: 'bold',
    color: '#05344F',
    background: 'linear-gradient(to bottom, #46A4B3C7, #97DFEA)',
  },
  '@media (max-width: 500px)': {
    a: {
      fontSize: '10px',
      padding: '3px 10px',
      width: "100px",
      marginBottom: '15px',
    },
  },
})

export const BannerHelp = ({
  title,
  text,
  linkToDoc,
}: {
  title: string
  text: string
  linkToDoc?: string
}) => {
  const intl = useIntl()
  const [showDescription, setSshowDescription] = useState(false)
  const getTruncated = (name: string) => {
    const words = name.trim().split(/\s+/)
    return words.join(' ').substring(0, 400)
  }
  return (
    <BannerCard>
      <BannerBody>
        <div>
          <h3>{intl.formatMessage({id: `${title}`})}</h3>
        </div>
        <HelpLink>
          <p style={{color: '#212121'}}>{intl.formatMessage({id: 'BANNER_HELP.TEXT'})}</p>
          <a href='https://docs.growker.ai' target='_blank' rel='noopener noreferrer'>
            {intl.formatMessage({id: 'BANNER_HELP.TEXT.LINK'})}
          </a>
        </HelpLink>
        <p style={{color: '#212121'}}>
          {showDescription
            ? intl.formatMessage({id: `${text}`})
            : getTruncated(intl.formatMessage({id: `${text}`}))}
          {intl.formatMessage({id: `${text}`}).length > 400 && (
            <button
              style={{
                border: 'none',
                background: 'transparent',
                fontWeight: 'bold',
              }}
              onClick={() => setSshowDescription((pre) => !pre)}
            >
              {showDescription
                ? intl.formatMessage({id: 'PRODUCT_HEALTH.CARD_ACTION_HIDDEN'})
                : intl.formatMessage({id: 'PRODUCT_HEALTH.CARD_ACTION_SHOW'})}
            </button>
          )}
        </p>
      </BannerBody>
      <BannerImg>
        <Img>
          <img alt='Atro' src={toAbsoluteUrl('/media/backgrounds/perso.png')} />
        </Img>
      </BannerImg>
    </BannerCard>
  )
}
