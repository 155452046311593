export type ReferenceType = {
  value: number
  label: string
  flag: boolean
}

export type IndexedReferences = {
  [key: number]: ReferenceType
}

export type State = {
  data: PlaylistType[]
  auxData: PlaylistType[]
  selected: Task[]
  references: IndexedReferences
}

export type PriorityType = 0 | 1 | 2 | 3

export type StatusType = 1 | 2 | 3

export type OriginType = 'CUSTOM' | 'OUTPUT'

export interface Task {
  id: number
  item: string
  type: OriginType
  notes: null | string
  title: null | string
  status: StatusType
  usersId: 501
  deadline: null | string
  priority: PriorityType
  createdAt: string
  deletedAt: null | string
  updatedAt: string
  resolvedAt: null | boolean
  description: string
  referenceId: number
  marketplaceId: number
}

export interface PlaylistType {
  month: number
  year: number
  resolved: number | string
  unresolved: number | string
  tasks: Task[]
}

export interface Action {
  type?: string
  key?: string
  payload: any
}

export type PlaylistActions = {
  selectOneItem: ({
    id,
    checked,
    index,
  }: {
    id: number
    checked: boolean | undefined
    index: number
  }) => void
  applyFilters: ({filters}: {filters: Filters[]}) => void
  selectAll: (checked: boolean, indexTable: number) => void
  updateTasks: (task: Task[], edit?: boolean, indexTable?: number) => void,
  updateState: (playlist: PlaylistType) => void
  sortItems: (sors: FieldSort) => void
}

export type FieldSortType = 'type' | 'marketplaceId' | 'priority' | 'status' | 'deadline' | 'createdAt'

export type FieldSort = {
  field: FieldSortType
  label: string
  sort: 'asc' | 'desc'
}

export type Field = 'status' | 'priority' | 'referenceId' | 'marketplaceId' | 'type'

export type Filters = {
  field: Field
  value: number | string
}

export type ValueList = {
  label: string
  color: string
}

export const priorityPlaylist: Record<PriorityType, ValueList> = {
  0: {
    label: 'Neutral',
    color: '#F6F6F6',
  },
  1: {
    label: 'Alta',
    color: '#F9D7DD',
  },
  2: {
    label: 'Media',
    color: '#F9E6D3',
  },
  3: {
    label: 'Baja',
    color: '#F9D7DD',
  },
}

export const statusPlaylist: Record<StatusType, ValueList> = {
  1: {
    label: 'Sin iniciar',
    color: '#F6F6F6',
  },
  2: {
    label: 'En proceso',
    color: '#A5CAF1',
  },
  3: {
    label: 'Finalizado',
    color: '#92E07E',
  },
}

export const typePlaylist: Record<OriginType, ValueList> = {
  CUSTOM: {
    label: 'CUSTOM',
    color: '#B5D2E3',
  },
  OUTPUT: {
    label: 'OUTPUT',
    color: '#CBF3F9',
  },
}

export const Months: any = {
  1: 'Enero',
  2: 'Febrero',
  3: 'Marzo',
  4: 'Abril',
  5: 'Mayo',
  6: 'Junio',
  7: 'Julio',
  8: 'Agosto',
  9: 'Septiembre',
  10: 'Octubre',
  11: 'Noviembre',
  12: 'Diciembre',
}
