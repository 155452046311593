import {useState} from 'react'
import {
  CardProductBody,
  CardProductContainer,
  CardProductDescription,
  CardProductHeader,
  CardProductImage,
  CardProductTitle,
} from './styled'
import parse from 'html-react-parser'
import {makeURL} from '../../../../_gws/helpers/misc'
import {useIntl} from 'react-intl'
import {Marketplace} from '../core/type'
import { MARKETPLACES } from '../../../constants/marketplaces'

type Product = {
  asin: string
  name: string
  price: number
  top: number
  description: string
  brand: string
  category: string
  image: string
  marketplace: number
  type: string
}

const makeLink = (asin: string, marketplace: number) => {
  const link = makeURL(asin, marketplace)
  return link && parse(link)
}

const getTruncated = (name: string) => {
  const words = name.trim().split(/\s+/)
  return words.join(' ').substring(0, 200)
}

export const ProductCard = ({
  product,
  marketplace,
}: {
  product: Product
  marketplace: Marketplace
}) => {
  const intl = useIntl()
  const [showDescription, setSshowDescription] = useState(false)
  return (
    <CardProductContainer>
      <CardProductHeader>
        <CardProductTitle>
          <h6>{product.name}</h6>
          <p>${product.price}</p>
        </CardProductTitle>
        <CardProductImage>
          <img src={product.image} alt={product.asin} />
        </CardProductImage>
      </CardProductHeader>
      <CardProductDescription>
        <p>
          {showDescription ? product.description : getTruncated(product.description)}
          {product.description.length > 200 && (
            <button onClick={() => setSshowDescription((pre) => !pre)}>
              {showDescription
                ? intl.formatMessage({id: 'PRODUCT_HEALTH.CARD_ACTION_HIDDEN'})
                : intl.formatMessage({id: 'PRODUCT_HEALTH.CARD_ACTION_SHOW'})}
            </button>
          )}
        </p>
      </CardProductDescription>
      <CardProductBody>
        <p>
          {intl.formatMessage({id: 'PRODUCT_HEALTH.TOP'})} <span>{product.top}</span>
        </p>
        <p>
          {intl.formatMessage({id: 'PRODUCT_HEALTH.ID_PRODUCT'})}
          <span>{makeLink(product.asin, product.marketplace)}</span>
        </p>
        <p>
          {intl.formatMessage({id: 'PRODUCT_HEALTH.CATEGORY'})} <span>{product.category}</span>
        </p>
        {marketplace === MARKETPLACES.AMAZON.name ? (
          <p>
            {intl.formatMessage({id: 'PRODUCT_HEALTH.INDICATOR.TYPE'})} <span>{product.type}</span>
          </p>
        ) : null}
      </CardProductBody>
    </CardProductContainer>
  )
}

