import React from 'react'
import {TextProps} from '../AgText'

/**
 * Standard prototype of Text "AgBigNumberCard" | renders a div element with a specific font size and line height, and can have a custom class and text provided as properties.
 * @param customClass - to add extra classes
 * @param text - the text content
 * @constructor
 */
const AgMonthlyTitle: React.FC<TextProps> = ({customClass, text, children}) => {
  /**
   * static css for the component
   */
  const css = {
    fontSize: '1.1em',
    //fontWeight: '700',
    //lineHeight: 'normal',
  }

  return (
    <div className={`ff-prompt ${customClass}`} style={css}>
      {text}
      {children}
    </div>
  )
}

export { AgMonthlyTitle }
