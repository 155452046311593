import React, {FC} from 'react'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import {useAuth} from '../../../../app/modules/auth'
import {getClientToken} from '../../../layout/components/header/merchant/core/_request'
import {useIntl} from 'react-intl'
import {VerificationBadge} from "./VerificationBadge";
import ConnectedBadge from "./ConnectedBadge";

type Props = {
  userID: number
  picUrl: string | undefined
  name: string | undefined
  username: string | undefined
  lastname: string | undefined
  isMerchant?: boolean
  isVerified?: boolean
  tooltip?: string
}

const AvatarUser: FC<Props> = ({picUrl, name, username, lastname, userID, isMerchant = false, isVerified}) => {
  const intl = useIntl()
  const alert = withReactContent(Swal)
  const {saveClientAuth} = useAuth()

  const handleClick = async (id: number) => {
    try {
      const data = await getClientToken(id)
      if (data.status === 200) {
        saveClientAuth(data)
      }
    } catch (error: any) {
      alert.fire({
        text: 'Error',
        icon: 'warning',
        iconColor: '#00c6c7',
        buttonsStyling: false,
        confirmButtonText: `${intl.formatMessage({
          id: 'GENERAL.ALERT.CLIENT.404',
        })} ${intl.formatMessage({
          id: 'MENU.DASHBOARD',
        })}`,
        customClass: {
          confirmButton: 'btn btn-primary',
          popup: 'bg-light',
        },
      })
    } finally {
      window.location.reload()
    }
  }

  return (
    <div
      className={'symbol symbol-circle symbol-50px me-3 bg-hover-light'}
      onClick={() => {
        handleClick(userID)
      }}
    >
      {picUrl !== undefined && picUrl !== null && picUrl !== '' ? (
        <img alt={username} src={picUrl} />
      ) : (
        <span className='symbol-label bg-light-primary text-dark text-uppercase fw-bold'>
          {lastname?.substring(0, 1)}
          {name?.substring(0, 1)}
        </span>
      )}
      {isMerchant && <ConnectedBadge />}
      {isVerified && <VerificationBadge />}
    </div>
  )
}

export {AvatarUser}
