import {mkProp} from '../../../modules/auth'
import {getInventoryData} from './_requests'

type salesI = {
  top_revenue: number
  top: number
  sold: number
  marketplace_fee: number
  discounts: number
  operating_income: number
  sales_revenue: number
}
type Product = {
  asin?: string
  description?: string
  image?: string
  landed_cost?: number
  name?: string
  product_health?: string
  sold?: number
  sales?: salesI
  top?: number
  isFba?: boolean
  isDead: boolean
}

type InventoryData = {
  [key: string]: {
    amountProducts: number
    amountUniqueProducts: number
    noDeadCount: {
      all: number
      unique: number
    }
    products: Product[]
  }
}

const filterByDeadProducts = (products: Product[], isDead: boolean) => {
  if (!isDead) return products
  return products.filter((item) => item.isDead === true)
}

export const getProductsData = async ({
  marketplace,
  isDead,
}: {
  marketplace: mkProp
  isDead: boolean
}) => {
  if (!marketplace || marketplace === 'growker') return
  const [data] = await Promise.all([getInventoryData(marketplace)])
  const inventory = data.data as InventoryData
  return {
    ...inventory[marketplace],
    amountProducts: isDead
      ? inventory[marketplace].noDeadCount.all
      : inventory[marketplace].amountProducts,
    amountUniqueProducts: isDead
      ? inventory[marketplace].noDeadCount.unique
      : inventory[marketplace].amountUniqueProducts,
    products: filterByDeadProducts(inventory[marketplace].products, isDead),
  }
}
