import {OriginType, PriorityType, ReferenceType, StatusType, Task} from '../../types'
import Offcanvas from 'react-bootstrap/Offcanvas'
import {PlaylistTab} from '../PlaylistTab'
import {useState} from 'react'
import {Alert, Container} from './styled'
import {RenderForm} from './Form'
import {Button, Spinner} from 'react-bootstrap'
import {updatePlayListTask} from '../../core/_requests'
import {useIntl} from 'react-intl'
import {TYPETASK} from '../../../../constants/task'
import {IconChecked, IconLimite} from '../PlayListIcons'
import styled from '@emotion/styled'
import {validateFields} from './validations'

export type StateTask = {
  title: string | null
  description: string
  priority: PriorityType
  deadline: string | null
  status: StatusType
  notes: string | null
  referenceId: number
  marketplaceId: number,
  type: OriginType,
  item: string
}

const BtnStyled = styled(Button)({
  background: '#0094EB !important',
  color: '#fff !important',
  marginLeft: '5px !important',
})

const dispatchClearMessage = (setIsLoading: any) => {
  setTimeout(() => {
    setIsLoading((pre: any) => ({
      ...pre,
      msg: '',
    }))
  }, 2000)
}

const FormEdit = ({
  references,
  updateTasksAction,
  item,
  indexTable,
}: {
  references: ReferenceType[]
  updateTasksAction: any
  item: Task
  indexTable: number
}) => {
  const intl = useIntl()
  const InitialState: StateTask = {
    title: item.title,
    description: item.description,
    priority: item.priority,
    deadline: item.deadline,
    status: item.status,
    notes: item.notes,
    referenceId: item.referenceId,
    marketplaceId: item.marketplaceId,
    type: item.type,
    item: item.item
  }
  const [task, setTask] = useState<StateTask>(InitialState)
  const [validations, setValidations] = useState({
    error: false,
    message: [],
  })
  const [endDate, setEndDate] = useState(() =>
    item.deadline === null ? new Date() : new Date(item.deadline)
  )
  const [isLoading, setIsLoading] = useState({
    loading: false,
    msg: '',
    error: false,
  })

  const handlerTask = (key: string, e: any) => {
    setTask((pre) => ({
      ...pre,
      [key]: e.target.value,
    }))
  }

  const handlerUpdate = async () => {
    const updateTask: any = {
      ...task,
      id: item.id,
      deadline: new Date(endDate).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    }
    const validation: any = validateFields(updateTask, intl)
    if (validation.error)
      return setValidations({
        ...validation,
      })
    setIsLoading((pre) => ({
      ...pre,
      loading: true,
    }))
    setValidations({
      error: false,
      message: [],
    })
    if (!updateTask.notes) {
      delete updateTask.notes
    }
    if (updateTask.referenceId === '0') {
      updateTask.referenceId = null
    }
    if (!updateTask.item) {
      updateTask.item = null
    }
    if (updateTask.status){
      updateTask.status = parseInt(updateTask.status)
    }
    const response = await updatePlayListTask(updateTask)
    if (response.status !== 200) {
      setIsLoading((pre) => ({
        ...pre,
        loading: false,
        msg: intl.formatMessage({id: 'PLAYLIST.ERROR.MESSAGE'}),
      }))
      dispatchClearMessage(setIsLoading)
      return
    }
    setIsLoading((pre) => ({
      ...pre,
      loading: false,
      msg: intl.formatMessage({id: 'PLAYLIST.SUCCESS.MESSAGE.EDIT'}),
    }))
    updateTasksAction(
      [
        {
          ...updateTask,
          createdAt: item.createdAt,
          type: item.type,
          usersId: item.usersId,
          status: parseInt(updateTask.status),
        },
      ],
      true,
      indexTable
    )
    dispatchClearMessage(setIsLoading)
  }

  return (
    <Container>
      {validations.error && (
        <Alert error={true}>
          <span>
            <IconLimite />
          </span>
          {validations.message.length === 1 ? 'El' : 'Los campos'}
          {validations.message.map((key: any) => {
            return ` ${key.field}, `
          })}
          {validations.message.length === 1 ? 'es requerido' : 'son requeridos'}
        </Alert>
      )}
      <RenderForm
        startDate={new Date(item.createdAt)}
        endDate={endDate}
        setDateRange={setEndDate}
        task={task}
        setTask={setTask}
        handlerTask={handlerTask}
        disabled={item.type === TYPETASK.OUTPUT}
        references={references}
        edit={true}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {isLoading.msg && (
          <Alert error={false}>
            {!isLoading.error ? (
              <span>
                <IconChecked />
              </span>
            ) : (
              <span>
                <IconLimite />
              </span>
            )}
            {isLoading.msg}
          </Alert>
        )}
        <BtnStyled disabled={isLoading.loading} onClick={handlerUpdate} size='sm'>
          {isLoading.loading ? (
            <Spinner size='sm' animation='border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </Spinner>
          ) : (
            <>{intl.formatMessage({id: 'PLAYLIST.HEADER.UPDATE.TASK.TITLE'})}</>
          )}
        </BtnStyled>
      </div>
    </Container>
  )
}

export const PlaylistEditTask = ({
  show,
  handleClose,
  references,
  item,
  updateTasks,
  indexTable,
}: {
  show: boolean
  handleClose: any
  references: ReferenceType[]
  item: Task
  updateTasks: any
  indexTable: number
}) => {
  const intl = useIntl()
  const content: any = {
    tasks: (
      <FormEdit
        indexTable={indexTable}
        item={item}
        references={references}
        updateTasksAction={updateTasks}
      />
    ),
  }
  return (
    <Offcanvas show={show} onHide={handleClose} placement='end'>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          {intl.formatMessage({id: 'PLAYLIST.HEADER.UPDATE.TASK.TITLE'})}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <PlaylistTab isCreate={true} content={content} />
      </Offcanvas.Body>
    </Offcanvas>
  )
}
