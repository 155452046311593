/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {HeaderToolbar} from './HeaderToolbar'
import {useIntl} from 'react-intl'
import {useState} from 'react'
import styled from '@emotion/styled'

const Container = styled('div')({
  background: '#FFFFFF',
})
export function HeaderWrapper() {
  const intl = useIntl()
  const {classes, attributes} = useLayout()
  const [playlistItems] = useState(0)

  // const fetchPlaylist = async () => {
  //   try {
  //     /**
  //      * Params to send to the backend
  //      */
  //     const params: any = {
  //       pagination: {
  //         page: 1,
  //         records: 2,
  //       },
  //       filters: {},
  //       order: [['id', 'ASC']],
  //     }
  //
  //     /**
  //      * Request to the backend and set the data
  //      */
  //     const response = await getOwnPlaylistPaginated(params)
  //     if (response.data.status === 200) {
  //       setPlaylistItem(response.data.pageInfo.total_records)
  //     }
  //   } catch (error: any) {
  //     console.error('Error al obtener el playlist', error)
  //   }
  // }
  //
  // fetchPlaylist()

  //const {config, classes, attributes} = useLayout()
  //const {aside} = config
  // const systemMode =
  //   ThemeModeComponent.getMenuMode() !== '' ? ThemeModeComponent.getMenuMode() : 'dark'
  return (
    <Container
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      <div className='header-brand' style={{background: '#fff'}}>
        <Link to='/'>
          {/*<img*/}
          {/*  alt='Logo'*/}
          {/*  src={toAbsoluteUrl('/media/logos/growker_' + systemMode + '.svg')}*/}
          {/*  className='h-25px h-lg-25px'*/}
          {/*/> */}
          <img
            alt='Logo Growker'
            src={toAbsoluteUrl('/media/logos/gwsbeta_1.svg')}
            className='h-25px h-lg-25px d-lg-block d-none'
          />
          <img
            alt='Logo Growker'
            src={toAbsoluteUrl('/media/logos/gwsbeta_1.svg')}
            className='h-25px h-lg-25px d-lg-none'
          />
        </Link>

        {/*{aside.minimize && (*/}
        {/*  <div*/}
        {/*    id='kt_aside_toggle'*/}
        {/*    className='btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize'*/}
        {/*    data-kt-toggle='true'*/}
        {/*    data-kt-toggle-state='active'*/}
        {/*    data-kt-toggle-target='body'*/}
        {/*    data-kt-toggle-name='aside-minimize'*/}
        {/*  >*/}
        {/*    <KTSVG*/}
        {/*      path='/media/icons/duotune/arrows/arr092.svg'*/}
        {/*      className='svg-icon-1 me-n1 minimize-default'*/}
        {/*    />*/}
        {/*    <KTSVG*/}
        {/*      path='/media/icons/duotune/arrows/arr076.svg'*/}
        {/*      className='svg-icon-1 minimize-active'*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*)}*/}

        <div
          className='d-flex align-items-center d-lg-none ms-n3 me-1'
          title={intl.formatMessage({id: 'ASIDE.SHOW_MENU'})}
        >
          <div
            className='btn btn-icon btn-active-color-primary w-30px h-30px'
            id='kt_aside_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-1' />
          </div>
        </div>
      </div>

      <HeaderToolbar playlistItems={playlistItems} />
    </Container>
  )
}
