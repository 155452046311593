import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {Audit} from './Audit'
import {PageTitle} from '../../../../_gws/layout/core'

const AuditWrapper: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: intl.formatMessage({id: 'MENU.HOME'}),
            path: '/dashboard',
            isSeparator: false,
            isActive: false,
          },
          {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.AUDIT'})}
      </PageTitle>
      <Audit />
    </>
  )
}

export {AuditWrapper}
