import {template} from './template'
import {Action, State} from './types'

export const initialState: State = {
  glossary: [],
  content: '',
  change: false,
  item: {
    id: 0,
    title: '',
    description: '',
    category: '',
    categories: [],
    subtitle: ''
  },
  loading: false
}

export const enum ActionTypes {
  SET_STATE = 'SET_STATE',
  CHANGE_CONTENT = 'CHANGE_CONTENT',
  CHANGE_ITEM = 'CHANGE_ITEM',
  RESET_STATE = 'RESET_STATE',
  EDIT_ITEM = 'EDIT_ITEM',
}

const setInitialState = (state: State, {payload}: Action) => ({
  ...state,
  glossary: payload.glossary,
  content: template,
})

const handlerChangeMD = (state: State, {payload}: Action) => ({
  ...state,
  content: payload,
  change: true,
})

const handlerChangeItem = (state: State, {payload}: Action) => ({
  ...state,
  item: {
    ...state.item,
    [payload.key]: payload.value,
  },
  change: true,
})

const handlerEditItem = (state: State, {payload}: Action) => {
  return {
    ...state,
    item: payload.item,
    content: payload.item.description,
    change: true,
  }
}

const resetContentMD = (state: State) => ({
  ...state,
  content: template,
  change: false,
  item: {
    id: 0,
    title: '',
    description: '',
    category: '',
    categories: [],
    subtitle: ''
  },
})

const actionMap = new Map<string, any>([
  [ActionTypes.SET_STATE, setInitialState],
  [ActionTypes.CHANGE_CONTENT, handlerChangeMD],
  [ActionTypes.RESET_STATE, resetContentMD],
  [ActionTypes.CHANGE_ITEM, handlerChangeItem],
  [ActionTypes.EDIT_ITEM, handlerEditItem],
])

export const documentationEditorReducer = (state: State, action: Action) => {
  const typeToUse = action.type ? action.type : ActionTypes.SET_STATE
  const mappedActions = actionMap.get(typeToUse)
  return mappedActions ? mappedActions(state, action) : state
}
