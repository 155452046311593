/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../_gws/assets/ts/components'
import {useIntl} from 'react-intl'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import {associateUser} from '../core/_requests'

type Props = {
  row: any
  userID: number
}

/**
 * The button showed in list of users to assign the users
 * @param row - List of users
 * @param userID - The Merchant ID
 */
const UserActionsCell: FC<Props> = ({row, userID}) => {
  const intl = useIntl()
  const Alert = withReactContent(Swal)

  /**
   * To handle the loading behaviour icon over the button
   */
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    MenuComponent.reinitialization()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Used to fire the associate API after a sweet alert confirmation
   * @param clientID
   */
  const associate = async (clientID: number) => {
    try {
      setLoading(true)
      await associateUser(userID, clientID)
    } catch (error: any) {
      setLoading(false)
      Alert.fire({
        title: intl.formatMessage({id: 'USER_MNGT.TABLE.ACTIONS.ASSOCIATE.ERROR'}),
        icon: 'error',
        iconColor: '#00c6c7',
        buttonsStyling: false,
        confirmButtonText: intl.formatMessage({id: 'ACCOUNT.PROFILE.DETAILS.OK_GOT_IT'}),
        customClass: {
          confirmButton: 'btn btn-primary',
          popup: 'bg-light',
        },
      })
    } finally {
      setLoading(false)
      Alert.fire({
        text: intl.formatMessage({id: 'USER_MNGT.TABLE.ACTIONS.ASSOCIATE.DONE'}),
        title: intl.formatMessage({id: 'USER_MNGT.MODAL.DELETED_DONE'}),
        icon: 'success',
        iconColor: '#00c6c7',
        buttonsStyling: false,
        confirmButtonText: intl.formatMessage({id: 'ACCOUNT.PROFILE.DETAILS.OK_GOT_IT'}),
        customClass: {
          confirmButton: 'btn btn-primary',
          popup: 'bg-light',
        },
      }).then(() => {
        /**
         * After the action refreshing is needed
         */
        window.location.reload()
      })
    }
  }

  /**
   * Handle the click over the table button and ask to confirm the action, then fires the associate function
   * @param clientID
   */
  const handleOnClick = (clientID: any) => {
    Alert.fire({
      text: intl.formatMessage({id: 'USER_MNGT.ASSOCIATE.WARNING'}),
      title: intl.formatMessage({id: 'AUTH.GENERAL.ARE_YOU_SURE'}),
      icon: 'question',
      iconColor: '#00c6c7',
      buttonsStyling: false,
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({id: 'AUTH.GENERAL.CONTINUE'}),
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-light-primary',
        popup: 'bg-light',
      },
      focusConfirm: false,
      cancelButtonText: intl.formatMessage({id: 'USER_MNGT.MODAL.BUTTON.CANCEL'}),
    }).then((result) => {
      if (result.isConfirmed) {
        associate(clientID)
      }
    })
  }

  return (
    <>
      {!row.assigned ? (
        <span
          onClick={() => handleOnClick(row.id)}
          className='py-3 px-5 border-0 mb-5 mt-5 text-primary bg-black bg-hover-opacity-75 rounded-pixel shadow-login-btn cursor-pointer'
        >
          {!loading && (
            <span className='indicator-label'>
              {intl.formatMessage({id: 'USER_MNGT.TABLE.ACTIONS.ASSOCIATE'})}
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'AUTH.GENERAL.PLEASE_WAIT'})}...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </span>
      ) : (
        <span className='btn disabled btn-light btn-active-light-primary btn-sm rounded-pixel'>
          {intl.formatMessage({id: 'USER_MNGT.TABLE.ACTIONS.ASSOCIATED'})}
        </span>
      )}
    </>
  )
}

export {UserActionsCell}
