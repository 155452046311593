import styled from '@emotion/styled'
import {Link, useLocation} from 'react-router-dom'
import {IconDashboard} from '../icons/IconDashboard'
import {IconBrandHealth} from '../icons/IconBrandHealth'
import {IconPlaylist} from '../icons/IconPlaylist'
import {IconStrategy} from '../icons/IconStrategy'
import {IconInventory} from '../icons/IconInventory'
import {IconPaidMarketing} from '../icons/IconPaidMarketing'
import {IconGlossary} from '../icons/IconGlossary'
import {IconProfile} from '../icons/IconProfile'
import {IconSales} from '../icons/IconSales'
import {useIntl} from 'react-intl'
// import { IconMonitor } from '../icons/IconMonitor'
// import { IconDiagnostic } from '../icons/IconDiagnostick'
import { IconBeeper } from '../icons/IconBeeper'

type Item = {
  icon: any
  to: string
  label: string,
  remote?:string
}
type Section = {
  section: string
  items: Item[]
}

type Navigation = Section[]

const navigation: Navigation = [
  {
    section: '',
    items: [
      {
        icon: IconDashboard,
        to: '/dashboard',
        label: 'MENU.DASHBOARD',
      },
    ],
  },
  {
    section: 'BRANDHEALTH',
    items: [
      {
        icon: IconBrandHealth,
        to: '/brand-health',
        label: 'MENU.BRAND_HEALTH',
      },
      {
        icon: IconPlaylist,
        to: '/playlist',
        label: 'MENU.PLAYLIST',
      },
      {
        icon: IconStrategy,
        to: '/strategy',
        label: 'MENU.REPORTS.STRATEGY',
      },
    ],
  },
  {
    section: 'PRODUCTOS',
    items: [
      {
        icon: IconInventory,
        to: '/inventory',
        label: 'MENU.REPORTS.INVENTORY',
      },
    ],
  },
  {
    section: 'REPORTES',
    items: [
      {
        icon: IconSales,
        to: '/sales',
        label: 'MENU.REPORTS.SALES',
      },
      {
        icon: IconPaidMarketing,
        to: '/paid-marketing',
        label: 'MENU.REPORTS.PAID_MARKETING',
      },
    ],
  },
  {
    section: 'APLICACIONES',
    items: [
      {
        icon: IconBeeper,
        to: '/beeper',
        label: 'MENU.BEEPER',
      },
    ],
  },
  {
    section: 'AJUSTES',
    items: [
      {
        icon: IconProfile,
        to: '/account/overview',
        label: 'HUMENU.PROFILE',
      },
    ],
  },
  {
    section: 'CENTRO DE AYUDA',
    items: [
      {
        icon: IconGlossary,
        to: '/glossary',
        label: 'MENU.GLOSSARY',
        remote: `${process.env.REACT_APP_REDIRECT_URL}/glossary`,
      },
    ],
  },
]
const Container = styled('div')({
  // paddingLeft: '20px',
  overflow: 'hidden',
})

const NavItem = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  fontSize: '1rem',
  fontWeight: '500',
  color: '#2F2F2FE6',
  margin: '10px 0px',
  cursor: 'pointer',
  transition: 'all ease .3s',
  padding: '5px',
  paddingLeft: '20px',
  ':hover': {
    color: '#2F2F2F',
    background: '#F5F5F5',
  },
})

export const ItemDoc = styled('a')({
  display: 'flex',
  alignItems: 'center',
  fontSize: '1rem',
  fontWeight: '500',
  color: '#2F2F2FE6',
  margin: '10px 0px',
  cursor: 'pointer',
  transition: 'all ease .3s',
  padding: '5px',
  paddingLeft: '20px',
  ':hover': {
    color: '#2F2F2F',
    background: '#F5F5F5',
  },
})

const SectionLabel = styled('div')({
  margin: '13px 0px',
  fontWeight: '900',
  paddingLeft: '20px',
})

const Content = styled('div')({
  overflow: 'auto',
  maxHeight: '100%',
  width: 'calc(100% + 17px)',
})

const remotePaths: string[] = []

const MentorAside = () => {
  const intl = useIntl()
  const location = useLocation()
  return (
    <Container>
      <Content>
        {navigation.map((section: Section, i: number) => (
          <div key={i}>
            <SectionLabel>{section.section}</SectionLabel>
            {section.items.map((item: Item) =>
              remotePaths.includes(item.to) ? (
                <ItemDoc
                  href={item.remote}
                  target="_self"
                  rel='noopener noreferrer'
                >
                  <div style={{marginRight: '10px'}}>
                    <item.icon
                      size={12}
                      color={location.pathname === item.to ? '#0D7CBD' : '#2F2F2F'}
                    />
                  </div>
                  {intl.formatMessage({id: `${item.label}`})}
                </ItemDoc>
              ) : (
                <NavItem to={item.to} key={item.label}>
                  <div style={{marginRight: '10px'}}>
                    <item.icon
                      size={12}
                      color={location.pathname === item.to ? '#0D7CBD' : '#2F2F2F'}
                    />
                  </div>
                  {intl.formatMessage({id: `${item.label}`})}
                </NavItem>
              )
            )}
          </div>
        ))}
      </Content>
    </Container>
  )
}

export {MentorAside}

/**
  {
    section: 'GROWKER LABS',
    items: [
      {
        icon: IconMonitor ,
        to: '/performace-monitor',
        label: 'MENU.MONITOR',
      },
    ],
  },
 */
