import axios from 'axios'

/**
 * API ENV Parameter
 */
const API_URL_CRUNCH = process.env.REACT_APP_BACKEND_MAIN_API

/**
 * URL build for api request
 */
export const GET_AUDIT = `${API_URL_CRUNCH}/api/audit/get`

/**
 * It returns a promise that will resolve to the response of the GET request to the URL GET_AUDIT
 * @returns An array of users log
 */
export function getAuditData(page: number, size: number, search: string) {
  return axios.post(GET_AUDIT, {
    page: page,
    size: size,
    search: search,
  })
}
