import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'
import {ToolkitContextType} from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import {optionsTable} from '../../../../../_gws/partials/tables/TableHelper'

type Props = {
  columns: any
  products: any
  title: string
  autoHeight: boolean
}
const FrankieTable: FC<Props> = ({columns, products, title, autoHeight}) => {
  const intl = useIntl()
  const height = autoHeight === undefined ? false : autoHeight
  const customTotal = (from: number, to: number, size: number) => (
    <div className='mt-3 mb-4 justify-content-start align-items-center'>
      <span className='text-gray-600 fw-normal gy-5'>
        {from} {'→'} {to} {intl.formatMessage({id: 'REPORT.INVENTORY.FROM'})} {size}{' '}
      </span>
    </div>
  )

  const customTexts = {
    firstPageText: '',
    prePageText: '',
    nextPageText: '',
    lastPageText: '',
    nextPageTitle: '',
    prePageTitle: '',
    firstPageTitle: '',
    lastPageTitle: '',
  }
  return (
    <div className={height ? 'card h-100' : 'card'}>
      <div className='card-header border-0'>
        <h3 className='card-title fw-semibold text-dark'>{title}</h3>
      </div>
      <div className='card-body pt-0 pb-3'>
        <ToolkitProvider columns={columns} data={products} keyField={'uniqueId'}>
          {(props: ToolkitContextType) => (
            <div>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                striped={false}
                hover={true}
                pagination={paginationFactory(
                  optionsTable(
                    customTotal,
                    intl.locale,
                    1,
                    1,
                    undefined,
                    5,
                    undefined,
                    undefined,
                    undefined,
                    customTexts
                  )
                )}
                classes={'table table-row-gray-900 gy-3 gs-3'}
                wrapperClasses={'table-responsive position-relative mb-5'}
                bodyClasses={'text-gray-600 fw-normal'}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  )
}

export default FrankieTable
