import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'
import {useAuth} from '../../../../../../app/modules/auth'

const AppsAside = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()

  let isAdmin = currentUser?.roleId === 2
  // const {currentUser} = useAuth()
  // let isMerchant = currentUser?.roleId === 3
  return (
    <>
      <AsideMenuItemWithSub
        listTo={['wip']}
        icon='/media/icons/gws-icons/aside-apps.svg'
        title={intl.formatMessage({id: 'MENU.APPS'})}
      >
        {isAdmin ? (
          <>
            <AsideMenuItem
              to='/apps/audit'
              title={intl.formatMessage({id: 'MENU.AUDIT'})}
              hasBullet={true}
            />
          </>
        ) : (
          <>
            {/*Calendario*/}
            {/*<AsideMenuItem*/}
            {/*  to='/calendar'*/}
            {/*  title={intl.formatMessage({id: 'MENU.CALENDAR'})}*/}
            {/*  hasBullet={true}*/}
            {/*/>*/}
            {/*Chat*/}
            {/*Ticket*/}
            {/*comunidad*/}
            {/*mentores*/}

            {/*Biper*/}
            <AsideMenuItem
              to='/beeper'
              title={intl.formatMessage({id: 'MENU.BEEPER'})}
              hasBullet={true}
            />
            
            {/** diagnostik */}
            {/**<AsideMenuItem
              to='/DI.A.gnostik'
              title={intl.formatMessage({id: 'MENU.DI.A.gnostik'})}
              hasBullet={true}
            />*/}
            {
              /**
              <AsideMenuItem
              to='/mentores'
              title={intl.formatMessage({id: 'MENU.MENTORS'})}
              hasBullet={true}
            />
               */
            }
            {/*glossary*/}
            <AsideMenuItem
              to='/glossary'
              title={intl.formatMessage({id: 'MENU.GLOSSARY'})}
              hasBullet={true}
            />
            {/*Wiki*/}
            {/**
            <div className='menu-item'>
              <a
                href='https://wiki.growker.ai/'
                className={'menu-link without-sub'}
                target='_blank'
                rel='noreferrer'
              >
                <span className='menu-bullet'>
                  <span className='bullet bullet-dot'></span>
                </span>

                <AgAside
                  choice={'category'}
                  text={'Wiki'}
                  customClass={'menu-title text-gray-550 py-0'}
                />
              </a>
            </div>
             */}
            {/*Archivos*/}
          </>
        )}
      </AsideMenuItemWithSub>
    </>
  )
}

export {AppsAside}
