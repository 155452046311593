import {IconBase} from './Base'

export const IconDashboard = ({color = '#273A51', size = 24}: {color?: string; size?: number}) => {
  const setColor = color
  return (
    <IconBase size={size} color={setColor} viewBox='0 0 10 10'>
      <svg
        width='10'
        height='10'
        viewBox='0 0 10 10'
        fill='none'
      >
        <path
          d='M9.01899 9.70546C8.75354 9.90121 8.33537 9.99997 7.76267 9.99997H6.55726C5.98456 9.99997 5.56823 9.90121 5.30642 9.70546C5.11189 9.55908 4.99007 9.34217 4.94098 9.05295C4.89189 9.34217 4.77188 9.55908 4.58098 9.70546C4.32462 9.90121 3.90466 9.99997 3.32468 9.99997H2.14473C1.57203 9.99997 1.15204 9.90121 0.888412 9.70546C0.622968 9.5097 0.490234 9.18345 0.490234 8.73022V4.11505C0.639319 4.17325 0.813857 4.20675 1.01203 4.20675H1.47748C1.78474 4.20675 2.09744 4.1274 2.37561 3.9916V8.50626C2.37561 8.60149 2.43563 8.6491 2.55562 8.6491H3.86465C3.98465 8.6491 4.04464 8.60149 4.04464 8.50626V3.79409C4.25009 3.88932 4.48644 3.9193 4.74097 3.9193H5.16825C5.41187 3.9193 5.64094 3.88226 5.83912 3.79232V8.50449C5.83912 8.59972 5.89911 8.64734 6.01911 8.64734H7.35359C7.47359 8.64734 7.53361 8.59972 7.53361 8.50449V3.34262C7.81905 3.4837 8.11721 3.56836 8.43174 3.56836H8.89719C9.08445 3.56836 9.27353 3.53838 9.41898 3.48547V8.73022C9.41898 9.18345 9.28625 9.5097 9.02081 9.70546H9.01899Z'
          fill={color}
        />
        <path
          d='M8.93894 2.4231H8.47349C7.69898 2.4231 6.88811 1.91873 6.6663 1.2962L6.60085 1.11279V1.30678C6.60085 1.92225 5.97906 2.4231 5.21182 2.4231H4.78454C4.0173 2.4231 3.39553 1.92402 3.39553 1.30678V1.11279L3.33006 1.2962C3.10643 1.91873 2.29738 2.4231 1.52287 2.4231H1.05742C0.282908 2.4231 -0.166164 1.91873 0.0574628 1.2962L0.361095 0.447937C0.455636 0.181643 0.759252 0 1.10287 0H8.89713C9.24075 0 9.54436 0.183407 9.63891 0.447937L9.94254 1.2962C10.1662 1.91873 9.71709 2.4231 8.94258 2.4231H8.93894Z'
          fill={color}
        />
      </svg>
    </IconBase>
  )
}
