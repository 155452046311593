import {Months} from '../../../types'

export const RenderDate = ({date}: {date: string}) => {
  const flex = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  }
  const created = new Date(date)
  .toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
  const month =  new Date(date).getMonth()
  return <div style={{...flex}}>{`${created.split('/')[1]} ${Months[month + 1]}`}</div>
}
