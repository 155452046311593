import {IndicatorCard, IndicatorsWrapper} from './styled'
import {useIntl} from 'react-intl'
import {  IndicatorFieldType, Indicators, ItemIndicator, KeyIndicator } from '../core/types'


export const MarketingIndicators = ({indicator}: {indicator: Indicators}) => {
  const intl = useIntl()
  const IndicatorField: Record<KeyIndicator, IndicatorFieldType> = {
    campaign: {
      background: '#E2EBD3',
      label: intl.formatMessage({id: 'REPORT.PAID_MARKETING.CAMPAIGNS'}),
    },
    convertions: {
      background: '#CBF3F9',
      label: intl.formatMessage({id: 'REPORT.PAID_MARKETING.CONVERTIONS'}),
    },
    clicks: {
      background: '#CEE6E3',
      label: intl.formatMessage({id: 'REPORT.PAID_MARKETING.CLICKS'}),
    },
    cpc: {
      background: '#E2EBD3',
      label: intl.formatMessage({id: 'REPORT.PAID_MARKETING.CPC'}),
    },
    tacos: {
      background: '#E6FBFF',
      label: intl.formatMessage({id: 'REPORT.PAID_MARKETING.TACOS'}),
    },
    acos: {
      background: '#D4FFFA',
      label: intl.formatMessage({id: 'REPORT.PAID_MARKETING.ACOS'}),
    },
    budget: {
      background: '#E2EBD3',
      label: intl.formatMessage({id: 'REPORT.ACTIVE_CAMPAIGNS.DAILY_BUDGET'}),
    },
    cost: {
      background: '#CBF3F9',
      label: intl.formatMessage({id: 'REPORT.PAID_MARKETING.COST'}),
    },
    impressions: {
      background: '#CEE6E3',
      label: intl.formatMessage({id: 'REPORT.PAID_MARKETING.IMPRESSIONS'}),
    },
  }
  const keys = Object.keys(indicator) as KeyIndicator[]
  const indicators = keys.reduce((acc: ItemIndicator[], el: KeyIndicator) => {
    acc.push({
      value: indicator[el],
      key: el,
      background: IndicatorField[el].background,
      label: IndicatorField[el].label,
    })
    return acc
  }, [])
  return (
    <IndicatorsWrapper>
      {indicators.map((item, i) => (
        <IndicatorCard key={i} bg={item.background}>
          <div>
            <h6>{item.label}</h6>
            <p>{item.value}</p>
          </div>
        </IndicatorCard>
      ))}
    </IndicatorsWrapper>
  )
}