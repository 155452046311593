import React from 'react'
import {AgText} from '../../../layout/components/ag/AgText'
import {addCommasInNumber} from '../../../helpers/misc'

type PropsChartsLabels = {
  colors: string[]
  currentElement?: number
  handleMouseIn?: any
  handelMouseOut?: any
  isRow?: boolean
  labels: string[]
  values?: string[] | number[]
  isMoney?: boolean
}

/**
 * This component is used to display the labels of the charts
 * @param labels - array of labels
 * @param values - array of values
 * @param colors - array of colors in hexadecimal format
 * @param handleMouseIn - function to change the color of the bar when mouse enter
 * @param handelMouseOut - function to reset the color of the bar when mouse leave
 * @param isRow - boolean to display the labels in row or column
 * @constructor
 */
const ChartsLabels: React.FC<PropsChartsLabels> = ({
  labels,
  values,
  colors,
  handleMouseIn,
  handelMouseOut,
  isRow = false,
  isMoney,
}) => {
  return (
    <>
      <div
        className={`d-flex flex-${isRow ? 'row' : 'column'} justify-content-${
          isRow ? 'start' : 'center'
        } flex-grow-1 mb-3 mt-3 ms-2 gap-3`}
      >
        {labels &&
          labels.map((data: any, i: number) => (
            <div
              className='d-flex fs-8 fw-regular align-items-baseline'
              key={i.toString()}
              onMouseEnter={() => {
                handleMouseIn(i)
              }}
              onMouseLeave={handelMouseOut}
            >
              <div className='w-10px h-10px me-3' style={{backgroundColor: colors[i]}}></div>
              <div className='fs-8 fw-semibold text-dark'>
                <AgText choice={'percentageCard'} text={data} />
              </div>
              {values && (
                <>
                  <div className='separator min-w-10px flex-grow-1 mx-2'></div>
                  <div className='ms-auto fw-bolder text-dark text-end'>
                    <AgText
                      choice={'percentageCard'}
                      text={isMoney ? `$${addCommasInNumber(values[i])}` : `${values[i]}`}
                    />
                  </div>
                </>
              )}
            </div>
          ))}
      </div>
    </>
  )
}

export {ChartsLabels}
