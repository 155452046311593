import React, {FC, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../../_gws/helpers'
import {Splash} from '../../../../../../_gws/helpers/misc'
import {getMerchantDetails} from '../core/_requests'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'
import {Merchant} from '../core/_models'
import {UsersListSymbols} from './UsersListSymbols'

type MerchantHeaderProps = {
  userID: number
  activeUsers: any
}

/**
 * Component to show the Merchant info, this is used in Merchant details to assign new users
 * @param userID - The Merchant ID
 * @param activeUsers - List of all users associated to current Merchant
 */
const MerchantHeader: FC<MerchantHeaderProps> = ({userID, activeUsers}) => {
  const Alert = withReactContent(Swal)
  const intl = useIntl()

  /**
   * To set the actual User acting as Merchant
   */
  const [merchant, setMerchant] = useState<Merchant>()

  /**
   * Getting the info from the Endpoint and populating the Merchant object
   * @param userID
   */
  const fetchMerchantInfo = async (userID: number) => {
    try {
      Splash(true)
      const {data} = await getMerchantDetails(userID)
      if (data) {
        setMerchant(data.detail)
        Splash(false)
      }
    } catch (error: any) {
      Alert.fire({
        title: intl.formatMessage({id: 'ERROR.BACKEND.FETCH'}),
        text: intl.formatMessage({id: 'USER_MNGT.ALERT.UNABLE_USER'}),
        icon: 'error',
        iconColor: '#00c6c7',
        buttonsStyling: false,
        confirmButtonText: intl.formatMessage({id: 'ACCOUNT.PROFILE.DETAILS.OK_GOT_IT'}),
        customClass: {
          confirmButton: 'btn btn-primary',
          popup: 'bg-light',
        },
      }).then(() => {
        Splash(false)
      })
    }
  }

  useEffect(() => {
    fetchMerchantInfo(userID)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {merchant !== undefined && (
        <div className='card mb-5 mb-xl-5'>
          <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
              <div className='me-7 mb-4'>
                <div className='symbol symbol-100px symbol-lg-150px symbol-fixed position-relative'>
                  {merchant.contactProfiles?.map((profiles) => (
                    <img
                      alt={merchant.username}
                      src={
                        profiles.picture !== null &&
                        profiles.picture !== undefined &&
                        profiles.picture !== ''
                          ? profiles.picture
                          : toAbsoluteUrl('/media/svg/avatars/blank.svg')
                      }
                      key={profiles.id}
                    />
                  ))}
                  {Number(merchant.contactProfiles?.length) === 0 && (
                    <img
                      alt={merchant.username}
                      src={toAbsoluteUrl('/media/svg/avatars/blank.svg')}
                    />
                  )}
                </div>
              </div>

              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-1'>
                      <span className='text-gray-800 fs-2 fw-bolder me-1'>
                        {merchant?.name} {merchant?.lastname}
                      </span>
                    </div>
                    <div className=' fs-6 mb-4 pe-2'>
                      <div className='d-block align-items-center text-gray-600 me-5 mb-1 mt-2 fw-normal'>
                        <KTSVG
                          path='/media/icons/unicos/019-message.svg'
                          className='svg-icon-4 me-1'
                        />
                        {merchant.email}
                      </div>
                      <div className='d-block align-items-center text-gray-600 me-5 mb-2 fw-normal'>
                        <KTSVG
                          path='/media/icons/unicos/043-user.svg'
                          className='svg-icon-4 me-1'
                        />
                        {merchant.username}
                      </div>
                    </div>
                    <UsersListSymbols activeUsers={activeUsers} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export {MerchantHeader}
