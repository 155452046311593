import {Category, ItemGlossary} from './types'

export const categories: Category[] = [
  'Output',
  'report',
  'marketing',
  'sales',
  'inventory',
  'amazon',
  'mercado-libre',
  'amazon-ads',
]

export const glossaryItems: ItemGlossary[] = [
  {
    id: 1,
    title: 'Calidad de título',
    description:
      'Se refiere a la forma en que se presenta y se optimiza el título de un producto en la plataforma. El título es una parte crucial de la información que se muestra a los clientes y puede influir en su decisión de hacer clic en un producto y realizar una compra. Una buena calidad de título puede ayudar a mejorar la visibilidad y la relevancia de un producto en los resultados de búsqueda de Amazon.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 2,
    title: 'Reputacion',
    description: 'Reputación del vendedor en el marketplace.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 3,
    title: 'Stock Disponible',
    description: 'Cantidad de unidades disponibles de todos los productos.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 4,
    title: 'Estados de Productos',
    description:
      'Estos estados son importantes para que los compradores puedan conocer la condición del producto antes de realizar una compra. Los vendedores deben seleccionar el estado adecuado al listar sus productos para garantizar una descripción precisa y transparente del artículo que están vendiendo. Esto ayuda a generar confianza y satisfacción entre los compradores.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 5,
    title: 'Productos Únicos sin Stock',
    description:
      'En Amazon y Mercado Libre, los "Productos Únicos sin Stock" (PSS) se refieren a los productos que están listados en la plataforma pero actualmente no tienen stock disponible para su venta. Estos productos pueden haber agotado su inventario o estar temporalmente fuera de stock debido a diferentes circunstancias, como alta demanda, procesos de reposición o cualquier otro motivo que haya llevado a la falta de disponibilidad en ese momento.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 6,
    title: 'ROAS',
    description:
      'El Roas (Return On Advertising Spend) es el Retorno de la Inversión Publicitaria y nos dice a través de un porcentaje, por cada peso que invertimos en publicidad cuántos pesos nos generan las campañas. Sin duda uno de los KPIs principales para saber la rentabilidad de tus campañas publicitarias.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 7,
    title: 'CTR',
    description:
      'El CTR (Click-Through Rate) se refiere a la tasa de clics, es decir, la proporción de usuarios que hacen clic en un anuncio en relación con el número de veces que se muestra ese anuncio. Tanto en Amazon como en Mercado Libre, el CTR de una campaña se calcula dividiendo el número total de clics entre el número total de impresiones y luego multiplicándolo por 100 para obtener el porcentaje.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 8,
    title: 'Productos únicos sin Landed Cost',
    description:
      '(PSLC) Se refieren a aquellos productos que están listados en la plataforma pero no tienen un costo de aterrizaje (landed cost) asociado. El costo de aterrizaje se refiere al costo total de un producto que incluye el precio de compra, los gastos de envío, los impuestos y cualquier otro costo relacionado con la importación o adquisición del producto.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 9,
    title: 'Producto sin campañas Activas',
    description:
      '(PSCA) Se refiere a un producto que no está actualmente promocionado o anunciado mediante campañas de publicidad en la plataforma de Amazon.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 10,
    title: 'Calidad de imágenes',
    description:
      'La "Calidad de imágenes" (GCI) en Amazon y Mercado Libre (MELI) se refiere a los estándares y requisitos que deben cumplir las imágenes de los productos para ser aceptadas y mostradas de manera efectiva en las plataformas.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 11,
    title: 'Calidad de bullets points (GCBP)',
    description:
      'La calidad de bullets points en Amazon y Mercado Libre (MELI) se refiere a los estándares y requisitos que deben cumplir los puntos destacados o características del producto para ofrecer una descripción clara y convincente.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 12,
    title: 'Calidad de descripción',
    description:
      'La "Calidad de descripción" (GCD) en Amazon y Mercado Libre (MELI) se refiere a los estándares y requisitos que deben cumplir las descripciones de los productos para proporcionar información detallada y precisa sobre el producto.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 13,
    title: 'Cantidad de Productos',
    description:
      'La cantidad exacta de productos fluctúa constantemente debido a las adiciones y eliminaciones de productos por parte de los vendedores y la rotación del inventario.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 14,
    title: 'Portfolio',
    description:
      'La cantidad de productos únicos en el portfolio de un vendedor puede variar según el tamaño y la estrategia del vendedor. Algunos vendedores pueden tener un portfolio amplio con cientos o incluso miles de productos únicos, mientras que otros pueden tener un portfolio más pequeño y especializado con un número limitado de productos.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 15,
    title: 'ASIN',
    description:
      'ASIN son las siglas de "Amazon Standard Identification Number" (Número de Identificación Estándar de Amazon, en español). Es un identificador único de 10 caracteres asignado a cada producto que se vende en Amazon. Cada variante de un producto tiene su propio ASIN, lo que significa que un mismo producto en diferentes colores o tamaños tendrá diferentes ASIN.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 16,
    title: 'SKU1',
    description:
      'La sigla "SKU" significa "Stock Keeping Unit" (Unidad de Mantenimiento de Stock, en español). Un SKU es un código alfanumérico único que se utiliza para identificar y rastrear un producto específico en el inventario de una empresa o comercio.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 17,
    title: 'SKU2',
    description:
      'El término "SKU2" no es un estándar ampliamente reconocido en el contexto de Amazon o Mercado Libre. Sin embargo, basándome en el contexto de SKU, podría inferir que "SKU2" podría referirse a un segundo código de Stock Keeping Unit (SKU) asignado a un producto específico dentro de un sistema de gestión de inventario.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 18,
    title: 'Meli ID',
    description:
      'Es una abreviatura utilizada comúnmente para referirse al "ID de Mercado Libre" o al "identificador de Mercado Libre". Se refiere a un número o código único asignado a un producto, publicación, usuario o cualquier elemento en la plataforma de Mercado Libre.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 19,
    title: 'Nombre del producto',
    description:
      'El nombre del producto es importante en ambos marketplaces, ya que influye en la visibilidad, el posicionamiento en los resultados de búsqueda y la capacidad de captar la atención de los compradores. Un nombre de producto claro, relevante y descriptivo puede aumentar las posibilidades de que los compradores encuentren y consideren el producto.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 20,
    title: 'Cantidad de productos',
    description:
      'Ambas plataformas, Amazon y Mercado Libre, ofrecen una amplia gama de productos en diferentes categorías y atienden a una base de usuarios global. La cantidad de productos disponibles en cada plataforma está sujeta a cambios y actualizaciones constantes debido a la adición y eliminación de productos por parte de los vendedores y la rotación del inventario.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 21,
    title: 'Condición del producto',
    description:
      'Se refiere al estado en el que se encuentra un producto específico en los marketplaces de Amazon y Mercado Libre. Indica si el producto es nuevo, usado o reacondicionado. Esta información es importante para que los compradores tengan claridad sobre el estado del producto que están adquiriendo.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 22,
    title: 'Landed Cost',
    description:
      'Se refiere al costo total de un producto, incluyendo no solo su precio de compra, sino también los costos adicionales asociados con su importación y entrega al destino final. Tanto en Amazon como en Mercado Libre, el concepto de Landed Cost es relevante para los vendedores que realizan operaciones de importación y exportación.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 23,
    title: 'Órdenes totales',
    description:
      'El número de órdenes totales es una métrica importante para medir la actividad y el volumen de transacciones en ambos marketplaces. Tanto Amazon como Mercado Libre utilizan esta información para evaluar el rendimiento y la demanda de los productos y para proporcionar datos y análisis a los vendedores.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 24,
    title: 'Estado de las órdenes',
    description:
      'Se refiere a la etapa o condición en la que se encuentra un pedido o transacción en cada plataforma. Proporciona información sobre el progreso y la situación actual de un pedido, permitiendo a los vendedores y compradores realizar un seguimiento de su estado y tomar las acciones correspondientes.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 25,
    title: 'Cantidad de productos',
    description:
      'La "cantidad de productos" en Amazon y Mercado libre se refiere al número total de productos que se enviaron ese mes',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 26,
    title: 'Gastos de Envio',
    description:
      'Los gastos de envío son importantes tanto para los compradores como para los vendedores, ya que afectan el precio final del producto y la decisión de compra. En ambas plataformas, es fundamental que los vendedores especifiquen claramente los gastos de envío asociados con sus productos para que los compradores tengan una idea precisa del costo total de la compra.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 27,
    title: 'Países de envio',
    description:
      'Es importante tener en cuenta que la disponibilidad de envío a ciertos países puede variar según el vendedor y el producto en particular. No todos los vendedores en Amazon o Mercado Libre envían a todos los países, y la disponibilidad de envío puede estar sujeta a cambios. Antes de realizar una compra, los compradores deben verificar la disponibilidad de envío a su país y revisar las políticas y costos de envío establecidos por el vendedor.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 28,
    title: 'Estados de Envio',
    description:
      'Se refieren a las etapas por las que pasa un pedido desde el momento en que se realiza hasta que es entregado al comprador.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 29,
    title: 'Cantidad de órdenes',
    description:
      'Los Ingresos Totales son una métrica importante tanto para los vendedores como para las plataformas de comercio electrónico. Ayuda a evaluar el desempeño de ventas, calcular las ganancias generadas y medir el volumen de transacciones en cada plataforma. Además, los Ingresos Totales también se utilizan para calcular las comisiones o tarifas que se deben pagar a las plataformas de Amazon y Mercado Libre en función del valor de las ventas realizadas.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 30,
    title: 'Ingresos Totales',
    description:
      'Los Ingresos Totales son una métrica importante tanto para los vendedores como para las plataformas de comercio electrónico. Ayuda a evaluar el desempeño de ventas, calcular las ganancias generadas y medir el volumen de transacciones en cada plataforma. Además, los Ingresos Totales también se utilizan para calcular las comisiones o tarifas que se deben pagar a las plataformas de Amazon y Mercado Libre en función del valor de las ventas realizadas.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 31,
    title: 'Total en descuentos',
    description:
      'Los descuentos son una estrategia común utilizada por los vendedores para atraer a los compradores, aumentar las ventas y promover la rotación de inventario. Tanto Amazon como Mercado Libre ofrecen diferentes tipos de descuentos y promociones a lo largo del tiempo, y el total en descuentos puede variar dependiendo de las ofertas disponibles en cada plataforma.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 32,
    title: 'Total en envios',
    description:
      'Los costos de envío pueden variar en función de varios factores, como el tamaño y peso del producto, la ubicación del comprador, el método de envío seleccionado y cualquier promoción especial de envío gratuito. Es importante que los compradores revisen y comprendan los costos de envío aplicables antes de realizar una compra en Amazon o Mercado Libre, ya que pueden afectar el precio total y la decisión de compra.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 33,
    title: 'Total en impuestos',
    description:
      'Los impuestos aplicables pueden diferir según el país y la región en la que se realice la compra. Los vendedores en Amazon y Mercado Libre deben cumplir con las regulaciones fiscales correspondientes y aplicar los impuestos adecuados a sus productos.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 34,
    title: 'Producto de la Orden',
    description:
      'El producto de la orden es importante tanto para los compradores como para los vendedores, ya que representa el artículo específico que se va a comprar y enviar. Los compradores deben revisar cuidadosamente los detalles del producto de la orden, como su descripción, características, precio y condiciones, antes de realizar la compra. Para los vendedores, es fundamental asegurarse de que el producto de la orden se corresponda con la publicación y cumplir con las expectativas del comprador en cuanto a calidad y entrega.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 35,
    title: 'Fecha de la Orden',
    description:
      'Tanto en Amazon como en MELI, la fecha de la orden puede ser relevante para el comprador y el vendedor, ya que influye en los plazos de entrega, las expectativas de recepción del producto y la planificación del envío. Es importante que los compradores estén atentos a la fecha de la orden y cualquier información relacionada con los tiempos de envío proporcionada por el vendedor o la plataforma. Esto los ayudará a tener una idea de cuándo pueden esperar recibir su pedido.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 36,
    title: 'Número de la orden',
    description:
      'En Amazon y Mercado Libre (MELI) es un identificador único asignado a cada pedido realizado por un comprador. En Amazon, el número de la orden es un código alfanumérico único que se genera para identificar un pedido específico. Este número de orden se utiliza para rastrear y administrar el pedido a lo largo de todo el proceso, desde la confirmación hasta la entrega. Tanto los compradores como los vendedores pueden hacer referencia al número de la orden para obtener información y seguimiento del pedido. En Mercado Libre (MELI), el número de la orden también es un identificador único asignado a cada pedido. Este número se genera cuando un comprador realiza una compra y se utiliza para identificar y rastrear el pedido en el sistema de MELI. Tanto los compradores como los vendedores pueden utilizar el número de la orden para realizar consultas, seguimiento y comunicación relacionada con el pedido.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 37,
    title: 'Cargos de la Orden',
    description:
      'Se refieren a los costos asociados con un pedido realizado por un comprador. Estos cargos pueden incluir diferentes elementos dependiendo de las políticas y condiciones de venta de cada plataforma.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 38,
    title: 'Envío de la Orden',
    description:
      'En ambos casos, el envío de la orden es un aspecto crucial para asegurar la entrega exitosa de los productos al comprador. Los compradores deben estar atentos a las opciones de envío disponibles, los tiempos estimados de entrega y los costos asociados para seleccionar la opción más conveniente. Los vendedores, por su parte, deben garantizar el embalaje adecuado y la coordinación de la entrega para cumplir con las expectativas del comprador.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 39,
    title: 'Descuento de la orden',
    description:
      'En Amazon y Mercado Libre se refiere a una reducción de precio aplicada a un pedido o producto específico durante el proceso de compra.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 40,
    title: 'Impuestos de la orden',
    description:
      'Son los cargos impositivos aplicados al precio de un pedido realizado por un comprador. Estos impuestos pueden incluir impuestos sobre las ventas, impuestos al valor agregado (IVA) u otros tipos de impuestos requeridos por las regulaciones fiscales correspondientes.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 41,
    title: 'Total de la orden',
    description:
      'El total de la orden es una cifra importante que los compradores deben revisar cuidadosamente antes de confirmar la compra. Les permite tener una idea clara del costo total de su pedido, incluyendo cualquier cargo adicional como los impuestos y los cargos de envío. Los vendedores, por su parte, deben asegurarse de que el total de la orden sea calculado correctamente y refleje los precios y cargos correspondientes al pedido.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 42,
    title: 'Cantidad del producto de la orden',
    description:
      'La "cantidad del producto de la orden" en Amazon y Mercado Libre se refiere a la cantidad de unidades de un producto específico incluido en un pedido realizado por un comprador.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 43,
    title: 'Cantidad de campañas activas',
    description:
      'Tanto en Amazon como en Mercado Libre, las campañas activas son una herramienta importante para que los vendedores aumenten la visibilidad de sus productos y generen más ventas. Los vendedores pueden administrar y ajustar sus campañas activas según sus necesidades y objetivos de promoción.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 44,
    title: 'Presupuesto diario',
    description:
      'El presupuesto diario es una herramienta útil para que los vendedores administren y controlen sus gastos en publicidad. Les permite establecer límites claros y ajustar su inversión publicitaria según sus objetivos y presupuesto.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 45,
    title: 'Estados de las campañas',
    description:
      'Los "estados de las campañas" en Amazon y Mercado Libre se refieren a los diferentes estados o condiciones en los que pueden encontrarse las campañas publicitarias en cada plataforma. En Amazon y MELI, las campañas publicitarias pueden tener los siguientes estados: Activa: La campaña está en curso y se muestra a los usuarios según los criterios de segmentación y las configuraciones establecidas. Pausada: La campaña se ha detenido temporalmente y no se muestra a los usuarios. Los vendedores pueden pausar una campaña si desean realizar ajustes o cambios. Terminada: La campaña ha finalizado según la fecha de finalización establecida o el presupuesto diario se ha agotado.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 46,
    title: 'Estrategia de las campañas',
    description:
      'Tanto en Amazon como en Mercado Libre, existen diferentes estrategias que los vendedores pueden utilizar en sus campañas publicitarias para promocionar sus productos y aumentar su visibilidad.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 47,
    title: 'ID de la campaña activa',
    description:
      'Es un identificador único asignado a cada campaña publicitaria que está en curso en cada plataforma. En Amazon, el ID de la campaña activa es un código alfanumérico único que identifica de manera única una campaña publicitaria en el sistema de gestión publicitaria de Amazon. Este ID se utiliza para realizar seguimiento y referencia de la campaña, y puede ser utilizado por los vendedores y anunciantes para gestionar y monitorear el desempeño de la campaña. En Mercado Libre, el ID de la campaña activa también es un código único asignado a cada campaña publicitaria en el sistema de publicidad. Este ID se utiliza para identificar y gestionar la campaña, y permite a los vendedores hacer seguimiento del rendimiento de sus anuncios y realizar ajustes según sea necesario. El ID de la campaña activa es importante para los vendedores y anunciantes, ya que les permite identificar y rastrear de manera precisa cada una de sus campañas publicitarias en las respectivas plataformas.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 48,
    title: 'Nombre de la campaña activa',
    description:
      'El nombre de la campaña activa es útil para que los vendedores y anunciantes identifiquen y organicen sus campañas publicitarias, especialmente cuando gestionan múltiples campañas a la vez. Ayuda a diferenciar y distinguir cada campaña según su objetivo o tema específico.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 49,
    title: 'Inicio de la campaña activa',
    description:
      'El "inicio de la campaña activa" en Amazon y Mercado Libre se refiere al momento en el que una campaña publicitaria comienza a mostrar anuncios o promociones en la plataforma respectiva.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 50,
    title: 'Presupuesto diario de la campaña activa',
    description:
      'El presupuesto diario de la campaña activa es una forma de controlar el gasto publicitario y garantizar que se mantenga dentro de los límites establecidos por el vendedor. Al establecer un presupuesto diario, los vendedores pueden administrar eficientemente sus recursos y ajustar su estrategia publicitaria según sea necesario.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 51,
    title: 'Estrategia de la campaña activa',
    description:
      'La elección de la estrategia de la campaña activa dependerá del objetivo del vendedor, el tipo de producto o anuncio, y las preferencias específicas de promoción. Estas estrategias se diseñan para optimizar la visibilidad y el rendimiento de los anuncios en cada plataforma, ayudando a aumentar la exposición y las ventas de los productos anunciados.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 52,
    title: 'ACOS',
    description:
      'ACOS (Advertising Cost of Sale), conocido como Costo de Venta Publicitaria en español, es una métrica utilizada en Amazon para medir la eficacia y rentabilidad de las campañas publicitarias.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 53,
    title: 'Cantidad de campañas',
    description:
      'La cantidad de campañas puede variar según las estrategias y necesidades de promoción de cada vendedor. Al tener múltiples campañas activas, los vendedores pueden promocionar diferentes productos, realizar pruebas A/B, segmentar audiencias específicas o adaptar la estrategia publicitaria según los resultados y objetivos comerciales.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 54,
    title: 'Clics',
    description:
      'Los clicks son una métrica importante en la publicidad en línea, ya que indican la cantidad de usuarios que han mostrado interés en un producto o anuncio al hacer clic en él. Los anunciantes y vendedores pueden utilizar esta métrica para evaluar la efectividad de sus campañas publicitarias y el interés generado en sus productos. Además, los clicks pueden conducir a acciones posteriores, como la compra de un producto o la navegación por el sitio, lo que puede influir en el rendimiento y éxito de una campaña publicitaria.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 55,
    title: 'Conversiones',
    description:
      'En Amazon y Mercado Libre se refiere al número de veces que un usuario completa una acción deseada después de hacer clic en un anuncio o enlace de un producto. Las "conversiones" representan la cantidad de veces que un usuario realiza una compra después de hacer clic en un anuncio. Por ejemplo, si un anuncio obtiene 100 clics y resulta en 10 compras, la tasa de conversión sería del 10%. Las conversiones pueden variar según los objetivos comerciales de los vendedores, como la venta de un producto, la suscripción a un servicio o cualquier otra acción específica que se considere como una conversión.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 56,
    title: 'Costo en publicidad',
    description:
      'El costo en publicidad es una métrica importante para los vendedores y anunciantes, ya que les permite evaluar la rentabilidad y el retorno de la inversión de sus campañas publicitarias. Un seguimiento adecuado del costo en publicidad les ayuda a gestionar y optimizar su presupuesto, ajustar las estrategias publicitarias y tomar decisiones informadas sobre la inversión en publicidad.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 57,
    title: 'CPC-Costo por clic',
    description:
      'CPC (Costo por Click) en Amazon y Mercado Libre (MELI) se refiere al costo que un vendedor o anunciante paga por cada clic que se realiza en su anuncio. El CPC es el monto que un vendedor está dispuesto a pagar cada vez que un usuario hace clic en su anuncio patrocinado. El costo por clic puede variar según diversos factores, como la competencia en las palabras clave, la relevancia del anuncio y la calidad de la campaña. Los anunciantes establecen un presupuesto diario y una oferta máxima por clic para determinar su CPC.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 58,
    title: 'Impresiones',
    description:
      'Las impresiones se cuentan cada vez que un anuncio se muestra en una página de resultados de búsqueda o en una página de detalle de producto. Por ejemplo, si un anuncio se muestra 100 veces a los usuarios, se registra un total de 100 impresiones. Las impresiones ayudan a los vendedores a evaluar la visibilidad y exposición de sus anuncios en la plataforma.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 59,
    title: 'TACoS',
    description:
      'TACoS (Total Advertising Cost of Sales) en Amazon y Mercado Libre es una métrica que representa el costo total de las ventas generadas a través de la publicidad en relación con los ingresos totales.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 60,
    title: 'Nombre de la campaña',
    description:
      'Se refiere al nombre o identificador único que se asigna a una campaña publicitaria específica. Es un nombre personalizado que el vendedor o anunciante elige para identificar y distinguir una campaña de otras dentro de su cuenta.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 61,
    title: 'Costo de la campaña',
    description:
      'El "Costo de la campaña" en Amazon y Mercado Libre se refiere al gasto total incurrido en una campaña publicitaria específica durante un período determinado.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 62,
    title: 'Cantidad de clics de la campaña',
    description:
      'La "Cantidad de clicks de la campaña" en Amazon y Mercado Libre se refiere al número total de veces que los anuncios de una campaña específica han sido clicados por los usuarios.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 63,
    title: 'Cantidad de impresiones de la campaña',
    description:
      'La "Cantidad de impresiones de la campaña" en Amazon y Mercado Libre se refiere al número total de veces que los anuncios de una campaña específica se han mostrado a los usuarios.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 64,
    title: 'ROAS de la campaña',
    description:
      'ROAS (Return on Advertising Spend) se refiere al retorno de la inversión publicitaria, es decir, la relación entre los ingresos generados y el gasto en publicidad. Tanto en Amazon como en Mercado Libre, el ROAS de una campaña publicitaria se calcula dividiendo los ingresos generados por la campaña entre el gasto en publicidad.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 65,
    title: 'ACoS de la campaña',
    description:
      'El ACoS (Advertising Cost of Sale) se refiere al costo total de publicidad en relación a las ventas generadas por una campaña. Tanto en Amazon como en Mercado Libre, el ACoS se calcula dividiendo el gasto en publicidad entre los ingresos generados por la campaña.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 66,
    title: 'CPC de la campaña',
    description:
      'El CPC (Costo por Clic) se refiere al costo promedio que se paga por cada clic en un anuncio de una campaña publicitaria. Tanto en Amazon como en Mercado Libre, el CPC de una campaña se calcula dividiendo el gasto en publicidad entre la cantidad total de clics generados por esa campaña.',
    category: '',
    categories: [],
    subtitle: '',
  },
  {
    id: 67,
    title: 'CTR de la campaña',
    description:
      'El CTR (Click-Through Rate) se refiere a la tasa de clics, es decir, la proporción de usuarios que hacen clic en un anuncio en relación con el número de veces que se muestra ese anuncio. Tanto en Amazon como en Mercado Libre, el CTR de una campaña se calcula dividiendo el número total de clics entre el número total de impresiones y luego multiplicándolo por 100 para obtener el porcentaje.',
    category: '',
    categories: [],
    subtitle: '',
  },
]
