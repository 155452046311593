import React, {FC} from 'react'
import FrankieAds from './FrankieAds'

type Props = {
  mkp: string | undefined
  marketingData: any
}
const FrankieAdsWrapper: FC<Props> = ({mkp, marketingData}) => {
  return (
    <>
      <FrankieAds marketingData={marketingData} />
      <br />
    </>
  )
}

export default FrankieAdsWrapper
