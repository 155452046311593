/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {AgText} from '../../layout/components/ag/AgText'
import {useIntl} from 'react-intl'
import CloseButton from 'react-bootstrap/CloseButton'
import Modal from 'react-bootstrap/Modal'
import styled from '@emotion/styled'
import { makeURL } from '../../helpers/misc'
import parse from 'html-react-parser'
type Props = {
  name: string
  asinId?: string
  imgUrl: string
  color?: string
  marketplaceId?: number
}

/**
 * Component to show a reference to the product in the Product Ranking table
 * @param name - string to add the name of the product
 * @param asinId - (optional)string to add the product code (if the product is from Amazon its the ASIN, if its from Meli is ID)
 * @param imgUrl - string to add the product image url (if the product is from Meli)
 * @param color - (optional) string to custom font color
 */

const ImgWrapper = styled('div')({
  minWidth: '2.8em',
  minHeight: '2.8em',
  maxWidth: '2.8em',
  maxHeight: '2.8em',
  overflow: 'auto',
  '::-webkit-scrollbar': {
    display: 'none',
  },
})

export const ShowProduct = ({
  showModal,
  handlerClouse,
  name,
  imgUrl,
}: {
  showModal: boolean
  handlerClouse: any
  name: string
  imgUrl: string
}) => {
  const intl = useIntl()
  return (
    <Modal show={showModal} dialogClassName='modal-dialog-centered'>
      <Modal.Header className={'justify-content-between px-10 py-5'}>
        <h4>{intl.formatMessage({id: 'DIALOG.PRODUCT'})}</h4>
        <CloseButton onClick={handlerClouse} />
      </Modal.Header>
      <Modal.Body className='px-10 py-5'>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '.5fr 1fr',
            gap: '1rem',
          }}
        >
          <div
            style={{
              width: '70%',
              display: 'grid',
              placeItems: 'center',
            }}
          >
            <img className='img-fluid' src={imgUrl} alt={name} />
          </div>
          <div>
            <p>{name}</p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const ProductWithImage: FC<Props> = ({name, asinId, imgUrl, color = ''}) => {
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false)

  const getTruncatedDescription = (name: string) => {
    const words = name.trim().split(/\s+/)
    return words.join(' ').substring(0, 10)
  }

  return (
    <div className={`d-flex justify-content-start align-items-start w-100 gap-4`}>
      <ImgWrapper>
        <img className='img-fluid' src={imgUrl} alt={name} />
      </ImgWrapper>

      <div style={{width: '78px'}}>
        <AgText
          customClass='text-gray-900 d-inline-block'
          choice={'small'}
          text={getTruncatedDescription(name)}
        >
          <a
            href={'#'}
            type='button'
            className='p-0 m-0 d-inline'
            onClick={() => setIsDescriptionOpen(true)}
          >
            ...
          </a>
        </AgText>
        <ShowProduct
          showModal={isDescriptionOpen}
          handlerClouse={() => setIsDescriptionOpen((pre) => !pre)}
          name={name}
          imgUrl={imgUrl}
        />
      </div>
    </div>
  )
}

export {ProductWithImage}

export const ProductWithImageDashboard: FC<Props> = ({name, asinId, imgUrl, color = '', marketplaceId}) => {
  const makeLink = (asin: any, marketplace: any) => {
    const link = makeURL(asin, marketplace)
    return link && parse(link)
  }
  
  const getTruncated = (name: string) => {
    const words = name.trim().split(/\s+/)
    return words.join(' ').substring(0, 25)
  }

  return (
    <div className={`d-flex justify-content-start align-items-start w-100 gap-4`}>
      <ImgWrapper>
        <img className='img-fluid' src={imgUrl} alt={name} />
      </ImgWrapper>
      <div style={{display: 'flex', flexFlow: 'column'}}>
        <AgText
          customClass='text-gray-900 d-inline-block'
          choice={'small'}
          text={getTruncated(name)}
        />
        {makeLink(asinId, marketplaceId)}
      </div>
    </div>
  )
}
