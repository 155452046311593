import React from 'react'
import { FontSizes } from '../../../../../app/constants/sizes'

type Props = {
  customClass?: string
  text: string
}
/**
 * Standard prototype of Text "AgLabelWithIcon" | renders a div element with a specific font size adn font weight, and can have a custom class and text provided as properties.
 * @param customClass - to add extra classes
 * @param text - the text content
 * @constructor
 */
const AgLabelWithIcon: React.FC<Props> = ({customClass, text}) => {
  /**
   * static css for the component
   */
  const css = {
    fontSize: FontSizes.MINI.SIZE_4, //'0.7em',
    fontWeight: 750,
  }

  return (
    <div className={`ff-prompt ${customClass}`} style={css}>
      {text}
    </div>
  )
}

export {AgLabelWithIcon}
