enum Sizes {
  MINI = 'MINI',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  XLARGE = 'XLARGE',
  SUPER_LARGE = 'SUPER_LARGE',
}

type FontSizesElementsType = {
  SIZE_1: string
  SIZE_2: string
  SIZE_3: string
  SIZE_4: string
  SIZE_5: string
}

type FrontSizesType = {
  [Sizes.MINI]: FontSizesElementsType
  [Sizes.SMALL]: FontSizesElementsType
  [Sizes.MEDIUM]: FontSizesElementsType
  [Sizes.LARGE]: FontSizesElementsType
  [Sizes.XLARGE]: FontSizesElementsType
  [Sizes.SUPER_LARGE]: FontSizesElementsType
}

export const FontSizes: FrontSizesType = {
  MINI: {
    SIZE_1: '0.49em', // 6px
    SIZE_2: '0.57em', // 7px
    SIZE_3: '0.65em', // 8px
    SIZE_4: '0.7em', // 9px
    SIZE_5: '0.81em', // 10px
  },
  SMALL: {
    SIZE_1: '0.89em', // 11px
    SIZE_2: '0.98em', // 12px
    SIZE_3: '1.06em', // 13px
    SIZE_4: '1.14em', // 14px
    SIZE_5: '1.22em', // 15px
  },
  MEDIUM: {
    SIZE_1: '1.30em', // 16px
    SIZE_2: '1.38em', // 17px
    SIZE_3: '1.46em', // 18px
    SIZE_4: '1.54em', // 19px
    SIZE_5: '1.63em', // 20px
  },
  LARGE: {
    SIZE_1: '1.71em', // 21px
    SIZE_2: '1.79em', // 22px
    SIZE_3: '1.87em', // 23px
    SIZE_4: '1.95em', // 24px
    SIZE_5: '2.03em', // 25px
  },
  XLARGE: {
    SIZE_1: '2.11em', // 26px
    SIZE_2: '2.19em', // 27px
    SIZE_3: '2.28em', // 28px
    SIZE_4: '2.36em', // 29px
    SIZE_5: '2.44em', // 30px
  },
  SUPER_LARGE: {
    SIZE_1: '3.74em', // 46px
    SIZE_2: '3.82em', // 47px
    SIZE_3: '3.90em', // 48px
    SIZE_4: '3.98em', // 49px
    SIZE_5: '4.06em', // 50px
  },
}
