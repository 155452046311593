import styled from '@emotion/styled'
import {Button} from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
export const Controls = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
})

export const Reset = styled(Button)({
  background: 'transparent !important',
})

export const Filters = styled('div')({
  display: 'flex',
  alignItems: "center",
  justifyContent: "center"
})

export const WrapperControls = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  margin: '20px 0px',
})

export const WrapperGrid = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: "1rem",
  margin: "20px 0px"
})


export const InputStyled = styled(Form.Control)()

export const LabelStyled = styled(Form.Label)()
