import styled from '@emotion/styled'
import {Button} from 'react-bootstrap'

// --------- Banner ---------------------//
export const BannerCard = styled('div')({
  borderRadius: '10px',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  height: '100%',
  background: '#E5F9FC',
  '@media (max-width: 1024px)': {
    gridTemplateColumns: '1fr',
  },
})

export const BannerBody = styled('div')({
  display: 'grid',
  placeItems: 'center',
  padding: '20px',
  h4: {
    color: '#212121',
    fontSize: '1.6rem',
  },
  p: {
    color: '#9E9E9E',
  },
})

export const BannerImg = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  img: {
    width: '340px',
    height: "auto"
  },
  '@media (max-width: 1024px)': {
    display: 'none',
  },
})

export const StyledButton = styled(Button)({
  background: '#0D7CBD !important',
  color: '#fff !important',
})

export const Revenue = styled('span')({
  color: '#212121',
  fontSize: '1.5rem',
  fontWeight: 'bold',
})

export const Year = styled('span')({
  borderRadius: '8px',
  fontWeight: 'bold',
  marginLeft: '10px',
  padding: '5px',
  background: '#fff',
  color: "#212121"
})
// --------- Banner ---------------------//

// --------- Brand health ---------------------//
type PropsIcon = {
  bg: string
}
type PropsLabel = {
  bg: string
  color: string
}
export const BrandHealCard = styled('div')({
  padding: '20px',
  height: '100%',
  borderRadius: '10px',
  boxShadow: '-1px 14px 19px -4px #919EAB33',
  h4: {
    marginTop: '10px',
    color: "#212121"
  },
})

export const BrandHealItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '10px 0px',
})

export const Divider = styled('div')({
  width: '100%',
  height: '1px',
  borderRadius: '20px',
  background: '#F5F5F5',
})


export const BrandHealWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

export const BrandHealIcon = styled('div')<PropsIcon>(({bg}) => ({
  width: '40px',
  height: '40px',
  display: 'grid',
  placeItems: 'center',
  borderRadius: '8px',
  background: bg,
  marginRight: '10px',
}))

export const BrandHealTitle = styled('div')({
  h6: {
    margin: '0px',
    color: '#212121',
  },
  p: {
    margin: '0px',
    color: '#9E9E9E',
  },
})

export const BrandHealLabel = styled('div')<PropsLabel>(({bg, color}) => ({
  padding: '2px 10px',
  borderRadius: '5px',
  background: bg,
  color: color,
  fontWeight: 'bold',
}))
// --------- Brand health ---------------------//

// --------- Sales ---------------------//
export const CardSales = styled('div')({
  boxShadow: '-1px 14px 19px -4px #919EAB33',
  height: '100%',
  borderRadius: "10px",
  padding: "20px"
})
// --------- Sales ---------------------//

// --------- Playlist indicator ---------------------//
type IndicatorProps = {
  bg: string
}
export const PlaylistlCard = styled('div')({
  padding: '20px',
  height: '100%',
  borderRadius: '10px',
  h4: {
    marginTop: '10px',
  },
  boxShadow: '-1px 14px 19px -4px #919EAB33',
})

export const PlaylistBody = styled('div')({
  width: '90%',
  minHeight: "400px",
  display: "flex",
  flexFlow: "column",
  justifyContent: "space-between",
  '@media (max-width: 1110px)': {
    width: '100%',
  },
  '@media (max-width: 500px)': {
    width: '100%',
  },
})

export const PlaylistItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '15px 0px',
  p: {
    margin: '0px',
    fontWeight: '400',
    color: '#212121'
  },
})

export const Indicator = styled('div')<IndicatorProps>(({bg}) => ({
  height: '20px',
  width: '20px',
  borderRadius: '50%',
  background: bg,
}))

export const PlayListButton = styled(Button)({
  background: '#0D7CBD !important',
  color: '#fff !important',
  width: '100%',
  marginTop: '20px',
})

// --------- Playlist indicator ---------------------//
