import React, {useState} from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import {KTSVG, toAbsoluteUrl} from '../../../../_gws/helpers'
import Form from 'react-bootstrap/Form'
import {Button} from 'react-bootstrap'
import {Field, ReferenceType} from '../types'
import {Line, WrapperColumn} from './styled'
import {IconOrigin, IconPriority, IconStatus, IconStore} from './PlayListIcons'
import {useIntl} from 'react-intl'

const CustomToggle = React.forwardRef(({children, onClick}: any, ref: any) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    }}
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
  >
    <KTSVG
      path={toAbsoluteUrl('media/icons/gws-icons/filter_list.svg')}
      className={`svg-icon-1x svg-icon-dark`}
    />
    {children}
  </div>
))

const RenderCheckboxs = ({handlerFilters, field, value, label}: InputParams) => {
  const intl = useIntl()
  return (
    <div style={{...flex, marginLeft: '10px'}}>
      <input
        onChange={() => handlerFilters(field, value)}
        style={{marginRight: '5px'}}
        type='checkbox'
      />
      <span>{intl.formatMessage({id: `PLAYLIST.${field.toUpperCase()}.OPTION.${value}`})}</span>
    </div>
  )
}
const flex = {
  display: 'flex',
  alignItems: 'center',
}

const styleMenu = {
  padding: '20px',
  width: '300px',
  background: '#fff',
  boxShadow: '1px 2px 4px 2px #0000001A',
}

export type StateFilters = {
  field: Field
  value: number | string
}

type Input = StateFilters & {
  label: string
}

type InputsType = {
  mkp: Input[]
  type: Input[]
  priority: Input[]
  status: Input[]
}

type InputParams = {
  handlerFilters: any
  field: Field
  value: number | string
  label: string
}

export const Filters = ({
  applyFilters,
  references,
}: {
  applyFilters: any
  references: ReferenceType[]
}) => {
  const intl = useIntl()
  const [filters, setFilters] = useState<StateFilters[]>([])

  const handlerFilters = (field: Field, value: any) => {
    const element = {field, value}
    const index = filters.findIndex(
      (item) => item.field === element.field && item.value === element.value
    )
    if (index === -1) {
      if (field === 'referenceId') {
        const update = filters.map((item: StateFilters) => {
          if (item.field === 'referenceId') {
            return {
              ...item,
              value: element.value,
            }
          }
          return item
        })
        if (filters.filter((item: StateFilters) => item.field === 'referenceId').length < 1) {
          update.push({field: field, value: value})

          setFilters(() => [...update])
        } else {
          setFilters(() => [...update])
        }
        return
      }
      setFilters((pre) => [...pre, {field: field, value: value}])
    } else {
      const remove = [...filters]
      delete remove[index]
      setFilters(() => remove.filter((item) => item !== undefined))
    }
  }

  const handlerApplyFilters = () => {
    let filtersFormat: StateFilters[] = []
    if (filters.length > 0) {
      const index = filters.findIndex((item) => item.field === 'referenceId' && item.value === 0)
      if (index !== -1) {
        filtersFormat = filters.filter((item: StateFilters) => item.field !== 'referenceId')
      } else {
        filtersFormat = [...filters]
      }
    }
    applyFilters({
      filters: filtersFormat,
    })
  }

  const Inputs: InputsType = {
    mkp: [
      {
        field: 'marketplaceId',
        value: 1,
        label: 'Amazon',
      },
      {
        field: 'marketplaceId',
        value: 2,
        label: 'Mercado Libre',
      },
    ],
    type: [
      {
        field: 'type',
        value: 'CUSTOM',
        label: 'Custom',
      },
      {
        field: 'type',
        value: 'OUTPUT',
        label: 'Output',
      },
    ],
    priority: [
      {
        field: 'priority',
        value: 1,
        label: 'Alto',
      },
      {
        field: 'priority',
        value: 2,
        label: 'Medio',
      },
      {
        field: 'priority',
        value: 3,
        label: 'Bajo',
      },
    ],
    status: [
      {
        field: 'status',
        value: 1,
        label: 'Sin iniciar',
      },
      {
        field: 'status',
        value: 2,
        label: 'En proceso',
      },
      {
        field: 'status',
        value: 3,
        label: 'Finalizado',
      },
    ],
  }

  const Columns = {
    top: [
      {
        icons: <IconStore />,
        title: 'Marketplace',
        filters: Inputs.mkp,
      },
      {
        icons: <IconOrigin />,
        title: 'Origen',
        filters: Inputs.type,
      },
    ],
    down: [
      {
        icons: <IconPriority />,
        title: 'Prioridad',
        filters: Inputs.priority,
      },
      {
        icons: <IconStatus />,
        title: 'Estatus',
        filters: Inputs.status,
      },
    ],
  }
  const options = [...references]
  options.push({
    flag: false,
    label: 'Todos',
    value: 0,
  })
  return (
    <Dropdown
      style={{
        margin: '0px 20px',
      }}
    >
      <Dropdown.Toggle as={CustomToggle}>
        {intl.formatMessage({id: 'PLAYLIST.FILTER'})}
      </Dropdown.Toggle>

      <Dropdown.Menu style={{...styleMenu}}>
        <div>
          <h4>{intl.formatMessage({id: 'PLAYLIST.FILTER'})}</h4>
          <Form.Select
            onChange={(e: any) => handlerFilters('referenceId', parseInt(e.target.value))}
            size='sm'
          >
            {options.map((item: ReferenceType) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </Form.Select>
        </div>

        <WrapperColumn>
          {Columns.top.map((item, i) => (
            <div key={i}>
              <div style={{...flex}}>
                {item.icons}
                <h6 style={{marginTop: '5px', marginLeft: '5px'}}>{item.title}</h6>
              </div>
              {item.filters.map((item, i) => (
                <RenderCheckboxs
                  key={i}
                  value={item.value}
                  label={item.label}
                  field={item.field}
                  handlerFilters={handlerFilters}
                />
              ))}
            </div>
          ))}
        </WrapperColumn>
        <Line />

        <WrapperColumn>
          {Columns.down.map((item, i) => (
            <div key={i}>
              <div style={{...flex}}>
                {item.icons}
                <h6 style={{marginTop: '5px', marginLeft: '5px'}}>{item.title}</h6>
              </div>
              {item.filters.map((item, i) => (
                <RenderCheckboxs
                  key={i}
                  value={item.value}
                  label={item.label}
                  field={item.field}
                  handlerFilters={handlerFilters}
                />
              ))}
            </div>
          ))}
        </WrapperColumn>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button size='sm' onClick={handlerApplyFilters}>
            {intl.formatMessage({id: 'PLAYLIST.FILTER.APPLY'})}
          </Button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}
