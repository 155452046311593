import React from 'react'
import BhCardActions from './components/BHCardActions'
import {Link} from 'react-router-dom'
import {brandHealthStateList} from '../../../_gws/helpers/miscConst'
import {useIntl} from 'react-intl'
import {ProgressIndicator} from '../../../_gws/partials/widgets/statistics/ProgressWidget'
import styled from '@emotion/styled'
import {IconInventory} from '../../../_gws/layout/components/aside/icons/IconInventory'
import {IconPlaylist} from '../../../_gws/layout/components/aside/icons/IconPlaylist'
import {IconActiveCampaigns} from '../../../_gws/layout/components/aside/icons/IconActiveCampaigns'

type Props = {
  objectCategory: any
  mkp: string
}

const WrapperIndicators = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
  gap: '1rem',
})

const BrandHealth: React.FC<Props> = ({objectCategory, mkp}) => {
  const intl = useIntl()
  let healthDiagnostic = brandHealthStateList.find(
    (state) => state.id === objectCategory.brand_health.general_status.health_status
  )
  if (healthDiagnostic === undefined) {
    healthDiagnostic = brandHealthStateList[0]
  }

  /**
   * Create an array of object, where each object has {id, name, and all the data from the REST }
   */
  let indicatorArray = Object.entries(objectCategory.brand_health)
    .filter((arr) => arr[0] !== 'general_status')
    .map((arr) => {
      const category: any = arr[1]
      category.indicators = category.indicators.filter(
        (indicator: any) => indicator.count > 0 && indicator.health_status < 3
      )
      return {
        id: arr[0].split('_')[1],
        name: intl.formatMessage({id: `BRAND_HEALTH.${arr[0].toUpperCase()}`}),
        ...category,
      }
    })
  const IndicatorsGauge: any = {
    Stock: {
      icon: <IconInventory color='#2F2F2F' size={40} />,
      color: '#F9D7DD',
    },
    Listings: {
      icon: <IconPlaylist color='#2F2F2F' size={40} />,
      color: '#D5EEF4',
    },
    Publicidad: {
      icon: <IconActiveCampaigns color='#2F2F2F' size={40} />,
      color: '#FCF2E9',
    },
  }

  let gaugeArray = indicatorArray.reduce((acc, idx) => {
    return [
      ...acc,
      {
        id: idx.id,
        name: idx.name,
        healthy: idx.healthy,
        unhealthy: idx.unhealthy,
        percentage: idx.percentage,
        icon: IndicatorsGauge[idx.name]?.icon,
        color: IndicatorsGauge[idx.name]?.color,
      },
    ]
  }, [])
  return (
    <>
      <WrapperIndicators>
        {gaugeArray.map((item: any) => (
          <div key={item.id}>
            <Link to={`/brand-health/${mkp}/category_${item.id}`} className={'text-dark'}>
              <ProgressIndicator
                indicator={item}
                expected={item.healthy + item.unhealthy}
                lastUpdate={objectCategory.created_at}
              />
            </Link>
          </div>
        ))}
      </WrapperIndicators>

      <div className='row mt-5'>
        <div className='col-md-12'>
          <div className='row thirdStep'>
            {indicatorArray.map((item: any) => (
              <div className='col-md-4' key={item.id}>
                <BhCardActions
                  metricId={item.id}
                  listAction={item.indicators}
                  indicatorName={item.name}
                  mkp={mkp}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
export {BrandHealth}
