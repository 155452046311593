import styled from '@emotion/styled'
import {IconAmazon, IconMeli} from '../../playlist/components/PlayListIcons'
import {useIntl} from 'react-intl'
import {brandHealthStateList} from '../../../../_gws/helpers/miscConst'
import {Button} from 'react-bootstrap'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import {addDays, setHours, setMinutes} from 'date-fns'
import {TYPESTATUS, TYPETASK} from '../../../constants/task'
import {createPlayListTask} from '../../playlist/core/_requests'
import {AlertFetchError} from '../../../../_gws/helpers/misc'
import { Divider } from '../../brandHealth/components/styled'

type PropsIcon = {
  bg?: any
}

const CardContainer = styled('div')({
  padding: '20px',
  borderRadius: '10px',
  boxShadow: '-1px 14px 19px -4px #919EAB33',
})

const SendPlaylistButton = styled(Button)({
  background: '#0D7CBD !important',
  color: '#fff !important',
  width: '100%',
})

const Container = styled('div')({
  overflow: 'auto',
  height: '365px',
  width: '100%',
  marginBottom: '20px',
})

const WrapperIcon = styled('div')<PropsIcon>(({bg}) => ({
  display: 'grid',
  placeItems: 'center',
  padding: '10px',
  borderRadius: '10px',
  background: bg ? bg : 'transparent',
  marginRight: '20px',
}))

const Title = styled('div')({
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
  p: {
    margin: '0px',
  },
  h4: {
    margin: '0px',
    fontSize: '1rem',
  },
})

const WrapperItem = styled('div')({
  display: 'flex',
  margin: '15px 0px',
  borderRadius: '10px',
  transition: 'all ease .3s',
})

type ItemList = {
  health_status: number
  indicator: string
  item_type_id: number
  marketplace_id: number
  id: string
  metricId: string
}

type Metric = {
  current_rating: string
  expected_rating: string
  health_status: string
  indicator: string
  item_type_id: string
  marketplace_id: string
  id: string
  metricId: string
  description: string
}

const IndicatorItem = ({item}: {item: ItemList}) => {
  const intl = useIntl()
  const formatTitle = intl.formatMessage({
    id: `BRAND_HEALTH.INDICATOR.TYPE.NAME.${item.item_type_id}`,
  })
  const title =
    formatTitle === 'Without code name' ? `Without key: ${item.item_type_id}` : formatTitle
  let currentState = brandHealthStateList.find((state) => state.id === Number(item.health_status))
  return (
    <WrapperItem>
      <WrapperIcon bg={currentState?.color}>
        {item.marketplace_id === 1 ? (
          <IconAmazon width='50px' height='35px' color='#2A3547' />
        ) : (
          <IconMeli width='50px' height='35px' color='#2A3547' />
        )}
      </WrapperIcon>
      <Title>
        <h4>{title}</h4>
        <p>
          {intl.formatMessage({
            id: `${currentState?.label}`,
          })}
        </p>
      </Title>
    </WrapperItem>
  )
}

const IndicatorTable = ({listAction}: {listAction: ItemList[]}) => {
  return (
    <>
      <Container>
        {listAction.map((item, i: number) => (
          <>
            <IndicatorItem key={i} item={item} />
            <Divider />
          </>
        ))}
      </Container>
    </>
  )
}

export const BrandHealthIndicators = ({metrics}: {metrics: Metric[]}) => {
  const intl = useIntl()
  const data: ItemList[] = metrics.map((item: Metric) => ({
    ...item,
    id: item.id.replace(/"/g, ''),
    indicator: item.indicator.replace(/"/g, ''),
    item_type_id: parseInt(item.item_type_id),
    marketplace_id: parseInt(item.marketplace_id),
    health_status: parseInt(item.health_status),
  }))

  const Alert = withReactContent(Swal)
  const deadlineDays: Date = addDays(new Date(), 2)
  const definedHours: Date = setMinutes(setHours(deadlineDays, 23), 59)

  const sendAllToPlaylist = async (allItems: ItemList[]) => {
    const formatTask = allItems.map((item: any) => ({
      title: item.indicator,
      description: item.description.replace(/"/g, ''),
      priority: item.health_status,
      deadline: definedHours,
      status: TYPESTATUS.NotStarted,
      type: TYPETASK.OUTPUT,
      referenceId: item.item_type_id,
      marketplaceId: item.marketplace_id,
      item: item.id,
    }))
    const response: any = await createPlayListTask(formatTask)
    if (response.status !== 200) {
      AlertFetchError(intl.locale, intl.formatMessage({id: 'PLAYLIST.ERROR.MESSAGE'})).fire()
      return
    }
    Alert.fire({
      text: intl.formatMessage({id: 'PLAYLIST.SUCCESS.MESSAGE.BRANDHEALTH'}),
      icon: 'success',
      iconColor: '#00C6C7;',
      buttonsStyling: false,
      confirmButtonText: `${intl.formatMessage({
        id: 'USER_MNGT.MODAL.BUTTON.ACCEPT',
      })}`,
      customClass: {
        confirmButton: 'btn btn-primary',
        popup: 'bg-light',
      },
    })
  }

  return (
    <CardContainer>
      <h4>Playlist</h4>
      <IndicatorTable listAction={data} />
      <SendPlaylistButton size='sm' onClick={() => sendAllToPlaylist(data)}>
        {intl.formatMessage({id: 'BRAND_HEALTH.TABLE.ADD.ALL.PLAYLIST'})}
      </SendPlaylistButton>
    </CardContainer>
  )
}
