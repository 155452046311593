import {FC} from 'react'
import {useIntl} from 'react-intl'
import {BasicButton} from '../../../../_gws/partials/buttons'
import {IndicatorTable} from './IndicatorTable'
import styled from '@emotion/styled'
import {Button} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'

type Prop = {
  listAction: any
  indicatorName: string
  metricId: number
  handleClick?: any
  mkp?: string
}

const Card = styled('div')({
  boxShadow: '-1px 14px 19px -4px #919EAB33',
  borderRadius: '8px',
  padding: '15px 0px',
  h4: {
    color: '#2A3547',
    fontWeight: 'bold',
    fontSize: '1.3rem',
  },
  p: {
    color: '#7C8FAC',
  },
})

const StyledButton = styled(Button)({
  background: '#0D7CBD !important',
  color: '#fff !important',
  width: '100%',
  marginTop: '20px',
})

const BhCardActions: FC<Prop> = ({listAction, indicatorName, metricId, handleClick, mkp}) => {
  const navigate = useNavigate()
  const intl = useIntl()

  listAction = listAction.map((item: any) => {
    let ms = Date.now() + Math.random()
    return {
      ...item,
      id: `${ms}-${item.indicator}`,
      metricId: metricId,
    }
  })

  return (
    <Card>
      <div className='p-4 mb-3' style={{overflowY: 'scroll'}}>
        <h4>{indicatorName}</h4>
        <p>
          {intl.formatMessage({
            id: `BRAND_HEALTH.INDICATOR_DESCRIPTION.${indicatorName.toUpperCase()}`,
          })}
        </p>

        <IndicatorTable listAction={listAction} />

        <StyledButton
          onClick={() => navigate(`/brand-health/${mkp}/category_${metricId}`)}
          size='sm'
        >
          {intl.formatMessage({
            id: `BRAND_HEALTH.INDICATOR.ACTION`,
          })}
        </StyledButton>
        {handleClick && (
          <div className='row'>
            <div className='col d-flex justify-content-end'>
              <BasicButton
                bgColor={'primary'}
                clickFunction={(e) => handleClick(e)}
                text={intl.formatMessage({id: 'BRAND_HEALTH.TABLE.ADD.ALL.PLAYLIST'})}
              />
            </div>
          </div>
        )}
      </div>
    </Card>
  )
}

export default BhCardActions
