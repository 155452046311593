import React from 'react'
import {HealthAside} from './groupAsideSaas/HealthAside'
import {InventoryAside} from './groupAsideSaas/InventoryAside'
import {SalesAside} from './groupAsideSaas/SalesAside'
import {MarketingAside} from './groupAsideSaas/MarketingAside'
import {SettingsAside} from './groupAsideSaas/SettingsAside'
import {HomeAside} from './groupAsideSaas/HomeAside'

const ProAside = () => {
  return (
    <>
      <HomeAside />
      <HealthAside />
      <InventoryAside />
      <SalesAside />
      <MarketingAside />
      <SettingsAside />
    </>
  )
}

export {ProAside}
