import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {TableClientWrapper} from './merchant/TableClientWrapper'
import {ResultsCard} from '../../../../_gws/partials'
import {QueryResponseProvider} from '../../../modules/apps/user-management/users-list/core/QueryResponseProvider'
import {ListViewProvider} from '../../../modules/apps/user-management/users-list/core/ListViewProvider'
import {QueryRequestProvider} from '../../../modules/apps/user-management/users-list/core/QueryRequestProvider'

type Props = {
  list: any
}

/**
 * Merchant Dashboard
 * @param list - Clients list
 * @component
 */
const DashboardMerchant: FC<Props> = ({list}) => {
  const intl = useIntl()
  return (
    <>
      <div className='row g-5 g-xl-8 mt-5'>
        <h3 className='ms-1'>
          {intl.formatMessage({id: 'DASHBOARD.MERCHANT.CLIENTS.COUNT.TITLE'})}&nbsp;
        </h3>
        <div className='col-md-3 col-lg-4 col-xl-3 mt-1'>
          <ResultsCard
            className={'card-xl-stretch'}
            color={'card'}
            titleColor={'dark'}
            descriptionColor={'dark'}
            title={list.length}
            description={intl.formatMessage({id: 'DASHBOARD.MERCHANT.CLIENTS.COUNT'})}
            height={'130px'}
            countUp={true}
            decimal={false}
          />
        </div>
      </div>

      <div className='row g-5 g-xl-8 mt-3'>
        <div className='col'>
          {/*// QueryRequestProvider, QueryResponseProvider, ListViewProvider: used to fire the selected user in the actions options on the table*/}
          <QueryRequestProvider>
            <QueryResponseProvider>
              <ListViewProvider>
                <TableClientWrapper list={list} />
              </ListViewProvider>
            </QueryResponseProvider>
          </QueryRequestProvider>
        </div>
      </div>
    </>
  )
}

export {DashboardMerchant}
