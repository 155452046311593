import axios from 'axios'
import {BusinessInfoModel, ProfileRequestModel} from './_models'
import { getTokenHeader } from './AuthHelpers'
import { MemberstackFunctions } from '../../../../solidInterfaces/membestack'

const API_URL_CRUNCH = process.env.REACT_APP_BACKEND_MAIN_API

export async function login(user: string, pass: string) {  
  const {data} = await MemberstackFunctions.login(user, pass)
  const token = data.tokens.accessToken
  const userData = await getUserData(token)
  userData.data.token = token
  return userData
}

export async function getUserData(token: string) {
  return await axios.get(`${API_URL_CRUNCH}/api/auth/get-user-data`, {headers: {'crunch-dna-token': token}})
}

export async function signUp(user: string, pass: string) {
  const {data} = await MemberstackFunctions.signUp(user, pass)
  const token = data.tokens.accessToken
  return await signUpMemberstack(token)
}

export async function signUpMemberstack(token: string) {
  return await axios.post(`${API_URL_CRUNCH}/api/auth/sign-up-memberstack`, {}, {headers: {'crunch-dna-token': token}})
}

export const RECOVERY_URL = `${API_URL_CRUNCH}/api/users/reset/password`
export function recovery(email: string) {
  return axios.post(RECOVERY_URL, {
    email: email,
  })
}

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL_CRUNCH}/api/profile`
export function getUserByToken() {
  return axios
    .get<ProfileRequestModel>(GET_USER_BY_ACCESSTOKEN_URL, {})
    .then((response) => {
      return response.data.detail
    })
    .then((result: any) => {
      return {data: result}
    })
}

export function getUserByManualToken(clientAuth: any) {
  const cleanAxios = axios.create()
  return cleanAxios
    .get<ProfileRequestModel>(GET_USER_BY_ACCESSTOKEN_URL, {
      headers: {'crunch-dna-token': clientAuth.token},
    })
    .then((response) => {
      return response.data.detail
    })
    .then((result: any) => {
      return {data: result}
    })
}

export function getProducts(clientAuth: any) {
  const axiosClean = axios.create()
  return axiosClean.get(`${API_URL_CRUNCH}/payments/products`, {
    headers: {'crunch-dna-token': clientAuth.token},
  })
}

export function getCheckoutSession({email, product}: {email: string; product: string}) {
  const axiosClean = axios.create()
  return axiosClean.post(`${API_URL_CRUNCH}/payments/checkout-session/`, {
    email,
    product,
  })
}
export const GET_USER_RECAPTCHA_SCORE = `${API_URL_CRUNCH}/api/auth/validate-recaptcha`
export function getReCaptchaScore(token: any) {
  const cleanAxios = axios.create()
  return cleanAxios.post(GET_USER_RECAPTCHA_SCORE, {filter: token})
}
export function setBusinessProfile(data: BusinessInfoModel) {
  const axiosClean = axios.create()
  return axiosClean.post(`${API_URL_CRUNCH}/api/auth/business_info`, data)
}

export function linkBusinessProfile(id: string) {
  const axiosClean = axios.create()
  return axiosClean.post(`${API_URL_CRUNCH}/api/auth/associate_user_business_info`, 

    {businessInfoId: id}, 
    // @ts-ignore
    getTokenHeader())
}