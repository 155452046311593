import React from 'react'
import {KTSVG} from '../../../helpers'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'

const VerificationBadge = () => {
  const intl = useIntl()
  const tooltip = intl.formatMessage({id: 'GENERAL.VERIFIED'})
  return (
    <OverlayTrigger
      placement='top'
      overlay={<Tooltip id={`tooltip-top`}>{tooltip}</Tooltip>}
      defaultShow={false}
    >
      <span
        className='symbol-badge badge badge-circle top-100 start-100'
        style={{
          width: '15px',
          height: '15px',
          border: '1px solid white',
          marginTop: '-45px',
          marginLeft: '-7px',
        }}
      >
        <KTSVG
          path='/media/icons/unicos/065-check.svg'
          className='svg-icon-2'
          svgClassName={'text-primary'}
        />
      </span>
    </OverlayTrigger>
  )
}

export {VerificationBadge}
