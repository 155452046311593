import {
  Months,
  PriorityType,
  ReferenceType,
  StatusType,
  priorityPlaylist,
  statusPlaylist,
} from '../../types'
import {IconAmazon, IconCalendar, IconMeli} from '../PlayListIcons'
import Form from 'react-bootstrap/Form'
import {Group} from './styled'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {forwardRef} from 'react'
import {useIntl} from 'react-intl'
import {TYPETASK} from '../../../../constants/task'
import styled from '@emotion/styled'
import {getMonth} from 'date-fns'

const priorityKeys: any = {
  1: 'CRITIQUE',
  2: 'DELICATE',
  3: 'GOOD',
  0: 'OPTIMAL',
}

type MarketplaceInput = {
  disabled: boolean
  handlerMkp: any
  value: number
  checked: boolean
  icon: any
}

const flex = {
  display: 'flex',
  alignItems: 'center',
}

const InputStyled = styled(Form.Control)({
  background: 'transparent',
  ':focus': {
    background: 'transparent',
  },
})

const LabelStyled = styled(Form.Label)({
  color: '#212121B0',
})

export const CalendarBtn = styled('button')({
  border: '1px solid #2121211A',
  display: 'flex',
  padding: '6px',
  cursor: 'pointer',
  borderRadius: '5px',
  background: 'transparent',
  width: '100%',
})

export const statusOptions: any = [
  {
    label: 'Sin iniciar',
    color: '#F6F6F6',
    value: 1,
  },
  {
    label: 'En proceso',
    color: '#A5CAF1',
    value: 2,
  },
  {
    label: 'Finalizado',
    color: '#92E07E',
    value: 3,
  },
]

export const priorityOptions: any = [
  {
    label: 'Neutral',
    color: '#F6F6F6',
    value: 0,
  },
  {
    label: 'Alto',
    color: '#F9D7DD',
    value: 1,
  },
  {
    label: 'Medio',
    color: '#F9E6D3',
    value: 2,
  },
  {
    label: 'Bajo',
    color: '#F9D7DD',
    value: 3,
  },
]

export const RenderSelectMkp = ({task, setState}: {task: any; setState: any}) => {
  const handlerMkp = (e: any) => {
    setState((pre: any) => ({
      ...pre,
      marketplaceId: parseInt(e.target.value),
    }))
  }

  const inputs: MarketplaceInput[] = [
    {
      disabled: task.type === TYPETASK.OUTPUT,
      handlerMkp: handlerMkp,
      value: 1,
      checked: task.marketplaceId === 1,
      icon: <IconAmazon width='40px' />,
    },
    {
      disabled: task.type === TYPETASK.OUTPUT,
      handlerMkp: handlerMkp,
      value: 2,
      checked: task.marketplaceId === 2,
      icon: <IconMeli width='40px' />,
    },
  ]

  return (
    <div style={{...flex, justifyContent: 'flex-end'}}>
      <div style={{...flex}}>
        {inputs.map((item, i) => (
          <div key={i} style={{...flex, marginRight: '15px'}}>
            {item.icon}
            <input
              disabled={item.disabled}
              onChange={item.handlerMkp}
              value={item.value}
              checked={item.checked}
              style={{marginLeft: '5px'}}
              type='radio'
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export const RenderTittleInput = ({handlerTask, task}: {handlerTask: any; task: any}) => {
  return (
    <>
      <LabelStyled htmlFor='title'>Task</LabelStyled>
      <InputStyled
        onChange={(e: any) => handlerTask('title', e)}
        size='sm'
        type='text'
        id='title'
        aria-describedby='title'
        value={task.title}
      />
    </>
  )
}

export const RenderItemInput = ({
  handlerTask,
  task,
  disabled,
}: {
  handlerTask: any
  task: any
  disabled: boolean
}) => {
  return (
    <>
      <LabelStyled htmlFor='title'>Código del producto</LabelStyled>
      <InputStyled
        onChange={(e: any) => handlerTask('item', e)}
        size='sm'
        type='text'
        id='title'
        aria-describedby='title'
        value={task.item}
        disabled={disabled}
      />
    </>
  )
}

export const RenderDescriptionInput = ({
  handlerTask,
  task,
  disabled,
}: {
  handlerTask: any
  task: any
  disabled: boolean
}) => {
  const intl = useIntl()
  const priority = task.priority as PriorityType
  const formatPrefix = intl.formatMessage({
    id: `PLAYLIST.TABLE.DESCRIPTION.TYPE_${task.referenceId}.PRIO_${task.priority}.MKP_${task.marketplaceId}.PREFIX.${priorityKeys[priority]}`,
  })
  const formatSuffix = intl.formatMessage({
    id: `PLAYLIST.TABLE.DESCRIPTION.TYPE_${task.referenceId}.PRIO_${task.priority}.MKP_${task.marketplaceId}.SUFFIX.${priorityKeys[priority]}`,
  })
  const prefix = formatPrefix === 'N/A' ? `N/A -> type ${task.referenceId} - priority ${task.priority}` : formatPrefix
  const suffix = formatSuffix === 'N/A' ? '' : formatSuffix

  const TEMPLATE = `${prefix}${suffix}`
  return (
    <>
      <LabelStyled htmlFor='description'>Descripción</LabelStyled>
      <InputStyled
        value={TEMPLATE}
        disabled={disabled}
        onChange={(e: any) => handlerTask('description', e)}
        as='textarea'
      />
    </>
  )
}

export const RenderNotesInput = ({handlerTask, task}: {handlerTask: any; task: any}) => {
  return (
    <>
      <LabelStyled htmlFor='notes'>Notas</LabelStyled>
      <InputStyled
        value={task.notes}
        onChange={(e: any) => handlerTask('notes', e)}
        as='textarea'
      />
    </>
  )
}

export const RenderReferenceInput = ({
  handlerTask,
  task,
  disabled,
  references,
}: {
  handlerTask: any
  task: any
  disabled: boolean
  references: ReferenceType[]
}) => {
  const options = [...references]
  options.push({
    flag: false,
    label: 'Sin referencia',
    value: 0,
  })
  return (
    <>
      <style type='text/css'>
        {`
        .form-select {
          background-color: transparent;
        }
    `}
      </style>
      <LabelStyled htmlFor='reference'>Referencia</LabelStyled>
      <Form.Select
        bsPrefix='form-select'
        onChange={(e: any) => handlerTask('referenceId', e)}
        value={task.referenceId}
        disabled={disabled}
        size='sm'
      >
        {options.map((item: ReferenceType) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </Form.Select>
    </>
  )
}

const CustomInputRange = forwardRef(({value, onClick}: any, ref: any) => {
  const [startDate, endDate] = value.split(' - ')
  const month = Months[getMonth(endDate) + 1] ? Months[getMonth(endDate) + 1] : '.'
  const start = startDate.split('/')[1] ? startDate.split('/')[1] : '.'
  const end = endDate.split('/')[1] ? endDate.split('/')[1] : '.'
  return (
    <CalendarBtn onClick={onClick} ref={ref}>
      {`${start}-${month}-${end}`}
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconCalendar />
      </span>
    </CalendarBtn>
  )
})

const CustomOnlyDeadline = forwardRef(({value, onClick, start}: any, ref: any) => {
  const startDate = new Date(start)
    .toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .split('/')[1]
  const month = Months[getMonth(value) + 1] ? Months[getMonth(value) + 1] : '.'
  const end = value.split('/')[1] ? value.split('/')[1] : '.'
  return (
    <CalendarBtn onClick={onClick} ref={ref}>
      {`${startDate}-${month}-${end}`}
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconCalendar />
      </span>
    </CalendarBtn>
  )
})

export const RenderDates = ({
  startDate,
  endDate,
  setDateRange,
  edit,
}: {
  endDate: Date
  startDate: Date
  setDateRange: any
  edit?: boolean
}) => {
  return (
    <div
      style={{
        width: '85%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        alignItems: 'center',
      }}
    >
      <LabelStyled htmlFor='status'>Fecha limite</LabelStyled>
      {!edit ? (
        <DatePicker
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={(update) => {
            setDateRange(update)
          }}
          customInput={<CustomInputRange />}
        />
      ) : (
        <DatePicker
          selected={endDate}
          onChange={(date) => setDateRange(date)}
          placeholderText='Select a date other than today or yesterday'
          customInput={<CustomOnlyDeadline start={startDate} />}
        />
      )}
    </div>
  )
}

export const RenderPriority = ({
  handlerTask,
  disabled,
  priority,
}: {
  handlerTask: any
  disabled: boolean
  priority: PriorityType
}) => {
  const intl = useIntl()
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '10px 0px',
          width: '85%',
        }}
      >
        <LabelStyled htmlFor='priority'>Prioridad</LabelStyled>
        <Form.Select
          style={{background: priorityPlaylist[priority].color, width: '50%'}}
          value={priority}
          disabled={disabled}
          onChange={(e: any) => handlerTask('priority', e)}
          size='sm'
        >
          {priorityOptions.map((item: any) => (
            <option key={item.value} value={item.value}>
              {intl.formatMessage({id: `PLAYLIST.PRIORITY.OPTION.${item.value}`})}
            </option>
          ))}
        </Form.Select>
      </div>
    </>
  )
}

export const RenderStatus = ({
  handlerTask,
  disabled,
  status,
  edit,
}: {
  handlerTask: any
  disabled: boolean
  status: StatusType
  edit?: boolean
}) => {
  const intl = useIntl()
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '85%',
        }}
      >
        <LabelStyled htmlFor='status'>Estatus</LabelStyled>
        <Form.Select
          style={{background: statusPlaylist[status].color, width: '50%'}}
          value={status}
          onChange={(e: any) => handlerTask('status', e)}
          size='sm'
        >
          {statusOptions.map((item: any) => (
            <option disabled={!edit && item.value === 3} key={item.value} value={item.value}>
              {intl.formatMessage({id: `PLAYLIST.STATUS.OPTION.${item.value}`})}
            </option>
          ))}
        </Form.Select>
      </div>
    </>
  )
}

export const RenderForm = ({
  task,
  setTask,
  handlerTask,
  references,
  disabled,
  startDate,
  endDate,
  setDateRange,
  edit,
}: {
  task: any
  setTask: any
  handlerTask: any
  references: ReferenceType[]
  disabled: boolean
  startDate: Date
  endDate: Date
  setDateRange: any
  edit?: boolean
}) => {
  return (
    <>
      <Group>
        <RenderSelectMkp task={task} setState={setTask} />
        <RenderTittleInput task={task} handlerTask={handlerTask} />
      </Group>
      <Group>
        <RenderDescriptionInput disabled={disabled} task={task} handlerTask={handlerTask} />
      </Group>
      <Group>
        <RenderItemInput disabled={disabled} task={task} handlerTask={handlerTask} />
      </Group>
      <Group>
        <RenderNotesInput task={task} handlerTask={handlerTask} />
      </Group>
      <Group>
        <RenderReferenceInput
          references={references}
          disabled={disabled}
          task={task}
          handlerTask={handlerTask}
        />
      </Group>
      <RenderPriority disabled={disabled} handlerTask={handlerTask} priority={task.priority} />
      <RenderStatus
        edit={edit}
        disabled={disabled}
        handlerTask={handlerTask}
        status={task.status}
      />
      <Group>
        <RenderDates
          startDate={startDate}
          setDateRange={setDateRange}
          endDate={endDate}
          edit={edit}
        />
      </Group>
    </>
  )
}
