import React, {FC} from 'react'
import {AgText} from '../../layout/components/ag/AgText'
import {GwsLineChart} from '../newComponets/charts/GWSLineChart'
import {ObjectColors, ObjectValues} from '../newComponets/charts/components/WrapperLineChart'

type Props = {
  title?: string
  subtitle?: string
  value?: string
  dataLabels: string[] | number[]
  dataColors: ObjectColors[]
  dataValues: ObjectValues[]
  chartTitleYAxis?: string
  chartTitleY1Axis?: string
  chartTitleXAxis?: string
  hasLegend?: boolean
  cleanChart?: boolean
  canSeeTooltip?: boolean
  isShort?: boolean
  fullHeigth?: boolean
}

/**
 * Card Line chart component with gradient and possibility to have 2 Y axis
 * @param title - Title of the card
 * @param subtitle - Subtitle of the card
 * @param value
 * @param dataLabels - Labels of the X axis
 * @param dataColors - Colors of the lines this should have the gradientEnd, gradientStart and borderColor
 * @param dataValues - Values of the lines, this array of object has some the type of ObjectValues {
 *   axis?: string : if this is defined, the value will be displayed in the second Y axis
 *   label: string : Label of the line
 *   values: string[] : Values of the line
 * }
 * @param chartTitleYAxis - Title of the Y axis
 * @param chartTitleY1Axis - Title of the Y1 axis
 * @param chartTitleXAxis - Title of the X axis
 * @param hasLegend - If true, the legend will be displayed
 * @param cleanChart - If true, the grid and axis will be displayed
 * @param canSeeTooltip
 * @param isShort
 * @param fullHeigth
 * @example:
 * <GwsLineChart
 *  dataLabels={['Enero', 'Febrero', 'Marzo', 'Abril']}
 *  dataColors={[
 *    {
 *      borderColor: 'rgba(80,187,204,1)',
 *      gradientEnd: 'rgba(198,241,250,0.40)',
 *      gradientStart: 'rgba(253,254,254,0.50)',
 *    },
 *  ]}
 *  dataValues={[
 *    {label: 'Clicks', values: ['37', '195', '81', '120']},
 *  ]}
 *  />
 */
const CardLineChart: FC<Props> = ({
  title,
  subtitle,
  value,
  dataLabels,
  dataColors,
  dataValues,
  chartTitleXAxis,
  chartTitleYAxis,
  chartTitleY1Axis,
  hasLegend,
  cleanChart,
  canSeeTooltip,
  isShort,
  fullHeigth,
}) => {
  return (
    <div className={`card rounded-2 ${fullHeigth && 'h-100'}`}>
      <div className={`card-body ${!cleanChart ? 'p-4' : 'p-0 pt-4'}`}>
        <div className={`row p-3 pt-0 ${isShort && 'position-absolute z-index-3'}`}>

          {title && 
                    <AgText choice={'titlecard'} text={title} customClass={'ps-4'} />}

          {subtitle && 
                    (
            <AgText choice={'percentagecard'} text={subtitle} customClass={'text-gray-500 ps-4'} />
                    )}
          {' '}

          {value && 
            <AgText choice={'biggernumbercard'} text={value} customClass={'text-dark ps-4'} />}
        
        </div>
        <div className='row mt-3 d-flex justify-content-center p-0 m-0'>
          <GwsLineChart
            dataLabels={dataLabels}
            dataColors={dataColors}
            dataValues={dataValues}
            chartTitleYAxis={chartTitleYAxis}
            chartTitleXAxis={chartTitleXAxis}
            chartTitleY1Axis={chartTitleY1Axis}
            hasLegend={hasLegend}
            cleanChart={cleanChart}
            canSeeTooltip={canSeeTooltip}
          />
        </div>
      </div>
    </div>
  )
}

export {CardLineChart}
