import React, {useEffect, useState, FC} from 'react'

type Props = {
  backgroundColor: string
  visualParts: [
    {
      percentage: string
      color: string
    }
  ]
}
/**
 * This Component create a Dinamic Progress component with number and text
 *
 * @param backgroundColor - background base Color for the progress section. (variable sass)
 * @param color - color for text-component (variable sass)
 * @param visualParts - array with percentage and color (variable sass) for progress active section
 */

const ProgressComponent: FC<Props> = ({
  backgroundColor,
  // expected format for visual parts
  visualParts = [
    {
      percentage: '0%',
      color: 'white',
    },
  ],
}) => {
  const [widths, setWidths] = useState(visualParts.map((item) => '0'))

  useEffect(() => {
    const timerId = setTimeout(() => {
      setWidths(visualParts.map((item) => item.percentage))
    }, 0)

    return () => clearTimeout(timerId)
  }, [visualParts])

  return (
    <div
      className={`d-flex progress w-100`}
      style={{
        height: '.5rem',
        background: `${backgroundColor}47`,
      }}
    >
      {visualParts.map((item, index) => {
        return (
          <div
            className={`progress-bar`}
            key={index}
            style={{
              width: widths[index],
              transition: 'width 2s',
              background: item.color
            }}
          />
        )
      })}
    </div>
  )
}

export {ProgressComponent}
