import {KTSVG} from '../../../helpers'
import {toAbsoluteUrl} from '../../../helpers'
import { LabelWithIndicatorText } from './LabelWithIndicatorText'

type Props = {
  text: string
  backgroundColor?: string
  color?: string
  icon?: string
  svgColor?: string
}
/**
 * This Component create a Label with indicator
 *
 * @param text - string with the text
 * @param backgroundColor - background Color for the component. (variable sass)
 * @param color - color for text-component (variable-sass)
 * @param icon - string(icon referent) for the component
 * @param svgColor - string with color svg icon (variable sass)
 */

const LabelWithIndicator: React.FC<Props> = ({text, color, backgroundColor, icon, svgColor}) => {
  const labelStyles = {
    height: '1em',
    border: 'none',
    borderRadius: '0.125em',
  }

  let isUp = Number(text) > 0
  let iconPath = icon ? icon : `/media/icons/gws-icons/${isUp ? 'Arrow10' : 'Arrow10-decrec'}.svg`
  let iconColor = svgColor ? svgColor : isUp ? 'success' : 'danger'
  return (
    <div
      className={`d-flex justify-content-between align-items-center text-uppercase `}
      style={labelStyles}
    >
      <LabelWithIndicatorText
        customClass={`bg-light-${
          backgroundColor ? backgroundColor : iconColor
        } text-${iconColor}-dark px-3 border-4 me-1`}
        text={text + '%'}
      />
      <KTSVG path={toAbsoluteUrl(iconPath)} className={`svg-icon-10 svg-icon-${iconColor} mb-1`} />
    </div>
  )
}

export {LabelWithIndicator}
