import {useIntl} from 'react-intl'
import {ButtonPay, PaymentContainer} from './styled'
import {useEffect, useState} from 'react'
import {getCheckoutSession, setBusinessProfile} from '../core/_requests'
import {Col, Form, FormControl, InputGroup, Row, Spinner} from 'react-bootstrap'
import {useLocation, useNavigate} from 'react-router-dom'
import {SubmitHandler, useForm} from 'react-hook-form'
import styled from '@emotion/styled'

const LogoStripe = () => {
  return (
    <svg width='100px' id='Layer_1' data-name='Layer 1' viewBox='0 0 150 34'>
      <title>Powered by Stripe - black</title>
      <path d='M146,0H3.73A3.73,3.73,0,0,0,0,3.73V30.27A3.73,3.73,0,0,0,3.73,34H146a4,4,0,0,0,4-4V4A4,4,0,0,0,146,0Zm3,30a3,3,0,0,1-3,3H3.73A2.74,2.74,0,0,1,1,30.27V3.73A2.74,2.74,0,0,1,3.73,1H146a3,3,0,0,1,3,3Z' />
      <path d='M17.07,11.24h-4.3V22h1.92V17.84h2.38c2.4,0,3.9-1.16,3.9-3.3S19.47,11.24,17.07,11.24Zm-.1,5H14.69v-3.3H17c1.38,0,2.11.59,2.11,1.65S18.35,16.19,17,16.19Z' />
      <path d='M25.1,14a3.77,3.77,0,0,0-3.8,4.09,3.81,3.81,0,1,0,7.59,0A3.76,3.76,0,0,0,25.1,14Zm0,6.67c-1.22,0-2-1-2-2.58s.76-2.58,2-2.58,2,1,2,2.58S26.31,20.66,25.1,20.66Z' />
      <polygon points='36.78 19.35 35.37 14.13 33.89 14.13 32.49 19.35 31.07 14.13 29.22 14.13 31.59 22.01 33.15 22.01 34.59 16.85 36.03 22.01 37.59 22.01 39.96 14.13 38.18 14.13 36.78 19.35' />
      <path d='M44,14a3.83,3.83,0,0,0-3.75,4.09,3.79,3.79,0,0,0,3.83,4.09A3.47,3.47,0,0,0,47.49,20L46,19.38a1.78,1.78,0,0,1-1.83,1.26A2.12,2.12,0,0,1,42,18.47h5.52v-.6C47.54,15.71,46.32,14,44,14Zm-1.93,3.13A1.92,1.92,0,0,1,44,15.5a1.56,1.56,0,0,1,1.69,1.62Z' />
      <path d='M50.69,15.3V14.13h-1.8V22h1.8V17.87a1.89,1.89,0,0,1,2-2,4.68,4.68,0,0,1,.66,0v-1.8c-.14,0-.3,0-.51,0A2.29,2.29,0,0,0,50.69,15.3Z' />
      <path d='M57.48,14a3.83,3.83,0,0,0-3.75,4.09,3.79,3.79,0,0,0,3.83,4.09A3.47,3.47,0,0,0,60.93,20l-1.54-.59a1.78,1.78,0,0,1-1.83,1.26,2.12,2.12,0,0,1-2.1-2.17H61v-.6C61,15.71,59.76,14,57.48,14Zm-1.93,3.13a1.92,1.92,0,0,1,1.92-1.62,1.56,1.56,0,0,1,1.69,1.62Z' />
      <path d='M67.56,15a2.85,2.85,0,0,0-2.26-1c-2.21,0-3.47,1.85-3.47,4.09s1.26,4.09,3.47,4.09a2.82,2.82,0,0,0,2.26-1V22h1.8V11.24h-1.8Zm0,3.35a2,2,0,0,1-2,2.28c-1.31,0-2-1-2-2.52s.7-2.52,2-2.52c1.11,0,2,.81,2,2.29Z' />
      <path d='M79.31,14A2.88,2.88,0,0,0,77,15V11.24h-1.8V22H77v-.83a2.86,2.86,0,0,0,2.27,1c2.2,0,3.46-1.86,3.46-4.09S81.51,14,79.31,14ZM79,20.6a2,2,0,0,1-2-2.28v-.47c0-1.48.84-2.29,2-2.29,1.3,0,2,1,2,2.52S80.25,20.6,79,20.6Z' />
      <path d='M86.93,19.66,85,14.13H83.1L86,21.72l-.3.74a1,1,0,0,1-1.14.79,4.12,4.12,0,0,1-.6,0v1.51a4.62,4.62,0,0,0,.73.05,2.67,2.67,0,0,0,2.78-2l3.24-8.62H88.82Z' />
      <path d='M125,12.43a3,3,0,0,0-2.13.87l-.14-.69h-2.39V25.53l2.72-.59V21.81a3,3,0,0,0,1.93.7c1.94,0,3.72-1.59,3.72-5.11C128.71,14.18,126.91,12.43,125,12.43Zm-.65,7.63a1.61,1.61,0,0,1-1.28-.52l0-4.11a1.64,1.64,0,0,1,1.3-.55c1,0,1.68,1.13,1.68,2.58S125.36,20.06,124.35,20.06Z' />
      <path d='M133.73,12.43c-2.62,0-4.21,2.26-4.21,5.11,0,3.37,1.88,5.08,4.56,5.08a6.12,6.12,0,0,0,3-.73V19.64a5.79,5.79,0,0,1-2.7.62c-1.08,0-2-.39-2.14-1.7h5.38c0-.15,0-.74,0-1C137.71,14.69,136.35,12.43,133.73,12.43Zm-1.47,4.07c0-1.26.77-1.79,1.45-1.79s1.4.53,1.4,1.79Z' />
      <path d='M113,13.36l-.17-.82h-2.32v9.71h2.68V15.67a1.87,1.87,0,0,1,2.05-.58V12.54A1.8,1.8,0,0,0,113,13.36Z' />
      <path d='M99.46,15.46c0-.44.36-.61.93-.61a5.9,5.9,0,0,1,2.7.72V12.94a7,7,0,0,0-2.7-.51c-2.21,0-3.68,1.18-3.68,3.16,0,3.1,4.14,2.6,4.14,3.93,0,.52-.44.69-1,.69a6.78,6.78,0,0,1-3-.9V22a7.38,7.38,0,0,0,3,.64c2.26,0,3.82-1.15,3.82-3.16C103.62,16.12,99.46,16.72,99.46,15.46Z' />
      <path d='M107.28,10.24l-2.65.58v8.93a2.77,2.77,0,0,0,2.82,2.87,4.16,4.16,0,0,0,1.91-.37V20c-.35.15-2.06.66-2.06-1V15h2.06V12.66h-2.06Z' />
      <polygon points='116.25 11.7 118.98 11.13 118.98 8.97 116.25 9.54 116.25 11.7' />
      <rect x='116.25' y='12.61' width='2.73' height='9.64' />
    </svg>
  )
}

const StyledFormControl = styled(FormControl)({
  border: '1px solid #DBDFE9',
  background: 'transparent',
  borderRadius: '8px',
  color: '#DBDFE9',
})

const StyledSelectControl = styled(Form.Select)({
  border: '1px solid #DBDFE9',
  background: 'transparent',
  borderRadius: '8px',
  appearance: 'none',
  paddingRight: '30px',
  backgroundImage: `url("data:image/svg+xml,%3Csvg version='1.1' id='Capa1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M506.157,132.386c-7.803-7.819-20.465-7.831-28.285-0.029l-207.73,207.299c-7.799,7.798-20.486,7.797-28.299-0.015 L34.128,132.357c-7.819-7.803-20.481-7.79-28.285,0.029c-7.802,7.819-7.789,20.482,0.029,28.284l207.701,207.27 c11.701,11.699,27.066,17.547,42.433,17.547c15.358,0,30.719-5.846,42.405-17.533L506.128,160.67 C513.946,152.868,513.959,140.205,506.157,132.386z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'calc(100% - 10px) center',
  backgroundSize: '12px',
})

interface IFormInput {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  companyName: string
  website: string
  marketplaces: string
  salesRange: string
}

export const BusinessInfo: React.FC = () => {
  const navigate = useNavigate()
  const {search} = useLocation()
  const [email, setEmail] = useState('')
  const intl = useIntl()
  const marketplaceOptions = [
    {value: 'Amazon', label: intl.formatMessage({id: 'businessInfo.marketplace.amazon'})},
    {
      value: 'Mercado Libre',
      label: intl.formatMessage({id: 'businessInfo.marketplace.mercadoLibre'}),
    },
    {
      value: 'Amazon,Mercado Libre',
      label: intl.formatMessage({id: 'businessInfo.marketplace.both'}),
    },
  ]
  const salesRangeOptions = [
    {value: '$0 - $100K', label: intl.formatMessage({id: 'businessInfo.salesRange.under100k'})},
    {value: '$100k - $300k', label: intl.formatMessage({id: 'businessInfo.salesRange.100kTo300k'})},
    {value: '$300k - $500k', label: intl.formatMessage({id: 'businessInfo.salesRange.300kTo500k'})},
    {value: 'Más de $500k', label: intl.formatMessage({id: 'businessInfo.salesRange.over500k'})},
  ]

  const [products, setProducts] = useState({
    products: [],
    isLoading: false,
  })

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<IFormInput>({
    defaultValues: {
      email: email,
      marketplaces: '',
      salesRange: '',
    },
  })
  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    const marketplaces = data.marketplaces.split(',').map((marketplace) => ({marketplace}))
    const businessInfo = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phoneNumber,
      companyName: data.companyName,
      website: data.website,
      salesRange: data.salesRange,
      marketplaces: marketplaces,
    }
    setBusinessProfile(businessInfo).then((response) => {
      const businessId = response.data.id
      localStorage.setItem('businessId', businessId)
      handlerCheckoutSession()
    })
  }

  const handlerCheckoutSession = async () => {
    setProducts((prev) => ({
      ...prev,
      isLoading: true,
    }))

    const response = await getCheckoutSession({
      email: email,
      product: process.env.REACT_APP_PRODUCT_NITRO || '',
    })

    if (response.status !== 201) return

    window.open(response.data.checkout_url, '_self')
  }

  useEffect(() => {
    const email = new URLSearchParams(search).get('email')
    if (!email) return navigate('/auth')
    setEmail(email)
    localStorage.setItem('temporal', email)
  }, [search, navigate])

  return (
    <PaymentContainer>
      <h4 style={{fontSize: '1.6rem'}}>{intl.formatMessage({id: 'LOGIN.BUSINESS.INFO.TITLE'})}</h4>
      <p style={{marginTop: '5px'}}>{intl.formatMessage({id: 'LOGIN.BUSINESS.INFO.TEXT'})}</p>
      <Form style={{marginTop: '30px'}} noValidate onSubmit={handleSubmit(onSubmit)}>
        <Row className='mb-3'>
          <Form.Group as={Col} controlId='formGridFirstName'>
            <StyledFormControl
              type='text'
              placeholder={intl.formatMessage({id: 'businessInfo.placeholder.firstName'})}
              {...register('firstName', {
                required: intl.formatMessage({id: 'businessInfo.error.firstName'}),
              })}
              isInvalid={!!errors.firstName}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.firstName?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId='formGridLastName'>
            <StyledFormControl
              type='text'
              placeholder={intl.formatMessage({id: 'businessInfo.placeholder.lastName'})}
              {...register('lastName', {
                required: intl.formatMessage({id: 'businessInfo.error.lastName'}),
              })}
              isInvalid={!!errors.lastName}
            />
            <Form.Control.Feedback type='invalid'>{errors.lastName?.message}</Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Form.Group className='mb-3' controlId='formGridEmail'>
          <StyledFormControl
            type='email'
            placeholder={intl.formatMessage({id: 'businessInfo.placeholder.email'})}
            {...register('email', {
              required: intl.formatMessage({id: 'businessInfo.error.emailRequired'}),
              pattern: {
                value: /^\S+@\S+\.\S+$/,
                message: intl.formatMessage({id: 'businessInfo.error.emailInvalid'}),
              },
            })}
            isInvalid={!!errors.email}
          />
          <Form.Control.Feedback type='invalid'>{errors.email?.message}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className='mb-3' controlId='formGridPhoneNumber'>
          <InputGroup>
            <InputGroup.Text>+52</InputGroup.Text>
            <StyledFormControl
              type='text'
              placeholder={intl.formatMessage({id: 'businessInfo.placeholder.phoneNumber'})}
              {...register('phoneNumber', {
                required: intl.formatMessage({id: 'businessInfo.error.phoneNumberRequired'}),
                pattern: {
                  value: /^[0-9]*$/,
                  message: intl.formatMessage({id: 'businessInfo.error.phoneNumberInvalid'}),
                },
              })}
              isInvalid={!!errors.phoneNumber}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.phoneNumber?.message}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group className='mb-3' controlId='formGridCompanyName'>
          <StyledFormControl
            type='text'
            placeholder={intl.formatMessage({id: 'businessInfo.placeholder.companyName'})}
            {...register('companyName', {
              required: intl.formatMessage({id: 'businessInfo.error.companyNameRequired'}),
            })}
            isInvalid={!!errors.companyName}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.companyName?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className='mb-3' controlId='formGridWebsite'>
          <StyledFormControl
            type='text'
            placeholder={intl.formatMessage({id: 'businessInfo.placeholder.website'})}
            {...register('website', {
              required: intl.formatMessage({id: 'businessInfo.error.websiteRequired'}),
            })}
            isInvalid={!!errors.website}
          />
          <Form.Control.Feedback type='invalid'>{errors.website?.message}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className='mb-3' controlId='formGridMarketplaces'>
          <StyledSelectControl
            {...register('marketplaces', {
              required: intl.formatMessage({id: 'businessInfo.error.marketplacesRequired'}),
            })}
            isInvalid={!!errors.marketplaces}
          >
            <option value='' disabled>
              {intl.formatMessage({id: 'businessInfo.placeholder.marketplaces'})}
            </option>
            {marketplaceOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </StyledSelectControl>
          <Form.Control.Feedback type='invalid'>
            {errors.marketplaces?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className='mb-3' controlId='formGridSalesRange'>
          <StyledSelectControl
            {...register('salesRange', {
              required: intl.formatMessage({id: 'businessInfo.error.salesRangeRequired'}),
            })}
            isInvalid={!!errors.salesRange}
          >
            <option value='' disabled>
              {intl.formatMessage({id: 'businessInfo.title.salesRange'})}
            </option>
            {salesRangeOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </StyledSelectControl>
          <Form.Control.Feedback type='invalid'>{errors.salesRange?.message}</Form.Control.Feedback>
        </Form.Group>

        <div className='text-end'>
          {' '}
          {/* Alinea el contenido a la derecha */}
          <ButtonPay type='submit'>
            {products.isLoading ? (
              <Spinner size='sm' animation='border' role='status'>
                <span className='visually-hidden'>
                  {intl.formatMessage({id: 'businessInfo.loading'})}
                </span>
              </Spinner>
            ) : (
              intl.formatMessage({id: 'businessInfo.payment.stripeButton'})
            )}
          </ButtonPay>
          <div className='mt-2'>
            {' '}
            {/* Margen superior para separar el logo del botón */}
            <LogoStripe />
          </div>
        </div>
      </Form>
      <div
        style={{
          margin: '20px 0px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      ></div>
    </PaymentContainer>
  )
}
