import React from 'react'
import {useIntl} from 'react-intl'
import {TabPaneProductTracker} from './TabPaneProductTracker'

type PropsPeriodSales = {
  dataReport?: any
}

const PeriodSales: React.FC<PropsPeriodSales> = ({dataReport}) => {
  const intl = useIntl()

  /**
   * Filters for tab period graph
   */
  let periodList = [
    {
      id: 1,
      item: 'monthly',
      active: 'active',
    },
    {
      id: 2,
      item: 'bimonthly',
      active: '',
    },
    {
      id: 3,
      item: 'quarterly',
      active: '',
    },
    {
      id: 4,
      item: 'biannual',
      active: '',
    },
  ]

  return (
    <>
      <div className='col-md-12'>
        <ul className='nav nav-pills' id='pills-tab' role='tablist'>
          {periodList.map((list) => {
            return (
              <li className='nav-item m-0' role='presentation' key={list.id}>
                <a
                  className={`${list.active} btn btn-light-primary ms-1 fs-7 px-3 py-3`}
                  id={`#pills-tab-${list.id}`.trim()}
                  data-bs-toggle='pill'
                  data-bs-target={`#tab-${list.id}`.trim()}
                  href={'/#'}
                  aria-selected='true'
                  role='tab'
                >
                  <span className=''>
                    {intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.' + list.item.toUpperCase()})}
                  </span>
                </a>
              </li>
            )
          })}
        </ul>
      </div>
      <div className='col-md-12 mb-5 mt-4 tab-content'>
        <div className='tab-pane show active' id='tab-1' role='tabpanel'>
          <TabPaneProductTracker dataReport={dataReport.monthly}></TabPaneProductTracker>
        </div>
        <div className='tab-pane' id='tab-2' role='tabpanel'>
          <TabPaneProductTracker dataReport={dataReport.bimonthly}></TabPaneProductTracker>
        </div>
        <div className='tab-pane' id='tab-3' role='tabpanel'>
          <TabPaneProductTracker dataReport={dataReport.quarterly}></TabPaneProductTracker>
        </div>
        <div className='tab-pane' id='tab-4' role='tabpanel'>
          <TabPaneProductTracker dataReport={dataReport.biannual}></TabPaneProductTracker>
        </div>
      </div>
    </>
  )
}

export {PeriodSales}
