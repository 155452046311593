import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_gws/layout/core'
import {WaitingScreen} from '../../../_gws/partials/content/waiting/WaitingScreen'
import useSWR from 'swr'
import {useEffect, useState} from 'react'
import {useAuth} from '../../modules/auth'
import {HeaderReport} from '../../modules/apps/header/HeaderReport'
import {DateRange, SalesReport} from './core/types'
import {getSalesData} from './core/getSalesData'
import {ReportSales} from './SalesReport'
import {Splash} from '../../../_gws/helpers/misc'
import {BannerHelp} from '../../modules/apps/BannerHelp/BannerHelp'
import {FilterReport} from '../paidMarketingReport/components/FilterReport'
import { FilterWrapper } from './components/styled'

const actualDate = new Date()
const last30Days = new Date()
actualDate.setDate(actualDate.getDate() - 1)
last30Days.setDate(last30Days.getDate() - 30)
const end = actualDate.toISOString().split('T')[0]
const start = last30Days.toISOString().split('T')[0]

const SalesWrapper = () => {
  const intl = useIntl()
  const {currentMkp, setCurrentMkp} = useAuth()
  const [period, setPeriod] = useState(1)
  const hasBeenVerified = localStorage.getItem('verified') === 'true'
  const date: DateRange = {start: start, end: end}

  const swrOptions = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: false,
  }
  const {data: sales, isLoading: isLoadingSales} = useSWR(
    () => (currentMkp ? ['/reports/sales/', currentMkp, period] : null),
    () => getSalesData({date, marketplace: currentMkp, periodType: period}),
    {
      ...swrOptions,
    }
  ) as {
    data: SalesReport
    error: any
    isLoading: boolean
  }

  useEffect(() => {
    Splash(isLoadingSales)
  }, [isLoadingSales])

  const handlerSetPeriod = (period: number) => setPeriod(period)

  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: intl.formatMessage({id: 'MENU.HOME'}),
            path: '/dashboard',
            isSeparator: false,
            isActive: false,
          },
          {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.REPORTS.SALES'})}
      </PageTitle>
      <BannerHelp linkToDoc='https://docs.growker.ai/kb/ventas/' title='HELP.TITLE.SALES' text='HELP.TEXT.SALES' />
      <FilterWrapper>
        <HeaderReport
          hasMarketPlace={true}
          hasDate={false}
          hasFilter={false}
          growkerMetric={true}
          isMonth={false}
          setMkplace={setCurrentMkp}
        />
        {sales && <FilterReport handlerPeriodFilter={handlerSetPeriod} />}
      </FilterWrapper>

      {hasBeenVerified ? (
        <>{sales && <ReportSales currentMkp={currentMkp} sales={sales} />}</>
      ) : (
        <WaitingScreen />
      )}
    </>
  )
}

export {SalesWrapper}
