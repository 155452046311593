import {useAuth} from '../../modules/auth'
import {useEffect} from 'react'
import {Splash} from '../../../_gws/helpers/misc'
import {HeaderReport} from '../../modules/apps/header/HeaderReport'
import useSWR from 'swr'
import {getSalesStrategyData} from './core/getSalesStrategyData'
import {SalesReportStrategy} from './SalesReportStrategy'
import {Button} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

const SalesStrategyReport = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const Alert = withReactContent(Swal)
  const {currentUser, currentClient, currentMkp, setCurrentMkp} = useAuth()
  const clientData: any = currentClient !== undefined ? currentClient : currentUser // //clientData is any because it has many optional properties

  const hasSalesGoals =
    clientData?.goals?.sales_amazon > 0 || clientData.goals.sales_mercado_libre > 0
  const hasProfitGoals =
    clientData?.goals?.profit_amazon > 0 || clientData?.goals?.profit_mercado_libre > 0

  const {data, isLoading} = useSWR(
    () => (currentMkp ? ['/reports/strategy/', currentMkp] : null),
    () =>
      getSalesStrategyData({
        marketplace: currentMkp,
        periodReportType: 1,
        clientData: clientData,
      }),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    }
  )
  useEffect(() => {
    Splash(isLoading)
  }, [isLoading])

  useEffect(() => {
    if (!hasSalesGoals && !hasProfitGoals) {
      Alert.fire({
        title: intl.formatMessage({id: 'ERROR.NOT.SALESGOALS.TITLE'}),
        text: intl.formatMessage({id: 'ERROR.NOT.SALESGOALS.DESC'}),
        icon: 'info',
        iconColor: '#00c6c7',
        showConfirmButton: false,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <HeaderReport
        hasMarketPlace={true}
        hasDate={false}
        hasFilter={false}
        setMkplace={setCurrentMkp}
      />
      <div>
        {data && hasSalesGoals && hasProfitGoals && <SalesReportStrategy report={data} />}
        {!hasSalesGoals && !hasProfitGoals && (
          <Button size='sm' onClick={() => navigate('/goals')}>
            {intl.formatMessage({id: 'ERROR.GO.TO.CONF_GOALS'})}
          </Button>
        )}
      </div>
    </>
  )
}

export {SalesStrategyReport}
