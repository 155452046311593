import {Dispatch, SetStateAction} from 'react'

export type LandedCost = {
  id: number | undefined
  asin: number
  marketplaceId?: number
  cost: string
  createdAt: string
  updatedAt: string
  usersId?: number
  marketplace?: {id: number; description: string} | undefined
}

export type ID = undefined | null | number

export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
}

export type Response<T> = {
  data?: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
  }
}

export type QueryState = SortState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export const initialQueryResponse = {
  refetch: () => {},
  isLoading: false,
  query: '',
  error: undefined
}

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>

  itemIdForUpdateNew?: ID
  setItemIdForUpdateNew: Dispatch<SetStateAction<ID>>

  itemUpdated?: any
  setItemUpdated: Dispatch<SetStateAction<any>>
  isAllSelected: boolean
  disabled: boolean
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},
  setItemIdForUpdateNew: () => {},
  setItemUpdated: () => {},
  isAllSelected: false,
  disabled: false,
}

interface Product {
  asin: string;
  name: string;
  condition: string;
  description: string;
  image: string;
  landed_cost: number;
  marketplace: number;
  product_health: string;
  profit: number;
  sold?: number; // Usando '?' para indicar que la propiedad puede ser undefined
  stock: number;
  top: number;
  units: number;
  ads_cost?: number; // Propiedades opcionales, asumiendo que pueden no estar presentes
  forecast?: any; // Tipo 'any' usado por falta de detalle, idealmente debería ser más específico
  sales?: any; // Igual que 'forecast', ser específico si es posible
}

export interface marketPlaceData {
  amountProducts: number;
  amountUniqueProducts: number;
  products: Product[];
}

export interface inventoryDataResponseI {
  amazon?: marketPlaceData;
  meli?: marketPlaceData;
  growker?: marketPlaceData;
}