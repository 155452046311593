import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {createUser, restorePassword, updateUser} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {User} from '../core/_models'
import {useIntl} from 'react-intl'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
// @ts-ignore
import Select from 'react-select'
import {useAuth} from '../../../../auth'

type Props = {
  isUserLoading: boolean
  user: any
  roles: any
  saasPlans: any
  newUser: boolean
}

const UserEditModalForm: FC<Props> = ({user, isUserLoading, roles, saasPlans, newUser}) => {
  const {currentUser} = useAuth()
  let isAdmin = currentUser?.roleId === 2
  let isMerchant = currentUser?.roleId === 3

  const isEditForm = user.id ? true : false
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  const Alert = withReactContent(Swal)
  const intl = useIntl()
  const [userForEdit] = useState<User>({
    ...user,
    roleId: user.roleId,
    saasPlan: user.saasPlan,
    name: user.name,
    lastname: user.lastname,
    username: user.username,
    email: user.email,
  })

  /**
   * Admin can asign Roles and saasPlan
   * Merchant just can create users clients and associate to his own saasPlan id
   * That's why editUserSchema is split on Yup validation
   */
  let editUserSchemaAdmin = {}
  if (isAdmin) {
    editUserSchemaAdmin = {
      roleId: Yup.number().required(intl.formatMessage({id: 'AUTH.VALIDATION.ROLEID_REQUIRED'})),
      saasPlan: Yup.number().required(
        intl.formatMessage({id: 'AUTH.VALIDATION.SAASPLAN_REQUIRED'})
      ),
    }
  }

  /**
   * Base Yup validation rules for all global inputs
   */
  let editUserSchemaBase = {
    name: Yup.string()
      .min(3, intl.formatMessage({id: 'AUTH.VALIDATION.MINIMUN_3'}))
      .max(50, intl.formatMessage({id: 'AUTH.VALIDATION.MAXIMUM_50'}))
      .required(intl.formatMessage({id: 'AUTH.VALIDATION.NAME_REQUIRED'})),
    lastname: Yup.string()
      .min(3, intl.formatMessage({id: 'AUTH.VALIDATION.MINIMUN_3'}))
      .max(50, intl.formatMessage({id: 'AUTH.VALIDATION.MAXIMUM_50'}))
      .required(intl.formatMessage({id: 'AUTH.VALIDATION.LASTNAME_REQUIRED'})),
    username: Yup.string()
      .min(3, intl.formatMessage({id: 'AUTH.VALIDATION.MINIMUN_3'}))
      .max(50, intl.formatMessage({id: 'AUTH.VALIDATION.MAXIMUM_50'})),
    email: Yup.string()
      .email(intl.formatMessage({id: 'AUTH.VALIDATION.WRONG_EMAIL_FORMAT'}))
      .min(3, intl.formatMessage({id: 'AUTH.VALIDATION.MINIMUN_3'}))
      .max(50, intl.formatMessage({id: 'AUTH.VALIDATION.MAXIMUM_50'}))
      .required(intl.formatMessage({id: 'AUTH.VALIDATION.EMAIL_REQUIRED'})),
  }

  /**
   * Merging both configurations
   */
  const editUserSchema = Yup.object({...editUserSchemaAdmin, ...editUserSchemaBase})

  /**
   * To Handle te cancel button
   * @param withRefresh
   */
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  /**
   * Formik function to handle the submition with Yup validation
   */
  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      if (isMerchant) {
        formik.values.roleId = 1
      }
      setSubmitting(true)
      try {
        if (!newUser) {
          await updateUser(values)
        } else {
          await createUser(values)
        }
        Alert.fire({
          title: newUser
            ? intl.formatMessage({id: 'USER_MNGT.ADDUSER.SUCCESS.TITLE'})
            : intl.formatMessage({id: 'USER_MNGT.UPDATE.SUCCESS.TITLE'}),
          html: newUser
            ? intl.formatMessage({id: 'USER_MNGT.ADDUSER.SUCCESS.TEXT'})
            : intl.formatMessage({id: 'USER_MNGT.UPDATE.SUCCESS.TEXT'}),
          icon: 'success',
          iconColor: '#00c6c7',
          buttonsStyling: false,
          confirmButtonText: intl.formatMessage({id: 'USER_MNGT.MODAL.BUTTON.ACCEPT'}),
          reverseButtons: true,
          customClass: {
            confirmButton: 'btn btn-primary',
            popup: 'bg-light',
          },
        }).then(() => {
          setSubmitting(true)
          cancel(true)
          window.location.reload()
        })
      } catch (ex: any) {
        let errTitle = newUser
          ? intl.formatMessage({id: 'USER_MNGT.ADDUSER.FAIL.TITLE'})
          : intl.formatMessage({id: 'USER_MNGT.UPDATE.FAIL.TITLE'})
        let errText = ''
        if (ex.response.data.status === 400) {
          errText = intl.formatMessage({id: 'USER_MNGT.ADDUSER.FAIL.400.TEXT'})
        } else if (ex.response.data.status === 404) {
          errText = intl.formatMessage({id: 'USER_MNGT.ADDUSER.FAIL.404.TEXT'})
        } else {
          errText = intl.formatMessage({id: 'USER_MNGT.ADDUSER.FAIL.GEN.TEXT'})
        }
        Alert.fire({
          title: errTitle,
          html: errText,
          icon: 'info',
          iconColor: '#00c6c7',
          buttonsStyling: false,
          confirmButtonText: intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'}),
          reverseButtons: true,
          customClass: {
            confirmButton: 'btn btn-primary',
            popup: 'bg-light',
          },
        })
      }
    },
  })

  /**
   * Trigger the onclick over restore Password button
   */
  const handleRestorePass = () => {
    restorePassword(formik.values).then(() => {
      Alert.fire({
        title: intl.formatMessage({id: 'USER_MNGT.ACTION.RESTORE_PASS.TITLE'}),
        html: intl.formatMessage({id: 'USER_MNGT.ACTION.RESTORE_PASS.TEXT'}),
        icon: 'success',
        iconColor: '#00c6c7',
        buttonsStyling: false,
        confirmButtonText: intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'}),
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          popup: 'bg-light',
        },
      }).then(() => {
        window.location.reload()
      })
    })
  }

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-7 ms-1'>
            <label className='required fw-bold fs-6 mb-2'>
              {intl.formatMessage({id: 'USER_MNGT.TABLE.HEAD.NAME'})}
            </label>
            <input
              type='text'
              {...formik.getFieldProps('name')}
              name='name'
              className={clsx(
                'form-control bg-transparent fw-normal',
                {'is-invalid': formik.touched.name && formik.errors.name},
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete='off'
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7 ms-1'>
            <label className='required fw-bold fs-6 mb-2'>
              {intl.formatMessage({id: 'USER_MNGT.TABLE.HEAD.LASTNAME'})}
            </label>
            <input
              type='text'
              {...formik.getFieldProps('lastname')}
              name='lastname'
              className={clsx(
                'form-control bg-transparent fw-normal',
                {'is-invalid': formik.touched.lastname && formik.errors.lastname},
                {
                  'is-valid': formik.touched.lastname && !formik.errors.lastname,
                }
              )}
              autoComplete='off'
            />
            {formik.touched.lastname && formik.errors.lastname && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.lastname}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7 ms-1' hidden={user.id ? false : true}>
            <label className='fw-bold fs-6 mb-2'>
              {intl.formatMessage({id: 'USER_MNGT.TABLE.HEAD.USERNAME'})}
            </label>
            <label
              hidden={!user.id}
              className={clsx(
                'form-control bg-transparent  fw-normal',
                {'is-invalid': formik.touched.username && formik.errors.username},
                {
                  'is-valid': formik.touched.username && !formik.errors.username,
                }
              )}
            >
              {formik.values.username}
            </label>
          </div>
          <div className='fv-row mb-7 ms-1'>
            {user.id ? (
              <>
                <label className='fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'USER_MNGT.TABLE.HEAD.EMAIL'})}
                </label>
                <label
                  className={clsx(
                    'form-control bg-transparent  fw-normal',
                    {'is-invalid': formik.touched.email && formik.errors.email},
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                >
                  {formik.values.email}
                </label>
              </>
            ) : (
              <>
                <label className='required fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'USER_MNGT.TABLE.HEAD.EMAIL'})}
                </label>
                <input
                  type='text'
                  {...formik.getFieldProps('email')}
                  name='email'
                  className={clsx(
                    'form-control bg-transparent  fw-normal',
                    {'is-invalid': formik.touched.email && formik.errors.email},
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  autoComplete='off'
                />
              </>
            )}
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>

          {isAdmin && (
            <div className='mb-7 ms-1'>
              <>
                <label className='required fw-bold fs-6 mb-5'>
                  {intl.formatMessage({id: 'USER_MNGT.TABLE.HEAD.ROLE'})}
                </label>
                <Select
                  name='roleId'
                  options={roles}
                  defaultValue={
                    isEditForm
                      ? roles.find((rol: any) => rol.value === user.roleId)
                      : {value: 0, label: ''}
                  }
                  classNamePrefix='react-select'
                  onChange={(e: any) => {
                    formik.values.roleId = e.value
                  }}
                  className={clsx(
                    'form-control bg-transparent p-0',
                    {'is-invalid': formik.touched.roleId && formik.errors.roleId},
                    {
                      'is-valid': formik.touched.roleId && !formik.errors.roleId,
                    }
                  )}
                />
                {formik.touched.roleId && formik.errors.roleId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.roleId}</span>
                    </div>
                  </div>
                )}
              </>
            </div>
          )}
          {isAdmin && (
            <div className='mb-7 ms-1'>
              <>
                <label className='required fw-bold fs-6 mb-5'>
                  {intl.formatMessage({id: 'USER_MNGT.TABLE.HEAD.SAAS.PLAN'})}
                </label>
                <Select
                  name='saasPlan'
                  defaultValue={
                    isEditForm
                      ? saasPlans.find((plan: any) => plan.value === user.saasPlan)
                      : {value: 0, label: ''}
                  }
                  options={saasPlans}
                  classNamePrefix='react-select'
                  onChange={(e: any) => {
                    formik.values.saasPlan = e.value
                  }}
                  className={clsx(
                    'form-control bg-transparent p-0',
                    {'is-invalid': formik.touched.saasPlan && formik.errors.saasPlan},
                    {
                      'is-valid': formik.touched.saasPlan && !formik.errors.saasPlan,
                    }
                  )}
                />
                {formik.touched.saasPlan && formik.errors.saasPlan && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.saasPlan}</span>
                    </div>
                  </div>
                )}
              </>
            </div>
          )}
          {isEditForm ? (
            <div className='fv-row mb-7 ms-1'>
              <small className={'fw-regular fs-7'}>
                {intl.formatMessage({id: 'USER_MNGT.ACTION.RESTORE_PASS.NOTE'})}
              </small>
            </div>
          ) : (
            <div className='fv-row mb-7 ms-1'>
              <small className={'fw-regular fs-7'}>
                {intl.formatMessage({id: 'USER_MNGT.ACTION.PASSWORD.NOTE'})}
              </small>
            </div>
          )}

          <div className='modal-footer d-flex flex-center'>
            <button
              type='button'
              className='btn btn-secondary me-3'
              onClick={() => setItemIdForUpdate(undefined)}
              data-bs-dismiss='modal'
            >
              {intl.formatMessage({id: 'USER_MNGT.MODAL.BUTTON.CANCEL'})}
            </button>
            {isEditForm && (
              <button
                type='button'
                className='btn btn-light-primary me-3 text-dark'
                onClick={handleRestorePass}
              >
                {intl.formatMessage({id: 'USER_MNGT.ACTION.BUTTON.RESTORE_PASS'})}
              </button>
            )}
            <button type='submit' className='btn btn-primary text-dark'>
              {isEditForm
                ? intl.formatMessage({id: 'USER_MNGT.ACTION.BUTTON.UPDATE'})
                : intl.formatMessage({id: 'USER_MNGT.ACTION.BUTTON.CREATE'})}
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

export {UserEditModalForm}
