import styled from '@emotion/styled'

export const Container = styled('div')({})

//-------------- charts -------------------------//
export const ContainerCharts = styled('div')({
  marginTop: '2rem',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: "1fr 1fr",
  gap: '1rem',
  '@media (max-width: 768px)': {
    display: 'flex',
    flexFlow: 'column',
  },
})

export const CardChart = styled('div')({
  padding: '20px',
  borderRadius: '10px',
  boxShadow: '-1px 14px 19px -4px #919EAB33',
  p: {
    color: '#9E9E9E',
  },
})
//-------------- charts -------------------------//

//-------------- global info -------------------------//
type InfoProp = {
  bg?: string
}
export const GlobalInfoContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '2rem',
  '@media (max-width: 727px)': {
    gridTemplateColumns: '1fr',
  },
})
export const InfoCard = styled('div')<InfoProp>(({bg}) => ({
  background: bg,
  display: 'flex',
  justifyContent: 'space-between',
  borderRadius: '10px',
  height: '80px',
  padding: '20px',
  h4: {
    fontSize: '1.5rem',
    marginLeft: '10px',
  },
  p: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginTop: '10px',
  },
}))

//-------------- global info -------------------------//

//-------------- global info -------------------------//
type IconProp = {
  bg?: string
}
export const GoalsProgressContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '2rem',
  '@media (max-width: 727px)': {
    gridTemplateColumns: '1fr',
  },
})
export const CardGoals = styled('div')({
  padding: '20px',
  borderRadius: '10px',
  boxShadow: '-1px 14px 19px -4px #919EAB33',
})

export const WrapperIcon = styled('div')<IconProp>(({bg}) => ({
  padding: "10px",
  borderRadius: "10px",
  background: bg,
  marginRight: "10px"
}))

export const WrapperDataGoals = styled('div')<IconProp>(({bg}) => ({
  display: "flex",
  alignItems: "center",
  h6: {
    margin: '0px',
    fontSize: '1.3rem',
    fontWeight: "bold"
  },
  p: {
    margin: "0px",
    color: "#9E9E9E",
    marginTop: "5px",
    fontSize: "1.2rem"
  }
}))

export const WrapperProgress = styled('div')({
  marginTop: "40px"
})
//-------------- global info -------------------------//
