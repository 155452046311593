import {useQuery} from 'react-query'
import {UserEditModalForm} from './UserEditModalForm'
import {useListView} from '../core/ListViewProvider'
import {getRolesList, getSaasPlans, getUserById} from '../core/_requests'
import {isNotEmpty} from '../../../../../../_gws/helpers'
import {FC, useEffect, useState} from 'react'
import {ModalLoading} from '../components/ModalLoading'

type Props = {
  height?: number
}

const UserEditModalFormWrapper: FC<Props> = ({height}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)

  /**
   * Used to catch roles and plans objects
   */
  const [roles, setRoles] = useState([])
  const [saasPlans, setSaasPlans] = useState([])

  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `get user`,
    () => {
      return getUserById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay))
  }

  useEffect(() => {
    /**
     * Requesting Catalogs
     */
    const loadCatalogs = async () => {
      try {
        await timeout(2000)
        /**
         * Getting & Assing Roles
         */
        const responseRoles = await getRolesList()
        if (responseRoles.data.status === 200) {
          setRoles(responseRoles.data.detail)
        }

        /**
         * Getting & Assing Plans
         */
        const responseSaasPlans = await getSaasPlans()
        if (responseSaasPlans.data.status === 200) {
          setSaasPlans(responseSaasPlans.data.detail)
        }
      } catch (error) {
        console.log(error)
      }
    }

    loadCatalogs()
  }, [])

  /**
   * Used if is a new user to save
   * Don't need user, roles or saasPlans
   */
  if (!itemIdForUpdate) {
    if (roles.length > 0 && saasPlans.length > 0) {
      return (
        <UserEditModalForm
          isUserLoading={isLoading}
          user={{id: undefined}}
          roles={roles}
          saasPlans={saasPlans}
          newUser={true}
        />
      )
    } else {
      return <ModalLoading height={height} />
    }
  }

  /**
   * Validations required to populate users, roles and saasPlans
   */
  if (!isLoading && !error && user && roles.length > 0 && saasPlans.length > 0) {
    return (
      <UserEditModalForm
        isUserLoading={isLoading}
        user={user}
        roles={roles}
        saasPlans={saasPlans}
        newUser={false}
      />
    )
  } else {
    return <ModalLoading height={height} />
  }
}

export {UserEditModalFormWrapper}
