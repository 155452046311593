import axios from 'axios'
import {getTokenHeader} from '../../auth'
import {ItemGlossary} from '../types'

const API_URL_CRUNCH = process.env.REACT_APP_BACKEND_MAIN_API

export const getGlossaryData = async () => {
  const head = getTokenHeader()
  const cleanAxios = axios.create()

  return cleanAxios.get(
    `${API_URL_CRUNCH}/api/glossary`,
    // @ts-ignore
    head
  )
}

export const createGlossaryItem = async (item: ItemGlossary) => {
  const head = getTokenHeader()
  const cleanAxios = axios.create()

  return cleanAxios
    .post(
      `${API_URL_CRUNCH}/api/glossary`,
      {
        ...item
      },
      // @ts-ignore
      head
    )
    .then((data) => data)
    .catch((err) => err.response)
}

export const updateGlossaryItem = async (item: ItemGlossary) => {
  const head = getTokenHeader()
  const cleanAxios = axios.create()

  return cleanAxios.put(
    `${API_URL_CRUNCH}/api/glossary/${item.id}`,
    {
      title: item.title,
      subtitle: item.subtitle,
      description: item.description,
      category: item.category,
      categories: item.categories,
    },
    // @ts-ignore
    head
  ).catch((err) => err.response )
}

export const deleteGlossaryItem = async (id: number) => {
  const head = getTokenHeader()
  const cleanAxios = axios.create()

  return cleanAxios.delete(
    `${API_URL_CRUNCH}/api/glossary/${id}`,
    // @ts-ignore
    head
  )
}
