import {User, UserAssociated} from '../core/_models'
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, {customFilter} from 'react-bootstrap-table2-filter'
import {ToolkitContextType} from 'react-bootstrap-table2-toolkit'
//@ts-ignore
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'

import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css'
import {CustomSelectFilter} from '../../../../../../_gws/partials/tables/CustomSelectFilter'
import {CustomSearchForm} from '../../../../../../_gws/partials/tables/CustomSearchForm'
import {UserActionsCell} from './ActionCell'
import {CustomTotal} from '../../../../../../_gws/partials/tables/CustomTotal'
import {headFormatter, optionsTable} from '../../../../../../_gws/partials/tables/TableHelper'
import {AvatarUser} from '../../../../../../_gws/partials/widgets/misc/AvatarUser'

type UsersTableAssignsProps = {
  users?: User
  activeUsers?: UserAssociated
  userId: number
}

/**
 * Table to show Clients users
 * Allows to assign users to current Merchant with a button
 * The table is sorted by Associated to show first associated users
 * @param users - List of all users
 * @param activeUsers - List of associated users
 * @param userId - Current user id (Merchant)
 */
const UsersTableAssigns: FC<UsersTableAssignsProps> = ({users, activeUsers, userId}) => {
  const intl = useIntl()

  /**
   * Array to put in memory assigned users to merchant
   */
  let dataUserActive: any = []
  /**
   * Mapping to generate the array of associated users to current Merchant id
   */
  activeUsers?.map((user: any) => {
    return dataUserActive.push(user.id)
  })

  /**
   * Building a new list mapping all the users non Admin or Merchants
   */
  let dataUser: any = []
  users?.map((user: any) => {
    if (user.roleId === 1) {
      dataUser = [
        ...dataUser,
        {
          ...user,
          assigned: dataUserActive.indexOf(user.id) !== -1,
        },
      ]
    }
    return null
  })

  const customTotal = (from: number, to: number, size: number) => (
    <CustomTotal from={from} to={to} size={size} />
  )

  const formatterActions = (cellContent: any, row: any) => (
    <UserActionsCell row={row} userID={userId} />
  )

  /**
   * Columns configuration for React Bootstrap Table
   */
  const columns = [
    {
      dataField: 'picture',
      text: '',
      searchable: false,
      style: {width: '5%'},
      headerFormatter: headFormatter,
      formatter: (cellContent: any, row: any) => {
        return (
          <AvatarUser
            userID={row.id}
            picUrl={row.picture}
            name={row.name}
            username={row.username}
            lastname={row.lastname}
          />
        )
      },
    },
    {
      dataField: 'id',
      text: intl.formatMessage({id: 'USER_MNGT.TABLE.HEAD.ID'}),
      sort: true,
      searchable: false,
      headerFormatter: headFormatter,
      style: {
        width: '5%',
      },
    },
    {
      dataField: 'email',
      text: intl.formatMessage({id: 'USER_MNGT.TABLE.HEAD.EMAIL'}),
      sort: true,
      searchable: true,
      headerFormatter: headFormatter,
      style: {
        width: '20%',
      },
    },
    {
      dataField: 'name',
      text: intl.formatMessage({id: 'USER_MNGT.TABLE.HEAD.NAME'}),
      sort: true,
      searchable: true,
      headerFormatter: headFormatter,
      style: {
        width: '20%',
      },
    },
    {
      dataField: 'lastname',
      text: intl.formatMessage({id: 'USER_MNGT.TABLE.HEAD.LASTNAME'}),
      sort: true,
      searchable: true,
      headerFormatter: headFormatter,
      style: {
        width: '20%',
      },
    },
    {
      dataField: 'assigned',
      text: '',
      sort: false,
      searchable: false,
      headerFormatter: headFormatter,
      style: {
        width: '15%',
      },
      filter: customFilter({}),
      filterRenderer: (onFilter: any) => (
        <CustomSelectFilter
          title={intl.formatMessage({id: 'USER_MNGT.TABLE.HEAD.STATUS'})}
          options={{
            true: intl.formatMessage({id: 'USER_MNGT.TABLE.ACTIONS.ASSOCIATED'}),
            false: intl.formatMessage({id: 'USER_MNGT.TABLE.ACTIONS.NO_ASSOCIATED'}),
          }}
          onFilter={onFilter}
        ></CustomSelectFilter>
      ),
      formatter: (cellContent: any, row: any) => {
        if (row.assigned) {
          return (
            <span className='badge badge-secondary fw-regular px-3 py-3'>
              {intl.formatMessage({id: 'USER_MNGT.TABLE.ACTIONS.ASSOCIATED'})}
            </span>
          )
        } else {
          return (
            <span className='badge badge-dark fw-regular px-3 py-3'>
              {intl.formatMessage({id: 'USER_MNGT.TABLE.ACTIONS.NO_ASSOCIATED'})}
            </span>
          )
        }
      },
    },
    {
      dataField: 'Action user',
      isDummyField: true,
      headerFormatter: headFormatter,
      text: intl.formatMessage({id: 'USER_MNGT.ACTION'}),
      formatter: formatterActions,
      style: {
        width: '15%',
      },
    },
  ]

  return (
    <ToolkitProvider
      columns={columns}
      data={dataUser === undefined ? [] : dataUser}
      keyField={'id'}
      search
    >
      {(props: ToolkitContextType) => (
        <div>
          <CustomSearchForm
            {...props.searchProps}
            placeholder={intl.formatMessage({id: 'REPORT.INVENTORY.SEARCH_TERM'})}
          />
          <div className='separator separator-dotted my-5'></div>
          <BootstrapTable
            {...props.baseProps}
            bootstrap4={true}
            striped={false}
            hover={true}
            pagination={paginationFactory(optionsTable(customTotal, intl.locale))}
            filter={filterFactory()}
            classes={'table gy-2 gs-2'}
            wrapperClasses={'table-responsive position-relative mb-5'}
            bodyClasses={'text-gray-600'}
            rowClasses={'align-middle'}
            defaultSorted={[
              {
                dataField: 'assigned',
                order: 'desc',
              },
            ]}
          />
        </div>
      )}
    </ToolkitProvider>
  )
}

export {UsersTableAssigns}
