/* eslint-disable jsx-a11y/anchor-is-valid */
import {Route, Routes} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_gws/helpers'
import styled from '@emotion/styled'
import {Payment} from './components/Payment'
import { useIntl } from 'react-intl'
/**
 * Auth Layout for the login form and the other elements outside the form
 * @component
 */

export const Container = styled('div')({
  background: '#ffffff',
  height: "100vh"
})

export const ContainerImg = styled('div')({
  background: `url(/media/backgrounds/bg-gradient.png) no-repeat center`,
  backgroundSize: "cover",
  display: "grid",
  placeItems: "center",
  "@media (max-width: 768px)": {
    display: "none"
  }
})

export const ContentImg = styled('div')({
  color: "#fff",
  display: "flex",
  flexFlow: "column",
  alignItems: "center",
  img: {
    width: "70%"
  },
  h4: {
    color: "#fff",
    fontSize: "2rem"
  },
  p: {
    width: "50%",
    textAlign: "center",
    marginTop: "10px"
  }
})

export const Wrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: '.9fr 1fr',
  height: '100%',
  '@media (max-width: 1000px)': {
    gridTemplateColumns: '1fr',
  },
})

const PaymentLayout = () => {
  const intl = useIntl()
  return (
    <Container>
      <Wrapper>
        <div
          style={{
            display: 'grid',
            placeItems: 'center',
          }}
        >
          <Payment />
        </div>
        <ContainerImg>
          <ContentImg>
            <img alt='Atro' src={toAbsoluteUrl('/media/backgrounds/Artboard-payment.png')} />
            <h4>{intl.formatMessage({id: 'LOGIN.PAYMENT.TITLE'})}</h4>
            <p>
              {intl.formatMessage({id: 'LOGIN.PAYMENT.TEXT'})}
            </p>
            <img style={{ width: "200px", marginTop: "10px" }} alt='Logo' src={toAbsoluteUrl('/media/backgrounds/early-access.png')} />
          </ContentImg>
        </ContainerImg>
      </Wrapper>
    </Container>
  )
}

const PaymentPage = () => (
  <Routes>
    <Route element={<PaymentLayout />}>
      <Route index element={<Payment />} />
    </Route>
  </Routes>
)

export {PaymentPage}
