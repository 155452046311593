import {TextProps} from '../AgText'

/**
 * Standard prototype of Text "General" | renders a div element with a specific font size and line height, and can have a custom class and text provided as properties.
 * @param customClass - to add extra classes
 * @param text - the text content
 * @constructor
 */
const AgTextGeneral: React.FC<TextProps> = ({customClass, text, children}) => {
  /**
   * static css for the content
   */
  const css = {
    fontSize: '0.95em',
    linHeight: '23px',
  }

  return (
    <div className={`ff-prompt fw-normal ${customClass}`} style={css}>
      {text}
      {children}
    </div>
  )
}

export {AgTextGeneral}
