import React, {FC} from 'react'
import {StatisticsWidget7} from '../../../../../_gws/partials'
import {useIntl} from 'react-intl'
import {FrankiePieChart} from '../FrankiePieChart'
import {FrankieTableWrapper} from '../FrankieTable/FrankieTableWrapper'

type InventoryProps = {
  inventory: any
  mkp: string | undefined
}
const FrankieInventoryWrapper: FC<InventoryProps> = ({inventory, mkp}) => {
  const intl = useIntl()

  const listConditions: any = Array.from(
    new Set(
      inventory?.uniqueProductsList?.map((dat: any) => {
        return dat.state
      })
    )
  )

  const newItemCount = listConditions.map((cond: any) => {
    return inventory?.uniqueProductsList?.filter((prod: any) => prod.state === cond).length
  })

  const labels = listConditions.map((cond: any) => {
    return `${intl.formatMessage({
      id: 'REPORT.INVENTORY.TABLE.HEAD.STATE.' + cond.toUpperCase(),
    })}`
  })

  return (
    <>
      <div className='row mt-5 mb-5'>
        <div className='col-12'>
          <h5 className='card-title align-items-start flex-column'>
            <span className='card-label fw-semibold fs-3 mb-1 ps-5'>{`${intl.formatMessage({
              id: 'REPORT.FRANKIE.INVENTORY.AVAILABILITY',
            })}`}</span>
          </h5>
        </div>
      </div>

      <div className='row mt-1'>
        <div className='col-6 col-md-2 mt-3 mb-3'>
          <StatisticsWidget7
            className={'card-xl-stretch'}
            iconColor={'dark'}
            titleColor={'dark'}
            descriptionColor={'dark'}
            title={inventory.availableUnitsAmount}
            description={`${intl.formatMessage({
              id: 'REPORT.FRANKIE.INVENTORY.PRODUCT.AVAILABLE',
            })}`}
            height={'180px'}
            countUp={true}
          />
          <StatisticsWidget7
            className={'card-xl-stretch mt-6'}
            iconColor={'dark'}
            titleColor={'dark'}
            descriptionColor={'dark'}
            title={inventory.uniqueProductsAmount}
            description={`${intl.formatMessage({id: 'REPORT.FRANKIE.INVENTORY.PRODUCT.UNIQUE'})}`}
            height={'190px'}
            countUp={true}
          />
        </div>
        <div className='col-6 col-md-3 mt-3 mb-3'>
          <FrankiePieChart listLabels={labels} listCounts={newItemCount} />
        </div>

        <div className='col-md-7 mt-3 mb-3'>
          <FrankieTableWrapper
            listProduct={inventory.uniqueProductsList.filter((prod: any) => prod.stock > 0)}
            mkp={mkp}
            listConditions={listConditions}
            title={`${intl.formatMessage({
              id: 'REPORT.FRANKIE.INVENTORY.PRODUCT.UNIQUE_AVAILABLE',
            })}`}
            autoHeight={true}
          />
        </div>
      </div>
      <div className='row mt-5'>
        <div className='col-12'>
          <h5 className='card-title align-items-start flex-column'>
            <span className='card-label fw-semibold fs-3 mb-1 ps-5'>Stock</span>
          </h5>
        </div>
      </div>
      <div className='row mt-1'>
        <div className='col-md-6 mt-3'>
          <StatisticsWidget7
            className={'card-xl-stretch'}
            iconColor={'dark'}
            titleColor={'dark'}
            descriptionColor={'dark'}
            title={inventory.nonStockProducts}
            description={`${intl.formatMessage({id: 'REPORT.FRANKIE.INVENTORY.NO_STOCK'})}`}
            height={'100px'}
            countUp={true}
          />
          <div className='mt-5'></div>
          {inventory.nonStockProducts > 0 && (
            <FrankieTableWrapper
              listProduct={inventory.nonStockProductsList}
              mkp={mkp}
              listConditions={listConditions}
              title={`${intl.formatMessage({id: 'REPORT.FRANKIE.INVENTORY.NO_STOCK'})}`}
            />
          )}
        </div>
        <div className='col-md-6 mt-3'>
          <StatisticsWidget7
            className={'card-xl-stretch'}
            iconColor={'dark'}
            titleColor={'dark'}
            descriptionColor={'dark'}
            title={inventory.lowStockProducts}
            description={`${intl.formatMessage({id: 'REPORT.FRANKIE.INVENTORY.LOW_STOCK'})}`}
            height={'100px'}
            countUp={true}
          />
          <div className='mt-5'></div>
          {inventory.lowStockProducts > 0 && (
            <FrankieTableWrapper
              listProduct={inventory.lowStockProductsList}
              mkp={mkp}
              listConditions={listConditions}
              title={`${intl.formatMessage({id: 'REPORT.FRANKIE.INVENTORY.LOW_STOCK'})}`}
            />
          )}
        </div>
      </div>
      <br />
    </>
  )
}

export {FrankieInventoryWrapper}
