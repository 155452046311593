import {Container, ExportCSV, ProductsWrapper} from './components/styled'
import {ProductCard} from './components/ProductCard'
import {Bestseller} from './core/types'
import {IconAmazonShort} from '../playlist/components/PlayListIcons'
import {FilterMonitorCategory} from './components/FiltersMonitor'
import {BannerBody, BannerCard} from '../dashboard/components/client/styled'
import {toAbsoluteUrl} from '../../../_gws/helpers'
import styled from '@emotion/styled'
import {mkConfig, generateCsv, download} from 'export-to-csv'
import {useIntl} from 'react-intl'

export const BannerImg = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  img: {
    width: '300px',
    height: 'auto',
  },
  '@media (max-width: 1024px)': {
    display: 'none',
  },
})

export const PerformaceMonitor = ({
  data,
  category,
}: {
  data: Bestseller[]
  category: {
    value: string
    handlerCategory: any
  }
}) => {
  const intl = useIntl()
  const csvConfig = mkConfig({
    useKeysAsHeaders: true,
    quoteCharacter: "'",
    quoteStrings: false,
  })
  const exportToCSV = () => {
    const format = data.map((item: Bestseller) => ({
      asin: item.asin,
      title: item.title,
      brand: item.brand,
      productGroup: item.productGroup,
      color: item.color,
      binding: item.binding,
      publicationDate: item.publicationDate,
      price: item.price,
      rank: item.rank,
    }))
    const csv = generateCsv(csvConfig)(format)
    download(csvConfig)(csv)
  }
  return (
    <Container>
      <BannerCard
        style={{
          background: '#F9F1E3',
          gridTemplateColumns: '2fr 1fr',
          margin: '20px 0px',
        }}
      >
        <BannerBody
          style={{
            placeItems: 'flex-start',
          }}
        >
          <h3>{intl.formatMessage({id: 'MONITOR.TITLE'})}</h3>
          <p>{intl.formatMessage({id: 'MONITOR.DESCRIPTION'})}</p>
        </BannerBody>
        <BannerImg>
          <img alt='Atro' src={toAbsoluteUrl('/media/backgrounds/astro-junior.png')} />
        </BannerImg>
      </BannerCard>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <h4 style={{marginLeft: '10px'}}>{intl.formatMessage({id: 'MONITOR.LABEL'})}</h4>
          <IconAmazonShort />
        </div>
        <div style={{display: 'flex'}}>
          <FilterMonitorCategory category={category} />
          <ExportCSV onClick={exportToCSV}>
            <svg width='14' height='14' viewBox='0 0 14 14' fill='none'>
              <path
                d='M1.55556 14C1.12778 14 0.761574 13.8477 0.456944 13.5431C0.152315 13.2384 0 12.8722 0 12.4444V10.1111C0 9.89074 0.074537 9.70602 0.223611 9.55694C0.372685 9.40787 0.557407 9.33333 0.777778 9.33333C0.998148 9.33333 1.18287 9.40787 1.33194 9.55694C1.48102 9.70602 1.55556 9.89074 1.55556 10.1111V12.4444H12.4444V1.55556H1.55556V3.88889C1.55556 4.10926 1.48102 4.29398 1.33194 4.44306C1.18287 4.59213 0.998148 4.66667 0.777778 4.66667C0.557407 4.66667 0.372685 4.59213 0.223611 4.44306C0.074537 4.29398 0 4.10926 0 3.88889V1.55556C0 1.12778 0.152315 0.761574 0.456944 0.456944C0.761574 0.152315 1.12778 0 1.55556 0H12.4444C12.8722 0 13.2384 0.152315 13.5431 0.456944C13.8477 0.761574 14 1.12778 14 1.55556V12.4444C14 12.8722 13.8477 13.2384 13.5431 13.5431C13.2384 13.8477 12.8722 14 12.4444 14H1.55556ZM6.72778 7.77778H0.777778C0.557407 7.77778 0.372685 7.70324 0.223611 7.55417C0.074537 7.40509 0 7.22037 0 7C0 6.77963 0.074537 6.59491 0.223611 6.44583C0.372685 6.29676 0.557407 6.22222 0.777778 6.22222H6.72778L5.28889 4.78333C5.13333 4.62778 5.0588 4.4463 5.06528 4.23889C5.07176 4.03148 5.1463 3.85 5.28889 3.69444C5.44444 3.53889 5.62917 3.45787 5.84306 3.45139C6.05694 3.44491 6.24167 3.51944 6.39722 3.675L9.17778 6.45556C9.25556 6.53333 9.31065 6.61759 9.34306 6.70833C9.37546 6.79907 9.39167 6.8963 9.39167 7C9.39167 7.1037 9.37546 7.20093 9.34306 7.29167C9.31065 7.38241 9.25556 7.46667 9.17778 7.54444L6.39722 10.325C6.24167 10.4806 6.05694 10.5551 5.84306 10.5486C5.62917 10.5421 5.44444 10.4611 5.28889 10.3056C5.1463 10.15 5.07176 9.96852 5.06528 9.76111C5.0588 9.5537 5.13333 9.37222 5.28889 9.21667L6.72778 7.77778Z'
                fill='#212121'
              />
            </svg>
            <span style={{marginLeft: '5px'}}>
              {intl.formatMessage({id: 'REPORT.SALES.TABLE.ACTION'})}
            </span>
          </ExportCSV>
        </div>
      </div>
      <ProductsWrapper>
        {data.map((product, i) => (
          <ProductCard key={i} data={product} />
        ))}
      </ProductsWrapper>
    </Container>
  )
}
