export type Marketplaces = {
  nameUpper: string
  name: string
  id: number
}

export const MARKETPLACES: Record<string, Marketplaces> = {
  AMAZON: {
    name: 'amazon',
    nameUpper: 'AMAZON',
    id: 1,
  },
  MERCADOLIBRE: {
    name: 'meli',
    nameUpper: 'MELI',
    id: 2,
  },
  GROWKER: {
    name: 'growker',
    nameUpper: 'GROWKER',
    id: 0,
  },
}
type PeriodReportTypes = 'ACTUAL_MONTH' | 'LAST_30_DAYS' | 'LAST_15_DAYS' | 'LAST_7_DAYS'

export const PERIOD_REPORT_TYPES: Record<PeriodReportTypes, number> = {
  ACTUAL_MONTH: 1,
  LAST_30_DAYS: 2,
  LAST_15_DAYS: 3,
  LAST_7_DAYS: 4,
}

type CategoryTypes = {
  CATEGORY_4: string
}

export const BRAND_HEALTH_CATEGORY_TYPES: CategoryTypes = {
  CATEGORY_4: 'category_4',
}

type ProductType = {
  FBA: 'FBA'
  FBM: 'FBM'
}
export const PRODUCT_TYPE: ProductType = {
  FBA: 'FBA',
  FBM: 'FBM',
}
