import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'

const InventoryAside = () => {
  const intl = useIntl()
  return (
    <>
      <AsideMenuItemWithSub
        listTo={['inventory', 'product-tracker']}
        icon='/media/icons/gws-icons/inventario.svg'
        title={intl.formatMessage({id: 'MENU.REPORTS.INVENTORY'})}
      >
        {/*Reporte*/}
        <AsideMenuItem
          to='/inventory'
          title={intl.formatMessage({id: 'MENU.REPORTS.INVENTORY'})}
          hasBullet={true}
        />
        {/*  */}
      </AsideMenuItemWithSub>
    </>
  )
}

export {InventoryAside}
