/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import CountUp from 'react-countup'
import {abbreviateNumber} from '../../../helpers/misc'

type Props = {
  className: string
  color?: string
  iconColor: string
  title: string | null
  titleColor?: string
  description: string
  descriptionColor?: string
  backgroundShape?: any
  height?: string
  tooltip?: string
  countUp?: boolean
  decimal?: boolean
  prefix?: string
  metrix?: string
}

const StatisticsWidget7: React.FC<Props> = ({
  className,
  color,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
  height,
  tooltip,
  countUp,
  decimal,
  prefix,
  metrix,
}) => {
  const countUpState = countUp !== true ? false : true

  return (
    <div
      className={`card bg-${color} hoverable ${className}`}
      style={{
        height: `${height}`,
      }}
    >
      <div className='card-body p-1'>
        <div className='row h-100'>
          <div className='col-12 d-flex flex-column align-items-center justify-content-center d-none d-md-flex'>
            <div className={`text-${titleColor} fw-bold fs-1 ps-2 pe-2`}>
              {prefix}
              {abbreviateNumber(Number(title), 1)}
              {metrix}
            </div>
            <div className={`fw-semibold text-center ps-3 pe-3 text-${descriptionColor}`}>
              {description}&nbsp;
              {tooltip && (
                <OverlayTrigger
                  placement='top'
                  overlay={<Tooltip id={`tooltip-top`}>{tooltip}</Tooltip>}
                  defaultShow={false}
                >
                  <div
                    className='symbol symbol-50px me-5'
                    data-balloon-pos='up'
                    aria-label={tooltip}
                  >
                    <span>
                      <KTSVG
                        path={toAbsoluteUrl('media/icons/duotune/general/gen046.svg')}
                        className={`svg-icon-1x svg-icon-${iconColor}`}
                      />
                    </span>
                  </div>
                </OverlayTrigger>
              )}
            </div>
          </div>
          <div className='col-12 d-flex flex-column align-items-center justify-content-center d-md-none d-block'>
            <div className={`text-${titleColor} fw-bold fs-1`}>
              {prefix}
              {countUpState && decimal && (
                <CountUp end={Number(title)} separator={','} decimals={2} />
              )}
              {countUpState && !decimal && <CountUp end={Number(title)} separator={','} />}
              {!countUpState && title}
              {metrix}
            </div>
            <div className={`fw-semibold text-center text-${descriptionColor}`}>
              {description}&nbsp;
              {tooltip && (
                <OverlayTrigger
                  placement='top'
                  overlay={<Tooltip id={`tooltip-top`}>{tooltip}</Tooltip>}
                  defaultShow={false}
                >
                  <div
                    className='symbol symbol-50px me-5'
                    data-balloon-pos='up'
                    aria-label={tooltip}
                  >
                    <span>
                      <KTSVG
                        path={toAbsoluteUrl('media/icons/duotune/general/gen046.svg')}
                        className={`svg-icon-1x svg-icon-${iconColor}`}
                      />
                    </span>
                  </div>
                </OverlayTrigger>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {StatisticsWidget7}
