import React from 'react'
import {BasicLabel} from '../Labels'
import {ProgressWithNum} from '../Progress'
import {AgPercentageCardText} from '../../../layout/components/ag/components/AgPercentageCardText'
import {AgTitleCard} from '../../../layout/components/ag/components/AgTitleCard'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {AgText} from '../../../layout/components/ag/AgText'

type CardSliderProps = {
  title: string
  to?: string
  subtitle?: string
  basicLabel?: {
    text: string
    backgroundColor: string
    color: string
  }
  progressWithNum: {
    labelColor: string
    backgroundColor: string
    visualPartsPercentage: string
    visualPartsColor: string
  }
}

/**
 * This Component create a Card with Label, Progress, number and link.
 *
 * @param title - string with the title
 * @param subtitle - string with the subtitle
 * @param to - string with de url to redirect for more details. Is to is empty the components dont show the text.
 * @param basicLabel - object with text, backgroundColor(variable sass) and color(variable sass)
 * @param progressWithNum - object with labelColor (variable sass), backgroundColor (variable sass), visualPartsPercentage (string with percentage) and visualPartsColor (string with color sass variable)
 */

const CardLabelandProgress: React.FC<CardSliderProps> = ({
  title,
  subtitle,
  basicLabel,
  progressWithNum,
  to,
}) => {
  const intl = useIntl()

  return (
    <div className='card d-flex justify-content-between w-100 flex-column flex-column align-items-start p-4 pe-5 m-0'>
      <AgTitleCard text={title} customClass={'mb-5 pe-8'} />
      {subtitle && <AgText choice={'percentageCard'} text={subtitle} />}
      <div className='mt-9 w-100'>
        <ProgressWithNum
          labelColor={progressWithNum.labelColor}
          backgroundColor={progressWithNum.backgroundColor}
          visualParts={[
            {
              percentage: `${progressWithNum.visualPartsPercentage}`,
              color: `${progressWithNum.visualPartsColor}`,
            },
          ]}
        />
      </div>
      {
         (basicLabel || to) && (
        <div className='d-flex flex-row justify-content-between w-100 mt-3'>
          {basicLabel && (
            <BasicLabel
              text={basicLabel.text}
              backgroundColor={basicLabel.backgroundColor}
              color={basicLabel.color}
            />
          )}
          {to && (
            <Link to={to} className='text-decoration-none align-self-end'>
              <AgPercentageCardText
                customClass='text-dark align-items-center'
                text={to ? intl.formatMessage({ id: 'GENERAL.DETAILS' }) : ''}
              />
            </Link>
          )}
          </div>
          )
      }
     
    </div>
  )
}

export {CardLabelandProgress}
