import {IconBase} from './Base'

export const IconProfile = ({color = '#273A51', size = 24}: {color?: string; size?: number}) => {
  const setColor = color
  return (
    <IconBase size={size} color={setColor} viewBox='0 0 14 14'>
      <svg
        width='14'
        height='14'
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M7.00496 8.5641C7.7281 8.5641 8.3386 8.31348 8.83646 7.81225C9.33432 7.31102 9.58325 6.69884 9.58325 5.9757C9.58325 5.25258 9.33263 4.64208 8.8314 4.14422C8.33018 3.64636 7.718 3.39743 6.99488 3.39743C6.27174 3.39743 5.66124 3.64804 5.16338 4.14927C4.66552 4.6505 4.41659 5.26268 4.41659 5.98581C4.41659 6.70895 4.6672 7.31945 5.16844 7.81731C5.66966 8.31517 6.28184 8.5641 7.00496 8.5641ZM2.01273 13.1667C1.67502 13.1667 1.39402 13.0545 1.16971 12.8302C0.945405 12.6059 0.833252 12.3249 0.833252 11.9872V2.01281C0.833252 1.6751 0.945405 1.39409 1.16971 1.16979C1.39402 0.945482 1.67502 0.833328 2.01273 0.833328H11.9871C12.3248 0.833328 12.6058 0.945482 12.8301 1.16979C13.0544 1.39409 13.1666 1.6751 13.1666 2.01281V11.9872C13.1666 12.3249 13.0544 12.6059 12.8301 12.8302C12.6058 13.0545 12.3248 13.1667 11.9871 13.1667H2.01273ZM1.85248 12.5H12.1474C12.2649 12.2735 12.345 12.1509 12.3877 12.1322C12.4305 12.1135 12.4679 11.9749 12.4999 11.7163C11.7083 11.0913 10.8437 10.6191 9.90617 10.2997C8.96867 9.98024 7.99992 9.82052 6.99992 9.82052C5.99992 9.82052 5.03117 9.98024 4.09367 10.2997C3.15617 10.6191 2.29159 11.0913 1.49992 11.7163C1.53197 11.9749 1.56937 12.1135 1.61211 12.1322C1.65483 12.1509 1.73495 12.2735 1.85248 12.5Z'
          fill='#212121'
        />
      </svg>
    </IconBase>
  )
}
