import { FontSizes } from "../../../../../app/constants/sizes"

type Props = {
  customClass?: string
  text: string
}
/**
 * Standard prototype of Aside Text "Category" | renders a div element with a specific font size and line height, and can have a custom class and text provided as properties.
 * @param customClass - to add extra classes
 * @param text - the text content
 * @constructor
 */
const AgCategory: React.FC<Props> = ({customClass, text}) => {
  /**
   * static css for the content
   */
  const css = {
    fontSize: FontSizes.SMALL.SIZE_2 //'0.95em',
  }

  return (
    <div className={`ff-prompt fw-normal ${customClass}`} style={css}>
      {text}
    </div>
  )
}

export {AgCategory}
