import styled from '@emotion/styled'
import ReactApexChart from 'react-apexcharts'
import {Button} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {abbreviateNumber} from '../../../../_gws/helpers/misc'
import {useState} from 'react'
import {CardChartHeader} from '../../salesReport/components/styled'
import Form from 'react-bootstrap/Form'

export const CardContainer = styled('div')({
  padding: '20px',
  borderRadius: '10px',
  boxShadow: '-1px 14px 19px -4px #919EAB33',
  minHeight: '450px',
})

export const ButtonGoTo = styled(Button)({
  background: '#0D7CBD !important',
  color: '#fff !important',
  width: '200px',
})

export const ChartIncome = ({data}: {data: any}) => {
  const actualMonth = new Date().getMonth() + 1
  const navigation = useNavigate()
  const intl = useIntl()
  const [dataDetail, setDataDetail] = useState(1)

  const MONTHS: Record<string, string> = {
    JAN: intl.formatMessage({id: 'GENERAL.MONTHS.JANUARY_SHORT'}),
    FEB: intl.formatMessage({id: 'GENERAL.MONTHS.FEBRUARY_SHORT'}),
    MAR: intl.formatMessage({id: 'GENERAL.MONTHS.MARCH_SHORT'}),
    APR: intl.formatMessage({id: 'GENERAL.MONTHS.APRIL_SHORT'}),
    MAY: intl.formatMessage({id: 'GENERAL.MONTHS.MAY_SHORT'}),
    JUN: intl.formatMessage({id: 'GENERAL.MONTHS.JUNE_SHORT'}),
    JUL: intl.formatMessage({id: 'GENERAL.MONTHS.JULY_SHORT'}),
    AUG: intl.formatMessage({id: 'GENERAL.MONTHS.AUGUST_SHORT'}),
    SEP: intl.formatMessage({id: 'GENERAL.MONTHS.SEPTEMBER_SHORT'}),
    OCT: intl.formatMessage({id: 'GENERAL.MONTHS.OCTOBER_SHORT'}),
    NOV: intl.formatMessage({id: 'GENERAL.MONTHS.NOVEMBER_SHORT'}),
    DEC: intl.formatMessage({id: 'GENERAL.MONTHS.DECEMBER_SHORT'}),
  }
  const tempMonths = Object.keys(data)
  const ordertemp = [...tempMonths.slice(actualMonth), ...tempMonths.slice(0, actualMonth)]
  const months = ordertemp.map((month: string) => MONTHS[month.toUpperCase()])

  const soldValues = ['sold'].map((label: any) => ({
    name: intl.formatMessage({id: `PRODUCT_HEALTH.CHART.LABEL.${label.toUpperCase()}`}),
    data: ordertemp.map((el: string) => {
      return data[el][label]
    }, []),
  }))

  const ordersValue = ['orders'].map((label: any) => ({
    name: intl.formatMessage({id: `PRODUCT_HEALTH.CHART.LABEL.${label.toUpperCase()}`}),
    data: ordertemp.map((el: string) => {
      return data[el][label]
    }, []),
  }))

  const handlerShowChart = (value: number) => setDataDetail(Number(value))

  const generateChartsOptions = ({
    xaxis,
    series,
    income,
  }: {
    xaxis: string[]
    series: any[]
    income: boolean
  }) => {
    return {
      series: series,
      options: {
        colors: income ? ['#F49302'] : ['#002F6D'],
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: '40%',
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: xaxis,
          tooltip: {
            enabled: false,
            offsetX: 0,
          },
        },
        yaxis: {
          labels: {
            formatter: (value: any) => {
              return income ? abbreviateNumber(value, 2) : value
            },
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            show: true,
            formatter: function (value: any) {
              return income ? '$' + abbreviateNumber(value, 2) : value
            },
          },
        },
      },
    }
  }

  const soldOptions: any = generateChartsOptions({
    xaxis: months,
    series: soldValues,
    income: true,
  })

  const ordersOptions: any = generateChartsOptions({
    xaxis: months,
    series: ordersValue,
    income: false,
  })

  return (
    <CardContainer>
      <CardChartHeader>
        <div>
          <h4>
            {dataDetail === 1
              ? intl.formatMessage({id: `PRODUCT_HEALTH.REVENUE_GENERATED`})
              : intl.formatMessage({id: `PRODUCT_HEALTH.ORDERS_GENERATED`})}
          </h4>
          <p>
            {dataDetail === 1
              ? intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.TOTAL_AMOUNT'})
              : intl.formatMessage({id: 'REPORT.LOGISTICS.ORDERS_QUANTITY'})}
          </p>
        </div>
        <Form.Select
          onChange={(e: any) => handlerShowChart(e.target.value)}
          value={dataDetail}
          size='sm'
          style={{width: '100px'}}
        >
          <option value='1'>
            {intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.TOTAL_AMOUNT'})}
          </option>
          <option value='2'>{intl.formatMessage({id: 'REPORT.LOGISTICS.ORDERS_QUANTITY'})}</option>
        </Form.Select>
      </CardChartHeader>
      {dataDetail === 1 ? (
        <ReactApexChart
          options={soldOptions.options}
          series={soldOptions.series}
          type='area'
          height={340}
        />
      ) : (
        <ReactApexChart
          options={ordersOptions.options}
          series={ordersOptions.series}
          type='area'
          height={340}
        />
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <ButtonGoTo onClick={() => navigation('/sales')} size='sm'>
          {intl.formatMessage({id: `PRODUCT_HEALTH.CARD_ACTION_REPORTS`})}
        </ButtonGoTo>
      </div>
    </CardContainer>
  )
}
