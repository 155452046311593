import React, {FC} from 'react'
import {useIntl} from 'react-intl'
type Props = {
  card: boolean
  arrayToCheck: any[] | undefined
  component: any
  text?: string
  customClass?: string
}
/**
 * Component to show a label when there is no data
 * @param arrayToCheck - array to check if it has data
 * @param card - if true, the label will be inside a card
 * @param component - component to be shown when there is data
 * @param text - message to be shown, but if it is not defined, the default message will be shown
 * @param customClass - string with styles
 */
const NoDataLabelCheck: FC<Props> = ({card, component, arrayToCheck, text, customClass}) => {
  const intl = useIntl()
  if (arrayToCheck === undefined || arrayToCheck.length === 0) {
    if (card) {
      return (
        <div className={`card ${customClass} bg-gray-300 h-100 rounded-1`}>
          <div className='card-body d-flex justify-content-center align-items-center p-0 m-0'>
            <p className={'m-0 p-0'}>
              {text ? text : intl.formatMessage({id: 'REPORT.LOGISTICS.NO_DATA'})}
            </p>
          </div>
        </div>
      )
    }
    return (
      <>
        <p> {text ? text : intl.formatMessage({id: 'REPORT.LOGISTICS.NO_DATA'})}</p>
      </>
    )
  }
  return component
}

export {NoDataLabelCheck}
