import {IconBase} from './Base'

export const IconBeeper = ({color = '#273A51', size = 24}: {color?: string; size?: number}) => {
  const setColor = color
  return (
    <IconBase size={size} color={setColor} viewBox='0 0 14 14'>
      <svg
        width='14'
        height='14'
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M4.69229 11.5256C4.33011 11.5256 4.02161 11.3982 3.76681 11.1434C3.51199 10.8886 3.38458 10.5801 3.38458 10.2179V10.0513H11.8429L12.3718 10.5801V3.99998H12.5384C12.9006 3.99998 13.2091 4.12738 13.4639 4.38219C13.7187 4.63699 13.8461 4.94549 13.8461 5.30767V13.7564L11.6154 11.5256H4.69229ZM0.153809 10.782V2.141C0.153809 1.77882 0.281211 1.47033 0.536017 1.21552C0.790836 0.960716 1.09934 0.833313 1.46152 0.833313H9.56408C9.92626 0.833313 10.2348 0.960716 10.4896 1.21552C10.7444 1.47033 10.8718 1.77882 10.8718 2.141V7.24356C10.8718 7.60574 10.7444 7.91424 10.4896 8.16904C10.2348 8.42386 9.92626 8.55127 9.56408 8.55127H2.38458L0.153809 10.782Z'
          fill='#212121'
        />
      </svg>
    </IconBase>
  )
}
