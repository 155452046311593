import React, {FC} from 'react'
import {BhGauge} from '../../../brandHealth/components/BHGauge'
import {StatisticsWidget7} from '../../../../../_gws/partials'
import {useIntl} from 'react-intl'
import {CampaignsTable} from '../../../paidMarketingReport/components/CampaignsTable'

type Props = {
  marketingData: any
}
const FrankieAds: FC<Props> = ({marketingData}) => {
  const intl = useIntl()
  return (
    <>
      <div className='row mt-5 mb-5'>
        <div className='col-12'>
          <h5 className='card-title align-items-start flex-column'>
            <span className='card-label fw-semibold fs-3 mb-1 ps-5'>KPI's</span>
          </h5>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-5'>
          <div className='row'>
            <div className='col-6 col-lg-6 col-xl-4 mb-5'>
              <StatisticsWidget7
                className={'card-xl-stretch'}
                iconColor={'dark'}
                titleColor={'dark'}
                descriptionColor={'dark'}
                title={marketingData.ctr}
                description={`${intl.formatMessage({id: 'REPORT.PAID_MARKETING.CTR'})} Global`}
                height={'150px'}
                countUp={true}
                decimal={true}
                metrix={'%'}
              />
            </div>

            <div className='col-6 col-lg-6 col-xl-4 mb-5'>
              <StatisticsWidget7
                className={'card-xl-stretch'}
                iconColor={'dark'}
                titleColor={'dark'}
                descriptionColor={'dark'}
                title={marketingData.impressions}
                description={`${intl.formatMessage({id: 'REPORT.PAID_MARKETING.IMPRESSIONS'})} `}
                height={'150px'}
                countUp={true}
              />
            </div>
            <div className='col-6 col-lg-6 col-xl-4  mb-5'>
              <StatisticsWidget7
                className={'card-xl-stretch'}
                iconColor={'dark'}
                titleColor={'dark'}
                descriptionColor={'dark'}
                title={marketingData.clicks}
                description={`${intl.formatMessage({id: 'REPORT.PAID_MARKETING.CLICKS'})} `}
                height={'150px'}
                countUp={true}
              />
            </div>
            <div className='col-6 col-lg-6 col-xl-4  mb-5'>
              <StatisticsWidget7
                className={'card-xl-stretch'}
                iconColor={'dark'}
                titleColor={'dark'}
                descriptionColor={'dark'}
                title={marketingData.roas}
                description={`${intl.formatMessage({id: 'REPORT.PAID_MARKETING.ROAS'})} `}
                height={'150px'}
                countUp={true}
                decimal={true}
              />
            </div>
            <div className='col-6 col-lg-6 col-xl-4  mb-5'>
              <StatisticsWidget7
                className={'card-xl-stretch'}
                iconColor={'dark'}
                titleColor={'dark'}
                descriptionColor={'dark'}
                title={marketingData.acos}
                description={`${intl.formatMessage({id: 'REPORT.PAID_MARKETING.ACOS'})} `}
                height={'150px'}
                countUp={true}
              />
            </div>
            <div className='col-6 col-lg-6 col-xl-4  mb-5'>
              <StatisticsWidget7
                className={'card-xl-stretch'}
                iconColor={'dark'}
                titleColor={'dark'}
                descriptionColor={'dark'}
                title={marketingData.cpc}
                description={`${intl.formatMessage({id: 'REPORT.PAID_MARKETING.CPC'})} `}
                height={'150px'}
                countUp={true}
                prefix={'$'}
              />
            </div>
          </div>
          <div className='row mt-1'>
            <div className='col mb-5'>
              <BhGauge
                percent={marketingData.conversion}
                title={`${intl.formatMessage({id: 'REPORT.PAID_MARKETING.CONVERSIONS'})}`}
                description={''}
                low={marketingData.conversion < 10}
              />
            </div>
          </div>
        </div>
        <div className='col-md-7'>
          <div className='card'>
            <div className='card-header border-0'>
              <h3 className='card-title fw-semibold text-dark'>
                {intl.formatMessage({id: 'REPORT.ACTIVE_CAMPAIGNS.TABLE_TITLE'})}
              </h3>
            </div>
            <div className='card-body pt-0'>
              <CampaignsTable dataReport={marketingData.campaignsList} frankie={true} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FrankieAds
