import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import {useIntl} from 'react-intl'

const CustomToggle = React.forwardRef(({children, onClick}: any, ref: any) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      padding: '10px',
      border: '1px solid #212121',
      borderRadius: '8px',
    }}
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
  >
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none'>
      <mask id='mask0_2144_157' maskUnits='userSpaceOnUse' x='0' y='0' width='18' height='18'>
        <rect width='18' height='18' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_2144_157)'>
        <path
          d='M10.125 6.75V3H15V6.75H10.125ZM3 9V3H7.875V9H3ZM10.125 15V9H15V15H10.125ZM3 15V11.25H7.875V15H3ZM3.75 8.25H7.125V3.75H3.75V8.25ZM10.875 14.25H14.25V9.75H10.875V14.25ZM10.875 6H14.25V3.75H10.875V6ZM3.75 14.25H7.125V12H3.75V14.25Z'
          fill='#1C1B1F'
        />
      </g>
    </svg>

    {children}
  </div>
))

export const FilterMonitorCategory = ({
  category,
}: {
  category: {
    value: string
    handlerCategory: any
  }
}) => {
  const intl = useIntl()
  const categoryOptions: Record<string, string> = {
    '9298576011': 'MONITOR.CATEGORY.BOOKS',
    '9482558011': 'MONITOR.CATEGORY.ELECTRONICS',
    '11260452011': 'MONITOR.CATEGORY.BEAUTY',
    '13848838011': 'MONITOR.CATEGORY.ACCESSORIES',
    '9482593011': 'MONITOR.CATEGORY.HOME',
  }
  const handlerChangeCtegory = (code: string) => category.handlerCategory(code)
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle}>
        {intl.formatMessage({id: 'PRODUCT_HEALTH.CATEGORY'})}:{' '}
        {intl.formatMessage({id: `${categoryOptions[category.value]}`})}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => handlerChangeCtegory('9482558011')}>
          {intl.formatMessage({id: 'MONITOR.CATEGORY.ELECTRONICS'})}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handlerChangeCtegory('11260452011')}>
          {intl.formatMessage({id: 'MONITOR.CATEGORY.BEAUTY'})}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handlerChangeCtegory('13848838011')}>
          {intl.formatMessage({id: 'MONITOR.CATEGORY.ACCESSORIES'})}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handlerChangeCtegory('9482593011')}>
          {intl.formatMessage({id: 'MONITOR.CATEGORY.HOME'})}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handlerChangeCtegory('9298576011')}>
          {intl.formatMessage({id: 'MONITOR.CATEGORY.BOOKS'})}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
