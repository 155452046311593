import { mkProp } from '../../modules/auth'
import {TableOrders} from '../stateOrdersReport/components/TableOrders'
import {OrdersIndicators} from './components/OrdersIndicators'
import {SalesReportCharts} from './components/SalesReportCharts'
import {CardTable, Container} from './components/styled'
import {SalesReport} from './core/types'

export const ReportSales = ({sales, currentMkp}: {sales: SalesReport, currentMkp: mkProp}) => {
  return (
    <Container>
      <div>
        <OrdersIndicators indicator={sales.orders.indicators} />
      </div>
      <div>
        <SalesReportCharts
          ordersDetail={sales.sales.orderStatus}
          monthlyDetail={sales.sales.orderMonthlyDetail}
          currentMkp={currentMkp}
        />
      </div>
      <CardTable>
        <TableOrders dataTable={sales.orders.items} />
      </CardTable>
    </Container>
  )
}
