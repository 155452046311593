import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_gws/layout/core'
import {useEffect} from 'react'
import {WaitingScreen} from '../../../_gws/partials/content/waiting/WaitingScreen'
import {HeaderReport} from '../../modules/apps/header/HeaderReport'
import {Diagnostik} from './Diagnostik'
import {useAuth} from '../../modules/auth'
import useSWR from 'swr'
import {getDiagnostikReport} from './getDiagnostikReport'
import {Splash} from '../../../_gws/helpers/misc'

export const DiagnostikWrapper = () => {
  const intl = useIntl()
  const {currentMkp, setCurrentMkp} = useAuth()
  const hasBeenVerified = localStorage.getItem('verified') === 'true'

  const swrOptions = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: false,
  }
  const {data: report, isLoading} = useSWR(
    () => (currentMkp ? ['/reports/diagnostik/', currentMkp] : null),
    () =>
      getDiagnostikReport({
        marketplace: currentMkp,
      }),
    {
      ...swrOptions,
    }
  )

  useEffect(() => {
    Splash(isLoading)
  }, [isLoading])

  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: intl.formatMessage({id: 'MENU.HOME'}),
            path: '/dashboard',
            isSeparator: false,
            isActive: false,
          },
          {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.DI.A.gnostik'})}
      </PageTitle>
      {hasBeenVerified ? (
        <>
          <HeaderReport
            hasMarketPlace={true}
            hasDate={false}
            hasFilter={false}
            setMkplace={setCurrentMkp}
          />
          {hasBeenVerified ? <>{report && <Diagnostik report={report} />}</> : <WaitingScreen />}
        </>
      ) : (
        <WaitingScreen />
      )}
    </>
  )
}
