/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ReactElement} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {addCommasInNumber, valueAndColor} from '../../../helpers/misc'
import {ProgressWithNum} from '../../newComponets/Progress'
import {AgText} from '../../../layout/components/ag/AgText'
import {useIntl} from 'react-intl'
import styled from '@emotion/styled'

type ItemIndicator = {
  name: string
  percentage: number | string
  unhealthy: number | string
  icon: ReactElement
  color: string
}

type IndicatorProps = {
  indicator: ItemIndicator
  expected: number
  lastUpdate: any
}

type Props = {
  title: string
  subTitle?: string
  valuePercent: number | string
  valueOverTen: number | string
  valueColor?: string
  height?: string
  tooltip?: string
  countUp?: boolean
  decimal?: boolean
  titleColor?: string
  backgroundColor?: string
  className?: string
  iconColor?: string
  svgIcon?: string
  expected?: number
  arrayColor?: any
  progressText?: string
  lastUpdate?: any
}

type IconProps = {
  bg: string
}
const Container = styled('div')({
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '-1px 10px 10px -4px #919EAB33',
})

const Header = styled('div')({
  display: 'flex',
})

const Icon = styled('div')<IconProps>(({bg}) => ({
  width: '80px',
  height: '80px',
  display: 'grid',
  placeItems: 'center',
  borderRadius: '8px',
  marginRight: '10px',
  background: `${bg}47`,
}))

const Content = styled('div')({
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
  p: {
    margin: '0px',
    fontWeight: 'bold',
    fontSize: '1.1rem',
    color: "#212121",
  },
})

const WrapperProgress = styled('div')({
  marginTop: "40px"
})

export const ProgressWidget: React.FC<Props> = ({
  backgroundColor,
  className,
  height,
  tooltip,
  titleColor,
  title,
  subTitle,
  iconColor,
  valueOverTen,
  valuePercent,
  svgIcon,
  expected,
  progressText,
}) => {
  const {value: percentage, color} = valueAndColor(valuePercent)
  return (
    <div
      className={`card bg-${backgroundColor} hoverable ${className} p-4 rounded-2`}
      style={{
        height: `${height}`,
      }}
    >
      <div className='card-body p-0 d-flex flex-column justify-content-between'>
        <div className='row'>
          <div
            className={`col d-flex flex-column ${
              svgIcon ? 'align-items-between justify-content-between' : 'justify-content-center'
            }`}
          >
            <div className={`fw-semibold text-start text-${titleColor}`}>
              <AgText choice={'titleCard'} text={title} customClass={'m-0'} />
              {subTitle && <AgText choice={'percentageCard'} text={subTitle} customClass={'m-0'} />}
              {tooltip && (
                <OverlayTrigger
                  placement='top'
                  overlay={<Tooltip id={`tooltip-top`}>{tooltip}</Tooltip>}
                  defaultShow={false}
                >
                  <div
                    className='symbol symbol-50px me-5'
                    data-balloon-pos='up'
                    aria-label={tooltip}
                  >
                    <span>
                      <KTSVG
                        path={toAbsoluteUrl('/media/icons/duotune/general/gen046.svg')}
                        className={`svg-icon-1x svg-icon-${iconColor}`}
                      />
                    </span>
                  </div>
                </OverlayTrigger>
              )}
            </div>
            <span className={''}>
              <AgText
                choice={'biggernumbercard'}
                text={addCommasInNumber(valueOverTen)}
                customClass={'d-inline-block'}
              />
              <AgText
                choice={'biggernumbercard'}
                text={`${expected ? `/${addCommasInNumber(expected)}` : ''}`}
                customClass={'d-inline-block'}
              />
            </span>
          </div>
        </div>
        <div className='row '>
          <div className='col '>
            <ProgressWithNum
              labelColor={`${color}`}
              backgroundColor={'light'}
              visualParts={[
                {
                  percentage: `${percentage}%`,
                  color: `${color}`,
                },
              ]}
            />

            {progressText && (
              <AgText
                choice={'percentageTitle'}
                text={progressText}
                customClass={'text-gray-500 pt-1'}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export const ProgressIndicator: React.FC<IndicatorProps> = ({indicator, expected, lastUpdate}) => {
  const intl = useIntl()
  const {value: percentage, color} = valueAndColor(indicator.percentage)
  return (
    <Container>
      <Header>
        <Icon bg={color}>{indicator.icon}</Icon>
        <Content>
          <p>{indicator.name}</p>
          <p>
            {addCommasInNumber(indicator.unhealthy)}
            {`/${addCommasInNumber(expected)}`}
          </p>
          <p style={{color: '#9E9E9E', fontWeight: "normal"}}>
            {`${intl.formatMessage({
              id: 'BRAND_HEALTH.LAST.UPDATE',
            })}:  ${lastUpdate}`}
          </p>
        </Content>
      </Header>
      <WrapperProgress>
        <ProgressWithNum
          labelColor={`${color}`}
          backgroundColor={color}
          visualParts={[
            {
              percentage: `${percentage}%`,
              color: `${color}`,
            },
          ]}
        />
      </WrapperProgress>
    </Container>
  )
}
