import {Action, IndexedOutputs, MkpValues, Output, PriorityValues, State} from './types'

export const priorityValues: PriorityValues[] = [
  {
    value: 'critique',
    name: 'Critique',
  },
  {
    value: 'delicate',
    name: 'Delicate',
  },
  {
    value: 'good',
    name: 'Good',
  },
  {
    value: 'optimal',
    name: 'Optimal',
  },
]

export const mkpValues: MkpValues[] = [
  {
    value: 'amazon',
    name: 'Amazon',
  },
  {
    value: 'meli',
    name: 'Mercado libre',
  },
]

export const initialState: State = {
  outputs: [],
  output: '0',
  priority: 'critique',
  mkp: 'amazon',
  content: '',
  change: false,
  indexedOutputs: {},
  showCreateOutput: false,
  newOutput: {
    id: 159357,
    output: 'N/A',
    title: 'N/A',
    amazon: {
      critique: 'N/A',
      delicate: 'N/A',
      good: 'N/A',
      optimal: 'N/A',
    },
    meli: {
      critique: 'N/A',
      delicate: 'N/A',
      good: 'N/A',
      optimal: 'N/A',
    },
  },
}

export const enum ActionTypes {
  SET_STATE = 'SET_STATE',
  CHANGE_CONTENT = 'CHANGE_CONTENT',
  RESET_STATE = 'RESET_STATE',
  SET_OUTPUT = 'SET_OUTPUT',
  SET_MKP = 'SET_MKP',
  SET_PRIORITY = 'SET_PRIORITY',
  HANDLER_MODAL = 'HANDLER_MODAL',
  HANDLER_CHANGE_NEW_OUTPUT = 'HANDLER_CHANGE_NEW_OUTPUT',
  SET_NEW_OUTPUT = 'SET_NEW_OUTPUT',
}

export const PriorityKeys = {
  critique: 1,
  delicate: 2,
  good: 3,
  optimal: 4,
}

export const MkpKeys = {
  amazon: 1,
  meli: 2,
}

export const indexedOutputs = (arr: any[]) =>
  arr.reduce((acc: IndexedOutputs, el: Output) => {
    if (el.id !== null) {
      acc[el.id] = el
      return acc
    }
    return acc
  }, {})

const setInitialState = (state: State, {payload}: Action) => ({
  ...state,
  outputs: payload.outputs,
  output: '0',
  priority: 'critique',
  mkp: 'amazon',
  content: payload.indexed[state.output][state.mkp][state.priority],
  change: false,
  indexedOutputs: payload.indexed,
})

const handlerChangeMD = (state: State, {payload}: Action) => ({
  ...state,
  content: payload,
  change: true,
})

const resetContentMD = (state: State) => ({
  ...state,
  output: '0',
  priority: 'critique',
  mkp: 'amazon',
  content: state.indexedOutputs['0']['amazon']['critique'],
  change: false,
})

const changeOuputMD = (state: State, {payload}: Action) => ({
  ...state,
  output: payload,
  content: state.indexedOutputs[payload][state.mkp][state.priority],
})

const changeMKP = (state: State, {payload}: Action) => {
  const mkp = payload as 'amazon' | 'meli'
  return {
    ...state,
    mkp: payload,
    content: state.indexedOutputs[state.output][mkp][state.priority],
  }
}

const changePriority = (state: State, {payload}: Action) => {
  const priority = payload as 'critique' | 'delicate' | 'good' | 'optimal'
  return {
    ...state,
    priority: payload,
    content: state.indexedOutputs[state.output][state.mkp][priority],
  }
}

const changeNewOutput = (state: State, {payload}: Action) => ({
  ...state,
  newOutput: {
    ...state.newOutput,
    [payload.key]: payload.content,
  },
})

const handlerShowCreateOutput = (state: State) => ({
  ...state,
  showCreateOutput: !state.showCreateOutput,
})

const setNewOutput = (state: State) => {
  const addedOutput = [...state.outputs, state.newOutput]
  const newIndexedOutputs = indexedOutputs(addedOutput)
  return {
    ...state,
    indexedOutputs: newIndexedOutputs,
    outputs: addedOutput,
    output: state.newOutput.id.toString(),
    showCreateOutput: false,
    content: newIndexedOutputs[state.newOutput.id.toString()][state.mkp][state.priority],
  }
}

const actionMap = new Map<string, any>([
  [ActionTypes.SET_STATE, setInitialState],
  [ActionTypes.CHANGE_CONTENT, handlerChangeMD],
  [ActionTypes.RESET_STATE, resetContentMD],
  [ActionTypes.SET_OUTPUT, changeOuputMD],
  [ActionTypes.SET_MKP, changeMKP],
  [ActionTypes.SET_PRIORITY, changePriority],
  [ActionTypes.HANDLER_MODAL, handlerShowCreateOutput],
  [ActionTypes.HANDLER_CHANGE_NEW_OUTPUT, changeNewOutput],
  [ActionTypes.SET_NEW_OUTPUT, setNewOutput],
])

export const outputsEditorReducer = (state: State, action: Action) => {
  const typeToUse = action.type ? action.type : ActionTypes.SET_STATE
  const mappedActions = actionMap.get(typeToUse)
  return mappedActions ? mappedActions(state, action) : state
}
