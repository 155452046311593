import {getFixedDecimals} from '../../../_gws/helpers/misc'
import {mkProp} from '../../modules/auth'
import {getDiagnostikData} from './core/_request'
import {DiagnosticReport, ObjectData} from './core/types'

const ordermonthlyData = (data: ObjectData) => {
  const keys = Object.keys(data).sort((a: any, b: any) => {
    const months = [
      'jan',
      'feb',
      'mar',
      'apr',
      'may',
      'jun',
      'jul',
      'aug',
      'sep',
      'oct',
      'nov',
      'dic',
    ]
    return months.indexOf(a) - months.indexOf(b)
  })
  const reorder: ObjectData = {}
  keys.forEach((key) => {
    reorder[key] = data[key]
  })
  return reorder
}

const structureAreaChartDetail = (data: ObjectData, property: string) => {
  const values = []
  const xaxis = []
  for (const key in data) {
    xaxis.push(key)
    values.push(getFixedDecimals(data[key][property]))
  }
  return {
    values,
    xaxis,
  }
}

export const getDiagnostikReport = async ({marketplace}: {marketplace: mkProp}) => {
  if (!marketplace || marketplace === 'growker' || marketplace === 'meli') return
  const [data] = await Promise.all([getDiagnostikData()])

  const inventoryData = data.data.inventory.amazon.monthly
  const salesData = data.data.sales.amazon
  const marketingData = data.data.marketing.amazon.monthly

  const inventoryActiveProducts = structureAreaChartDetail(
    ordermonthlyData(inventoryData),
    'unique_products'
  )
  const inventoryOutOfStock = structureAreaChartDetail(
    ordermonthlyData(inventoryData),
    'out_of_stock_products'
  )

  const marketingClicks = structureAreaChartDetail(ordermonthlyData(marketingData), 'clicks')
  const marketingConvertions = structureAreaChartDetail(
    ordermonthlyData(marketingData),
    'conversions'
  )
  const marketingImpressions = structureAreaChartDetail(
    ordermonthlyData(marketingData),
    'impressions'
  )

  const salesOrdersShipped = structureAreaChartDetail(
    ordermonthlyData(salesData.shipped.monthly),
    'orders'
  )
  const salesOrderspending = structureAreaChartDetail(
    ordermonthlyData(salesData.pending.monthly),
    'orders'
  )
  const salesOrderscancelled = structureAreaChartDetail(
    ordermonthlyData(salesData.cancelled.monthly),
    'orders'
  )

  const salesSolsShipped = structureAreaChartDetail(
    ordermonthlyData(salesData.shipped.monthly),
    'sold'
  )
  const salesSolspending = structureAreaChartDetail(
    ordermonthlyData(salesData.pending.monthly),
    'sold'
  )
  const salesSolscancelled = structureAreaChartDetail(
    ordermonthlyData(salesData.cancelled.monthly),
    'sold'
  )

  const report: DiagnosticReport = {
    inventory: {
      series: [
        {
          name: 'REPORT.FRANKIE.INVENTORY.PRODUCT.UNIQUE',
          data: inventoryActiveProducts.values,
          color: '#46A4B3',
        },
        {
          name: 'REPORT.FRANKIE.INVENTORY.NO_STOCK',
          data: inventoryOutOfStock.values,
          color: '#0DBDA6',
        },
      ],
      xaxis: inventoryActiveProducts.xaxis,
    },
    marketing: {
      series: [
        {
          name: 'Click',
          data: marketingClicks.values,
          color: '#46A4B3',
        },
        {
          name: 'Conversiones',
          data: marketingConvertions.values,
          color: '#0DBDA6',
        },
      ],
      xaxis: marketingClicks.xaxis,
      impressions: {
        series: [
          {
            name: 'Impresiones',
            data: marketingImpressions.values,
            color: '#46A4B3',
          },
        ],
        xaxis: marketingClicks.xaxis,
      },
    },
    sales: {
      orders: {
        series: [
          {
            name: 'shipped',
            data: salesOrdersShipped.values,
            color: '#46A4B3',
          },
          {
            name: 'pending',
            data: salesOrderspending.values,
            color: '#0DBDA6',
          },
          {
            name: 'cancelled',
            data: salesOrderscancelled.values,
            color: '#0DBDA6',
          },
        ],
      },
      sold: {
        series: [
          {
            name: 'shipped',
            data: salesSolsShipped.values,
            color: '#46A4B3',
          },
          {
            name: 'pending',
            data: salesSolspending.values,
            color: '#0DBDA6',
          },
          {
            name: 'cancelled',
            data: salesSolscancelled.values,
            color: '#0DBDA6',
          },
        ],
      },
      xaxis: salesOrdersShipped.xaxis,
    },
  }
  console.log(data)
  return report
}
