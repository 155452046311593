import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {ResultsCard} from '../../../../../_gws/partials/widgets/statistics/ResultsCard'

type totalRoles = {
  totalRoles: {
    admin: number,
    client: number,
    merchant: number
  }
}

const TopCardsContainer: FC<totalRoles> = ({totalRoles}) => {
  const intl = useIntl()

  const totalUsers = totalRoles.admin + totalRoles.client + totalRoles.merchant

  return (
    <>
      <div className='row my-3 mb-5'>
        <div className='col-md-3 mt-3'>
          <ResultsCard
            className={'card-xl-stretch'}
            color={'card'}
            titleColor={'dark'}
            descriptionColor={'dark'}
            title={`${totalUsers}`}
            description={intl.formatMessage({id: 'USER_MNGT.TOP.ALLUSERS'})}
            height={'130px'}
            countUp={true}
            decimal={false}
          />
        </div>
        <div className='col-md-3 mt-3'>
          <ResultsCard
            className={'card-xl-stretch'}
            color={'card'}
            titleColor={'dark'}
            descriptionColor={'dark'}
            title={`${totalRoles.admin}`}
            description={` ${intl.formatMessage({
              id: 'USER_MNGT.TOP.ALLUSERS',
            })} ${intl.formatMessage({
              id: 'USER_MNGT.TABLE.HEAD.ROLE.2',
            })}`}
            height={'130px'}
            countUp={true}
            decimal={false}
          />
        </div>
        <div className='col-md-3 mt-3'>
          <ResultsCard
            className={'card-xl-stretch'}
            color={'card'}
            titleColor={'dark'}
            descriptionColor={'dark'}
            title={`${totalRoles.client}`}
            description={` ${intl.formatMessage({
              id: 'USER_MNGT.TOP.ALLUSERS',
            })} ${intl.formatMessage({
              id: 'USER_MNGT.TABLE.HEAD.ROLE.1',
            })}`}
            height={'130px'}
            countUp={true}
            decimal={false}
          />
        </div>
        <div className='col-md-3 mt-3'>
          <ResultsCard
            className={'card-xl-stretch'}
            color={'card'}
            titleColor={'dark'}
            descriptionColor={'dark'}
            title={`${totalRoles.merchant}`}
            description={` ${intl.formatMessage({
              id: 'USER_MNGT.TOP.ALLUSERS',
            })} ${intl.formatMessage({
              id: 'USER_MNGT.TABLE.HEAD.ROLE.3',
            })}`}
            height={'130px'}
            countUp={true}
            decimal={false}
          />
        </div>
      </div>
    </>
  )
}

export {TopCardsContainer}
