import styled from '@emotion/styled'

export const Container = styled('div')({})

export const Content = styled('div')({})

export const WrapperLayoud = styled('div')({
  display: "grid",
  gridTemplateColumns: "1.9fr 1fr",
  gap: "1rem",
})

export const ContentTopWrapper = styled('div')({
  display: "grid",
  gridTemplateColumns: "2fr 1fr",
  margin: "20px 0px",
  gap: "1rem",
  "@media (max-width: 500px)": {
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr 1fr",
  }
})

export const ContentDownWrapper = styled('div')({
  display: "grid",
  gridTemplateColumns: "2fr 1fr",
  margin: "20px 0px",
  gap: "1rem",
  "@media (max-width: 500px)": {
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr 1fr",
    height: "initial",
  }
})

export const ContainerBanner = styled('div')({})

export const ContainerSales = styled('div')({})

export const ContainerPlaylist = styled('div')({})

export const ContainerBrand = styled('div')({})
