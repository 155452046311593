import ReactApexChart from 'react-apexcharts'
import {CardChart, CardChartHeader, ContainerCharts} from './styled'
import {ModelOrderStatusDetail, ModelReportMonthlyDetail} from '../core/types'
import {useIntl} from 'react-intl'
import Form from 'react-bootstrap/Form'
import {useState} from 'react'
import {abbreviateNumber} from '../../../../_gws/helpers/misc'
import {mkProp} from '../../../modules/auth'
import {MARKETPLACES} from '../../../constants/marketplaces'

export const SalesReportCharts = ({
  monthlyDetail,
  ordersDetail,
  currentMkp,
}: {
  monthlyDetail: ModelReportMonthlyDetail
  ordersDetail: ModelOrderStatusDetail
  currentMkp: mkProp
}) => {
  const intl = useIntl()
  const [dataDetail, setDataDetail] = useState(1)
  monthlyDetail.orders.series[0].name = intl.formatMessage({id: 'REPORT.SALES.ORDERS'})
  monthlyDetail.profit.series[0].name = intl.formatMessage({id: 'REPORT.SALES.PROFIT'})

  const handlerShowChart = (value: number) => setDataDetail(Number(value))

  const generateChartsOptions = ({
    xaxis,
    series,
    income,
  }: {
    xaxis: string[]
    series: any[]
    income: boolean
  }) => {
    return {
      series: series,
      options: {
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: '40%',
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: xaxis,
        },
        yaxis: {
          labels: {
            formatter: (value: any) => {
              return abbreviateNumber(value, 2)
            },
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            show: true,
            formatter: function (value: any, {w}: any) {
              return w.config.series[0].name === intl.formatMessage({id: 'REPORT.SALES.PROFIT'})
                ? '$' + abbreviateNumber(value, 2)
                : abbreviateNumber(value, 2)
            },
          },
        },
      },
    }
  }

  const ordersOptions: any = generateChartsOptions({
    xaxis: monthlyDetail.orders.categories,
    series: monthlyDetail.orders.series,
    income: false,
  })

  const profitOptions: any = generateChartsOptions({
    xaxis: monthlyDetail.profit.categories,
    series: monthlyDetail.profit.series,
    income: true,
  })

  const ordersDeepCopy = JSON.stringify(ordersDetail)
  const ordersDetailFormat = JSON.parse(ordersDeepCopy)
  ordersDetail.forEach((element, i) => {
    ordersDetailFormat[i].x = intl.formatMessage({
      id: `REPORT.SALES.CHART.STATE.${element.x.toUpperCase()}`,
    })
  })

  const optionsTree: any = {
    series: [
      {
        data: ordersDetailFormat,
        color: currentMkp === MARKETPLACES.AMAZON.name ? '#F5C57C' : '#7B94B3',
      },
    ],
    options: {
      legend: {
        show: false,
      },
      chart: {
        height: 350,
        type: 'treemap',
        toolbar: {
          show: false,
        },
      },
    },
  }
  return (
    <ContainerCharts>
      <CardChart>
        <CardChartHeader>
          <div>
            <h4>{intl.formatMessage({id: 'REPORT.SALES.MONTHLY_DETAILS'})}</h4>
            <p>
              {dataDetail === 1
                ? intl.formatMessage({id: 'REPORT.LOGISTICS.ORDERS_QUANTITY'})
                : intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.TOTAL_AMOUNT'})}
            </p>
          </div>
          <Form.Select
            onChange={(e: any) => handlerShowChart(e.target.value)}
            value={dataDetail}
            size='sm'
            style={{width: '100px'}}
          >
            <option value='1'>
              {intl.formatMessage({id: 'REPORT.LOGISTICS.ORDERS_QUANTITY'})}
            </option>
            <option value='2'>
              {intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.TOTAL_AMOUNT'})}
            </option>
          </Form.Select>
        </CardChartHeader>
        {dataDetail === 1 ? (
          <ReactApexChart
            options={ordersOptions.options}
            series={ordersOptions.series}
            type='bar'
            height={350}
          />
        ) : (
          <ReactApexChart
            options={profitOptions.options}
            series={profitOptions.series}
            type='bar'
            height={350}
          />
        )}
      </CardChart>
      <CardChart>
        <h4>{intl.formatMessage({id: 'REPORT.SALES.ORDER_STATUS'})}</h4>
        <p>{intl.formatMessage({id: 'REPORT.SALES.ORDER_STATUS_DESCRIPTION'})}</p>
        <ReactApexChart
          options={optionsTree.options}
          series={optionsTree.series}
          type='treemap'
          height={350}
        />
      </CardChart>
    </ContainerCharts>
  )
}
