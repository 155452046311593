import React, {FC} from 'react'
import {AgPercentageCardText} from '../../../layout/components/ag/components/AgPercentageCardText'
import {ProgressComponent} from './ProgressComponent'
import CountUp from 'react-countup'

type Props = {
  among: string
  text: string
  labelColor: string
  backgroundColor: string
  visualParts: [
    {
      percentage: string
      color: string
    }
  ],
  fontSize?: string
}

/**
 * This Component create a Dinamic Progress component with number and text
 *
 * @param among - among (string) for label section
 * @param text - string with the text for label section
 * @param labelColor - color for label section (var sass)
 * @param backgroundColor - background base Color for the progress section.(var sass)
 * @param color - color for text-component (var sass)
 * @param visualParts - array with percentage and color (var sass) for progress active section
 */

const ProgressWithNumText: FC<Props> = ({
  among,
  text,
  labelColor,
  backgroundColor,
  visualParts,
  fontSize = 'fs-9',
}) => {
  const labelStyles = {
    width: 'fit-content',
    flexShrink: 0,
  }

  return (
    <div>
      <div className='d-flex justify-content-start align-items-end mb-2 gap-3'>
        <div
          className={`bg-${labelColor} py-1 px-2 border border-none rounded`}
          style={labelStyles}
        >
          <CountUp
            className={`ff-prompt fw-normal ${fontSize}`}
            start={0}
            end={Number(among)}
            duration={2}
          />
        </div>
        <div>
          <AgPercentageCardText customClass='' text={text} />
        </div>
      </div>
      <ProgressComponent backgroundColor={backgroundColor} visualParts={visualParts} />
    </div>
  )
}

export {ProgressWithNumText}
