import axios from 'axios'
import {getTokenHeader} from '../../../modules/auth'

const API_URL_CRUNCH = process.env.REACT_APP_BACKEND_MAIN_API

export const getDiagnostikData = () => {
  const head = getTokenHeader()
  const cleanAxios = axios.create()
  return cleanAxios.get(
    `${API_URL_CRUNCH}/api/diagnostik/`,
    // @ts-ignore
    head
  )
}

