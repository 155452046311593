import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
import {useIntl} from 'react-intl'
import { SignUp } from './components/SignUp'

const RECAPTCHA_KEY =
  process.env.REACT_APP_RECAPTCHA_KEY === undefined ? '' : process.env.REACT_APP_RECAPTCHA_KEY

/**
 * Wrapper to handle ReCaptcha in Login Form
 * Setup for the ReCaptcha Provider
 * @component
 */
const SignUpWrapper = () => {
  const intl = useIntl()

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={RECAPTCHA_KEY}
      language={intl.locale}
      scriptProps={{async: true}}
    >
      <SignUp/>
    </GoogleReCaptchaProvider>
  )
}
export {SignUpWrapper}
