import {ReactElement} from 'react'

import {IndicatorCard, IndicatorsWrapper} from './styled'
import {useIntl} from 'react-intl'
import {addCommasInNumber} from '../../../../_gws/helpers/misc'
import {
  IconDiscount,
  IconOrders,
  IconProducts,
  IconSalesShipping,
  IconTotalSales,
} from './SalesIcon'

type Indicator = {
  orderQty: number
  itemsQty: number
  shipmentAmount: number
  discountAmount: number
  profitAmount: number
}

type KeyIndicator = 'orderQty' | 'itemsQty' | 'shipmentAmount' | 'discountAmount' | 'profitAmount'

type ItemIndicator = {
  value: number
  key: KeyIndicator
  background: string
  icon: ReactElement
  label: string
}

type IndicatorFieldType = {
  background: string
  icon: ReactElement
  label: string
}

export const OrdersIndicators = ({indicator}: {indicator: Indicator}) => {
  const intl = useIntl()
  const IndicatorField: Record<KeyIndicator, IndicatorFieldType> = {
    orderQty: {
      background: '#E2EBD3',
      icon: <IconOrders />,
      label: intl.formatMessage({id: 'REPORT.SALES.ORDERS_INDICATOR.ORFERQTY'}),
    },
    itemsQty: {
      background: '#CBF3F9',
      icon: <IconProducts />,
      label: intl.formatMessage({id: 'REPORT.SALES.ORDERS_INDICATOR.ITEMSQTY'}),
    },
    shipmentAmount: {
      background: '#CEE6E3',
      icon: <IconSalesShipping />,
      label: intl.formatMessage({id: 'REPORT.SALES.ORDERS_INDICATOR.SHIPMENTAMOUNT'}),
    },
    discountAmount: {
      background: '#E2EBD3',
      icon: <IconDiscount />,
      label: intl.formatMessage({id: 'REPORT.SALES.ORDERS_INDICATOR.DISCOUNTAMOUNT'}),
    },
    profitAmount: {
      background: '#E6FBFF',
      icon: <IconTotalSales />,
      label: intl.formatMessage({id: 'REPORT.SALES.ORDERS_INDICATOR.PROFITAMOUNT'}),
    },
  }
  const keys = Object.keys(indicator) as KeyIndicator[]
  const indicators = keys.reduce((acc: ItemIndicator[], el: KeyIndicator) => {
    acc.push({
      value: indicator[el],
      key: el,
      background: IndicatorField[el].background,
      icon: IndicatorField[el].icon,
      label: IndicatorField[el].label,
    })
    return acc
  }, [])
  return (
    <IndicatorsWrapper>
      {indicators.map((item, i) => (
        <IndicatorCard key={i} bg={item.background}>
          {item.icon}
          <div>
            <h6>{item.label}</h6>
            <p>
              {item.key === 'profitAmount' ||
              item.key === 'shipmentAmount' ||
              item.key === 'discountAmount'
                ? `$${addCommasInNumber(Number(item.value.toFixed(2)))}`
                : addCommasInNumber(item.value)}
            </p>
          </div>
        </IndicatorCard>
      ))}
    </IndicatorsWrapper>
  )
}
