import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'

const SalesAside = () => {
  const intl = useIntl()
  return (
    <>
      <AsideMenuItemWithSub
        listTo={['sales', 'logistics', 'results']}
        icon='/media/icons/gws-icons/aside-sales.svg'
        title={intl.formatMessage({id: 'MENU.REPORTS.SALES'})}
      >
        {/*ventas*/}

        <AsideMenuItem
          to='/sales'
          title={intl.formatMessage({id: 'MENU.REPORTS.SALES'})}
          hasBullet={true}
        />

        {/*historico de ventas*/}
        {/*      <AsideMenuItem*/}
        {/*        to='/history'*/}
        {/*        title={intl.formatMessage({id: 'MENU.REPORTS.HISTORY'})}*/}
        {/*        hasBullet={true}*/}
        {/*      />*/}
        {/*gestion de envíos*/}
        <AsideMenuItem
          to='/logistics'
          title={intl.formatMessage({id: 'MENU.REPORT.LOGISTICS'})}
          hasBullet={true}
        />
        {/*resultado de órdenes*/}
        <AsideMenuItem
          to='/results'
          title={intl.formatMessage({id: 'MENU.REPORT.STATE_RESULTS'})}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
    </>
  )
}

export {SalesAside}
