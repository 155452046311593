import {useState} from 'react'
import CloseButton from 'react-bootstrap/CloseButton'
import Modal from 'react-bootstrap/Modal'
import {AgText} from '../../../../../../_gws/layout/components/ag/AgText'
import {OriginType, PriorityType} from '../../../types'
import {makeURL} from '../../../../../../_gws/helpers/misc'
import {useIntl} from 'react-intl'
import MDEditor from '@uiw/react-md-editor'
import parse from 'html-react-parser'
import styled from '@emotion/styled'

const Btn = styled('button')({
  height: '100%',
  width: '100%',
  background: 'transparent',
  cursor: 'pointer',
  border: 'none',
  padding: '0px',
  textAlign: 'start',
})

const getTruncatedMessage = (message: string) => {
  const words = message.trim().split(/\s+/)
  return words.join(' ').substring(0, 10)
}

const priorityKeys: any = {
  1: 'CRITIQUE',
  2: 'DELICATE',
  3: 'GOOD',
  0: 'OPTIMAL',
}

const ShowMessage = ({
  showModal,
  handlerClouse,
  message,
  title,
}: {
  showModal: boolean
  handlerClouse: any
  message: string
  title: string
}) => {
  return (
    <Modal centered show={showModal}>
      <Modal.Header className={'justify-content-between px-10 py-5'}>
        <h4>{title}</h4>
        <CloseButton onClick={handlerClouse} />
      </Modal.Header>
      <Modal.Body className='px-10 py-5'>{message}</Modal.Body>
    </Modal>
  )
}

const ShowOutputDescription = ({
  showModal,
  handlerClouse,
  message,
  externalLink,
}: {
  showModal: boolean
  handlerClouse: any
  message: string
  externalLink: string | undefined
}) => {
  return (
    <Modal size='lg' centered show={showModal}>
      <Modal.Header className={'justify-content-between px-10 py-5'}>
        <h4>Producto: {externalLink && parse(externalLink)} </h4>
        <CloseButton onClick={handlerClouse} />
      </Modal.Header>
      <Modal.Body className='px-10 py-5'>
        <div data-color-mode='light'>
          <MDEditor.Markdown source={message} />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export const RenderCustomDescription = ({description}: {description: string}) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div>
      <Btn
        className='p-0 m-0 d-inline'
        onClick={() => setIsOpen(true)}
      >
        <AgText
          customClass='text-gray-900 d-inline-block'
          choice={'small'}
          text={getTruncatedMessage(description)}
        ></AgText>
      </Btn>
      <ShowMessage
        showModal={isOpen}
        handlerClouse={() => setIsOpen((pre) => !pre)}
        message={description}
        title='Descripción'
      />
    </div>
  )
}

export const RenderOutputDescription = ({
  description,
  marketplaceId,
  item,
  priority,
  type,
}: {
  description: string
  marketplaceId: number
  item: string
  priority: PriorityType
  type: number
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const intl = useIntl()
  let externalLink = makeURL(description, marketplaceId, item)

  const formatPrefix = intl.formatMessage({
    id: `PLAYLIST.TABLE.DESCRIPTION.TYPE_${type}.PRIO_${priority}.MKP_${marketplaceId}.PREFIX.${priorityKeys[priority]}`,
  })
  const formatSuffix = intl.formatMessage({
    id: `PLAYLIST.TABLE.DESCRIPTION.TYPE_${type}.PRIO_${priority}.MKP_${marketplaceId}.SUFFIX.${priorityKeys[priority]}`,
  })
  const prefix =
    formatPrefix === 'N/A' ? `N/A -> type ${type} - priority ${priority}` : formatPrefix
  const suffix = formatSuffix === 'N/A' ? '' : formatSuffix

  const suffixFormated = {
    suffix: suffix.length > 80 ? suffix.slice(0, 80) + '...' : suffix,
    isLarge: suffix.length > 80 ? true : false,
  }
  // const productHealthUrl = `/product-health/${MKP[marketplaceId]}/${item}`

  const TEMPLATE = `
  ${prefix}${suffix}
  `
  return (
    <div>
      <Btn onClick={() => setIsOpen(true)}>{suffixFormated.suffix}</Btn>
      <ShowOutputDescription
        showModal={isOpen}
        handlerClouse={() => setIsOpen((pre) => !pre)}
        message={TEMPLATE}
        externalLink={externalLink}
      />
    </div>
  )
}

export const RenderDescription = ({
  description,
  type,
  marketplaceId,
  item,
  priority,
  reference,
}: {
  description: string
  type: OriginType
  marketplaceId: number
  item: string
  priority: PriorityType
  reference: number
}) => {
  return (
    <>
      {type === 'CUSTOM' ? (
        <RenderCustomDescription description={description} />
      ) : (
        <RenderOutputDescription
          description={description}
          marketplaceId={marketplaceId}
          item={item}
          priority={priority}
          type={reference}
        />
      )}
    </>
  )
}
