import React, {FC} from 'react'
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'
import {ToolkitContextType} from 'react-bootstrap-table2-toolkit'
import {CustomSearchForm} from '../../../../../_gws/partials/tables/CustomSearchForm'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../modules/auth'
import {KTSVG} from '../../../../../_gws/helpers'
import {UserEditModal} from '../../../../modules/apps/user-management/users-list/modals/UserEditModal'
import {useListView} from '../../../../modules/apps/user-management/users-list/core/ListViewProvider'

type Props = {
  listClient: any
  columns: any
  optionsTable: any
}

/**
 * Bootstrap Table component
 * Showing Clients of Merchants
 * @param listClient
 * @param columns
 * @param optionsTable
 * @component
 */
const TableClient: FC<Props> = ({listClient, columns, optionsTable}) => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  // @ts-ignore
  const showAdmin: boolean | undefined =
    // @ts-ignore
    currentUser.canSeeUserAdmin === undefined ? false : currentUser.canSeeUserAdmin
  /**
   * Needed to show "New User" modal
   */
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <>
      {itemIdForUpdate !== undefined && <UserEditModal height={66} />}

      <div className='card'>
        <div className='card-header cursor-pointer border-0'>
          <h3 className='card-title m-0 align-items-start flex-column'>
            <span className='card-label fw-bolder m-0'>
              {intl.formatMessage({id: 'MENU.DASHBOARD.TABLE.TITLE'})}
            </span>
          </h3>

          {/*// @ts-ignore*/}
          {showAdmin && (
            <button
              type='button'
              className='py-3 px-5 border-0 mb-5 mt-5 text-primary bg-black bg-hover-opacity-75 rounded-pixel shadow-login-btn cursor-pointer'
              onClick={openAddUserModal}
            >
              <KTSVG
                path='/media/icons/unicos/053-add.svg'
                className='svg-icon-1x svg-icon-primary me-1'
              />
              {intl.formatMessage({id: 'USER_MNGT.ACTION.BUTTON.CREATE'})}
            </button>
          )}
        </div>
        <div className='card-body pt-0'>
          <ToolkitProvider columns={columns} data={listClient} keyField={'id'} search>
            {(props: ToolkitContextType) => (
              <div>
                <CustomSearchForm
                  placeholder={intl.formatMessage({id: 'USER_MNGT.TABLE.HEAD.SEARCH_TERM'})}
                  {...props.searchProps}
                />
                <div className='separator separator-dotted my-5'></div>
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4={true}
                  hover={true}
                  pagination={paginationFactory(optionsTable)}
                  striped={false}
                  classes={'table gy-2 gs-2'}
                  wrapperClasses={'table-responsive position-relative mb-5'}
                  bodyClasses={'text-gray-600'}
                  rowClasses={'align-middle'}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
      </div>
    </>
  )
}

export default TableClient
