import {filter} from './core/filters'
import {
  sortCreated,
  sortDeadline,
  sortMarketplace,
  sortPriority,
  sortStatus,
  sortType,
} from './core/sorts'
import {Action, Filters, PlaylistType, State, Task} from './types'

export const initialState: State = {
  data: [],
  auxData: [],
  selected: [],
  references: {},
}

export const enum ActionTypes {
  SET_STATE = 'SET_STATE',
  SELECT_ITEM = 'SELECT_ITEM',
  APPLY_FILTERS = 'APPLY_FILTERS',
  SELECT_ALL = 'SELECT_ALL',
  UPDATE_TASKS = 'UPDATE_TASKS',
  UPDATE_STATE = 'UPDATE_STATE',
  SORT_ITEMS = 'SORT_ITEMS',
}

const indexed = (key: string, arr: any[]) =>
  arr.reduce((acc: any, el: any) => {
    acc[el[key]] = el
    return acc
  }, {})

const hiddenFinalizedTasks = (data: PlaylistType[]) =>
  data.map((playlist: PlaylistType) => ({
    ...playlist,
    tasks: playlist.tasks.filter((task: Task) => task.status !== 3),
  }))

const setInitialState = (state: State, {payload}: Action) => {
  return {
    ...state,
    data: hiddenFinalizedTasks(payload.data),
    references: indexed('value', payload.references),
    auxData: payload.data,
  }
}

const selectItem = (state: State, {payload}: Action) => {
  let selected: Task[] = state.selected
  const verify = state.selected.filter((item: Task) => item.id === payload.id)
  if (verify.length < 1) {
    const finded = state.data[payload.index].tasks.filter((item: Task) => item.id === payload.id)
    selected.push(finded[0])
  } else {
    const removed = state.selected.filter((item: Task) => item.id !== payload.id)
    selected = [...removed]
  }
  return {
    ...state,
    selected: selected,
  }
}

const applyFilters = (state: State, {payload}: Action) => {
  const deepCopy = JSON.stringify(state.auxData)
  const aux: PlaylistType[] = JSON.parse(deepCopy)
  const filters: Filters[] = payload.filters

  aux.forEach((playlist: PlaylistType, index: number) => {
    const filtered = filter(filters, playlist.tasks)
    aux[index].tasks = [...filtered]
  })
  return {
    ...state,
    data: filters.length > 0 ? aux : hiddenFinalizedTasks(state.auxData),
  }
}

const selectAllItems = (state: State, {payload}: Action) => {
  return {
    ...state,
    selected: payload.checked ? state.selected.concat(state.data[payload.index].tasks) : [],
  }
}

const updatedTasks = (state: State, {payload}: Action) => {
  const formatNewTasks = payload.tasks.map((item: any) => ({
    id: item.id,
    item: item.item,
    type: item.type,
    notes: item.notes,
    title: item.title,
    status: item.status,
    usersId: payload.edit ? item.usersId : 184,
    deadline: new Date(item.deadline).toISOString(),
    priority: item.priority,
    createdAt: payload.edit ? item.createdAt : new Date().toISOString(),
    deletedAt: null,
    updatedAt: payload.edit ? item.updatedAt : new Date().toISOString(),
    resolvedAt: null,
    description: item.description,
    referenceId: !item.referenceId ? null : item.referenceId,
    marketplaceId: item.marketplaceId,
  }))
  const deepCopy = JSON.stringify(state.data)
  const aux: PlaylistType[] =
    state.data.length > 0
      ? JSON.parse(deepCopy)
      : [
          {
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear().toString(),
            resolved: 0,
            unresolved: 1,
            tasks: [],
          },
        ]
  if (!payload.edit) {
    aux[0].tasks.unshift(formatNewTasks[0])
  } else {
    const updated = aux[payload.indexTable].tasks.map((item) => {
      if (item.id === formatNewTasks[0].id) {
        return {
          ...formatNewTasks[0],
        }
      } else {
        return item
      }
    })
    aux[payload.indexTable].tasks = updated
  }
  return {
    ...state,
    data: hiddenFinalizedTasks(aux),
  }
}

const updatedState = (state: State, {payload}: Action) => {
  return {
    ...state,
    data: hiddenFinalizedTasks(payload.data),
    auxData: payload.data,
    selected: [],
  }
}

const sortItems = (state: State, {payload}: Action) => {
  const deepCopy = JSON.stringify(state.data)
  const aux: PlaylistType[] = JSON.parse(deepCopy)
  const listSort: any = {
    marketplaceId: (data: Task[], sort: string) => sortMarketplace(sort, data),
    priority: (data: Task[], sort: string) => sortPriority(sort, data),
    type: (data: Task[], sort: string) => sortType(sort, data),
    status: (data: Task[], sort: string) => sortStatus(sort, data),
    deadline: (data: Task[], sort: string) => sortDeadline(sort, data),
    createdAt: (data: Task[], sort: string) => sortCreated(sort, data),
  }
  state.data.forEach((playlist: PlaylistType, index: number) => {
    aux[index].tasks = listSort[payload.sort.field](playlist.tasks, payload.sort.sort)
  })
  return {
    ...state,
    data: aux,
  }
}

const actionMap = new Map<string, any>([
  [ActionTypes.SET_STATE, setInitialState],
  [ActionTypes.SELECT_ITEM, selectItem],
  [ActionTypes.APPLY_FILTERS, applyFilters],
  [ActionTypes.SELECT_ALL, selectAllItems],
  [ActionTypes.UPDATE_TASKS, updatedTasks],
  [ActionTypes.UPDATE_STATE, updatedState],
  [ActionTypes.SORT_ITEMS, sortItems],
])

export const playlistReducer = (state: State, action: Action) => {
  const typeToUse = action.type ? action.type : ActionTypes.SET_STATE
  const mappedActions = actionMap.get(typeToUse)
  return mappedActions ? mappedActions(state, action) : state
}
