import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {LogTable} from './components/LogTable'
import {Bar} from 'react-chartjs-2'
import {matrixColor, Splash, tooltipDefault} from '../../../../_gws/helpers/misc'
import {ChartsLabels} from '../../../../_gws/partials/newComponets/charts/ChartsLabels'
import {getAuditData} from './core/_resquests'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import {useParams} from 'react-router-dom'

const Audit = () => {
  const intl = useIntl()
  const Alert = withReactContent(Swal)

  /**
   * Set into individual objects the request parts
   */
  const [endpoints, setEndpoints] = useState([])
  const [logs, setLogs] = useState([])
  const [pageInfo, setPageInfo] = useState([])

  /**
   * URL Params
   */
  const params = useParams()
  /**
   * Check if exists param current page, if not 1
   */
  const initialPage = params.page === undefined ? 1 : Number(params.page)
  /**
   * Check if exists param count or page size, if not 10
   */
  const initialCount = params.count === undefined ? 10 : Number(params.count)
  /**
   * Check if exists param search, if not empty
   */
  const initialSearch = params.search === undefined ? '' : params.search.toString()
  /**
   * Fetching data from endpoint
   * @param page - current page
   * @param size - page size
   * @param search - search term
   */
  const fetchAudit = async (page: number, size: number, search: string) => {
    try {
      Splash(true)
      const response = await getAuditData(page, size, search)
      if (response.status === 200) {
        setEndpoints(response.data.endpoints)
        setLogs(response.data.list)
        setPageInfo(response.data.pageInfo)
        Splash(false)
      }
    } catch (error: any) {
      Alert.fire({
        title: intl.formatMessage({id: 'ERROR.BACKEND.FETCH'}),
        text: 'test',
        icon: 'error',
        iconColor: '#00c6c7',
        buttonsStyling: false,
        confirmButtonText: intl.formatMessage({id: 'ACCOUNT.PROFILE.DETAILS.OK_GOT_IT'}),
        customClass: {
          confirmButton: 'btn btn-primary',
          popup: 'bg-light',
        },
      }).then(() => {
        Splash(false)
      })
    }
  }

  useEffect(() => {
    /**
     *  Call fetching data when loading page
     *  */
    fetchAudit(initialPage, initialCount, initialSearch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Options for charts.js
   */
  const optionsBarState = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: tooltipDefault(' request', true),
    },
    scales: {
      x: {
        title: {
          display: true,
          text: intl.formatMessage({id: 'AUDIT.TABLE.GRAPH.ENDPOINT'}),
          color: '#a1a5b7',
          font: {
            weight: 'bold',
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
          color: '#a1a2b6',
        },
      },

      y: {
        stacked: true,
        title: {
          display: true,
          text: intl.formatMessage({id: 'AUDIT.TABLE.GRAPH.REQUESTS'}),
          color: '#a1a5b7',
          font: {
            weight: 'bold',
          },
        },
        grid: {
          drawBorder: false,
          drawTicks: false,
          borderWidth: 0,
          color: function () {
            return 'rgba(161,162,182,0.17)'
          },
          borderDash: [5, 5],
          lineWidth: 1,
        },
        ticks: {
          color: '#a1a2b6',
        },
      },
    },
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
  }

  const labelsOrderStatus: any = []
  const valuesOrderStatus: any = []
  const barsColors: any = []
  let maxValueArray: any = []
  let maxValue: number = 0

  if (endpoints) {
    /**
     * sort the countries object by number of orders
     */
    const endpointSort = Object.keys(endpoints)
      .sort((a: any, b: any) => {
        return endpoints[b] - endpoints[a]
      })
      .reduce(
        (_sortedObj, key: any) => ({
          ..._sortedObj,
          [key]: endpoints[key],
        }),
        {}
      )
    let count = 1
    Object.entries(endpointSort).map((item: any) => {
      count++
      if (count === 20) {
        count = 1
      }
      labelsOrderStatus.push(item[0].toUpperCase())
      valuesOrderStatus.push(item[1])

      barsColors.push(matrixColor(count))
      return ''
    })
    maxValue = Math.max.apply(null, valuesOrderStatus)
    maxValueArray = valuesOrderStatus.map((ord: number) => maxValue - ord)
  }
  const dataBarState = {
    labels: Array(valuesOrderStatus.length).fill(''),
    datasets: [
      {
        label: intl.formatMessage({id: 'AUDIT.TABLE.GRAPH.ENDPOINT'}),
        data: valuesOrderStatus,
        backgroundColor: barsColors,
        borderRadius: 8,
        barThickness: 16,
        stack: 'Stack 0',
      },
      {
        data: maxValueArray,
        backgroundColor: Array(valuesOrderStatus.lenght).fill('#DADADAAA'),
        borderRadius: 8,
        barThickness: 16,
        stack: 'Stack 0',
      },
    ],
  }

  return (
    <>
      <div className='row'>
        <div className='col'>
          <div className='card'>
            <div className='card-header cursor-pointer'>
              <h3 className='card-title m-0 align-items-start flex-column'>
                <span className='card-label fw-bolder m-0'>
                  {intl.formatMessage({id: 'AUDIT.TABLE.GRAPH.TITLE'})}
                </span>
              </h3>
            </div>
            <div className='card-body'>
              <div className='row'>
                <div className='col-12'>
                  <Bar data={dataBarState} options={optionsBarState} />
                </div>
                <div className='col-12'>
                  <ChartsLabels
                    labels={labelsOrderStatus}
                    values={valuesOrderStatus}
                    colors={barsColors}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-8'>
        <div className='col'>
          <div className='card'>
            <div className='card-header cursor-pointer'>
              <h3 className='card-title m-0 align-items-start flex-column'>
                <span className='card-label fw-bolder m-0'>
                  {intl.formatMessage({id: 'AUDIT.TABLE.TITLE'})}
                </span>
              </h3>

              <div className='card-toolbar'></div>
            </div>
            <div className='card-body'>
              <LogTable
                logs={logs}
                pageInfo={pageInfo}
                searchTerm={initialSearch}
                count={initialCount}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {Audit}
