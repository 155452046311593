import {IconBase} from './Base'

export const IconActiveCampaigns = ({
  color = '#273A51',
  size = 24,
}: {
  color?: string
  size?: number
}) => {
  const setColor = color
  return (
    <IconBase size={size} color={setColor} viewBox='0 0 10 12'>
      <svg
        width='10'
        height='12'
        viewBox='0 0 10 12'
        fill='none'
      >
        <path
          d='M1.00961 11.25C0.721953 11.25 0.481771 11.1536 0.289063 10.9609C0.0963542 10.7682 0 10.528 0 10.2404V3.50961C0 3.22195 0.0963542 2.98177 0.289063 2.78906C0.481771 2.59635 0.721953 2.5 1.00961 2.5H2.5C2.5 1.80369 2.74259 1.21294 3.22777 0.727766C3.71294 0.242589 4.30369 0 5 0C5.69631 0 6.28706 0.242589 6.77223 0.727766C7.25741 1.21294 7.5 1.80369 7.5 2.5H8.99039C9.27805 2.5 9.51823 2.59635 9.71094 2.78906C9.90365 2.98177 10 3.22195 10 3.50961V10.2404C10 10.528 9.90365 10.7682 9.71094 10.9609C9.51823 11.1536 9.27805 11.25 8.99039 11.25H1.00961ZM3.125 2.5H6.875C6.875 1.97917 6.69271 1.53646 6.32812 1.17188C5.96354 0.807292 5.52083 0.625 5 0.625C4.47917 0.625 4.03646 0.807292 3.67188 1.17188C3.30729 1.53646 3.125 1.97917 3.125 2.5ZM4.99639 6.25C5.63903 6.25 6.21074 6.02684 6.71155 5.58053C7.21235 5.13422 7.46155 4.6214 7.45914 4.04206C7.45914 3.96114 7.42989 3.89223 7.37139 3.83534C7.3129 3.77845 7.23918 3.75 7.15023 3.75C7.07653 3.75 7.01042 3.77884 6.95192 3.83653C6.89343 3.89423 6.85417 3.96475 6.83414 4.04808C6.76763 4.50001 6.55969 4.87581 6.21033 5.17548C5.86097 5.47516 5.45633 5.625 4.99639 5.625C4.53645 5.625 4.13121 5.47516 3.78066 5.17548C3.43009 4.87581 3.22276 4.50001 3.15866 4.04808C3.13862 3.96234 3.10056 3.89122 3.04447 3.83473C2.98838 3.77824 2.92347 3.75 2.84977 3.75C2.76082 3.75 2.68649 3.77845 2.6268 3.83534C2.5671 3.89223 2.53725 3.96114 2.53725 4.04206C2.53725 4.6214 2.78645 5.13422 3.28486 5.58053C3.78326 6.02684 4.35377 6.25 4.99639 6.25Z'
          fill={color}
        />
      </svg>
    </IconBase>
  )
}
