import axios from 'axios';

export const REQUEST_JSON = 'glossary.json'
export const getGlossaryData = () => {
  const axiosInstance = axios.create();
  return axiosInstance.get(
    REQUEST_JSON
  );
};



// export const REQUEST_ID_URL = `${API_URL_CRUNCH}/api/reports/client-history/`
// export function getHistoryReportData() {
//   const axiosClean = axios.create()
//   return axiosClean.get(
//     REQUEST_ID_URL,
//     // @ts-ignore
//     getTokenHeader()
//   )
// }