import styled from '@emotion/styled'
import {useIntl} from 'react-intl'
import ReactApexChart from 'react-apexcharts'
import {abbreviateNumber} from '../../../../../_gws/helpers/misc'
import {serieChart} from '../../core/types'

const Container = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '1rem',
  '@media (max-width: 760px)': {
    gridTemplateColumns: '1fr',
  },
})

const generateChartsOptions = ({xaxis, series}: {xaxis: string[]; series: any[]}) => {
  return {
    series: series,
    options: {
      chart: {
        type: 'area',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: '40%',
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: xaxis,
        tooltip: {
          enabled: false,
          offsetX: 0,
        },
      },
      yaxis: {
        labels: {
          formatter: (value: any) => {
            return abbreviateNumber(value, 2)
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          show: true,
          formatter: function (value: any) {
            return abbreviateNumber(value, 2)
          },
        },
      },
    },
  }
}

export const ContentSales = ({
  report,
}: {
  report: {
    orders: {
      series: serieChart[]
    }
    sold: {
      series: serieChart[]
    }
    xaxis: string[]
  }
}) => {
  const intl = useIntl()
  report.orders.series[0].name = intl.formatMessage({id: 'REPORT.FRANKIE.TABLE.HEAD.STATE.SHIPPED'})
  report.orders.series[1].name = intl.formatMessage({id: 'REPORT.FRANKIE.TABLE.HEAD.STATE.PENDING'})
  report.orders.series[2].name = intl.formatMessage({
    id: 'REPORT.FRANKIE.TABLE.HEAD.STATE.CANCELLED',
  })

  report.sold.series[0].name = intl.formatMessage({id: 'REPORT.FRANKIE.TABLE.HEAD.STATE.SHIPPED'})
  report.sold.series[1].name = intl.formatMessage({id: 'REPORT.FRANKIE.TABLE.HEAD.STATE.PENDING'})
  report.sold.series[2].name = intl.formatMessage({id: 'REPORT.FRANKIE.TABLE.HEAD.STATE.CANCELLED'})

  const salesOptions: any = generateChartsOptions({
    xaxis: report.xaxis,
    series: report.orders.series,
  })

  const salesSoldOptions: any = generateChartsOptions({
    xaxis: report.xaxis,
    series: report.sold.series,
  })
  return (
    <Container>
      <div>
        <div>
          <h4
            style={{
              color: '#212121',
            }}
          >
            {intl.formatMessage({id: 'REPORT.LOGISTICS.ORDERS_QUANTITY'})}
          </h4>
          <p>{intl.formatMessage({id: 'REPORT.SALES.MONTHLY_DETAILS'})}</p>
        </div>
        <ReactApexChart
          options={salesOptions.options}
          series={salesOptions.series}
          type='bar'
          height={340}
        />
      </div>
      <div>
        <div>
          <h4
            style={{
              color: '#212121',
            }}
          >
            {intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.TOTAL_AMOUNT'})}
          </h4>
          <p>{intl.formatMessage({id: 'REPORT.SALES.MONTHLY_DETAILS'})}</p>
        </div>
        <ReactApexChart
          options={salesSoldOptions.options}
          series={salesSoldOptions.series}
          type='area'
          height={340}
        />
      </div>
    </Container>
  )
}
