import styled from '@emotion/styled'

export const CheckoutCircle = () => {
  return (
    <svg style={{marginRight: '5px'}} width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path
        d='M6.9055 10.4231L11.319 6.03044L10.8334 5.54485L6.9055 9.4519L5.13467 7.7019L4.64908 8.18748L6.9055 10.4231ZM8.00283 15.1666C7.02126 15.1666 6.0948 14.9803 5.22346 14.6075C4.35211 14.2347 3.58916 13.7214 2.9346 13.0675C2.28006 12.4135 1.76622 11.6513 1.39308 10.7809C1.01994 9.91036 0.833374 8.98433 0.833374 8.00277C0.833374 7.00731 1.01976 6.07738 1.39252 5.21298C1.7653 4.34858 2.27864 3.5891 2.93256 2.93454C3.58648 2.28 4.34868 1.76616 5.21917 1.39302C6.08967 1.01988 7.01569 0.833313 7.99725 0.833313C8.99271 0.833313 9.92264 1.01969 10.787 1.39246C11.6514 1.76524 12.4109 2.27858 13.0655 2.9325C13.72 3.58642 14.2339 4.34515 14.607 5.20869C14.9801 6.07224 15.1667 7.00174 15.1667 7.99719C15.1667 8.97876 14.9803 9.90522 14.6076 10.7766C14.2348 11.6479 13.7214 12.4109 13.0675 13.0654C12.4136 13.72 11.6549 14.2338 10.7913 14.6069C9.92778 14.9801 8.99828 15.1666 8.00283 15.1666ZM8.00004 14.5C9.8056 14.5 11.3403 13.868 12.6042 12.6041C13.8681 11.3403 14.5 9.80554 14.5 7.99998C14.5 6.19442 13.8681 4.6597 12.6042 3.39581C11.3403 2.13192 9.8056 1.49998 8.00004 1.49998C6.19449 1.49998 4.65976 2.13192 3.39587 3.39581C2.13199 4.6597 1.50004 6.19442 1.50004 7.99998C1.50004 9.80554 2.13199 11.3403 3.39587 12.6041C4.65976 13.868 6.19449 14.5 8.00004 14.5Z'
        fill='#212121'
      />
    </svg>
  )
}

export const PaymentContainer = styled('div')({
  "@media (max-width: 600px)": {
    padding: "20px"
  }
})

export const PaymentHeader = styled('div')({
  h1: {
    width: '60%',
    marginBottom: '10px',
    color: '#212121',
    fontWeight: 'bold',
  },
})

export const Licence = styled('div')({
  display: 'flex',
  margin: '20px 0px',
  h4: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
  },
  "@media (max-width: 430px)": {
    flexFlow: "column"
  }
})

export const Badge = styled('span')({
  borderRadius: '8px',
  background: '#E2EBD3',
  display: 'flex',
  alignItems: 'center',
  padding: '0px 10px',
  marginLeft: '20px',
  "@media (max-width: 430px)": {
    marginLeft: '0px',
  }
})

export const Saving = styled('span')({
  position: 'absolute',
  right: "0px",
  top: "30px",
  "@media (max-width: 430px)": {
    right: "-30px",
  }
})

export const Month = styled('span')({
  fontSize: '.8rem',
})

export const Price = styled('span')({
  fontSize: '1.2rem',
  fontWeight: 'bold',
  marginLeft: '5px',
})

export const ButtonPay = styled('button')({
  width: '100%',
  height: '50px',
  borderRadius: '8px',
  cursor: 'pointer',
  color: '#fff',
  background: '#212121',
  fontSize: '1.2rem',
  fontWeight: 'bold',
  border: 'none',
})

export const AssetsList = styled('ul')({
  listStyleType: 'none',
  padding: '0px',
  margin: '20px 0px',
  li: {
    padding: '0px',
    margin: '10px 0px',
  },
})
