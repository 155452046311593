import {FC} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  from: number
  to: number
  size: number
}

/**
 * Bottom leyend Bootstrap Table configuration
 * @param from
 * @param to
 * @param size
 */
const CustomTotal: FC<Props> = ({from, to, size}) => {
  const intl = useIntl()
  return (
    <div className='mt-3 mb-4 justify-content-start align-items-center'>
      <span className='text-gray-100-dark fw-normal gy-5'>
        {intl.formatMessage({id: 'REPORT.INVENTORY.SHOW'})} {from}{' '}
        {intl.formatMessage({id: 'REPORT.INVENTORY.TO'})} {to}{' '}
        {intl.formatMessage({id: 'REPORT.INVENTORY.FROM'})} {size}{' '}
      </span>
    </div>
  )
}

export {CustomTotal}
