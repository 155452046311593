import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_gws/helpers'
import FrankieAdsWrapper from './components/FrankieAds/FrankieAdsWrapper'
import {FrankieSalesWrapper} from './components/FrankieSales/FrankieSalesWrapper'
import {FrankieInventoryWrapper} from './components/FrankieInventory/FrankieInventoryWrapper'
import {useIntl} from 'react-intl'
import {useAuth} from '../../modules/auth'

type PropsFrankie = {
  dataReport?: any
  mkp: string | undefined
  tabId?: string
}

const FrankieHealth: React.FC<PropsFrankie> = ({dataReport, mkp, tabId}) => {
  const intl = useIntl()
  const {clientHasAmazonAds} = useAuth()

  return (
    <div id={tabId} role='tabpanel' className='tab-content fade show mt-8'>
      <div className='row'>
        <div className='col-12'>
          <div className='alert alert-dark d-flex align-items-center p-3'>
            <KTSVG
              path={toAbsoluteUrl('media/icons/unicos/015-information.svg')}
              className='svg-icon svg-icon-1 svg-icon-dark  me-3'
            />
            <div className='d-flex flex-column'>
              <h4 className='m-0 p-0  text-dark fw-bold fs-5'>
                {intl.formatMessage({id: 'REPORT.FRANKIE.DATA.TIME'})}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-5 mb-5'>
        <div className='col-12'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{`${intl.formatMessage({
              id: 'REPORT.FRANKIE.INVENTORY.BLOCK',
            })}`}</span>
          </h3>
        </div>
      </div>
      {dataReport.inventory && (
        <FrankieInventoryWrapper inventory={dataReport.inventory} mkp={mkp} />
      )}
      <div className='row mt-5 mb-5'>
        <div className='col-12'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              {intl.formatMessage({id: 'REPORT.FRANKIE.ADS.BLOCK'})}
            </span>
          </h3>
        </div>
      </div>
      {dataReport.ads ? (
        <>
          {mkp === 'amazon' ? (
            !clientHasAmazonAds ? (
              <div className='alert alert-warning d-flex align-items-center p-3 mt-3 mb-10'>
                <KTSVG
                  path={toAbsoluteUrl('media/icons/unicos/054-alert.svg')}
                  className='svg-icon svg-icon-1 svg-icon-dark  me-3'
                />
                <div className='d-flex flex-column'>
                  <h4 className='m-0 p-0  text-dark fw-bold fs-5'>
                    {intl.formatMessage({id: 'REPORT.FRANKIE.ADS.NO.CREDENTIALS'})}
                  </h4>
                </div>
              </div>
            ) : !dataReport.ads.campaignsList ? (
              <div className='alert alert-warning d-flex align-items-center p-3 mt-3 mb-10'>
                <KTSVG
                  path={toAbsoluteUrl('media/icons/unicos/054-alert.svg')}
                  className='svg-icon svg-icon-1 svg-icon-dark  me-3'
                />
                <div className='d-flex flex-column'>
                  <h4 className='m-0 p-0 text-dark fw-bold fs-5'>
                    {intl.formatMessage({id: 'REPORT.FRANKIE.ADS.NO.AVAILABLE'})}
                  </h4>
                </div>
              </div>
            ) : (
              <FrankieAdsWrapper mkp={mkp} marketingData={dataReport.ads} />
            )
          ) : (
            <FrankieAdsWrapper mkp={mkp} marketingData={dataReport.ads} />
          )}
        </>
      ) : (
        <div className='alert alert-warning d-flex align-items-center p-3 mt-3 mb-10'>
          <KTSVG
            path={toAbsoluteUrl('media/icons/unicos/054-alert.svg')}
            className='svg-icon svg-icon-1 svg-icon-dark  me-3'
          />
          <div className='d-flex flex-column'>
            <h4 className='m-0 p-0 text-dark fw-bold fs-5'>
              {intl.formatMessage({id: 'REPORT.FRANKIE.ADS.NO.AVAILABLE'})}
            </h4>
          </div>
        </div>
      )}

      <div className='row mt-5'>
        <div className='col-12'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{`${intl.formatMessage({
              id: 'REPORT.FRANKIE.SALES.BLOCK',
            })}`}</span>
          </h3>
        </div>
      </div>
      {dataReport.sales && <FrankieSalesWrapper sales={dataReport.sales} mkp={mkp} />}
    </div>
  )
}
export {FrankieHealth}
