import React, {FC, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_gws/helpers'
// @ts-ignore
import Flatpickr from 'react-flatpickr'
import {Spanish} from 'flatpickr/dist/l10n/es.js'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'

import 'flatpickr/dist/plugins/monthSelect/style.css'
import {useIntl} from 'react-intl'
import moment from 'moment/moment'
import {getShortMonth} from '../../../../_gws/helpers/misc'

type Props = {
  month: boolean | undefined
  setMonth: any
  changeDate?: any
}
/**
 * This function is a React component that renders a date picker
 * @param  - `month`: boolean, if true, the date picker will be in month mode,
 * otherwise it will be in day mode.
 */
const DatePickerWrapper: FC<Props> = ({month, changeDate, setMonth}) => {
  const intl = useIntl()

  const today = month
    ? moment().subtract(1, 'month').format('YYYY-MM')
    : moment().subtract(1, 'days').format('YYYY-MM-DD')

  const locale = intl.locale === 'en' ? 'en' : Spanish
  const [maxDate] = useState(moment().subtract(1, 'days').format('YYYY-MM-DD'))
  const [minDate] = useState(moment().subtract(30, 'days').format('YYYY-MM-DD'))
  const [minMonth] = useState(moment().subtract(11, 'months').format('YYYY-MM'))
  const [maxMonth] = useState(moment().subtract(0, 'months').format('YYYY-MM'))

  const dateFormat = 'Y-m-d'
  const optionsMonth = {
    locale: locale,
    dateFormat: dateFormat,
    defaultDate: today,
    minDate: minMonth,
    maxDate: maxMonth,
    plugins: [
      // @ts-ignore
      new monthSelectPlugin({
        shorthand: true, //defaults to false
        dateFormat: 'M - Y', //defaults to "F Y"
        altFormat: 'F Y', //defaults to "F Y"
        theme: 'dark', // defaults to "light"
      }),
    ],
  }

  const optionsDayRange = {
    mode: 'range',
    minDate: minDate,
    maxDate: maxDate,
    dateFormat: dateFormat,
  }

  const options = month ? optionsMonth : optionsDayRange

  const onChangeDate = (date: any) => {
    if (month) {
      setMonth({
        month: getShortMonth(parseInt(moment(date[0]).format('M')) - 1),
        year: parseInt(moment(date[0]).format('YYYY')),
      })
      console.log({
        month: getShortMonth(parseInt(moment(date[0]).format('M')) - 1),
        year: parseInt(moment(date[0]).format('YYYY')),
      })
    }
    const dateFrom = moment(date[0]).format('YYYY-MM-DD')
    const dateTo = moment(date[1]).format('YYYY-MM-DD')
    if (date[1] >= date[0] && changeDate !== undefined) {
      changeDate({start: dateFrom, end: dateTo})
    }
  }
  return (
    <>
      <div className='col-6 col-sm-4 col-md-auto'>
        <div className='position-relative d-flex align-items-center'>
          <label htmlFor='to' className='visually-hidden'>
            {intl.formatMessage({id: 'REPORT.SALES.TO_DATE'})}
          </label>
          <KTSVG
            path={toAbsoluteUrl('media/icons/unicos/003-date.svg')}
            className='svg-icon svg-icon-2 position-absolute mx-4'
          />
          <Flatpickr
            name='to'
            className='form-control form-control-solid ps-12 fs-9'
            options={options}
            onChange={(e: any) => {
              onChangeDate(e)
            }}
            placeholder={today}
          />
        </div>
      </div>
    </>
  )
}

export {DatePickerWrapper}
