import axios from 'axios'
import {getTokenHeader} from '../../../modules/auth'

const API_URL_CRUNCH = process.env.REACT_APP_BACKEND_MAIN_API

export const REQUEST_MARKETING_URL = `${API_URL_CRUNCH}/api/reports/paid-marketing/`

export function getPaidMarketingData(mktplace: any, periodType: Number) {
  const axiosClean = axios.create()
  return axiosClean.post(
    REQUEST_MARKETING_URL + mktplace,
    {
      periodType: periodType
    },
    // @ts-ignore
    getTokenHeader()
  )
}
/*
export function getPaidMarketingDataMeli(mktplace: string) {
  const axiosClean = axios.create()
  return axiosClean.post(
    REQUEST_MARKETING_URL + mktplace,
    {},
    // @ts-ignore
    getTokenHeader()
  )
}
*/