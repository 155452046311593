import React, {FC, useEffect, useState} from 'react'
import {ChartsLabels} from './ChartsLabels'
import {WrapperBarChart} from './components/WrapperBarChart'
type Props = {
  arrayLabel: string[]
  arrayValues: number[]
  arrayColors?: string[]
  chartYTitle?: string
  chartXTitle?: string
  legendPosition?: 'start' | 'end'
  legendPositionInverted?: boolean
  cleanChart?: boolean
  cleanAxeX?: boolean
  widthChart?: number
  heightChart?: number,
  isMoney?: boolean
}
/**
 * Bar chart component with legend interaction
 * @param arrayLabel - Labels of the X axis
 * @param arrayValues - Values of the bars
 * @param arrayColors - Colors(hex) of the bars
 * @param chartYTitle - Title of the Y axis
 * @param chartXTitle - Title of the X axis
 * @param legendPosition - Position of the legend (start or end)
 * @param legendPositionInverted - If true, the legend will be shown at first
 * @param cleanChart - If true, the chart will be clean, with no grids nor axis
 * @param cleanAxeX - If true, the chart will be clean, with no grids nor axis
 * @param widthChart - size of the chart in *px*
 * @example:
 * <GwsBarChart
 *  arrayLabel={['Nuevo', 'Usado']}
 *  arrayValues={[98, 74]}
 *  arrayColor={['#B1CF7F','#50BBCC']}
 *  legendPosition={'start'}
 *  legendPositionInverted={true}
 *  />
 */
const GwsBarChart: FC<Props> = ({
  arrayLabel,
  arrayValues,
  arrayColors,
  chartYTitle,
  chartXTitle,
  legendPosition = 'end',
  legendPositionInverted = false,
  cleanChart = false,
  cleanAxeX = false,
  widthChart,
  heightChart,
  isMoney
}) => {
  let baseColor = '#E7F2F8'
  let hoverColor = '#3D96CA'
  let hoverColorSeries = hoverColor
  /**
   * Set the default color of the bars and legends
   */
  const [dataColor, setDataColor] = useState<string[]>(
    arrayColors
      ? arrayColors
      : (Array.from({length: arrayLabel.length}).fill(baseColor) as string[])
  )

  const [currentElement, setCurrentElement] = useState<number>(0)

  /**
   * Update the color of the bars and legends
   * @param index
   */
  const updateColor = (index: number) => {
    let tempDataColor = Array.from({length: arrayLabel.length}).fill(baseColor) as string[]
    tempDataColor[index] = arrayColors ? arrayColors[index] : hoverColor
    hoverColorSeries = arrayColors ? arrayColors[index] : hoverColor
    setDataColor(tempDataColor)
  }
  /**
   *  Change the color of the bar when mouse enter
   * @param index
   */
  const handleMouseIn = (index: number) => {
    updateColor(index)
  }
  /**
   * Reset the color of the bar when mouse leave
   * @param e
   */
  const handelMouseOut = () => {
    let tempDataColor = arrayColors
      ? arrayColors
      : (Array.from({length: arrayLabel.length}).fill(baseColor) as string[])

    arrayColors && (tempDataColor[0] = hoverColor)
    setDataColor(tempDataColor)
  }
  useEffect(() => {
    updateColor(currentElement)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentElement])
  return (
    <div
      className={`row ${
        legendPositionInverted && 'flex-row-reverse'
      } d-flex justify-content-${legendPosition}`}
    >
      <div>
        <WrapperBarChart
          dataLabels={arrayLabel}
          dataNumbers={arrayValues}
          hoverColor={hoverColorSeries}
          dataColor={dataColor}
          chartYTitle={chartYTitle}
          chartXTitle={chartXTitle}
          currentElement={currentElement}
          updateCurrentElement={setCurrentElement}
          cleanChart={cleanChart}
          cleanAxeX={cleanAxeX}
          widthChart={widthChart}
          heightChart={heightChart}
        />
      </div>
      <div>
        <ChartsLabels
          labels={arrayLabel}
          values={arrayValues}
          colors={dataColor}
          handleMouseIn={handleMouseIn}
          handelMouseOut={handelMouseOut}
          isMoney={isMoney}
        />
      </div>
    </div>
  )
}

export {GwsBarChart}
