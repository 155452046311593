import React, {useEffect, useState} from 'react'
import {ProductHealth} from './ProductHealth'
import {useParams} from 'react-router-dom'
import {PageTitle} from '../../../_gws/layout/core'
import {useIntl} from 'react-intl'
import {AlertFetchError, Splash} from '../../../_gws/helpers/misc'
import {getProductHealth} from './core/_request'
import useSWR from 'swr'
import { Marketplace } from './core/type'

const ProduchtHealthWrapper = () => {
  const intl = useIntl()
  const {mkp, productId} = useParams()
  const marketplace = mkp as Marketplace
  const [showReport, setShowReport] = useState(false)
  const [productData, setProductData] = useState<any>({})

  const {data, error, isLoading} = useSWR(
    () => (mkp ? [mkp, productId] : null),
    () => getProductHealth(mkp, productId),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false
    }
  )

  const fetchProductHealthData = () => {
    if(!mkp) return 
    if (error) AlertFetchError(intl.locale, error).fire()
    if (data) {
      setProductData(data.data)
      setShowReport(true)
    }
  }

  useEffect(() => {
    if (data && !isLoading) {
      fetchProductHealthData()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, isLoading])


  useEffect(() => {
    Splash(isLoading)
  }, [isLoading])

  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: intl.formatMessage({id: 'MENU.HOME'}),
            path: '/dashboard',
            isSeparator: false,
            isActive: false,
          },
          {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.REPORTS.PRODUCT_HEALTH'})}
      </PageTitle>
      {showReport && <ProductHealth metaProduct={productData} mkplace={marketplace} />}
    </>
  )
}

export {ProduchtHealthWrapper}
