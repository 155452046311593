import React, {FC} from 'react'

/**
 * Props declarations
 * @param props - table component options
 * @param totalPages - total number of pages obtained by request
 */
type Props = {
  props: any
  totalPages: number
}

/**
 * Component that replace default behaviour of the pages buttons
 * @param props
 * @param totalPages
 */
const CustomPageList: FC<Props> = ({props, totalPages}) => {
  /**
   * Used as flag to resizing pages array
   */
  let stop = false

  /**
   * New pages array
   */
  const filterPages: any = []

  /**
   * Iterator to create new array
   */
  props.pages.forEach((v: any, index: any) => {
    /**
     * If last page is achieved the flag stop is changed
     */
    if (v.page > totalPages) {
      stop = true
    }

    /**
     * If flag is false the elements are pushed
     */
    if (!stop) {
      filterPages.push(v)
    }
  })

  return (
    <div className='react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6'>
      <ul className='pagination react-bootstrap-table-page-btns-ul'>
        {filterPages.map((p: any, index: number) => (
          <li key={index} className={`page-item ${p.active ? 'active' : ''} `} title={p.page}>
            <div onClick={() => props.onPageChange(p.page)} className='page-link cursor-pointer'>
              {p.page}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export {CustomPageList}
