import {ReactElement} from 'react'
import {IconCampaigns, IconLandedCost, IconMkpFee, IconStock, IconType, IconUtility} from './Icons'
import {IndicatorCard, IndicatorsWrapper} from './styled'
import {useIntl} from 'react-intl'
import {addCommasInNumber} from '../../../../_gws/helpers/misc'
import {Marketplace} from '../core/type'

type Indicator = {
  stock: number
  orders: number
  landed_cost: number
  marketplace_fee: number
  utility: number
  campaigns: number
}

type KeyIndicator = 'stock' | 'orders' | 'landed_cost' | 'marketplace_fee' | 'utility' | 'campaigns'

type ItemIndicator = {
  value: number | string
  key: KeyIndicator
  background: string
  icon: ReactElement
  label: string
}

type IndicatorFieldType = {
  background: string
  icon: ReactElement
  label: string
}

export const ProductIndicators = ({indicator, mkp}: {indicator: Indicator; mkp: Marketplace}) => {
  const intl = useIntl()
  const IndicatorField: Record<KeyIndicator, IndicatorFieldType> = {
    stock: {
      background: '#E2EBD3',
      icon: <IconStock />,
      label: 'Stock',
    },
    orders: {
      background: '#D4FFFA',
      icon: <IconType />,
      label: intl.formatMessage({id: 'PRODUCT_HEALTH.ORDERS'}),
    },
    landed_cost: {
      background: '#CEE6E3',
      icon: <IconLandedCost />,
      label: 'Landed cost',
    },
    marketplace_fee: {
      background: '#E2EBD3',
      icon: <IconMkpFee />,
      label: 'Fee Marketplace',
    },
    utility: {
      background: '#E6FBFF',
      icon: <IconUtility />,
      label: intl.formatMessage({id: 'PRODUCT_HEALTH.INDICATOR.UTILITY'}),
    },
    campaigns: {
      background: '#CBF3F9',
      icon: <IconCampaigns />,
      label: intl.formatMessage({id: 'PRODUCT_HEALTH.INDICATOR.CAMPAIGN'}),
    },
  }
  const keys = Object.keys(indicator) as KeyIndicator[]
  const indicators = keys.reduce((acc: ItemIndicator[], el: KeyIndicator) => {
    acc.push({
      value: indicator[el],
      key: el,
      background: IndicatorField[el].background,
      icon: IndicatorField[el].icon,
      label: IndicatorField[el].label,
    })
    return acc
  }, [])
  return (
    <IndicatorsWrapper>
      {indicators.map((item, i) => (
        <IndicatorCard key={i} bg={item.background}>
          {item.icon}
          <div>
            <h6>{item.label}</h6>
            <p>
              {(item.key === 'utility' && typeof item.value === 'number') ||
              (item.key === 'marketplace_fee' && typeof item.value === 'number')
                ? `$${addCommasInNumber(Number(item.value.toFixed(2)))}`
                : item.value}
            </p>
          </div>
        </IndicatorCard>
      ))}
    </IndicatorsWrapper>
  )
}
