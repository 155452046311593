import styled from '@emotion/styled'

type CellProps = {
  event?: boolean
  bg?: string
}

export const Container = styled('div')({
  background: '#fff',
  borderRadius: '10px',
  padding: '20px',
})

export const Header = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  p: {
    color: '#9E9E9E',
  },
})

export const PlaylistFilterWrapper = styled('div')({
  '@media (max-width: 500px)': {
    display: 'none',
  },
})

export const PlaylistFilterWrapperRes = styled('div')({
  display: 'none',
  '@media (max-width: 500px)': {
    display: 'inherit',
  },
})

export const ContainerFilters = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '@media (max-width: 500px)': {
    flexFlow: 'column',
    alignItems: 'flex-start',
  },
})

export const InputSearch = styled('input')({
  width: '60px',
  transition: 'all ease .3s',
  marginLeft: '10px',
  borderRadius: '5px',
  padding: '5px 10px',
  border: 'none',
  background: '#EFEFEF',
  ':focus': {
    width: '200px',
    outline: 'none',
  },
  '::placeholder': {
    fontSize: '10px',
  },
  '@media (max-width: 500px)': {
    width: '100%',
    margin: '10px 0px',
    ':focus': {
      width: '100%',
    },
  },
})

export const Wrapper = styled('div')({
  '@media (max-width: 500px)': {
    width: '100%',
    button: {
      width: '100%',
    },
  },
})

export const WrapperTable = styled('div')({
  marginBottom: '20px',
})

export const Table = styled('table')({
  borderCollapse: 'separate',
  borderSpacing: 0,
  width: '100%',
  '@media (max-width: 780px)': {
    overflow: 'auto',
    display: 'block',
    whiteSpace: 'nowrap',
  },
  th: {},
  'th, td': {
    padding: '10px',
    border: '1px solid #DDDDDD',
  },
  'tr:first-child th:first-child': {
    borderTopLeftRadius: '10px',
  },
  'tr:first-child th:last-child': {
    borderTopRightRadius: '10px',
  },
  'tr:last-child td:first-child': {
    borderBottomLeftRadius: '10px',
  },
  'tr:last-child td:last-child': {
    borderBottomRightRadius: '10px',
  },
})

export const HeaderCell = styled('th')({})

export const Cell = styled('td')<CellProps>(({event, bg}) => ({
  padding: '10px !important',
  transition: 'all ease .3s',
  background: bg ? bg : 'transpranet',
  ':hover': {
    background: event ? '#F5F6F8' : bg ? bg : 'transpranet',
    cursor: event ? 'pointer' : '',
  },
}))

export const ContentCell = styled('tr')({})

//---------- Playlist Filters ---------- //

export const WrapperColumn = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '1rem',
  margin: '15px 0px',
})

export const Line = styled('div')({
  height: '2px',
  background: '#DDDDDD',
  margin: '10px 0px',
})
//---------- Playlist Filters ---------- //
