/* eslint-disable jsx-a11y/anchor-is-valid */
import {Outlet, Route, Routes} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_gws/helpers'
import {Recovery} from './components/Recovery'
import styled from '@emotion/styled'
import {ContentImg} from './PaymentPage'
import { useIntl } from 'react-intl'
/**
 * Auth Layout for the login form and the other elements outside the form
 * @component
 */

const Container = styled('div')({
  height: '100vh',
  background: '#ffffff',
})
const ContainerImg = styled('div')({
  height: '100vh',
  background: `url(/media/backgrounds/bg-gradient.png) no-repeat center`,
  backgroundSize: "cover",
  display: "grid",
  placeItems: "center"
})

const Wrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: '.7fr 1fr',
  height: '100%',
  gap: '1rem',
  '@media (max-width: 1000px)': {
    gridTemplateColumns: '1fr',
  },
})

const RecoveryLayout = () => {
  const intl = useIntl()
  return (
    <Container>
      <Wrapper>
        <div
          style={{
            display: 'grid',
            placeItems: 'center',
          }}
        >
          <Outlet />
        </div>
        <ContainerImg>
          <ContentImg>
            <img alt='Atro' src={toAbsoluteUrl('/media/backgrounds/Artboard-login.png')} />
            <h4>{intl.formatMessage({id: 'LOGIN.PAGE.TITLE'})}</h4>
            <p>
              {intl.formatMessage({id: 'LOGIN.PAGE.TEXT'})}
            </p>
            <img style={{ width: "200px", marginTop: "10px" }} alt='Logo' src={toAbsoluteUrl('/media/backgrounds/early-access.png')} />
          </ContentImg>
        </ContainerImg>
      </Wrapper>
    </Container>
  )
}

/**
 * Router for the RecoveryPage page
 * @component
 */
const RecoveryPage = () => (
  <Routes>
    <Route element={<RecoveryLayout />}>
      <Route index element={<Recovery />} />
    </Route>
  </Routes>
)

export {RecoveryPage}
