import {MarketingReport} from './core/types'
import {Container} from 'react-bootstrap'
import {MarketingIndicators} from './components/MarketingIndicators'
import { TableCampaigns } from './components/CampaignsTable'
import { TableCard } from './components/styled'

export const ReportMarketing = ({report}: {report: MarketingReport}) => {
  return (
    <Container>
      <div>
        <MarketingIndicators indicator={report.indicators} />
      </div>
      <TableCard>
        <TableCampaigns data={report.campaigns}/>
      </TableCard>
    </Container>
  )
}
