import {FC} from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import {headFormatterDashboard} from '../../../../../_gws/partials/tables/TableHelper'
import filterFactory from 'react-bootstrap-table2-filter'
import {useIntl} from 'react-intl'
import {NoDataLabelCheck} from '../../../../../_gws/partials/content/list/NoDataLabelCheck'
import {ProductWithImageDashboard} from '../../../../../_gws/partials/misc'
import {Link, useNavigate} from 'react-router-dom'
import {BasicLabelWithIcon} from '../../../../../_gws/partials/newComponets/Labels'
import {brandHealthDictionary} from '../../../../../_gws/helpers/miscConst'
import {AgText} from '../../../../../_gws/layout/components/ag/AgText'
import {AgTitleCard} from '../../../../../_gws/layout/components/ag/components/AgTitleCard'
import {addCommasInNumber} from '../../../../../_gws/helpers/misc'
import styled from '@emotion/styled'
import {Button} from 'react-bootstrap'
import {CardTable} from '../../../salesReport/components/styled'

type Props = {
  data?: any
}

export const StyledButton = styled(Button)({
  background: '#0D7CBD !important',
  color: '#fff !important',
})
const TableDashboardWrapper: FC<Props> = ({data}) => {
  const navigate = useNavigate()
  const intl = useIntl()

  const product = data?.top?.slice(0, 10)

  const columnsBase = [
    {
      dataField: 'top',
      text: 'Top',
      sort: true,
      headerFormatter: headFormatterDashboard,
      style: {
        minWidth: '8%',
        color: 'black',
      },
      classes: 'align-middle',
      formatter: (cellContent: any, row: any) => {
        return <AgText choice={'small'} text={cellContent} customClass='text-center' />
      },
    },
    {
      dataField: 'product',
      text: intl.formatMessage({id: 'REPORT.STATE_RESULT.TABLE_HEAD.PRODUCT'}),
      headerFormatter: headFormatterDashboard,
      style: {
        minWidth: '50%',
      },
      classes: 'align-middle',
      formatter: (cellContent: any, row: any) => {
        return (
          <ProductWithImageDashboard
            name={row.name}
            asinId={row.asin}
            imgUrl={row.image}
            marketplaceId={row.marketplace}
            color='gray-800'
          />
        )
      },
    },
    {
      dataField: 'marketplace',
      text: '',
      sort: true,
      style: {
        minWidth: '12%',
        color: 'black',
      },
      classes: 'align-middle',
      headerFormatter: headFormatterDashboard,
      formatter: (cellContent: any, row: any) => {
        return (
          <AgText
            choice={'small'}
            text={row.marketplace === 1 ? 'Amazon' : 'Mercado Libre'}
            customClass='text-center'
          />
        )
      },
    },
    {
      dataField: 'totalSales',
      text: intl.formatMessage({id: 'STRATEGY.SALES.SALES'}),
      sort: true,
      style: {
        color: 'black',
      },
      classes: 'align-middle',
      headerFormatter: headFormatterDashboard,
      formatter: (cellContent: any, row: any) => {
        return (
          <AgText
            choice={'small'}
            text={`$${addCommasInNumber(Number(row.sales.sold?.toFixed(2)))}`}
          />
        )
      },
    },
    {
      dataField: 'productHealth',
      text: intl.formatMessage({id: `MENU.REPORTS.PRODUCT_HEALTH`}),
      sort: true,
      headerFormatter: headFormatterDashboard,
      style: {
        minWidth: '20%',
      },
      classes: 'align-middle',
      formatter: (cellContent: any, row: any) => {
        return (
          <Link to={`/product-health/${row.marketplace === 1 ? 'amazon' : 'meli'}/${row.asin}`}>
            <BasicLabelWithIcon
              text={intl.formatMessage({
                id: `BRAND_HEALTH.${row.product_health}`,
              })}
              backgroundColor={brandHealthDictionary[row.product_health]?.backgroundColor}
              color={brandHealthDictionary[row.product_health]?.color}
              //icon={brandHealthDictionary[row.product_health]?.icon}
              svgColor={brandHealthDictionary[row.product_health]?.svgColor}
              customClass={'justify-content-center'}
            />
          </Link>
        )
      },
    },
    {
      dataField: 'Stock',
      text: 'stock',
      sort: true,
      style: {
        color: 'black',
      },
      classes: 'align-middle',
      headerFormatter: headFormatterDashboard,
      formatter: (cellContent: any, row: any) => {
        return (
          <AgText choice={'small'} text={`${addCommasInNumber(Number(row.stock.toFixed(2)))}`} />
        )
      },
    },
    {
      dataField: 'units',
      text: 'U.Vendidas',
      sort: true,
      style: {
        color: 'black',
      },
      classes: 'align-middle',
      headerFormatter: headFormatterDashboard,
      formatter: (cellContent: any, row: any) => {
        return (
          <AgText
            choice={'small'}
            text={`${addCommasInNumber(Number(row.sales.units?.toFixed(2)))}`}
          />
        )
      },
    },
    {
      dataField: 'sold',
      text: 'utilidad',
      sort: true,
      style: {
        color: 'black',
      },
      classes: 'align-middle',
      headerFormatter: headFormatterDashboard,
      formatter: (cellContent: any, row: any) => {
        return (
          <AgText
            choice={'small'}
            text={`$${addCommasInNumber(Number(row.sales.sold?.toFixed(2)))}`}
          />
        )
      },
    },
  ]

  return (
    <>
      <CardTable
        style={{
          marginTop: '0rem',
        }}
      >
        <AgTitleCard text={'Ranking de productos'} />
        <div className='card-body d-flex flex-column p-0 pt-4'>
          <NoDataLabelCheck
            card={false}
            arrayToCheck={data}
            component={
              <>
                <BootstrapTable
                  columns={columnsBase}
                  data={product}
                  keyField={'uniqueId'}
                  filter={filterFactory()}
                  bootstrap4={true}
                  hover={true}
                  striped={false}
                  classes={'table table-row-gray-300 gy-3 gs-3 mb-0'}
                  wrapperClasses={'table-responsive position-relative'}
                  bodyClasses={'text-gray-600 fw-semibold'}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <StyledButton onClick={() => navigate('/inventory')} size='sm'>
                    {intl.formatMessage({id: 'MENU.DASHBOARD.SEE_MORE'})}
                  </StyledButton>
                </div>
              </>
            }
          />{' '}
        </div>
      </CardTable>
    </>
  )
}

export {TableDashboardWrapper}
