import {FC, useState, createContext, useContext, useMemo} from 'react'
import {
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
} from '../../../../_gws/helpers/crud-helper/helpers'

import {useQueryResponse, useQueryResponseData} from './QueryResponseProvider'
import {ID, initialListView, inventoryDataResponseI, ListViewContextProps} from './_models'

const ListViewContext = createContext<ListViewContextProps>(initialListView)

const ListViewProvider: FC<any> = ({children}) => {
  const [selected, setSelected] = useState<Array<ID>>(initialListView.selected)
  const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(initialListView.itemIdForUpdate)
  const [itemIdForUpdateNew, setItemIdForUpdateNew] = useState<ID>(
    initialListView.itemIdForUpdateNew
  )
  const [itemUpdated, setItemUpdated] = useState<any>(initialListView.itemUpdated)
  const {isLoading} = useQueryResponse()
  const data: inventoryDataResponseI[] | undefined = useQueryResponseData() as inventoryDataResponseI[] 
  const disabled = useMemo(() => calculatedGroupingIsDisabled<inventoryDataResponseI> (isLoading, data), [isLoading, data])
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])
  return (
    <ListViewContext.Provider
      value={{
        selected,
        itemIdForUpdate,
        setItemIdForUpdate,
        itemIdForUpdateNew,
        setItemIdForUpdateNew,
        itemUpdated,
        setItemUpdated,
        disabled,
        isAllSelected,
        onSelect: (id: ID) => {
          groupingOnSelect(id, selected, setSelected)
        },
        onSelectAll: () => {
          groupingOnSelectAll(isAllSelected, setSelected, data)
        },
        clearSelected: () => {
          setSelected([])
        },
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export {ListViewProvider, useListView}
