import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import {ToolkitContextType} from 'react-bootstrap-table2-toolkit'
//@ts-ignore
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'

import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css'
import {headFormatter, optionsTable} from '../../../../../_gws/partials/tables/TableHelper'
import {CustomSearchForm} from '../../../../../_gws/partials/tables/CustomSearchForm'
import {TableAvatarUser} from '../../../../../_gws/partials/tables/TableAvatarUser'
import {CustomSizePerPage} from '../../../../../_gws/partials/tables/CustomSizePerPage'
import {CustomPageList} from '../../../../../_gws/partials/tables/CustomPageList'
import {CustomTotalRemote} from '../../../../../_gws/partials/tables/CustomTotalRemote'

/**
 * Component Props
 */
type LogTableProps = {
  logs: any
  pageInfo: any
  searchTerm?: string
  count?: number
}

/**
 * Show logs audit with a table Component
 * @param logs - rows of the request
 * @param pageInfo - information about pagination
 * @param searchTerm -  Search term to handle pagination
 * @param count - Count or Page Size to handle pagination
 * @constructor
 */
const LogTable: FC<LogTableProps> = ({logs = [], pageInfo = {}, searchTerm = '', count = 10}) => {
  const intl = useIntl()

  const columns = [
    {
      dataField: 'userPicture',
      text: intl.formatMessage({id: 'AUDIT.TABLE.COL.TITLE.USER'}),
      sort: false,
      searchable: false,
      headerFormatter: headFormatter,
      formatter: (cellContent: any, row: any) => {
        return (
          <TableAvatarUser
            userID={row.userId}
            picUrl={row.userPicture}
            name={row.userFullname}
            email={row.userEmail}
          />
        )
      },
      style: {
        width: '40%',
      },
    },
    {
      dataField: 'endpoint',
      text: intl.formatMessage({id: 'AUDIT.TABLE.COL.TITLE.ACTION'}),
      sort: false,
      searchable: true,
      headerFormatter: headFormatter,
      style: {
        width: '30%',
      },
    },
    {
      dataField: 'status',
      text: intl.formatMessage({id: 'AUDIT.TABLE.COL.TITLE.STATE'}),
      sort: false,
      searchable: true,
      headerFormatter: headFormatter,
      style: {
        width: '10%',
      },
    },
    {
      dataField: 'startDate',
      text: intl.formatMessage({id: 'AUDIT.TABLE.COL.TITLE.STARTED_LOG'}),
      sort: false,
      searchable: true,
      headerFormatter: headFormatter,
      style: {
        width: '10%',
      },
    },
    {
      dataField: 'endDate',
      text: intl.formatMessage({id: 'AUDIT.TABLE.COL.TITLE.FINISHED_LOG'}),
      sort: false,
      searchable: true,
      headerFormatter: headFormatter,
      style: {
        width: '10%',
      },
    },
  ]

  /**
   * Calling Custom table component for total resume
   * @param props
   */
  const customTotalRemote = (props: any) => (
    <CustomTotalRemote props={props} pageInfo={pageInfo} count={count} />
  )

  /**
   * Calling Custom table component for Dropdown page size
   * @param props
   */
  const customSizePerPage = (props: any) => <CustomSizePerPage props={props} count={count} />

  /**
   * Calling Custom table component for list navigation buttons
   * @param props
   * @param totalPages - Total pages of the data with page size defined
   */
  const customPageList = (props: any, totalPages: number) => (
    <CustomPageList props={props} totalPages={pageInfo.total_pages} />
  )

  /**
   * Handle to manipulate pagination of table using redirections to generate the new request
   * @param type - search/pagination
   * @param props
   */
  const handleTableChange = (type: any, props: any) => {
    /**
     * If searched, the page and size is set to defaults
     */
    if (type === 'search') {
      window.location.href = `/apps/audit/1/10/${props.searchText}`
    } else {
      /**
       * If actual count is different to new sizePerPage, possible new dropdown value
       * count is the actual url value
       */
      if (count !== props.sizePerPage) {
        /**
         * Fix for last page and sizePerPage value, we use count instead sizePerPage to repair the size of the page when navigate to other page
         */
        if (pageInfo.current_page === pageInfo.total_pages) {
          window.location.href = `/apps/audit/${props.page}/${count}/${searchTerm}`
        } else {
          window.location.href = `/apps/audit/1/${props.sizePerPage}/${searchTerm}`
        }
      } else {
        window.location.href = `/apps/audit/${props.page}/${count}/${searchTerm}`
      }
    }
  }

  return (
    <>
      <ToolkitProvider columns={columns} data={logs} keyField={'id'} search>
        {(props: ToolkitContextType) => (
          <div>
            <CustomSearchForm
              {...props.searchProps}
              placeholder={intl.formatMessage({id: 'AUDIT.TABLE.COL.TITLE.EMAIL'})}
              button={true}
              searchTerm={searchTerm}
            />
            <div className='separator separator-dashed my-5'></div>
            <BootstrapTable
              {...props.baseProps}
              bootstrap4={true}
              striped={false}
              hover={true}
              remote={{pagination: true, filter: false, sort: false}}
              pagination={paginationFactory(
                optionsTable(
                  customTotalRemote,
                  intl.locale,
                  3,
                  1,
                  pageInfo.current_page,
                  pageInfo.page_records,
                  pageInfo.total_records,
                  customSizePerPage,
                  customPageList
                )
              )}
              onTableChange={handleTableChange}
              filter={filterFactory()}
              classes={'table table-row-gray-300 gy-5 gs-5'}
              wrapperClasses={'table-responsive position-relative'}
              bodyClasses={'text-gray-600 fw-regular'}
              rowClasses={'align-middle'}
              defaultSorted={[
                {
                  dataField: 'assigned',
                  order: 'desc',
                },
              ]}
            />
          </div>
        )}
      </ToolkitProvider>
    </>
  )
}

export {LogTable}
