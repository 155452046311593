import {ObjectColors, ObjectValues, WrapperLineChart} from './components/WrapperLineChart'
import React, {FC, useEffect, useRef, useState} from 'react'
import {ChartsLabels} from './ChartsLabels'

import {Chart as ChartJS} from 'chart.js'

type Props = {
  dataLabels: string[] | number[]
  dataColors: ObjectColors[]
  dataValues: ObjectValues[]
  chartTitleYAxis?: string
  chartTitleY1Axis?: string
  chartTitleXAxis?: string
  hasLegend?: boolean
  cleanChart?: boolean
  canSeeTooltip?: boolean
}
/**
 * Line chart component with gradient and possibility to have 2 Y axis
 *
 * @param dataLabels - Labels of the X axis
 * @param dataColors - Colors of the lines this should have the gradientEnd, gradientStart and borderColor
 * @param dataValues - Values of the lines, this array of object has some the type of ObjectValues {
 *   axis?: string : if this is defined, the value will be displayed in the second Y axis
 *   label: string : Label of the line
 *   values: string[] : Values of the line
 * }
 * @param chartTitleYAxis - Title of the Y axis
 * @param chartTitleY1Axis - Title of the Y1 axis
 * @param chartTitleXAxis - Title of the X axis
 * @param hasLegend - If true, the legend will be displayed
 * @param cleanChart - If true, the grid and axis will be displayed
 * @param canSeeTooltip - If true, the tooltip will be displayed
 * @example:
 * <GwsLineChart
 *  dataLabels={['Enero', 'Febrero', 'Marzo', 'Abril']}
 *  dataColors={[
 *    {
 *      borderColor: 'rgba(80,187,204,1)',
 *      gradientEnd: 'rgba(198,241,250,0.40)',
 *      gradientStart: 'rgba(253,254,254,0.50)',
 *    },
 *  ]}
 *  dataValues={[
 *    {label: 'Clicks', values: ['37', '195', '81', '120']},
 *  ]}
 *  />
 */
const GwsLineChart: FC<Props> = ({
  dataLabels,
  dataColors,
  dataValues,
  chartTitleYAxis,
  chartTitleY1Axis,
  chartTitleXAxis,
  hasLegend = false,
  cleanChart = false,
  canSeeTooltip = false,
}) => {
  let baseColorObject = {
    borderColor: 'rgba(195,193,193,0.08)',
    gradientEnd: 'rgba(223,223,223,0.06)',
    gradientStart: 'rgba(255,255,255,0.04)',
  }

  /**
   * Set the default color of the bars and legends
   */
  const [dataColor, setDataColor] = useState<ObjectColors[]>(dataColors)
  const chartRef = useRef<ChartJS>(null)

  let arrayLabelLegend = dataValues.map((item) => item.label)

  let dataColorsArray = dataColor.map((item: ObjectColors) => item.borderColor)

  const updateOrderAndColor = (index: number) => {
    let newDataColors = dataColors.map((item: ObjectColors, i: number) => {
      if (i === index) {
        return item
      } else {
        return baseColorObject
      }
    })
    setDataColor(newDataColors)
  }
  /**
   *  Change the color of the bar when mouse enter
   * @param index
   */
  const handleMouseIn = (index: number) => {
    updateOrderAndColor(index)
  }

  const handelMouseOut = () => {
    setDataColor(dataColors)
  }

  useEffect(() => {
    chartRef.current?.update()
  }, [])

  return (
    <div className='row d-flex justify-content-center align-items-center p-0 m-0'>
      {hasLegend && (
        <div
          className={`col-12 d-flex justify-content-start align-items-end  ${
            !cleanChart && 'p-0 pt-3'
          }`}
        >
          <ChartsLabels
            labels={arrayLabelLegend}
            colors={dataColorsArray}
            handleMouseIn={handleMouseIn}
            handelMouseOut={handelMouseOut}
            isRow={true}
          />
        </div>
      )}
      <div
        className={`col-12 d-flex justify-content-end align-items-end  ${cleanChart && 'p-0 pt-3'} p-0`}
      >
        <WrapperLineChart
          ref={chartRef}
          dataLabels={dataLabels}
          dataColors={dataColor}
          dataValues={dataValues}
          titleXAxis={chartTitleXAxis}
          titleYAxis={chartTitleYAxis}
          titleY1Axis={chartTitleY1Axis}
          cleanChart={cleanChart}
          canSeeTooltip={canSeeTooltip}
        />
      </div>
    </div>
  )
}

export {GwsLineChart}
