import React from 'react'
import {HealthAside} from './groupAsideSaas/HealthAside'
import {SettingsAside} from './groupAsideSaas/SettingsAside'
import {HomeAside} from './groupAsideSaas/HomeAside'
import {useAuth} from '../../../../../app/modules/auth'
import {InventoryAside} from "./groupAsideSaas/InventoryAside";
import {SalesAside} from "./groupAsideSaas/SalesAside";
import {MarketingAside} from "./groupAsideSaas/MarketingAside";

const PartnerAside = () => {
  const {currentUser, currentClient} = useAuth()

  let isMerchant = currentUser?.roleId === 3
  let isClient = currentUser?.roleId === 1

  let merchantHasClientF = currentClient !== undefined

  /*
   * Flag that is true when the current user is a client, but if is a merchant, should have a client selected
   */
  let showAsClient = isClient || (isMerchant && merchantHasClientF)

  return (
    <>
      <HomeAside />
      {showAsClient && (
        <>
            <HealthAside />
            <InventoryAside />
            <SalesAside />
            <MarketingAside />
            <SettingsAside />
        </>
      )}
    </>
  )
}

export {PartnerAside}
