import {useEffect, useState} from 'react'
import {Dashboard} from './Dashboard'
import {PageTitle} from '../../../_gws/layout/core'
import {useIntl} from 'react-intl'
import {AlertFetchError, Splash} from '../../../_gws/helpers/misc'
import {getDashboardData} from './core/_request'
import useSWR from 'swr'
import {WaitingScreen} from '../../../_gws/partials/content/waiting/WaitingScreen'
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const DashboardClientContainer = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const alert = withReactContent(Swal)
  const hasBeenVerified = localStorage.getItem('verified') === 'true'
  const {data, error, isLoading} = useSWR(
    () => (hasBeenVerified ? ['/growmmary'] : null),
    () => getDashboardData(),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    }
  )

  const [showReport, setShowReport] = useState(false)
  const [dataDashboard, setDataDashboard] = useState<any>({})

  const fetchDashboardData = async () => {
    if (error) return AlertFetchError(intl.locale, error).fire()
    if (data) {
      setDataDashboard(data.data)
      setShowReport(true)
    }
  }

  useEffect(() => {
    if (!isLoading && data) {
      fetchDashboardData()
    }
    if (!isLoading && !data && error) {
      if (error.response.status === 500) {
        AlertFetchError(intl.locale, error).fire()
      }
      if (error.response.status === 422) {
        alert.fire({
          icon: 'info',
          confirmButtonText: intl.formatMessage({id: 'ACCOUNT.PROFILE.DETAILS.OK_GOT_IT'}),
          iconColor: '#00c6c7',
          text: intl.formatMessage({id: 'GENERAL.ALERT.422'}),
          customClass: {
            confirmButton: 'btn btn-primary',
            popup: 'bg-light',
          },
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, error])

  useEffect(() => {
    Splash(isLoading)
  }, [isLoading])

  useEffect(() => {
    if (!hasBeenVerified) return navigate('/account/overview')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasBeenVerified])

  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: intl.formatMessage({id: 'MENU.HOME'}),
            path: '/dashboard',
            isSeparator: false,
            isActive: false,
          },
          {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.DASHBOARD'})}
      </PageTitle>
      {hasBeenVerified ? (
        <>{showReport && <Dashboard dataReport={dataDashboard} />}</>
      ) : (
        <WaitingScreen />
      )}
    </>
  )
}

export {DashboardClientContainer}
