import React, {useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
//@ts-ignore
import {LandedCostEditModal} from './modals/LandedCostEditModal'
import {useListView} from './core/ListViewProvider'
import {ProductWithImage} from '../../../_gws/partials/misc'
import {BasicLabelWithIcon} from '../../../_gws/partials/newComponets/Labels'
import {addCommasInNumber, makeURL} from '../../../_gws/helpers/misc'
import parse from 'html-react-parser'
import {brandHealthDictionary} from '../../../_gws/helpers/miscConst'
import {Link} from 'react-router-dom'
import {
  ActionsTop,
  Cell,
  Container,
  ContentCell,
  ExportCSV,
  FilterAction,
  HeaderCell,
  Search,
  Table,
  TableTitle,
  WrapperActions,
  WrapperPagination,
  WrapperTable,
} from './components/InventoryTable/styled'
import {TableActions} from './components/TableActions'
import {
  sortByNumberSales,
  sortByNumber,
  sortByString,
  sortByNumberForcast,
  sortByFBA,
  sortBySalesUnits,
} from './core/filters'
import {mkConfig, generateCsv, download} from 'export-to-csv'
// import {marketPlaceData} from './core/_models'
import {Pagination} from '../playlist/components/Table/components/Pagination'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import {GlobalInfoContainer, InfoCard} from '../salesStrategy/components/styled'
import {IconInventory, IconProducts} from '../salesReport/components/SalesIcon'
// import {Form} from 'react-bootstrap'

type salesI = {
  top_revenue: number
  top: number
  sold: number
  marketplace_fee: number
  discounts: number
  operating_income: number
  sales_revenue: number,
}

export interface dataTable {
  amountProducts?: number
  amountUniqueProducts?: number
  products?: [
    {
      asin?: string
      description?: string
      image?: string
      landed_cost?: number
      name?: string
      product_health?: string
      sold?: number
      sales?: salesI
      top?: number
      isFba?: boolean
    }
  ]
}

type Props = {
  dataTable?: any
  isDead: boolean
  handlerShowDeadProducts: any
}

const columns = {
  secondary: [
    {
      label: 'Product Health',
      field: 'product_health',
      sort: false,
      sortOrder: 'asc',
    },
    {
      label: 'Id del producto',
      field: 'asin',
      sort: false,
      sortOrder: 'asc',
    },
    {
      label: 'Forecast(Unidades)',
      field: 'units',
      sort: true,
      sortOrder: 'asc',
    },
    {
      label: 'Unidades vendidas',
      field: 'sales_units',
      sort: true,
      sortOrder: 'asc',
    },
    {
      label: 'Forecast(Moneda)',
      field: 'money',
      sort: true,
      sortOrder: 'asc',
    },
    {
      label: 'Marketplace',
      field: 'marketplace',
      sort: true,
      sortOrder: 'asc',
    },
    {
      label: 'Tipo',
      field: 'isFba',
      sort: true,
      sortOrder: 'asc',
    },
    {
      label: 'Ventas totales',
      field: 'sales_revenue',
      sort: true,
      sortOrder: 'asc',
    },
    {
      label: 'Landed cost',
      field: 'landed_cost',
      sort: false,
      sortOrder: 'asc',
    },
    {
      label: 'Fees de Marketplaces',
      field: 'marketplace_fee',
      sort: true,
      sortOrder: 'asc',
    },
    {
      label: 'Inversion en publicidad',
      field: 'ads_cost',
      sort: true,
      sortOrder: 'asc',
    },
    {
      label: 'Costos totales',
      field: 'discounts',
      sort: true,
      sortOrder: 'asc',
    },
    {
      label: 'Ingreso Operativo',
      field: 'operating_income',
      sort: true,
      sortOrder: 'asc',
    },
    {
      label: 'Utilidad',
      field: 'sold',
      sort: true,
      sortOrder: 'asc',
    },
  ],
  primary: [
    {
      label: 'Ranking venta',
      field: 'top_revenue',
      sort: true,
      sortOrder: 'asc',
    },
    {
      label: 'Ranking utilidad',
      field: 'top',
      sort: true,
      sortOrder: 'asc',
    },
    {
      label: 'Producto',
      field: 'product',
      sort: false,
      sortOrder: 'asc',
    },
  ],
}

const indexed = (key: string, arr: any[]) =>
  arr.reduce((acc, el) => {
    acc[el[key]] = el
    return acc
  }, {})

export const SearcherAsin = ({data, onUpdate}: {data: any; onUpdate: any}) => {
  const [searchTerm] = useState({
    term: '',
    indexedData: indexed('asin', data),
  })
  const handlerSearch = (term: string) => {
    const findedElements = Object.keys(searchTerm.indexedData)
      .filter((asin) => new RegExp(term).test(asin))
      .map((code) => ({...searchTerm.indexedData[code]}))
    onUpdate(findedElements)
  }
  return (
    <Search>
      <span>
        <svg
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='#BBBBBB'
          width={20}
          height={20}
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z'
          />
        </svg>
      </span>
      <input
        type='text'
        placeholder='Buscar...'
        onChange={(e) => handlerSearch(e.target.value.toUpperCase())}
      />
    </Search>
  )
}

export const InventoryTable = ({
  data,
  columns,
  handlerShowDeadProducts,
  isDead,
}: {
  data: any[]
  columns: any
  handlerShowDeadProducts: any
  isDead: boolean
}) => {
  const csvConfig = mkConfig({
    useKeysAsHeaders: true,
    quoteCharacter: "'",
    quoteStrings: false,
  })
  const intl = useIntl()
  const [state, setState] = useState({
    data: data,
    columns: columns,
  })
  let PageSize = 10
  const [currentPage, setCurrentPage] = useState(1)
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    return state.data.slice(firstPageIndex, lastPageIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, state.data])

  useEffect(() => {
    setState((pre) => ({
      ...pre,
      data: data,
    }))
  }, [data])

  const handlerUpdateItem = (item: any, asin: string) => {
    const updateData = state.data.map((element) => {
      if (element.asin === asin) {
        return {
          ...item,
        }
      }
      return element
    })
    setState((pre) => ({
      ...pre,
      data: updateData,
    }))
  }
  const makeLink = (asin: any, marketplace: any) => {
    const link = makeURL(asin, marketplace)
    return link && parse(link)
  }

  const handlerUpdateData = (data: any[]) => {
    setState((pre) => ({
      ...pre,
      data: data,
      total: data.length,
    }))
  }

  const hanlderSort = (sortOrder: string, sortField: string, typeColumn: string) => {
    const updateColumns = state.columns[typeColumn].map((item: any) => {
      if (item.field === sortField) {
        return {
          ...item,
          sortOrder: item.sortOrder === 'asc' ? 'desc' : 'asc',
        }
      }
      return item
    })
    const SortAction: any = {
      top_revenue: sortByNumberSales,
      top: sortByNumberSales,
      units: sortByNumberForcast,
      money: sortByNumberForcast,
      marketplace: sortByNumber,
      asin: sortByString,
      sold: sortByNumberSales,
      marketplace_fee: sortByNumberSales,
      ads_cost: sortByNumber,
      discounts: sortByNumberSales,
      operating_income: sortByNumberSales,
      sales_revenue: sortByNumberSales,
      isFba: sortByFBA,
      sales_units: sortBySalesUnits,
    }
    setState((pre) => ({
      ...pre,
      data: SortAction[sortField](sortOrder, pre.data, sortField),
      columns: {
        ...state.columns,
        [typeColumn]: updateColumns,
      },
    }))
  }

  const exportToCSV = () => {
    const data = state.data
    const format = data.map((item: any) => ({
      name: item.name.replaceAll(',', ' '),
      ASIN: item.asin,
      image: item.image,
      forecast_units: item.forecast.units,
      forecast_money: item.forecast.money,
      landed_cost: item.landed_cost,
      marketplace: item.marketplace === 1 ? 'Amazon' : 'Mercado Libre',
      product_health: item.product_health,
      total_sales: item.sales.sold,
      marketplace_fee: item.sales.marketplace_fee,
      ads_cost: item.ads_cost,
      discounts: item.sales.discounts,
      operating_income: item.sales.operating_income,
      sales_revenue: item.sales.sales_revenue,
    }))
    const csv = generateCsv(csvConfig)(format)
    download(csvConfig)(csv)
  }
  return (
    <>
      <Container>
        <ActionsTop>
          <TableTitle>
            <h4>{intl.formatMessage({id: 'MENU.REPORTS.INVENTORY'})}</h4>
            <p>{intl.formatMessage({id: 'MENU.REPORTS.INVENTORY.SUBTITLE'})}</p>
          </TableTitle>

          <WrapperActions>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <input
                onChange={handlerShowDeadProducts}
                checked={isDead}
                type='checkbox'
                style={{marginRight: '5px'}}
              />
              <span>{intl.formatMessage({id: 'INVENTORY.FILTER.DEAD.PRODUCT'})}</span>
            </div>
            <SearcherAsin onUpdate={handlerUpdateData} data={state.data} />
            <ExportCSV onClick={exportToCSV}>
              <svg
                width='14'
                height='14'
                viewBox='0 0 14 14'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M1.55556 14C1.12778 14 0.761574 13.8477 0.456944 13.5431C0.152315 13.2384 0 12.8722 0 12.4444V10.1111C0 9.89074 0.074537 9.70602 0.223611 9.55694C0.372685 9.40787 0.557407 9.33333 0.777778 9.33333C0.998148 9.33333 1.18287 9.40787 1.33194 9.55694C1.48102 9.70602 1.55556 9.89074 1.55556 10.1111V12.4444H12.4444V1.55556H1.55556V3.88889C1.55556 4.10926 1.48102 4.29398 1.33194 4.44306C1.18287 4.59213 0.998148 4.66667 0.777778 4.66667C0.557407 4.66667 0.372685 4.59213 0.223611 4.44306C0.074537 4.29398 0 4.10926 0 3.88889V1.55556C0 1.12778 0.152315 0.761574 0.456944 0.456944C0.761574 0.152315 1.12778 0 1.55556 0H12.4444C12.8722 0 13.2384 0.152315 13.5431 0.456944C13.8477 0.761574 14 1.12778 14 1.55556V12.4444C14 12.8722 13.8477 13.2384 13.5431 13.5431C13.2384 13.8477 12.8722 14 12.4444 14H1.55556ZM6.72778 7.77778H0.777778C0.557407 7.77778 0.372685 7.70324 0.223611 7.55417C0.074537 7.40509 0 7.22037 0 7C0 6.77963 0.074537 6.59491 0.223611 6.44583C0.372685 6.29676 0.557407 6.22222 0.777778 6.22222H6.72778L5.28889 4.78333C5.13333 4.62778 5.0588 4.4463 5.06528 4.23889C5.07176 4.03148 5.1463 3.85 5.28889 3.69444C5.44444 3.53889 5.62917 3.45787 5.84306 3.45139C6.05694 3.44491 6.24167 3.51944 6.39722 3.675L9.17778 6.45556C9.25556 6.53333 9.31065 6.61759 9.34306 6.70833C9.37546 6.79907 9.39167 6.8963 9.39167 7C9.39167 7.1037 9.37546 7.20093 9.34306 7.29167C9.31065 7.38241 9.25556 7.46667 9.17778 7.54444L6.39722 10.325C6.24167 10.4806 6.05694 10.5551 5.84306 10.5486C5.62917 10.5421 5.44444 10.4611 5.28889 10.3056C5.1463 10.15 5.07176 9.96852 5.06528 9.76111C5.0588 9.5537 5.13333 9.37222 5.28889 9.21667L6.72778 7.77778Z'
                  fill='#212121'
                />
              </svg>
              <span style={{marginLeft: '5px'}}>Exportar</span>
            </ExportCSV>
          </WrapperActions>
        </ActionsTop>

        <WrapperTable>
          <Table>
            <tr>
              {state.columns.primary.map((item: any, i: number) => (
                <HeaderCell
                  onClick={() =>
                    item.sort ? hanlderSort(item.sortOrder, item.field, 'primary') : null
                  }
                  key={i}
                >
                  {intl.formatMessage({
                    id: `MENU.REPORTS.INVENTORY.TABLE.HEADER.${item.field.toUpperCase()}`,
                  })}
                  <FilterAction>
                    <svg
                      width='12'
                      height='18'
                      viewBox='0 0 12 18'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M6 0L11.1962 6.58536H0.803847L6 0Z'
                        fill={item.sortOrder === 'asc' ? '#9E9E9E' : '#9E9E9E33'}
                      />
                      <path
                        d='M6 17.561L0.80385 10.9756L11.1962 10.9756L6 17.561Z'
                        fill={item.sortOrder === 'desc' ? '#9E9E9E' : '#9E9E9E33'}
                      />
                    </svg>
                  </FilterAction>
                </HeaderCell>
              ))}
            </tr>

            {currentTableData.map((item: any, i: any) => (
              <ContentCell key={i}>
                <Cell>{item.sales?.top_revenue}</Cell>
                <Cell>{item.sales?.top}</Cell>
                <Cell>
                  <ProductWithImage
                    name={item.name}
                    asinId={item.id}
                    imgUrl={item.image}
                    color='gray-900'
                  />
                </Cell>
              </ContentCell>
            ))}
          </Table>

          <Table style={{borderLeft: '5px solid #f1f1f1'}}>
            <tr>
              {state.columns.secondary.map((item: any, i: number) => (
                <HeaderCell
                  onClick={() =>
                    item.sort ? hanlderSort(item.sortOrder, item.field, 'secondary') : null
                  }
                  key={i}
                >
                  {item.field === 'units' || item.field === 'money' ? (
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip>
                          {intl.formatMessage({
                            id: 'MENU.REPORTS.INVENTORY.TABLE.HEADER.FORECAST_TOOLTIP',
                          })}
                        </Tooltip>
                      }
                    >
                      <span>
                        {intl.formatMessage({
                          id: `MENU.REPORTS.INVENTORY.TABLE.HEADER.${item.field.toUpperCase()}`,
                        })}
                      </span>
                    </OverlayTrigger>
                  ) : (
                    <span>
                      {intl.formatMessage({
                        id: `MENU.REPORTS.INVENTORY.TABLE.HEADER.${item.field.toUpperCase()}`,
                      })}
                    </span>
                  )}
                  <FilterAction>
                    <svg width='12' height='18' viewBox='0 0 12 18' fill='none'>
                      <path
                        d='M6 0L11.1962 6.58536H0.803847L6 0Z'
                        fill={item.sortOrder === 'asc' ? '#9E9E9E' : '#9E9E9E33'}
                      />
                      <path
                        d='M6 17.561L0.80385 10.9756L11.1962 10.9756L6 17.561Z'
                        fill={item.sortOrder === 'desc' ? '#9E9E9E' : '#9E9E9E33'}
                      />
                    </svg>
                  </FilterAction>
                </HeaderCell>
              ))}
            </tr>

            {currentTableData.map((item: any, i: any) => (
              <ContentCell key={i}>
                <Cell style={{padding: '16.1px'}}>
                  <Link
                    to={`/product-health/${item.marketplace === 1 ? 'amazon' : 'meli'}/${
                      item.asin
                    }`}
                  >
                    <BasicLabelWithIcon
                      text={intl.formatMessage({
                        id: `BRAND_HEALTH.${item.product_health || 'NO_INFORMATION'}`,
                      })}
                      backgroundColor={
                        brandHealthDictionary[item.product_health || 'NULL']?.backgroundColor
                      }
                      color={brandHealthDictionary[item.product_health || 'NULL']?.color}
                      svgColor={brandHealthDictionary[item.product_health || 'NULL']?.svgColor}
                    />
                  </Link>
                </Cell>
                <Cell block={false}>{makeLink(item.asin, item.marketplace)}</Cell>
                <Cell block={false}>{item.forecast?.units ?? 0}</Cell>
                <Cell block={false}>{item.sales?.units ?? 0}</Cell>
                <Cell block={false}>{`$${addCommasInNumber(item.forecast?.money ?? 0)}`}</Cell>
                <Cell block={false}>{item.marketplace === 1 ? 'Amazon' : 'Mercado Libre'}</Cell>
                <Cell block={false}>{item.isFba ? 'FBA' : 'FBM'}</Cell>
                <Cell block={false}>{`$${addCommasInNumber(
                  Number(item.sales?.sold?.toFixed(2) ?? '0.00')
                )}`}</Cell>
                <Cell block={false}>
                  <TableActions handlerUpdateItem={handlerUpdateItem} item={item} />
                </Cell>
                <Cell block={false}>{`$${addCommasInNumber(
                  Number(item.sales.marketplace_fee?.toFixed(2) ?? '0.00')
                )}`}</Cell>
                <Cell block={false}>${addCommasInNumber(item.ads_cost ?? 0)}</Cell>
                <Cell block={false}>${addCommasInNumber(item.sales.discounts ?? 0)}</Cell>
                <Cell block={false}>${addCommasInNumber(item.sales.operating_income ?? 0)}</Cell>
                <Cell block={false}>{`$${addCommasInNumber(
                  Number(item.sales?.sales_revenue?.toFixed(2) ?? '0.00')
                )}`}</Cell>
              </ContentCell>
            ))}
          </Table>
        </WrapperTable>
      </Container>
      <WrapperPagination>
        <Pagination
          currentPage={currentPage}
          totalCount={state.data.length}
          pageSize={PageSize}
          onPageChange={(page: any) => setCurrentPage(page)}
        />
      </WrapperPagination>
    </>
  )
}

const InventoryReport: React.FC<Props> = ({dataTable = {}, handlerShowDeadProducts, isDead}) => {
  const intl = useIntl()
  const {itemIdForUpdate} = useListView()
  const amountUniqueProducts = dataTable?.amountUniqueProducts as number
  const amountProducts = dataTable?.amountProducts as number
  return (
    <>
      {itemIdForUpdate !== undefined && <LandedCostEditModal height={65} />}
      <GlobalInfoContainer>
        <InfoCard bg='#E2EBD3'>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconInventory />
            <h4>{intl.formatMessage({id: 'REPORT.INVENTORY.TOTAL.AVAILABLE'})}</h4>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <p>{addCommasInNumber(amountProducts)}</p>
          </div>
        </InfoCard>
        <InfoCard bg='#CBF3F9'>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconProducts />
            <h4>{intl.formatMessage({id: 'REPORT.INVENTORY.TOTAL.UNIQUE'})}</h4>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <p>{addCommasInNumber(amountUniqueProducts)}</p>
          </div>
        </InfoCard>
      </GlobalInfoContainer>
      <InventoryTable
        isDead={isDead}
        handlerShowDeadProducts={handlerShowDeadProducts}
        data={dataTable?.products ?? []}
        columns={columns}
      />
    </>
  )
}

export {InventoryReport}
