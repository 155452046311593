import {useQuery} from 'react-query'
import {useListView} from '../core/ListViewProvider'
import React, {FC} from 'react'
import {isNotEmpty} from '../../../../_gws/helpers'
import {ModalLoading} from '../components/ModalLoading'
import {LandedCostEditModalForm} from './LandedCostEditModalForm'
import {getLandedCost} from '../core/_requests'

type Props = {
  height?: number
}

/**
 * Wrapper of the form
 * @param height
 * @constructor
 */
const LandedCostEditModalFormWrapper: FC<Props> = ({height}) => {
  const {itemIdForUpdate, setItemIdForUpdate, itemIdForUpdateNew} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)

  /**
   * Requesting Playlist in ListView Provider
   */
  const {
    isLoading,
    data: item,
    error,
  } = useQuery(
    `get landed cost`,
    () => {
      return getLandedCost(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return (
      <LandedCostEditModalForm
        isLoading={isLoading}
        item={{id: undefined}}
        newItem={true}
        itemIdForUpdate={itemIdForUpdateNew}
      />
    )
  }

  if (!isLoading && !error && item) {
    return (
      <LandedCostEditModalForm
        isLoading={isLoading}
        item={item.data.detail}
        newItem={false}
        itemIdForUpdate={itemIdForUpdate}
      />
    )
  } else {
    return <ModalLoading height={height} />
  }
}

export {LandedCostEditModalFormWrapper}
