import {useEffect, useState} from 'react'
import {useParams, useSearchParams} from 'react-router-dom'
import {PageTitle} from '../../../../_gws/layout/core'
import {useIntl} from 'react-intl'
import {Splash} from '../../../../_gws/helpers/misc'
import useSWR from 'swr'
import {ParamsType, getBrandhealthIndicators} from '../core/getBrandhealthIndicators'
import {BrandhealthTableDetails} from '../components/BrandhealthTableDetails'

const BrandHealthIndicatorWrapper = () => {
  const intl = useIntl()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchParams] = useSearchParams()
  const params = useParams()
  const indicator = params.ind === undefined ? '' : params.ind.toString()
  const initialMkp = params.mkp === undefined ? '' : params.mkp.toString()
  const category = indicator.split('_')[1]

  const type = searchParams.get('output')
  const priority = searchParams.get('priority')
  const mkpFilter = searchParams.get('marketplaceFilter')
  const stockless = searchParams.get('stockless')
  const paramsObj: ParamsType = {
    category,
    initialMkp,
  }
  const {data, isLoading} = useSWR(
    ['/indicators', initialMkp, category, type, priority, mkpFilter, stockless, currentPage],
    () =>
      getBrandhealthIndicators({
        initialMkp: initialMkp,
        category: Number(category),
        type,
        priority,
        currentPage,
        mkpFilter,
        stockless
      }),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  useEffect(() => {
    Splash(isLoading)
  }, [isLoading])

  const handlerCurrentPage = (page: any) => setCurrentPage(page)

  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: intl.formatMessage({id: 'MENU.BRAND_HEALTH'}),
            path: '/brand-health',
            isSeparator: false,
            isActive: false,
          },
          {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
          },
        ]}
      >
        {intl.formatMessage({id: `BRAND_HEALTH.${indicator.toUpperCase()}`})}
      </PageTitle>
      {data && (
        <BrandhealthTableDetails
          paramsObj={paramsObj}
          indicators={data.indicators}
          references={data.references}
          totalIndicators={data.total_indicators || 0}
          handlerCurrentPage={handlerCurrentPage}
          currentPage={currentPage}
          delicate={data.urgentes}
          critical={data.importantes}
        />
      )}
    </>
  )
}

export {BrandHealthIndicatorWrapper}
