import ReactApexChart from 'react-apexcharts'
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../modules/auth'
import {abbreviateNumber, checkUndefined, getShortMonth} from '../../../../../_gws/helpers/misc'
import {CardSales} from './styled'
export const ChartDashboardSales = ({dataReport}: {dataReport: any}) => {
  const intl = useIntl()
  const {hasAmazon, hasMeli} = useAuth()
  const today = new Date()

  // Create a new array with the last 6 months from today
  const dataMonths = []
  for (let i = 0; i < 6; i++) {
    const month = today.getMonth() - i
    if (month < 0) {
      dataMonths.push(getShortMonth(month + 12))
      continue
    }
    dataMonths.push(getShortMonth(month))
  }
  dataMonths.reverse()

  // Create a new array with the sales from the last 6 months from today
  const amazonSales = dataMonths.map((month) => {
    //@ts-ignore
    return checkUndefined(dataReport?.sales?.amazon?.monthly[month]?.sold).toFixed(2)
  })

  const meliSales = dataMonths.map((month) => {
    //@ts-ignore
    return checkUndefined(dataReport?.sales?.meli?.monthly[month]?.sold).toFixed(2)
  })
  let dataSales: any = []
  hasAmazon && (dataSales = [...dataSales, {name: 'Amazon', data: amazonSales}])
  hasMeli && (dataSales = [...dataSales, {name: 'Mercado Libre', data: meliSales}])

  const dataMonthI18n = dataMonths.map((item) =>
    intl.formatMessage({id: `REPORT.PRODUCT_TRACKER.${item.toUpperCase()}`})
  )

  const op: any = {
    series: dataSales,
    options: {
      colors: ['#F49302', '#002F6D'],
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      xaxis: {
        categories: dataMonthI18n,
        tooltip: {
          enabled: false,
          offsetX: 0,
        },
      },
      yaxis: {
        labels: {
          formatter: (value: any) => {
            return abbreviateNumber(value, 2)
          },
        },
      },
      fill: {
        pattern: {},
      },
      tooltip: {
        enabled: true,
        x: {
          show: true,
        },
        y: {
          show: true,
          formatter: function (value: any) {
            return '$' + abbreviateNumber(value, 2)
          },
        },
        z: {
          show: true,
        },
        marker: {
          show: false,
        },
        onDatasetHover: {
          highlightDataSeries: false,
        },
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'left',
        itemMargin: {
          horizontal: 10,
          vertical: 20,
        },
      },
      title: {},
    },
  }

  return (
    <CardSales>
      <h4
        style={{
          color: '#212121',
        }}
      >
        {intl.formatMessage({id: `DASHBOARD.SALES`})}
      </h4>
      <ReactApexChart options={op.options} series={op.series} type='area' height={'100%'} />
    </CardSales>
  )
}
