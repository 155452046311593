import React from 'react';

const ConnectedBadge = () => {
    return (
        <span
            className='symbol-badge badge badge-circle bg-success  top-100 start-100'
            style={{
                width: '12px',
                height: '12px',
                border: '1px solid white',
                marginTop: '-7px',
                marginLeft: '-7px',
            }}
        ></span>
    );
};

export default ConnectedBadge;