import React, {useEffect, useState} from 'react'
import {DashboardMerchant} from './components/DashboardMerchant'
import {getlistClients} from './core/_request'

const DashboardMerchantContainer = () => {
  const [clients, setClients] = useState([])

  const fetchClients = async () => {
    const response = await getlistClients()
    if (response.status === 200) {
      setClients(response.detail)
    }

    return response.status
  }

  useEffect(() => {
    fetchClients()
  }, [])

  return (
    <div>
      <DashboardMerchant list={clients} />
    </div>
  )
}

export {DashboardMerchantContainer}
