/* eslint-disable jsx-a11y/anchor-is-valid */
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'
import {ToolkitContextType} from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {useListView} from '../core/ListViewProvider'
import {CustomSearchForm} from '../../../../../../_gws/partials/tables/CustomSearchForm'
import {KTSVG} from '../../../../../../_gws/helpers'

type Props = {
  columns: any
  listUser: any
  optionsTable: any
}
const UserTable: FC<Props> = ({columns, listUser, optionsTable}) => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()

  /**
   * Needed to open "New User" modal
   */
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='card'>
      <div className='card-header cursor-pointer border-0'>
        <h3 className='card-title m-0 align-items-start flex-column'>
          <span className='card-label fw-bolder m-0'>
            {intl.formatMessage({id: 'USER_MNGT.TOP.TITLE'})}
          </span>
        </h3>

        <div className='card-toolbar'>
          <button
            type='button'
            className='py-3 px-5 border-0 mb-5 mt-5 text-primary bg-black bg-hover-opacity-75 rounded-pixel shadow-login-btn cursor-pointer'
            onClick={openAddUserModal}
          >
            <KTSVG
              path='/media/icons/unicos/053-add.svg'
              className='svg-icon-1x svg-icon-primary me-1'
            />
            {intl.formatMessage({id: 'USER_MNGT.MODAL.HEAD.NEW_USER'})}
          </button>
        </div>
      </div>
      <div className='card-body pt-0'>
        <ToolkitProvider columns={columns} data={listUser} keyField={'id'} search>
          {(props: ToolkitContextType) => (
            <div>
              <CustomSearchForm
                placeholder={intl.formatMessage({id: 'USER_MNGT.TABLE.HEAD.SEARCH_TERM'})}
                {...props.searchProps}
              />
              <div className='separator separator-dotted my-5'></div>
              <BootstrapTable
                {...props.baseProps}
                filter={filterFactory()}
                bootstrap4={true}
                hover={true}
                pagination={paginationFactory(optionsTable)}
                striped={false}
                classes={'table gy-2 gs-2'}
                wrapperClasses={'table-responsive position-relative mb-5'}
                bodyClasses={'text-gray-600'}
                rowClasses={'align-middle'}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  )
}

export {UserTable}
