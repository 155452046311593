/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_gws/helpers'
import {Button, CloseButton, Modal, Spinner} from 'react-bootstrap'
import {useState} from 'react'
import Form from 'react-bootstrap/Form'
import styled from '@emotion/styled'
import {submitLandedCost} from '../core/_requests'
import {AlertFetchError} from '../../../../_gws/helpers/misc'

const InputStyled = styled(Form.Control)({
  background: 'transparent',
  ':focus': {
    background: 'transparent',
  },
})

const BtnStyled = styled(Button)({
  background: '#0094EB !important',
  color: '#fff !important',
  marginLeft: '5px !important',
})

const LabelStyled = styled(Form.Label)({
  color: '#212121B0',
})
const EditLandedCost = ({
  showModal,
  handlerClouse,
  item,
  handlerUpdateItem,
}: {
  showModal: boolean
  handlerClouse: any
  item: any
  handlerUpdateItem: any
}) => {
  const intl = useIntl()
  const [landedCost, setLandedCost] = useState(item.landed_cost)
  const [isLoading, setIsLoading] = useState({
    loading: false,
  })

  const handlerCost = (e: any) => {
    setLandedCost(e.target.value)
  }
  const handlerUpdateLandedCost = async () => {
    setIsLoading((pre) => ({...pre, loading: true}))
    const response = await submitLandedCost({
      asin: item.asin,
      marketplace: item.marketplace,
      cost: landedCost,
    })
    if (response.status !== 200)
      AlertFetchError(intl.locale, intl.formatMessage({id: 'PLAYLIST.ERROR.MESSAGE'})).fire()
    handlerUpdateItem(
      response.data.detail.inventoryProduct,
      response.data.detail.inventoryProduct.asin
    )
    setIsLoading((pre) => ({...pre, loading: false}))
    handlerClouse()
  }
  return (
    <Modal centered show={showModal}>
      <Modal.Header className={'justify-content-between px-10 py-5'}>
        <h4>
        {intl.formatMessage({id: 'MENU.REPORTS.ACTIONS.HEADER.LANDED_COST'})}
        </h4>
        <CloseButton onClick={handlerClouse} />
      </Modal.Header>
      <Modal.Body className='px-10 py-5'>
        <div>
          <LabelStyled htmlFor='product'>
            {intl.formatMessage({id: 'MENU.REPORTS.INVENTORY.TABLE.HEADER.PRODUCT'})}
          </LabelStyled>
          <InputStyled size='sm' type='text' id='product' value={item.asin} disabled />
        </div>
        <div style={{margin: '20px 0px'}}>
          <LabelStyled htmlFor='marketplace'>
            {intl.formatMessage({id: 'MENU.REPORTS.INVENTORY.TABLE.HEADER.MARKETPLACE'})}
          </LabelStyled>
          <InputStyled
            size='sm'
            type='text'
            id='marketplace'
            value={item.marketplace === 1 ? 'Amazon' : 'Mercado libre'}
            disabled
          />
        </div>
        <div style={{margin: '20px 0px'}}>
          <LabelStyled htmlFor='landed_cost'>
            {' '}
            {intl.formatMessage({id: 'MENU.REPORTS.INVENTORY.TABLE.HEADER.LANDED_COST'})} (MXN$)
          </LabelStyled>
          <InputStyled
            size='sm'
            type='number'
            id='landed_cost'
            value={landedCost}
            onChange={handlerCost}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <BtnStyled onClick={handlerUpdateLandedCost} disabled={isLoading.loading} size='sm'>
            {isLoading.loading ? (
              <Spinner size='sm' animation='border' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </Spinner>
            ) : (
              <>
              {intl.formatMessage({id: 'MENU.REPORTS.ACTIONS.ADD.LANDED_COST'})} 
              </>
            )}
          </BtnStyled>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const TableActions = ({item, handlerUpdateItem}: {item: any; handlerUpdateItem: any}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <a
        onClick={() => setIsOpen(true)}
        href='#'
        className='text-gray-600'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        ${item.landed_cost}
        <KTSVG
          path='/media/icons/unicos/016-menu.svg'
          className='svg-icon-5 m-0 ms-1 d-inline-block text-gray-600'
        />
      </a>
      <EditLandedCost
        showModal={isOpen}
        handlerClouse={() => setIsOpen((pre) => !pre)}
        item={item}
        handlerUpdateItem={handlerUpdateItem}
      />
    </>
  )
}

export {TableActions}
