import {useEffect, useReducer} from 'react'
import {Container, Header} from './components/styled'
import {PlaylistTab} from './components/PlaylistTab'
import {KTSVG, toAbsoluteUrl} from '../../../_gws/helpers'
import {PlaylisActions} from './components/PlaylistActions'
import {PlaylistTable} from './components/Table/PlaylistTable'
import {
  FieldSort,
  Filters,
  IndexedReferences,
  PlaylistActions,
  PlaylistType,
  ReferenceType,
  State,
  Task,
} from './types'
import {ActionTypes, initialState, playlistReducer} from './playlist.reducer'
import {PlaylistResolveButton} from './components/Table/PlaylistResolve'
import {useIntl} from 'react-intl'

const PlaylistTableWrapper = ({
  data,
  actions,
  selectedItems,
  references,
  optionReferences,
}: {
  data: PlaylistType[]
  actions: PlaylistActions
  selectedItems: Task[]
  references: IndexedReferences
  optionReferences: ReferenceType[]
}) => {
  return (
    <>
      {data.map((item: PlaylistType, i: number) => (
        <PlaylistTable
          key={i}
          indexTable={i}
          tasks={item.tasks}
          year={item.year}
          month={item.month}
          actions={actions}
          selectedItems={selectedItems}
          references={references}
          optionReferences={optionReferences}
        />
      ))}
    </>
  )
}

const Playlist = ({data, references}: {data: PlaylistType[]; references: ReferenceType[]}) => {
  const intl = useIntl()
  const [state, dispatch] = useReducer<(state: State, action: any) => State>(
    playlistReducer,
    initialState
  )

  useEffect(() => {
    dispatch({
      type: ActionTypes.SET_STATE,
      payload: {
        data: data,
        references: references,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selectOneItem = ({
    id,
    checked,
    index,
  }: {
    id: number
    checked: boolean | undefined
    index: number
  }) => {
    dispatch({
      type: ActionTypes.SELECT_ITEM,
      payload: {
        index: index,
        id: id,
        selected: checked,
      },
    })
  }

  const applyFilters = ({filters}: {filters: Filters[]}) => {
    dispatch({
      type: ActionTypes.APPLY_FILTERS,
      payload: {
        filters: filters,
      },
    })
  }

  const selectAll = (checked: boolean, indexTable: number) => {
    dispatch({
      type: ActionTypes.SELECT_ALL,
      payload: {
        checked: checked,
        index: indexTable,
      },
    })
  }

  const updateTasks = (tasks: Task[], edit?: boolean, indexTable?: number) => {
    dispatch({
      type: ActionTypes.UPDATE_TASKS,
      payload: {
        tasks: tasks,
        edit: edit,
        indexTable: indexTable,
      },
    })
  }

  const updateState = (playlist: PlaylistType) => {
    dispatch({
      type: ActionTypes.UPDATE_STATE,
      payload: {
        data: playlist,
      },
    })
  }

  const sortItems = (sorts: FieldSort) => {
    dispatch({
      type: ActionTypes.SORT_ITEMS,
      payload: {
        sort: sorts,
      },
    })
  }

  const ACTIONS: PlaylistActions = {
    selectOneItem,
    applyFilters,
    selectAll,
    updateTasks,
    updateState,
    sortItems,
  }

  const content: any = {
    tasks: (
      <PlaylistTableWrapper
        references={state.references}
        selectedItems={state.selected}
        actions={ACTIONS}
        data={state.data}
        optionReferences={references}
      />
    ),
  }
  return (
    <Container>
      <Header>
        <div>
          <h4>{intl.formatMessage({id: 'PLAYLIST.HEADER.TITLE'})}</h4>
          <p>{intl.formatMessage({id: 'PLAYLIST.HEADER.SUBTITLE'})}</p>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <KTSVG
            path={toAbsoluteUrl('media/icons/gws-icons/play_circle.svg')}
            className={`svg-icon-2x svg-icon-dark`}
          />
        </div>
      </Header>
      <PlaylisActions actions={ACTIONS} selected={state.selected} references={references} />
      <PlaylistResolveButton updateState={ACTIONS.updateState} selectedItems={state.selected} />
      <PlaylistTab content={content} />
    </Container>
  )
}
export {Playlist}
