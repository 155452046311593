import React from 'react'
import clsx from 'clsx'
import {useLocation} from 'react-router'
import {KTSVG, WithChildren} from '../../../helpers'
import {AgAside} from '../ag'

type Props = {
  listTo: string[]
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
}
/**
 *
 * @param listTo - array of strings from the subcategory, i.e. brand-health, paid-marketing
 * @param title - title of the category
 * @param icon - icon of the category
 * @param fontIcon
 * @param hasBullet - has dot before the subcategory
 */
const AsideMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  listTo,
  title,
  icon,
  fontIcon,
  hasBullet,
}) => {
  /**
   * Get the current URL
   */
  const {pathname} = useLocation()

  /**
   * Make an array with the pathname from the URL
   */
  const listURL = pathname.split('/')
  /**
   * Checks if any of the names from the listTo is includes in the name in the URL
   */
  const isActive = listTo.some((urlName) => listURL.includes(urlName))

  return (
    <div
      className={clsx('menu-item', {'here show': isActive}, 'menu-accordion')}
      data-kt-menu-trigger='click'
    >
      <span className='menu-link'>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot' style={{ backgroundColor: 'var(--kt-text-gray-550)' }}></span>
          </span>
        )}
        {icon && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}
        {fontIcon && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <AgAside choice={'category'} text={title} customClass={'menu-title fw-bold'} />
        <span className='menu-arrow'></span>
      </span>
      <div className={clsx('menu-sub menu-sub-accordion', {'menu-active-bg': isActive})}>
        {children}
      </div>
    </div>
  )
}

export {AsideMenuItemWithSub}
