import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_gws/layout/core'
import { DocumentationEditView } from './Documentation'


export const DocumentationWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: intl.formatMessage({id: 'MENU.HOME'}),
            path: '/dashboard',
            isSeparator: false,
            isActive: false,
          },
          {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
          },
        ]}
      >
        Gestion de glosario
      </PageTitle>
      <DocumentationEditView/>
    </>
  )
}
