import styled from '@emotion/styled'

export const Container = styled('div')({})

// ---------------- Indicators ---------------------//
type IndicatorProp = {
  bg?: string
}
export const ContainerIndicators = styled('div')({
  margin: '20px 0px',
})

export const IndicatorsWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
  gap: '1rem',
})

export const IndicatorCard = styled('div')<IndicatorProp>(({bg}) => ({
  background: bg,
  borderRadius: '8px',
  fontSize: '1.5rem',
  fontWeight: 'bold',
  display: 'grid',
  placeItems: 'center',
  height: '150px',
  textAlign: 'center',
}))
// ---------------- Indicators ---------------------//

// ---------------- Charts ---------------------//
export const ContainerCharts = styled('div')({
  marginTop: '2rem',
  display: 'grid',
  gridTemplateColumns: '1fr 1.5fr',
  gap: '1rem',
  '@media (max-width: 768px)': {
    display: 'flex',
    flexFlow: 'column',
  },
})

export const CardChart = styled('div')({
  padding: '20px',
  borderRadius: '10px',
  boxShadow: '-1px 14px 19px -4px #919EAB33',
  p: {
    color: '#9E9E9E',
  },
})

export const CardChartHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})
// ---------------- Charts ---------------------//

export const CardTable = styled('div')({
  padding: '20px',
  borderRadius: '10px',
  boxShadow: '-1px 14px 19px -4px #919EAB33',
  marginTop: '2rem',
  p: {
    color: '#9E9E9E',
  },
})

export const FilterWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  "@media (max-width: 500px)": {
    display: 'flex',
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    marginBottom: "20px"
  }
})
