/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth, RecoveryPage} from '../modules/auth'
import {App} from '../App'
import { PaymentPage } from '../modules/auth/PaymentPage'
import { PaymentErrorPage } from '../modules/auth/PaymentErrorPage'
import { PaymentSuccessPage } from '../modules/auth/PaymentSuccessPage'
import { BusinessInfoPage } from '../modules/auth/businessInfoPage'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser, currentClient} = useAuth()
  const clientData = currentClient !== undefined ? currentClient : currentUser

  const isClient = currentUser?.roleId === 1
  const isAdmin = currentUser?.roleId === 2
  const canSeeUserAdmin = clientData?.canSeeUserAdmin === true

  let url = ''
  if (isClient) {
    url = '/dashboard'
  } else if (isAdmin) {
    url = '/apps/user-management/users'
  } else if (currentClient === undefined && canSeeUserAdmin) {
    url = '/apps/user-management/clients'
  } else if (currentClient !== undefined && canSeeUserAdmin) {
    url = '/dashboard'
  } else if (currentClient !== undefined && !canSeeUserAdmin) {
    url = '/dashboard'
  }

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route path='recovery' element={<RecoveryPage />} />
          <Route path='businessInfo' element={<BusinessInfoPage />} />
          <Route path='payment' element={<PaymentPage />} />
          <Route path='payment/error' element={<PaymentErrorPage />} />
          <Route path='payment/success' element={<PaymentSuccessPage />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to={url} />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
