import React, {FC} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../helpers'

type Props = {
  marketplaces: any
  admin?: boolean
  tooltip?: string
}
/**
 * It returns a table with the logos of the marketplaces
 * @param marketplaces format
 * @param tooltip optional, if you want to show a tooltip
 * @constructor
 */
const MarketplaceConexionTable: FC<Props> = ({marketplaces, admin, tooltip}) => {
  /**
   * It returns a logo based on the value of the mkpId and connected parameters
   * @param {number} mkpId - ID number of the marketplace
   * @param {boolean} connected - boolean, true if it has credentials, else false
   * @returns the image path based on the marketplace id and the connection status.
   */

  const findImag = (mkpId: number, connected: boolean) => {
    if (mkpId === 1 && connected) return '/media/marketplace/amazon-logo-history.png'
    if (mkpId === 1 && !connected) return false
    if (mkpId === 2 && connected) return '/media/marketplace/meli-logo-history.png'
    if (mkpId === 2 && !connected) return false
    if (mkpId === 4 && connected) return '/media/marketplace/amazon-ads-logo.png'
    if (mkpId === 4 && !connected) return false
  }


  /**
  *  It returns a list of marketplaces based on the admin parameter,
  */
  const mkpList = !admin
    ? marketplaces
        .filter((mkp: any) => mkp.marketplaceId !== 3)
        .map((mkp: any) => {
          return {
            id: mkp.marketplaceId,
            name: mkp.marketplace,
            hasCredential: mkp.secretId.length >= 20,
            img: findImag(mkp.marketplaceId, mkp.secretId.length >= 20),
          }
        })
    : marketplaces.map((m: any) => {
        let createdLocalTime = new Date(m.createdAt).toLocaleString()
        let updateLocalTime = new Date(m.updatedAt).toLocaleString()
        return {
        id: m.marketplaceId,
        name: m.marketplaceId,
        hasCredential: true,
        img: findImag(m.marketplaceId, true),
        created: createdLocalTime,
        updated: updateLocalTime,
      }})

  return (
    <div className='d-flex align-items-center mb-0'>
      <div className='symbol-group symbol-hover'>
        {mkpList.map((mkp: any) => {
          return (
            mkp.img && (
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    {tooltip ? (
                      admin ? (
                        <span>
                          <strong>Enroll:</strong> {mkp.created} <br></br>
                          <strong>Update:</strong> {mkp.updated}
                        </span>
                      ) : (
                        tooltip
                      )
                    ) : (
                      mkp.name
                    )}
                  </Tooltip>
                }
                defaultShow={false}
                key={mkp.id}
              >
                <div className='symbol symbol-35px symbol-circle'>
                  <img alt={`${mkp.name} logo`} src={toAbsoluteUrl(`${mkp.img}`)} />
                </div>
              </OverlayTrigger>
            )
          )
        })}
      </div>
    </div>
  )
}

export {MarketplaceConexionTable}
