import {KTSVG} from '../../../helpers'
import {toAbsoluteUrl} from '../../../helpers'
import {AgLabelWithIcon} from '../../../layout/components/ag/components/AgLabelWithIcon'

type Props = {
  text: string
  backgroundColor?: string
  color?: string
  extraCSS?: Object
  icon?: string
  svgColor?: string
  customClass?: string
}
/**
 * This Component create a Basic Label with icon
 *
 * @param text - string with the text
 * @param backgroundColor - background Color for the component. (variable sass)
 * @param color - color for text-component (variable sass)
 * @param icon - string(icon referent) for the component
 * @param svgColor - string with svg icon (variable sass)
 */

const BasicLabelWithIcon: React.FC<Props> = ({text, color, backgroundColor, icon, svgColor, customClass}) => {
  const labelStyles = {
    fontWeight: '700',
    border: 'none',
    width: '10em',
    borderRadius: '0.5em',
  }
  return (
    <div
      className={`d-flex justify-content-start align-items-center py-1 px-2 text-uppercase bg-${backgroundColor} ${customClass}`}
      style={labelStyles}
    >
      { icon &&  (<KTSVG
        path={toAbsoluteUrl(icon)}
        className={`svg-icon-6 svg-icon-${svgColor} d-flex align-items-center`}
      />)}
      <AgLabelWithIcon customClass={`align-self-center px-2 text-${color}`} text={text} />
    </div>
  )
}

export {BasicLabelWithIcon}
