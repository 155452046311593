import {ReactNode} from 'react'

export const IconBase = ({
  color = '#000',
  size = 24,
  viewBox = '0 0 24 24',
  children,
}: {
  color: string
  size: number
  viewBox: string
  children: ReactNode
}) => {
  return (
    <svg width={size} height={size} viewBox={viewBox}>
      <g fill={color}>{children}</g>
    </svg>
  )
}
