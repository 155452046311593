import React, {FC} from 'react'

type TableAvatarUserProps = {
  userID: number
  picUrl: string | undefined
  name: string | undefined
  username?: string | undefined
  lastname?: string | undefined
  email?: string | undefined
}

const TableAvatarUser: FC<TableAvatarUserProps> = ({
  picUrl,
  name,
  username,
  lastname,
  email,
  userID,
}) => {
  return (
    <div className='d-flex align-items-center me-4'>
      <div className={'symbol symbol-circle symbol-50px me-3'}>
        {picUrl !== undefined && picUrl !== null && picUrl !== '' ? (
          <img alt={username} src={picUrl} />
        ) : (
          <span className='symbol-label bg-light-primary text-dark text-uppercase fw-bold'>
            {lastname?.substring(0, 1)}
            {name?.substring(0, 1)}
          </span>
        )}
      </div>
      <div className='m-0'>
        <span className='fw-semibold text-gray-700 d-block fs-6'>
          {name} {lastname}
        </span>
        <span className='fw-regular  text-primary fs-7'>{email}</span>
      </div>
    </div>
  )
}

export {TableAvatarUser}
