/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'

import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_gws/layout/core'
import {AlertFetchError, Splash} from '../../../_gws/helpers/misc'
import {InventoryReport} from './InventoryReport'
import {InventoryReportModal} from '../../../_gws/partials/modals/reports/InventoryReportModal'
import {HeaderReport} from '../../modules/apps/header/HeaderReport'
import {WaitingScreen} from '../../../_gws/partials/content/waiting/WaitingScreen'
import useSWR from 'swr'
import {mkProp} from '../../modules/auth'
import {BannerHelp} from '../../modules/apps/BannerHelp/BannerHelp'
import {getProductsData} from './core/getProducts'

const InventoryWrapper = () => {
  const intl = useIntl()
  const [mkplace, setMkplace] = useState<mkProp>(undefined)
  const [isDead, setIsDead] = useState(false)
  const hasBeenVerified = localStorage.getItem('verified') === 'true'

  const {data, error, isLoading} = useSWR(
    () => (hasBeenVerified ? (mkplace ? [mkplace, isDead] : null) : null),
    () =>
      getProductsData({
        marketplace: mkplace,
        isDead: isDead
      }),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    }
  )

  const handlerShowDeadProducts = () => setIsDead((pre) => !pre)

  useEffect(() => {
    if (error) AlertFetchError(intl.locale, error).fire()
  }, [error])

  useEffect(() => {
    Splash(isLoading)
  }, [isLoading])

  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: intl.formatMessage({id: 'MENU.HOME'}),
            path: '/dashboard',
            isSeparator: false,
            isActive: false,
          },
          {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.REPORTS.INVENTORY'})}
      </PageTitle>
      {hasBeenVerified ? (
        <>
          <BannerHelp
            linkToDoc='https://docs.growker.ai/kb/inventario/'
            title='HELP.TITLE.INVENTORY'
            text='HELP.TEXT.INVENTORY'
          />
          <InventoryReportModal />
          <HeaderReport
            hasMarketPlace={true}
            hasFilter={false}
            hasDate={false}
            growkerMetric={true}
            setMkplace={setMkplace}
          />
          {!isLoading && data && (
            <>
              {mkplace && data && (
                <InventoryReport
                  isDead={isDead}
                  handlerShowDeadProducts={handlerShowDeadProducts}
                  dataTable={data}
                />
              )}
            </>
          )}
        </>
      ) : (
        <WaitingScreen />
      )}
    </>
  )
}
export {InventoryWrapper}
