import React from 'react'
import {useIntl} from 'react-intl'
import {getFixedDecimals} from '../../../../_gws/helpers/misc'
import {KTSVG, toAbsoluteUrl} from '../../../../_gws/helpers'
import {ResultsCard} from '../../../../_gws/partials/widgets/statistics/ResultsCard'

type PropsProductHeader = {
  dataReport?: any
}

/**
 * Component of Report Header
 * Show's featured KPI's
 * @param dataReport {object} - API object requested
 */
const ProductHeader: React.FC<PropsProductHeader> = ({dataReport}) => {
  const intl = useIntl()
  return (
    <>
      <div className='col-md-12'>
        <h3 className='ms-1'>
          {intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.MODAL.FEATURED_KPI'})}&nbsp;
          <a href={'/#'} data-bs-toggle='modal' data-bs-target='#info_pt2_report_modal'>
            <KTSVG
              path={toAbsoluteUrl('/media/icons/duotune/general/gen046.svg')}
              className='svg-icon-2x svg-icon-dark'
            />
          </a>
        </h3>
        <div className='row'>
          <div className='col-6 col-md-5 mb-5'>
            <ResultsCard
              className={'card-xl-stretch'}
              color={'card'}
              titleColor={'dark'}
              descriptionColor={'dark'}
              title={`${dataReport?.orderCount}`}
              description={intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.ORDER_COUNT'})}
              height={'130px'}
              countUp={true}
            />
          </div>
          <div className='col-6 col-md-7 mb-5'>
            <ResultsCard
              className={'card-xl-stretch'}
              color={'card'}
              titleColor={'dark'}
              descriptionColor={'dark'}
              title={`${getFixedDecimals(dataReport?.orderTotal)}`}
              description={intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.ORDER_TOTAL'})}
              height={'130px'}
              countUp={true}
              decimal={true}
              prefix={'$'}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export {ProductHeader}
