export const sortPriority = (sortOrder: string, data: any) =>
  data.toSorted((a: any, b: any) =>
    sortOrder === 'asc' ? a.priority - b.priority : b.priority - a.priority
  )

export const sortType = (sortOrder: string, data: any) => {
  const result = data.toSorted((a: any, b: any) =>
    sortOrder === 'asc' ? a.type.localeCompare(b.type) : b.type.localeCompare(a.type)
  )
  return result
}

export const sortMarketplace = (sortOrder: string, data: any) => {
  const result = data.toSorted((a: any, b: any) =>
    sortOrder === 'asc' ? a.marketplaceId - b.marketplaceId : b.marketplaceId - a.marketplaceId
  )
  return result
}

export const sortStatus = (sortOrder: string, data: any) =>
  data.toSorted((a: any, b: any) =>
    sortOrder === 'asc' ? a.status - b.status : b.status - a.status
  )

export const sortDeadline = (sortOrder: string, data: any) => {
  const result = data.toSorted((a: any, b: any) =>
    sortOrder === 'asc'
      ? a.deadline.localeCompare(b.deadline)
      : b.deadline.localeCompare(a.deadline)
  )
  return result
}

export const sortCreated = (sortOrder: string, data: any) => {
  const result = data.toSorted((a: any, b: any) =>
    sortOrder === 'asc'
      ? a.createdAt.localeCompare(b.createdAt)
      : b.createdAt.localeCompare(a.createdAt)
  )
  return result
}
