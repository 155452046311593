import {FC} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_gws/helpers'
import {useAuth} from '../../auth'
import styled from '@emotion/styled'

type Props = {
  mkp: any
  handleClick?: any
  comingSoon?: boolean
}

type PillProps = {
  mkp: any
  comingSoon?: boolean
  show: boolean
  currentMkp: any
}

const PillMkp = styled('div')<PillProps>(({mkp, comingSoon, show, currentMkp}) => ({
  minWidth: '150px',
  minHeight: '50px',
  height: '50px',
  marginRight: '10px',
  borderRadius: '8px',
  cursor: show ? 'pointer' : 'inherit',
  transition: 'all ease .3s',
  boxShadow: mkp === currentMkp ? '1px 14px 19px -4px #919EAB33' : '0px 0px 0px 0px #919EAB33',
  border: `1px solid ${mkp === currentMkp ? "#DFDFDF": "#DFDFDF"}`,
  ':hover': {
    boxShadow: '-1px 14px 19px -4px #919EAB33',
  },
}))

const MarketplaceButton: FC<Props> = ({mkp, handleClick, comingSoon}) => {
  const {currentMkp} = useAuth()
  return (
    <PillMkp
      mkp={mkp.shortname}
      currentMkp={currentMkp}
      show={mkp.show}
      comingSoon={comingSoon}
      className={`d-flex justify-content-center align-items-center `}
      onClick={() => { handleClick && handleClick(mkp) }}
    >
      <KTSVG
        path={toAbsoluteUrl(`${mkp.img}`)}
        className={`svg-icon-5x svg-icon-${mkp.shortname === currentMkp ? 'dark' : 'gray'}`}
      />
    </PillMkp>
  )
}

export {MarketplaceButton}