import {PriorityType, priorityPlaylist} from '../../../types'

export const RenderPriority = ({priority}: {priority: PriorityType}) => {
  const flex = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
  return (
    <div style={{...flex }}>
      {priorityPlaylist[priority].label}
    </div>
  )
}
