import {useIntl} from 'react-intl'
import {GlobalInfoContainer, InfoCard} from './styled'
import { ReportStrategyData } from '../core/types'
import { addCommasInNumber } from '../../../../_gws/helpers/misc'

const IconTotalProfit = () => {
  return (
    <svg width='45' height='45' viewBox='0 0 45 45' fill='none'>
      <mask id='mask0_91_1021' maskUnits='userSpaceOnUse' x='0' y='0' width='45' height='45'>
        <rect width='45' height='45' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_91_1021)'>
        <path
          d='M3.75 37.5V7.5H41.25V37.5H3.75ZM7.5 33.75H37.5V11.25H7.5V33.75ZM15 31.875H18.75V30H20.625C21.1562 30 21.6016 29.8203 21.9609 29.4609C22.3203 29.1016 22.5 28.6562 22.5 28.125V22.5C22.5 21.9688 22.3203 21.5234 21.9609 21.1641C21.6016 20.8047 21.1562 20.625 20.625 20.625H15V18.75H22.5V15H18.75V13.125H15V15H13.125C12.5938 15 12.1484 15.1797 11.7891 15.5391C11.4297 15.8984 11.25 16.3438 11.25 16.875V22.5C11.25 23.0312 11.4297 23.4766 11.7891 23.8359C12.1484 24.1953 12.5938 24.375 13.125 24.375H18.75V26.25H11.25V30H15V31.875ZM30 30.4688L33.75 26.7188H26.25L30 30.4688ZM26.25 18.75H33.75L30 15L26.25 18.75Z'
          fill='#1C1B1F'
        />
      </g>
    </svg>
  )
}

const IconTotalSales = () => {
  return (
    <svg width='45' height='46' viewBox='0 0 45 46' fill='none'>
      <mask id='mask0_91_1030' maskUnits='userSpaceOnUse' x='0' y='0' width='45' height='46'>
        <rect y='0.266098' width='45' height='45' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_91_1030)'>
        <path
          d='M13.125 41.5161C12.0938 41.5161 11.2109 41.1489 10.4766 40.4145C9.74219 39.6802 9.375 38.7973 9.375 37.7661C9.375 36.7348 9.74219 35.852 10.4766 35.1177C11.2109 34.3833 12.0938 34.0161 13.125 34.0161C14.1563 34.0161 15.0391 34.3833 15.7734 35.1177C16.5078 35.852 16.875 36.7348 16.875 37.7661C16.875 38.7973 16.5078 39.6802 15.7734 40.4145C15.0391 41.1489 14.1563 41.5161 13.125 41.5161ZM31.875 41.5161C30.8438 41.5161 29.9609 41.1489 29.2266 40.4145C28.4922 39.6802 28.125 38.7973 28.125 37.7661C28.125 36.7348 28.4922 35.852 29.2266 35.1177C29.9609 34.3833 30.8438 34.0161 31.875 34.0161C32.9063 34.0161 33.7891 34.3833 34.5234 35.1177C35.2578 35.852 35.625 36.7348 35.625 37.7661C35.625 38.7973 35.2578 39.6802 34.5234 40.4145C33.7891 41.1489 32.9063 41.5161 31.875 41.5161ZM11.5313 11.5161L16.0313 20.8911H29.1563L34.3125 11.5161H11.5313ZM9.75 7.7661H37.4063C38.125 7.7661 38.6719 8.08641 39.0469 8.72704C39.4219 9.36766 39.4375 10.0161 39.0938 10.6723L32.4375 22.6723C32.0938 23.2973 31.6328 23.7817 31.0547 24.1255C30.4766 24.4692 29.8438 24.6411 29.1563 24.6411H15.1875L13.125 28.3911H35.625V32.1411H13.125C11.7188 32.1411 10.6563 31.5239 9.9375 30.2895C9.21875 29.0552 9.1875 27.8286 9.84375 26.6098L12.375 22.0161L5.625 7.7661H1.875V4.0161H7.96875L9.75 7.7661Z'
          fill='#1C1B1F'
        />
      </g>
    </svg>
  )
}

export const GlobalInfo = ({report}: {report: ReportStrategyData}) => {
  const intl = useIntl()
  return (
    <GlobalInfoContainer>
      <InfoCard bg='#E2EBD3'>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconTotalSales />
          <h4>{intl.formatMessage({id: 'STRATEGY.SALES.SALES'})}</h4>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <p>{addCommasInNumber(report.unitsSold)}</p>
        </div>
      </InfoCard>
      <InfoCard bg='#CBF3F9'>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconTotalProfit />
          <h4>{intl.formatMessage({id: 'STRATEGY.PROFIT.TOTAL'})}</h4>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <p>${report.orderProfit}</p>
        </div>
      </InfoCard>
    </GlobalInfoContainer>
  )
}
