import {OriginType, typePlaylist} from '../../../types'

export const RenderOrigin = ({origin}: {origin: OriginType}) => {
  const flex = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
  return (
    <div style={{...flex }}>
      {typePlaylist[origin].label}
    </div>
  )
}
