/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useMutation} from 'react-query'
import {MenuComponent} from '../../../../../../_gws/assets/ts/components'
import {useListView} from '../core/ListViewProvider'
import {deleteUser, verifyUser} from '../core/_requests'
import {KTSVG} from '../../../../../../_gws/helpers'
import {useIntl} from 'react-intl'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useAuth} from "../../../../auth";

type Props = {
  row: any
}

const UserActionsCell: FC<Props> = ({row}) => {
  const {setItemIdForUpdate} = useListView()
  const intl = useIntl()
  const {currentUser} = useAuth()

  const isAdmin = currentUser?.roleId === 2
  const Alert = withReactContent(Swal)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  /**
   * Used to send the updating flag and the user id to User Edit Form
   */
  const openEditModal = () => {
    setItemIdForUpdate(row.id)
  }

  /**
   * Used to delete a user
   */
  const deleteItem = useMutation(() => deleteUser(row.id), {
    onSuccess: () => {
      Alert.fire({
        title: intl.formatMessage({id: 'USER_MNGT.MODAL.DELETED_DONE'}),
        html: intl.formatMessage({id: 'USER_MNGT.MODAL.DELETED_MSG'}),
        icon: 'success',
        iconColor: '#00c6c7',
        buttonsStyling: false,
        confirmButtonText: intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'}),
        denyButtonText: 'Cancel',
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          popup: 'bg-light',
        },
      }).then(() => {
        window.location.reload()
      })
    },
  })

  /**
   * Used to activate a user
   */
  const activateItem = useMutation(() => verifyUser(row.id, row.verified), {
    onSuccess: () => {
      Alert.fire({
        title: intl.formatMessage({id: `USER_MNGT.MODAL.${row.verified ? "DEACTIVE":"ACTIVE"}_DONE`}),
        html: intl.formatMessage({id: `USER_MNGT.MODAL.${row.verified ? "DEACTIVE":"ACTIVE"}_MSG`}),
        icon: 'success',
        iconColor: '#00c6c7',
        buttonsStyling: false,
        confirmButtonText: intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'}),
        denyButtonText: 'Cancel',
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          popup: 'bg-light',
        },
      }).then(() => {
        window.location.reload()
      })
    },
  })

  /**
   * Alert notification to cancel or proceed with the elimination of user
   */
  const handleDeleteClick = () => {
    Alert.fire({
      title: intl.formatMessage({id: 'USER_MNGT.MODAL.DELETE.HEAD'}),
      html: `${intl.formatMessage({id: 'USER_MNGT.MODAL.DELETE.TEXT'})} </br> ${intl.formatMessage({
        id: 'USER_MNGT.MODAL.DELETE.TEXT2',
      })} `,
      icon: 'warning',
      iconColor: '#00c6c7',
      buttonsStyling: false,
      showCancelButton: true,
      focusConfirm: false,
      cancelButtonText: intl.formatMessage({id: 'USER_MNGT.MODAL.BUTTON.CANCEL'}),
      confirmButtonText: intl.formatMessage({id: 'USER_MNGT.MODAL.BUTTON.DELETE'}),
      reverseButtons: true,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-light-primary',
        popup: 'bg-light',
      },
    }).then(async (result) => {
      result.isConfirmed && (await deleteItem.mutateAsync())
    })
  }
  const handleActivateClick = () => {
    Alert.fire({
      title: intl.formatMessage({id: `USER_MNGT.TABLE.ACTIONS.${row.verified ? "DEACTIVATE":"ACTIVATE"}`}),
      html: `${intl.formatMessage({id:"USER_MNGT.MODAL.USER"})}: ${row.lastname}, ${row.name} 
                ${intl.formatMessage({id:"USER_MNGT.MODAL.WOULD"})} ${intl.formatMessage({id: `USER_MNGT.MODAL.${row.verified ? "DEACTIVE":"ACTIVE"}_DONE`})}`,
      icon: 'warning',
      iconColor: '#00c6c7',
      buttonsStyling: false,
      showCancelButton: true,
      focusConfirm: false,
      cancelButtonText: 'Cancelar',
      confirmButtonText: intl.formatMessage({id: `USER_MNGT.TABLE.ACTIONS.${row.verified ?  'DEACTIVATE':'ACTIVATE'}`}),
      reverseButtons: true,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-light-primary',
        popup: 'bg-light',
      },
    }).then(async (result) => {
      result.isConfirmed && (await activateItem.mutateAsync())
    })
  }
  return (
    <>
      <a
        href='#'
        className='py-3 px-5 border-0 mb-5 mt-5 text-primary bg-black bg-hover-opacity-75 rounded-pixel shadow-login-btn cursor-pointer text-nowrap'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <span className='d-inline-block'>
          {intl.formatMessage({id: 'USER_MNGT.TABLE.ACTIONS'})}
        </span>
        <KTSVG
          path='/media/icons/unicos/078-down-chevron.svg'
          className='svg-icon-5 m-0 ms-1 d-inline-block text-primary'
        />
      </a>

      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >

        {isAdmin && (
          <div className='menu-item px-3'>
          <a
              className='menu-link px-3'
              data-kt-users-table-filter='delete_row'
              onClick={handleActivateClick}
          >
            {intl.formatMessage({id: `USER_MNGT.TABLE.ACTIONS.${row.verified ? 'DEACTIVATE' : 'ACTIVATE'}`})}
          </a>
        </div>)}
        {row.roleId === 3 && (
          <div className='menu-item px-3'>
            <Link
              className={clsx('menu-link px-3')}
              to={'/apps/user-management/associate/' + row.id}
            >
              {intl.formatMessage({id: 'USER_MNGT.TABLE.ACTIONS.ASSOCIATE'})}
            </Link>
          </div>
        )}

        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            {intl.formatMessage({id: 'USER_MNGT.TABLE.ACTIONS.EDIT'})}
          </a>
        </div>

        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={handleDeleteClick}
          >
            {intl.formatMessage({id: 'USER_MNGT.TABLE.ACTIONS.DELETE'})}
          </a>
        </div>
      </div>
    </>
  )
}

export {UserActionsCell}
