import styled from '@emotion/styled'
import {Button} from 'react-bootstrap'

type CellProps = {
  event?: boolean
  bg?: string
}

type IndicatorProps = {
  bg?: string
}

export const Container = styled('div')({})

export const WrapperTable = styled('div')({
  marginBottom: '20px',
})

export const Table = styled('table')({
  borderCollapse: 'separate',
  borderSpacing: 0,
  width: '100%',
  '@media (max-width: 780px)': {
    overflow: 'auto',
    display: 'block',
    whiteSpace: 'nowrap',
  },
  th: {},
  'th, td': {
    padding: '10px',
    border: '1px solid #DDDDDD',
  },
  'tr:first-child th:first-child': {
    borderTopLeftRadius: '10px',
  },
  'tr:first-child th:last-child': {
    borderTopRightRadius: '10px',
  },
  'tr:last-child td:first-child': {
    borderBottomLeftRadius: '10px',
  },
  'tr:last-child td:last-child': {
    borderBottomRightRadius: '10px',
  },
})

export const HeaderCell = styled('th')({})

export const Cell = styled('td')<CellProps>(({event, bg}) => ({
  padding: '10px !important',
  transition: 'all ease .3s',
  background: bg ? bg : 'transpranet',
  ':hover': {
    background: event ? '#F5F6F8' : bg ? bg : 'transpranet',
    cursor: event ? 'pointer' : '',
  },
}))

export const ContentCell = styled('tr')({})

export const Btn = styled('button')({
  height: '100%',
  width: '100%',
  background: 'transparent',
  cursor: 'pointer',
  border: 'none',
  padding: '0px',
  textAlign: 'start',
  fontSize: '.9rem',
})

export const StyledButton = styled(Button)({
  background: '#0D7CBD !important',
  color: '#fff !important',
  marginBottom: '20px',
})

export const WrapperIndicators = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '1rem',
  margin: '20px 0px',
  '@media (max-width: 600px)': {
    gridTemplateColumns: '1fr',
  },
})

export const IndicatorCard = styled('div')<IndicatorProps>(({bg}) => ({
  padding: '20px',
  background: bg,
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  h6: {
    fontSize: '1.5rem',
    marginLeft: '10px',
    marginTop: '5px',
  },
  p: {
    fontSize: '1.5rem',
    marginTop: '5px',
  },
}))

export const WrapperColumn = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '1rem',
  margin: '15px 0px',
})

export const Line = styled('div')({
  height: '2px',
  background: '#DDDDDD',
  margin: '10px 0px',
})


export const Divider = styled('div')({
  width: '100%',
  height: '1px',
  borderRadius: '20px',
  background: '#F5F5F5',
})
