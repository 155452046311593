import {StatusType, statusPlaylist} from '../../../types'

export const RenderStatus = ({status}: {status: StatusType}) => {
  const flex = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
  return (
    <div style={{...flex }}>
      {statusPlaylist[status].label}
    </div>
  )
}
