import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PeriodSales} from './components/PeriodSales'
import {ProductHeader} from './components/ProductHeader'
import {AlertFetchError, Splash} from '../../../_gws/helpers/misc'
import {getAuth} from '../../modules/auth'
import {getProductTracker} from './core/_requests'
import {ProductDetails} from './components/ProductDetails'
import {KTSVG, toAbsoluteUrl} from '../../../_gws/helpers'
import {SelectOption} from '../../../_gws/partials/widgets/leyends/SelectOption'
import moment from 'moment'

type PropsProductTrackerReport = {
  mkp: string
  value: string
}

/**
 * Report Component
 * @param mkp {string} - Marketplace in text (amazon/meli)
 * @param value {string} - ASIN or ID
 */
const ProductTrackerReport: React.FC<PropsProductTrackerReport> = ({mkp, value}) => {
  const intl = useIntl()
  useEffect(() => {
    setQuery(value)
    setShowYear(value !== '' ? true : false)
    setShowReports(false)

    if (value !== '') {
      handleClick(new Date().getFullYear())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mkp, value])

  const [showReports, setShowReports] = useState(false)
  /**
   * Show/Hide the year selector
   */
  const [showYear, setShowYear] = useState(false)
  /**
   * Handle the text input as term of search
   */
  const [query, setQuery] = useState(value)
  /**
   * The year value of year picker
   */
  const [year, setYear] = useState(0)
  /**
   * The Marketplace selected in text param, used to get the info by the API's
   */
  const [mkpData, setMkpData] = useState(false)

  /**
   * Autogenerate years actually year - 1
   */
  const yearsAuto = () => {
    const yearsArr = []
    const dateStart = moment().subtract(1, 'y')
    const dateEnd = moment().add(0, 'y')
    let count = 0
    while (dateEnd.diff(dateStart, 'years') >= 0) {
      count++
      yearsArr.push({
        id: count,
        label: dateStart.format('YYYY').toString(),
        year: Number(dateStart.format('YYYY')),
      })
      dateStart.add(count, 'year')
    }
    return yearsArr
  }

  /**
   * Options for the year picker
   */
  let yearList = yearsAuto()

  /**
   * Handle the change of the search input text
   * @param value {any} - term search
   */
  const handleChange = (value: any) => {
    if (value.length > 0) {
      setShowYear(true)
    } else {
      setShowYear(false)
    }
    setYear(0)
    setQuery(value.toUpperCase())
  }

  /**
   * This function handle the click of the year button, after click the request is fired to get all the info
   * @param yearhandle {number} - year for the search
   */
  const handleClick = async (yearhandle: number) => {
    // if (yearhandle !== year) {
    setYear(yearhandle)
    Splash(true)

    /**
     * Show if is a authenticated user
     */
    const auth = getAuth()
    if (auth === undefined) {
      return
    }

    try {
      /**
       * API request
       */
      const response = await getProductTracker(mkp, query, yearhandle)
      if (response.data.status === 200) {
        setMkpData(response.data.detail)
      }
      Splash(false)
      setShowReports(true)
    } catch (error: any) {
      setShowReports(false)
      setYear(0)
      Splash(false)
      AlertFetchError(intl.locale, error).fire()
    } finally {
      Splash(false)
    }
    //}
  }

  return (
    <>
      <div className='tab-content fade show'>
        <div className='row mb-5 mt-5'>
          <h3 className='ms-1'>
            {intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.SEARCH_PRODUCT'})}&nbsp;
            <a href={'/#'} data-bs-toggle='modal' data-bs-target='#info_pt1_report_modal'>
              <KTSVG
                path={toAbsoluteUrl('/media/icons/duotune/general/gen046.svg')}
                className='svg-icon-2x svg-icon-dark'
              />
            </a>
          </h3>
          <div className='col-md-12 mb-5 '>
            <div className='form-floating'>
              <input
                type='text'
                className='form-control text-gray-800 fw-bold bg-gray-200'
                placeholder={
                  mkp === 'amazon'
                    ? intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.ASIN'})
                    : intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.ID'})
                }
                onChange={(e) => handleChange(e.target.value)}
                value={query}
              />

              <label htmlFor='buscarProducto'>{mkp === 'amazon' ? 'ASIN' : 'ID'}</label>
            </div>
          </div>
          {!showYear && (
            <SelectOption text={intl.formatMessage({id: 'GENERAL.LEYEND_SEARCH_ASIN_ID'})} />
          )}
          {showYear && (
            <div className='col d-flex'>
              {yearList.map((list) => {
                return (
                  <button
                    onClick={() => {
                      handleClick(list.year)
                    }}
                    key={list.id}
                    className={`btn btn-light-primary ms-1 fs-7 px-3 py-3 mb-5 ${
                      year === list.year ? 'active' : ''
                    }`}
                  >
                    <span>{list.label}</span>
                  </button>
                )
              })}
            </div>
          )}
          {year === 0 && showYear && (
            <SelectOption text={intl.formatMessage({id: 'GENERAL.LEYEND_SELECT_YEAR'})} />
          )}
        </div>
      </div>

      {showReports && (
        <>
          <div className='row'>
            <div className='col-12 col-md-5 order-2 order-md-1'>
              <ProductDetails dataReport={mkpData} query={query} />
            </div>
            <div className='col-12 col-md-7 order-1 order-md-2'>
              <ProductHeader dataReport={mkpData} />
              <PeriodSales dataReport={mkpData} />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export {ProductTrackerReport}
