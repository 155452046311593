/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {User} from './core/_models'
import {getClientToken} from './core/_request'
import {useAuth} from '../../../../../app/modules/auth'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'
import {AvatarUser} from '../../../../partials/widgets/misc/AvatarUser'

type Props = {
  title: string
  list: User
  setSearchVal: any
  setSearched: any
  actual: any
}

const ListClientPanel: FC<Props> = ({title, list, setSearchVal, setSearched, actual}) => {
  const intl = useIntl()

  const alert = withReactContent(Swal)
  const {saveClientAuth} = useAuth()
  const handleClick = async (id: number) => {
    try {
      const data = await getClientToken(id)
      if (data.status === 200) {
        saveClientAuth(data)
      }
    } catch (error: any) {
      alert.fire({
        text: 'Error',
        icon: 'warning',
        iconColor: '#00c6c7',
        buttonsStyling: false,
        confirmButtonText: `${intl.formatMessage({
          id: 'GENERAL.ALERT.CLIENT.404',
        })} ${intl.formatMessage({
          id: 'MENU.DASHBOARD',
        })}`,
        customClass: {
          confirmButton: 'btn btn-primary',
          popup: 'bg-light',
        },
      })
    } finally {
      /**
       * Fires only when the current user is merchant
       * and the user is switching to another client
       */
      // @ts-ignore
      window.location.href = '/brand-health'
    }
  }

  return (
    <>
      <div className='d-flex flex-stack fw-regular mb-4 px-3'>
        <span className='fs-6 me-2'>{title}</span>
      </div>

      <div className='scroll-y mh-200px mh-lg-325px'>
        {list?.map((client) => (
          <div
            className='d-flex align-items-center mb-5 p-2 rounded-1 bg-hover-light'
            onClick={(e) => {
              handleClick(client.id)
            }}
            key={client.id}
          >
            <AvatarUser //@ts-ignore
              picUrl={client.contactProfiles[0]?.picture}
              userID={client.id}
              name={client?.name}
              username={client?.username}
              lastname={client?.lastname}
              isMerchant={actual === client.id}
            />
            <div className='d-flex flex-column'>
              <span className='fs-6 text-gray-800 fw-semibold'>
                {client?.name} {client?.lastname}
              </span>
              <span className='fs-7 text-gray-600 fw-lighter'>{client.email}</span>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export {ListClientPanel}
