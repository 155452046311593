import axios from 'axios'
import {getTokenHeader} from '../../../modules/auth'
import {MARKETPLACES} from '../../../constants/marketplaces'

const API_URL_CRUNCH = process.env.REACT_APP_BACKEND_MAIN_API
const MKP_VALUES = {growker: 0, amazon: 1, meli: 2}
/**
 * API call to set the shepherd as saw
 */
export const updateShepherdSeen = () => {
  const head = getTokenHeader()
  let logURL = `${API_URL_CRUNCH}/api/profile/update-brandhealth`
  const config = {
    method: 'post',
    url: logURL,
    header: head,
  }
  return axios.request(config)
}
/**
 * API call to get brand health data
 * @param mkp - marketplace i.e.: 'amazon' / 'meli'
 * @param category - number of category, if it's 0 return the brand health summary
 */
export const getBrandHealthData = (mkp?: string, category?: number) => {
  const marketplaceId = Object.values(MARKETPLACES).find((mk) => mk.name === mkp)?.id
  const head = getTokenHeader()
  const cleanAxios = axios.create()

  return cleanAxios.get(
    `${API_URL_CRUNCH}/api/brand-health/?marketplaceId=${marketplaceId}`,
    // @ts-ignore
    head
  )
}

export const getBrandHealthDataByCategory = (url: string) => {
  const head = getTokenHeader()
  const cleanAxios = axios.create()
  return cleanAxios.get(
    url,
    // @ts-ignore
    head
  )
}

/**
 * API call to save an item in the playlist
 * @param data
 */
export const createPlaylistItem = (data: any) => {
  const head = getTokenHeader()
  const cleanAxios = axios.create()
  let logURL = `${API_URL_CRUNCH}/api/playlist`

  return cleanAxios.post(
    logURL,
    // @ts-ignore
    [data],
    // @ts-ignore
    head
  )
}

export const createPlaylistAllItems = (arrayData: any) => {
  const head = getTokenHeader()
  const cleanAxios = axios.create()
  let logURL = `${API_URL_CRUNCH}/api/playlist`
  let data = arrayData.map((item: any) => {
    let productId = item.id
    return {
      typeId: item.item_type_id,
      description: item.description,
      priority: item.health_status,
      marketplaceId:
        // @ts-ignore
        item.marketplace_id !== 0 ? item.marketplace_id : MKP_VALUES[marketplace],
      item: productId,
    }
  })

  return cleanAxios.post(
    logURL,

    data,
    // @ts-ignore
    head
  )
}
