import {FC} from 'react'
import {KTSVG} from '../../../helpers'
import {toAbsoluteUrl} from '../../../helpers'
import {AgPercentageCardText} from '../../../layout/components/ag/components/AgPercentageCardText'

type Props = {
  text: string
  backgroundColor?: string
  color?: string
  svgColor?: string
}

/**
 * This Component create a Label with Grow indicator
 *
 * @param text - string with the text, without % symbol
 * @param backgroundColor - background Color for the component. (variable sass)
 * @param color - color for text-component (variable sass)
 * @param svgColor - string with color svg icon (variable sass)
 */

const LabelWithGrowIndicator: FC<Props> = ({text, color, backgroundColor, svgColor}) => {
  const noData = Number(text) === 0
 

  const icon =
    Number(text) > 0
      ? 'media/icons/gws-icons/etiqueta-arrow-up.svg'
      : '/media/icons/gws-icons/etiqueta-arrow-decrec.svg'

  const svgColorB = svgColor ? svgColor : Number(text) > 0 ? 'success' : 'danger'
  const textColor = color ? color : Number(text) > 0 ? 'success' : 'danger'

  return (
    <div className={`d-flex justify-content-between align-items-center text-uppercase`}>
      {!noData && (
        <>
          <AgPercentageCardText customClass={`text-${textColor}`} text={`${text}%`} />
          <KTSVG
            path={toAbsoluteUrl(icon)}
            className={`svg-icon-10 svg-icon-${svgColorB} bg-light-${svgColorB} d-flex align-items-center p-1 mx-1 rounded-circle`}
          />
        </>
      )}
    </div>
  )
}

export {LabelWithGrowIndicator}
