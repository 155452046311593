import {useIntl} from 'react-intl'
import {
  CardGoals,
  GoalsProgressContainer,
  WrapperDataGoals,
  WrapperIcon,
  WrapperProgress,
} from './styled'
import {ReportStrategyData} from '../core/types'
import {ProgressWithNum} from '../../../../_gws/partials/newComponets/Progress'

const IconSalesUnits = () => {
  return (
    <svg width='35' height='35' viewBox='0 0 35 35' fill='none'>
      <mask id='mask0_152_121' maskUnits='userSpaceOnUse' x='0' y='0' width='35' height='35'>
        <rect width='35' height='35' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_152_121)'>
        <path
          d='M7.29167 11.6667H19.6146H18.9583H19.4688H7.29167ZM7.875 8.75H27.125L25.8854 7.29167H9.11458L7.875 8.75ZM14.5833 18.5938L17.5 17.1354L20.4167 18.5938V11.6667H14.5833V18.5938ZM21.2188 30.625H7.29167C6.48958 30.625 5.80295 30.3394 5.23177 29.7682C4.66059 29.1971 4.375 28.5104 4.375 27.7083V9.51563C4.375 9.17535 4.42969 8.84722 4.53906 8.53125C4.64844 8.21528 4.8125 7.92361 5.03125 7.65625L6.85417 5.43229C7.12153 5.09201 7.45573 4.83073 7.85677 4.64844C8.25781 4.46615 8.67708 4.375 9.11458 4.375H25.8854C26.3229 4.375 26.7422 4.46615 27.1432 4.64844C27.5443 4.83073 27.8785 5.09201 28.1458 5.43229L29.9688 7.65625C30.1875 7.92361 30.3516 8.21528 30.4609 8.53125C30.5703 8.84722 30.625 9.17535 30.625 9.51563V16.6615C30.1632 16.4913 29.6892 16.3576 29.2031 16.2604C28.717 16.1632 28.2187 16.1146 27.7083 16.1146V11.6667H23.3333V17.2448C22.4826 17.7309 21.7413 18.3325 21.1094 19.0495C20.4774 19.7665 19.9792 20.5747 19.6146 21.474L17.5 20.4167L11.6667 23.3333V11.6667H7.29167V27.7083H19.4688C19.6632 28.2674 19.9062 28.7899 20.1979 29.276C20.4896 29.7622 20.8299 30.2118 21.2188 30.625ZM26.25 30.625V26.25H21.875V23.3333H26.25V18.9583H29.1667V23.3333H33.5417V26.25H29.1667V30.625H26.25Z'
          fill='#1C1B1F'
        />
      </g>
    </svg>
  )
}

const IconIncome = () => {
  return (
    <svg width='35' height='35' viewBox='0 0 35 35' fill='none'>
      <mask id='mask0_152_115' maskUnits='userSpaceOnUse' x='0' y='0' width='35' height='35'>
        <rect width='35' height='35' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_152_115)'>
        <path
          d='M20.4166 18.9583C19.2014 18.9583 18.1684 18.533 17.3177 17.6823C16.467 16.8316 16.0416 15.7986 16.0416 14.5833C16.0416 13.3681 16.467 12.3351 17.3177 11.4844C18.1684 10.6337 19.2014 10.2083 20.4166 10.2083C21.6319 10.2083 22.6649 10.6337 23.5156 11.4844C24.3663 12.3351 24.7916 13.3681 24.7916 14.5833C24.7916 15.7986 24.3663 16.8316 23.5156 17.6823C22.6649 18.533 21.6319 18.9583 20.4166 18.9583ZM10.2083 23.3333C9.40623 23.3333 8.7196 23.0477 8.14842 22.4766C7.57724 21.9054 7.29165 21.2187 7.29165 20.4167V8.74999C7.29165 7.94791 7.57724 7.26128 8.14842 6.6901C8.7196 6.11892 9.40623 5.83333 10.2083 5.83333H30.625C31.4271 5.83333 32.1137 6.11892 32.6849 6.6901C33.2561 7.26128 33.5416 7.94791 33.5416 8.74999V20.4167C33.5416 21.2187 33.2561 21.9054 32.6849 22.4766C32.1137 23.0477 31.4271 23.3333 30.625 23.3333H10.2083ZM13.125 20.4167H27.7083C27.7083 19.6146 27.9939 18.9279 28.5651 18.3568C29.1363 17.7856 29.8229 17.5 30.625 17.5V11.6667C29.8229 11.6667 29.1363 11.3811 28.5651 10.8099C27.9939 10.2387 27.7083 9.55208 27.7083 8.74999H13.125C13.125 9.55208 12.8394 10.2387 12.2682 10.8099C11.697 11.3811 11.0104 11.6667 10.2083 11.6667V17.5C11.0104 17.5 11.697 17.7856 12.2682 18.3568C12.8394 18.9279 13.125 19.6146 13.125 20.4167ZM29.1666 29.1667H4.37498C3.5729 29.1667 2.88626 28.8811 2.31508 28.3099C1.7439 27.7387 1.45831 27.0521 1.45831 26.25V10.2083H4.37498V26.25H29.1666V29.1667Z'
          fill='#1C1B1F'
        />
      </g>
    </svg>
  )
}
export const GoalsProgress = ({report}: {report: ReportStrategyData}) => {
  const intl = useIntl()
  return (
    <GoalsProgressContainer>
      <CardGoals>
        <WrapperDataGoals>
          <WrapperIcon bg={report.unitsToSell.color}>
            <IconSalesUnits />
          </WrapperIcon>
          <div>
            <h6>{intl.formatMessage({id: 'STRATEGY.TARGET.SALES.UNIT'})}</h6>
            <p>
              {report.unitsToSell.from} {intl.formatMessage({id: 'REPORT.INVENTORY.FROM'})}{' '}
              {report.unitsToSell.to}
            </p>
          </div>
        </WrapperDataGoals>
        <WrapperProgress>
          <ProgressWithNum
            labelColor={`${report.unitsToSell.progressColor}`}
            backgroundColor={`${report.unitsToSell.progressColor}`}
            visualParts={[
              {
                percentage: `${report.unitsToSell.progress}%`,
                color: `${report.unitsToSell.progressColor}`,
              },
            ]}
          />
        </WrapperProgress>
      </CardGoals>
      <CardGoals>
        <WrapperDataGoals>
          <WrapperIcon bg={report.income.color}>
            <IconIncome />
          </WrapperIcon>
          <div>
            <h6>{intl.formatMessage({id: 'STRATEGY.TARGET.PROFIT.GENERAL'})}</h6>
            <p>
              ${report.income.from} {intl.formatMessage({id: 'REPORT.INVENTORY.FROM'})} $
              {report.income.to}
            </p>
          </div>
        </WrapperDataGoals>
        <WrapperProgress>
          <ProgressWithNum
            labelColor={`${report.income.progressColor}`}
            backgroundColor={`${report.income.progressColor}`}
            visualParts={[
              {
                percentage: `${report.income.progress}%`,
                color: `${report.income.progressColor}`,
              },
            ]}
          />
        </WrapperProgress>
      </CardGoals>
    </GoalsProgressContainer>
  )
}
