import {useIntl} from 'react-intl'
import {GlobalInfo} from './components/GlobalInfo'
import {StrategyCharts} from './components/StrategyCharts'
import {Container} from './components/styled'
import {ReportStrategyData} from './core/types'
import {GoalsProgress} from './components/GoalsProgress'

export const SalesReportStrategy = ({report}: {report: ReportStrategyData}) => {
  const intl = useIntl()
  return (
    <Container>
      <div>
        <GlobalInfo report={report} />
      </div>
      <div>
        <h4
          style={{
            fontWeight: '1.6rem',
            margin: '20px 0px',
          }}
        >
          {intl.formatMessage({id: 'STRATEGY.REPORT.GOALS'})}
        </h4>
        <GoalsProgress report={report} />
      </div>
      <div>
        <StrategyCharts report={report}/>
      </div>
    </Container>
  )
}
