import axios, {AxiosResponse} from 'axios'
import {User, ID, Response} from './_models'
import {getAuth} from '../../../../auth'

const API_URL_CRUNCH = `${process.env.REACT_APP_BACKEND_MAIN_API}/api`

const auth = getAuth()

const USER_URL = `${API_URL_CRUNCH}/users`

/**
 * It returns a promise that resolves to the data property of the response object
 * from an axios request to the USER_URL with the query and page number as
 * parameters
 * @param {string} query - string - The query string to search for users, list
 * @returns A promise that resolves to an array of users
 */
const getUsers = (query: string): Promise<any> => {
  return axios.get(`${USER_URL}/${query}/1`).then((d: AxiosResponse<any>) => d.data)
}

/**
 * It takes an ID and returns a Promise that resolves to a User or undefined
 * @param {ID} id - ID
 * @returns A promise that resolves to a User or undefined
 */
const getUserById = (id: ID): Promise<User | undefined> => {
  return axios.get(`${USER_URL}/${id}`).then((response) => {
    const {data} = response
    return data.detail
  })
}

/**
 * It creates a user
 * @param {User} user - User - This is the user object that we're going to send to
 * the API.
 * @returns A promise that returns a user or undefined
 */
const createUser = (user: User): Promise<User | undefined> => {
  const data = {
    // @ts-ignore
    roleId: user.roleId,
    name: user.name,
    lastname: user.lastname,
    email: user.email,
    password: 'GrowkerGWS.1234',
    // @ts-ignore
    saasPlan: user.saasPlan,
  }
  return axios
    .post(`${USER_URL}`, data, {
      headers: {
        'crunch-dna-token': auth !== undefined ? auth?.token : '',
        'Content-Type': 'application/json',
      },
    })
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}
/**
 * It restores the user's password and returns a promise that resolves to a user or undefined
 * @param {User} user - User - The user object that we want to update.
 * @returns A promise that resolves to a user or undefined
 */
const restorePassword = (user: User): Promise<User | undefined> => {
  let data = {
    // @ts-ignore
    roleId: user.roleId,
    name: user.name,
    lastname: user.lastname,
    email: user.email,
    password: 'GrowkerGWS.1234',
    // @ts-ignore
    saasPlan: parseInt(user.saasPlan),
  }
  return axios
    .post(`${USER_URL}/${user.id}`, data, {
      headers: {
        'crunch-dna-token': auth !== undefined ? auth?.token : '',
        'Content-Type': 'application/json',
      },
    })
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

/**
 * It updates a user and returns a promise that resolves to a user or undefined
 * @param {User} user - User - The user object that we want to update.
 * @returns A promise that resolves to a user or undefined
 */
const updateUser = (user: User): Promise<User | undefined> => {
  let data = {
    roleId: user.roleId,
    name: user.name,
    lastname: user.lastname,
    email: user.email,
    saasPlan: user.saasPlan,
  }
  return axios
    .post(`${USER_URL}/${user.id}`, data, {
      headers: {
        'crunch-dna-token': auth !== undefined ? auth?.token : '',
        'Content-Type': 'application/json',
      },
    })
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<any> => {
  return axios.delete(`${USER_URL}/${userId}`, {
    headers: {
      'crunch-dna-token': auth !== undefined ? auth?.token : '',
    },
  })
}

const verifyUser = (userId: ID, verified: boolean): Promise<any> => {
  return axios.post(
    `${USER_URL}/update/verification/${userId}`,
    {
      verified: !verified,
    },
    {
      headers: {
        'crunch-dna-token': auth !== undefined ? auth?.token : '',
      },
    }
  )
}


export {getUsers, deleteUser, getUserById, createUser, updateUser, restorePassword, verifyUser}

export function getRolesList() {
  return axios.get(USER_URL + '/roles/selector')
}

export function getSaasPlans() {
  return axios.get(USER_URL + '/saas-plans/selector')
}
