import {useState} from 'react'
import styled from '@emotion/styled'
import {KTSVG, toAbsoluteUrl} from '../../../../_gws/helpers'

type TabProps = {
  active: boolean
}

const Tabs = styled('div')({
  display: 'flex',
  marginTop: '20px',
  borderBottom: '1px solid #9E9E9E',
})

const Tab = styled('div')<TabProps>(({active}) => ({
  marginRight: '15px',
  marginLeft: "10px",
  cursor: 'pointer',
  transition: 'all ease .3s',
  borderBottom: !active ? '3px solid transparent' : '3px solid #000',
  h4: {
    color: !active ? '#9E9E9E' : '#000',
  },
}))

const TabContent = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const PlaylistTab = ({ content, isCreate }: { content: any, isCreate?: boolean }) => {
  const [activeTab, setActiveTab] = useState({
    label: 'tasks',
  })
  const handlerChangeTab = (value: string) => {
    setActiveTab((pre) => ({
      ...pre,
      label: value,
    }))
  }
  const tabs = [
    {
      label: 'tasks',
      text: 'Tareas',
      icon: 'media/icons/gws-icons/view_week.svg',
    },
  ]
  return (
    <div>
      <Tabs>
        {tabs.map((item) => (
          <Tab
            onClick={() => handlerChangeTab(item.label)}
            key={item.label}
            active={item.label === activeTab.label}
          >
            <TabContent>
              <KTSVG
                path={toAbsoluteUrl(item.icon)}
                className={`svg-icon-1x svg-icon-${
                  item.label === activeTab.label ? 'dark' : 'grey'
                }`}
              />
              <h6 style={{marginTop: '3px', marginLeft: '5px'}}>{item.text}</h6>
            </TabContent>
          </Tab>
        ))}
      </Tabs>
      <div
        className='card'
        style={{borderRadius: '0px 0px 10px 10px', boxShadow: '-1px 2px 7px -5px #0000001A'}}
      >
        <div className={isCreate ? '': 'card-body'}>{content[activeTab.label]}</div>
      </div>
    </div>
  )
}
