import React from 'react'
import {TextProps} from '../AgText'
import { FontSizes } from '../../../../../app/constants/sizes'

/**
 * Standard prototype of Text "AgPercentageText" | renders a div element with a specific font size and line height, and can have a custom class and text provided as properties.
 * @param customClass - to add extra classes
 * @param text - the text content
 * @constructor
 */
const AgPercentageText: React.FC<TextProps> = ({customClass, text, children}) => {
  /**
   * static css for the component
   */
  const css = {
    fontSize: FontSizes.SMALL.SIZE_4, // '1.14em',
    lineHeight: '1.1em'
  }

  return (
    <div className={`ff-prompt ${customClass}`} style={css}>
      {text}
      {children}
    </div>
  )
}

export {AgPercentageText}
