import React, {FC} from 'react'
import {Doughnut} from 'react-chartjs-2'
import {datalabelsDefault, matrixColor} from '../../../../_gws/helpers/misc'
import {useIntl} from 'react-intl'

type PieProps = {
  listLabels: any
  listCounts: any
}
const FrankiePieChart: FC<PieProps> = ({listLabels, listCounts}) => {
  const intl = useIntl()
  const makeColorList = (listLabels: []) => {
    let listColors: any[] = []
    for (let i = 0; i < listLabels.length; i++) {
      listColors = [...listColors, matrixColor(i)]
    }
    return listColors
  }
  const dataPie = {
    labels: listLabels,
    datasets: [
      {
        data: listCounts,
        backgroundColor: makeColorList(listLabels),
        borderWidth: 1,
        datalabels: datalabelsDefault(0, false),
      },
    ],
  }
  const optionPie = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom' as const,
        labels: {
          padding: 20,
        },
      },
    },
  }
  return (
    <div className={'card h-100'}>
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title fw-semibold text-dark'>{`${intl.formatMessage({
          id: 'REPORT.INVENTORY.TABLE.HEAD.STATE',
        })}`}</h3>
        <p className='text-muted fw-semibold fs-7 mt-1'>
          {`${intl.formatMessage({id: 'REPORT.FRANKIE.INVENTORY.STATE.TEXT'})}`}
        </p>
      </div>

      <div className='card-body d-flex align-items-center p-0'>
        <Doughnut data={dataPie} options={optionPie} />
      </div>
    </div>
  )
}

export {FrankiePieChart}
