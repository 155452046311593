import {FC, useEffect, useState} from 'react'
import {Splash} from '../../../../../_gws/helpers/misc'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {TopCardsContainer} from './TopCardsContainer'
import {TableWrapper} from './table/TableWrapper'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {getUsers} from './core/_requests'
import {UserEditModal} from './modals/UserEditModal'

type Props = {
  listUsers: any
  totalRoles: any
}

/**
 * Used to wrap Top Cards and Table containers
 * @param listUsers - List of Users
 * @component
 */
const UsersList: FC<Props> = ({listUsers, totalRoles}) => {
  const {itemIdForUpdate} = useListView()

  return (
    <>
      <TopCardsContainer totalRoles={totalRoles} />
      <TableWrapper listUser={listUsers} />
      {itemIdForUpdate !== undefined && <UserEditModal height={90} />}
    </>
  )
}

const UsersListWrapper: FC = () => {
  const [listUsers, setListUsers] = useState<any>([])
  const intl = useIntl()
  const navigate = useNavigate()

  const Alert = withReactContent(Swal)

  /**
   * It fetches all the users from the backend and sets the list of users in the
   * state.
   */
  const fetchAllUsers = async () => {
    try {
      Splash(true)
      const data = await getUsers('/list')
      if (data?.status === 200) {
        setListUsers(data?.detail)
        Splash(false)
      }
    } catch (error: any) {
      Alert.fire({
        title: intl.formatMessage({id: 'ERROR.BACKEND.FETCH'}),
        text: intl.formatMessage({id: 'USER_MNGT.ALERT.UNABLE_USER'}),
        icon: 'error',
        iconColor: '#00c6c7',
        buttonsStyling: false,
        confirmButtonText: intl.formatMessage({id: 'ACCOUNT.PROFILE.DETAILS.OK_GOT_IT'}),
        customClass: {
          confirmButton: 'btn btn-primary',
          popup: 'bg-light',
        },
      }).then(() => {
        navigate('/')
      })
    }
  }

  useEffect(() => {
    fetchAllUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (listUsers && listUsers?.users?.length > 0) ? (
    <>
      {/*// QueryRequestProvider, QueryResponseProvider, ListViewProvider: used to fire the selected user in the actions options on the table*/}
      <QueryRequestProvider>
        <QueryResponseProvider>
          <ListViewProvider>
            <UsersList listUsers={listUsers?.users} totalRoles={listUsers?.totalRoles} />
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  ) : (
    <>{intl.formatMessage({id: 'REPORT.LOGISTICS.NO_DATA'})}</>
  )
}

export {UsersListWrapper}
