import {Chart} from 'react-chartjs-2'
import {useIntl} from 'react-intl'
import React from 'react'
import {abbreviateNumber, matrixColor, tooltipDefault} from '../../../../_gws/helpers/misc'
import {KTSVG, toAbsoluteUrl} from '../../../../_gws/helpers'

type PropsTabPaneProductTracker = {
  dataReport?: any
}

const TabPaneProductTracker: React.FC<PropsTabPaneProductTracker> = ({dataReport}) => {
  const intl = useIntl()

  /**
   * Empty arrays to build mapping the API information
   */
  const labels: any = []
  const totalAmount: any = []
  const orders: any = []
  const values: any = []

  /**
   * Mapping of the object and fill the empty arrays for labels and values for Chartjs
   */
  if (dataReport) {
    Object.entries(dataReport).map((item) => {
      labels.push(intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.' + item[0].toUpperCase()}))
      values.push(item[1])
      return ''
    })

    values.map((e: any) => {
      totalAmount.push(e.totalAmount)
      orders.push(e.orders)
      return ''
    })
  }

  /**
   * Options for Chartjs graph
   */
  const optionsChart = {
    aspectRatio: 1 | 2,
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    scales: {
      x: {
        title: {
          display: false,
        },
        stacked: true,
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          color: '#a1a2b6',
        },
      },
      y: {
        title: {
          display: true,
          text: intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.ORDERS'}),
          color: '#a1a5b7',
          font: {
            weight: 'normal',
          },
        },
        stacked: true,
        grid: {
          drawBorder: false,
          drawTicks: false,
          borderDash: [5, 5],
          lineWidth: 1,
          color: function () {
            return 'rgba(161,162,182,0.17)'
          },
        },
        ticks: {
          color: '#a1a2b6',
          stepSize: 1,
          min: 1,
        },
      },
      y1: {
        title: {
          display: true,
          text: intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.TOTAL_AMOUNT'}),
          color: '#a1a5b7',
          font: {
            weight: 'normal',
          },
        },
        stacked: true,
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          color: '#a1a2b6',
          callback: function (value: any, index: any, ticks: any) {
            return '$' + abbreviateNumber(value, 0)
          },
        },
        position: 'right' as const,
      },
    },
  }

  /**
   * Data and colors for the Chartjs graph
   */
  const dataChart: any = {
    labels: labels,
    datasets: [
      {
        type: 'line' as const,
        label: intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.ORDERS'}),
        data: orders,
        borderColor: matrixColor(6),
        pointBackgroundColor: matrixColor(13),
        pointRadius: 4,
        pointHoverRadius: 8,
        fill: false,
        tension: 0.45,
        datalabels: {
          display: false,
        },
      },
      {
        type: 'bar' as const,
        label: intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.TOTAL_AMOUNT'}),
        data: totalAmount,
        backgroundColor: matrixColor(1),
        stack: 'Stack 1',
        yAxisID: 'y1',
        tension: 0.45,
        tooltip: tooltipDefault('$'),
        borderRadius: 8,
        maxBarThickness: 16,
        borderSkipped: false,
        datalabels: {
          color: '#000000',
          backgroundColor: 'rgba(255,255,255, 0.5)',
          padding: {
            top: 2,
            left: 2,
            right: 2,
            bottom: 1,
          },
          borderRadius: 2,
          borderWidth: 0.5,
          rotation: -90,
          font: {
            size: 10,
            // weight: 'bold' as const,
          },
          align: 'center' as const,
          anchor: 'center' as const,
          display: function (context: any) {
            return context.dataset.data[context.dataIndex] > 0
          },
          formatter: function (value: any, context: any) {
            return '$' + abbreviateNumber(value, 0)
          },
        },
      },
    ],
  }

  return (
    <div className='card'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            {intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.UNITS_SOLD_YEAR'})}&nbsp;
            <a href={'/#'} data-bs-toggle='modal' data-bs-target='#info_pt3_report_modal'>
              <KTSVG
                path={toAbsoluteUrl('/media/icons/duotune/general/gen046.svg')}
                className='svg-icon-2x svg-icon-dark'
              />
            </a>
          </span>

          <span className='text-muted fw-semibold fs-7'>
            {intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.UNITS_SOLD_YEAR_DESCRIPTION'})}
          </span>
        </h3>
      </div>
      <div className='card-body'>
        <Chart data={dataChart} options={optionsChart} type='bar' />
      </div>
    </div>
  )
}
export {TabPaneProductTracker}
