import React, {FC, useState} from 'react'
// @ts-ignore
import Flatpickr from 'react-flatpickr'
import {Spanish} from 'flatpickr/dist/l10n/es.js'
import {useIntl} from 'react-intl'
import moment from 'moment/moment'

type Props = {
  changeDate?: Function
  defaultDate?: Boolean
  date?: any
}

/**
 * This function is a React component that renders a range picker
 * changeDate({start: dateFrom, end: dateTo})
 * @params changeDate: function that returns when the date has changed
 * @param defaultDate : boolean, if it's true the calendar show the current month. is only used for logistic report component.
 */
const NewCalendar: FC<Props> = ({changeDate, defaultDate , date}) => {
  const intl = useIntl()

  const today = moment()
  const startDate = today.clone().startOf('month').format('YYYY-MM-DD')
  const endDate = today.clone().subtract(1, 'days').format('YYYY-MM-DD')

  const newToday = [
    moment().subtract(1, 'days').subtract(1, 'months').format('YYYY-MM-DD'),
    moment().subtract(1, 'days').format('YYYY-MM-DD'),
  ]

  const locale = intl.locale === 'en' ? 'en' : Spanish
  const [maxDate] = useState(moment().subtract(1, 'days').format('YYYY-MM-DD'))
  const [minDate] = useState(moment().subtract(60, 'days').format('YYYY-MM-DD'))

  const dateFormat = 'Y-m-d'

  const options = {
    locale: locale,
    minDate: minDate,
    inline: true,
    maxDate: maxDate,
    dateFormat: dateFormat,
    defaultDate: newToday,
    mode: 'range',
  }
  const optionsDefault = {
    locale: locale,
    minDate: startDate,
    inline: true,
    maxDate: endDate,
    dateFormat: dateFormat,
    defaultDate: date? [date.start, date.end]: [startDate, endDate],
    mode: 'range',
  }

  const onChangeDate = (date: any) => {
    const dateFrom = moment(date[0]).format('YYYY-MM-DD')
    const dateTo = moment(date[1]).format('YYYY-MM-DD')
    if (date[1] >= date[0] && changeDate !== undefined) {
      changeDate({start: dateFrom, end: dateTo})
    }
  }

  return (
    <>
      <div className='card position-relative d-flex align-items-center rounded-4 w-100 h-100'>
        <Flatpickr
          name='to'
          className='visually-hidden'
          options={defaultDate ? optionsDefault : options}
          onChange={(e: any) => {
            onChangeDate(e)
          }}
        />
      </div>
    </>
  )
}

export {NewCalendar}
