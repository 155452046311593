import axios from 'axios'
import {getTokenHeader, UserGoalsModel} from '../../../modules/auth'

const API_URL_CRUNCH = process.env.REACT_APP_BACKEND_MAIN_API

export const REQUEST_SALES_URL = `${API_URL_CRUNCH}/api/reports/sales/`
export const REQUEST_PROFILE_GOALS_URL = `${API_URL_CRUNCH}/api/profile/goals`

export function getSales(mktplace: string, periodReportType: number) {
  const axiosClean = axios.create()
  return axiosClean.post(
    REQUEST_SALES_URL + mktplace,
    {
      periodType: periodReportType
    },
    // @ts-ignore
    getTokenHeader()
  )
}

export const updateGoals = (goals: UserGoalsModel) => {
  const axiosClean = axios.create()
  let data = [
    {
      goalTypeId: 1,
      value: goals.profit,
    },
    {
      goalTypeId: 2,
      value: goals.sales,
    },
  ]

  return axiosClean.post(
    REQUEST_PROFILE_GOALS_URL,
    {
      data,
    },
    // @ts-ignore
    getTokenHeader()
  )
}
