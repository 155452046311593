type Props = {
  customClass?: string
  text: string
}

/**
 * Standard prototype of h2 | renders an h2 heading with a specific font size and line height, and can have a custom class and text provided as properties.
 * @param customClass - to add extra classes
 * @param text - the text content
 * @component
 */
const AgTitleH2: React.FC<Props> = ({customClass, text}) => {
  /**
   * static css for the component
   */
  const css = {
    fontSize: '1.25em',
    linHeight: '23px',
  }

  return (
    <h2 className={`ff-prompt fw-normal ${customClass}`} style={css}>
      {text}
    </h2>
  )
}

export {AgTitleH2}
