import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import {useIntl} from 'react-intl'

const CustomToggle = React.forwardRef(({children, onClick}: any, ref: any) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      border: '1px solid #212121',
      padding: '10px',
      width: '100px',
      borderRadius: '5px',
    }}
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
  >
    {children}
  </div>
))

const styleMenu = {
  padding: '10px',
  background: '#fff',
  boxShadow: '1px 2px 4px 2px #0000001A',
}
export const FilterReport = ({handlerPeriodFilter}: {handlerPeriodFilter: any}) => {
  const intl = useIntl()
  return (
    <Dropdown
    >
      <Dropdown.Toggle as={CustomToggle}>
        {intl.formatMessage({id: 'PLAYLIST.FILTER'})}
      </Dropdown.Toggle>

      <Dropdown.Menu style={{...styleMenu}}>
        <Dropdown.Item onClick={() => handlerPeriodFilter(1)}>
          {intl.formatMessage({id: 'REPORT.FILTERS.ACTUAL'})}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handlerPeriodFilter(2)}>
          {intl.formatMessage({id: 'REPORT.FILTERS.30.DAYS'})}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handlerPeriodFilter(3)}>
          {intl.formatMessage({id: 'REPORT.FILTERS.15.DAYS'})}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handlerPeriodFilter(4)}>
          {intl.formatMessage({id: 'REPORT.FILTERS.7.DAYS'})}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
