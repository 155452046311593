import React from 'react'
import {AgTitleCard} from '../../../layout/components/ag/components/AgTitleCard'
import {KTSVG} from '../../../helpers'
import {toAbsoluteUrl} from '../../../helpers'
import {AgText} from '../../../layout/components/ag/AgText'

type CardSliderProps = {
  title: string
  textNumber: string
  icon: string
  svgColor: string
  backgroundColorSvg: string
  textState: string
}

/**
 * This Component create a Card with title, text Number, and KTSVG with an icon.
 *
 * @param title - string with the title
 * @param textNumber - string with de number of action
 * @param icon - string with the refer to icon
 * @param svgColor - string with color for KTSVG component (sass variable)
 * @param backgroundColorSvg - string with color for background color to KTSVG component (sass variable)
 */

const CardTitleIcon: React.FC<CardSliderProps> = ({
  title,
  textNumber,
  icon,
  svgColor,
  backgroundColorSvg,
  textState,
}) => {
  return (
    <div className='card d-flex w-100 flex-column flex-column align-items-start p-4 m-0 h-100 rounded-2'>
      <AgTitleCard text={title} />
      <div className={'w-100 d-flex flex-column justify-content-between flex-fill'}>
        <AgText choice={'biggernumbercard'} text={textNumber} /> 
        <div className='d-flex flex-row justify-content-between align-items-end w-100 mt-2'>
          <AgText choice={'PercentageCard'} text={textState} />
          <KTSVG
            path={toAbsoluteUrl(icon)}
            className={`svg-icon-2x svg-icon-${svgColor} bg-${backgroundColorSvg} rounded-1 p-3 pt-2`}
          />
        </div>
      </div>
    </div>
  )
}

export {CardTitleIcon}
