import {FC, useState} from 'react'
import {toAbsoluteUrl} from '../../../../_gws/helpers'
import {useAuth} from '../../auth'
import {MarketPlacePill} from './MarketPlacePill'
import styled from '@emotion/styled'

type PropsSelectorMktPlace = {
  setMkplace: any
  pathname: string
  onlyShowMarketplace?: boolean
  helperBubble?: boolean
}

const WrapperMkpPills = styled('div')({
  padding: '2px',
  display: 'flex',
  width: '100%',
  overflow: 'auto',
  height: '70px',
})
const hasGrowkerOptionArray = ['/brand-health', '/glossary']

const MarketplacesListHeader: FC<PropsSelectorMktPlace> = ({setMkplace, pathname}) => {
  const [path] = useState(pathname)
  const {currentMkp, setCurrentMkp, hasMeli, hasAmazon} = useAuth()

  // const {mode} = useThemeMode()

  let listMkp: any[] = [
    {
      ans: 'amazon',
      img: toAbsoluteUrl('/media/marketplace/amazon-selector.svg'),
      imgDark: toAbsoluteUrl('/media/marketplace/amazon-selector-white.svg'),
      imgList: toAbsoluteUrl('/media/icons/amazon-icon.svg'),
      name: 'Amazon',
      shortname: 'amazon',
      show: hasAmazon,
    },
    {
      shortname: 'meli',
      name: 'Mercado Libre',
      img: toAbsoluteUrl('/media/marketplace/meli-selector.svg'),
      imgDark: toAbsoluteUrl('/media/marketplace/meli-selector-white.svg'),
      imgList: toAbsoluteUrl('/media/icons/meli-icon.svg'),
      ans: 'meli',
      show: hasMeli,
    },
  ]

  /*let listMkpToBe: any[] = [
    {
      ans: 'shopify',
      img: toAbsoluteUrl('/media/marketplace/shopify-selector.svg'),
      imgDark: toAbsoluteUrl('/media/marketplace/shopify-selector-white.svg'),
      imgList: toAbsoluteUrl('/media/icons/shopify-icon.svg'),
      name: 'Shopify',
      shortname: 'shopify',
      tooltip: intl.formatMessage({id: 'BRAND_HEALTH.KPI.PRODUCTS.TOOLTIP'}),
    },
    {
      shortname: 'claroshop',
      name: 'Claro Shop',
      img: toAbsoluteUrl('/media/marketplace/claroshop-selector.svg'),
      imgDark: toAbsoluteUrl('/media/marketplace/claroshop-selector-white.svg'),
      imgList: toAbsoluteUrl('/media/icons/meli-claroshop.svg'),
      ans: 'claroshop',
      tooltip: intl.formatMessage({id: 'BRAND_HEALTH.KPI.PRODUCTS.TOOLTIP'}),
    },
    {
      shortname: 'facebookMarketplace',
      name: 'Facebook Marketplace',
      img: toAbsoluteUrl('/media/marketplace/facebookMarketplace-selector.svg'),
      imgDark: toAbsoluteUrl('/media/marketplace/facebookMarketplace-selector-white.svg'),
      imgList: toAbsoluteUrl('/media/icons/meli-facebookMarketplace.svg'),
      ans: 'facebookMarketplace',
      tooltip: intl.formatMessage({id: 'BRAND_HEALTH.KPI.PRODUCTS.TOOLTIP'}),
    },
  ]*/
  /**
   * Check if the current path hast the string 'brand-health' or 'inventory' or 'sales'
   */
  const hasGrowkerOption = hasGrowkerOptionArray.includes(`/${path.split('/')[1]}`)
  if (hasGrowkerOption && hasAmazon && hasMeli) {
    setMkplace(currentMkp)
    setCurrentMkp(currentMkp)
  }

  currentMkp === 'growker' && !(hasAmazon && hasMeli) && hasAmazon && setMkplace('amazon')
  currentMkp === 'growker' && !(hasAmazon && hasMeli) && hasMeli && setMkplace('meli')
  /**
   * If the current path has the string 'brand-health' and the marketplace is not 'growker' then remove growker from the list
   */
  if (listMkp.findIndex((mk) => mk.ans === 'growker') !== -1) {
    listMkp.splice(0, 1)
  }

  /**
   * If the current path has the string 'brand-health' and the marketplace is not 'growker', then add growker to the list
   */
  if (hasGrowkerOption && hasAmazon && hasMeli) {
    if (listMkp.findIndex((mk) => mk.ans === 'growker') === -1) {
      listMkp = [
        {
          shortname: 'growker',
          name: 'Growker',
          ans: 'growker',
          img: toAbsoluteUrl('/media/marketplace/growker-selector.svg'),
          imgDark: toAbsoluteUrl('/media/marketplace/growker-selector-white.svg'),
          imgList: toAbsoluteUrl('/media/icons/growker-icon.svg'),
          show: true,
        },
        ...listMkp,
      ]
    }
  }

  const handleClick = (mkp: any) => {
    setCurrentMkp(mkp.shortname)
    setMkplace(mkp.ans)
  }

  return (
    <>
      <WrapperMkpPills>
        {pathname === '/DI.A.gnostik' || pathname === '/performace-monitor'
          ? [listMkp[0]].map((mkp: any) => {
              return mkp.show ? (
                <MarketPlacePill mkp={mkp} key={mkp.ans} handleClick={handleClick} />
              ) : null
            })
          : listMkp.map((mkp: any) => {
              return mkp.show ? (
                <MarketPlacePill mkp={mkp} key={mkp.ans} handleClick={handleClick} />
              ) : null
            })}
      </WrapperMkpPills>
    </>
  )
}

export {MarketplacesListHeader}
