/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {KTSVG} from '../../../helpers'
import {ElementWithIcon} from '../../content/list/ElementWithIcon'
import {useIntl} from 'react-intl'

const BrandHealthReportModal: FC = () => {
  const intl = useIntl()
  return (
    <>
      <div className='modal fade' id='info_pmk1_report_modal' aria-hidden='true'>
        <div className='modal-dialog mw-550px modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='text-center mb-13'>
              <h1 className='mb-3'>
                {intl.formatMessage({id: 'REPORT.PAID_MARKETING.MODAL.FEATURED_KPI'})}
              </h1>
            </div>

            <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
              <ElementWithIcon
                icon={''}
                title={intl.formatMessage({id: 'BRAND_HEALTH.MODAL.CAMPAIGNS.TITLE'})}
                description={intl.formatMessage({
                  id: 'BRAND_HEALTH.MODAL.CAMPAIGNS.DESC',
                })}
              />

              <ElementWithIcon
                icon={''}
                title={intl.formatMessage({id: 'BRAND_HEALTH.MODAL.PRODUCT.TITLE'})}
                description={intl.formatMessage({
                  id: 'BRAND_HEALTH.MODAL.PRODUCT.DESC',
                })}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' id='info_pmk2_report_modal' aria-hidden='true'>
        <div className='modal-dialog mw-550px modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='text-center mb-13'>
              <h1 className='mb-3'>
                {intl.formatMessage({id: 'REPORT.PAID_MARKETING.MODAL.GRAPH_TITLE'})}
              </h1>
            </div>

            <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
              <ElementWithIcon
                icon={'/media/icons/duotune/graphs/gra006.svg'}
                title={intl.formatMessage({id: 'BRAND_HEALTH.MODAL.GRAPH.CONVERTION.TITLE'})}
                description={intl.formatMessage({
                  id: 'BRAND_HEALTH.MODAL.GRAPH.CONVERTION.DESC',
                })}
              />
              <ElementWithIcon
                icon={'/media/icons/duotune/ecommerce/ecm011.svg'}
                title={intl.formatMessage({id: 'BRAND_HEALTH.MODAL.GRAPH.CTR.TITLE'})}
                description={intl.formatMessage({
                  id: 'BRAND_HEALTH.MODAL.GRAPH.CTR.DESC',
                })}
              />
              <ElementWithIcon
                icon={'/media/icons/duotune/ecommerce/ecm011.svg'}
                title={intl.formatMessage({id: 'BRAND_HEALTH.MODAL.GRAPH.TACOS.TITLE'})}
                description={intl.formatMessage({
                  id: 'BRAND_HEALTH.MODAL.GRAPH.TACOS.DESC',
                })}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' id='info_pmk3_report_modal' aria-hidden='true'>
        <div className='modal-dialog mw-550px modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='text-center mb-13'>
              <h1 className='mb-3'>
                {intl.formatMessage({id: 'REPORT.PAID_MARKETING.MODAL.TABLE_TITLE'})}
              </h1>
            </div>

            <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
              <ElementWithIcon
                icon={''}
                title={intl.formatMessage({id: 'REPORT.PAID_MARKETING.ID'})}
                description={intl.formatMessage({
                  id: 'REPORT.PAID_MARKETING.ID_DESC',
                })}
              />

              <ElementWithIcon
                icon={''}
                title={intl.formatMessage({id: 'REPORT.PAID_MARKETING.CAMPAIGN_NAME'})}
                description={intl.formatMessage({
                  id: 'REPORT.PAID_MARKETING.CAMPAIGN_NAME_DESC',
                })}
              />

              <ElementWithIcon
                icon={''}
                title={intl.formatMessage({id: 'REPORT.PAID_MARKETING.IMPRESSIONS'})}
                description={intl.formatMessage({
                  id: 'REPORT.PAID_MARKETING.IMPRESSIONS_DESC',
                })}
              />

              <ElementWithIcon
                icon={''}
                title={intl.formatMessage({id: 'REPORT.PAID_MARKETING.CLICKS'})}
                description={intl.formatMessage({
                  id: 'REPORT.PAID_MARKETING.CLICKS_DESC',
                })}
              />

              <ElementWithIcon
                icon={''}
                title={intl.formatMessage({id: 'REPORT.PAID_MARKETING.COST'})}
                description={intl.formatMessage({
                  id: 'REPORT.PAID_MARKETING.COST_DESC',
                })}
              />

              <ElementWithIcon
                icon={''}
                title={intl.formatMessage({id: 'REPORT.PAID_MARKETING.ROAS'})}
                description={intl.formatMessage({
                  id: 'REPORT.PAID_MARKETING.ROAS_DESC',
                })}
              />

              <ElementWithIcon
                icon={''}
                title={intl.formatMessage({id: 'REPORT.PAID_MARKETING.TACOS'})}
                description={intl.formatMessage({
                  id: 'REPORT.PAID_MARKETING.TACOS_DESC',
                })}
              />

              <ElementWithIcon
                icon={''}
                title={intl.formatMessage({id: 'REPORT.PAID_MARKETING.CPC'})}
                description={intl.formatMessage({
                  id: 'REPORT.PAID_MARKETING.CPC_DESC',
                })}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' id='info_pmk4_report_modal' aria-hidden='true'>
        <div className='modal-dialog mw-550px modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='text-center mb-13'>
              <h1 className='mb-3'>{intl.formatMessage({id: 'BRAND_HEALTH.MODAL.PRODUCT.KPI'})}</h1>
            </div>

            <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
              <ElementWithIcon
                icon={'/media/icons/duotune/coding/cod008.svg'}
                title={intl.formatMessage({id: 'REPORT.LOGISTICS.ORDER_PROMOTION'})}
                description={intl.formatMessage({id: 'REPORT.LOGISTICS.ORDER_PROMOTION_DESC'})}
              />
              <ElementWithIcon
                icon={'/media/icons/duotune/coding/cod007.svg'}
                title={intl.formatMessage({id: 'REPORT.LOGISTICS.ORDER_WO_PROMOTION'})}
                description={intl.formatMessage({id: 'REPORT.LOGISTICS.ORDER_WO_PROMOTION_DESC'})}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {BrandHealthReportModal}
