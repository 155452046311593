import React, {FC} from 'react'
import {AgText} from '../../layout/components/ag/AgText'
import {GwsBarChart} from '../newComponets/charts/GWSBarChart'
import {LabelWithGrowIndicator} from '../newComponets/Labels'

type Props = {
  arrayLabel: string[]
  arrayValues: number[]
  arrayColors?: string[]
  title?: string
  subtitle?: string
  incremental?: string
  chartYTitle?: string
  chartXTitle?: string
  legendPosition?: 'start' | 'end'
  cleanChart?: boolean
  cleanAxeX?: boolean
  legendPositionInverted?: boolean
  widthChart?: number
  heightChart?: number
  isMoney?: boolean
}
/**
 * Bar chart component with legend interaction
 * @param title - Title of the card
 * @param subtitle - Subtitle of the card
 * @param incremental - Incremental o decremental value
 *  @param arrayLabel - Labels of the X axis
 *   @param arrayValues - Values of the bars
 *   @param arrayColors - Colors(hex) of the bars
 *   @param chartYTitle - Title of the Y axis
 *   @param chartXTitle - Title of the X axis
 *   @param legendPosition - Position of the legend (start or end)
 *   @param legendPositionInverted - If true, the legend will be shown at first
 *   @param cleanChart - If true, the chart will be clean, with no grids nor axis
 *   @example:
 *   <GwsBarChart
 *    arrayLabel={['Nuevo', 'Usado']}
 *    arrayValues={[98, 74]}
 *    legendPosition={'start'}
 *    legendPositionInverted={true}
 *    />
 *
 */
const CardBarChart: FC<Props> = ({
  title,
  subtitle,
  incremental,
  chartYTitle,
  arrayLabel,
  arrayValues,
  arrayColors,
  chartXTitle,
  legendPosition,
  legendPositionInverted,
  cleanChart,
  cleanAxeX,
  heightChart,
  widthChart,
  isMoney
}) => {
  return (
    <div className='card'>
      <div className='card-body px-3 pt-5'>
        <div className='row'>
          {title && <AgText choice={'titlecard'} text={title} />}
          {subtitle && (
            <AgText choice={'percentagecard'} text={subtitle} customClass={'text-gray-500'} />
          )}
          {incremental && <LabelWithGrowIndicator text={`${incremental}`} />}
        </div>
        <div className='row mt-3'>
          <GwsBarChart
            chartYTitle={chartYTitle}
            arrayLabel={arrayLabel}
            arrayValues={arrayValues}
            arrayColors={arrayColors}
            chartXTitle={chartXTitle}
            legendPosition={legendPosition}
            legendPositionInverted={legendPositionInverted}
            cleanChart={cleanChart}
            cleanAxeX={cleanAxeX}
            widthChart={widthChart}
            heightChart={heightChart}
            isMoney={isMoney}
          />
        </div>
      </div>
    </div>
  )
}

export {CardBarChart}
