import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'

const HealthAside = () => {
  const intl = useIntl()

  return (
    <>
      {/*Health*/}
      <AsideMenuItemWithSub
        listTo={['brand-health', 'strategy']}
        icon='/media/icons/gws-icons/aside-health.svg'
        title={intl.formatMessage({id: 'MENU.HEALTH'})}
      >
        {/*brand health*/}
        <AsideMenuItem
          to='/brand-health'
          title={intl.formatMessage({id: 'MENU.BRAND_HEALTH'})}
          hasBullet={true}
        />
        {/*alertas*/}
        {/*estrategias de venta*/}
        <AsideMenuItem
          to='/strategy'
          title={intl.formatMessage({id: 'MENU.STRATEGY'})}
          hasBullet={true}
        />
        {/*playlist*/}
        <AsideMenuItem
          to='/playlist'
          title={intl.formatMessage({id: 'MENU.PLAYLIST'})}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
    </>
  )
}

export {HealthAside}
