export const template = `
## Titulo del output

Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi

[MD92IP](productid). 
Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi 
ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit

ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit 

Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi

sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
ut labore et dolore magna aliqua. 

👉 Haz clic aquí [MD92IP](producthealth) elit, sed do eiusmod
sed do eiusmod tempor incididunt sed do eiusmod`
