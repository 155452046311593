/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {UserTable} from './UsersTable'
import {customFilter} from 'react-bootstrap-table2-filter'
import {useIntl} from 'react-intl'
import {UserActionsCell} from './UserActionCell'
import {CustomSelectFilter} from '../../../../../../_gws/partials/tables/CustomSelectFilter'
import {CustomTotal} from '../../../../../../_gws/partials/tables/CustomTotal'
import {headFormatter, optionsTable} from '../../../../../../_gws/partials/tables/TableHelper'
import {AvatarUser} from '../../../../../../_gws/partials/widgets/misc/AvatarUser'
import {MarketplaceConexionTable} from "../../../../../../_gws/partials/widgets/misc/MarketplaceConexionTable";

type Props = {
  listUser: any
  listRoles: any
  saasPlans: any
  roles: any
}
const TableContainer: FC<Props> = ({listUser, listRoles, saasPlans, roles}) => {
  const intl = useIntl()
  /**
   * Reducers for custom filters
   */
  const selectOptionsRoles = roles.reduce((rol: any, val: any) => {
    rol[val.value] = val.label
    return rol
  }, {})

  const selectOptionsPlans = saasPlans.reduce((plan: any, val: any) => {
    plan[val.value] = val.label
    return plan
  }, {})

  // const selectMarketplace = marketplaceMap.reduce((mkp: any, val: any) => {
  //   mkp[val.value] = val.label
  //   return mkp
  // }, {})

  const formatterActions = (cellContent: any, row: any) => <UserActionsCell row={row} />

  /**
   * Needed to format as label the roles column
   * @param cell
   * @param row
   * @param index
   * @param extraData
   */
  const formatterSelectRoles = (cell: any, row: any, index: any, extraData: any) => {
    let roles: any = {value: 0, label: 'none'}
    if (extraData.length > 0) {
      roles = extraData.find((rol: any) => rol.value === row.roleId)
    }
    return <span className={`badge badge-secondary fw-regular px-3 py-3`}>{roles.label}</span>
  }

  /**
   * Needed to format as label the plans column
   * @param cell
   * @param row
   * @param index
   * @param extraData
   */
  const formatterSelectPlans = (cell: any, row: any, index: any, extraData: any) => {
    let plans: any = {value: 0, label: 'none'}
    if (extraData.length > 0) {
      plans = extraData.find((plan: any) => plan.value === row.saasPlan)
    }
    return <span className={`badge badge-secondary fw-regular px-3 py-3`}>{plans.label}</span>
  }

  const formatterUserMarketplaces = (cell: any, row: any) => {
    return (
      cell.length > 0 ? (
        <MarketplaceConexionTable marketplaces={cell} admin={true} tooltip={'true'} />
      ) : (<span className={``}> {' '}</span>  )
    )
  }
  const customTotal = (from: number, to: number, size: number) => (
    <CustomTotal from={from} to={to} size={size} />
  )

  /**
   * Bootstrap Table columns configuration
   */
  const columns = [
    {
      dataField: 'picture',
      text: '',
      searchable: false,
      style: {width: '1%'},
      headerFormatter: headFormatter,
      formatter: (cellContent: any, row: any) => {
        return (
          <AvatarUser
            userID={row.id}
            picUrl={row.picture}
            name={row.name}
            username={row.username}
            lastname={row.lastname}
            isVerified={row.verified}
          />
        )
      },
    },
    {
      dataField: 'id',
      text: intl.formatMessage({id: 'USER_MNGT.TABLE.HEAD.ID'}),
      sort: true,
      searchable: false,
      style: {width: '1%'},
      headerFormatter: headFormatter,
    },
    {
      dataField: 'email',
      text: intl.formatMessage({id: 'USER_MNGT.TABLE.HEAD.EMAIL'}),
      sort: true,
      headerFormatter: headFormatter,
    },

    {
      dataField: 'name',
      text: intl.formatMessage({id: 'USER_MNGT.TABLE.HEAD.NAME'}),
      sort: true,
      headerFormatter: headFormatter,
    },
    {
      dataField: 'lastname',
      text: intl.formatMessage({id: 'USER_MNGT.TABLE.HEAD.LASTNAME'}),
      sort: true,
      headerFormatter: headFormatter,
    },
    {
      dataField: 'roleId',
      text: '',
      searchable: false,
      headerFormatter: headFormatter,
      filter: customFilter({}),
      filterRenderer: (onFilter: any) => (
        <CustomSelectFilter
          title={intl.formatMessage({id: 'USER_MNGT.TABLE.HEAD.ROLE'})}
          options={selectOptionsRoles}
          onFilter={onFilter}
        ></CustomSelectFilter>
      ),
      formatter: formatterSelectRoles,
      formatExtraData: roles,
    },
    {
      dataField: 'saasPlan',
      text: '',
      searchable: false,
      headerFormatter: headFormatter,
      filter: customFilter({}),
      filterRenderer: (onFilter: any) => (
        <CustomSelectFilter
          title={intl.formatMessage({id: 'USER_MNGT.TABLE.HEAD.SAASPLAN'})}
          options={selectOptionsPlans}
          onFilter={onFilter}
        ></CustomSelectFilter>
      ),
      formatter: formatterSelectPlans,
      formatExtraData: saasPlans,
    },{
      dataField: 'userMarketplaces',
      text: 'Marketplaces',
      sort: true,
      searchable: false,
      formatter: formatterUserMarketplaces,
      headerFormatter: headFormatter,
    },
    {
      dataField: 'Action user',
      isDummyField: true,
      headerFormatter: headFormatter,
      text: intl.formatMessage({id: 'USER_MNGT.ACTION'}),
      formatter: formatterActions,
    },
  ]

  return (
    <UserTable
      columns={columns}
      listUser={listUser}
      optionsTable={optionsTable(customTotal, intl.locale, 3)}
    />
  )
}

export {TableContainer}
