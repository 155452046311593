type Props = {
  customClass?: string
  text: string
}
/**
 * Standard prototype of Aside Text "Subcategory" | renders a div element with a specific font size and line height, and can have a custom class and text provided as properties.
 * @param customClass - to add extra classes
 * @param text - the text content
 * @constructor
 */

const AgSubCategory: React.FC<Props> = ({customClass, text}) => {
  /**
   * static css for the content
   */
  const css = {
    fontSize: '0.85em',
  }

  return (
    <div className={`ff-prompt fw-normal ${customClass}`} style={css}>
      {text}
    </div>
  )
}

export {AgSubCategory}
