/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {useIntl} from 'react-intl'
// import {DarkLightSelector} from './DarkLightSelector'
import {ClientWrapper} from '../../../layout/components/header/merchant/ClientWrapper'
import {useThemeMode} from '../theme-mode/ThemeModeProvider'

const HeaderUserTopMenu: FC = () => {
  const {currentUser, currentClient} = useAuth()
  const intl = useIntl()
  // Nos aseguramos que el tema sea light
  const {updateMode, updateMenuMode} = useThemeMode()
  updateMenuMode('light')
  updateMode('light')

  const isMerchant = currentUser?.roleId === 3

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary-dark fw-bold py-2 fs-7 w-275px'
      data-kt-menu='true'
      data-popper-placement='bottom-start'
    >
      {isMerchant && currentClient === undefined && (
        <>
          <div className='menu-item px-3'>
            <ClientWrapper />
          </div>
          <div className='separator my-2 opacity-25'></div>
        </>
      )}
      {currentClient !== undefined && (
        <>
          <div className='menu-item px-3'>
            <ClientWrapper />
          </div>
          <div className='separator my-2 opacity-25'></div>
        </>
      )}

      <div className='menu-item px-5'>
        <Link to={'/account/overview'} className='menu-link px-5'>
          {intl.formatMessage({id: 'HUMENU.PROFILE'})}
        </Link>
      </div>

      <div className='separator my-2 opacity-25'></div>
      <Languages />
      {/* <DarkLightSelector /> */}
      <div className='separator my-2 opacity-25'></div>
      <div className='menu-item px-5'>
        {/*<a onClick={logout} className='menu-link px-5'>*/}
        {/*  {intl.formatMessage({id: 'HUMENU.LOGOUT'})}*/}
        {/*</a>*/}
        <Link to='/logout' className='menu-link px-5'>
          {intl.formatMessage({id: 'HUMENU.LOGOUT'})}
        </Link>
      </div>
    </div>
  )
}

export {HeaderUserTopMenu}
