import axios from 'axios'
import {getTokenHeader} from '../../../modules/auth'
import {MarketplaceType} from './types'

const API_URL_CRUNCH = process.env.REACT_APP_BACKEND_MAIN_API

export const REQUEST_SALES_URL = `${API_URL_CRUNCH}/api/reports/sales/`
export const REQUEST_ID_URL_SHIPPING = `${API_URL_CRUNCH}/api/reports/shipments-management/`
export const REQUEST_ID_URL_ORDERS = `${API_URL_CRUNCH}/api/reports/orders-results/`

export function getSales(
  start: string,
  end: string,
  mktplace: MarketplaceType,
  periodType: number
) {
  const axiosClean = axios.create()

  return axiosClean.post(
    REQUEST_SALES_URL + mktplace,
    {
      periodType: periodType,
    },
    // @ts-ignore
    getTokenHeader()
  )
}

export function getShippingManagement(start: string, end: string, mktplace: MarketplaceType) {
  const axiosClean = axios.create()
  return axiosClean.post(
    REQUEST_ID_URL_SHIPPING + mktplace,
    {
      start,
      end,
    },
    // @ts-ignore
    getTokenHeader()
  )
}

export function getOrders(start: string, end: string, mktplace: MarketplaceType, periodType: number) {
  const axiosClean = axios.create()
  return axiosClean.post(
    REQUEST_ID_URL_ORDERS + mktplace,
    {
      periodType: periodType,
    },
    // @ts-ignore
    getTokenHeader()
  )
}
