import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG, toAbsoluteUrl} from '../../../../_gws/helpers'
import {ProductGauge} from './ProductGauge'

type PropsProductDetails = {
  dataReport?: any
  query?: any
}

/**
 * Component for show text details of products
 * @param dataReport {object} - API object requested
 * @param query {any} - search term (ASIN or ID)
 * @constructor
 */
const ProductDetails: React.FC<PropsProductDetails> = ({dataReport, query}) => {
  const intl = useIntl()
  const [queryResult] = useState(query)
  return (
    <div className='col-md-12 '>
      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              ASIN/ID&nbsp;
              <a href={'/#'} data-bs-toggle='modal' data-bs-target='#info_pt1_report_modal'>
                <KTSVG
                  path={toAbsoluteUrl('/media/icons/duotune/general/gen046.svg')}
                  className='svg-icon-2x svg-icon-dark'
                />
              </a>
            </span>
            <span className='text-muted fw-semibold fs-7'>{queryResult}</span>
          </h3>
        </div>
        <div className='card-body'>
          <div className='row'>
            <div className='col-12'>
              <span className='mb-1 fw-semibold fs-5 w-100 d-block'>
                {intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.NAME'})}
              </span>
              <span className='text-gray-800 fw-regular text-dark'>{dataReport.product.name}</span>
              <div className='separator separator-dashed my-5'></div>
            </div>

            <div className='col-12'>
              <span className='mb-1 fw-semibold fs-5 w-100 d-block'>
                {intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.DESCRIPTION'})}
              </span>
              <span className='text-gray-800 fw-regular text-dark'>
                {dataReport.product.description}
              </span>
              <div className='separator separator-dashed my-5'></div>
            </div>

            {dataReport.product.category !== '-' && (
              <div className='col-12  col-lg-6 p-2'>
                <div className='bg-light bg-opacity-50  rounded-1 p-4 h-100'>
                  <span className='mb-1 fw-semibold fs-5 w-100 d-block'>
                    {intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.CATEGORY'})}
                  </span>
                  <span className='text-gray-800 fw-regular text-dark'>
                    {dataReport.product.category}
                  </span>
                </div>
              </div>
            )}
            {dataReport.product.brand !== '-' && (
              <div className='col-12 col-lg-6 p-2'>
                <div className='bg-light bg-opacity-50  rounded-1 p-4 h-100'>
                  <span className='mb-1 fw-semibold fs-5 w-100 d-block'>
                    {intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.BRAND'})}
                  </span>
                  <span className='text-gray-800 fw-regular text-dark'>
                    {dataReport.product.brand}
                  </span>
                </div>
              </div>
            )}
            {dataReport.product.color !== '-' && (
              <div className='col-12 col-lg-6 p-2'>
                <div className='bg-light bg-opacity-50  rounded-1 p-4 h-100'>
                  <span className='mb-1 fw-semibold fs-5 w-100 d-block'>
                    {intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.COLOR'})}
                  </span>
                  <span className='text-gray-800 fw-regular text-dark'>
                    {dataReport.product.color}
                  </span>
                </div>
              </div>
            )}
            {dataReport.product.size !== '-' && (
              <div className='col-12 col-lg-6 p-2'>
                <div className='bg-light bg-opacity-50 rounded-1 p-4 h-100'>
                  <span className='mb-1 fw-semibold fs-5 w-100 d-block'>
                    {intl.formatMessage({id: 'REPORT.PRODUCT_TRACKER.SIZE'})}
                  </span>
                  <span className='text-gray-800 fw-regular text-dark'>
                    {dataReport.product.size}
                  </span>
                </div>
              </div>
            )}
            <div className='col-12'>
              <div className='separator separator-dashed my-5'></div>
              <ProductGauge dataReport={dataReport} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export {ProductDetails}
