import {useEffect} from 'react'
import {useAuth} from './core/Auth'
import {Splash} from '../../../_gws/helpers/misc'
import { MemberstackFunctions } from '../../../solidInterfaces/membestack'

/**
 * System Logout
 * Link is needed to fire the logout.
 * Empty return, refresh to reset the user flow
 * @component
 */
export function Logout() {
  const {logout} = useAuth()
  useEffect(() => {
    Splash(true)
    const membestackLogout = async () => {
      await MemberstackFunctions.logout()
    }
    membestackLogout()
    logout()
    // @ts-ignore
    window.location = '/'
    window.Intercom('shutdown')
  }, [logout])

  return <></>
}
