import axios from 'axios'
import {getTokenHeader, mkProp} from '../../../modules/auth'
import {MARKETPLACES} from '../../../constants/marketplaces'
import {inventoryDataResponseI} from './_models'
import {Response} from '../../../../_gws/helpers/crud-helper/models'

const API_URL_CRUNCH = process.env.REACT_APP_BACKEND_MAIN_API

export const REQUEST_ID_URL = `${API_URL_CRUNCH}/api/reports/inventory`

export function getInventoryData(
  mk: mkProp = 'growker',
  limitProducts = 0,
): Promise<Response<inventoryDataResponseI>> {
  const axiosClean = axios.create()
  const marketplaceId = Object.values(MARKETPLACES).find((mkp) => mkp.name === mk)?.id
  return axiosClean.get(
    `${REQUEST_ID_URL}?marketplaceId=${marketplaceId}&limitProducts=${limitProducts}`,
    // @ts-ignore
    {...getTokenHeader()}
  )
}

export const GET_LANDED_COST = `${API_URL_CRUNCH}/api/playlist/landed-cost/get`
export function getLandedCost(id: any) {
  const axiosClean = axios.create()
  const data = {
    filter: id.toString(),
  }
  return axiosClean.post(
    GET_LANDED_COST,
    data,
    // @ts-ignore
    getTokenHeader()
  )
}
export const SUBMIT_LANDED_COST = `${API_URL_CRUNCH}/api/playlist/landed-cost/submit`
export function submitLandedCost(values: any) {
  const axiosClean = axios.create()

  const data = {
    asin: values.asin,
    marketplaceId: values.marketplace,
    cost: values.cost,
  }

  return axiosClean.post(
    SUBMIT_LANDED_COST,
    data,
    // @ts-ignore
    getTokenHeader()
  )
}
export const DELETE_LANDED_COST = `${API_URL_CRUNCH}/api/playlist/landed-cost/delete/`
export function deleteLandedCost(itemId: any) {
  const axiosClean = axios.create()
  return axiosClean.delete(
    DELETE_LANDED_COST + itemId,
    // @ts-ignore
    getTokenHeader()
  )
}
