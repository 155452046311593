import axios, {AxiosResponse} from 'axios'
import {getTokenHeader} from '../../../modules/auth'
import {Playlist, Response} from './_models'


/**
 * API ENV Parameter
 */
const API_URL_CRUNCH = process.env.REACT_APP_BACKEND_MAIN_API

/**
 * URL build for api request
 */
export const OWN_PLAYLIST = `${API_URL_CRUNCH}/api/playlist/`

/**
 * This function returns the playlist for the client token
 */
export function getOwnPlaylist() {
  const axiosClean = axios.create()
  return axiosClean.get(
    OWN_PLAYLIST,
    // @ts-ignore
    getTokenHeader()
  )
}

export const OWN_PLAYLIST_PAGINATED = `${API_URL_CRUNCH}/api/playlist/own/paginated`

/**
 * This function returns the playlist for the client token
 * @param params
 */
export function getOwnPlaylistPaginated(params: any) {
  const data = params
  const axiosClean = axios.create()
  return axiosClean.post(
    OWN_PLAYLIST_PAGINATED,
    data,
    // @ts-ignore
    getTokenHeader()
  )
}

const GET_ITEM = `${API_URL_CRUNCH}/api/playlist/detail`

/**
 * This function returns the playlist for the client token
 * @param id
 */
export function getItemById(id: any) {
  return axios.get(`${GET_ITEM}/${id}`).then((response) => {
    const {data} = response
    return data.detail
  })
}

export const PLAYLISTS_TYPES = `${API_URL_CRUNCH}/api/playlist/items-type`

/**
 * This function returns the types catalog for the playlist
 */
export function getTypes() {
  return axios.get(PLAYLISTS_TYPES)
}

export const PLAYLISTS = `${API_URL_CRUNCH}/api/playlist`

/**
 * This function creates a new playlist
 * @param playlist
 */
export function createPlayList(playlist: Playlist) {
  const axiosClean = axios.create()
  const data = [{
    description: playlist.description,
    item: playlist.itemId,
    marketplaceId: playlist.marketplaceId,
    priority: playlist.priority,
    typeId: playlist.typeId,
  }]
  return axiosClean
    .post(
      `${PLAYLISTS}`,
      data,
      // @ts-ignore
      getTokenHeader()
    )
    .then((response: AxiosResponse<Response<Playlist>>) => response.data)
    .then((response: Response<Playlist>) => response.data)
}

/**
 * This function updates a playlist
 * @param playlist
 */
export function updatePlayList(playlist: Playlist) {
  const axiosClean = axios.create()
  let data = [{
    description: playlist.description,
    item: playlist.itemId,
    marketplaceId: playlist.marketplaceId,
    resolved: playlist.resolved,
    typeId: playlist.typeId,
  }]
  return axiosClean
    .post(
      `${PLAYLISTS}`,
      data,
      // @ts-ignore
      getTokenHeader()
    )
    .then((response: AxiosResponse<Response<Playlist>>) => response.data)
    .then((response: Response<Playlist>) => response.data)
}

export async function resolvePlayList(dataRaw: any) {

  const axiosClean = axios.create()
  let data = [{
    resolved: true,
    priority: dataRaw.priority,
    id: dataRaw.id
  }]

  return await axiosClean.put(
    `${PLAYLISTS}`,
    data,
    // @ts-ignore
    getTokenHeader()
  )
}

export async function resolveAllPlayList(dataRaw: any) {
  
  const axiosClean = axios.create()

   let data = dataRaw.map((item: any) => {
    return {
      resolved: true,
      priority: item.priority,
      id: item.id
    }
  })

 return await axiosClean.put(
    `${PLAYLISTS}`,
    data,
    // @ts-ignore
    getTokenHeader()
  )

 
}

/**
 * This function deletes a playlist
 * @param userId
 */
export function deletePlaylist(itemId: any) {
  const axiosClean = axios.create()
  return axiosClean.delete(
    `${PLAYLISTS}`,
    // @ts-ignore
    getTokenHeader()
  )
}

export const GENERAL = `${API_URL_CRUNCH}/api/general/marketplaces/selector`

/**
 * This function returns the marketplaces catalog
 */
export function getMarketplaces() {
  return axios.get(GENERAL)
}



// ----------- new req ------------- //
export async function createPlayListTask(tasks: any) {
  const axiosClean = axios.create()
  return axiosClean
    .post(
      `${PLAYLISTS}`,
      tasks,
      // @ts-ignore
      getTokenHeader()
    )
    .then((res) => res)
}

export async function updatePlayListTask(tasks: any) {
  const axiosClean = axios.create()
  return axiosClean
    .put(
      `${PLAYLISTS}`,
      tasks,
      // @ts-ignore
      getTokenHeader()
    )
    .then((res) => res)
}

export async function resolvedPlayList(ids: number[]) {
  const axiosClean = axios.create()
  return axiosClean
    .put(
      `${PLAYLISTS}/resolve`,
      {
        ids: ids,
      },
      // @ts-ignore
      getTokenHeader()
    )
    .then((res) => res)
}
// ----------- new req ------------- //
