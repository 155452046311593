/* eslint-disable jsx-a11y/anchor-is-valid */
import {Route, Routes} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_gws/helpers'
import {PaymentError} from './components/PaymentError'
import {Container, ContainerImg, ContentImg, Wrapper} from './PaymentPage'
import {useIntl} from 'react-intl'
/**
 * Auth Layout for the login form and the other elements outside the form
 * @component
 */

const PaymentErrorLayout = () => {
  const intl = useIntl()
  return (
    <Container>
      <Wrapper>
        <div
          style={{
            display: 'grid',
            placeItems: 'center',
          }}
        >
          <PaymentError />
        </div>
        <ContainerImg>
          <ContentImg>
            <img alt='Atro' src={toAbsoluteUrl('/media/backgrounds/Artboard-payment-error.png')} />
            <h4>{intl.formatMessage({id: 'LOGIN.PAYMENT.ERROR.TITLE'})}</h4>
            <p>{intl.formatMessage({id: 'LOGIN.PAYMENT.ERROR.TEXT'})}</p>
            <img
              style={{width: '200px', marginTop: '10px'}}
              alt='Logo'
              src={toAbsoluteUrl('/media/backgrounds/early-access.png')}
            />
          </ContentImg>
        </ContainerImg>
      </Wrapper>
    </Container>
  )
}

const PaymentErrorPage = () => (
  <Routes>
    <Route element={<PaymentErrorLayout />}>
      <Route index element={<PaymentError />} />
    </Route>
  </Routes>
)

export {PaymentErrorPage}
