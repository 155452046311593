import {useState} from 'react'
import {Button, Spinner} from 'react-bootstrap'
import {Task} from '../../types'
import {resolvedPlayList} from '../../core/_requests'
import {Alert} from '../PlaylistCreateEdit/styled'

type ResolvedState = {
  isLoading: boolean
  error: boolean
  msg: string
}

const dispatchClearMessage = (setIsLoading: any) => {
  setTimeout(() => {
    setIsLoading((pre: any) => ({
      ...pre,
      msg: '',
    }))
  }, 2000)
}

export const PlaylistResolveButton = ({
  selectedItems,
  updateState,
}: {
  selectedItems: Task[]
  updateState: any
}) => {
  const [resolved, setResolved] = useState<ResolvedState>({
    isLoading: false,
    error: false,
    msg: '',
  })

  const handlerResolved = async () => {
    setResolved((pre) => ({...pre, isLoading: true}))
    const ids = selectedItems.map((task: Task) => task.id)
    const response = await resolvedPlayList(ids)
    if (response.status === 200) {
      updateState(response.data)
      setResolved((pre) => ({...pre, isLoading: false, msg: 'Tareas resueltas correctamente'}))
      dispatchClearMessage(setResolved)
    } else {
      setResolved((pre) => ({
        ...pre,
        isLoading: false,
        error: true,
        msg: 'Ah ocurrido un error intente mas tarde',
      }))
      dispatchClearMessage(setResolved)
    }
  }
  return (
    <>
      {resolved.msg && (
        <Alert style={{margin: '10px 0px', width: '50%'}} error={resolved.error}>
          {resolved.msg}
        </Alert>
      )}
      {selectedItems.length > 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button disabled={resolved.isLoading} onClick={handlerResolved} size='sm'>
            {resolved.isLoading ? (
              <Spinner size='sm' animation='border' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </Spinner>
            ) : (
              <>{selectedItems.length <= 1 ? 'Resolver' : 'Resolver todo'}</>
            )}
          </Button>
        </div>
      )}
    </>
  )
}
