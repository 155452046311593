import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from '../../AsideMenuItemWithSub'
import {AsideMenuItem} from '../../AsideMenuItem'

const MarketingAside = () => {
  const intl = useIntl()
  return (
    <>
      <AsideMenuItemWithSub
        listTo={['paid-marketing', 'active-campaigns']}
        icon='/media/icons/gws-icons/aside-marketing.svg'
        title={intl.formatMessage({id: 'MENU.REPORTS.MARKETING'})}
      >
        {/*marketing pagado*/}
        <AsideMenuItem
          to='/paid-marketing'
          title={intl.formatMessage({id: 'MENU.REPORTS.PAID_MARKETING'})}
          hasBullet={true}
        />
        {/*campañas activas*/}
        <AsideMenuItem
          to='/active-campaigns'
          title={intl.formatMessage({id: 'MENU.REPORTS.ACTIVE_CAMPAIGNS'})}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
    </>
  )
}

export {MarketingAside}
