import {AuthModel} from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'user_auth'
const AUTH_CLIENT_LOCAL_STORAGE_KEY = 'client_auth'

/**
 * It gets the auth object from local storage, if it exists
 * @returns validation token
 */
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

/**
 * It takes an AuthModel object, converts it to a string, and saves it to local
 * storage
 * @param {AuthModel} auth - AuthModel - this is the object that we want to save to
 * local storage.
 * @returns the value of the localStorage.getItem() method.
 */
const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    const token = JSON.stringify({"state":{"token":auth.token},"version":0})
    localStorage.setItem('verified', `${auth.verified}`)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
    localStorage.setItem('token', token)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

/**
 * It removes the auth token from local storage
 */
const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: any}) => {
      const auth = getAuth()
      if (auth && auth.token) {
        config.headers = {'crunch-dna-token': auth.token}
      }
      return config
    },
    (err: any) => Promise.reject(err)
  )
}

export function getTokenHeader() {
  const auth = getAuth()
  const clientAuth = getClientAuth()
  if (clientAuth === undefined) {
    return {headers: {'crunch-dna-token': auth?.token}}
  } else {
    return {headers: {'crunch-dna-token': clientAuth?.token}}
  }
}

/**
 * It gets the auth object from local storage, if it exists
 * @returns the user data in an object
 */
const getClientAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_CLIENT_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const clientAuth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (clientAuth) {
      // You can easily check auth_token expiration also
      return clientAuth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

/**
 * It takes an AuthModel object, converts it to a string, and saves it to local
 * storage
 * @param {AuthModel} auth - AuthModel - this is the auth object that we're going
 * to save to local storage.
 * @returns the value of the localStorage.getItem() method.
 */
const setClientAuth = (clientAuth: AuthModel) => {
  if (!localStorage) {
    return
  }
  try {
    const lsValue = JSON.stringify(clientAuth)
    localStorage.setItem(AUTH_CLIENT_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

/**
 * It removes the client auth from local storage
 * @returns the value of the localStorage.getItem() method.
 */
const removeClientAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_CLIENT_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export {
  AUTH_CLIENT_LOCAL_STORAGE_KEY,
  AUTH_LOCAL_STORAGE_KEY,
  getAuth,
  getClientAuth,
  removeAuth,
  removeClientAuth,
  setAuth,
  setClientAuth,
}
