import React from 'react'
import {useIntl} from 'react-intl'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {PageTitle} from '../../../../_gws/layout/core'
import {slugify, Splash} from '../../../../_gws/helpers/misc'
import axios from 'axios'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

const API_URL_CRUNCH = process.env.REACT_APP_BACKEND_MAIN_API

const ConfirmMeli = () => {
  const intl = useIntl()
  const alert = withReactContent(Swal)
  const navigate = useNavigate()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const code = searchParams.get('code')

  Splash(true)
  const fetchConfirm = async () => {
    try {
      const response = await axios.get(`${API_URL_CRUNCH}/api/meli/login?code=${code}`, {
        headers: {},
      })

      Splash(false)
      if (response.data.status === 200) {
        alert
          .fire({
            text: intl.formatMessage({
              id: 'MARKETPLACE.MELI.ALERT.YAY',
            }),
            icon: 'success',
            iconColor: '#00c6c7',
            buttonsStyling: false,
            confirmButtonText: intl.formatMessage({
              id: 'MENU.MARKETPLACES',
            }),
            customClass: {
              confirmButton: 'btn btn-primary',
              popup: 'bg-light',
            },
          })
          .then(() => {
            navigate('/marketplaces')
          })
      } else {
        const sAlertMessage = intl.formatMessage({
          id: 'GENERAL.ALERT.500',
        })
        alert
          .fire({
            text: sAlertMessage,
            icon: 'warning',
            iconColor: '#00c6c7',
            buttonsStyling: false,
            confirmButtonText: `${intl.formatMessage({
              id: 'GENERAL.ALERT.BACK',
            })} ${intl.formatMessage({
              id: 'MENU.MARKETPLACES',
            })}`,
            customClass: {
              confirmButton: 'btn btn-primary',
              popup: 'bg-light',
            },
          })
          .then(() => {
            navigate('/marketplaces')
          })
      }
    } catch (error: any) {
      Splash(false)

      let errorText = ''
      if (error.response.status === 500) {
        errorText = intl.formatMessage({id: 'ERROR.BACKEND.FETCH.TRY_AGAIN'})
      } else {
        if (error.response.data.description) {
          errorText = intl.formatMessage({id: slugify(error.response.data.description)})
        } else {
          errorText = intl.formatMessage({id: 'ERROR.BACKEND.FETCH.TRY_AGAIN'})
        }
      }

      alert
        .fire({
          title: intl.formatMessage({id: 'ERROR.BACKEND.FETCH'}),
          text: errorText,
          icon: 'error',
          iconColor: '#00c6c7',
          buttonsStyling: false,
          confirmButtonText: intl.formatMessage({id: 'ACCOUNT.PROFILE.DETAILS.OK_GOT_IT'}),
          customClass: {
            confirmButton: 'btn btn-primary',
            popup: 'bg-light',
          },
        })
        .then(() => {
          navigate('/marketplaces')
        })
      console.error(error)
    }
  }
  fetchConfirm()
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: intl.formatMessage({id: 'MENU.HOME'}),
            path: '/dashboard',
            isSeparator: false,
            isActive: false,
          },
          {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
          },
        ]}
      >
        {intl.formatMessage({id: 'PAGE.MELI.CONFIRMATION'})}
      </PageTitle>
    </>
  )
}

export {ConfirmMeli}
