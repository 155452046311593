import {DiagnostikTabPrincipalContent} from './components/TabContent/TabContent'
import { DiagnosticReport } from './core/types'

export const Diagnostik = ({ report }: { report: DiagnosticReport }) => {
  return (
    <div>
      <DiagnostikTabPrincipalContent report={report} />
    </div>
  )
}
