import {FC} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_gws/helpers'
import {useIntl} from 'react-intl'

const Error404: FC = () => {
  const intl = useIntl()
  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid p-10'>
        <img
          src={toAbsoluteUrl('/media/logos/growkers_404.png')}
          alt=''
          className='mw-100 mb-10 h-lg-450px'
        />
        <h1 className='fw-bold mb-10'>{intl.formatMessage({id: '404.NOTHING_HERE'})}</h1>
        <Link to='/' className='btn btn-primary'>
          {intl.formatMessage({id: '404.RETURN_HOME'})}
        </Link>
      </div>
    </div>
  )
}

export {Error404}
