import React from 'react'
import { TextProps } from '../AgText'
import { FontSizes } from '../../../../../app/constants/sizes'


/**
 * Standard prototype of Text "AgBigNumberCard" | renders a div element with a specific font size and line height, and can have a custom class and text provided as properties.
 * @param customClass - to add extra classes
 * @param text - the text content
 * @constructor
 */
const AgMonthlyNumber: React.FC<TextProps> = ({ customClass, text, children }) => {
  /**
   * static css for the component
   */
  const css = {
    fontSize: FontSizes.SMALL.SIZE_2, //'1.05em',
    fontWeight: '700',
    lineHeight: 'normal',
  }

  return (
    <div className={`ff-prompt ${customClass}`} style={css}>
      {text}
      {children}
    </div>
  )
}

export { AgMonthlyNumber }
