import {FC, useEffect, useRef} from 'react'
import {useIntl} from 'react-intl'
import WorkingData from '../../../../_gws/assets/lottie/WorkingData.json'
import {Splash} from '../../../../_gws/helpers/misc'
import Lottie from 'lottie-react'
const WaitingScreen: FC = () => {
  const intl = useIntl()
  const video = useRef<HTMLVideoElement>(null)
  const playVideo = () => {
    video.current && video.current.play()
  }

  useEffect(() => {
    playVideo()
    Splash(false)
  }, [])

  return (
    <>
      <div className='d-flex flex-column flex-center'>
        <div className='d-flex flex-column flex-center flex-column-fluid p-10'>
          <h1 className='fw-bold mb-10'>{intl.formatMessage({id: 'DOWNLOAD.TEXT_WORKING'})}</h1>
          <div className='mw-100 h-lg-450px'>
            <Lottie animationData={WorkingData} style={{height: '450px'}} />
          </div>
          <p>{intl.formatMessage({id: 'DOWNLOAD.TEXT'})}</p>
        </div>
      </div>
    </>
  )
}

export {WaitingScreen}
