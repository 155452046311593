import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_gws/layout/core'
import React, {useEffect, useState} from 'react'
import {FrankieHealth} from './FrankieHealth'
import {HeaderReport} from '../../modules/apps/header/HeaderReport'
import {getFrankieData} from './core/_requests'
import {AlertFetchError, Splash} from '../../../_gws/helpers/misc'
import {useAuth} from '../../modules/auth/core/Auth'

const FrankieWrapper = () => {
  const intl = useIntl()

  const [showReports, setShowReports] = useState(false)
  const {hasAmazon, hasMeli, setCurrentMkp} = useAuth()
  const [mkplace, setMkplace] = useState('')
  const [mkpData, setMkpData] = useState({})
  const [isFirstTime, setIsFirstTime] = useState(true)
  const changeMarketPlace = async (mk: string) => {
    try {
      Splash(true)
      setShowReports(false)
      let response
      if (mk === 'meli') {
        response = await getFrankieData(2)
        if (response.status === 200) {
          setMkpData(response.data.detail.meli)
          setShowReports(true)
        }
      }
      if (mk === 'amazon') {
        response = await getFrankieData(1)
        if (response.status === 200) {
          setMkpData(response.data.detail.amazon)
          setShowReports(true)
        }
      }
      if (mk === '') return
    } catch (error: any) {
      Splash(false)
      AlertFetchError(intl.locale, error).fire()
      setShowReports(false)
    } finally {
      Splash(false)
    }
  }

  useEffect(() => {
    if (isFirstTime) {
      if (hasAmazon) {
        changeMarketPlace('amazon')
        setCurrentMkp('amazon')
      } else if (hasMeli) {
        changeMarketPlace('meli')
        setCurrentMkp('meli')
      }
      setIsFirstTime(false)
    } else {
      changeMarketPlace(mkplace)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mkplace])

  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: intl.formatMessage({id: 'MENU.HOME'}),
            path: '/lite',
            isSeparator: false,
            isActive: false,
          },
          {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.FRANKIE'})}
      </PageTitle>
      <HeaderReport
        hasMarketPlace={true}
        hasDate={false}
        hasFilter={false}
        setMkplace={setMkplace}
      />
      {showReports && <FrankieHealth dataReport={mkpData} mkp={mkplace} />}
    </>
  )
}

export {FrankieWrapper}
