import styled from '@emotion/styled'
import ReactApexChart from 'react-apexcharts'
import {useIntl} from 'react-intl'
import {serieChart} from '../../core/types'

const Container = styled('div')({})

const generateChartsOptions = ({xaxis, series}: {xaxis: string[]; series: any[]}) => {
  return {
    series: series,
    options: {
      chart: {
        type: 'area',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: '40%',
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: xaxis,
        tooltip: {
          enabled: false,
          offsetX: 0,
        },
      },
      yaxis: {},
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {},
      },
    },
  }
}
export const ContentInventory = ({
  report,
}: {
  report: {
    series: serieChart[]
    xaxis: string[]
  }
}) => {
  const intl = useIntl()
  report.series[0].name = intl.formatMessage({id: 'REPORT.FRANKIE.INVENTORY.PRODUCT.UNIQUE'})
  report.series[1].name = intl.formatMessage({id: 'REPORT.FRANKIE.INVENTORY.PRODUCT.UNIQUE'})
  const inventoryOptions: any = generateChartsOptions({
    xaxis: report.xaxis,
    series: report.series,
  })
  return (
    <Container>
      <div>
        <h4
          style={{
            color: '#212121',
          }}
        >
          {intl.formatMessage({id: 'REPORT.STATE_RESULT.QTY_PRODUCTS'})}
        </h4>
        <p>{intl.formatMessage({id: 'REPORT.SALES.MONTHLY_DETAILS'})}</p>
      </div>
      <ReactApexChart
        options={inventoryOptions.options}
        series={inventoryOptions.series}
        type='area'
        height={340}
      />
    </Container>
  )
}
