import styled from '@emotion/styled'
import {IconAmazon, IconMeli} from '../../playlist/components/PlayListIcons'
import {useIntl} from 'react-intl'
import {brandHealthStateList} from '../../../../_gws/helpers/miscConst'
import {useNavigate} from 'react-router-dom'
import { Divider } from './styled'

type PropsIcon = {
  bg?: any
}

const Container = styled('div')({
  overflow: 'auto',
  height: '500px',
})

const WrapperIcon = styled('div')<PropsIcon>(({bg}) => ({
  display: 'grid',
  placeItems: 'center',
  padding: '10px',
  borderRadius: '10px',
  background: bg ? bg : 'transparent',
  marginRight: '20px',
}))

const Title = styled('div')({
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
  p: {
    margin: '0px',
    color: '#9E9E9E',
  },
  h4: {
    margin: '0px',
    fontSize: '1rem',
    color: '#212121',
  },
})

const WrapperItem = styled('div')({
  display: 'flex',
  margin: '15px 0px',
  cursor: 'pointer',
  borderRadius: '10px',
  transition: 'all ease .3s',
  ':hover': {
    background: '#F6F6F6',
  },
})

type ItemList = {
  current_rating: number
  expected_rating: number
  health_status: number
  indicator: string
  item_type_id: number
  marketplace_id: number
  id: string
  metricId: string
}

const IndicatorItem = ({item}: {item: ItemList}) => {
  const navigate = useNavigate()
  const intl = useIntl()
  const formatTitle = intl.formatMessage({
    id: `BRAND_HEALTH.INDICATOR.TYPE.NAME.${item.item_type_id}`,
  })
  const title = formatTitle === 'Without code name' ? `Without key: ${item.item_type_id}` : formatTitle
  let currentState = brandHealthStateList.find((state) => state.id === Number(item.health_status))
  const handleItemClick = (item: ItemList) => {
    let mkp = `${item.marketplace_id}` === '1' ? 'amazon' : 'meli'
    navigate(
      `/brand-health/${mkp}/category_${item.metricId}/?&priority=${item.health_status}&output=${item.item_type_id}`
    )
  }
  return (
    <WrapperItem onClick={() => handleItemClick(item)}>
      <WrapperIcon bg={currentState?.color}>
        {item.marketplace_id === 1 ? (
          <IconAmazon width='50px' height='35px' color='#2A3547' />
        ) : (
          <IconMeli width='50px' height='35px' color='#2A3547' />
        )}
      </WrapperIcon>
      <Title>
        <h4>{title}</h4>
        <p>
          {intl.formatMessage({
            id: `${currentState?.label}`,
          })}
        </p>
      </Title>
    </WrapperItem>
  )
}

export const IndicatorTable = ({listAction}: {listAction: ItemList[]}) => {
  return (
    <>
      <Container>
        {listAction.map((item, i: number) => (
          <>
            <IndicatorItem key={i} item={item} />
            <Divider />
          </>
        ))}
      </Container>
    </>
  )
}
