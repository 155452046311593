import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_gws/layout/core'
import {SalesStrategyReport} from './SalesStrategyReport'
import {WaitingScreen} from '../../../_gws/partials/content/waiting/WaitingScreen'
import { BannerHelp } from '../../modules/apps/BannerHelp/BannerHelp'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

const SalesStrategyWrapper = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const hasBeenVerified = localStorage.getItem('verified') === 'true'

  useEffect(() => {
    if (!hasBeenVerified) return navigate('/account/overview')
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasBeenVerified])
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: intl.formatMessage({id: 'MENU.HOME'}),
            path: '/',
            isSeparator: false,
            isActive: false,
          },
          {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.STRATEGY.SALES'})}
      </PageTitle>

      {hasBeenVerified ? (
        <>
          <BannerHelp linkToDoc='https://docs.growker.ai/kb/estrategia/' title='HELP.TITLE.STRATEGY' text='HELP.TEXT.STRATEGY' />
          <SalesStrategyReport />{' '}
        </>
      ) : (
        <WaitingScreen />
      )}
    </>
  )
}

export {SalesStrategyWrapper}
