import React from 'react'
import {KTSVG} from '../../helpers'
import {toAbsoluteUrl} from '../../helpers/AssetHelpers'
import {Card} from 'react-bootstrap'
import {AgTitleCard} from '../../layout/components/ag/components/AgTitleCard'
import CountUp from 'react-countup'
import {AgBiggerNumberCard} from '../../layout/components/ag/components/AgBiggerNumberCard'
import styled from '@emotion/styled'

const CardStyled = styled(Card)({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  height: '100%',
})

const ContainerInfoCard = styled('div')({
  height: '100%',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
})
const WrapperFlex = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const CardStyledIcon = styled(Card)({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  height: '100%',
})

type CardProps = {
  text: string
  counter: string
  backgroundColor?: string
  color?: string
  currency?: string
  suffix?: string
  withDecimals?: Boolean
  icon?: string
  customStyleIcon?: string
}

/**
 * Component to show a card with a counter in the corner
 * @param text - string to name de counter.
 * @param counter - string that stats the counting.
 * @param backgroundColor - (optional)string to pass a backgroundColor to de card -var sass-.
 * @param color - (optional)string to pass a font color -var sass-.
 * @param currency - (optional)string to indicate the currency.
 * @param suffix - (optional)string to add a suffix.
 * @param withDecimals - (optional)boolean to allow showing decimals, if is not provided, the number won't show them.
 * @param icon - (optional)string to add icon's path
 * @param customStyleIcon - (optional)string -use class- to pass a custom style for your icon -var sass-.
 */

const CardWithCounterBody = ({
  text,
  counter,
  backgroundColor,
  color = 'dark',
  currency,
  suffix,
  withDecimals,
}: {
  counter: string
  currency?: string
  suffix?: string
  withDecimals?: Boolean
  text: string
  backgroundColor?: string
  color?: string
}) => {
  return (
    <CardStyled className={`rounded-1 p-5 pb-2 bg-${backgroundColor} text-${color}`}>
      <AgTitleCard text={text} />
      <div>
        <ContainerInfoCard>
          <WrapperFlex>
            {currency && <AgBiggerNumberCard text={currency} />}
            <CountUp
              end={Number(counter)}
              duration={2}
              separator={','}
              decimals={withDecimals ? 2 : 0}
              className='fw-bolder h2 mb-0'
            />
            {suffix && <AgBiggerNumberCard text={suffix} />}
          </WrapperFlex>
        </ContainerInfoCard>
      </div>
    </CardStyled>
  )
}

const CardWithCounterIcon = ({
  text,
  counter,
  backgroundColor,
  color = 'dark',
  currency,
  suffix,
  withDecimals,
  icon,
  customStyleIcon,
}: {
  counter: string
  currency?: string
  suffix?: string
  withDecimals?: Boolean
  text: string
  backgroundColor?: string
  color?: string
  icon: string
  customStyleIcon?: string
}) => {
  return (
    <CardStyledIcon className={`rounded-1 p-5 pb-2 bg-${backgroundColor} text-${color}`}>
      <div>
        <AgTitleCard text={text} />
        <div style={{display: 'flex'}} className='pt-6'>
          <KTSVG path={toAbsoluteUrl(icon)} className={`svg-icon-2x ${customStyleIcon}`} />
        </div>
      </div>
      <div>
        <ContainerInfoCard>
          <WrapperFlex>
            {currency && <AgBiggerNumberCard text={currency} />}
            <CountUp
              end={Number(counter)}
              duration={2}
              separator={','}
              decimals={withDecimals ? 2 : 0}
              className='fw-bolder h2 mb-0'
            />
            {suffix && <AgBiggerNumberCard text={suffix} />}
          </WrapperFlex>
        </ContainerInfoCard>
      </div>
    </CardStyledIcon>
  )
}
const CardWithCounter: React.FC<CardProps> = ({
  text,
  counter,
  backgroundColor,
  color = 'dark',
  currency,
  suffix,
  withDecimals,
  icon,
  customStyleIcon,
}) => {
  return (
    <>
      {!icon ? (
        <CardWithCounterBody
          text={text}
          counter={counter}
          backgroundColor={backgroundColor}
          color={color}
          currency={currency}
          suffix={suffix}
          withDecimals={withDecimals}
        />
      ) : (
        <CardWithCounterIcon
          text={text}
          counter={counter}
          backgroundColor={backgroundColor}
          color={color}
          currency={currency}
          suffix={suffix}
          withDecimals={withDecimals}
          icon={icon}
          customStyleIcon={customStyleIcon}
        />
      )}
    </>
  )
}

export {CardWithCounter}
