export const sortByNumber = (sortOrder: string, data: any, field: string) => {
  const result = data.toSorted((a: any, b: any) => {
    return sortOrder === 'asc' ? a[field] - b[field] : b[field] - a[field]
  })
  return result
}

export const sortByNumberSales = (sortOrder: string, data: any, field: string) => {
  const result = data.toSorted((a: any, b: any) => {
    return sortOrder === 'asc' ? a.sales[field] - b.sales[field] : b.sales[field] - a.sales[field]
  })
  return result
}

export const sortBySalesUnits = (sortOrder: string, data: any, field: string) => {
  const result = data.toSorted((a: any, b: any) => {
    return sortOrder === 'asc' ? a.sales.units - b.sales.units : b.sales.units - a.sales.units
  })
  return result
}

export const sortByFBA = (sortOrder: string, data: any, field: string) => {
  const result = data.toSorted((a: any, b: any) => {
    return a === b ? 0 : a ? -1 : 1
  })
  return result
}

export const sortByNumberForcast = (sortOrder: string, data: any, field: string) => {
  const result = data.toSorted((a: any, b: any) => {
    return sortOrder === 'asc'
      ? a.forecast[field] - b.forecast[field]
      : b.forecast[field] - a.forecast[field]
  })
  return result
}

export const sortByString = (sortOrder: string, data: any, field: string) => {
  const result = data.toSorted((a: any, b: any) =>
    sortOrder === 'asc' ? a[field].localeCompare(b[field]) : b[field].localeCompare(a[field])
  )
  return result
}
