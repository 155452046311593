import {IconBase} from './Base'

export const IconStrategy = ({color = '#273A51', size = 24}: {color?: string; size?: number}) => {
  const setColor = color
  return (
    <IconBase size={size} color={setColor} viewBox='0 0 10 10'>
      <svg
        width='10'
        height='10'
        viewBox='0 0 10 10'
        fill='none'
      >
        <path
          d='M1.11111 10C0.805556 10 0.543982 9.8912 0.326389 9.67361C0.108796 9.45602 0 9.19444 0 8.88889V1.11111C0 0.805556 0.108796 0.543982 0.326389 0.326389C0.543982 0.108796 0.805556 0 1.11111 0H8.88889C9.19444 0 9.45602 0.108796 9.67361 0.326389C9.8912 0.543982 10 0.805556 10 1.11111V8.88889C10 9.19444 9.8912 9.45602 9.67361 9.67361C9.45602 9.8912 9.19444 10 8.88889 10H1.11111ZM2.77778 3.88889C2.62037 3.88889 2.48843 3.94213 2.38194 4.04861C2.27546 4.15509 2.22222 4.28704 2.22222 4.44444V7.22222C2.22222 7.37963 2.27546 7.51157 2.38194 7.61806C2.48843 7.72454 2.62037 7.77778 2.77778 7.77778C2.93519 7.77778 3.06713 7.72454 3.17361 7.61806C3.28009 7.51157 3.33333 7.37963 3.33333 7.22222V4.44444C3.33333 4.28704 3.28009 4.15509 3.17361 4.04861C3.06713 3.94213 2.93519 3.88889 2.77778 3.88889ZM5 2.22222C4.84259 2.22222 4.71065 2.27546 4.60417 2.38194C4.49769 2.48843 4.44444 2.62037 4.44444 2.77778V7.22222C4.44444 7.37963 4.49769 7.51157 4.60417 7.61806C4.71065 7.72454 4.84259 7.77778 5 7.77778C5.15741 7.77778 5.28935 7.72454 5.39583 7.61806C5.50232 7.51157 5.55556 7.37963 5.55556 7.22222V2.77778C5.55556 2.62037 5.50232 2.48843 5.39583 2.38194C5.28935 2.27546 5.15741 2.22222 5 2.22222ZM7.22222 5.55556C7.06482 5.55556 6.93287 5.6088 6.82639 5.71528C6.71991 5.82176 6.66667 5.9537 6.66667 6.11111V7.22222C6.66667 7.37963 6.71991 7.51157 6.82639 7.61806C6.93287 7.72454 7.06482 7.77778 7.22222 7.77778C7.37963 7.77778 7.51157 7.72454 7.61806 7.61806C7.72454 7.51157 7.77778 7.37963 7.77778 7.22222V6.11111C7.77778 5.9537 7.72454 5.82176 7.61806 5.71528C7.51157 5.6088 7.37963 5.55556 7.22222 5.55556Z'
          fill={color}
        />
      </svg>
    </IconBase>
  )
}
