import React from 'react'
import {Card} from 'react-bootstrap'
import {AgTitleCard} from '../../layout/components/ag/components/AgTitleCard'
import CountUp from 'react-countup'
import {AgBiggerNumberCard} from '../../layout/components/ag/components/AgBiggerNumberCard'
import {AgPercentageCardText} from '../../layout/components/ag/components/AgPercentageCardText'
import {LabelWithGrowIndicator} from '../newComponets/Labels'
import styled from '@emotion/styled'

const ContainerInfoCard = styled('div')({
  height: '100%',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
})
const WrapperFlex = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

type CardProps = {
  text: string
  counter: string
  backgroundColor?: string
  color?: string
  currency?: string
  suffix?: string
  withDecimals?: Boolean
  textDescription?: string
  svgColorLabel?: string
  textLabel?: string
  backgroungColorLabel?: string
  colorLabel?: string
  iconLabel?: string
}

/**
 * Component to show a card with a counter in the corner
 * @param text - string to name de counter.
 * @param textDescription - string to description de counter.
 * @param counter - string that stats the counting.
 * @param backgroundColor - (optional)string to pass a backgroundColor to de card -var sass-.
 * @param color - (optional)string to pass a font color -var sass-.
 * @param currency - (optional)string to indicate the currency.
 * @param suffix - (optional)string to add a suffix.
 * @param withDecimals - (optional)boolean to allow showing decimals, if is not provided, the number won't show them.
 * @param backgroundColorLabel - background Color for the label component. (variable sass)
 * @param colorLabel - color for text- label component (variable sass) default "black"
 * @param iconLabel - string(icon referent) for the label component
 * @param svgColorLabel- string with color of svg icon
 * @param textLabel: string with title of label
 */

const CardWithCounterLabel: React.FC<CardProps> = ({
  text,
  counter,
  backgroundColor,
  color = 'dark',
  currency,
  suffix,
  withDecimals,
  textDescription = '',
  svgColorLabel,
  textLabel = '',
  backgroungColorLabel,
  colorLabel = 'dark',
  iconLabel = '',
}) => {
  return (
    <Card
      className={`d-flex flex-column justify-content-between rounded-4 h-100 p-3 bg-${backgroundColor} text-${color}`}
    >
      <div className='d-flex flex-column'>
        <div className='d-flex mb-1'>
          <AgTitleCard customClass='me-2' text={text} />
          <LabelWithGrowIndicator
            svgColor={svgColorLabel}
            text={textLabel}
            backgroundColor={backgroungColorLabel}
            color={colorLabel}
          />
        </div>
        <AgPercentageCardText text={textDescription} />
      </div>
      <ContainerInfoCard>
        <WrapperFlex>
          {currency && <AgBiggerNumberCard text={currency} />}
          <CountUp
            end={Number(counter)}
            duration={2}
            separator={','}
            decimals={withDecimals ? 2 : 0}
            className='fw-bolder h2 mb-0'
          />
          {suffix && <AgBiggerNumberCard text={suffix} />}
        </WrapperFlex>
      </ContainerInfoCard>
    </Card>
  )
}

export {CardWithCounterLabel}
