import {IconBase} from './Base'

export const IconGlossary = ({color = '#273A51', size = 24}: {color?: string; size?: number}) => {
  const setColor = color
  return (
    <IconBase size={size} color={setColor} viewBox='0 0 10 13'>
      <svg
        width='10'
        height='13'
        viewBox='0 0 10 13'
        fill='none'
      >
        <path
          d='M2.1875 12.5C1.58333 12.5 1.06771 12.2865 0.640625 11.8594C0.213542 11.4323 0 10.9167 0 10.3125V2.1875C0 1.58333 0.213542 1.06771 0.640625 0.640625C1.06771 0.213542 1.58333 0 2.1875 0H8.75C9.09375 0 9.38802 0.122396 9.63281 0.367188C9.8776 0.611979 10 0.90625 10 1.25V8.76563C10 8.93229 9.95833 9.08333 9.875 9.21875C9.79167 9.35417 9.67708 9.45833 9.53125 9.53125C9.38542 9.60417 9.27083 9.71094 9.1875 9.85156C9.10417 9.99219 9.0625 10.1458 9.0625 10.3125C9.0625 10.4792 9.10417 10.6354 9.1875 10.7812C9.27083 10.9271 9.38542 11.0312 9.53125 11.0938C9.66667 11.1458 9.77865 11.224 9.86719 11.3281C9.95573 11.4323 10 11.5573 10 11.7031V11.8594C10 12.0365 9.9401 12.1875 9.82031 12.3125C9.70052 12.4375 9.55208 12.5 9.375 12.5H2.1875ZM4.10938 5.76563H5.875L6.1875 6.64062C6.20833 6.71354 6.2474 6.77083 6.30469 6.8125C6.36198 6.85417 6.43229 6.875 6.51563 6.875C6.64063 6.875 6.73958 6.82292 6.8125 6.71875C6.88542 6.61458 6.89583 6.50521 6.84375 6.39063L5.46875 2.73438C5.44792 2.66146 5.40625 2.60417 5.34375 2.5625C5.28125 2.52083 5.20833 2.5 5.125 2.5H4.84375C4.76042 2.5 4.6875 2.52083 4.625 2.5625C4.5625 2.60417 4.52083 2.66146 4.5 2.73438L3.125 6.40625C3.07292 6.52083 3.08594 6.6276 3.16406 6.72656C3.24219 6.82552 3.34375 6.875 3.46875 6.875C3.55208 6.875 3.6224 6.85417 3.67969 6.8125C3.73698 6.77083 3.77604 6.71354 3.79688 6.64062L4.10938 5.76563ZM4.32813 5.125L4.96875 3.3125H5.01563L5.64063 5.125H4.32813ZM2.1875 11.25H8.01562C7.95312 11.1042 7.90365 10.9557 7.86719 10.8047C7.83073 10.6536 7.8125 10.4896 7.8125 10.3125C7.8125 10.1458 7.82812 9.98438 7.85937 9.82813C7.89062 9.67188 7.94271 9.52083 8.01562 9.375H2.1875C1.91667 9.375 1.69271 9.46615 1.51562 9.64844C1.33854 9.83073 1.25 10.0521 1.25 10.3125C1.25 10.5833 1.33854 10.8073 1.51562 10.9844C1.69271 11.1615 1.91667 11.25 2.1875 11.25Z'
          fill={color}
        />
      </svg>
    </IconBase>
  )
}
