import React, {FC, useEffect, useRef} from 'react'
import {abbreviateNumber, tooltipDefault} from '../../../../helpers/misc'
import {Chart} from 'react-chartjs-2'
import {Chart as ChartJS} from 'chart.js'

type Props = {
  dataLabels: string[]
  dataNumbers: number[]
  dataColor: string[]
  hoverColor?: string
  chartYTitle?: string
  chartXTitle?: string
  currentElement?: number
  updateCurrentElement?: (index: number) => void
  cleanChart?: boolean
  cleanAxeX?: boolean
  widthChart?: number
  heightChart?: number
}
/**
 * This component is used to display the bar chart
 * @param dataLabels - array of labels
 * @param dataNumbers - array of values
 * @param dataColor - array of colors in hexadecimal format
 * @param hoverColor - color in hexadecimal format
 * @param chartYTitle - title of the y-axis
 * @param chartXTitle - title of the x-axis
 * @param currentElement - index of the current element
 * @param updateCurrentElement - function to update the current element
 * @param cleanChart - boolean to display or not the grid and axis
 * @param cleanAxeX - boolean to display or not the x-axis
 * @param widthChart - size of the chart in *px*
 */
const WrapperBarChart: FC<Props> = ({
  dataLabels,
  dataNumbers,
  dataColor,
  hoverColor = '#3D96CA',
  chartYTitle,
  chartXTitle,
  currentElement,
  updateCurrentElement,
  cleanChart,
  cleanAxeX,
  widthChart,
  heightChart,
}) => {
  const chartRef = useRef<ChartJS>(null)
  let canShowLabels = !cleanAxeX && !cleanChart
  /**
   * Update the current element when the mouse is hover
   * @param event
   * @param context
   */
  const handleHover = (event: any, context: any) => {
    if (currentElement !== context[0].index) {
      updateCurrentElement && updateCurrentElement(context[0].index)
    }
  }

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: canShowLabels ? tooltipDefault(null, false) : {enabled: false},
      datalabels: {
        display: false,
      },
    },
    onHover: handleHover,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    scales: {
      x: {
        title: {
          display: !cleanChart && !!chartXTitle,
          text: chartXTitle && chartXTitle,
          color: chartXTitle && '#a1a5b7',
          font: {
            weight: 'normal',
          },
        },
        grid: {
          display: false,
          drawBorder: false,
          drawTicks: false,
        },
      },
      y: {
        title: {
          display: !cleanChart && !!chartYTitle,
          text: chartYTitle && chartYTitle,
          color: chartYTitle && '#a1a5b7',
          font: {
            weight: 'normal',
          },
        },

        grid: {
          display: !cleanChart,
          drawBorder: false,
          drawTicks: false,
          borderDash: [5, 5],
          lineWidth: 1,
          color: function () {
            return 'rgba(161,162,182,0.17)'
          },
        },
        ticks: {
          display: !cleanChart,
          color: '#a1a5b7',
          callback: function (value: any, index: any, ticks: any) {
            return abbreviateNumber(value, 0)
          },
        },
      },
    },
  }
  const data = {
    labels: canShowLabels ? dataLabels : Array.from({length: dataLabels.length}, () => ''),
    datasets: [
      {
        data: dataNumbers,
        backgroundColor: dataColor,
        hoverBackgroundColor: hoverColor,
        borderRadius: {topLeft: 8, topRight: 8, bottomLeft: 0, bottomRight: 0},
        maxBarThickness: 14,
        borderSkipped: false,
      },
    ],
  }

  useEffect(() => {
    const chart = chartRef.current
    widthChart &&
      chart &&
      //@ts-ignore
      (chart.canvas.classList = `d-flex justify-content-end w-${widthChart}% h-${
        heightChart ? heightChart : Math.floor(widthChart / 2)
      }%`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Chart type='bar' ref={chartRef} data={data} options={options} />
    </>
  )
}

export {WrapperBarChart}
