import React, {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../helpers'
import {MenuComponent} from '../../assets/ts/components'

type Props = {
  title: string
  options: any
  onFilter: any
  colorTitle?:string
}

const CustomSelectFilter: FC<Props> = ({title, options, onFilter ,colorTitle}) => {
  useEffect(() => {
    return MenuComponent.reinitialization()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })

  useEffect(() => {
    onFilter()
    setFilter('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [filter, setFilter] = useState('')
  const cleanFilter = (e: any) => {
    e.preventDefault()
    onFilter('', setFilter(''))
  }

  return (
    <>
      {Object.keys(options).length > 1 ? (
        <>
          <a
            href={'/#'}
            className='text-start fw-normal fs-7 text-capitalize gs-0 text-nowrap cursor-pointer'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-start'
            style={{lineHeight: '16px', color: colorTitle}}
          >
            {filter === '' && title}
            {filter !== '' && (
              <>
                <span onClick={(e: any) => cleanFilter(e)} className='d-inline-block'>
                  <KTSVG path='/media/icons/unicos/008-exit.svg' className='svg-icon-6 m-0 me-1' />
                </span>
                <span className='d-inline-block'>{filter}</span>
              </>
            )}

            <span className='d-inline-block ms-1'>
              <KTSVG path='/media/icons/unicos/049-menu-1.svg' className='svg-icon-6' />
            </span>
          </a>
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 py-4 w-auto'
            data-kt-menu='true'
          >
            {Object.entries(options).map((value: any, index) => {
              return (
                <div className='menu-item px-3' key={value[0]}>
                  <span
                    className='menu-link px-3'
                    onClick={() => onFilter(value[0], setFilter(value[1]))}
                  >
                    {value[1]}
                  </span>
                </div>
              )
            })}
          </div>
        </>
      ) : (
        Object.keys(options).length <= 1 && (
          <span className='text-start fw-normal fs-7 text-capitalize gs-0 text-nowrap'>
            {title}
          </span>
        )
      )}
    </>
  )
}

export {CustomSelectFilter}
