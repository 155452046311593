import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_gws/layout/core'

const TicketWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: intl.formatMessage({id: 'MENU.HOME'}),
            path: '/dashboard',
            isSeparator: false,
            isActive: false,
          },
          {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.TICKETS'})}
      </PageTitle>
      <h1>Tickets...</h1>
    </>
  )
}

export default TicketWrapper
