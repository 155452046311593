import axios from 'axios'
import {getTokenHeader, mkProp} from '../../../modules/auth'

const API_URL_CRUNCH = process.env.REACT_APP_BACKEND_MAIN_API

export const getBestSellerByCategory = ({
  category,
  currentMkp,
}: {
  category: string
  currentMkp: mkProp
}) => {
  if (!currentMkp || currentMkp === 'growker' || currentMkp === 'meli') return
  const head = getTokenHeader()
  const cleanAxios = axios.create()
  return cleanAxios.get(
    `${API_URL_CRUNCH}/api/growker_monitor/best_sellers_products/${category}`,
    // @ts-ignore
    head
  )
}
