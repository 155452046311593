import React, {FC} from 'react'

/**
 * Props declarations
 * @param props - table component options
 * @param count - url actual page size value
 */
type Props = {
  props: any
  count: number
}

/**
 * These components allow to customize default dropdown for page sizing in table
 * @param props
 * @param count
 * @constructor
 */
const CustomSizePerPage: FC<Props> = ({props, count}) => {
  return (
    <div className='dropdown'>
      <button
        type='button'
        className='btn btn-light-primary btn-sm dropdown-toggle'
        data-bs-toggle='dropdown'
        aria-expanded='false'
      >
        {count}
      </button>
      <ul className='dropdown-menu'>
        {props.options.map((option: any) => {
          const isSelect = props.currSizePerPage === `${option.page}`
          return (
            <li key={option.text}>
              <div
                className={`dropdown-item cursor-pointer ${
                  isSelect ? 'bg-primary text-white' : 'bg-default'
                }`}
                onClick={() => props.onSizePerPageChange(option.page)}
              >
                {option.text}
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export {CustomSizePerPage}
