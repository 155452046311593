import { useState} from 'react'
import {Indicator, ParamsType, ReferenceType} from '../core/getBrandhealthIndicators'
import {
  Cell,
  Container,
  ContentCell,
  HeaderCell,
  Table,
  WrapperTable,
  StyledButton,
  WrapperIndicators,
  IndicatorCard,
} from './styled'
import {Pagination} from '../../playlist/components/Table/components/Pagination'
import {useIntl} from 'react-intl'
import {AlertFetchError} from '../../../../_gws/helpers/misc'
import {Spinner} from 'react-bootstrap'
import {addDays, setMinutes, setHours} from 'date-fns'
import {createPlayListTask} from '../../playlist/core/_requests'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import {
  IconCritical,
  IconDelicate,
  RenderMarketplace,
  RenderOutputDescription,
  RenderPriority,
  RenderSelect,
  RenderSelectAll,
} from './BrandhealthTableCells'
import {Filters} from './BrandhealthTableFilters'
import { mutate } from 'swr'

type Columns = {
  field: string
}

type InitialState = {
  selected: Indicator[]
}

const columns: Columns[] = [
  {
    field: 'select',
  },
  {
    field: 'Marketplace',
  },
  {
    field: 'Output',
  },
  {
    field: 'Descripción',
  },
  {
    field: 'Prioridad',
  },
]

export const brandHealthStateList: Record<number, any> = {
  1: {
    label: 'BRAND_HEALTH.SEVERITY.1',
    bg: '#F9D7DD',
    color: '#B3626F',
    gradient: 'bg-gradient-danger',
  },
  2: {
    id: 2,
    label: 'BRAND_HEALTH.SEVERITY.2',
    bg: '#F4EDC3',
    color: '#B3A036',
    gradient: 'bg-gradient-orange',
  },
  3: {
    label: 'BRAND_HEALTH.SEVERITY.3',
    bg: '#CBF3F9',
    color: '#46A4BD',
    gradient: 'bg-gradient-warning',
  },
  4: {
    label: 'BRAND_HEALTH.SEVERITY.4',
    bg: '#CBF3F9',
    color: '#46A4BD',
    gradient: 'bg-gradient-primary',
  },
}

const flex = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const verifiedChecket = (id: string, selectedItems: Indicator[]) => {
  const find = selectedItems.filter((item: Indicator) => item.id === id)
  return find.length > 0 ? true : false
}

export const BrandhealthTableDetails = ({
  indicators,
  references,
  paramsObj,
  totalIndicators,
  handlerCurrentPage,
  currentPage,
  delicate,
  critical
}: {
  indicators: Indicator[]
  references: ReferenceType[]
  paramsObj: ParamsType,
  totalIndicators: number,
  handlerCurrentPage: any,
  currentPage: number,
  delicate: number
  critical: number
}) => {
  let PageSize = 10
  const Alert = withReactContent(Swal)
  const intl = useIntl()
  const [state, setState] = useState<InitialState>({
    selected: [],
  })

  const [isLoading, setIsLoading] = useState({
    loading: false,
  })

  const selectItem = (id: string) => {
    let selected: Indicator[] = state.selected
    const verify = state.selected.filter((item: Indicator) => item.id === id)
    if (verify.length < 1) {
      const finded = indicators.filter((item: Indicator) => item.id === id)
      selected.push(finded[0])
    } else {
      const removed = state.selected.filter((item: Indicator) => item.id !== id)
      selected = [...removed]
    }
    setState((pre) => ({
      ...pre,
      selected: selected,
    }))
  }

  const selectAll = () => {
    setState((pre) => ({
      ...pre,
      selected: indicators.length !== pre.selected.length ? indicators : [],
    }))
  }

  const handleClickResolve = async () => {
    const deadlineDays: Date = addDays(new Date(), 2)
    const definedHours: Date = setMinutes(setHours(deadlineDays, 23), 59)
    const tasks = state.selected
    setIsLoading((pre) => ({
      ...pre,
      loading: true,
    }))
    const formatTask = tasks.map((item: any) => ({
      title: item.indicator,
      description: item.description,
      priority: item.health_status,
      deadline: definedHours,
      status: 1,
      type: 'OUTPUT',
      referenceId: item.item_type_id,
      marketplaceId: item.marketplace_id,
      item: item.id,
    }))
    const response: any = await createPlayListTask(formatTask)
    if (response.status !== 200) {
      setIsLoading((pre) => ({
        ...pre,
        loading: false,
        msg: intl.formatMessage({id: 'PLAYLIST.ERROR.MESSAGE'}),
      }))
      AlertFetchError(intl.locale, intl.formatMessage({id: 'PLAYLIST.ERROR.MESSAGE'})).fire()
      return
    }
    setIsLoading((pre) => ({
      ...pre,
      loading: false,
    }))
    mutate('/playlist')
    Alert.fire({
      text: intl.formatMessage({id: 'PLAYLIST.SUCCESS.MESSAGE.BRANDHEALTH'}),
      icon: 'success',
      iconColor: '#00C6C7;',
      buttonsStyling: false,
      confirmButtonText: `${intl.formatMessage({
        id: 'USER_MNGT.MODAL.BUTTON.ACCEPT',
      })}`,
      customClass: {
        confirmButton: 'btn btn-primary',
        popup: 'bg-light',
      },
    })
  }


  return (
    <Container>
      <WrapperIndicators>
        <IndicatorCard bg='#F4EDC3'>
          <div style={{...flex}}>
            <IconDelicate />
            <h6>{intl.formatMessage({id: 'PLAYLIST.PRIORITY.1'})}</h6>
          </div>
          <p>{delicate}</p>
        </IndicatorCard>
        <IndicatorCard bg='#F9D7DD'>
          <div style={{...flex}}>
            <IconCritical />
            <h6>{intl.formatMessage({id: 'PLAYLIST.PRIORITY.2'})}</h6>
          </div>
          <p>{critical}</p>
        </IndicatorCard>
      </WrapperIndicators>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          margin: '10px 0px',
        }}
      >
        <h5>{intl.formatMessage({id: 'BRAND_HEALTH.LABEL'})}</h5>
        <Filters
          paramsObj={paramsObj}
          references={references}
        />
      </div>
      {state.selected.length > 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <StyledButton disabled={isLoading.loading} onClick={() => handleClickResolve()} size='sm'>
            {isLoading.loading ? (
              <Spinner size='sm' animation='border' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </Spinner>
            ) : (
              <>{intl.formatMessage({id: 'BRAND_HEALTH.TABLE.ADD.ALL.PLAYLIST'})}</>
            )}
          </StyledButton>
        </div>
      )}
      <WrapperTable>
        <Table>
          {indicators.length > 0 && (
            <tr>
              {columns.map((item: Columns) => (
                <HeaderCell key={item.field}>
                  {item.field === 'select' ? (
                    <RenderSelectAll selectAll={selectAll} />
                  ) : (
                    <div
                      style={
                        item.field === 'Marketplace' || item.field === 'Prioridad'
                          ? {
                              display: 'flex',
                              justifyContent: 'center',
                            }
                          : {}
                      }
                    >
                      {item.field}
                    </div>
                  )}
                </HeaderCell>
              ))}
            </tr>
          )}

          {indicators.length > 0 &&
            indicators.map((item: Indicator, i: number) => (
              <ContentCell key={i}>
                <Cell>
                  <RenderSelect
                    id={item.id}
                    checked={verifiedChecket(item.id, state.selected)}
                    selectItem={selectItem}
                  />
                </Cell>
                <Cell>
                  <RenderMarketplace marketplace={item.marketplace_id} />
                </Cell>
                <Cell>{item.indicator}</Cell>
                <Cell
                  event
                  style={{
                    width: '55%',
                  }}
                >
                  <RenderOutputDescription
                    marketplaceId={item.marketplace_id}
                    priority={item.health_status}
                    item={item.id}
                    type={item.item_type_id}
                    description={item.id}
                  />
                </Cell>
                <Cell bg={brandHealthStateList[item.health_status].bg}>
                  <RenderPriority priority={item.health_status} />
                </Cell>
              </ContentCell>
            ))}
          {indicators.length < 1 && (
            <div>{intl.formatMessage({id: 'BRAND_HEALTH.NO_INFORMATION'})}</div>
          )}
        </Table>
        <Pagination
          currentPage={currentPage}
          totalCount={totalIndicators}
          pageSize={PageSize}
          onPageChange={(page: any) => handlerCurrentPage(page)}
        />
      </WrapperTable>
    </Container>
  )
}
