import React, {useState} from 'react'
import {Carousel} from 'react-bootstrap'
import {KTSVG} from '../../../helpers'

type SliderCardWithProgressProps = {
  arrow?: boolean
  autoPlay?: boolean
  cardsData: Array<{
    children?: React.ReactNode
  }>,
  shadow?: boolean
  controls?: boolean
}

/**
 * This Component create a Dinamic Carousel component with diferent cards
 *
 * @param cardsData - cardsData is an Array with the children objects with the all props necesary to render the Cards. In children yo can add the Card components yo need to use. In TestSheet yo can find a example. 
section
 */

const SliderCardGeneral: React.FC<SliderCardWithProgressProps> = ({
  autoPlay = true,
  arrow = false,
  shadow = true,
  cardsData,
  controls = true,
}) => {
  const [activeCard, setActiveCard] = useState(0)

  const handleSelect = (selectedIndex: number) => {
    setActiveCard(selectedIndex)
  }

  const renderDots = () => {
    return cardsData.map((_, index) => (
      <div
        key={index}
        className={`dot ${index === activeCard ? 'active' : ''}`}
        onClick={() => handleSelect(index)}
      />
    ))
  }
  return (
    <div className={`carousel-container rounded-1 p-0 m-0 ${shadow && 'card'}`}>
      <style>{`
      
      .carousel{
          height:100%!important;
      }
        .carousel-container {
          position: relative;
          height:100%!important;
        }
        .carousel-inner{
          height:100%!important;
        }
        
        .dots-container {
          display:flex;
          position: absolute;
          top: 15px;
          right: 15px;
        }
        
        .dot {
          width: 4px;
          height: 4px;
          border-radius: 8px;
          background-color: #CCCCCC	;
          margin: 0 1px;
          cursor: pointer;
        }
        
        .dot.active {
          width: 15px;
          height: 4px;
          background-color: #CCCCCC;
        }
        .carousel-control-prev, .carousel-control-next {
            align-items:flex-start!important;
            padding-top:1rem!important;
            width:0px
        }
    
      `}</style>
      <Carousel
        activeIndex={activeCard}
        onSelect={handleSelect}
        indicators={false}
        interval={autoPlay ? 3000 : null}
        slide={true}
        controls={controls}
        prevIcon={
          arrow ? (
            <KTSVG path='/media/icons/duotune/general/gen058.svg' className='svg-icon-5 svg-icon-gray-900 top-10' />
          ) : (
            ''
          )
        }
        prevLabel={''}
        nextIcon={
          arrow ? (
            <KTSVG path={'/media/icons/duotune/general/gen057.svg'} className='svg-icon-5 svg-icon-gray-900 top-10' />
          ) : (
            ''
          )
        }
        nextLabel={''}
      >
        {cardsData.map((card, index) => (
          <Carousel.Item key={index} style={{height: '100%'}}>
            <div className='d-flex align-items-start justify-content-between h-100'>
              <div className='p-0 w-100 h-100'>{card.children}</div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      <div className='dots-container'>{!arrow && renderDots()}</div>
    </div>
  )
}

export {SliderCardGeneral}
