import styled from '@emotion/styled'
import {ButtonPay, PaymentContainer, PaymentHeader} from './styled'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'

const IconCancel = () => {
  return (
    <svg style={{marginBottom: '40px'}} width='70' height='72' viewBox='0 0 70 72' fill='none'>
      <path
        d='M20.7955 53.1154L34.8521 38.4279L48.9086 53.1154L51.2322 50.6875L37.1757 36L51.2322 21.3125L48.9086 18.8846L34.8521 33.5721L20.7955 18.8846L18.4719 21.3125L32.5284 36L18.4719 50.6875L20.7955 53.1154ZM34.8654 71.8334C30.1684 71.8334 25.7351 70.9014 21.5655 69.0376C17.396 67.1737 13.7451 64.607 10.6129 61.3374C7.4808 58.0678 5.02197 54.2568 3.23642 49.9044C1.45088 45.5519 0.558105 40.9218 0.558105 36.014C0.558105 31.0367 1.44998 26.387 3.23373 22.065C5.01755 17.743 7.47402 13.9456 10.6031 10.6728C13.7323 7.40013 17.3796 4.83093 21.545 2.96523C25.7105 1.09954 30.1418 0.166687 34.8387 0.166687C39.6022 0.166687 44.0521 1.0986 48.1884 2.96242C52.3248 4.8263 55.959 7.39304 59.0912 10.6626C62.2233 13.9322 64.6821 17.7259 66.4677 22.0436C68.2532 26.3613 69.146 31.0088 69.146 35.9861C69.146 40.8939 68.2541 45.5262 66.4704 49.8829C64.6866 54.2397 62.2301 58.0544 59.101 61.3272C55.9718 64.5999 52.3412 67.1691 48.209 69.0348C44.0767 70.9005 39.6288 71.8334 34.8654 71.8334ZM34.8521 68.5C43.492 68.5 50.836 65.3403 56.8839 59.0209C62.9319 52.7014 65.9559 45.0278 65.9559 36C65.9559 26.9722 62.9319 19.2986 56.8839 12.9792C50.836 6.65974 43.492 3.50002 34.8521 3.50002C26.2121 3.50002 18.8682 6.65974 12.8202 12.9792C6.77222 19.2986 3.74824 26.9722 3.74824 36C3.74824 45.0278 6.77222 52.7014 12.8202 59.0209C18.8682 65.3403 26.2121 68.5 34.8521 68.5Z'
        fill='#1C1B1F'
      />
    </svg>
  )
}

const WrapperAction = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '2rem',
  margin: '20px 0px',
})

export const CancelButton = styled('button')({
  width: '100%',
  height: '50px',
  borderRadius: '8px',
  cursor: 'pointer',
  color: '#fff',
  background: '#D3D3D3',
  fontSize: '1.2rem',
  fontWeight: 'bold',
  border: 'none',
})

export const PaymentError = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  return (
    <PaymentContainer style={{
      padding: "0px 30px"
    }}>
      <PaymentHeader>
        <IconCancel />
        <h1>{intl.formatMessage({id: 'PAYMENT.HEADER.ERROR.TITLE'})}</h1>
        <p>{intl.formatMessage({id: 'PAYMENT.HEADER.ERROR.SUBTITLE'})}</p>
      </PaymentHeader>
      <div>
        <p>{intl.formatMessage({id: 'PAYMENT.ERROR.PARAGRAPH.1'})}</p>
        <p>{intl.formatMessage({id: 'PAYMENT.ERROR.PARAGRAPH.2'})}</p>
      </div>
      <WrapperAction>
        <CancelButton onClick={() => navigate('/auth')}>
          {intl.formatMessage({id: 'MARKETPLACE.CANCEL'})}
        </CancelButton>
        <ButtonPay onClick={() => navigate(`/payment?email=${localStorage.getItem('temporal')}`)}>
          {intl.formatMessage({id: 'PAYMENT.ACTION.PAY.RETRY'})}
        </ButtonPay>
      </WrapperAction>
    </PaymentContainer>
  )
}
