import {FC} from 'react'
import {
  Container,
  ContainerBanner,
  ContainerBrand,
  ContainerPlaylist,
  ContainerSales,
  Content,
  ContentTopWrapper,
  ContentDownWrapper,
} from './styled'
import {TableDashboardWrapper} from './components/client/TableDashboardWrapper'
import {Banner} from './components/client/Banner'
import {BrandHealthStatus} from './components/client/BrandHealthStatus'
import {ChartDashboardSales} from './components/client/ChartDashboardSales'
import {PlaylistIndicator} from './components/client/PlaylistIndicator'

type Props = {
  dataReport?: any
}

const Dashboard: FC<Props> = ({dataReport}) => {
  return (
    <Container>
      <ContentTopWrapper>
        <ContainerBanner>
          <Banner dataReport={dataReport} />
        </ContainerBanner>
        <ContainerBrand>
          <BrandHealthStatus data={dataReport.brand_health_summary} />
        </ContainerBrand>
      </ContentTopWrapper>

      <ContentDownWrapper>
        <ContainerSales>
          <ChartDashboardSales dataReport={dataReport} />
        </ContainerSales>
        <ContainerPlaylist>
          <PlaylistIndicator />
        </ContainerPlaylist>
      </ContentDownWrapper>

      <Content>
        <TableDashboardWrapper data={dataReport} />
      </Content>
    </Container>
  )
}

export {Dashboard}
