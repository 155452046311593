import {FC, Fragment, useEffect, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
import WIPSplash from '../../../../_gws/assets/lottie/WIP.json'
import {Splash} from '../../../../_gws/helpers/misc'
import Lottie from 'lottie-react'

const WIP: FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const video = useRef<HTMLVideoElement>(null)
  const playVideo = () => {
    video.current && video.current.play()
  }

  useEffect(() => {
    playVideo()
    Splash(false)
  }, [])

  const handleClick = () => {
    navigate(-2)
  }
  return (
    <Fragment>
      <div className='d-flex flex-column flex-center'>
        <div className='d-flex flex-column flex-center flex-column-fluid p-10'>
          <h1 className='fw-bold mb-10'>{intl.formatMessage({id: 'WIP.TEXT_WORKING'})}</h1>
          <div className='mw-100 h-lg-400px'>
            <Lottie animationData={WIPSplash} style={{height: '450px'}} />
          </div>
        </div>
        <div className='d-flex flex-column flex-center flex-column-fluid p-10'>
          <div className='mw-100'>
            <button onClick={handleClick} className='btn btn-primary'>
              {intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export {WIP}
