import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_gws/layout/core'
import React, {useEffect, useState} from 'react'
import {ProductTrackerReport} from './ProductTrackerReport'
import {ProductTrackerReportModal} from '../../../_gws/partials/modals/reports/ProductTrackerReportModal'
import {useParams} from 'react-router-dom'
import {HeaderReport} from '../../modules/apps/header/HeaderReport'
import { mkProp } from '../../modules/auth'

const ProductTrackerWrapper = () => {
  const intl = useIntl()

  /**
   * URL Params
   */
  const params = useParams()
  /**
   * Check if exists params, if not initiate as '' in both values
   */
  const initialMkp = params.mkpl ?? 'growker'
  const initialValue = params.id === undefined ? '' : params.id.toString()

  /**
   * Set mkplace with the previous initiate value
   */
  // @ts-ignore
  const [mkplace, setMkplace] = useState(initialMkp)
  /**
   * If initialMkp is not empty the search input is showed
   */
  const [showSearchInput, setShowSearchInput] = useState(initialMkp === '' ? false : true)

  /**
   * This function handel the status of Marketplace selector
   * @param mk {string} - text of the marketplace selected
   */
  const changeMarketPlace = async (mk: string) => {
    setShowSearchInput(false)
    if (mk.length > 0) {
      setShowSearchInput(true)
    }
  }
  useEffect(() => {
    changeMarketPlace(mkplace)
  }, [mkplace])

  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: intl.formatMessage({id: 'MENU.HOME'}),
            path: '/dashboard',
            isSeparator: false,
            isActive: false,
          },
          {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.REPORTS.PRODUCT_HEALTH'})}
      </PageTitle>
      <ProductTrackerReportModal />
      <HeaderReport
        hasMarketPlace={true}
        hasDate={false}
        hasFilter={false}
        setMkplace={setMkplace}
        activeMkp={mkplace as mkProp}
      />
      {showSearchInput && <ProductTrackerReport mkp={mkplace} value={initialValue} />}
    </>
  )
}

export {ProductTrackerWrapper}
