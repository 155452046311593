import {addCommasInNumber} from '../../../../_gws/helpers/misc'
import {mkProp} from '../../../modules/auth'
import {getSales} from './_requests'
import {MarketplaceGoals, MarketplaceGoalsKey, ReportStrategyData, Strategy} from './types'

const date = new Date()
const endDayMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
const daysOfMonths: number[] = Array.from({length: endDayMonth}, (_, i) => i + 1)

const structureChartDatails = ({goals, current}: {goals: number; current: number}) => {
  let data: number[] = []
  let tendency: number[] = []
  let target: number[] = []

  daysOfMonths.forEach((day: number) => {
    target = [...target, goals]
    tendency = [...tendency, Number(((day / endDayMonth) * goals).toFixed(0))]
    if (day <= date.getDate()) {
      data = [...data, Number(((day / date.getDate()) * current).toFixed(2))]
    }
  })
  return {
    data,
    tendency,
    target,
  }
}

const Goals: Record<MarketplaceGoalsKey, MarketplaceGoals> = {
  amazon: 'amazon',
  meli: 'mercado_libre',
}

export const getSalesStrategyData = async ({
  periodReportType,
  marketplace,
  clientData,
}: {
  periodReportType: number
  marketplace: mkProp
  clientData: any | undefined //clientData is any because it has many optional properties
}) => {
  if (!clientData || !marketplace || marketplace === 'growker') return
  const goalsKey = marketplace as MarketplaceGoalsKey
  const [strategy] = await Promise.all([getSales(marketplace, periodReportType)])
  const data = strategy.data.detail as Strategy

  const goalsSales: number = clientData.goals[`sales_${Goals[goalsKey]}`]
  const goalsProfit: number = clientData.goals[`profit_${Goals[goalsKey]}`]

  const icomeProgress = ((data.orderProfit * 100) / goalsProfit).toFixed(2)
  const unitsToSellProgress = ((data.unitsSold * 100) / goalsSales).toFixed(2)

  const report: ReportStrategyData = {
    unitsSold: data.unitsSold,
    orderProfit: addCommasInNumber(data.orderProfit),
    roas: clientData.goals.roas,
    unitsToSell: {
      from: data.unitsSold,
      to: goalsSales,
      progress: Number(unitsToSellProgress) > 100 ? '100.00' : unitsToSellProgress,
      progressColor: '#42E5FF',
      color: '#CDF1F6',
    },
    income: {
      from: addCommasInNumber(data.orderProfit),
      to: addCommasInNumber(goalsProfit),
      progress: Number(icomeProgress) > 100 ? '100.00' : icomeProgress,
      progressColor: '#FF5472',
      color: '#F6D8DD',
    },
    salesChartData: structureChartDatails({
      goals: goalsSales,
      current: data.unitsSold,
    }),
    profitChartData: structureChartDatails({
      goals: goalsProfit,
      current: data.orderProfit,
    }),
    labels: daysOfMonths,
  }
  return report
}
