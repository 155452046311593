import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_gws/layout/core'
import {Playlist} from './Playlist'
import {WaitingScreen} from '../../../_gws/partials/content/waiting/WaitingScreen'
import useSWR from 'swr'
import {getOwnPlaylist, getTypes} from './core/_requests'
import {useEffect} from 'react'
import {AlertFetchError, Splash} from '../../../_gws/helpers/misc'
import {BannerHelp} from '../../modules/apps/BannerHelp/BannerHelp'
import {useNavigate} from 'react-router-dom'

const PlaylistWrapper = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const hasBeenVerified = localStorage.getItem('verified') === 'true'

  const {data, error, isLoading} = useSWR(
    () => (hasBeenVerified ? ['/playlist'] : null),
    () => getOwnPlaylist(),
    {}
  ) as {
    data: any
    error: any
    isLoading: boolean
  }

  const {data: dataTypes} = useSWR('/types', getTypes, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: false,
  }) as {
    data: any
  }

  useEffect(() => {
    if (error) {
      AlertFetchError(intl.locale, error).fire()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, isLoading])

  useEffect(() => {
    Splash(isLoading)
  }, [isLoading])

  useEffect(() => {
    if (!hasBeenVerified) return navigate('/account/overview')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasBeenVerified])
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: intl.formatMessage({id: 'MENU.HOME'}),
            path: '/',
            isSeparator: false,
            isActive: false,
          },
          {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.PLAYLIST'})}
      </PageTitle>
      {hasBeenVerified ? (
        <>
          <BannerHelp linkToDoc='https://docs.growker.ai/kb/playlist/' title='HELP.TITLE.PLAYLIST' text='HELP.TEXT.PLAYLIST' />
          {dataTypes && data?.data && !isLoading && (
            <Playlist references={dataTypes.data.detail} data={data.data} />
          )}
        </>
      ) : (
        <WaitingScreen />
      )}
    </>
  )
}

export {PlaylistWrapper}
