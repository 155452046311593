import rehypeSanitize from 'rehype-sanitize'
import MDEditor from '@uiw/react-md-editor'
import '../outputsEditor/styles.css'
import {ActionTypes, documentationEditorReducer, initialState} from './documentation.reduce'
import {useEffect, useReducer} from 'react'
import {ItemGlossary, State} from './types'
import {categories} from './glossary'
import {Controls, Filters, InputStyled, LabelStyled, Reset, WrapperControls} from './styled'
import Form from 'react-bootstrap/Form'
import {Button} from 'react-bootstrap'
import {
  createGlossaryItem,
  deleteGlossaryItem,
  getGlossaryData,
  updateGlossaryItem,
} from './core/_request'
import useSWR from 'swr'
import {Splash} from '../../../_gws/helpers/misc'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {useNavigate} from 'react-router-dom'

const getInitialData = async () => {
  const [data] = await Promise.all([getGlossaryData()])
  const glossary = data.data.map((item: any) => ({
    ...item,
    category: !item.category ? '' : item.category,
    categories: !item.categories ? [] : item.categories,
  })) as ItemGlossary[]
  return glossary
}

export const DocumentationEditor = () => {
  const alert = withReactContent(Swal)
  const navigate = useNavigate()
  const [state, dispatch] = useReducer<(state: State, action: any) => State>(
    documentationEditorReducer,
    initialState
  )

  const {data: glossaryData, isLoading} = useSWR('/glossary', getInitialData, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: false,
  })

  useEffect(() => {
    if (!isLoading && glossaryData) {
      dispatch({
        type: ActionTypes.SET_STATE,
        payload: {
          glossary: glossaryData,
        },
      })
    }
  }, [glossaryData, isLoading])

  useEffect(() => {
    Splash(isLoading)
  }, [isLoading])

  const handlerSave = async () => {
    if (state.item.id !== 0) {
      const updatedItem: any = {
        ...state.item,
        description: state.content,
      }
      if (state.item.category === '') updatedItem.category = null
      const updated = await updateGlossaryItem(updatedItem)
      if (updated.status === 200) {
        alert.fire({
          icon: 'success',
          iconColor: '#00c6c7',
          text: 'Actualizado con exito',
          customClass: {
            confirmButton: 'btn btn-primary',
            popup: 'bg-light',
          },
        })
      } else {
        alert.fire({
          icon: 'error',
          text: 'El titulo y el subtitulo son obligatiorios',
          customClass: {
            confirmButton: 'btn btn-primary',
            popup: 'bg-light',
          },
        })
      }
    } else {
      const newItem: any = {...state.item}
      if (state.item.category === '') delete newItem?.category
      delete newItem.id
      const response = await createGlossaryItem({
        ...newItem,
        description: state.content,
      })
      if (response.status === 201) {
        alert
          .fire({
            icon: 'success',
            iconColor: '#00c6c7',
            text: 'Elemento creado con exito',
            customClass: {
              confirmButton: 'btn btn-primary',
              popup: 'bg-light',
            },
          })
          .then(() => {
            navigate(0)
          })
      } else {
        alert.fire({
          icon: 'error',
          text: 'El titulo y el subtitulo son obligatiorios',
          customClass: {
            confirmButton: 'btn btn-primary',
            popup: 'bg-light',
          },
        })
      }
    }
  }

  const handlerEditItem = (id: number) => {
    const item = state.glossary.find((element) => element.id === id)
    dispatch({
      type: ActionTypes.EDIT_ITEM,
      payload: {
        item: item,
      },
    })
  }

  const handlerDeleteItem = async (id: number) => {
    const response = await deleteGlossaryItem(id)
    if (response.status === 204) {
      alert
        .fire({
          icon: 'success',
          iconColor: '#00c6c7',
          text: 'Eliminado con exito',
          customClass: {
            confirmButton: 'btn btn-primary',
            popup: 'bg-light',
          },
        })
        .then(() => {
          navigate(0)
        })
    }
  }

  return (
    <div>
      <WrapperControls>
        <Filters>
          <div>
            <p>Seleciona un elemento</p>
            <Form.Select
              value={state.item.id}
              onChange={(e) => handlerEditItem(parseInt(e.target.value))}
              size='sm'
            >
              {state.glossary.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.title}
                </option>
              ))}
              <option value={0}>Nuevo</option>
            </Form.Select>
          </div>
          <div style={{marginLeft: '20px'}}>
            <p>Asigna una categoria</p>
            <Form.Select
              value={state.item.category}
              onChange={(e) => {
                dispatch({
                  type: ActionTypes.CHANGE_ITEM,
                  payload: {
                    value: e.target.value,
                    key: 'category',
                  },
                })
              }}
              size='sm'
            >
              {categories.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
              <option value={''}>Ninguna</option>
            </Form.Select>
          </div>
        </Filters>
        {state.change && (
          <Controls>
            <Button size='sm' onClick={handlerSave} className='mx-5'>
              Guardar
            </Button>
            <Reset onClick={() => dispatch({type: ActionTypes.RESET_STATE})} size='sm'>
              Reiniciar
            </Reset>
          </Controls>
        )}
      </WrapperControls>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div style={{marginRight: '20px'}}>
          <LabelStyled htmlFor='title'>Titulo</LabelStyled>
          <InputStyled
            size='sm'
            type='text'
            id='title'
            aria-describedby='title'
            value={state.item.title}
            style={{width: '280px'}}
            onChange={(e) =>
              dispatch({
                type: ActionTypes.CHANGE_ITEM,
                payload: {
                  value: e.target.value,
                  key: 'title',
                },
              })
            }
          />
        </div>
        <div>
          <LabelStyled htmlFor='subtitle'>Subtitulo</LabelStyled>
          <InputStyled
            size='sm'
            type='text'
            id='subtitle'
            aria-describedby='subtitle'
            value={state.item.subtitle}
            style={{width: '280px'}}
            onChange={(e) =>
              dispatch({
                type: ActionTypes.CHANGE_ITEM,
                payload: {
                  value: e.target.value,
                  key: 'subtitle',
                },
              })
            }
          />
        </div>
        {state.item.id !== 0 && (
          <Button
            style={{
              marginTop: '25px',
            }}
            size='sm'
            onClick={() => handlerDeleteItem(state.item.id)}
            className='mx-5'
          >
            Eliminar elemento
          </Button>
        )}
      </div>
      <div style={{margin: '20px 0px'}} data-color-mode='light'>
        <MDEditor
          value={state.content}
          height={500}
          onChange={(e: any) => dispatch({type: ActionTypes.CHANGE_CONTENT, payload: e})}
          previewOptions={{
            rehypePlugins: [[rehypeSanitize]],
          }}
        />
      </div>
    </div>
  )
}
