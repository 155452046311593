import styled from '@emotion/styled'
import ReactApexChart from 'react-apexcharts'
import {abbreviateNumber} from '../../../../../_gws/helpers/misc'
import {useIntl} from 'react-intl'
import {serieChart} from '../../core/types'

const Container = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '1fr',
  '@media (max-width: 760px)': {
    gridTemplateColumns: '1fr',
  },
})

const generateChartsOptions = ({xaxis, series}: {xaxis: string[]; series: any[]}) => {
  return {
    series: series,
    options: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: '40%',
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: xaxis,
        tooltip: {
          enabled: false,
          offsetX: 0,
        },
      },
      yaxis: {
        labels: {
          formatter: (value: any) => {
            return abbreviateNumber(value, 2)
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        enabled: true,
        x: {
          show: true,
        },
        y: {
          show: true,
          formatter: function (value: any) {
            return abbreviateNumber(value, 2)
          },
        },
        z: {
          show: true,
        },
        marker: {
          show: false,
        },
        onDatasetHover: {
          highlightDataSeries: false,
        },
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'left',
        itemMargin: {
          horizontal: 10,
          vertical: 20,
        },
      },
    },
  }
}

export const ContentMerketing = ({
  report,
}: {
  report: {
    series: serieChart[]
    xaxis: string[]
    impressions: {
      series: serieChart[]
      xaxis: string[]
    }
  }
}) => {
  const intl = useIntl()
  report.series[0].name = intl.formatMessage({id: 'REPORT.PAID_MARKETING.CLICKS'})
  report.series[1].name = intl.formatMessage({id: 'REPORT.PAID_MARKETING.CONVERTIONS'})
  report.impressions.series[0].name = intl.formatMessage({id: 'REPORT.PAID_MARKETING.IMPRESSIONS'})

  const marketingOptions: any = generateChartsOptions({
    xaxis: report.xaxis,
    series: report.series,
  })

  const marketingImpressionsOptions: any = generateChartsOptions({
    xaxis: report.impressions.xaxis,
    series: report.impressions.series,
  })
  return (
    <Container>
      <div>
        <div>
          <h4
            style={{
              color: '#212121',
            }}
          >
            {intl.formatMessage({id: 'MENU.REPORTS.MARKETING'})}
          </h4>
          <p>{intl.formatMessage({id: 'REPORT.SALES.MONTHLY_DETAILS'})}</p>
        </div>
        <ReactApexChart
          options={marketingOptions.options}
          series={marketingOptions.series}
          type='area'
          height={340}
        />
      </div>
      <div>
        <div>
          <h4
            style={{
              color: '#212121',
            }}
          >
            {intl.formatMessage({id: 'REPORT.PAID_MARKETING.IMPRESSIONS'})}
          </h4>
          <p>{intl.formatMessage({id: 'REPORT.SALES.MONTHLY_DETAILS'})}</p>
        </div>
        <ReactApexChart
          options={marketingImpressionsOptions.options}
          series={marketingImpressionsOptions.series}
          type='area'
          height={340}
        />
      </div>
    </Container>
  )
}
