import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_gws/layout/core'
import {WaitingScreen} from '../../../_gws/partials/content/waiting/WaitingScreen'
import {PerformaceMonitor} from './PerformaceMonitor'
import {HeaderReport} from '../../modules/apps/header/HeaderReport'
import {useAuth} from '../../modules/auth'
import useSWR from 'swr'
import {getBestSellerByCategory} from './core/_request'
import {useEffect, useState} from 'react'
import {Splash} from '../../../_gws/helpers/misc'

const MonitorWrapper = () => {
  const intl = useIntl()
  const hasBeenVerified = localStorage.getItem('verified') === 'true'
  const {currentMkp, setCurrentMkp} = useAuth()
  const [category, setCategory] = useState('9298576011')
  const swrOptions = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: false,
  }

  const {data, isLoading} = useSWR(
    () => (currentMkp ? ['/bestsellers/', category, currentMkp] : null),
    () =>
      getBestSellerByCategory({
        category: category,
        currentMkp
      }),
    {
      ...swrOptions,
    }
  )

  useEffect(() => {
    Splash(isLoading)
  }, [isLoading])

  const handlerCategory = (category: string) => setCategory(category)
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: intl.formatMessage({id: 'MENU.HOME'}),
            path: '/',
            isSeparator: false,
            isActive: false,
          },
          {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.MONITOR'})}
      </PageTitle>
      {hasBeenVerified ? (
        <>
          <HeaderReport
            hasMarketPlace={true}
            hasDate={false}
            hasFilter={false}
            growkerMetric={true}
            setMkplace={setCurrentMkp}
            activeMkp={currentMkp}
          />
          {data && (
            <PerformaceMonitor
              category={{
                value: category,
                handlerCategory: handlerCategory,
              }}
              data={data.data}
            />
          )}
        </>
      ) : (
        <WaitingScreen />
      )}
    </>
  )
}

export {MonitorWrapper}
