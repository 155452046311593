import {useMemo, useState} from 'react'
import {
  IndexedReferences,
  Months,
  PlaylistActions,
  ReferenceType,
  Task,
  priorityPlaylist,
  statusPlaylist,
  typePlaylist,
} from '../../types'
import {Cell, ContentCell, HeaderCell, Table, WrapperTable} from '../styled'
import {RenderSelectAll} from './components/SelectAll'
import {Pagination} from './components/Pagination'
import {RenderSelect} from './components/RenderSelect'
import {RenderTitle} from './components/RenderTitle'
import {RenderDescription} from './components/RenderDescription'
//import {RenderReference} from './components/RenderReference'
import {RenderDate} from './components/RenderDate'
import {RenderDeadline} from './components/RenderDeadline'
import {RenderStatus} from './components/RenderStatus'
import {RenderOrigin} from './components/RenderOrigin'
import {RenderPriority} from './components/RenderPriority'
import {RenderMarketplace} from './components/RenderMarketplace'
import {makeURL} from '../../../../../_gws/helpers/misc'
import parse from 'html-react-parser'
import {TYPETASK} from '../../../../constants/task'
import {PlaylistEditTask} from '../PlaylistCreateEdit/PlaylistEdit'
import {RenderNote} from './components/RenderNote'

type PlaylistColumns = {
  field: string
}
const InitialState: Task = {
  id: 113,
  item: '',
  type: 'OUTPUT',
  notes: null,
  title: '',
  status: 1,
  usersId: 501,
  deadline: '',
  priority: 2,
  createdAt: '',
  deletedAt: null,
  updatedAt: '',
  resolvedAt: null,
  description: '',
  referenceId: 1,
  marketplaceId: 1,
}
export const makeLink = (asin: any, marketplace: any) => {
  const link = makeURL(asin, marketplace)
  return link && parse(link)
}

const columns: PlaylistColumns[] = [
  {
    field: 'select',
  },
  {
    field: 'Marketplace',
  },
  {
    field: 'ID del producto',
  },
  {
    field: 'Tarea',
  },
  {
    field: 'Descripción',
  },
  {
    field: 'Notas',
  },
  {
    field: 'Prioridad',
  },
  {
    field: 'Estatus',
  },
  {
    field: 'Fecha creación',
  },
  {
    field: 'Fecha límite',
  },
  {
    field: 'Origen',
  },
]

const verifiedChecket = (id: number, selectedItems: Task[]) => {
  const find = selectedItems.filter((item: Task) => item.id === id)
  return find.length > 0 ? true : false
}

export const PlaylistTable = ({
  month,
  year,
  tasks,
  indexTable,
  actions,
  selectedItems,
  references,
  optionReferences,
}: {
  month: number
  year: number
  tasks: Task[]
  indexTable: number
  actions: PlaylistActions
  selectedItems: Task[]
  references: IndexedReferences
  optionReferences: ReferenceType[]
}) => {
  let PageSize = 5
  const [showEdit, setShowEdit] = useState(false)
  const [currentItem, setCurrentItem] = useState<Task>(InitialState)
  const [currentPage, setCurrentPage] = useState(1)

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    return tasks.slice(firstPageIndex, lastPageIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, tasks])

  const handlerOpenEdit = (item: Task) => {
    setCurrentItem(item)
    setShowEdit(true)
  }

  return (
    <WrapperTable>
      {currentTableData.length > 0 && <h6>{Months[month]}</h6>}
      <Table>
        {currentTableData.length > 0 && (
          <tr>
            {columns.map((item: PlaylistColumns) => (
              <HeaderCell key={item.field}>
                {item.field === 'select' ? (
                  <RenderSelectAll indexTable={indexTable} selectAction={actions.selectAll} />
                ) : (
                  item.field
                )}
              </HeaderCell>
            ))}
          </tr>
        )}

        {currentTableData.length > 0 &&
          currentTableData.map((item: Task, i: number) => (
            <ContentCell key={i}>
              <Cell>
                <RenderSelect
                  action={actions.selectOneItem}
                  id={item.id}
                  index={indexTable}
                  checked={verifiedChecket(item.id, selectedItems)}
                />
              </Cell>
              <Cell event onClick={() => handlerOpenEdit(item)}>
                <RenderMarketplace marketplace={item.marketplaceId} />
              </Cell>
              <Cell>
                {item.type === TYPETASK.OUTPUT
                  ? makeLink(item?.item ?? '', item.marketplaceId)
                  : item?.item ?? ''}
              </Cell>
              <Cell event onClick={() => handlerOpenEdit(item)}>
                <RenderTitle title={item?.title ? item.title : ''} />
              </Cell>
              <Cell event style={{ width: "200px" }}>
                <RenderDescription
                  marketplaceId={item.marketplaceId}
                  item={item.item}
                  type={item.type}
                  description={item.description}
                  priority={item.priority}
                  reference={item.referenceId}
                />
              </Cell>
              <Cell event style={{ width: "200px" }} onClick={() => handlerOpenEdit(item)}>
                <RenderNote note={item.notes || ''} />
              </Cell>
              {/**
              <Cell event onClick={() => handlerOpenEdit(item)}>
                <RenderReference reference={item.referenceId} references={references} />
              </Cell>
               */}
              <Cell
                event
                onClick={() => handlerOpenEdit(item)}
                bg={priorityPlaylist[item.priority].color}
              >
                <RenderPriority priority={item.priority} />
              </Cell>
              <Cell
                event
                onClick={() => handlerOpenEdit(item)}
                bg={statusPlaylist[item.status].color}
              >
                <RenderStatus status={item.status} />
              </Cell>
              <Cell event onClick={() => handlerOpenEdit(item)}>
                <RenderDate date={item.createdAt} />
              </Cell>
              <Cell>
                <RenderDeadline
                  indexTable={indexTable}
                  updateTasksAction={actions.updateTasks}
                  item={item}
                />
              </Cell>
              <Cell event onClick={() => handlerOpenEdit(item)} bg={typePlaylist[item.type].color}>
                <RenderOrigin origin={item.type} />
              </Cell>
            </ContentCell>
          ))}
      </Table>
      <PlaylistEditTask
        references={optionReferences}
        show={showEdit}
        handleClose={() => setShowEdit(false)}
        item={currentItem}
        updateTasks={actions.updateTasks}
        indexTable={indexTable}
      />
      <Pagination
        currentPage={currentPage}
        totalCount={tasks.length}
        pageSize={PageSize}
        onPageChange={(page: any) => setCurrentPage(page)}
      />
    </WrapperTable>
  )
}
