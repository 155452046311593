import {useListView} from '../core/ListViewProvider'
import {KTSVG} from '../../../../_gws/helpers'
import {useIntl} from 'react-intl'

/**
 * Header of the modal
 * @constructor
 */
const LandedCostEditModalHeader = () => {
  const intl = useIntl()
  const {setItemIdForUpdate, itemIdForUpdate} = useListView()
  return (
    <div className='modal-header border-0'>
      <h2 className='fw-bolder'>
        {itemIdForUpdate
          ? intl.formatMessage({id: 'LANDED_COST.MODAL.EDIT'})
          : intl.formatMessage({id: 'LANDED_COST.MODAL.NEW'})}
      </h2>
      <button
        type='button'
        data-bs-dismiss='modal'
        className='btn btn-icon btn-sm btn-active-icon-primary'
        onClick={() => setItemIdForUpdate(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/unicos/008-exit.svg' className='svg-icon-1' />
      </button>
    </div>
  )
}

export {LandedCostEditModalHeader}
