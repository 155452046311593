import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_gws/layout/core'
import {useAuth} from '../../modules/auth'
import {DashboardMerchantContainer} from './DashboardMerchantContainer'
import UsersPage from '../../modules/apps/user-management/UsersPage'
import {DashboardClientContainer} from './DashboardClientContainer'

const DashboardWrapper = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      {/*Usuario Cliente*/}
      {currentUser?.roleId === 1 && <DashboardClientContainer />}
      {/*Usuario Admin*/}
      {currentUser?.roleId === 2 && <UsersPage />}
      {/*Usuario merchant*/}
      {currentUser?.roleId === 3 && <DashboardMerchantContainer />}
    </>
  )
}

export {DashboardWrapper}
