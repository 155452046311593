import {useEffect, useReducer} from 'react'
import rehypeSanitize from 'rehype-sanitize'
import MDEditor from '@uiw/react-md-editor'
import {Button} from 'react-bootstrap'
import {Controls, Filters, Reset, WrapperControls, WrapperGrid} from './styled'
import Form from 'react-bootstrap/Form'
import {outputs} from './outputs'
import './styles.css'
import {
  ActionTypes,
  MkpKeys,
  PriorityKeys,
  indexedOutputs,
  initialState,
  mkpValues,
  outputsEditorReducer,
  priorityValues,
} from './outputsEditor.reducer'
import {State} from './types'
import {Modal} from 'react-bootstrap'
import CloseButton from 'react-bootstrap/CloseButton'

export const AddOutput = ({
  showModal,
  handlerClouse,
  dispatch,
  state,
}: {
  showModal: boolean
  handlerClouse: any
  dispatch: any
  state: State
}) => {
  const handlerSetNewOutput = () => dispatch({type: ActionTypes.SET_NEW_OUTPUT})
  return (
    <Modal show={showModal} dialogClassName='modal-dialog-centered'>
      <Modal.Header className={'justify-content-between px-10 py-5'}>
        <h4>Crear output</h4>
        <CloseButton onClick={handlerClouse} />
      </Modal.Header>
      <Modal.Body className='px-10 py-5'>
        <WrapperGrid>
          <div>
            <p>Ingrese el output name</p>
            <Form.Control
              value={state.newOutput.output}
              onChange={(e) =>
                dispatch({
                  type: ActionTypes.HANDLER_CHANGE_NEW_OUTPUT,
                  payload: {
                    content: e.target.value,
                    key: 'output',
                  },
                })
              }
              size='sm'
              type='text'
              aria-describedby='title'
            />
          </div>
          <div>
            <p>Ingrese el titulo</p>
            <Form.Control
              value={state.newOutput.title}
              onChange={(e) =>
                dispatch({
                  type: ActionTypes.HANDLER_CHANGE_NEW_OUTPUT,
                  payload: {
                    content: e.target.value,
                    key: 'title',
                  },
                })
              }
              size='sm'
              type='text'
              aria-describedby='title'
            />
          </div>
        </WrapperGrid>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button onClick={handlerSetNewOutput} size='sm'>
            Establecer
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export const EditorOutputs = () => {
  const [state, dispatch] = useReducer<(state: State, action: any) => State>(
    outputsEditorReducer,
    initialState
  )

  useEffect(() => {
    dispatch({
      type: ActionTypes.SET_STATE,
      payload: {
        indexed: indexedOutputs(outputs),
        outputs: outputs.filter((item) => item.id !== null),
      },
    })
  }, [])

  // The number 159357 is an arbitrary number designated to create a new output, since the id is generated in the backend
  const handlerSave = () =>
    console.log({
      output: state.indexedOutputs[state.output].output,
      id: state.indexedOutputs[state.output].id,
      priority: PriorityKeys[state.priority],
      mkp: MkpKeys[state.mkp],
      content: state.content,
    })
  return (
    <div>
      <WrapperControls>
        <Filters>
          <div>
            <p>Seleciona el output</p>
            <Form.Select
              onChange={(e) => dispatch({type: ActionTypes.SET_OUTPUT, payload: e.target.value})}
              value={state.output}
              size='sm'
            >
              {state.outputs.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.output}
                </option>
              ))}
            </Form.Select>
          </div>
          <div style={{marginLeft: '20px'}}>
            <p>Seleciona su prioridad</p>
            <Form.Select
              onChange={(e) => dispatch({type: ActionTypes.SET_PRIORITY, payload: e.target.value})}
              size='sm'
            >
              {priorityValues.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.name}
                </option>
              ))}
            </Form.Select>
          </div>
          <div style={{marginLeft: '20px'}}>
            <p>Seleciona el marketplace</p>
            <Form.Select
              onChange={(e) => dispatch({type: ActionTypes.SET_MKP, payload: e.target.value})}
              size='sm'
            >
              {mkpValues.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.name}
                </option>
              ))}
            </Form.Select>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              marginLeft: '20px',
            }}
          >
            <Button onClick={() => dispatch({type: ActionTypes.HANDLER_MODAL})} size='sm'>
              Crear output
            </Button>
          </div>
        </Filters>
        {state.change && (
          <Controls>
            <Reset onClick={() => dispatch({type: ActionTypes.RESET_STATE})} size='sm'>
              Reiniciar
            </Reset>
            <Button size='sm' onClick={handlerSave} className='mx-5'>
              Guardar
            </Button>
          </Controls>
        )}
      </WrapperControls>
      <div data-color-mode='light'>
        <MDEditor
          value={state.content}
          height={500}
          onChange={(e: any) => dispatch({type: ActionTypes.CHANGE_CONTENT, payload: e})}
          previewOptions={{
            rehypePlugins: [[rehypeSanitize]],
          }}
        />
      </div>
      <AddOutput
        showModal={state.showCreateOutput}
        handlerClouse={() => dispatch({type: ActionTypes.HANDLER_MODAL})}
        dispatch={dispatch}
        state={state}
      />
    </div>
  )
}
