import axios from 'axios'
import {getTokenHeader} from '../../../modules/auth'

const API_URL_CRUNCH = process.env.REACT_APP_BACKEND_MAIN_API
/**
 * API endpoint for Product Tracker Report
 */
export const REQUEST_PRODUCT_TRACKER_URL = `${API_URL_CRUNCH}/api/reports/product-tracker/`

/**
 * It takes a string, a string, and a number, and returns a string.
 * @param {string} mkp - The marketplace you want to get the data from.
 * @param {string} query - The query string to search for.
 * @param {number} year - The year you want to get the data for.
 */
export function getProductTracker(mkp: string, query: string, year: number) {
  const axiosClean = axios.create()
  return axiosClean.post(
    REQUEST_PRODUCT_TRACKER_URL + mkp,
    {
      query: query,
      year: year,
    },
    // @ts-ignore
    getTokenHeader()
  )
}
