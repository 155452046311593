import {MARKETPLACES} from '../../../constants/marketplaces'
import {getTypes} from '../../playlist/core/_requests'
import {getBrandHealthDataByCategory} from './_requests'

export type Indicator = {
  brand: string
  category: string
  current_rating: true
  description: string
  expected_rating: false
  health_status: number
  id: string
  image: string
  in_playlist: true
  indicator: string
  item_type_id: number
  marketplace_id: number
  name: string
}
export type Field = 'item_type_id' | 'health_status'

export type Filters = {
  field: Field
  value: number | string
}

export type ReferenceType = {
  value: number
  label: string
  flag: boolean
}

export type ParamsType = {
  initialMkp: string
  category: string
}

const API_URL_CRUNCH = process.env.REACT_APP_BACKEND_MAIN_API

const generateBrandHealthUrlApi = ({
  initialMkp,
  category,
  priority,
  type,
  mkpFilter,
  currentPage,
  stockless
}: {
  initialMkp: string
  category: number
  priority: string | null
  type: string | null
  currentPage: number,
  mkpFilter: string | null,
  stockless: string | null
}) => {
  const marketplaceId = Object.values(MARKETPLACES).find((mk) => mk.name === initialMkp)?.id
  const BASE_MKP = `category?marketplaceId=${marketplaceId}`
  const BASE_CATEGORY = `&categoryId=${category}`
  const SIZE = `&pageSize=10`
  const PAGE = `&page=${currentPage}`
  const OUTPUT = type ? `&output=${type}` : ``
  const PRIORITY = priority ? `&priority=${priority}` : ``
  const MKP_FILTER = mkpFilter ? `&marketplaceFilter=${mkpFilter}` : ``
  const STOCKLESS = stockless !== null ? `&stockless=${stockless}` : ``
  const URL_API = `${API_URL_CRUNCH}/api/brand-health/${BASE_MKP}${BASE_CATEGORY}${SIZE}${MKP_FILTER}${STOCKLESS}${OUTPUT}${PRIORITY}${PAGE}`
  return URL_API
}

export const getBrandhealthIndicators = async (params: {
  initialMkp: string
  category: number
  priority: string | null
  type: string | null
  currentPage: number,
  mkpFilter: string | null,
  stockless: string | null
}) => {
  const URL = generateBrandHealthUrlApi(params)
  const [data, dataTypes] = await Promise.all([
    getBrandHealthDataByCategory(URL),
    getTypes(),
  ])
  const indicators: Indicator[] = data.data.category.indicators
  return {
    total_indicators: data.data.category.total_indicators as number,
    urgentes: data.data.category.urgentes,
    importantes: data.data.category.importantes,
    references: dataTypes.data.detail as ReferenceType[],
    indicators: indicators,
  }
}
