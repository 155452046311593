/* eslint-disable jsx-a11y/anchor-is-valid,jsx-a11y/aria-role */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {recovery} from '../core/_requests'
import {useIntl} from 'react-intl'
import {AgTitleCard} from '../../../../_gws/layout/components/ag/components/AgTitleCard'
import styled from '@emotion/styled'
import {Spinner} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../_gws/helpers'

const BtnLogin = styled('button')({
  width: '100%',
  height: '50px',
  borderRadius: '8px',
  cursor: 'pointer',
  color: '#fff',
  background: '#212121',
  fontSize: '1.2rem',
  fontWeight: 'bold',
  border: 'none',
})

/**
 * Recovery form | Use Formik for form management and Yup for form validation.
 * @component
 */
export function Recovery() {
  const intl = useIntl()

  /**
   * Schema for validation inputs (Using Yup)
   */
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: 'AUTH.VALIDATION.WRONG_EMAIL_FORMAT'}))
      .required(intl.formatMessage({id: 'AUTH.VALIDATION.EMAIL_REQUIRED'})),
  })

  /**
   * Form initial values for Formik
   */
  const initialValues = {
    email: '',
  }

  /**
   * Show/Hide loading in button
   */
  const [loading, setLoading] = useState(false)

  /**
   * Formik Implementation Login Form
   * @param initialValues - Array for default values
   * @param validationSchema - Yup array for validations
   */
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        setLoading(true)
        const response = await recovery(values.email)
        if (response.data.status === 200) {
          setStatus(response.data.status)
          setLoading(false)
          setSubmitting(false)
        }
      } catch (error: any) {
        setStatus(error.response.data.status)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <div
      style={{
        height: '50%',
        display: 'flex',
        width: '100%',
        flexFlow: 'column',
        padding: '0px 70px',
      }}
    >
      <div style={{marginBottom: '10%'}}>
        <a href='https://growker.ai' target='_blank' rel='noreferrer'>
          <img
            style={{width: '150px'}}
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/logo-growker.svg')}
          />
        </a>
      </div>

      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {formik.status === 200 ? (
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <div className='text-center'>
              <AgTitleCard
                customClass={'mb-3 fs-2 fw-bold'}
                text={intl.formatMessage({id: 'AUTH.GENERAL.RECOVERY.SENT'})}
              />
            </div>

            <div className='fv-row d-flex flex-column justify-content-center align-items-center'>
              <AgTitleCard
                customClass='text-gray-550 text-center'
                text={intl.formatMessage({id: 'AUTH.GENERAL.RECOVERY.SUCCESS'})}
              />
            </div>

            <div className='d-flex w-75 justify-content-center mt-20'>
              {/* button back to login */}
              <button
                type='button'
                className={`w-100 rounded-2 justify-content-center px-5 py-2 bg-primary fw-bolder text-uppercase`}
                style={{
                  boxShadow: '3px 3px 0px 0px #212121',
                }}
              >
                <a href='/auth' className='text-black'>
                  {intl.formatMessage({id: 'AUTH.GENERAL.RECOVERY.BACK_TO_LOGIN'})}
                </a>
              </button>
            </div>
          </div>
        ) : (
          <>
            <h4 style={{fontSize: '1.5rem'}}>
              {intl.formatMessage({id: 'AUTH.GENERAL.FORGOT_BUTTON'})}
            </h4>
            <p style={{marginTop: '10px', color: '#B6B6B6'}}>
              {intl.formatMessage({id: 'AUTH.GENERAL.RECOVERY.LABEL'})}
            </p>

            {/** Show error message if the user is not registered */}
            {formik.status === 404 && (
              <div className='mb-5 fw-normal text-danger'>
                {intl.formatMessage({id: 'AUTH.GENERAL.RECOVERY.FAIL.NOT_REGISTERED'})}
              </div>
            )}
            <div className='fv-row mb-10 d-flex flex-column justify-content-center'>
              <input
                {...formik.getFieldProps('email')}
                className={clsx(
                  ' form-control form-control-lg form-control-solid rounded-1 bg-transparent border-black text-black',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
                type='email'
                name='email'
                autoComplete='off'
                id='email'
                placeholder={intl.formatMessage({id: 'AUTH.GENERAL.RECOVERY.PLACEHOLDER'})}
              />
              {formik.touched.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>
            <div>
              {/* button recuperar */}
              <div>
                <BtnLogin
                  type='submit'
                  id='kt_sign_in_submit'
                  role='submit'
                  disabled={formik.isSubmitting || !formik.isValid || formik.status === 200}
                >
                  {/** show button text when the form is ready to submit */}
                  {!loading &&
                    formik.status !== 200 &&
                    formik.status !== 404 &&
                    intl.formatMessage({id: 'AUTH.GENERAL.RECOVERY.BUTTON'})}
                  {/** show button text loading when the form is submitting */}
                  {loading && (
                    <Spinner size='sm' animation='border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                  )}
                  {!loading &&
                    formik.status !== 200 &&
                    formik.status === 404 &&
                    intl.formatMessage({id: 'PLAYLIST.MODAL.BUTTON.ACCEPT'})}
                </BtnLogin>
              </div>
            </div>
          </>
        )}
      </form>
    </div>
  )
}
