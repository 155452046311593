/* eslint-disable jsx-a11y/anchor-is-valid */
import {Route, Routes} from 'react-router-dom'
import {BusinessInfo} from './components/BusinessInfo'
import {Container, ContainerImg, ContentImg, Wrapper} from './PaymentPage'
import {toAbsoluteUrl} from '../../../_gws/helpers'
import {useIntl} from 'react-intl'
/**
 * Auth Layout for the login form and the other elements outside the form
 * @component
 */

const BusinessInfoLayoud = () => {
  const intl = useIntl()
  return (
    <Container>
      <Wrapper
      >
        <div
          style={{
            display: 'grid',
            placeItems: 'center',
          }}
        >
          <BusinessInfo />
        </div>
        <ContainerImg>
          <ContentImg>
            <img alt='Atro' src={toAbsoluteUrl('/media/backgrounds/Artboard-payment-form.png')} />
            <h4>{intl.formatMessage({id: 'BUSINESS.PAGE.FORM.TITLE'})}</h4>
            <p>{intl.formatMessage({id: 'BUSINESS.PAGE.FORM.TEXT'})}</p>
            <img
              style={{width: '200px', marginTop: '10px'}}
              alt='Logo'
              src={toAbsoluteUrl('/media/backgrounds/early-access.png')}
            />
          </ContentImg>
        </ContainerImg>
      </Wrapper>
    </Container>
  )
}
const BusinessInfoPage = () => (
  <Routes>
    <Route element={<BusinessInfoLayoud />}>
      <Route index element={<BusinessInfo />} />
    </Route>
  </Routes>
)

export {BusinessInfoPage}
