import {useState} from 'react'
import {Button} from 'react-bootstrap'
import {Wrapper} from './styled'
import {ReferenceType} from '../types'
import { PlaylistCreateTask } from './PlaylistCreateEdit/PlaylistCreate'
import { useIntl } from 'react-intl'

export const CreateTask = ({
  references,
  updateTasks,
}: {
  references: ReferenceType[]
  updateTasks: any
}) => {
  const intl = useIntl()
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  return (
    <Wrapper>
      <Button
        style={{
          background: '#0DBDA6',
          color: '#fff',
        }}
        size='sm'
        onClick={handleShow}
      >
        {intl.formatMessage({id: 'PLAYLIST.HEADER.NEW.TASK'})}
      </Button>
      <PlaylistCreateTask
        references={references}
        show={show}
        handleClose={() => setShow(false)}
        updateTasks={updateTasks}
      />
    </Wrapper>
  )
}
