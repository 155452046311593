import React from 'react'
import {SettingsAside} from './groupAsideSaas/SettingsAside'
import {HomeAside} from './groupAsideSaas/HomeAside'
import {AppsAside} from './groupAsideSaas/AppsAside'

const AdminAside = () => {
  return (
    <>
      <HomeAside />
      <AppsAside />
      <SettingsAside />
    </>
  )
}

export {AdminAside}
