import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import {ThemeModeProvider} from '../partials'
import {MenuComponent} from '../assets/ts/components'

const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
    // let validationScriptChatbot1 = document.getElementById('chatbot')
    // let validationScriptChatbot2 = document.getElementById('sbinit')
    // if (!validationScriptChatbot1 && !validationScriptChatbot2) {
    //   const scriptChatBot1 = document.createElement('script')
    //   scriptChatBot1.type = 'text/javascript'
    //   scriptChatBot1.id = 'chatbot'
    //   scriptChatBot1.src = 'https://mentor.growker.ai/js/min/jquery.min.js'
    //
    //   document.body.appendChild(scriptChatBot1)
    //   const scriptChatBot2 = document.createElement('script')
    //   scriptChatBot2.type = 'text/javascript'
    //   scriptChatBot2.id = 'sbinit'
    //   scriptChatBot2.src = 'https://mentor.growker.ai/js/main.js'
    //
    //   document.body.appendChild(scriptChatBot2)
    // }
  }, [location.key])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='page d-flex flex-row flex-column-fluid'>
          <AsideDefault />
          <div
            style={{background: '#FFFFFF'}}
            className='wrapper d-flex flex-column flex-row-fluid'
            id='kt_wrapper'
          >
            <HeaderWrapper />
            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              <div className='post d-flex flex-column-fluid' id='kt_post'>
                <Content>
                  <Outlet />
                </Content>
              </div>
            </div>
            <Footer />
          </div>
        </div>

        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
