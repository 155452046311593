import {useIntl} from 'react-intl'
import {CardChart, ContainerCharts} from './styled'
import ReactApexChart from 'react-apexcharts'
import {ReportStrategyData} from '../core/types'
import { abbreviateNumber, addCommasInNumber } from '../../../../_gws/helpers/misc'
const generateChartsOptions = ({
  xaxis,
  series,
  income,
}: {
  xaxis: number[]
  series: any[]
  income: boolean
}) => {
  return {
    series: series,
    options: {
      colors: ['#074366', '#99B36E', '#46A4B3'],
      chart: {
        height: 350,
        type: 'line',
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [5, 3, 3],
        curve: 'straight',
        dashArray: [0, 8, 5],
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
      },
      xaxis: {
        categories: xaxis,
        tooltip: {
          enabled: false,
          offsetX: 0,
        },
      },
      yaxis: {
        labels: {
          formatter: (value: any) => {
            return abbreviateNumber(value, 2)
          },
        },
      },
      grid: {
        borderColor: '#f1f1f1',
      },
      tooltip: {
        y: {
          show: true,
          formatter: function (value: any) {
            return income ? '$' + addCommasInNumber(value) : value
          },
        },
      },
    },
  }
}
export const StrategyCharts = ({report}: {report: ReportStrategyData}) => {
  const intl = useIntl()
  const salesChartOptions: any = generateChartsOptions({
    series: [
      {
        name: intl.formatMessage({id: 'REPORT.SALES.ORDERS'}),
        data: report.salesChartData.data,
      },
      {
        name: intl.formatMessage({id: 'STRATEGY.TARGET.CHART.TENDS'}),
        data: report.salesChartData.tendency,
      },
      {
        name: intl.formatMessage({id: 'STRATEGY.TARGET.SALES'}),
        data: report.salesChartData.target,
      },
    ],
    xaxis: report.labels,
    income: false,
  })
  const profitChartOptions: any = generateChartsOptions({
    series: [
      {
        name: intl.formatMessage({id: 'STRATEGY.TARGET.CHART.PROFIT'}),
        data: report.profitChartData.data,
      },
      {
        name: intl.formatMessage({id: 'STRATEGY.TARGET.CHART.PROFIT.TENDS'}),
        data: report.profitChartData.tendency,
      },
      {
        name: intl.formatMessage({id: 'STRATEGY.TARGET.PROFIT'}),
        data: report.profitChartData.target,
      },
    ],
    xaxis: report.labels,
    income: true,
  })
  return (
    <ContainerCharts>
      <CardChart>
        <h4>{intl.formatMessage({id: 'STRATEGY.TARGET.CHART.PROFIT'})}</h4>
        <ReactApexChart
          options={profitChartOptions.options}
          series={profitChartOptions.series}
          type='line'
          height={350}
        />
      </CardChart>
      <CardChart>
        <h4>{intl.formatMessage({id: 'STRATEGY.TARGET.CHART.SALES'})}</h4>
        <ReactApexChart
          options={salesChartOptions.options}
          series={salesChartOptions.series}
          type='line'
          height={350}
        />
      </CardChart>
    </ContainerCharts>
  )
}
