/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import CountUp from 'react-countup'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {abbreviateNumber} from '../../../helpers/misc'
import {useIntl} from 'react-intl'

type Props = {
  className: string
  color: string
  svgIcon: string
  iconColor?: string
  title?: string
  titleColor?: string
  progressColor?: string
  description?: string
  descriptionColor?: string
  backgroundShape?: any
  height?: string
  tooltip?: string
  countUp?: boolean
  progress: string
  total?: string
}

const StatisticsWidget6: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
  backgroundShape,
  tooltip,
  countUp,
  progress,
  progressColor,
  total,
}) => {
  const intl = useIntl()

  const bgShape =
    backgroundShape === undefined
      ? toAbsoluteUrl('media/svg/shapes/abstract-4-opacity.svg')
      : toAbsoluteUrl(backgroundShape)

  const countUpState = countUp !== true ? false : true
  return (
    <div
      className={`card bg-${color} hoverable ${className}`}
      style={{
        backgroundImage: `url(${bgShape})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top right',
      }}
    >
      <div className='card-body'>
        <div className='row h-100'>
          <div className='col-3 d-flex justify-content-center align-items-center '>
            <KTSVG path={svgIcon} className={`svg-icon-${iconColor} svg-icon-3x ms-n1`} />
          </div>
          <div className='col-9 d-flex flex-column align-items-start justify-content-center'>
            <div className={`text-${titleColor} fw-bold fs-1`}>
              {countUpState && <CountUp end={Number(title)} separator={','} />}
              {!countUpState && title}
            </div>
            <div className={`fw-semibold text-${descriptionColor}`}>
              {description}
              <OverlayTrigger
                placement='top'
                overlay={<Tooltip id={`tooltip-top`}>{tooltip}</Tooltip>}
                defaultShow={false}
              >
                <div
                  data-balloon-pos='up'
                  aria-label={tooltip}
                  className={`progress h-7px bg-${progressColor} bg-opacity-50 mt-7`}
                >
                  <div
                    className={`progress-bar bg-${progressColor}`}
                    role='progressbar'
                    style={{width: progress}}
                  />
                </div>
              </OverlayTrigger>
              <small>
                {progress} {intl.formatMessage({id: 'STRATEGY.FROM'})}{' '}
              </small>
              <small className={`text-${iconColor}`}>
                {abbreviateNumber(Number(total), 2)}&nbsp;&nbsp;
                <KTSVG
                  path={'/media/icons/duotune/maps/map001.svg'}
                  className={`svg-icon-${iconColor} svg-icon-1x ms-n1`}
                />
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {StatisticsWidget6}
