import React, {FC, lazy, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_gws/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_gws/assets/ts/_utils'
import {WithChildren} from '../../_gws/helpers'
import UsersPage from '../modules/apps/user-management/UsersPage'
import {FrankieWrapper} from '../pages/frankie/FrankieWrapper'
import {useAuth} from '../modules/auth'
import {BrandHealthWrapper} from '../pages/brandHealth/BrandHealthWrapper'
import {SalesStrategyWrapper} from '../pages/salesStrategy/SalesStrategyWrapper'
import {InventoryWrapper} from '../pages/inventoryReport/InventoryWrapper'
import {ProductTrackerWrapper} from '../pages/productTrackerReport/ProductTrackerWrapper'
import {SalesWrapper} from '../pages/salesReport/SalesWrapper'
import {PaidMarketingWrapper} from '../pages/paidMarketingReport/PaidMarketingWrapper'
import {AuditWrapper} from '../modules/apps/audit/AuditWrapper'
import CalendarBuilder from '../pages/calendar/CalendarWrapper'
import ChatDrawer from '../pages/chat/ChatDrawer'
import TicketWrapper from '../pages/tickets/TicketWrapper'
//import MentorsWrapper from '../pages/mentors/MentorsWrapper'
import GlossaryWrapper from '../pages/glossary/GlossaryWrapper'
import TestSheet from '../../_gws/partials/newComponets/TestSheet'
import {ConfirmMeli} from '../pages/maketplaces/confirmPages/ConfirmMeli'
import {ConfirmAmazon} from '../pages/maketplaces/confirmPages/ConfirmAmazon'
import {ConfirmAmazonADS} from '../pages/maketplaces/confirmPages/ConfirmAmazonADS'
//import {AfterLogin} from './AfterLogin'
import {PlaylistWrapper} from '../pages/playlist/PlaylistWrapper'
import {BrandHealthIndicatorWrapper} from '../pages/brandHealth/subindicators/BrandHealthIndicatorWrapper'
import {ProduchtHealthWrapper} from '../pages/productHealth/ProduchtHealthWrapper'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
//import {BeeperWrapper} from '../pages/beeper/BeeperWrapper'
import {DiagnostikWrapper} from '../pages/diagnostik/DiagnostikWrapper'
import {OutputsWrapper} from '../pages/outputs/OutputsWrapper'
import {MonitorWrapper} from '../pages/monitor/MonitorWrapper'
import {BeeperWrapper} from '../pages/beeper/BeeperWrapper'
import { DocumentationWrapper } from '../pages/documentation/DocumentationWrapper'

const PrivateRoutes = () => {
  const {currentUser, currentClient} = useAuth()
  const clientData = currentClient !== undefined ? currentClient : currentUser
  /**
   * Check if the current user is a client, admin or merchant
   */
  //const isClient = currentUser?.roleId === 1
  const isAdmin = currentUser?.roleId === 2

  /**
   * Check the current Plan of the user
   */
  const isSaaSPlay = currentUser?.saasPlanId === 1
  const isSaaSPro = currentUser?.saasPlanId === 2
  const isSaaSMentor = currentUser?.saasPlanId === 3
  const isSaaSPartner = currentUser?.saasPlanId === 4
  const isSaaSChallenge = currentUser?.saasPlanId === 7
  const canSeeUserAdmin = clientData?.canSeeUserAdmin === true

  /**
   * Check if the current user can open the different modules
   */
  const canOpenHealth = isSaaSPlay || isSaaSPro || isSaaSPartner || isSaaSMentor || isSaaSChallenge
  const canOpenInventory = isSaaSPro || isSaaSMentor || isSaaSPartner || isSaaSChallenge
  const canOpenSales = isSaaSPro || isSaaSMentor || isSaaSPartner || isSaaSChallenge
  const canOpenMarketing = isSaaSPro || isSaaSMentor || isSaaSPartner || isSaaSChallenge
  const canOpenSettings =
    isSaaSPlay || isSaaSPro || isSaaSMentor || isSaaSPartner || isSaaSChallenge

  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))

  /*let url = ''
  if (isClient) {
    url = '/dashboard'
  } else if (isAdmin) {
    url = '/apps/user-management/users'
  } else if (currentClient === undefined && canSeeUserAdmin) {
    url = '/apps/user-management/clients'
  } else if (currentClient !== undefined && canSeeUserAdmin) {
    url = '/dashboard'
  } else if (currentClient !== undefined && !canSeeUserAdmin) {
    url = '/dashboard'
  }*/

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registration */}
        {/*<Route path='auth/*' element={<Navigate to={url} />} />*/}
        {/** <Route path='auth/*' element={<AfterLogin url={url} />} /> */}
        {/* Dashboard wrapper for all SASS profiles */}
        <Route path='dashboard/*' element={<DashboardWrapper />} />
        <Route path='/*' element={<DashboardWrapper />} />
        {/*Dashboard Healthy*/}
        <Route
          path='lite'
          element={
            <SuspensedView>
              <FrankieWrapper />
            </SuspensedView>
          }
        />
        {canSeeUserAdmin && (
          <Route
            path='apps/user-management/*'
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          />
        )}
        {canSeeUserAdmin && (
          <Route
            path='/outputs/edit'
            element={
              <SuspensedView>
                <OutputsWrapper />
              </SuspensedView>
            }
          />
        )}
        {canSeeUserAdmin && (
          <Route
            path='/documentation/edit'
            element={
              <SuspensedView>
                <DocumentationWrapper/>
              </SuspensedView>
            }
          />
        )}
        {/*Healthy category*/}
        {canOpenHealth && (
          <>
            {/*Brand Health*/}
            <Route
              path='/brand-health/:mkp/:ind/:priority?/:types?'
              element={
                <SuspensedView>
                  <BrandHealthIndicatorWrapper />
                </SuspensedView>
              }
            />
            <Route
              path='/brand-health/:mkp/:ind/:priority'
              element={
                <SuspensedView>
                  <BrandHealthIndicatorWrapper />
                </SuspensedView>
              }
            />
            <Route
              path='/brand-health/:mkp/:ind'
              element={
                <SuspensedView>
                  <BrandHealthIndicatorWrapper />
                </SuspensedView>
              }
            />

            <Route
              path='/brand-health/:mkp'
              element={
                <SuspensedView>
                  <BrandHealthWrapper />
                </SuspensedView>
              }
            />

            <Route
              path='/brand-health'
              element={
                <SuspensedView>
                  <BrandHealthWrapper />
                </SuspensedView>
              }
            />
            <Route
              path='/product-health/:mkp/:productId'
              element={
                <SuspensedView>
                  <ProduchtHealthWrapper />
                </SuspensedView>
              }
            />
            <Route
              path='/product-health'
              element={
                <SuspensedView>
                  <ProduchtHealthWrapper />
                </SuspensedView>
              }
            />

            {/*Alertas*/}

            {/*Estrategia de ventas*/}
            <Route
              path='/strategy'
              element={
                <SuspensedView>
                  <SalesStrategyWrapper />
                </SuspensedView>
              }
            />
            {/*Playlist*/}
            <Route
              path='playlist/:page/:count/:priority'
              element={
                <SuspensedView>
                  <PlaylistWrapper />
                </SuspensedView>
              }
            />
            <Route
              path='playlist/:page/:count/:priority/:mkp'
              element={
                <SuspensedView>
                  <PlaylistWrapper />
                </SuspensedView>
              }
            />
            <Route
              path='playlist/:page/:count/:priority/:mkp/:types'
              element={
                <SuspensedView>
                  <PlaylistWrapper />
                </SuspensedView>
              }
            />
            <Route
              path='/playlist/:page/:count'
              element={
                <SuspensedView>
                  <PlaylistWrapper />
                </SuspensedView>
              }
            />
            <Route
              path='/playlist/:page'
              element={
                <SuspensedView>
                  <PlaylistWrapper />
                </SuspensedView>
              }
            />
            <Route
              path='/playlist'
              element={
                <SuspensedView>
                  <PlaylistWrapper />
                </SuspensedView>
              }
            />
          </>
        )}

        {/*Inventory category  */}
        {canOpenInventory && (
          <>
            {/*Reporte*/}
            <Route
              path='inventory'
              element={
                <SuspensedView>
                  <InventoryWrapper />
                </SuspensedView>
              }
            />
            {/*Tabla*/}

            {/*Product Tracker por id*/}
            <Route
              path='product-tracker/:mkpl/:id'
              element={
                <SuspensedView>
                  <ProductTrackerWrapper />
                </SuspensedView>
              }
            />

            {/*Product Tracker*/}
            <Route
              path='product-tracker'
              element={
                <SuspensedView>
                  <ProductTrackerWrapper />
                </SuspensedView>
              }
            />
          </>
        )}

        {/*Sales category*/}
        {canOpenSales && (
          <>
            {/*Ventas*/}
            <Route
              path='sales'
              element={
                <SuspensedView>
                  <SalesWrapper />
                </SuspensedView>
              }
            />
            {/*Historico de ventas*/}
            {/*gestion de envios*/}
            {/*resultados de ordenes*/}
          </>
        )}

        {/*Marketing category*/}
        {canOpenMarketing && (
          <>
            {/*marketing pagado*/}
            <Route
              path='paid-marketing'
              element={
                <SuspensedView>
                  <PaidMarketingWrapper />
                </SuspensedView>
              }
            />
            {/*campañas activas*/}
          </>
        )}

        {/*Apps category*/}
        {isAdmin && (
          <>
            <Route
              path='apps/audit/:page/:count/:search'
              element={
                <SuspensedView>
                  <AuditWrapper />
                </SuspensedView>
              }
            />
            <Route
              path='apps/audit/:page/:count'
              element={
                <SuspensedView>
                  <AuditWrapper />
                </SuspensedView>
              }
            />
            <Route
              path='apps/audit/:page'
              element={
                <SuspensedView>
                  <AuditWrapper />
                </SuspensedView>
              }
            />
            <Route
              path='apps/audit/'
              element={
                <SuspensedView>
                  <AuditWrapper />
                </SuspensedView>
              }
            />
          </>
        )}
        {isSaaSMentor && (
          <>
            {/*Calendario*/}
            <Route
              path='calendar'
              element={
                <SuspensedView>
                  <CalendarBuilder />
                </SuspensedView>
              }
            />
            {/*  chat*/}
            <Route path='chat' element={<ChatDrawer />} />
            {/*  ticket*/}
            <Route
              path='ticket'
              element={
                <SuspensedView>
                  <TicketWrapper />
                </SuspensedView>
              }
            />
            {/*  comunidad*/}
            {/*  mentores*/}
            {/* <Route path='mentores' element={<MentorsWrapper />} /> */}
            {/*  glossary*/}
            <Route path='glossary' element={<GlossaryWrapper />} />
            <Route path='/performace-monitor' element={<MonitorWrapper />} />
            {/*  Biper */}
            <Route path='beeper' element={<BeeperWrapper />} />
            {/* diagnostik */}
            <Route path='DI.A.gnostik' element={<DiagnostikWrapper />} />
            {/*  sheet*/}
            <Route path='/sheet' element={<TestSheet />} />
            {/*archivos*/}
          </>
        )}

        {/*Settings category*/}
        {canOpenSettings && (
          <>
            {/*Config de estrategias*/}
            {/*perfil*/}
            <Route
              path='account/*'
              element={
                <SuspensedView>
                  <AccountPage />
                </SuspensedView>
              }
            />
            {/*setud wizard*/}
            {/*marketplaces*/}
            {/*marketplaces enroll*/}
            <Route
              path='/meli/code'
              element={
                <SuspensedView>
                  <ConfirmMeli />
                </SuspensedView>
              }
            />
            <Route
              path='/amazon/code'
              element={
                <SuspensedView>
                  <ConfirmAmazon />
                </SuspensedView>
              }
            />
            <Route
              path='/amazon-ads/code'
              element={
                <SuspensedView>
                  <ConfirmAmazonADS />
                </SuspensedView>
              }
            />
            {/*quick access*/}
          </>
        )}

        <Route path='wip' element={<Navigate to='/error/wip' />} />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
