import React from 'react'
import { AgTextGeneralBig } from './components/AgTextGeneralBig'
import { AgTextGeneral } from './components/AgTextGeneral'
import { AgTextGeneralBigBold } from './components/AgTextGeneralBigBold'
import { AgTextGeneralLight } from './components/AgTextGeneralLight'
import { AgTextGeneralSmall } from './components/AgTextGeneralSmall'
import { AgPercentageCardText } from './components/AgPercentageCardText'
import { AgTitleCard } from './components/AgTitleCard'
import { AgSmall } from './components/AgSmall'
import { AgBigNumberCard } from './components/AgBigNumberCard'
import { AgBiggerNumberCard } from './components/AgBiggerNumberCard'
import { AgMonthlyTitle } from './components/AgMonthlyTitle'
import { AgMonthlyNumber } from './components/AgMonthlyNumber'
import { AgPercentageText } from './components/AgPercentageText'

type Props = {
  customClass?: string
  choice: any
  text: string
  children?: React.ReactNode
}

export type TextProps = {
  customClass?: string
  text: string | any
  children?: React.ReactNode
}
/**
 * Text component | render components depending on the value of the choice property.
 * @param customClass - to add extra classes
 * @param choice - determines which component to render
 * @param text - the text content
 * @component
 */
const AgText: React.FC<Props> = ({ customClass, choice, text, children }) => {
  /**
   * A statement that condition to render one of the other components, and if is anything else, it will return an empty fragment
   */
  switch (choice.toLowerCase()) {
    case 'general':
      return <AgTextGeneral text={text} customClass={customClass} children={children} />;
    case 'generalbig':
      return <AgTextGeneralBig text={text} customClass={customClass} children={children} />;
    case 'generalbigbold':
      return <AgTextGeneralBigBold text={text} customClass={customClass} children={children} />;
    case 'generallight':
      return <AgTextGeneralLight text={text} customClass={customClass} children={children} />;
    case 'generalsmall':
      return <AgTextGeneralSmall text={text} customClass={customClass} children={children} />;
    case 'percentagecard':
      return <AgPercentageCardText text={text} customClass={customClass} children={children} />;
    case 'titlecard':
      return <AgTitleCard text={text} customClass={customClass} children={children} />;
    case 'small':
      return <AgSmall text={text} customClass={customClass} children={children} />;
    case 'bignumbercard':
      return <AgBigNumberCard text={text} customClass={customClass} children={children} />;
    case 'biggernumbercard':
      return <AgBiggerNumberCard text={text} customClass={customClass} children={children} />;
    case 'monthlytitle':
      return <AgMonthlyTitle text={text} customClass={customClass} children={children} />;
    case 'monthlynumber':
      return <AgMonthlyNumber text={text} customClass={customClass} children={children} />;
    case 'percentagetitle':
      return <AgPercentageText text={text} customClass={customClass} children={children} />;
    default:
      // return <AgTextGeneralSmall text={text} customClass={'text-danger'} />
      return <code>{text}</code>;
  }

}

export { AgText }
