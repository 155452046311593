import {PageTitle} from '../../../../_gws/layout/core'
import React from 'react'
import {useIntl} from 'react-intl'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {slugify, Splash} from '../../../../_gws/helpers/misc'
import axios from 'axios'

const API_URL_CRUNCH = process.env.REACT_APP_BACKEND_MAIN_API
const AMAZON_ADS_CLIENT_ID = process.env.REACT_APP_AMAZON_ADS_CLIENT_ID
const AMAZON_ADS_SECRET_ID = process.env.REACT_APP_AMAZON_ADS_SECRET_ID
const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL

const ConfirmAmazonADS = () => {
  const intl = useIntl()
  const alert = withReactContent(Swal)
  const navigate = useNavigate()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const code = searchParams.get('code')

  Splash(true)
  const fetchConfirm = async () => {
    try {
      const response = await axios.post(
        'https://api.amazon.com/auth/o2/token',
        {
          grant_type: 'authorization_code',
          code: code,
          redirect_uri: PUBLIC_URL + '/amazon-ads/code',
          client_id: AMAZON_ADS_CLIENT_ID,
          client_secret: AMAZON_ADS_SECRET_ID,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      Splash(false)
      if (response.status === 200) {
        const saveMarketplace = await axios.post(`${API_URL_CRUNCH}/api/profile/marketplaces`, {
          marketplaceId: 4,
          url: 'amazon-ads-url',
          appId: AMAZON_ADS_CLIENT_ID,
          secretId: AMAZON_ADS_SECRET_ID,
          refreshToken: response.data.refresh_token,
        })

        if (saveMarketplace.status === 200) {
          alert
            .fire({
              text: intl.formatMessage({
                id: 'MARKETPLACE.MELI.ALERT.YAY',
              }),
              icon: 'success',
              iconColor: '#00c6c7',
              buttonsStyling: false,
              confirmButtonText: intl.formatMessage({
                id: 'MENU.MARKETPLACES',
              }),
              customClass: {
                confirmButton: 'btn btn-primary',
                popup: 'bg-light',
              },
            })
            .then(() => {
              navigate('/marketplaces')
            })
        } else {
          throw new Error('Growker API Error! - On Save Marketplaces')
        }
      }
    } catch (error: any) {
      Splash(false)
      let errorTitle = intl.formatMessage({id: 'ERROR.BACKEND.FETCH'})
      let errorText = ''
      if (error.response.status === 400) {
        errorTitle = intl.formatMessage({id: 'MARKETPLACE.AMAZON.ERROR.TOKEN'})
        errorText = intl.formatMessage({id: 'MARKETPLACE.AMAZON.ERROR.TOKEN.TEXT'})
      } else if (error.response.status === 500) {
        errorText = intl.formatMessage({id: 'ERROR.BACKEND.FETCH.TRY_AGAIN'})
      } else {
        if (error.response.data.description) {
          errorText = intl.formatMessage({id: slugify(error.response.data.description)})
        } else {
          errorText = intl.formatMessage({id: 'ERROR.BACKEND.FETCH.TRY_AGAIN'})
        }
      }

      alert
        .fire({
          title: errorTitle,
          text: errorText,
          icon: 'error',
          iconColor: '#00c6c7',
          buttonsStyling: false,
          confirmButtonText: intl.formatMessage({id: 'ACCOUNT.PROFILE.DETAILS.OK_GOT_IT'}),
          customClass: {
            confirmButton: 'btn btn-primary',
            popup: 'bg-light',
          },
        })
        .then(() => {
          navigate('/marketplaces')
        })
    }
  }

  if (code !== '') {
    fetchConfirm()
  } else {
    alert
      .fire({
        title: intl.formatMessage({id: 'MARKETPLACE.AMAZON.REDIRECT'}),
        text: intl.formatMessage({id: 'MARKETPLACE.AMAZON.ERROR.TOKEN.TEXT'}),
        icon: 'error',
        iconColor: '#00c6c7',
        buttonsStyling: false,
        confirmButtonText: intl.formatMessage({id: 'ACCOUNT.PROFILE.DETAILS.OK_GOT_IT'}),
        customClass: {
          confirmButton: 'btn btn-primary',
          popup: 'bg-light',
        },
      })
      .then(() => {
        navigate('/marketplaces')
      })
  }

  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: intl.formatMessage({id: 'MENU.HOME'}),
            path: '/dashboard',
            isSeparator: false,
            isActive: false,
          },
          {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
          },
        ]}
      >
        {intl.formatMessage({id: 'PAGE.AMAZON.CONFIRMATION'})}
      </PageTitle>
    </>
  )
}

export {ConfirmAmazonADS}
