import React, {FC, useState} from 'react'
import {SearchClient} from './SearchClient'
import {useAuth, UserModel} from '../../../../../app/modules/auth'
import {KTSVG} from '../../../../helpers'
import {useIntl} from 'react-intl'

type Props = {
  list: any
}
const ClientSelect: FC<Props> = ({list}) => {
  const intl = useIntl()

  const {currentUser, currentClient} = useAuth()
  const [client] = useState<UserModel | undefined>(
    currentClient !== undefined ? currentClient : currentUser
  )
  const [merchant] = useState(client?.role === 'merchant' ? true : false)

  return (
    <>
      <div
        className='d-flex align-items-center'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='bottom'
        data-kt-menu-target='#kt_header_client_switcher'
      >
        <div className='d-flex cursor-pointer rounded-1 bg-hover-light p-1 p-sm-2 w-100'>
          <div className='symbol symbol-circle  symbol-50px symbol-sm-60px me-3'>
            {
              //@ts-ignore
              client?.picUrl !== undefined && client?.picUrl !== null && client.picUrl !== '' ? (
                <img alt={client?.username} src={client.picUrl} />
              ) : (
                <span className='symbol-label bg-light-primary text-dark text-uppercase fw-bold'>
                  {client?.lastname?.substring(0, 1)}
                  {client?.name?.substring(0, 1)}
                </span>
              )
            }
            <span
              className='symbol-badge badge badge-circle bg-primary'
              style={{top: '90%', left: '90%'}}
            >
              <KTSVG
                path='/media/icons/unicos/038-transaction.svg'
                className='svg-icon-8 text-white'
              />
            </span>
          </div>
          <div className='flex-grow-1 me-2'>
            <span className='fs-7 fw-semibold text-gray-900 pe-4 text-nowrap d-block pt-0 pt-sm-1'>
              {client?.name} {client?.lastname}
            </span>
            <span className='fs-8 text-gray-600 fw-normal pe-4 text-nowrap d-block pt-0'>
              {client?.email}
            </span>
            <div className='d-flex align-items-center text-success fs-9'>
              <span className='bullet bullet-dot bg-success me-1'></span>
              {intl.formatMessage({id: 'GENERAL.CLIENT'})}
            </div>
          </div>
        </div>
      </div>
      <SearchClient client={client} list={list} isMerchant={merchant} />
    </>
  )
}

export {ClientSelect}
