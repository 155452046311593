/**
 * list of id of indicators which are categorized as "marketing"
 */
export const indicatorsMarketingArray = [8, 9, 10, 32, 36]
/**
 * list of id of indicators which are categorized as "reputation"
 */
export const indicatorsReputationArray = [0]

/**
 * list of id of indicators which are categorized as "PLayListManual"
 */
export const indicatorsPLayListManualArray = [
  15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 39, 40, 41, 42, 43, 44, 45, 46,
  47, 48, 49,
]

interface BrandHealthDictionary {
  [key: string]: {
    backgroundColor: string;
    color: string;
    icon: string;
    svgColor: string;
  };
}

   //dictionary to select label color in table's product health
export const brandHealthDictionary: BrandHealthDictionary = {
  OPTIMAL: {backgroundColor:"light-primary", color: "primary-dark", icon: "media/icons/gws-icons/etiqueta-optimo-rendimiento.svg", svgColor:"primary-dark"}, 
  URGENT: {backgroundColor:"light-warning", color: "warning-dark", icon: "media/icons/gws-icons/etiqueta-pronta-atencion.svg", svgColor:"warning-dark"}, 
  IMMEDIATE: {backgroundColor:"light-danger", color: "danger-dark", icon: "media/icons/gws-icons/etiqueta-atencion-inmediata.svg", svgColor:"danger"},
  NULL: {backgroundColor:"gray-100", color: "gray-550", icon: "media/icons/gws-icons/etiqueta-sin-informacion.svg", svgColor:"gray-550"}
}

export const brandHealthStateList = [
  {
    id: 1,
    label: 'BRAND_HEALTH.SEVERITY.1',
    color: '#F9D7DD',
    gradient: 'bg-gradient-danger',
  },
  {
    id: 2,
    label: 'BRAND_HEALTH.SEVERITY.2',
    color: '#F9E6D3',
    gradient: 'bg-gradient-orange',
  },
  {
    id: 3,
    label: 'BRAND_HEALTH.SEVERITY.3',
    color: '#F9E6D3',
    gradient: 'bg-gradient-warning',
  },
  {
    id: 4,
    label: 'BRAND_HEALTH.SEVERITY.4',
    color: '#CBF3F9',
    gradient: 'bg-gradient-primary',
  },
]

export const marketplaceList = [
  {
    id: 1,
    label: 'AMAZON',
    color: 'amazon',
  },
  {
    id: 2,
    label: 'MERCADOLIBRE',
    color: 'meli',
  },
]

/**
 * Map object color
 */
export const colorMap = [
  {
    label: 'success',
    hex: '#B1CF7F',
  },
  {
    label: 'danger',
    hex: '#FF8B9F',
  },
  {
    label: 'orange',
    hex: '#FFBF7F',
  },
  {
    label: 'warning',
    hex: '#ECD448',
  },
  {
    label: 'meli',
    hex: '#50BBCC',
  },
  {
    label: 'amazon',
    hex: '#0A8A79',
  },
]

export const marketplaceMap = [
  {value: 1, label: 'amazon'},
  {value: 2, label: 'meli'},
  {value: 4, label: 'amazon-ads'},
]

export const playListPriorityList = [
  {
    id: 'urgent',
    label: 'PLAYLIST.PRIORITY.1',
    color: 'danger',
    gradient: 'bg-gradient-danger',
    value: 1,
  },
  {
    id: 'important',
    label: 'PLAYLIST.PRIORITY.2',
    color: 'orange',
    gradient: 'bg-gradient-orange',
    value: 2,
  },
]

export const playListStateList = [
  {
    id: '1',
    label: 'BRAND_HEALTH.SEVERITY.1',
    color: 'danger',
    gradient: 'bg-gradient-danger',
    value: 1,
  },
  {
    id: '2',
    label: 'BRAND_HEALTH.SEVERITY.2',
    color: 'orange',
    gradient: 'bg-gradient-orange',
    value: 2,
  },
]

export const playListMkpList = [
  {
    id: 'amazon',
    label: 'Amazon',
    img: '/media/marketplace/amazon-selector.svg',
    value: 1,
  },
  {
    id: 'meli',
    label: 'Mercado Libre',
    img: '/media/marketplace/meli-selector.svg',
    value: 2,
  },
]

type UrlGlossary = {
  [key: string]: string
}

export const urlGlossary: UrlGlossary = {
  INVENTORY: '/inventory',
  SALES: '/sales',
  LOGISTICS: '/logistics',
  STATE_RESULTS: '/results',
  BRAND_HEALTH: '/brandh-health',
  PAID_MARKETING: '/paid-marketing',
  ACTIVE_CAMPAIGNS: '/active-campaigns',
  STRATEGY: '/strategy',
}

export const marketplaceOptionsGlossary: UrlGlossary = {
  1: '/media/marketplace/amazon-selector.svg',
  2: '/media/marketplace/meli-selector.svg',
}
export const marketplacesIds: any = {
  ALL: 0,
  AMAZON: 1,
  MELI: 2,
}

export const aplicableMetricsByProduct = ['category_1', 'category_2']
