import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_gws/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByManualToken, getUserByToken} from './_requests'
import {WithChildren} from '../../../../_gws/helpers'
import {removeClientAuth} from './AuthHelpers'
import { MemberstackFunctions } from '../../../../solidInterfaces/membestack'

export type mkProp = 'growker' | 'amazon' | 'meli' | undefined

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  setCurrentMkp: Dispatch<SetStateAction<mkProp | undefined>>
  clientAuth: string | AuthModel | undefined
  currentMkp: mkProp
  saveClientAuth: (clientAuth: AuthModel | undefined) => void
  currentClient: UserModel | undefined
  setCurrentClient: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
  hasAllMarketplaces: boolean | undefined
  brandhealthSeen: boolean | undefined
  hasAmazon: boolean | undefined
  hasAmazonAds: boolean | undefined
  hasMeli: boolean | undefined
  clientHasAmazonAds: boolean | undefined
  clientHasAmazon: boolean | undefined
  clientHasMeli: boolean | undefined
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  currentMkp: 'growker',
  setCurrentUser: () => {},
  clientAuth: authHelper.getClientAuth(),
  saveClientAuth: () => {},
  currentClient: undefined,
  setCurrentClient: () => {},
  setCurrentMkp: () => {},
  logout: () => {},
  hasAllMarketplaces: undefined,
  hasAmazon: undefined,
  hasAmazonAds: undefined,
  hasMeli: undefined,
  clientHasAmazonAds: undefined,
  clientHasAmazon: undefined,
  clientHasMeli: undefined,
  brandhealthSeen: undefined,
} as AuthContextProps

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [clientAuth, setClientAuth] = useState<AuthModel | undefined>(authHelper.getClientAuth())
  const [currentClient, setCurrentClient] = useState<UserModel | undefined>()

  let hasAllMarketplaces = false
  let hasAmazon = false
  let hasMeli = false
  let hasAmazonAds = false
  let clientHasAmazonAds = false
  let clientHasAmazon = false
  let clientHasMeli = false

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const saveClientAuth = (clientAuth: AuthModel | undefined) => {
    setClientAuth(clientAuth)
    if (clientAuth) {
      removeClientAuth()
      authHelper.setClientAuth(clientAuth)
    } else {
      authHelper.removeClientAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    saveClientAuth(undefined)
    setCurrentUser(undefined)
    setCurrentClient(undefined)
    localStorage.removeItem('show_onboarding')
    localStorage.removeItem('verified')
    localStorage.removeItem("token")
  }
  if (currentUser?.marketplaces !== undefined) {
    // @ts-ignore
    currentUser.marketplaces.map(
      (mkp: any) => (hasAllMarketplaces = hasAllMarketplaces && mkp.secretId.length > 19)
    )
    // @ts-ignore
    let mkpAm = currentUser.marketplaces.find((mkp) => mkp.marketplace === 'AMAZON')
    // @ts-ignore
    let mkpML = currentUser.marketplaces.find((mkp) => mkp.marketplace === 'MERCADOLIBRE')
    // @ts-ignore
    let mkpAmAD = currentUser.marketplaces.find((mkp) => mkp.marketplace === 'AMAZON ADS')
    hasAmazon = mkpAm.refreshToken.length > 19
    hasMeli = mkpML.refreshToken.length > 19
    hasAmazonAds = mkpAmAD.refreshToken.length > 19
  }
  if (currentClient?.marketplaces !== undefined) {
    // @ts-ignore
    let mkpAmzCl = currentClient.marketplaces.find((mkp) => mkp.marketplace === 'AMAZON')
    // @ts-ignore
    let mkpMeCl = currentClient.marketplaces.find((mkp) => mkp.marketplace === 'MERCADOLIBRE')
    // @ts-ignore
    let mkpAmAD = currentClient.marketplaces.find((mkp) => mkp.marketplace === 'AMAZON ADS')
    clientHasAmazon = mkpAmzCl.refreshToken.length > 19
    clientHasMeli = mkpMeCl.refreshToken.length > 19
    clientHasAmazonAds = mkpAmAD.refreshToken.length > 19
  }

  let brandhealthSeen = !currentUser?.brandhealthSeen

  let initialCurrentMkp: mkProp =
    hasAmazon && hasMeli ? 'growker' : hasAmazon ? 'amazon' : hasMeli ? 'meli' : undefined

  const [currentMkp, setCurrentMkp] = useState<mkProp>(initialCurrentMkp)

  return (
    <AuthContext.Provider
      value={{
        auth,
        currentUser,
        setCurrentUser,
        saveAuth,
        clientAuth,
        currentClient,
        currentMkp,
        setCurrentClient,
        setCurrentMkp,
        saveClientAuth,
        logout,
        hasAllMarketplaces,
        hasAmazon,
        hasAmazonAds,
        hasMeli,
        clientHasAmazonAds,
        clientHasAmazon,
        clientHasMeli,
        brandhealthSeen,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, clientAuth, setCurrentUser, setCurrentClient, logout, currentUser} = useAuth()
  const didRequest = useRef(false)
  const didRequestClient = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  const requestUser = async () => {
    try {
      if (!didRequest.current) {
        const {data} = await getUserByToken()
        if (data) {
          setCurrentUser(data)
        }
      }
    } catch (error) {
      if (!didRequest.current) {
        await MemberstackFunctions.logout()
        logout()
      }
    } finally {
      setShowSplashScreen(false)
    }

    return () => (didRequest.current = true)
  }

  const requestClient = async () => {
    try {
      if (!didRequestClient.current) {
        const {data} = await getUserByManualToken(clientAuth)
        if (data) {
          setCurrentClient(data)
        }
      }
    } catch (error) {
      console.error(error)

      if (!didRequestClient.current) {
        setCurrentClient(undefined)
      }
    }

    return () => (didRequestClient.current = true)
  }

  useEffect(() => {
    if (auth && auth.token) {
      requestUser()
      if (clientAuth) {
        requestClient()
      }
    } else {
      const memberstackLogout = async () => {
        await MemberstackFunctions.logout()
      }
      memberstackLogout()
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (currentUser) {
      window.Intercom('boot', {
        app_id: 'inummx5m',
        name: currentUser.name,
        email: currentUser.email,
        user_id: currentUser.id,
      })
    }
  }, [currentUser])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
