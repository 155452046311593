import enMessages from '../i18n/messages/en.json'
import esMessages from '../i18n/messages/es.json'
import Swal from 'sweetalert2'

export const makeURL = (descriptions: string, marketplaceId: number, id?: string) => {
  let externalLink = ''
  let productId: any = ''
  let url = marketplaceId === 1 ? 'amazon.com.mx/dp' : 'articulo.mercadolibre.com.mx'
  /**
   * Please, take care about AMAZON ASIN length, it could be 10 only
   */
  try {
    if (marketplaceId === 1) {
      // @ts-ignore
      productId = descriptions.match(/\w{10}/)[0]
      if(productId === "Disponibil"){
        productId = id
      }
    } else {
      // @ts-ignore
      productId = descriptions.match(/MLM\w+/)[0]
    }
  } catch (e) {
    productId = id
  }

  /**
   * Check if PLayListManual exist
   */
  if (productId !== '' && productId !== undefined && productId !== null) {
    externalLink =
      '<a href="https://' +
      url +
      '/' +
      productId.replace('MLM', 'MLM-') +
      '" target="_blank">' +
      productId +
      '</a>'
    return externalLink
  }
  return undefined
}

/**
 * It takes a string, makes it lowercase, trims it, removes all non-word
 * characters, replaces all whitespace and underscores with dashes, and removes
 * leading and trailing dashes
 * @param {string} str - The string to slugify
 * @returns A function that takes a string and returns a slugified version of it.
 */
export function slugify(str: string) {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '')
}

/**
 * It shows or hides the splash screen
 * @param {boolean} state - boolean - true to show the splash screen, false to hide
 * it
 */
export function Splash(state: boolean) {
  let display = 'none'
  let overflow = 'auto'
  if (state) {
    display = 'flex'
    overflow = 'hidden'
  }

  const splashScreen = document.getElementById('splash-screen')
  const body = document.getElementById('kt_body')
  if (splashScreen) {
    splashScreen.style.display = display
  }
  if (body) {
    body.style.overflow = overflow
  }
}

/**
 * It takes a number and returns a color
 * @param {number} key - The number of the color you want to use.
 * @returns A string of rgb values
 */
export function matrixColor(key: number) {
  const color = [
    '3D96CA',
    '3287b8',
    '3793c8',
    '479bcd',
    '57a4d1',
    '67add5',
    '77b5da',
    '87bede',
    '97c7e3',
    'a7cfe7',
    'b7d8eb',
    'c7e1f0',
    'd7e9f4',
    'f7fbfd',
  ]
  let aRgbHex: any = key > 13 ? color[13]: color[key].match(/.{1,2}/g)
  return `rgb(${parseInt(aRgbHex[0], 16)},${parseInt(aRgbHex[1], 16)},${parseInt(aRgbHex[2], 16)})`
}

export function matrixBadgeClass(key: number, light?: boolean) {
  const badgeColor = ['success', 'primary', 'warning', 'danger', 'dark', 'info', 'secondary']
  if (light) {
    return 'badge-light-' + badgeColor[key]
  } else {
    return 'badge-' + badgeColor[key]
  }
}

export function matrixBadgeClassTable(key: number, light?: boolean) {
  const badgeColor = ['success', 'primary', 'warning', 'danger', 'dark', 'info', 'secondary']
  return badgeColor[key]
}

/**
 * It takes a number or a string and returns a string with two decimal places
 * @param {string | number} value - string | number
 * @returns A function that takes a string or number and returns a string or
 * number.
 */
export function getFixedDecimals(value: string | number) {
  if (value) {
    if (typeof value === 'string') {
      return value
    }

    return parseFloat(value.toFixed(2))
  }
  return 0
}

export function datalabelsDefault(rotation: number, axis: boolean, symbol?: string) {
  return {
    color: '#000000',
    backgroundColor: 'rgba(255,255,255, 0.5)',
    padding: {
      top: 2,
      left: 8,
      right: 8,
      bottom: 1,
    },
    borderRadius: 2,
    borderWidth: 0.5,
    rotation: rotation,
    font: {
      size: 10,
      // weight: 'bold' as const,
    },
    align: 'end' as const,
    anchor: 'center' as const,
    display: function (context: any) {
      return context.dataset.data[context.dataIndex] > 0
    },
    formatter: function (value: any, context: any) {
      let ret = symbol !== undefined ? symbol + '' + value : value
      if (axis === true) {
        ret = context.chart.data.labels[context.dataIndex] + ': ' + ret
      }
      return ret
    },
  }
}

export function tooltipDefault(prefix?: any, right?: boolean) {
  return {
    backgroundColor: '#ffffff',
    borderColor: 'rgb(207,207,211)',
    borderWidth: 0.5,
    bodyColor: '#a1a5b7',
    titleColor: '#181c32',
    cornerRadius: 4,
    usePointStyle: true,
    titleMarginBottom: 8,
    boxWidth: 6,
    boxHeight: 6,
    callbacks: {
      label: function (context: any) {
        let label = context.dataset.label
        if (label === undefined) return
        if (label) {
          label += ': '
        }

        if (context.parsed.y !== null) {
          if (prefix !== undefined) {
            if (right) {
              label += getFixedDecimals(context.parsed.y) + prefix
            } else {
              label += prefix + getFixedDecimals(context.parsed.y)
            }
          } else {
            label += context.parsed.y
          }
        }
        return label
      },
    },
  }
}

/**
 * It takes a number and returns a string with the number abbreviated to the
 * nearest thousand, million, billion, or trillion
 * @param {any} num - The number to be abbreviated.
 * @param {any} fixed - The number of decimal places to show.
 * @returns the number of decimal places to show.
 */
export function abbreviateNumber(num: any, fixed: any) {
  if (num === null) {
    return null
  } // terminate early
  if (num === 0) {
    return '0'
  } // terminate early
  fixed = !fixed || fixed < 0 ? 0 : fixed // number of decimal places to show
  var b = num.toPrecision(2).split('e'), // get power
    k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
    c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
    d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
    e = d + ['', 'K', 'M', 'B', 'T'][k] // append power
  return e
}

export function abbreviateNumberSplited(num: any, fixed: any) {
  if (num === null) {
    return null
  } // terminate early
  if (num === 0) {
    return '0'
  } // terminate early
  fixed = !fixed || fixed < 0 ? 0 : fixed // number of decimal places to show
  var b = num.toPrecision(2).split('e'), // get power
    k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
    c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
    d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
    e = ['', 'K', 'M', 'B', 'T'][k] // append power
  // 0: number, 1: abbr
  return [d, e]
}

/**
 * It returns the short name of a month, given its id
 * @param {number} idMonth - The month number (0-11)
 * @returns A string
 */
export function getShortMonth(idMonth: number) {
  const listMonth = [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
  ]
  return listMonth[idMonth]
}

export function AlertFetchError(lang: string, error: any) {
  const i18n = lang === 'es' ? esMessages : enMessages

  let errorText = undefined
  /**
   * If the request has response, like a controlled error
   */
  console.log(error)
  if (error.response) {
    if (error.response.data.description !== undefined) {
      errorText = slugify(error.response.data.description)
    } else if (error.response.statusText !== undefined) {
      errorText = slugify(error.response.statusText)
    } else {
      errorText = 'ERROR.BACKEND.FETCH.CORS.TIMEOUT'
    }
  }

  // @ts-ignore
  let text = i18n[errorText]
  if (text === undefined) {
    text = i18n['ERROR.BACKEND.FETCH.ERROR'] + '<br/>' + i18n['ERROR.BACKEND.FETCH.TRY_AGAIN']
    console.log(errorText)
  }

  return Swal.mixin({
    // @ts-ignore
    title: text === undefined ? i18n['ERROR.BACKEND.FETCH'] : i18n['ERROR.BACKEND.MESSAGE'],
    html: text,
    icon: text !== undefined ? 'info' : 'warning',
    buttonsStyling: false,
    confirmButtonText: i18n['ACCOUNT.PROFILE.DETAILS.OK_GOT_IT'],
    iconColor: '#00c6c7',
    customClass: {
      confirmButton: 'btn btn-primary',
      popup: 'bg-light',
    },
  })
}

/**
 * Used to force UTC-6 time when choose new Date() as 'now'
 * @param date
 * @param hours
 */
export function addHours(date: any, hours: any) {
  date.setTime(date.getTime() + hours * 60 * 60 * 1000)
  return date
}

export function timeSince(date: any, lang: string) {
  const i18n = lang === 'es' ? esMessages : enMessages
  const input = new Date(date)
  const now = addHours(new Date(), 6)
  let month = false

  // @ts-ignore
  let seconds = Math.floor((now - input) / 1000)
  let intervalType

  let interval = Math.floor(seconds / 31536000)
  if (interval >= 1) {
    intervalType = ' ' + i18n['MISC.SINCE.YEARS']
  } else {
    interval = Math.floor(seconds / 2592000)
    if (interval >= 1) {
      intervalType = ' ' + i18n['MISC.SINCE.MONTHS']
      month = true
    } else {
      interval = Math.floor(seconds / 86400)
      if (interval >= 1) {
        intervalType = ' ' + i18n['MISC.SINCE.DAYS']
      } else {
        interval = Math.floor(seconds / 3600)
        if (interval >= 1) {
          intervalType = ' ' + i18n['MISC.SINCE.HOURS']
        } else {
          interval = Math.floor(seconds / 60)
          if (interval >= 1) {
            intervalType = ' ' + i18n['MISC.SINCE.MINUTES']
          } else {
            interval = seconds
            intervalType = ' ' + i18n['MISC.SINCE.SECONDS']
          }
        }
      }
    }
  }

  if (interval > 1 || interval === 0) {
    if (month && lang === 'es') {
      intervalType += 'es'
    } else {
      intervalType += 's'
    }
  }

  return interval + ' ' + intervalType
}

export function formatDateTime(dateTimeStr: any) {
  const dateTime = new Date(dateTimeStr)
  const year = dateTime.getFullYear().toString().slice(-2)
  const month = (dateTime.getMonth() + 1).toString().padStart(2, '0')
  const day = dateTime.getDate().toString().padStart(2, '0')
  const hours = dateTime.getHours().toString().padStart(2, '0')
  const minutes = dateTime.getMinutes().toString().padStart(2, '0')
  const seconds = dateTime.getSeconds().toString().padStart(2, '0')
  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`
}

/**
 *
 * @param n
 */
export function addCommasInNumber(n: number | string | undefined) {
  return typeof n === 'number' && !isNaN(n) ? n.toLocaleString() : ''
}

export function valueAndColor(valueIn: number | string) {
  const valuePercent = Number(valueIn)
  let color: string = ''

  if (valuePercent >= 0 && valuePercent < 20) color = '#FF8B9F'
  if (valuePercent >= 20 && valuePercent < 60) color = '#FFBF7F'
  if (valuePercent >= 60 && valuePercent < 90) color = '#46A4BD'
  if (valuePercent >= 90) color = '#46A4BD'

  let percentage: string = `${valuePercent}`
  if (valuePercent > 100) percentage = '100'
  return {
    value: percentage,
    color: color,
  }
}

export const checkUndefined = (value: any) => {
  if (value === undefined) {
    return 0
  } else {
    return Number(value)
  }
}


export function priceformated(price: number): string {
  const formated = (price / 100).toFixed(2);
  return formated;
}