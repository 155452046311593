import {Divider, Indicator, PlayListButton, PlaylistBody, PlaylistItem, PlaylistlCard} from './styled'
import {useNavigate} from 'react-router-dom'
import {PriorityType, priorityPlaylist} from '../../../playlist/types'
import {useIntl} from 'react-intl'
import parse from 'html-react-parser'
import {makeURL} from '../../../../../_gws/helpers/misc'
import {useEffect, useState} from 'react'
import {getLastElementsPlaylist} from '../../core/_request'
import {Spinner} from 'react-bootstrap'

type ItemPlaylist = {
  createdAt: string
  priority: PriorityType
  title: string
  item: string
  marketplaceId: number
}

const makeLink = (asin: any, marketplace: any) => {
  const link = makeURL(asin, marketplace)
  return link && parse(link)
}

const getTruncatedMessage = (message: string) => {
  const words = message.trim().split(/\s+/)
  return words.join(' ').substring(0, 15)
}

export const Item = ({item}: {item: ItemPlaylist}) => {
  return (
    <PlaylistItem>
      <div>
        <p>
          {new Date(item.createdAt).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })}
        </p>
      </div>
      <Indicator bg={priorityPlaylist[item.priority].color} />
      <div
        style={{
          width: '150px',
        }}
      >
        <p>{getTruncatedMessage(item.title)}</p>
        <p>{makeLink(item.item, item.marketplaceId)}</p>
      </div>
    </PlaylistItem>
  )
}

export const PlaylistIndicator = () => {
  const [elements, setElements] = useState({
    data: [],
    isLoading: true,
  })
  const intl = useIntl()
  const navigate = useNavigate()

  useEffect(() => {
    getLastElementsPlaylist().then((res) => {
      setElements((pre) => ({
        ...pre,
        isLoading: false,
        data: res.data,
      }))
    })
  }, [])
  return (
    <PlaylistlCard>
      <PlaylistBody >
        <h4>Playlist</h4>
        {!elements.isLoading &&
          elements.data
            .map((item: ItemPlaylist, i) => (
              <>
                <Item key={i} item={item} />
                {i !== 4 && <Divider />}
              </>
            ))
            .slice(0, 5)}
        {elements.isLoading && (
          <div
            style={{
              height: '300px',
              display: 'grid',
              placeItems: 'center',
            }}
          >
            <Spinner size='sm' animation='border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </Spinner>
          </div>
        )}
      </PlaylistBody>
      {!elements.isLoading && (
        <PlayListButton onClick={() => navigate('/playlist')} size='sm'>
          {intl.formatMessage({id: 'DASHBOARD.PLAYLIST.ACTION'})}
        </PlayListButton>
      )}
    </PlaylistlCard>
  )
}
