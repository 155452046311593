import React, { FC } from 'react'
import { TextProps } from '../../../layout/components/ag/AgText'
import { FontSizes } from '../../../../app/constants/sizes'



/**
 * Standard prototype of Text "AgPercentageCardText" | renders a div element with a specific font size and line height, and can have a custom class and text provided as properties.
 * @param customClass - to add extra classes
 * @param text - the text content
 * @constructor
 */
const LabelWithIndicatorText: FC<TextProps> = ({ customClass, text, children }) => {
    /**
     * static css for the component
     */
    const css = {
        fontSize: FontSizes.SMALL.SIZE_2, //'0.95em',
        fontWeight: '700',
    }

    return (
        <div className={`ff-prompt ${customClass}`} style={css}>
            {text}
            {children}
        </div>
    )
}

export { LabelWithIndicatorText }
