/* eslint-disable jsx-a11y/anchor-is-valid */
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import {mkProp} from '../../../modules/auth'
import {useEffect} from 'react'

/**
 * This Component create a GlossaryFilter Component, Has the responsability to change searchInput and selectedCheckedBoxes
 * @param searchInput - state of search value
 * @param setSearchInput - function to set the search value when user use the searchComponent
 * @param selectedCheckboxes - array related marketplace
 * @param setSelectedCheckboxes - function to set the selectedCheckboxes when user choose the marketplace.
 */

const MKP: Record<string, number[]> = {
  amazon: [1],
  meli: [2],
  growker: [],
}
type Props = {
  searchInput: string
  setSearchInput: React.Dispatch<React.SetStateAction<string>>
  selectedCheckboxes: number[]
  setSelectedCheckboxes: React.Dispatch<React.SetStateAction<number[]>>
  mkplace: mkProp
}

const GlossaryFilters: React.FC<Props> = ({
  selectedCheckboxes,
  setSelectedCheckboxes,
  searchInput,
  setSearchInput,
  mkplace,
}) => {
  /**
   * This function has the logic for check options
   */
  useEffect(() => {
    setSelectedCheckboxes(MKP[mkplace as string])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mkplace])

  /**
   * This function has the logic for search input
   */
  const handleSearchChange = (e: any) => {
    const value = e.target.value
    setSearchInput(value)
  }

  return (
    <div style={{
      display:  "flex",
      justifyContent: "flex-end",
      marginBottom: "20px"
    }}>
      <Form style={{width: '200px'}}>
        <InputGroup className='d-flex align-items-center'>
          <FormControl
            type='text'
            placeholder='Búsqueda'
            className='bg-white border pl-5'
            value={searchInput}
            onChange={handleSearchChange}
          />
          <InputGroup.Text
            className='bg-transparent border-0'
            style={{
              position: 'absolute',
              right: '0px',
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          >
          </InputGroup.Text>
        </InputGroup>
      </Form>
    </div>
  )
}

export {GlossaryFilters}
