import React, {FC} from 'react'
import FrankieTable from './FrankieTable'
import {headFormatterFK} from '../../../../../_gws/partials/tables/TableHelper'
import {useIntl} from 'react-intl'

type Props = {
  listProduct: any
  mkp: string | undefined
  listConditions: any
  title: string
  sales?: boolean
  autoHeight?: boolean
}
const FrankieTableWrapper: FC<Props> = ({
  listProduct,
  mkp,
  listConditions,
  title,
  sales = false,
  autoHeight = false,
}) => {
  const intl = useIntl()

  listProduct?.forEach(function (e: any, i: any) {
    e.uniqueId = i + new Date().getTime().toString()
  })
  // const selectOptionsColors = listConditions.reduce(function (o: any, val: any, index: any) {
  //   o[val] = index
  //   return o
  // }, {})
  const columnsAmazon = [
    {
      dataField: 'asin',
      text: intl.formatMessage({id: 'REPORT.FRANKIE.TABLE.HEAD.ASIN'}),
      sort: true,
      searchable: true,
      headerFormatter: headFormatterFK,
      style: {
        width: '12%',
      },
    },
    {
      dataField: 'stock',
      text: intl.formatMessage({id: 'REPORT.FRANKIE.TABLE.HEAD.QUANTITY'}),
      searchable: false,
      headerFormatter: headFormatterFK,
      style: {
        width: '12%',
      },
    },
    {
      dataField: 'name',
      text: intl.formatMessage({id: 'REPORT.FRANKIE.TABLE.HEAD.NAME'}),
      sort: true,
      searchable: true,
      style: {
        width: '50%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      headerFormatter: headFormatterFK,
    },
    {
      dataField: 'state',
      text: 'Estado',
      searchable: false,
      headerFormatter: headFormatterFK,
      style: {
        width: '10%',
      },
      formatter: (cellContent: any, row: any) => {
        return (
          <span className={`badge badge-light-primary fw-bold`}>{`${intl.formatMessage({
            id: 'REPORT.FRANKIE.TABLE.HEAD.STATE.' + row.state.toUpperCase(),
          })}`}</span>
        )
      },
    },
  ]
  const columnsSalesAmazon = [
    {
      dataField: 'id',
      text: intl.formatMessage({id: 'REPORT.FRANKIE.TABLE.HEAD.ID'}),
      sort: true,
      searchable: true,
      headerFormatter: headFormatterFK,
      style: {
        width: '12%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
    {
      dataField: 'date',
      text: intl.formatMessage({id: 'REPORT.INVENTORY.TABLE.HEAD.DATE'}),
      searchable: false,
      headerFormatter: headFormatterFK,
      style: {
        width: '12%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
    {
      dataField: 'productAmount',
      text: intl.formatMessage({id: 'REPORT.INVENTORY.TABLE.HEAD.QUANTITY'}),
      searchable: false,
      headerFormatter: headFormatterFK,
      style: {
        width: '12%',
      },
    },
    {
      dataField: 'productName',
      text: intl.formatMessage({id: 'REPORT.FRANKIE.TABLE.HEAD.NAME'}),
      sort: true,
      searchable: true,
      style: {
        width: '50%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      headerFormatter: headFormatterFK,
    },
    {
      dataField: 'state',
      text: 'Estado',
      searchable: false,
      headerFormatter: headFormatterFK,
      style: {
        width: '10%',
      },
      formatter: (cellContent: any, row: any) => {
        return (
          <span className={`badge badge-light-primary fw-bold`}>{`${intl.formatMessage({
            id: 'REPORT.FRANKIE.TABLE.HEAD.STATE.' + row.state.toUpperCase(),
          })}`}</span>
        )
      },
    },
  ]

  let columns = sales ? columnsSalesAmazon : columnsAmazon

  return (
    <>
      <FrankieTable
        title={title}
        columns={columns}
        products={listProduct}
        autoHeight={autoHeight}
      />
    </>
  )
}

export {FrankieTableWrapper}
